import { useNavigate, useParams } from "react-router-dom";
import { BoardViewEnum } from "../enums/BoardViewEnum";
import { TBoardParams } from "../../board/containers/Board/types/TBoardParams";
import { VisionEnum } from "../modules/holisticView/containers/Filter/VisionPicker/enums/VisionEnum";

const useNavigateBoard = () => {
  const { projectAbbr, boardRef } = <TBoardParams>useParams();
  const navigate = useNavigate();

  return (boardView: BoardViewEnum, holisticViewVision?: VisionEnum) => {
    const param1 = `/${boardView}`;
    const param2 = holisticViewVision ? `/${holisticViewVision}` : "";

    return navigate(`/${projectAbbr}/board/${boardRef}${param1}${param2}`);
  };
};

export default useNavigateBoard;
