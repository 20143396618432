import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IConvertTaskToEntityResource } from "../interfaces/IConvertTaskToEntityResource";

export const useConvertTaskToEntityService = (props: { taskId: number }) => {
  const response = useHttpClientDispatchable({
    url: `/task/${props.taskId}/convertToEntity`,
    method: RequestMethodEnum.POST,
  });

  return {
    ...response,
    data: response.data as IConvertTaskToEntityResource,
  };
};
