import { TTaskResourceProp } from "./types/TTaskResourceProp";
import { Typography } from "@mui/material";
import moment from "moment/moment";

const EarlyStart = (props: TTaskResourceProp) => {
  return (
    <Typography variant="caption">
      <Typography fontWeight="bold" variant="caption">
        Early in progress, starts at{" "}
        <b>{moment(props.task.startDate).format("MMM D, YYYY")}</b>
      </Typography>
    </Typography>
  );
};

export default EarlyStart;
