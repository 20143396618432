import { useAppDispatch } from "../../../common/hooks/redux";
import { useSubscribeEvent } from "../../../common/modules/eventProvider";
import { EventEnum } from "../../../common/modules/eventProvider/enums/EventEnum";
import { orgActions } from "../../org/slices/orgSlice";
import Title from "../../../common/components/Title/Title";
import { Box } from "@mui/material";
import { Button } from "../../../common/modules/materialUI";
import { Add } from "@mui/icons-material";
import { ITaskPriority } from "../../../entities/ITaskPriority";
import { taskPriorityActions } from "../slices/taskPrioritySlice";
import TaskPriorityGrid from "./TaskPriorityGrid/TaskPriorityGrid";
import CreateModal from "./CreateTaskPriority/CreateModal";

const TaskPrioritiesPage = () => {
  const dispatch = useAppDispatch();

  useSubscribeEvent(
    EventEnum.ON_TASK_PRIORITY_CREATED,
    (taskPriority: ITaskPriority) => {
      dispatch(orgActions.addTaskPriority(taskPriority));
    }
  );
  useSubscribeEvent(
    EventEnum.ON_TASK_PRIORITY_UPDATED,
    (taskPriority: ITaskPriority) => {
      dispatch(orgActions.updateTaskPriority(taskPriority));
    }
  );
  useSubscribeEvent(
    EventEnum.ON_TASK_PRIORITY_DELETED,
    (taskPriorityId: number) => {
      dispatch(orgActions.deleteTaskPriority({ id: taskPriorityId }));
    }
  );

  return (
    <>
      <Title>Task Priorities</Title>
      <Box mb={3}>
        <Button
          variant="text"
          startIcon={<Add />}
          onClick={() => dispatch(taskPriorityActions.openCreateModal())}
        >
          Add Task Priority
        </Button>

        <CreateModal />
      </Box>

      <TaskPriorityGrid />
    </>
  );
};

export default TaskPrioritiesPage;
