import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INetwork } from "../../../entities/INetwork";

type TState = {
  list: INetwork[];
  editableItem?: INetwork;
};

const initialState: TState = {
  list: [],
};

const slice = createSlice({
  name: "networking",
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<INetwork[]>) => {
      state.list = action.payload;
    },
    addItem: (state, action: PayloadAction<INetwork>) => {
      state.list = [action.payload, ...state.list];
    },
    setEditableItem: (state, action: PayloadAction<INetwork | undefined>) => {
      state.editableItem = action.payload;
    },
  },
});

export const networkingReducer = slice.reducer;

export const networkingActions = slice.actions;
