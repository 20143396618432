import { array } from "../../../../../../utils/array";
import classes from "../../common/Navigation.module.scss";
import List from "../../common/List/List";
import {
  Apps,
  Archive,
  Assignment,
  Calculate,
  TagOutlined,
  TipsAndUpdates,
} from "@mui/icons-material";
import { BoardTypeEnum } from "../../../../../../../entities/columns/board/BoardTypeEnum";
import IconImage from "../../../../../../components/Icon/IconImage/IconImage";
import CreateBoard from "../../../../../../../modules/board/containers/CreateBoard/CreateBoard";
import listClasses from "../../../../../../components/Navigation/List/List.module.css";
import BottomSection from "./BottomSection/BottomSection";
import { TMainNavigationContentProps } from "./types/TMainNavigationContentProps";
import Org from "./Org/Org";
import QuickStartMenuItem from "./QuickStartMenuItem";
import ExploreAndShare from "./ExploreAndShare";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useAccessControl } from "../../../../../../modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../../../modules/accessControl/enums/AbilityEnum";

const getBoardItemIcon = (boardType?: BoardTypeEnum) => {
  switch (boardType) {
    case BoardTypeEnum.HOLISTIC:
      return <IconImage img="/icons/hash-lock.png" size={14} />;
    case BoardTypeEnum.BACKLOG:
      return <Archive />;
    case BoardTypeEnum.GUESSTIMATION:
      return <Calculate />;
    default:
      return <TagOutlined />;
  }
};

const getBoardPath = (
  projectAbbr: string,
  boardRef: string,
  boardType?: BoardTypeEnum
) => {
  switch (boardType) {
    case BoardTypeEnum.BACKLOG:
      return `${projectAbbr}/board/${boardRef}/backlog`;
    default:
      return `${projectAbbr}/board/${boardRef}`;
  }
};

const MainNavigationContent = ({
  matchesMax992Screen,
  pushContentClassName,
  navigationRequestedHandler,
  projects,
  orgInitDataLoading,
}: TMainNavigationContentProps) => {
  const hasProjects = projects.length > 0;
  const { can } = useAccessControl();

  return (
    <nav
      className={array.toClassName([
        classes.navigation,
        !matchesMax992Screen ? pushContentClassName : "",
      ])}
    >
      <Org />
      <ExploreAndShare onClick={navigationRequestedHandler} />
      <QuickStartMenuItem onClick={navigationRequestedHandler} />
      {!orgInitDataLoading && (
        <List
          title="Featured"
          items={[
            ...(can(AbilityEnum.manage)
              ? [
                  {
                    id: "objectives",
                    name: "Objectives",
                    path: "/objectives",
                    icon: <TipsAndUpdates />,
                    onNavigate: navigationRequestedHandler,
                  },
                ]
              : []),
            ...(hasProjects
              ? [
                  {
                    id: "priorityView",
                    name: "Priority View",
                    path: "/priorityView",
                    icon: <Assignment />,
                    onNavigate: navigationRequestedHandler,
                  },
                  {
                    id: "projectView",
                    name: "Project View",
                    path: "/projectView",
                    icon: <Apps />,
                    onNavigate: navigationRequestedHandler,
                  },
                ]
              : []),
          ]}
        />
      )}
      {orgInitDataLoading && <List title="Loading Boards" loading={true} />}
      {!orgInitDataLoading &&
        projects.map((data) => {
          const boards = [...data.boards];

          const sortedBoards = [
            ...boards.splice(
              boards.findIndex(
                (board) => board.type === BoardTypeEnum.HOLISTIC
              ),
              1
            ),
            ...boards.splice(
              boards.findIndex((board) => board.type === BoardTypeEnum.BACKLOG),
              1
            ),
            ...boards,
          ];

          return (
            <List
              title={
                <>
                  {data.project.color && (
                    <span className={classes["icon-container"]}>
                      <BookmarkIcon
                        htmlColor={data.project.color}
                        fontSize="small"
                      />
                    </span>
                  )}
                  {data.project.name}
                </>
              }
              key={data.project.ref}
              items={sortedBoards.map((board) => {
                return {
                  className:
                    board.type === BoardTypeEnum.BACKLOG
                      ? classes["board-item-padding-bottom"]
                      : undefined,
                  id: board.ref!,
                  name: board.name,
                  path: getBoardPath(data.project.abbr, board.ref!, board.type),
                  icon: getBoardItemIcon(board.type),
                  onNavigate: navigationRequestedHandler,
                };
              })}
              action1Element={
                <CreateBoard
                  className={listClasses["action-1-icon"]}
                  projectRef={data.project.ref!}
                  projectName={data.project.name}
                  onClick={navigationRequestedHandler}
                />
              }
            />
          );
        })}
      <BottomSection onClick={navigationRequestedHandler} />
    </nav>
  );
};

export default MainNavigationContent;
