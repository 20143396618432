import Title from "../../../common/components/Title/Title";
import { Box } from "@mui/material";
import { Button } from "../../../common/modules/materialUI";
import { Add } from "@mui/icons-material";
import { useAppDispatch } from "../../../common/hooks/redux";
import { taskTypeActions } from "../slices/taskTypeSlice";
import CreateModal from "./CreateTaskType/CreateModal";
import TaskTypeGrid from "./TaskTypeGrid/TaskTypeGrid";
import { orgActions } from "../../org/slices/orgSlice";
import { useSubscribeEvent } from "../../../common/modules/eventProvider";
import { EventEnum } from "../../../common/modules/eventProvider/enums/EventEnum";
import { ITaskType } from "../../../entities/ITaskType";

const TaskTypesPage = () => {
  const dispatch = useAppDispatch();

  useSubscribeEvent(EventEnum.ON_TASK_TYPE_CREATED, (taskType: ITaskType) => {
    dispatch(orgActions.addTaskType(taskType));
  });
  useSubscribeEvent(EventEnum.ON_TASK_TYPE_UPDATED, (taskType: ITaskType) => {
    dispatch(orgActions.updateTaskType(taskType));
  });
  useSubscribeEvent(EventEnum.ON_TASK_TYPE_DELETED, (taskTypeId: number) => {
    dispatch(orgActions.deleteTaskType({ id: taskTypeId }));
  });

  return (
    <>
      <Title>Task Types</Title>
      <Box mb={3}>
        <Button
          variant="text"
          startIcon={<Add />}
          onClick={() => dispatch(taskTypeActions.openCreateModal())}
        >
          Add Task Type
        </Button>

        <CreateModal />
      </Box>

      <TaskTypeGrid />
    </>
  );
};

export default TaskTypesPage;
