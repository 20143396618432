import { TFormListing } from "../containers/Create/types/TFormListing";
import { useAppSelector } from "../../../common/hooks/redux";
import { IListResource } from "../../../interfaces/IListResource";
import { TFormListingProps } from "../containers/Create/types/TFormListingProps";
import { useOrgProjectData } from "../../org/hooks/useOrgProjectData";

const useList = (props: TFormListingProps): TFormListing => {
  const linkList = useAppSelector((state) => state.org.init.linkList);
  const project = useOrgProjectData().findProjectByRef(props.projectRef)
    ?.project!;
  const linkListFiltered = linkList.filter((item) => {
    if (item.projectIds) {
      return item.projectIds.includes(project.id as number) && item.isActive;
    }
    return item.isActive;
  });

  const linkListFilteredTransformed = linkListFiltered.map(
    (filteredLinkList) =>
      ({
        id: filteredLinkList.id,
        label: filteredLinkList.description,
      } as IListResource)
  );

  return {
    linkList: linkList,
    linkListFiltered: linkListFiltered,
    linkListFilteredTransformed: linkListFilteredTransformed,
  };
};

export default useList;
