import { createSlice } from "@reduxjs/toolkit";
import { TExploreAndShareState } from "./types/TExploreAndShareState";

const initialState: TExploreAndShareState = {
  openWhatsNew: false,
  openFeedback: false,
};

const slice = createSlice({
  name: "exploreAndShare",
  initialState,
  reducers: {
    openWhatsNew: (state) => {
      state.openWhatsNew = true;
    },
    closeWhatsNew: (state) => {
      state.openWhatsNew = false;
    },
    openFeedback: (state) => {
      state.openFeedback = true;
    },
    closeFeedback: (state) => {
      state.openFeedback = false;
    },
  },
});

export const exploreAndShareReducer = slice.reducer;

export const exploreAndShareActions = slice.actions;
