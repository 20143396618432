import { useAppDispatch } from "../../../common/hooks/redux";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { linkListActions } from "../slices/linkListSlice";
import { ILinkList } from "../../../entities/ILinkList";

export const useCreateLinkListService = () => {
  const dispatch = useAppDispatch();
  const response = useHttpClientDispatchable({
    url: "/linkList",
    method: RequestMethodEnum.POST,
    events: {
      beforeTransform: (data: ILinkList[]) => {
        dispatch(linkListActions.addToList(data));
      },
    },
  });

  return {
    ...response,
    data: response.data as ILinkList,
  };
};
