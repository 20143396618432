import { createSlice } from "@reduxjs/toolkit";

type TState = {
  redirectionDialog: {
    open: boolean;
    link?: string;
  };
};

const initialState: TState = {
  redirectionDialog: {
    open: false,
  },
};

const slice = createSlice({
  name: "system",
  initialState,
  reducers: {
    openConfirmRedirectionDialog: (state, action) => {
      state.redirectionDialog.open = true;
      state.redirectionDialog.link = action.payload;
    },
    closeConfirmRedirectionDialog: (state) => {
      state.redirectionDialog.open = false;
      state.redirectionDialog.link = undefined;
    },
  },
});

export const systemReducer = slice.reducer;

export const systemActions = slice.actions;
