import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IUserOfftime } from "../../../entities/IUserOfftime";

export const useCreateOfftimeService = () => {
  const response = useHttpClientDispatchable({
    url: "/offtime",
    method: RequestMethodEnum.POST,
  });

  return {
    ...response,
    data: response.data as IUserOfftime,
  };
};
