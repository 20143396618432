import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TLinkState } from "./types/TLinkState";
import { EntityTypeEnum } from "../../../entities/enums/EntityTypeEnum";
import { ILinkResource } from "../interfaces/ILinkResource";

const initialState: TLinkState = {
  linkCreate: {
    displayModal: false,
    entityType: null,
    entityId: null,
    projectRef: null,
  },
  entityLinkList: [],
};

const slice = createSlice({
  name: "link",
  initialState,
  reducers: {
    openCreateLinkModal: (
      state,
      action: PayloadAction<{
        entityType: EntityTypeEnum;
        entityId: number;
        projectRef: string;
      }>
    ) => {
      state.linkCreate.displayModal = true;
      state.linkCreate.entityType = action.payload.entityType;
      state.linkCreate.entityId = action.payload.entityId;
      state.linkCreate.projectRef = action.payload.projectRef;
    },
    closeCreateLinkModal: (state) => {
      state.linkCreate = initialState.linkCreate;
    },

    setLinkList: (state, action: PayloadAction<ILinkResource[]>) => {
      state.entityLinkList = action.payload;
    },
    addLinkToList: (state, action: PayloadAction<ILinkResource[]>) => {
      state.entityLinkList = [...state.entityLinkList, ...action.payload];
    },
    removeLinkFromList: (state, action: PayloadAction<{ linkId: number }>) => {
      state.entityLinkList = state.entityLinkList.filter(
        (item) => !(item.link.id === action.payload.linkId)
      );
    },
  },
});

export const linkReducer = slice.reducer;

export const linkActions = slice.actions;
