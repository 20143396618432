import { Box } from "@mui/material";
import ExpandableIcon from "../../../../../../common/components/Icon/Expandable/ExpandableIcon";
import {
  Autocomplete,
  ConfirmationDialog,
} from "../../../../../../common/modules/materialUI";
import { useState } from "react";
import { TListItem } from "../../../../../../common/types/TListItem";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { TCloneFromBoardProps } from "./types/TCloneFromBoardProps";
import { useFetchEntityFieldDefaultValuesByBoardRefService } from "../../../../../task/services/useFetchEntityFieldDefaultValuesByBoardRefService";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";

export default function CloneFromBoard({
  projectAbbr,
  board,
  ...props
}: TCloneFromBoardProps) {
  const dispatch = useAppDispatch();
  const [showBoardPicker, setShowBoardPicker] = useState<boolean>(false);
  const [selectedBoard, setSelectedBoard] = useState<TListItem<string> | null>(
    null
  );
  const [confirmationBoard, setConfirmationBoard] =
    useState<TListItem<string> | null>(null);
  const {
    isLoading: isTaskFieldDefaultValuesLoading,
    dispatch: dispatchFetchEntityFieldDefaultValues,
  } = useFetchEntityFieldDefaultValuesByBoardRefService();

  const projects = useAppSelector((state) => state.org.init.list);
  const boardOptions = projects
    .find((item) => item.project.abbr === projectAbbr)
    ?.boards?.filter((projectBoard) => projectBoard.ref !== board.ref)
    ?.map(
      (board) => ({ id: board.ref, label: board.name } as TListItem<string>)
    );

  return !!boardOptions && !!boardOptions.length ? (
    <Box pb={3}>
      <Box
        style={{ cursor: "pointer", fontSize: "0.9rem" }}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        gap={1}
      >
        <Box
          display="flex"
          alignItems="center"
          onClick={() => setShowBoardPicker((prevState) => !prevState)}
        >
          <span>Clone values from other board</span>
          <ExpandableIcon direction={!showBoardPicker ? "down" : "up"} />
        </Box>
      </Box>
      {showBoardPicker && (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Box width="250px">
            <Autocomplete
              name="boards"
              label="Boards"
              options={boardOptions}
              value={selectedBoard}
              onChange={(e, value) => {
                setSelectedBoard(value);

                // Showing confirmation dialog
                value && setConfirmationBoard(value);
              }}
            />
          </Box>
        </Box>
      )}

      {!!confirmationBoard && (
        <ConfirmationDialog
          open
          title={`Prefill Fields from ${confirmationBoard.label}`}
          content={`Are you sure you want to prefill your field values from "${confirmationBoard.label}" board.`}
          loading={isTaskFieldDefaultValuesLoading}
          closeHandler={() => setConfirmationBoard(null)}
          successHandler={() => {
            dispatchFetchEntityFieldDefaultValues({
              urlPath: confirmationBoard.id,
            })
              .then((entityFieldDefaultValues) => {
                props.onDataLoaded(entityFieldDefaultValues);
                !entityFieldDefaultValues.length &&
                  dispatch(
                    systemNotificationActions.open({
                      message: `No default values found for the selected board ${confirmationBoard.label}.`,
                      variant: "info",
                    })
                  );
              })
              .finally(() => setConfirmationBoard(null));
          }}
        />
      )}
    </Box>
  ) : null;
}
