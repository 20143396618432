import { Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import { TStepperProps } from "./types/TStepperProps";
import Steps from "../Steps/Steps";

const MSRegisterStepper = (props: TStepperProps) => {
  const steps = Steps({ ...props });
  return (
    <>
      <Stepper nonLinear activeStep={props.activeStep} sx={{ mb: 3 }}>
        {steps.map(({ label }, index) => {
          return (
            <Step key={label} completed={index < props.activeStep}>
              <StepLabel
                sx={{
                  ".MuiStepLabel-labelContainer span": {
                    fontSize: "0.75rem",
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {steps[props.activeStep].content}
    </>
  );
};

export default MSRegisterStepper;
