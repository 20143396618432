import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CreateForm from "./CreateForm";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import useUtils from "../../../linkList/hooks/useUtils";
import { linkActions } from "../../slices/linkSlice";

const LinkModal = () => {
  const { addLinkLabel } = useUtils();
  const createState = useAppSelector((state) => state.link.linkCreate);
  const dispatch = useAppDispatch();

  const closeHandler = () => dispatch(linkActions.closeCreateLinkModal());

  return (
    <Dialog
      open={createState.displayModal}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{addLinkLabel}</DialogTitle>
      <DialogContent>
        {createState.displayModal && (
          <CreateForm
            entityType={createState.entityType!}
            entityId={createState.entityId!}
            onCancel={closeHandler}
            projectRef={createState.projectRef!}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LinkModal;
