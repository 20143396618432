import classes from "./DropTargetContainer.module.scss";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { useDrop } from "react-dnd";
import { TDropResult } from "./types/TDropResult";
import { useEffect } from "react";
import { pointConverter } from "../../../../../../common/utils/pointConverter";
import moment, { Moment } from "moment/moment";
import {
  FORMAT_LABEL,
  FORMAT_RAW_DATE_ONLY,
} from "../../../../../../common/utils/date";
import { useTheme } from "@mui/material";
import useDragOverHelper from "../../../../hooks/useDragOverHelper";
import { DRAG_TYPE_GUESSTIMATION_ITEM_HORIZONTAL } from "../LaneItem/LaneItem";
import { TLaneItemProps } from "../LaneItem/types/TLaneItemProps";
import { guesstimationViewItemActions } from "../../slices/guesstimationViewItemSlice";
import {
  getDayByCoords,
  lastContainerRef,
} from "../../../holisticView/hooks/useVirtualizationHelper";
import { IGuesstimationItem } from "../../../../../../entities/IGuesstimationItem";
import { IUser } from "../../../../../../entities/IUser";

type TProps = {
  minDate: Moment;
  maxDate: Moment;
  width: number;
  guesstimationItem: IGuesstimationItem;
  users: IUser[];
};

const buildLabel = (date: string) => {
  const dateObject = moment(date);

  return `${dateObject.date()} ${dateObject.format(FORMAT_LABEL)}`;
};

export default function DropTargetContainer(props: TProps) {
  const { isOver, dragOverHandlers } = useDragOverHelper();
  const usersSectionWidth = 25;
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    currentDateStartXCoord,
    currentXCoord,
    currentHours,
    currentDate,
    dragPosition,
  } = useAppSelector((state) => state.guesstimationViewItem.dropContainerData);

  const workingHours = useAppSelector(
    (state) => state.guesstimationView.workingHours
  );
  const isDragging = useAppSelector(
    (state) => state.guesstimationViewItem.isDragging
  );
  const draggingItemLaneIndex = useAppSelector(
    (state) => state.guesstimationViewItem.draggingItemLaneIndex
  );

  const [, drop] = useDrop(
    () => ({
      accept: DRAG_TYPE_GUESSTIMATION_ITEM_HORIZONTAL,
      hover: (item: TLaneItemProps, monitor) => {
        dispatch(
          guesstimationViewItemActions.setDropContainerCoords(
            monitor.getClientOffset()!
          )
        );
      },
      drop: (item: TLaneItemProps, _monitor) => {
        return {
          itemLaneIndex: props.guesstimationItem.id,
          timeTick: {
            dropXCoord: currentXCoord,
            hour: currentHours,
            date: moment(currentDate)!.format(FORMAT_RAW_DATE_ONLY),
            label: buildLabel(currentDate!),
          },
          item: item,
          workingHours: workingHours,
        } as TDropResult;
      },
    }),
    [currentXCoord, currentHours, currentDate]
  );

  const stepInPx = pointConverter.oneTSPointToPixels;

  useEffect(() => {
    // Calculate the current position inside the lane based on the scroll left of the container,
    // so we know what is the container left offset and right offset, mouse drag coordinates
    // are current mouse coordinates and to achieve this we can sum to the scroll left the
    // diff of current x and container offset left to receive correct internal coordinates
    if (!dragPosition || !lastContainerRef) {
      return;
    }

    const scrollLeft = lastContainerRef.scrollLeft;

    const shiftInWindow =
      dragPosition.x - lastContainerRef.offsetLeft - usersSectionWidth;
    const currentX = scrollLeft + shiftInWindow;

    const coordData = getDayByCoords(currentX);
    if (!coordData) {
      return;
    }

    const { day, coord } = coordData;
    const hours = Math.ceil((currentX - coord) / stepInPx);

    dispatch(
      guesstimationViewItemActions.setDropContainerData({
        currentXCoord: currentX,
        currentDateStartXCoord: coord,
        currentHours: hours,
        currentDate: day,
      })
    );
    dispatch(guesstimationViewItemActions.updateDragSummary(buildLabel(day)));
  }, [dragPosition?.x]);

  if (!isDragging) {
    return null;
  }

  // The background overlay is opened by another component <Overlay />
  return (
    <>
      {!!currentDateStartXCoord &&
        isOver &&
        draggingItemLaneIndex === props.guesstimationItem?.phaseRef && (
          <div
            style={{
              position: "absolute",
              // By this we are moving the overlay to the left side for two days because we
              // display extra 4 hours, means 2 hours on both sides
              // Check line length: workingHours + 4
              left: currentDateStartXCoord - 2 * stepInPx + usersSectionWidth,
              top: 0,
              display: "flex",
              zIndex: 100,
            }}
          >
            {Array.from({ length: workingHours + 4 }).map((value, index) => (
              <div
                key={`${index}`}
                style={{
                  width: "1px",
                  // Index is doing -2 since we have shifted the overlay to -2 days, check above
                  // parent component absolute positioning
                  height: index + 1 - 2 === currentHours ? "10px" : "3px",
                  borderLeft: `1px solid ${theme.palette.dropTargetBackground.paper}`,
                  marginRight: stepInPx,
                }}
              />
            ))}
          </div>
        )}
      <div {...dragOverHandlers} ref={drop} className={classes.container} />
    </>
  );
}
