import Status from "../Status/Status";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { Box } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import Add from "../Add/Add";
import UpdateDrawer from "../Update/UpdateDrawer";
import { TCanvasProps } from "./types/TCanvasProps";
import { Button } from "../../../../../common/modules/materialUI";
import { useUpdateStatusListService } from "../../../services/useUpdateStatusListService";
import { IStatus } from "../../../../../entities/IStatus";
import { statusFlowActions } from "../../../slices/statusFlowSlice";
import { orgActions } from "../../../../org/slices/orgSlice";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { AggStatusEnum } from "../../../../../entities/columns/task/AggStatusEnum";
import { statusUtil } from "../../../../../common/utils/statusUtil";
import MoveDataFromStatusDialog from "../MoveDataFromStatusDialog/MoveDataFromStatusDialog";
import useForm from "../../../../../common/hooks/useForm/useForm";

export default function Canvas({ project }: TCanvasProps) {
  const dispatch = useAppDispatch();
  const items = useAppSelector((state) => state.statusFlow.statusList);
  const firstTodoItem = items.find(
    (item) => !item.isNew && item.type === AggStatusEnum.TO_DO
  );
  const isDirtyList = useAppSelector(
    (state) => state.statusFlow.updateStatusList.isDirty
  );
  const { getValidationErrorAtIndex } = useForm();

  const { isLoading: isPatchingListUpdate, dispatch: dispatchUpdateList } =
    useUpdateStatusListService({
      projectId: project.id,
    });

  const submitListUpdateHandler = () => {
    dispatchUpdateList({
      body: {
        statusList: items,
      },
    })
      .then((updatedStatuses: IStatus[]) => {
        // Updating statuses in the org init list
        dispatch(
          orgActions.updateProjectStatuses({
            projectId: project.id,
            statuses: updatedStatuses,
          })
        );

        dispatch(
          statusFlowActions.setStatusList(
            statusUtil.convertStatusesToStatusList(updatedStatuses)
          )
        );
        // Updating is dirty in the status flow slice
        dispatch(statusFlowActions.setListIsDirty(false));

        dispatch(
          systemNotificationActions.open({
            message: "Statuses are updated successfully",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        if (error.status === 422) {
          const validationFirstError = getValidationErrorAtIndex(0, error);

          dispatch(
            systemNotificationActions.open({
              message: `Status flow can not be updated. ${validationFirstError}.`,
              variant: "warning",
            })
          );
        } else {
          dispatch(
            systemNotificationActions.open({
              message: "Failed to update status flow. Please try again.",
              variant: "error",
            })
          );
        }
      });
  };

  return (
    <>
      <Add />
      <UpdateDrawer />
      <MoveDataFromStatusDialog />
      {/* Statuses */}
      <Box display="flex" flexDirection="column" gap={2} sx={{ mt: 2 }}>
        <DndProvider backend={HTML5Backend}>
          {items.map((item, index) => (
            <Status
              key={item.id}
              status={item}
              index={index}
              allowDelete={item.id !== firstTodoItem?.id}
            />
          ))}
        </DndProvider>
      </Box>
      {/* Submit button */}
      <Box
        sx={{
          paddingTop: "1rem",
          textAlign: "right",
        }}
      >
        <Button
          disabled={!isDirtyList || isPatchingListUpdate}
          variant="contained"
          type="submit"
          loading={isPatchingListUpdate}
          onClick={submitListUpdateHandler}
        >
          Save
        </Button>
      </Box>
    </>
  );
}
