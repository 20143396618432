import { IUser } from "../../entities/IUser";

export const user = {
  shortName: (user: IUser): string | undefined => {
    const [firstName, lastName] = user.name.split(" ");
    return firstName + (lastName ? ` ${lastName.charAt(0)}.` : "");
  },
  /**
   * id = 0 - stands for unassigned users
   */
  id: (user?: IUser): number => (user ? user.id : 0),

  unassignedEntity: {
    id: 0,
    name: "Unassigned",
  } as IUser,
};
