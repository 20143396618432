import { TEventProps } from "./types/TEventProps";
import { useTheme } from "@mui/material";
import classes from "./Event.module.css";
import { array } from "../../utils/array";
import { date, FORMAT_HOUR_MINUTE } from "../../utils/date";
import { pointConverter } from "../../utils/pointConverter";
import { CSSProperties } from "react";

export default function Event(props: TEventProps) {
  const theme = useTheme();
  const sizeSmall = props.size <= pointConverter.workingHoursToPixels(2);

  const positionStyle: CSSProperties =
    props.positionX || props.positionY
      ? {
          position: "absolute",
          top: props.positionY,
          left: props.positionX,
        }
      : {};

  return (
    <div
      className={array.toClassName([
        classes.container,
        sizeSmall ? classes.small : "",
      ])}
      style={{
        width: props.size,
        backgroundColor: theme.palette.event.background,
        backgroundImage: `url(${theme.palette.event.backgroundImgSrc})`,
        boxShadow: theme.palette.event.shadow,
        color: theme.palette.event.color,
        ...positionStyle,
      }}
    >
      <img src={theme.palette.event.icon} alt="" />
      {!sizeSmall && <p className={classes.name}>{props.name}</p>}
      <p className={classes.info}>
        {date.format(props.time, FORMAT_HOUR_MINUTE)}
      </p>
    </div>
  );
}
