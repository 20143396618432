import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IListResource } from "../../../interfaces/IListResource";
import { useHttpClientDispatchableOnly } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchableOnly";

export const useUserListService = (props?: { roleLimited?: boolean }) => {
  const response = useHttpClientDispatchableOnly({
    url: !props?.roleLimited ? "/user/list/" : "/user/list/roleLimited/",
    method: RequestMethodEnum.GET,
  });

  return {
    ...response,
    data: response.data as IListResource[],
  };
};
