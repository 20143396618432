import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { teamActions } from "../../slices/teamSlice";
import InviteForm from "./InviteForm";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const InviteModal = () => {
  const invitation = useAppSelector((state) => state.team.invitation);
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(teamActions.closeInvitationModal());
  };

  return (
    <Dialog
      open={invitation.displayModal}
      onClose={closeHandler}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Invite a member</DialogTitle>
      <DialogContent>
        <InviteForm onCancel={closeHandler} />
      </DialogContent>
    </Dialog>
  );
};

export default InviteModal;
