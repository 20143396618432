import { useEffect } from "react";

let currentMentions: string[] = [];

export default function useMentionHandler(html: string) {
  useEffect(() => {
    if (html) {
      currentMentions = getMentions(html);
    }

    return () => {
      currentMentions = [];
    };
  }, []);

  const getMentions = (html: string) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    const mentions = tempElement.getElementsByClassName("mention");
    const mentionsArray = Array.from(mentions);

    return mentionsArray.map((mention) => {
      return mention.getAttribute("data-mention-id")!;
    });
  };

  const newMentionsHandler = (html: string) => {
    const newMentions = getMentions(html);
    // "new Set" returns new array with unique values
    return [
      ...new Set(
        newMentions.filter((userId) => {
          return (
            newMentions.filter((newMention) => newMention === userId).length >
            currentMentions.filter(
              (currentMention) => currentMention === userId
            ).length
          );
        })
      ),
    ];
  };

  return {
    fetchCurrentMentions: () => currentMentions,
    fetchNewMentions: (html: string) => newMentionsHandler(html),
  };
}
