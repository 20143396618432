import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IHolisticViewDataRequest } from "../../../interfaces/IHolisticViewDataRequest";
import { IHolisticViewDataResource } from "../../../interfaces/IHolisticViewDataResource";
import { listingActions } from "../../../../../common/modules/listing/slices/listingSlice";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { IFilterRequest } from "../../../../task/interfaces/IFilterRequest";

export const useFetchHolisticViewDataService = (props: {
  ref: string;
  query?: IHolisticViewDataRequest;
  filters?: IFilterRequest;
}) => {
  const dispatch = useAppDispatch();
  const response = useHttpClientDispatchable({
    url: `/board/${props.ref}/holisticView/data`,
    method: RequestMethodEnum.GET,
    query: props.query,
    events: {
      beforeTransform: (data: IHolisticViewDataResource) => {
        data.monitors.length > 0 &&
          dispatch(listingActions.setMonitorList(data.monitors));
        dispatch(listingActions.setSprintList(data.sprints));
        dispatch(
          listingActions.setFilterableCustomFields(data.filterableCustomFields)
        );
      },
    },
  });

  return {
    ...response,
    data: response.data as IHolisticViewDataResource,
  };
};
