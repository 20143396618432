import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { TUseUpdateStatusListServiceProps } from "./types/TUseUpdateStatusListServiceProps";
import { IStatus } from "../../../entities/IStatus";

export const useUpdateStatusListService = ({
  projectId,
}: TUseUpdateStatusListServiceProps) => {
  const response = useHttpClientDispatchable({
    url: `/status/byProject/${projectId}/statusList`,
    method: RequestMethodEnum.PATCH,
  });

  return {
    ...response,
    data: response.data as IStatus[],
  };
};
