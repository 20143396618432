import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TListItem } from "../../../common/types/TListItem";
import { TBreakdownAction } from "./types/TBreakdownAction";
import { TAISentinelState } from "./types/TAISentinelState";
import { IGetCustomFieldsByBoardRefResource } from "../../customField/interfaces/IGetCustomFieldsByBoardRefResource";
import { EntityTypeEnum } from "../../../entities/enums/EntityTypeEnum";

const initialState: TAISentinelState = {
  isOpen: false,
  userMessage: null,
  aiTextMessage: null,
  aiRendered: false,
  breakdownMessage: {
    show: false,
    isLoading: false,
    data: null,
  },
};

const slice = createSlice({
  name: "aiSentinel",
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true;
    },
    toggleOpen: (state) => {
      if (state.isOpen) {
        return { ...initialState, aiRendered: state.aiRendered };
      }

      state.isOpen = true;
    },
    setUserMessage: (state, action: PayloadAction<string>) => {
      state.userMessage = action.payload;
    },
    setAiTextMessage: (state, action: PayloadAction<string | null>) => {
      state.aiTextMessage = action.payload;
    },
    setBreakdownMessageLoading: (state) => {
      state.breakdownMessage.show = true;
      state.breakdownMessage.isLoading = true;
    },
    resetBreakdownMessage: (state) => {
      state.breakdownMessage = initialState.breakdownMessage;
    },
    setBreakdownActions: (state, action: PayloadAction<TBreakdownAction[]>) => {
      state.breakdownMessage.data = {
        actions: action.payload,
        selectedBoard: null,
      };
      state.breakdownMessage.isLoading = false;
    },
    setBreakdownCustomFieldsLoading: (state) => {
      state.breakdownMessage.data!.customFieldData = {
        isLoading: true,
        customFields: [],
        defaultValues: [],
      };
    },
    setBreakdownCustomFieldsData: (
      state,
      action: PayloadAction<{
        customFields: IGetCustomFieldsByBoardRefResource["customFields"];
        defaultValues: Record<string, any>;
      }>
    ) => {
      state.breakdownMessage.data!.customFieldData = {
        isLoading: false,
        customFields: action.payload.customFields,
        defaultValues: action.payload.defaultValues,
      };
    },
    toggleBreakdownActionSelection: (
      state,
      action: PayloadAction<{ index: number }>
    ) => {
      const index = action.payload.index;

      if (!state.breakdownMessage.data?.actions[index]) {
        return;
      }

      state.breakdownMessage.data.actions[index].isSelected =
        !state.breakdownMessage.data.actions[index].isSelected;
    },
    setSelectedBoard: (state, action: PayloadAction<TListItem<string>>) => {
      if (!state.breakdownMessage.data) {
        return;
      }

      state.breakdownMessage.data.selectedBoard = action.payload;
    },
    setIsCreatingActions: (state) => {
      if (!state.breakdownMessage.data) {
        return;
      }

      state.breakdownMessage.data.isCreatingActions = true;
      state.breakdownMessage.data.actionsCreated = false;
    },
    setActionsCreated: (state) => {
      if (!state.breakdownMessage.data) {
        return;
      }

      state.breakdownMessage.data.isCreatingActions = false;
      state.breakdownMessage.data.actionsCreated = true;
    },
    setIsCreatingTask: (
      state,
      action: PayloadAction<{ index: number; isCreating: boolean }>
    ) => {
      const index = action.payload.index;

      if (!state.breakdownMessage.data?.actions[index]) {
        return;
      }

      state.breakdownMessage.data.actions[index].isCreating =
        action.payload.isCreating;
    },
    setCreatedEntityRef: (
      state,
      action: PayloadAction<{
        index: number;
        entityRef: string;
        entityType: EntityTypeEnum;
      }>
    ) => {
      const index = action.payload.index;

      if (!state.breakdownMessage.data?.actions[index]) {
        return;
      }

      state.breakdownMessage.data.actions[index].isCreating = false;
      state.breakdownMessage.data.actions[index].createdEntityRef =
        action.payload.entityRef;
      state.breakdownMessage.data.actions[index].createdEntityType =
        action.payload.entityType;
    },
    toggleAiRendered: (state, action: PayloadAction<boolean>) => {
      state.aiRendered = action.payload;
    },
  },
});

export const aiSentinelReducer = slice.reducer;

export const aiSentinelActions = slice.actions;
