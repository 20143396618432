import { TMenuItemProps } from "../../../../common/containers/Menu/types/TMenuItemProps";
import Menu from "../../../../common/containers/Menu/Menu";
import { MoreVert } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import SettingsMenuItem from "./SettingsMenuItem";
import IntegrationMenuItem from "./IntegrationMenuItem";
import { BoardTypeEnum } from "../../../../entities/columns/board/BoardTypeEnum";
import { TBoardRefProp } from "../../types/TBoardRefProp";
import { boardUtil } from "../../../../common/utils/boardUtil";

type TProps = {
  projectAbbr: string;
  boardType: BoardTypeEnum;
} & TBoardRefProp;

const BoardMenu = (props: TProps) => {
  return (
    <Menu
      button={(clickHandler) => (
        <Tooltip title="Board preferences">
          <IconButton
            onClick={clickHandler}
            sx={{ color: "primary.contrastText" }}
          >
            <MoreVert />
          </IconButton>
        </Tooltip>
      )}
      menuItems={
        [
          ...(boardUtil.isBoardIntegrationAvailableForType(props.boardType)
            ? [IntegrationMenuItem(props)]
            : []),
          SettingsMenuItem(props),
        ] as TMenuItemProps[]
      }
    />
  );
};

export default BoardMenu;
