import { TTaskPriorityState } from "./types/TTaskPriorityState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITaskPriority } from "../../../entities/ITaskPriority";

const initialState: TTaskPriorityState = {
  create: {
    showModal: false,
  },
  update: {
    showModal: false,
    editableRecord: null,
  },
  delete: {
    showConfirmation: false,
    deletableRecord: null,
  },
};

const slice = createSlice({
  name: "taskPriority",
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.create.showModal = true;
    },
    closeCreateModal: (state) => {
      state.create.showModal = false;
    },

    openUpdateModal: (state, action: PayloadAction<ITaskPriority>) => {
      state.update.showModal = true;
      state.update.editableRecord = action.payload;
    },
    closeUpdateModal: (state) => {
      state.update.showModal = false;
      state.update.editableRecord = null;
    },

    openDeleteConfirmation: (state, action: PayloadAction<ITaskPriority>) => {
      state.delete.showConfirmation = true;
      state.delete.deletableRecord = action.payload;
    },
    closeDeleteConfirmation: (state) => {
      state.delete.showConfirmation = false;
      state.delete.deletableRecord = null;
    },
  },
});

export const taskPriorityReducer = slice.reducer;

export const taskPriorityActions = slice.actions;
