import VerifyMail from "../VerifyMail/VerifyMail";
import { TVerifyMailProps } from "../VerifyMail/types/TVerifyMailProps";

const VerifyMailStep = (props: TVerifyMailProps) => {
  return {
    label: "Verify Email",
    content: <VerifyMail {...props} />,
  };
};

export default VerifyMailStep;
