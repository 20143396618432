import { ICustomField } from "../../../entities/ICustomField";
import { TListResource } from "../../../common/types/TListResource";
import { TypeEnum } from "../../../entities/columns/customField/TypeEnum";
import { TCustomFieldValue } from "./types/TCustomFieldValue";

export const customFieldInputUtil = {
  customFieldsAttribute: "customFieldValues",
  getCustomFieldUniqueKey: (customFieldId: number) => `CF-${customFieldId}`,
  generateName: (customField: ICustomField) =>
    `${
      customFieldInputUtil.customFieldsAttribute
    }.${customFieldInputUtil.getCustomFieldUniqueKey(customField.id)}.${
      customField.isMultiple ? "values" : "value"
    }`,
  /**
   * Get object in format:
   * {
   *  "customFieldValues": {
   *    "CF-1": {
   *      "customFieldId": 1,
   *      "value": "Test"
   *    },
   *    "CF-2": {
   *      "customFieldId": 2,
   *      "values": [1, 3, 5]
   *    },
   *    ...
   *  }
   * }
   */
  getCustomFieldFormAttributesTransformed: (
    customFields: ICustomField[],
    formData: Record<string, any>,
    addEmptyValues: boolean = true
  ) => {
    return {
      [customFieldInputUtil.customFieldsAttribute]: customFields.reduce(
        (obj, customField) => {
          const formAttrName = customFieldInputUtil.generateName(customField);
          const formValue = formData[formAttrName] ?? null;
          const transformedValue =
            formValue && customField.type === TypeEnum.OPTIONS
              ? formValue.id
              : formValue;
          const transformedValues =
            customField.isMultiple && formValue
              ? (formValue as TListResource<string>).map((item) => item.id)
              : null;

          // What if we don't want to add fields where value is empty?
          const isValueEmpty = customField.isMultiple
            ? !transformedValues || !transformedValues.length
            : !transformedValue;
          if (isValueEmpty && !addEmptyValues) {
            return { ...obj };
          }

          return {
            ...obj,
            [customFieldInputUtil.getCustomFieldUniqueKey(customField.id)]: {
              customFieldId: customField.id,
              ...(customField.isMultiple
                ? { values: transformedValues }
                : { value: transformedValue }),
            },
          };
        },
        {}
      ),
    };
  },
  getFormAttributeValue: (
    customField: ICustomField,
    customFieldValues: TCustomFieldValue[]
  ) => {
    const values = customFieldValues
      .filter(
        (customFieldValue) =>
          customFieldValue.customFieldId === customField.id &&
          customFieldValue.value !== null
      )
      .map((customFieldValue) =>
        customField.type === TypeEnum.OPTIONS
          ? customField.data?.options.find(
              (option) => option.id === customFieldValue.value
            )
          : customFieldValue.value
      );

    return customField.isMultiple ? values ?? [] : values?.[0] ?? null;
  },
  getFormAttributeValues: (
    customFieldValues: TCustomFieldValue[],
    customFields?: ICustomField[]
  ) => {
    if (!customFields) {
      return {};
    }

    return customFields.reduce((obj, customField) => {
      return {
        ...obj,
        [customFieldInputUtil.generateName(customField)]:
          customFieldInputUtil.getFormAttributeValue(
            customField,
            customFieldValues
          ),
      };
    }, {});
  },
};
