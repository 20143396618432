import { Grid } from "@mui/material";
import Title from "../../../../../common/components/Title/Title";
import { Button } from "../../../../../common/modules/materialUI";
import { useNavigate } from "react-router-dom";

const PasswordResetSuccess = () => {
  const navigate = useNavigate();
  const loginPageHandler = () => {
    navigate("/auth");
  };

  return (
    <Grid item>
      <Title>
        Your password has been successfully reset! Please login to continue.
      </Title>
      <Button variant="text" size="large" onClick={loginPageHandler}>
        Login
      </Button>
    </Grid>
  );
};

export default PasswordResetSuccess;
