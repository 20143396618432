import { Autocomplete } from "../../../../../common/modules/materialUI";
import { TCommonProps } from "./types/TCommonProps";
import { TCreateFormAttributes } from "../types/TCreateFormAttributes";
import { useEffect } from "react";

const SlackChannel = ({ form }: TCommonProps<TCreateFormAttributes>) => {
  useEffect(() => {
    return () => {
      form.changeHandler("notificationChannels", undefined);
    };
  }, []);

  return (
    <Autocomplete
      form={form}
      name="notificationChannels"
      label="Select slack channels"
      options={[
        { id: "tasksensor-alerts", label: "tasksensor-alerts" },
        { id: "dionelabs", label: "dionelabs" },
      ]}
      showRequired
      multiple
    />
  );
};

export default SlackChannel;
