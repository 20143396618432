import { TEditorProps } from "./types/TEditorProps";
import React, { forwardRef } from "react";
import { Editor as TinyMCEEditor } from "@tinymce/tinymce-react";
import { useTheme } from "@mui/material";
import usePreviewUrlHelper from "../../../../../../modules/attachment/modules/preview/hooks/usePreviewUrlHelper";
import { useUserListService } from "../../../../../../modules/user/services/useUserListService";
import { TListResource } from "../../../../../types/TListResource";
import useMedia from "../../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../hooks/useMedia/enums/QueryEnum";
import { config } from "./config";

const Editor = forwardRef(
  (
    {
      html,
      onInit,
      onKeyDown,
      onSave,
      uploadHandler,
      variant,
      autoFocus,
    }: TEditorProps,
    ref: any
  ) => {
    const userListService = useUserListService();
    const { refreshAttachmentImageUrl, resetFailedRetrialsCount } =
      usePreviewUrlHelper();
    const theme = useTheme();
    const themeMode = theme.palette.mode;
    const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);

    const cdnUrl = `${process.env.CDN_URL}/tinymce-assets/${themeMode}`;
    // Can be different per theme
    // @see: https://github.com/dionelabs-io/tasksensor-tinymce-skin
    // The skin configurator app from TinyMCE is pushed to tasksensor-tinymce-skin repo
    const version = theme.palette.mode === "dark" ? "v7" : "v6";
    const skinUrl = `${cdnUrl}/${version}/ui`;
    const contentUrl = `${cdnUrl}/${version}/content/content.min.css`;

    return (
      <TinyMCEEditor
        apiKey={process.env.TINY_MCE_API_KEY}
        onInit={(evt, editor) => {
          ref.current = editor;
          // Declare the editor as ready
          onInit?.();

          autoFocus &&
            setTimeout(() => {
              editor.execCommand("mceFocus");
            }, 200);
        }}
        initialValue={html}
        init={{
          ...config[variant ?? "full"],
          browser_spellcheck: true,
          skin_url: skinUrl,
          content_css: contentUrl,
          branding: false,
          statusbar: false,
          menubar: false,
          block_formats:
            "Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6;",
          link_default_target: "_blank",
          link_context_toolbar: true,
          quickbars_insert_toolbar: false,
          font_size_input_default_unit: "px",
          font_size_formats: "10px 12px 14px 16px 18px 24px 36px 48px",
          help_tabs: [
            "shortcuts", // the default shortcuts tab
            "keyboardnav", // the default keyboard navigation tab
          ],
          content_style: `body { font-family: "Roboto", sans-serif; font-size:${
            isTouchDevice ? 16 : 14
          }px }`,
          images_reuse_filename: true,
          images_upload_handler: (blobInfo, progress) =>
            new Promise((resolve, reject) => {
              const blob = blobInfo.blob();
              uploadHandler?.(
                new File([blob], blobInfo.filename(), {
                  type: blob.type,
                  lastModified: Date.now(),
                }),
                (previewUrl) => resolve(previewUrl!),
                (error) => reject({ message: error, remove: true })
              );
            }),
          setup: function (editor) {
            editor.on("SetContent", function () {
              const contentBody = editor.getBody();
              const images = contentBody.querySelectorAll("img");
              images.forEach((img) => {
                img.onerror = function () {
                  refreshAttachmentImageUrl(img, ["data-mce-src"]);
                };
                img.onload = function () {
                  resetFailedRetrialsCount(img.src);
                };
              });
            });
          },
          mentions_fetch: (
            query: { term: string; meta: {} },
            success: (users: { id: string | number; name: string }[]) => void
          ) => {
            userListService!
              .dispatch({ urlPath: query.term })
              .then((payload: TListResource<string | number>) =>
                success(
                  payload.map((user) => ({ id: user.id, name: user.label }))
                )
              )
              .catch(() => success([]));
          },
          mentions_selector: "span.mention",
          mentions_menu_complete: (
            editor: any,
            userInfo: { id: string | number; name: string }
          ) => {
            const span = editor.getDoc().createElement("span");
            span.className = "mention";
            // store the user id in the mention, so it can be identified later
            span.setAttribute("data-mention-id", userInfo.id as string);
            span.appendChild(
              editor.getDoc().createTextNode("@" + userInfo.name)
            );
            return span;
          },
          save_onsavecallback: onSave,
          // Removing this shortcut to stop the checklist from auto checking/unchecking on Ctrl+Enter
          // This solution is suggested as a workaround by TinyMCE team
          init_instance_callback: (editor) => {
            editor.shortcuts.remove("meta+13");
          },
        }}
        onKeyDown={onKeyDown}
      />
    );
  }
);

export default Editor;
