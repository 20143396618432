import { TColumnsProps } from "./types/TColumnsProps";
import { Grid } from "@mui/material";
import React from "react";
import LinearProgressWithLabel from "../../../../../../common/modules/materialUI/components/LinearProgress/LinearProgressWithLabel";

export default function ProgressColumn({
  initiative,
  fontSize,
  sx,
  extraMessage,
}: TColumnsProps) {
  return (
    <Grid item width={"170px"} sx={sx}>
      {(initiative.progress ?? 0) > 0 && (
        <>
          <LinearProgressWithLabel
            fontSize={fontSize}
            value={initiative.progress ?? 0}
            metadata={{
              doneCount: initiative.doneCount!,
              allCount: initiative.allCount!,
              extraMessage: extraMessage,
            }}
          />
        </>
      )}
    </Grid>
  );
}
