import { useEffect, useState } from "react";
import { TUseMedia } from "./types/TUseMedia";

const useMedia: TUseMedia = (query, callback) => {
  const media = window.matchMedia(query);
  const [matches, setMatches] = useState<boolean>(media.matches);
  const listener = (event: MediaQueryListEvent) => {
    setMatches(event.matches);
    callback && callback(event.matches);
  };

  useEffect(() => {
    callback && callback(media.matches);

    // Some old browsers e.g. safari on iOS 12 and earlier do not have support the "addEventListener", hence ignoring
    // on change listener, still the functionality will take place because of callback() execution,
    // but it will not listen for the resize event on runtime
    if (media.addEventListener) {
      media.addEventListener("change", listener);

      // On cleanup also remove the event listener not to end up calling multiple captured triggers
      return () => {
        media.removeEventListener("change", listener);
      };
    }
  }, [query]);

  return matches;
};

export default useMedia;
