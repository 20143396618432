import { TIconProps } from "./types/TIconProps";
import classes from "./Icon.module.css";
import { Box } from "@mui/material";

const Icon = ({ filePath, isSelected, onSelect, width }: TIconProps) => {
  return (
    <Box className={classes.iconWrapper} onClick={() => onSelect?.(filePath)}>
      <img
        src={`${process.env.CDN_URL}/${filePath}`}
        className={`${classes.iconImg} ${
          isSelected ? classes.iconImgSelected : ""
        }`}
        alt="Icon"
        width={width ?? 40}
        height={width ?? 40}
      />
    </Box>
  );
};

export default Icon;
