import { TBoardViewProps } from "../../../../../board/types/TBoardViewProps";
import ViewContainer from "../../../../containers/ViewContainer/ViewContainer";
import { Card, CardContent } from "@mui/material";
import { useEffect, useRef } from "react";
import classes from "./GuesstimationViewData.module.scss";
import TimeLine from "../../../../../../common/components/TimeLine/TimeLine";
import moment from "moment/moment";
import { useAppSelector } from "../../../../../../common/hooks/redux";
import { pointConverter } from "../../../../../../common/utils/pointConverter";
import { date } from "../../../../../../common/utils/date";
import Lane from "../Lane/Lane";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import LaneItemCustomDragLayer from "../LaneItem/LaneItemCustomDragLayer";
import { GuesstimationViewConfig } from "../../config/Config";
import { IGuesstimationViewDataResource } from "../../../../interfaces/IGuesstimationViewDataResource";
import {
  captureLastContainerRef,
  useVirtualizationHelper,
} from "../../../holisticView/hooks/useVirtualizationHelper";
import { IGuesstimationItem } from "../../../../../../entities/IGuesstimationItem";

type TProps = {
  data: IGuesstimationViewDataResource;
  gridItems: IGuesstimationItem[];
} & TBoardViewProps;

const GuesstimationViewData = ({ data, gridItems, ...props }: TProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const timelineMinDate = moment(
    useAppSelector((state) => state.guesstimationView.timelineDisplayFromDate)
  );
  const timelineMaxDate = moment(
    useAppSelector((state) => state.guesstimationView.timelineDisplayToDate)
  );
  const { scrollToToday } = useVirtualizationHelper();
  const canvasWidth =
    pointConverter.pointToXSizeInPixels() *
    date.intervalToDays(timelineMinDate, timelineMaxDate, true, false);

  useEffect(() => {
    // Set horizontal scroller to be few days back from today's date for better and easier visibility
    if (containerRef && containerRef.current) {
      // Cache the last container reference, so it can be used for later dynamic scroll operations
      captureLastContainerRef(containerRef.current);

      // Scroll to today's date as a starting point
      scrollToToday(timelineMinDate);
    }
  }, [containerRef]);

  return (
    <ViewContainer boardRef={props.boardRef}>
      <Card>
        <CardContent
          ref={containerRef}
          className={`${classes.content}`}
          style={{ padding: `1rem 2rem 0.5rem 0` }}
        >
          <div className={classes["timeline-container"]}>
            <div className={classes["timeline-left"]} />
            <TimeLine
              positionShift={GuesstimationViewConfig.itemSectionWidth}
              startDate={timelineMinDate}
              endDate={timelineMaxDate}
              sprints={data.sprints}
              nonWorkdays={data.nonWorkdays}
            />
          </div>
          <DndProvider backend={HTML5Backend}>
            <LaneItemCustomDragLayer />
            {gridItems.map((guesstimationItem) => (
              <Lane
                key={`t-l-${guesstimationItem.id ?? 0}`}
                minDate={timelineMinDate}
                maxDate={timelineMaxDate}
                width={canvasWidth}
                guesstimationItems={
                  data.guesstimationItems[guesstimationItem.phaseRef]
                }
                users={data.users}
              />
            ))}
          </DndProvider>
        </CardContent>
      </Card>
    </ViewContainer>
  );
};

export default GuesstimationViewData;
