import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";

/**
 * This service will be used with dynamic URL to call the install/uninstall resource endpoint
 * for different integrations.
 * E.g. slack, jira, github, etc.
 */
export const useConfigureIntegrationService = () =>
  useHttpClientDispatchable({
    url: "",
    method: RequestMethodEnum.POST,
  });
