import { Box } from "@mui/material";
import { DataGrid } from "../../../../common/modules/materialUI";
import Loader from "../../../../common/containers/Loader/Loader";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { gridClasses } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { useOrgProjectData } from "../../../org/hooks/useOrgProjectData";
import { useFetchSprintListService } from "../../services/useFetchSprintListService";
import { sprintActions } from "../../slices/sprintSlice";
import { Columns } from "./Columns";
import DeleteConfirmationDialog from "../DeleteSprint/DeleteConfirmationDialog";
import UpdateModal from "../UpdateSprint/UpdateModal";

const SprintGrid = () => {
  const { projectAbbr } = useParams();
  const project = useOrgProjectData().findProjectByAbbr(projectAbbr!)?.project;
  const { isLoading } = useFetchSprintListService({ projectId: project?.id! });
  const sprintList = useAppSelector((state) => state.sprint.list);
  const dispatch = useAppDispatch();

  return (
    <Box>
      <UpdateModal />
      <DeleteConfirmationDialog />
      <DataGrid
        columns={Columns({
          onEditButtonClick: (sprint) =>
            dispatch(sprintActions.openUpdateModal(sprint)),
          onDeleteButtonClick: (sprint) =>
            dispatch(sprintActions.openDeleteConfirmation(sprint)),
        })}
        getRowHeight={() => "auto"}
        rows={sprintList ?? []}
        disableRowSelectionOnClick
        checkboxSelection={false}
        autoHeight={!sprintList || !sprintList.length}
        loading={sprintList === null || isLoading}
        sx={{
          [`& .${gridClasses.row}`]: {
            minHeight: `52px !important`,
          },
        }}
      />
      {isLoading && <Loader />}
    </Box>
  );
};

export default SprintGrid;
