import { useFetchCustomFieldsByBoardRefService } from "../services/useFetchCustomFieldsByBoardRefService";
import { useEffect } from "react";

export default function useFetchCustomFieldData(
  boardRef: string,
  execute?: boolean,
  setHavingCustomFields?: React.Dispatch<React.SetStateAction<boolean>>
) {
  const {
    isLoading: isCustomFieldsLoading,
    dispatch: dispatchFetchCustomFields,
    data: customFieldsResource,
  } = useFetchCustomFieldsByBoardRefService({
    boardRef: boardRef,
  });
  const length = customFieldsResource?.customFields.length;

  useEffect(() => {
    (execute ?? true) && dispatchFetchCustomFields();
  }, []);

  useEffect(() => {
    setHavingCustomFields?.(!!length);
  }, [length]);

  return {
    isCustomFieldsLoading,
    customFieldsResource,
    length,
  };
}
