import { PeopleAlt } from "@mui/icons-material";
import TListItemProps from "../../../../../../../components/Navigation/ListItem/types/TListItemProps";
import { TSectionProps } from "../../../types/TSectionProps";

const TeamsListItem = (props: TSectionProps) =>
  ({
    id: "TeamsListItem",
    name: "Teams",
    icon: <PeopleAlt />,
    path: "/team",
    navigateToPath: true,
    onNavigate: props.onClick,
  } as TListItemProps);

export default TeamsListItem;
