import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { taskActions } from "../../slices/taskSlice";
import CreateForm from "./CreateForm";
import { TCreateModalProps } from "./types/TCreateModalProps";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useSubscribeEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import { InternalTypeEnum } from "../../../../entities/columns/task/InternalTypeEnum";

const CreateModal = (props: TCreateModalProps) => {
  const taskLabel = useAppSelector((state) => state.customs.taskLabel);
  const creation = useAppSelector((state) => state.task.creation);
  const internalType = creation.internalType ?? InternalTypeEnum.TASK;
  const modalLabel =
    internalType === InternalTypeEnum.TASK ? taskLabel : internalType;
  const dispatch = useAppDispatch();

  useSubscribeEvent(EventEnum.ON_TASK_CREATED, () => closeHandler());

  const closeHandler = () => {
    dispatch(taskActions.closeCreationModal());
  };

  return (
    <Dialog
      open={creation.displayModal}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      {/* Render content only if displayModal is enabled, otherwise we might have incorrect data when closing the dialog
       and animation didn't finish yet, e.g. title will show incorrect value when closing the dialog animation is running */}
      {creation.displayModal && (
        <>
          <DialogTitle>Create {modalLabel}</DialogTitle>
          <DialogContent>
            <CreateForm
              onCancel={closeHandler}
              boardRef={props.boardRef}
              renderOptionalFields={props.renderOptionalFields}
              internalType={internalType}
            />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default CreateModal;
