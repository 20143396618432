import { TEventListener } from "./types/TEventListener";
import { TEventDispatch } from "./types/TEventDispatch";
import { TListener } from "./types/TListener";
import { TEvent } from "./types/TEvent";

class EventProvider {
  static listeners: { [key: TEvent]: TListener[] } = {};

  static dispatch: TEventDispatch = (event, payload, callback) => {
    (this.listeners[event] ?? []).forEach((listener) =>
      listener(payload, callback)
    );
  };

  static addEventListener: TEventListener = (event, listener) => {
    const eventListeners = this.listeners[event] ?? [];
    eventListeners.push(listener);

    this.listeners[event] = eventListeners;
  };

  static removeEventListener: TEventListener = (event, listener) => {
    const eventListeners = this.listeners[event] ?? [];

    this.listeners[event] = eventListeners.filter(
      (registeredListener) => registeredListener !== listener
    );
  };
}

export default EventProvider;
