import { Skeleton } from "../../../../../../common/modules/materialUI";
import classes from "./View.module.scss";
import timelineClasses from "../../../../../../common/components/TimeLine/TimeLine.module.css";
import userLaneClasses from "../UserLane/UserLane.module.scss";
import { ViewHelper } from "./helpers/ViewHelper";
import { useMemo } from "react";
import { Card, CardContent } from "@mui/material";

const ViewSkeleton = () => {
  return (
    <Card>
      <CardContent sx={{ py: 2, display: "flex", justifyContent: "flex-end" }}>
        <Skeleton variant="text" width="30%" />
      </CardContent>
      <CardContent
        className={`${classes.content}`}
        style={{ padding: `0.5rem 2rem 0.5rem 0` }}
      >
        <div className={timelineClasses.container}>
          {Array.from({ length: 9 }).map((value, index) => (
            <div
              key={`timeline-skeleton-${index}`}
              style={{
                minWidth: ViewHelper.columnWidth(),
              }}
            >
              <Skeleton
                variant="text"
                width="40%"
                style={{ margin: "0 auto" }}
              />
              <Skeleton
                variant="text"
                width="30%"
                style={{ margin: "0 auto" }}
              />
            </div>
          ))}
        </div>
        {useMemo(
          () =>
            Array.from({ length: 4 }).map((value, index) => (
              <div
                key={`user-lane-skeleton-${index}`}
                className={userLaneClasses.container}
              >
                <div className={userLaneClasses.user}>
                  <div>
                    <Skeleton variant="text" width={70} />
                  </div>
                </div>
                <div className={userLaneClasses["task-lane"]}>
                  <Skeleton
                    style={{
                      position: "absolute",
                      left: Math.floor(Math.random() * 200),
                      height: "83%",
                    }}
                    variant="rounded"
                    width={Math.floor(Math.random() * 700) + 100}
                  />
                </div>
              </div>
            )),
          []
        )}
      </CardContent>
    </Card>
  );
};

export default ViewSkeleton;
