import { TCreateSprintProps } from "./types/TCreateSprintProps";
import CreateModal from "./CreateModal";

const CreateSprint = (props: TCreateSprintProps) => (
  <CreateModal
    projectAbbr={props.projectAbbr}
    isSprintPage={props.isSprintPage}
  />
);

export default CreateSprint;
