import { useState } from "react";

export default function useCopyLink(url: string, holdStateFor: number = 2000) {
  const [linkCopied, setLinkCopied] = useState(false);

  return {
    linkCopied,
    copyLinkHandler: () => {
      setLinkCopied((prevState) => !prevState);
      navigator.clipboard.writeText(`${window.location.origin}${url}`);
      setTimeout(() => {
        setLinkCopied(false);
      }, holdStateFor);
    },
  };
}
