import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TListingState } from "./types/TListingState";
import { IMonitor } from "../../../../entities/IMonitor";
import { ISprint } from "../../../../entities/ISprint";
import { TListItem } from "../../../types/TListItem";
import { ICustomField } from "../../../../entities/ICustomField";

const initialState: TListingState = {};

const entitiesToList = (entities: any[]) =>
  entities.map((entity) => entityToListItem(entity));

const entityToListItem = (entity: any) =>
  ({
    id: entity.ref!,
    label: entity.name,
  } as TListItem<string>);

const slice = createSlice({
  name: "listing",
  initialState,
  reducers: {
    setMonitorList: (state, action: PayloadAction<IMonitor[]>) => {
      state.monitorList = entitiesToList(action.payload);
    },
    addToMonitorList: (state, action: PayloadAction<IMonitor>) => {
      state.monitorList?.push(entityToListItem(action.payload));
    },
    setSprintList: (state, action: PayloadAction<ISprint[]>) => {
      state.sprintList = entitiesToList(action.payload);
    },
    addToSprintList: (state, action: PayloadAction<ISprint>) => {
      state.sprintList?.push(entityToListItem(action.payload));
    },
    setFilterableCustomFields: (
      state,
      action: PayloadAction<ICustomField[]>
    ) => {
      // deep copy because of state read only issues
      const customFields: ICustomField[] = JSON.parse(
        JSON.stringify(action.payload)
      );
      customFields.map((customField) => {
        if (customField.data?.options) {
          customField.data.options = JSON.parse(
            customField.data.options as unknown as string
          );
        }
      });

      state.filterableCustomFieldList = customFields;
    },
  },
});

export const listingReducer = slice.reducer;

export const listingActions = slice.actions;
