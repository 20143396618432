import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";

export const useAttachmentDownloadService = (props: {
  attachmentId: number;
}) => {
  return useHttpClientDispatchable({
    url: `/attachment/${props.attachmentId}/downloadUrl`,
    method: RequestMethodEnum.GET,
  });
};
