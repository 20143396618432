import { Box, IconButton } from "@mui/material";
import Section from "./Section";
import Menu from "../Menu/Menu";
import ExpandableIcon from "../../components/Icon/Expandable/ExpandableIcon";
import { MoreHoriz } from "@mui/icons-material";
import { TSubPickerProps } from "./types/TSubPickerProps";
import { menuItem } from "../Menu/utils/menuItem";

const DropdownPicker = (props: TSubPickerProps) => {
  const secondary = props.secondary ?? false;

  return (
    <Menu
      stretchOnMobile={false}
      closeOnSelection={true}
      button={(clickHandler) => {
        const section = props.sections.find(
          (section) => section.value === props.selection
        );

        return section ? (
          <Box onClick={clickHandler} display="flex" alignItems="center">
            <Section
              {...section}
              secondary={secondary}
              onClick={() => {}}
              displayText={props.renderDropdownText ?? true}
            />
            <ExpandableIcon direction="down" size="md" />
          </Box>
        ) : (
          <IconButton onClick={clickHandler}>
            <MoreHoriz />
          </IconButton>
        );
      }}
      menuItems={props.sections.map((section, index) =>
        menuItem.make(
          <Section
            {...section}
            secondary={secondary}
            style={{ padding: 0 }}
            displayText
            onClick={() => {
              props.onClick(section.value);
              section.onClick && section.onClick();
            }}
          />,
          undefined,
          section.render !== false
        )
      )}
    />
  );
};

export default DropdownPicker;
