import { TCustomFieldInputProps } from "./types/TCustomFieldInputProps";
import { TypeEnum } from "../../../../entities/columns/customField/TypeEnum";
import CustomTextField from "./CustomTextField/CustomTextField";
import CustomOptionsField from "./CustomOptionsField/CustomOptionsField";
import CustomDateField from "./CustomDateField/CustomDateField";

const CustomFieldInput = <TFormAttributes extends unknown>(
  props: TCustomFieldInputProps<TFormAttributes>
) => {
  switch (props.customField.type) {
    case TypeEnum.TEXT_FIELD:
      return <CustomTextField {...props} />;

    case TypeEnum.OPTIONS:
      return <CustomOptionsField {...props} />;

    case TypeEnum.DATE:
      return <CustomDateField {...props} />;
  }
};

export default CustomFieldInput;
