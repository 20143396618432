import { TBacklogItemMenuProps } from "./types/TBacklogItemMenuProps";
import Menu from "../../../../../../common/containers/Menu/Menu";
import { IconButton, Tooltip } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { TMenuItemProps } from "../../../../../../common/containers/Menu/types/TMenuItemProps";
import BacklogItemLinkMenuItem from "./BacklogItemLinkMenuItem";
import MoveToTaskMenuItem from "./MoveToTaskMenuItem";

const BacklogItemMenu = (props: TBacklogItemMenuProps) => {
  return (
    <Menu
      stretchOnMobile={false}
      button={(clickHandler) => (
        <Tooltip title="More options">
          <IconButton onClick={clickHandler}>
            <MoreHoriz sx={{ fontSize: props.fontSize ?? "1rem" }} />
          </IconButton>
        </Tooltip>
      )}
      menuItems={
        [
          BacklogItemLinkMenuItem(props),
          MoveToTaskMenuItem(props),
        ] as TMenuItemProps[]
      }
    />
  );
};

export default BacklogItemMenu;
