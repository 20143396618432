import { TOccupiedSpaceProps } from "./types/TOccupiedSpaceProps";
import { useTheme } from "@mui/material";
import classes from "./OccupiedSpaceSmall.module.css";
import { TaskHelper } from "../Task/helpers/TaskHelper";
import { array } from "../../utils/array";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Link from "./Link";

export default function OccupiedSpaceSmall(props: TOccupiedSpaceProps) {
  const theme = useTheme();
  const sizeXs = TaskHelper.sizeXs(props.size);

  return (
    <div
      className={array.toClassName([classes.main])}
      style={{
        width: props.size,
        backgroundImage: `url(${theme.palette.occupied.backgroundImgSrc})`,
        color: theme.palette.occupied.color,
        ...props.style,
      }}
    >
      <div className={array.toClassName([classes.warning])}>
        <p title={props.taskRef}>
          {!sizeXs ? "Occupied" : <Link {...props}>Occupied</Link>}
        </p>
        {!sizeXs && (
          <p className={classes.ref} title={props.taskRef}>
            <Link {...props}>{props.taskRef}</Link>
          </p>
        )}
      </div>
      <WarningRoundedIcon />
    </div>
  );
}
