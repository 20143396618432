import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { ITaskDetailsResource } from "../interfaces/ITaskDetailsResource";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { taskActions } from "../../../slices/taskSlice";
import { linkActions } from "../../../../link/slices/linkSlice";

export const useGetTaskService = () => {
  const dispatch = useAppDispatch();

  const response = useHttpClientDispatchable({
    url: `/task`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: ITaskDetailsResource) => {
        dispatch(taskActions.setData(data));
        dispatch(linkActions.setLinkList(data.links));
        dispatch(taskActions.setStatusReason(data.statusReason));
      },
    },
  });

  return {
    ...response,
    data: response.data as ITaskDetailsResource,
  };
};
