import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClientDispatchableOnly } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchableOnly";

export const useAttachmentPreviewUrlDispatchableOnlyService = (props: {
  attachmentId?: number;
}) =>
  useHttpClientDispatchableOnly({
    url: `/attachment/${props.attachmentId}/previewUrl`,
    method: RequestMethodEnum.GET,
  });
