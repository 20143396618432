import { TTypeProps } from "./types/TTypeProps";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import React from "react";
import { Button, Tooltip } from "../../../../common/modules/materialUI";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { useSetBoardTypeService } from "../../services/useSetBoardTypeService";
import { ISetBoardTypeRequest } from "../../interfaces/ISetBoardTypeRequest";
import { orgActions } from "../../../org/slices/orgSlice";

const Type = (props: TTypeProps) => {
  const dispatch = useAppDispatch();
  const { dispatch: dispatchSetBoardType, isLoading } = useSetBoardTypeService(
    props.boardRef
  );

  const setBoardTypeHandler = () => {
    dispatchSetBoardType({
      body: { type: props.type } as ISetBoardTypeRequest,
    }).then(() =>
      dispatch(
        orgActions.updateBoardData({
          boardRef: props.boardRef,
          projectAbbr: props.projectAbbr,
          type: props.type,
        })
      )
    );
  };

  return (
    <Card sx={{ maxWidth: 400 }}>
      <CardContent sx={{ height: 110, textAlign: "center" }}>
        <img src={props.imgSrc} width="80" height="80" alt="" />
      </CardContent>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props.desc}
        </Typography>
      </CardContent>
      <CardActions>
        {!props.comingSoon ? (
          <Button
            size="small"
            loading={isLoading}
            disabled={isLoading}
            onClick={setBoardTypeHandler}
          >
            Use Board
          </Button>
        ) : (
          <Tooltip
            title={
              <Typography variant="subtitle1">
                We are excited to announce that support for this board will be
                available soon. Stay tuned for updates and get ready to elevate
                your productivity to new heights with our cutting-edge
                technology.
              </Typography>
            }
          >
            <div>
              <Button size="small" disabled>
                Use Board
              </Button>
            </div>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
};

export default Type;
