import TListItemProps from "../../../../../../components/Navigation/ListItem/types/TListItemProps";
import { DriveFileRenameOutline } from "@mui/icons-material";
import { TSectionProps } from "../../types/TSectionProps";

const CustomFieldsListItem = (props: TSectionProps) =>
  ({
    id: "CustomFieldsListItem",
    name: "Custom Fields",
    icon: <DriveFileRenameOutline />,
    path: "/settings/customFields",
    navigateToPath: true,
    onNavigate: props.onClick,
    isDisabled: props.isDisabled,
    disabledTooltipMessage: props.disabledTooltipMessage,
  } as TListItemProps);

export default CustomFieldsListItem;
