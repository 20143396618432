import { TCreateFormProps } from "./types/TCreateFormProps";
import useForm from "../../../../common/hooks/useForm/useForm";
import { TCreateFormAttributes } from "./types/TCreateFormAttributes";
import Form from "../../../../common/components/Form/Form";
import {
  Button,
  DatePicker,
  TextField,
} from "../../../../common/modules/materialUI";
import { useCreateObjectiveService } from "../../services/useCreateObjectiveService";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { objectiveActions } from "../../slices/objectiveSlice";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { IObjective } from "../../../../entities/IObjective";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";

const CreateForm = (props: TCreateFormProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const dispatch = useAppDispatch();
  const { isLoading, dispatch: dispatchCreateObjectiveService } =
    useCreateObjectiveService();
  const form = useForm<TCreateFormAttributes>();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    dispatchCreateObjectiveService({
      body: form.data,
    })
      .then((payload: IObjective) => {
        dispatch(objectiveActions.addToList(payload));
        dispatch(objectiveActions.closeCreateModal());
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Add
          </Button>
        </>
      }
    >
      <TextField
        forceFocus={!isTouchDevice}
        form={form}
        name="name"
        label="Name"
        showRequired
      />
      <DatePicker form={form} name="startDate" label="Start Date" allowClear />
      <DatePicker form={form} name="endDate" label="End Date" allowClear />
    </Form>
  );
};

export default CreateForm;
