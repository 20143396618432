import React from "react";
import { useAppSelector } from "../../../../../common/hooks/redux";
import { Grid, useTheme } from "@mui/material";
import moment from "moment/moment";
import { Task } from "../../../../../common/components/Task";
import Section from "../../../../../common/components/Section/Section";

const UpcomingSection = () => {
  const theme = useTheme();
  const upcomingTasks = useAppSelector(
    (state) => state.priorityView.upcomingTaskList
  );

  return (
    <>
      {upcomingTasks.length > 0 && (
        <Section
          title={`Upcoming from ${moment(upcomingTasks[0].startDate).format(
            "MMM D, YYYY"
          )}`}
          sx={{ mt: 5 }}
        >
          <Grid container spacing={2}>
            {upcomingTasks.map((task, index) => (
              <Grid key={index} item xs={12} sm={12} lg={4}>
                <Task task={task} />
              </Grid>
            ))}
          </Grid>
        </Section>
      )}
    </>
  );
};

export default UpcomingSection;
