import { guesstimationItemViewActions } from "../../slices/viewSlice";
import { Add } from "@mui/icons-material";
import { Button } from "../../../../../../common/modules/materialUI";
import React from "react";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { IGuesstimationItem } from "../../../../../../entities/IGuesstimationItem";

type TProps = {
  guesstimationItem: IGuesstimationItem;
};

export default function AddAssigneesButton({ guesstimationItem }: TProps) {
  const dispatch = useAppDispatch();

  return (
    <Button
      onClick={() => {
        dispatch(
          guesstimationItemViewActions.openAddAssigneesDialog({
            item: guesstimationItem,
          })
        );
      }}
      size="small"
      sx={{
        minWidth: "55px",
        fontSize: "12px",
        textTransform: "capitalize",
      }}
    >
      <Add sx={{ fontSize: "inherit" }} /> Add
    </Button>
  );
}
