import { TCreateFormProps } from "./types/TCreateFormProps";
import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import { IMonitor } from "../../../../entities/IMonitor";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { useCreateMonitorService } from "../../services/useCreateMonitorService";
import { monitorActions } from "../../slices/monitorSlice";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { useUserListService } from "../../../user/services/useUserListService";
import {
  Autocomplete,
  Button,
  DatePicker,
  TextField,
  Tooltip,
} from "../../../../common/modules/materialUI";
import { listingActions } from "../../../../common/modules/listing/slices/listingSlice";
import { Grid, Typography } from "@mui/material";
import { TypeEnum } from "../../../../entities/columns/monitor/TypeEnum";
import { enumUtil } from "../../../../common/utils/enum";
import { InfoOutlined } from "@mui/icons-material";
import { NotificationMethodEnum } from "../../../../entities/enums/NotificationMethodEnum";
import { TCreateFormAttributes } from "./types/TCreateFormAttributes";
import { TCreateFormAttributesTransformed } from "./types/TCreateFormAttributesTransformed";
import { ThresholdOperatorEnum } from "../../../../entities/enums/ThresholdOperatorEnum";
import { FormHelper } from "./helpers/FormHelper";
import NotifyThreshold from "./fields/NotifyThreshold";
import SlackChannel from "./fields/SlackChannel";
import NotificationMethod from "./fields/NotificationMethod";

const CreateForm = (props: TCreateFormProps) => {
  const dispatch = useAppDispatch();
  const thresholdsDefaultAttributes = {
    firstThresholdEnabled: false,
    firstThresholdValue: "70",
    firstThresholdOperator: enumUtil.toListItem(ThresholdOperatorEnum.PERCENT),
    firstThresholdCustomized: false,
    secondThresholdEnabled: true,
    secondThresholdValue: "100",
    secondThresholdOperator: enumUtil.toListItem(ThresholdOperatorEnum.PERCENT),
    secondThresholdCustomized: false,
  };
  const form = useForm<TCreateFormAttributes>({
    boardRef: props.boardRef,
    ...thresholdsDefaultAttributes,
  });
  const formHelper = FormHelper.withForm(form.data);

  const { isLoading, dispatch: dispatchCreateMonitor } =
    useCreateMonitorService();
  const userListService = useUserListService();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreateMonitor({
      body: form.getTransformed<TCreateFormAttributesTransformed>((data) => ({
        ...data,
        firstThresholdOperator: data.firstThresholdOperator.id,
        secondThresholdOperator: data.secondThresholdOperator.id,
        reps: data.reps?.map((rep) => rep.id),
        type: data.type?.id,
        notificationMethods: data.notificationMethods?.map(
          (method) => method.id
        ),
        notificationChannels: data.notificationChannels?.map(
          (notificationChannel) => ({
            method: NotificationMethodEnum.SLACK, // Currently only slack method has channels
            channel: notificationChannel.id,
          })
        ),
      })),
    })
      .then((payload: IMonitor) => {
        dispatch(monitorActions.closeCreationModal());
        dispatch(listingActions.addToMonitorList(payload));
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Add
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField form={form} name="name" label="Name" showRequired />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            multiple
            form={form}
            name="reps"
            label="Representatives"
            service={userListService}
            showRequired={formHelper.hasSelectedUserNotificationMethod()}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Autocomplete
                form={form}
                name="type"
                label="type"
                options={enumUtil.toList(TypeEnum)}
                showRequired
                onChange={(event, value) => {
                  formHelper.shouldResetValue(value?.id, form.data?.type?.id) &&
                    form.set({
                      value: undefined,
                      ...thresholdsDefaultAttributes,
                    } as TCreateFormAttributes);
                }}
              />
            </Grid>
            <Grid item xs={8}>
              {formHelper.renderTextForValue() && (
                <TextField
                  form={form}
                  name="value"
                  label={`${form.data?.name ?? ""} value`}
                  showRequired
                  disabled={!form.data?.type}
                />
              )}
              {formHelper.renderDateForValue() && (
                <DatePicker
                  form={form}
                  name="value"
                  label={`${form.data?.name ?? ""} value`}
                  showRequired
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} display="flex" alignItems="flex-end">
          <Tooltip
            title="With exception of *Start date and *Due date for which the system
              generated values will be used."
          >
            <Typography sx={{ color: "text.secondary" }} variant="caption">
              Every task with this monitor attached, will have a new required
              field of the selected type to enter the correlative value.
              <InfoOutlined fontSize="inherit" />
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>

      <div>
        <NotifyThreshold
          form={form}
          thresholdEnabledAttr="firstThresholdEnabled"
          thresholdValueAttr="firstThresholdValue"
          thresholdOperatorAttr="firstThresholdOperator"
          thresholdCustomizedAttr="firstThresholdCustomized"
        />

        <NotifyThreshold
          form={form}
          thresholdEnabledAttr="secondThresholdEnabled"
          thresholdValueAttr="secondThresholdValue"
          thresholdOperatorAttr="secondThresholdOperator"
          thresholdCustomizedAttr="secondThresholdCustomized"
        />
      </div>

      {formHelper.hasNotificationRequested() && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <NotificationMethod form={form} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container display="flex" direction="column">
              {!formHelper.hasSelectedReps() &&
                formHelper.hasSelectedUserNotificationMethod() && (
                  <Grid item>
                    <Typography
                      sx={{ color: "warning.main" }}
                      variant="caption"
                    >
                      To notify using any of the{" "}
                      <strong>
                        {NotificationMethodEnum.SMS},{" "}
                        {NotificationMethodEnum.PHONE_CALL},{" "}
                        {NotificationMethodEnum.EMAIL}{" "}
                      </strong>
                      you must select at least one representative.
                    </Typography>
                  </Grid>
                )}

              {formHelper.hasSelectedSlackNotificationMethod() && (
                <Grid item>
                  <SlackChannel form={form} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Form>
  );
};

export default CreateForm;
