import { TCheckboxProps } from "./types/TCheckboxProps";
import { Checkbox as MuiCheckbox } from "@mui/material";

const Checkbox = <TFormAttributes extends unknown>(
  props: TCheckboxProps<TFormAttributes>
) => {
  const { form, ...checkboxProps } = props;
  const { name } = checkboxProps;

  return (
    <MuiCheckbox
      checked={(!!name && form?.get(name as keyof TFormAttributes)) ?? false}
      onChange={(e) => !!name && form?.changeHandler(name, e.target.checked)}
      size="small"
      {...checkboxProps}
    />
  );
};

export default Checkbox;
