import TListItemProps from "../../../../../../../components/Navigation/ListItem/types/TListItemProps";
import { SmartToy } from "@mui/icons-material";
import { TSectionProps } from "../../../types/TSectionProps";

const IntegrationListItem = (props: TSectionProps) =>
  ({
    id: "IntegrationsListItem",
    name: "App Integrations",
    icon: <SmartToy />,
    path: "/integration/list",
    navigateToPath: true,
    onNavigate: props.onClick,
  } as TListItemProps);

export default IntegrationListItem;
