export const keyboard = {
  onControlEnter: (
    event: React.KeyboardEvent,
    action: (event: React.KeyboardEvent) => void
  ) => {
    if (keyboard.isControlEnterPressed(event)) {
      action(event);
    }
  },
  onEnter: (
    event: React.KeyboardEvent,
    action: (event: React.KeyboardEvent) => void
  ) => {
    if (keyboard.isEnterPressed(event)) {
      action(event);
    }
  },
  isControlPressed: (event: any) => event.ctrlKey || event.metaKey,
  isShiftPressed: (event: any) => event.shiftKey,
  isAltPressed: (event: any) => event.altKey,
  isEnterPressed: (event: any) =>
    event.code === "Enter" ||
    event.code === "NumpadEnter" ||
    event.keyCode === 13,
  isControlEnterPressed: (event: React.KeyboardEvent) =>
    keyboard.isControlPressed(event) && keyboard.isEnterPressed(event),
  isControlSavePressed: (event: React.KeyboardEvent) =>
    keyboard.isControlPressed(event) && event.key === "s",
  isActiveElementEditable: () => {
    const activeElement = document.activeElement as HTMLElement;
    return (
      activeElement &&
      (activeElement.tagName === "INPUT" ||
        activeElement.tagName === "SELECT" ||
        activeElement.tagName === "TEXTAREA" ||
        activeElement.isContentEditable)
    );
  },
  isSingleKeyPressed: (event: any, key: string) =>
    !keyboard.isControlPressed(event) &&
    !keyboard.isShiftPressed(event) &&
    !keyboard.isAltPressed(event) &&
    (event.key === key || event.key === key.toUpperCase()),
  getControlKeyLabel: () =>
    window.navigator.userAgent.indexOf("Mac") != -1 ? "Cmd" : "Ctrl",
};
