import useMedia from "../../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../hooks/useMedia/enums/QueryEnum";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { THeaderSchemaProps } from "./types/THeaderSchemaProps";

const HeaderSchema = (props: THeaderSchemaProps) => {
  const matchesMax992Screen = useMedia(QueryEnum.MAX_WIDTH_992);

  return !matchesMax992Screen ? <Desktop {...props} /> : <Mobile {...props} />;
};

export default HeaderSchema;
