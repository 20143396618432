import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { TUseDeleteTaskTypeServiceProps } from "./types/TUseDeleteTaskTypeServiceProps";

export const useDeleteTaskTypeService = ({
  taskTypeId,
}: TUseDeleteTaskTypeServiceProps) => {
  return useHttpClientDispatchable({
    url: `/taskType/${taskTypeId}`,
    method: RequestMethodEnum.DELETE,
  });
};
