import { TextField } from "../../../../../common/modules/materialUI";
import { customFieldInputUtil } from "../../../utils/customFieldInputUtil";
import { TCustomFieldInputProps } from "../types/TCustomFieldInputProps";

const CustomTextField = <TFormAttributes extends unknown>({
  customField,
  form,
  showLabel,
  submitHandler,
  hideRequiredMark,
}: TCustomFieldInputProps<TFormAttributes>) => {
  const name = customFieldInputUtil.generateName(customField);

  return (
    <TextField
      form={form}
      name={name as string}
      label={showLabel ? customField.name : undefined}
      showRequired={!hideRequiredMark && customField.isRequired}
      onEnter={(_, ref) => ref.current?.blur()}
      onBlur={() => {
        submitHandler &&
          submitHandler(form?.get(name as keyof TFormAttributes));
      }}
    />
  );
};

export default CustomTextField;
