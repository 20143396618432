import { ConfirmationDialog } from "../../../../common/modules/materialUI";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { useDeleteSprintService } from "../../services/useDeleteSprintService";
import { sprintActions } from "../../slices/sprintSlice";

const DeleteConfirmationDialog = () => {
  const { showConfirmation, deletableRecord } = useAppSelector(
    (state) => state.sprint.delete
  );
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);
  const dispatch = useAppDispatch();
  const { isLoading, dispatch: dispatchDelete } = useDeleteSprintService({
    sprintId: +deletableRecord?.id!,
  });

  const closeConfirmationHandler = () =>
    dispatch(sprintActions.closeDeleteConfirmation());

  const successHandler = () => {
    dispatch(sprintActions.closeDeleteConfirmation());

    dispatchDelete()
      .then(() => {
        dispatch(sprintActions.removeFromList({ id: +deletableRecord?.id! }));
        dispatch(
          systemNotificationActions.open({
            message: `${sprintLabel} is deleted successfully`,
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        if (error.status === 422) {
          dispatch(
            systemNotificationActions.open({
              message: `The ${sprintLabel} "${deletableRecord?.name}" cannot be deleted. Please contact support.`,
              variant: "error",
            })
          );
        }
      });
  };

  return (
    <ConfirmationDialog
      open={showConfirmation}
      title={`Delete ${sprintLabel}`}
      content={`Are you sure you want to delete the ${sprintLabel} "${deletableRecord?.name}"?`}
      loading={isLoading}
      closeHandler={closeConfirmationHandler}
      successHandler={successHandler}
    />
  );
};

export default DeleteConfirmationDialog;
