import SortIcon from "@mui/icons-material/Sort";
import {
  Badge,
  Box,
  Grid,
  IconButton,
  SxProps,
  Typography,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { boardQueryActions } from "../../../../slices/boardQuerySlice";
import Menu from "../../../../../../common/containers/Menu/Menu";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import { Close } from "@mui/icons-material";

const sortingItems = [
  {
    id: "taskTypeId",
    label: "Task Type",
  },
  {
    id: "taskPriorityId",
    label: "Task Priority",
  },
];

const Sorting = () => {
  const dispatch = useAppDispatch();
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);
  const sort = useAppSelector((state) => state.boardQuery.sorting);
  const menuItemValue = sort && {
    id: sort.column,
    label: sortingItems.find((item) => item.id === sort.column)!.label,
    sortDirection: sort.direction,
  };
  const iconSx: SxProps = {
    fontSize: "0.8rem",
  };

  const menuItems = [
    ...(menuItemValue
      ? [
          menuItem.make(
            <Typography
              sx={{ color: "text.secondary", fontSize: "0.8rem" }}
              display="flex"
              alignItems="center"
              gap={0.5}
            >
              <Close sx={iconSx} /> Clear Sorting
            </Typography>,
            () => dispatch(boardQueryActions.setSorting())
          ),
        ]
      : []),
    ...sortingItems.map((sortingItem) => {
      const isSelected = menuItemValue?.id === sortingItem.id;
      const selectedSortIcon =
        menuItemValue?.sortDirection === "asc" ? (
          <ArrowDownwardIcon sx={iconSx} />
        ) : (
          <ArrowUpwardIcon sx={iconSx} />
        );
      const selectedSortText =
        menuItemValue?.sortDirection === "asc" ? "DESC" : "ASC";

      return menuItem.make(
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography sx={{ fontSize: "0.9rem" }}>
              {sortingItem.label}
            </Typography>
          </Grid>
          {isSelected && (
            <Grid item>
              <Typography
                sx={{ fontSize: "0.7rem" }}
                display="flex"
                alignItems="center"
                gap={0.1}
              >
                {selectedSortIcon} {selectedSortText}
              </Typography>
            </Grid>
          )}
        </Grid>,
        () =>
          dispatch(
            boardQueryActions.setSorting({
              column: sortingItem.id,
              direction:
                isSelected && menuItemValue.sortDirection === "asc"
                  ? "desc"
                  : "asc",
              putNullAtEnd: 1,
            })
          )
      );
    }),
  ];

  return (
    <Menu
      stretchOnMobile={false}
      closeOnSelection={true}
      listSubheader="Sorting by"
      button={(clickHandler) => (
        <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
          <Box onClick={clickHandler}>
            <IconButton>
              <Badge color="error" variant={menuItemValue ? "dot" : undefined}>
                <SortIcon />
              </Badge>
            </IconButton>
            {!matchesMax768Screen && !menuItemValue && (
              <Typography component="span" variant="body2" sx={{ mr: 1 }}>
                Sorting
              </Typography>
            )}
            {!matchesMax768Screen && menuItemValue && (
              <Typography component="span" variant="body2" sx={{ mr: 1 }}>
                {menuItemValue.label}
                <Box
                  component="span"
                  sx={{ verticalAlign: "super", fontSize: "0.75em" }}
                >
                  {menuItemValue.sortDirection === "asc" ? (
                    <ArrowUpwardIcon fontSize="inherit" />
                  ) : (
                    <ArrowDownwardIcon fontSize="inherit" />
                  )}
                </Box>
              </Typography>
            )}
          </Box>
        </Box>
      )}
      menuItems={menuItems}
    />
  );
};

export default Sorting;
