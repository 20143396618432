import { useState } from "react";
import { TEditorState } from "../tinymce/containers/Editor/types/TEditorState";
import useIOHandler from "./useIOHandler";
import { windowHelper } from "../../../utils/windowHelper";

export default function useStateManager() {
  const { sanitize } = useIOHandler();
  const [editorRequested, setEditorRequested] = useState(false);
  const [editorReady, setEditorReady] = useState(false);
  const [editorState, setEditorState] = useState<TEditorState | null>(null);

  return {
    html: editorState?.html ? sanitize(editorState.html) : undefined,
    setEditorState,
    editorRequested,
    editorReady,
    requestEditorHandler: (
      e?: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
    ) => {
      let propagateClick = true;

      // If clicked on a link, don't trigger the editor
      if ((e?.target as HTMLElement)?.tagName === "A") {
        propagateClick = false;
      }

      // If user was doing a selection no need to trigger the editor
      if (windowHelper.hasSelection()) {
        propagateClick = false;
      }

      propagateClick && !editorRequested && setEditorRequested(true);
    },
    immediateRequestEditorHandler: () => {
      !editorRequested && setEditorRequested(true);
    },
    initEditorHandler: () => {
      setEditorReady(true);
    },
    closeEditorHandler: () => {
      setEditorReady(false);
      setEditorRequested(false);
    },
  };
}
