import { IconButton, Typography, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { AddRoad, Calculate } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { TBoardParams } from "../../../../../board/containers/Board/types/TBoardParams";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import Section from "../../../../containers/AddButton/Section";
import { TMenuItemProps } from "../../../../../../common/containers/Menu/types/TMenuItemProps";
import OfftimeManagementMenu from "../../../../../offtime/containers/Menu/OfftimeManagementMenu";
import { guesstimationItemCreateActions } from "../../../../../guesstimation/modules/create/slices/guesstimationItemCreateSlice";
import Menu from "../../../../../../common/containers/Menu/Menu";
import ShortcutProTip from "../../../../../../common/modules/keyboardShortcut/containers/ProTip/ShortcutProTip";
import { KeyboardShortcutEnum } from "../../../../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import { useIsFeatureAllowed } from "../../../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../../../common/modules/accessControl/utils/featureUtil";

const AddButton = () => {
  const theme = useTheme();
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);
  const { projectAbbr } = useParams<TBoardParams>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isGuesstimationAllowed = useIsFeatureAllowed(
    FeatureEnum.GUESSTIMATION_BOARD
  );

  const addGuesstimationItemClickHandler = () => {
    dispatch(guesstimationItemCreateActions.openCreationModal());
  };

  const addSprintClickHandler = () => {
    navigate(`/settings/project/${projectAbbr}/sprints?modalOpen=true`);
  };

  return (
    <Menu
      stretchOnMobile={false}
      closeOnSelection={true}
      button={(clickHandler) => {
        return (
          <>
            <IconButton
              aria-label="delete"
              onClick={clickHandler}
              size="medium"
              style={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              <AddIcon />
            </IconButton>
          </>
        );
      }}
      menuItems={
        [
          menuItem.make(
            <Section
              helperText={
                <ShortcutProTip
                  shortcutKeyString={KeyboardShortcutEnum.t}
                  description="add an item"
                />
              }
              icon={<Calculate />}
              name={`Add Item`}
            />,
            addGuesstimationItemClickHandler,
            undefined,
            !isGuesstimationAllowed,
            featureUtil.notAvailableText
          ),
          OfftimeManagementMenu({
            label: <Typography>Add Offtime</Typography>,
          }),
          menuItem.make(
            <Section icon={<AddRoad />} name={`Add ${sprintLabel}`} />,
            addSprintClickHandler
          ),
        ] as TMenuItemProps[]
      }
    />
  );
};

export default AddButton;
