import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  useTheme,
} from "@mui/material";
import { Skeleton } from "../../../../common/modules/materialUI";
import VerticalDivider from "../../../../common/components/Divider/VerticalDivider";

const ViewSkeleton = () => {
  const theme = useTheme();
  const height = 91;

  return (
    <>
      <Card>
        <CardHeader
          title={<Skeleton variant="text" width={135} />}
          titleTypographyProps={{
            variant: "subtitle2",
          }}
        />
        <CardContent
          style={{ backgroundColor: theme.palette.cardsBackground.default }}
        >
          <Grid container>
            <Grid item xs={12} sm={12} lg={4}>
              <Box display="flex" alignItems="center" gap={2} sx={{ mb: 1 }}>
                <Skeleton variant="text" width={135} />
                <VerticalDivider />
                <Skeleton variant="text" width={145} />
              </Box>
              <div>
                <Skeleton style={{ height }} variant="rounded" />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ mt: 5 }}>
        <CardHeader
          title={<Skeleton variant="text" width={155} />}
          titleTypographyProps={{
            variant: "subtitle2",
          }}
        />
        <CardContent
          style={{ backgroundColor: theme.palette.cardsBackground.default }}
        >
          <Grid container spacing={2}>
            {Array.from({ length: 3 }).map((_, index) => (
              <Grid key={index} item xs={12} sm={12} lg={4}>
                <Box display="flex" alignItems="center" gap={2} sx={{ mb: 1 }}>
                  <Skeleton variant="text" width={135} />
                  <VerticalDivider />
                  <Skeleton variant="text" width={145} />
                </Box>
                <div>
                  <Skeleton style={{ height }} variant="rounded" />
                </div>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ViewSkeleton;
