import { IconTypeEnum } from "../../../entities/columns/orgIconLibrary/IconTypeEnum";
import { useAppDispatch } from "../../../common/hooks/redux";
import { IOrgIconLibrary } from "../../../entities/IOrgIconLibrary";
import useGetSignedUploadUrlToCloud from "../../../common/hooks/useGetSignedUploadUrlToCloud/useGetSignedUploadUrlToCloud";
import { useIconLibraryGetSignedUploadUrlService } from "../services/useIconLibraryGetSignedUploadUrlService";
import useUploadFileToCloud from "../../../common/hooks/useUploadFileToCloud/useUploadFileToCloud";
import useLinkCloudIcon from "./useLinkCloudIcon";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { TFileData } from "../../../common/hooks/useGetSignedUploadUrlToCloud/types/TFileData";

const useUploadIconServiceHelper = (iconType: IconTypeEnum) => {
  const dispatch = useAppDispatch();
  const { dispatch: dispatchGetSignedUploadUrlToCloud } =
    useGetSignedUploadUrlToCloud();
  const { dispatch: dispatchIconLibraryUploadService } =
    useIconLibraryGetSignedUploadUrlService();
  const { dispatch: dispatchUploadIconToCloud } = useUploadFileToCloud();
  const { dispatch: dispatchLinkCloudIcon } = useLinkCloudIcon(iconType);

  return {
    dispatch: (file: File, onSuccess?: (resource: IOrgIconLibrary) => void) => {
      if (!file) {
        return;
      }

      const maxSizeKB = 50;
      if (file.size > maxSizeKB * 1024) {
        dispatch(
          systemNotificationActions.open({
            variant: "warning",
            message: `You can upload maximum ${maxSizeKB}KB file.`,
          })
        );

        return;
      }

      // Trying to get image resolution
      const fileURL = URL.createObjectURL(file);
      const img = new Image();
      img.onload = () => {
        const fileData: TFileData = {
          file,
          resolution: {
            width: img.width,
            height: img.height,
          },
        };

        // Get upload signed url
        dispatchGetSignedUploadUrlToCloud(
          fileData,
          dispatchIconLibraryUploadService,
          (resource) => {
            // Intending to upload the file to the cloud using the signed url
            dispatchUploadIconToCloud(resource.signedUrl, fileData.file, () => {
              // Link the uploaded file to the icon library record in db
              dispatchLinkCloudIcon(fileData, resource.hashName, (resource) => {
                onSuccess?.(resource);

                dispatch(
                  systemNotificationActions.open({
                    variant: "success",
                    message: `The "${fileData.file.name}" is uploaded successfully`,
                  })
                );
              });
            });
          },
          undefined,
          { iconType }
        );

        // Clean up the temporary URL
        URL.revokeObjectURL(fileURL);
      };

      img.src = fileURL;
    },
  };
};

export default useUploadIconServiceHelper;
