import { Box, FormControlLabel, Grid, Typography } from "@mui/material";
import {
  Autocomplete,
  Checkbox,
  DatePicker,
  TextField,
} from "../../../../../common/modules/materialUI";
import ExpandableIcon from "../../../../../common/components/Icon/Expandable/ExpandableIcon";
import React from "react";
import { TNotifyThresholdProps } from "./types/TNotifyThresholdProps";
import { ThresholdOperatorEnum } from "../../../../../entities/enums/ThresholdOperatorEnum";
import { TCreateFormAttributes } from "../types/TCreateFormAttributes";
import { enumUtil } from "../../../../../common/utils/enum";
import { FormHelper } from "../helpers/FormHelper";
import { TFormHelper } from "../helpers/types/TFormHelper";
import moment from "moment";

const getSummary = (
  value: string,
  operator: ThresholdOperatorEnum,
  formHelper: TFormHelper
) => {
  if (!value) {
    return "Select a threshold value";
  }

  value = !formHelper.renderDateForThresholdValue(operator)
    ? value
    : moment(value).format("MM/DD/YYYY");

  switch (operator) {
    case ThresholdOperatorEnum.EQUALS:
      return `Notify when the value ${operator} ${value}`;
    case ThresholdOperatorEnum.GREATER_THAN:
    case ThresholdOperatorEnum.GREATER_THAN_OR_EQUAL:
    case ThresholdOperatorEnum.LESS_THAN:
    case ThresholdOperatorEnum.LESS_THAN_OR_EQUAL:
      return `Notify when the value is ${operator} ${value}`;
    case ThresholdOperatorEnum.PERCENT:
      return `Notify at ${value}% reach`;
    case ThresholdOperatorEnum.CLOSE_TO:
      return `Notify when the value gets closer to ${value}`;
  }
};

const NotifyThreshold = ({
  form,
  thresholdEnabledAttr,
  thresholdValueAttr,
  thresholdOperatorAttr,
  thresholdCustomizedAttr,
}: TNotifyThresholdProps<TCreateFormAttributes>) => {
  const formHelper = FormHelper.withForm(form.data);
  const disabled = !formHelper.hasSelectedValue();

  const customized = form.get(thresholdCustomizedAttr);
  const operator = form.get(thresholdOperatorAttr)!;

  return (
    <>
      <Box display="flex" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              form={form}
              name={thresholdEnabledAttr}
            />
          }
          label={
            <Typography
              sx={{ color: !disabled ? "text.primary" : "text.disabled" }}
              variant="body2"
            >
              {getSummary(
                form.get(thresholdValueAttr),
                operator.id,
                formHelper
              )}
            </Typography>
          }
        />
        {!disabled && (
          <ExpandableIcon
            onClick={() =>
              form.changeHandler(thresholdCustomizedAttr, !customized)
            }
            direction={!customized ? "down" : "up"}
            kind="solid"
          />
        )}
      </Box>
      {!disabled && customized && (
        <Grid sx={{ mb: 2 }} item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              {formHelper.renderTextForThresholdValue(operator.id) && (
                <TextField
                  form={form}
                  name={thresholdValueAttr}
                  label="threshold value"
                  showRequired
                />
              )}
              {formHelper.renderDateForThresholdValue(operator.id) && (
                <DatePicker
                  form={form}
                  name={thresholdValueAttr}
                  label="threshold value"
                  showRequired
                />
              )}
            </Grid>

            <Grid item xs={7}>
              <Autocomplete
                disableClearable
                form={form}
                name={thresholdOperatorAttr}
                label="operator"
                options={enumUtil.toList(ThresholdOperatorEnum)}
                showRequired
                onChange={(event, value) => {
                  formHelper.shouldResetThresholdValue(value.id, operator.id) &&
                    form.changeHandler(thresholdValueAttr, undefined);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default NotifyThreshold;
