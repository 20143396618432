import { TBreakdownFormDataProps } from "./types/TBreakdownFormDataProps";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { NavLink, useLocation } from "react-router-dom";
import useForm from "../../../../common/hooks/useForm/useForm";
import { useEffect } from "react";
import { useCreateTaskService } from "../../../task/services/useCreateTaskService";
import { useFetchCustomFieldsByBoardRefService } from "../../../customField/services/useFetchCustomFieldsByBoardRefService";
import { TListItem } from "../../../../common/types/TListItem";
import { aiSentinelActions } from "../../slices/aiSentinelSlice";
import { IGetCustomFieldsByBoardRefResource } from "../../../customField/interfaces/IGetCustomFieldsByBoardRefResource";
import { entityFieldDefaultValueUtil } from "../../../task/utils/entityFieldDefaultValueUtil";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { ITask } from "../../../../entities/ITask";
import { customFieldInputUtil } from "../../../customField/utils/customFieldInputUtil";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { Box, FormControlLabel, Typography, useTheme } from "@mui/material";
import Loading from "../../../../common/components/Icon/Loading/Loading";
import {
  Autocomplete,
  Button,
  Checkbox,
  Tooltip,
} from "../../../../common/modules/materialUI";
import CustomFieldInput from "../../../customField/containers/CustomFieldInput/CustomFieldInput";
import AiSentinelIcon from "../AiSentinel/AiSentinelIcon";
import { BoardTypeEnum } from "../../../../entities/columns/board/BoardTypeEnum";
import { useCreateBacklogItemService } from "../../../backlog/modules/create/services/useCreateBacklogItemService";
import { IBacklogItem } from "../../../../entities/IBacklogItem";
import { InternalTypeEnum } from "../../../../entities/columns/task/InternalTypeEnum";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../common/modules/eventProvider";
import { TBacklogItemCreatedEvent } from "../../../backlog/modules/create/containers/CreateBacklogItem/types/TBacklogItemCreatedEvent";
import { featureUtil } from "../../../../common/modules/accessControl/utils/featureUtil";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";
import { boardUtil } from "../../../../common/utils/boardUtil";
import { IGuesstimationItem } from "../../../../entities/IGuesstimationItem";
import { useCreateGuesstimationItemService } from "../../../guesstimation/modules/create/services/useCreateGuesstimationItemService";
import { TGuesstimationItemCreatedEvent } from "../../../guesstimation/modules/create/containers/CreateItem/types/TGuesstimationItemCreatedEvent";
import { entityUtil } from "../../../../common/utils/entityUtil";

const getBoardRef = (locationPathName: string) => {
  const parts = locationPathName.split("/");
  const boardIndex = parts.indexOf("board");
  const boardRefIndex = boardIndex >= 0 ? boardIndex + 1 : undefined;

  return boardRefIndex ? parts?.[boardRefIndex] : undefined;
};

const BreakdownFormData = ({ data }: TBreakdownFormDataProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const isBacklogAllowed = useIsFeatureAllowed(FeatureEnum.BACKLOG_ICEBOX);
  const isGuesstimationAllowed = useIsFeatureAllowed(
    FeatureEnum.GUESSTIMATION_BOARD
  );
  const location = useLocation();
  const pathBoardRef = getBoardRef(location.pathname);

  const theme = useTheme();

  const { list } = useAppSelector((state) => state.org.init);
  const boardList = list.flatMap((listItem) =>
    listItem.boards
      .filter((board) => !!board.type)
      .map(
        (board) =>
          ({
            id: board.ref,
            label: `${board.name} - ${listItem.project.name}`,
          } as TListItem<string>)
      )
  );
  const pathBoardListItem = !!pathBoardRef
    ? boardList.find((boardListItem) => boardListItem.id === pathBoardRef)
    : undefined;
  const skipBoardSelection = !!pathBoardListItem;
  const selectedBoardListItem =
    pathBoardListItem ??
    boardList.find(
      (boardListItem) => boardListItem.id === data.selectedBoard?.id
    );
  const selectedBoard = selectedBoardListItem
    ? list
        .flatMap((listItem) => listItem.boards)
        .find((board) => board.ref === selectedBoardListItem.id)
    : undefined;

  const {
    isLoading: fetchingCustomFieldData,
    dispatch: dispatchFetchCustomFieldData,
  } = useFetchCustomFieldsByBoardRefService({
    boardRef: selectedBoardListItem?.id ?? "",
    query: { withDefaultValues: 1 },
  });
  const { dispatch: dispatchCreateTaskService } = useCreateTaskService();
  const { dispatch: dispatchCreateBacklogItemService } =
    useCreateBacklogItemService();
  const { dispatch: dispatchCreateGuesstimationItemService } =
    useCreateGuesstimationItemService();

  const form = useForm<Record<string, any>>();
  const customFieldData = data.customFieldData;
  const emptyRequiredCustomFields = customFieldData?.customFields.filter(
    (customField) => {
      if (!customField.isRequired) {
        return false;
      }

      const fieldName = customFieldInputUtil.generateName(customField);

      // Return the custom field only if there's no value set
      return customField.isMultiple
        ? !customFieldData?.defaultValues?.[fieldName]?.length
        : !customFieldData?.defaultValues?.[fieldName];
    }
  );
  const hasRequiredFieldToFill = !!emptyRequiredCustomFields?.find(
    (customField) => {
      const fieldName = customFieldInputUtil.generateName(customField);
      const formValue = form.get(fieldName);

      return customField.isMultiple ? !formValue?.length : !formValue;
    }
  );

  // Fetch custom fields with default values
  useEffect(() => {
    form.reset();

    if (
      !data.actions.length ||
      !selectedBoard ||
      !boardUtil.isCustomFieldsSupportedForType(selectedBoard.type)
    ) {
      dispatch(
        aiSentinelActions.setBreakdownCustomFieldsData({
          customFields: [],
          defaultValues: [],
        })
      );

      return;
    }

    dispatch(aiSentinelActions.setBreakdownCustomFieldsLoading());

    dispatchFetchCustomFieldData().then(
      (customFieldDataResource: IGetCustomFieldsByBoardRefResource) => {
        // Generating native task default values ready for submission for task creation, not a form values
        // (e.g. contains user id instead of user list item)
        const nativeTaskDefaultValues =
          entityFieldDefaultValueUtil.getSubmittableNativeEntityDefaultValues(
            customFieldDataResource.entityFieldDefaultValues
          );

        const defaultValues = {
          ...nativeTaskDefaultValues,
          // Generating custom field FORM default values (might contain list item), not ready for submission
          // (must be converted for submission)
          ...entityFieldDefaultValueUtil.getFormCustomFieldDefaultValues(
            customFieldDataResource.entityFieldDefaultValues,
            customFieldDataResource.customFields
          ),
        };

        form.set({
          ...defaultValues,
        });

        dispatch(
          aiSentinelActions.setBreakdownCustomFieldsData({
            customFields: customFieldDataResource.customFields,
            defaultValues: defaultValues,
          })
        );
      }
    );
  }, [data.actions.length, selectedBoard?.id]);

  const handleTasksCreation = async () => {
    if (!data.actions.length) {
      return;
    }

    if (!selectedBoard || !selectedBoard.type) {
      dispatch(
        systemNotificationActions.open({
          variant: "error",
          message: "Board is required.",
        })
      );
      return;
    }

    if (
      (selectedBoard.type === BoardTypeEnum.BACKLOG && !isBacklogAllowed) ||
      (selectedBoard.type === BoardTypeEnum.GUESSTIMATION &&
        !isGuesstimationAllowed)
    ) {
      dispatch(
        systemNotificationActions.open({
          message:
            featureUtil.notAvailableFullText +
            ` ${selectedBoard.type} Board is not allowed.`,
          variant: "warning",
        })
      );
      return;
    }

    dispatch(aiSentinelActions.setIsCreatingActions());

    let lastCreatedEntity:
      | ITask
      | IBacklogItem
      | IGuesstimationItem
      | undefined;
    for (const index in data.actions) {
      if (!data.actions[index].isSelected) {
        continue;
      }

      dispatch(
        aiSentinelActions.setIsCreatingTask({ index: +index, isCreating: true })
      );

      const requestBody = form.getTransformed((formData) => {
        return {
          boardRef: selectedBoard.ref!,
          name: data.actions[index].text,
          ...formData,
          ...customFieldInputUtil.getCustomFieldFormAttributesTransformed(
            customFieldData?.customFields ?? [],
            formData as Record<string, any>
          ),
        };
      });

      try {
        let entityResource: ITask | IBacklogItem | IGuesstimationItem;
        if (selectedBoard.type === BoardTypeEnum.GUESSTIMATION) {
          entityResource = await dispatchCreateGuesstimationItemService({
            body: requestBody,
          });
        } else if (selectedBoard.type === BoardTypeEnum.BACKLOG) {
          entityResource = await dispatchCreateBacklogItemService({
            body: requestBody,
          });
        } else {
          entityResource = (
            await dispatchCreateTaskService({
              body: {
                internalType: InternalTypeEnum.TASK,
                ...requestBody,
              },
            })
          )[0];
        }
        lastCreatedEntity = entityResource;

        dispatch(
          aiSentinelActions.setCreatedEntityRef({
            index: +index,
            entityRef: entityResource.ref,
            entityType: boardUtil.getEntityTypeByBoardType(selectedBoard.type),
          })
        );
      } catch (error) {
        const validationFirstError = form.getValidationErrorAtIndex(
          0,
          error as THttpClientError
        );

        if (validationFirstError) {
          dispatch(
            systemNotificationActions.open({
              variant: "error",
              message: validationFirstError,
            })
          );
        }
        dispatch(
          aiSentinelActions.setIsCreatingTask({
            index: +index,
            isCreating: false,
          })
        );
        break;
      }
    }

    dispatch(aiSentinelActions.setActionsCreated());

    if (selectedBoard.type === BoardTypeEnum.GUESSTIMATION) {
      lastCreatedEntity &&
        dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_CREATED, {
          item: lastCreatedEntity,
        } as TGuesstimationItemCreatedEvent);
    } else if (selectedBoard.type === BoardTypeEnum.BACKLOG) {
      lastCreatedEntity &&
        dispatchEvent(EventEnum.ON_BACKLOG_ITEM_CREATED, {
          backlogItem: lastCreatedEntity,
        } as TBacklogItemCreatedEvent);
    } else {
      // Updating boards to reflect the changes if task is updated
      lastCreatedEntity && dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED);
    }
  };

  return (
    <>
      <Box
        p={1}
        my={2}
        mx={1}
        sx={{
          backgroundColor: theme.palette.aiSentinel.aiChatBackgroundColor,
          borderRadius: 5,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }} p={1}>
          {data.actions.map((action, index) => (
            <FormControlLabel
              key={index}
              {...(action.createdEntityRef && {
                labelPlacement: "start",
                sx: {
                  margin: 0,
                  justifyContent: "space-between",
                },
              })}
              control={
                <Checkbox
                  {...(action.createdEntityRef && {
                    inputProps: {
                      disabled: true,
                    },
                    sx: {
                      "&.Mui-checked": {
                        color: "#8c8f96",
                      },
                    },
                  })}
                  checked={action.isSelected}
                  onChange={() =>
                    !data.isCreatingActions &&
                    !data.actionsCreated &&
                    dispatch(
                      aiSentinelActions.toggleBreakdownActionSelection({
                        index,
                      })
                    )
                  }
                />
              }
              label={
                <>
                  {action.isCreating && <Loading />}
                  {action.createdEntityRef && (
                    <>
                      [
                      <NavLink
                        to={entityUtil.getUrlByEntityType(
                          action.createdEntityType!,
                          action.createdEntityRef
                        )}
                        target="_blank"
                        style={{
                          textDecoration: "underline",
                          display: "inline",
                        }}
                      >
                        {action.createdEntityRef}
                      </NavLink>
                      ]{" "}
                    </>
                  )}
                  {action.text}
                </>
              }
            />
          ))}
        </Box>

        {!data.actionsCreated &&
          (!skipBoardSelection || !!emptyRequiredCustomFields?.length) && (
            <Box
              p={1}
              mb={1}
              sx={{
                backgroundColor:
                  theme.palette.aiSentinel.chatRequiredFieldsBackgroundColor,
                borderRadius: 3,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "thin",
                  color: theme.palette.aiSentinel.chatRequiredFieldsColor,
                }}
              >
                Fill the required fields to create the tasks
              </Typography>

              {!skipBoardSelection && (
                <Box mt={1} mb={3}>
                  <Autocomplete
                    label="Board"
                    showRequired
                    disabled={!boardList.length}
                    options={boardList}
                    value={data.selectedBoard}
                    onChange={(e, value) => {
                      dispatch(aiSentinelActions.setSelectedBoard(value));
                    }}
                  />
                </Box>
              )}

              {emptyRequiredCustomFields?.map((customField) => {
                return (
                  <Box mb={1} key={customField.id}>
                    <CustomFieldInput
                      customField={customField}
                      form={form}
                      showLabel
                    />
                  </Box>
                );
              })}
            </Box>
          )}

        <Box display="flex" justifyContent="flex-end" p={1}>
          <Button
            variant="contained"
            loading={data.isCreatingActions}
            disabled={
              !selectedBoardListItem ||
              fetchingCustomFieldData ||
              hasRequiredFieldToFill ||
              data.isCreatingActions ||
              data.actionsCreated
            }
            onClick={handleTasksCreation}
            sx={{
              padding: 0,
              fontSize: "12px",
            }}
          >
            Create
          </Button>
        </Box>

        {fetchingCustomFieldData && (
          <Tooltip title="We are preparing your batch creation experience">
            <span>
              <Loading />
            </span>
          </Tooltip>
        )}
      </Box>

      <Box>
        <AiSentinelIcon size={30} displayText />
      </Box>
    </>
  );
};

export default BreakdownFormData;
