import { Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import { keyboard } from "../../../../utils/keyboard";
import InputAdornment from "@mui/material/InputAdornment";
import { Send } from "@mui/icons-material";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";
import { TextField } from "../../../materialUI";
import useForm from "../../../../hooks/useForm/useForm";
import { useSendFeedbackService } from "../../services/useSendFeedbackService";
import { THttpClientError } from "../../../httpClient/types/THttpClientError";
import Loading from "../../../../components/Icon/Loading/Loading";

const FeedbackForm = () => {
  const [feedbackReceived, setFeedbackReceived] = useState(false);
  const form = useForm<{ message: string }>();
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const { isLoading, dispatch: dispatchSendFeedback } =
    useSendFeedbackService();

  const disabled = !form.isDirty() || isLoading;

  const sendHandler = () => {
    if (disabled) {
      return;
    }

    dispatchSendFeedback({ body: { message: form.data?.message } })
      .then(() => {
        setFeedbackReceived(true);
        form.notDirty();
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return !feedbackReceived ? (
    <TextField
      disabled={isLoading}
      form={form}
      name="message"
      forceFocus={!isTouchDevice}
      variant="outlined"
      multiline
      maxRows={4}
      fullWidth
      placeholder="Type your message here..."
      onKeyDown={(event) => {
        if (keyboard.isEnterPressed(event) && !keyboard.isShiftPressed(event)) {
          event.preventDefault();
          sendHandler();
        }
      }}
      sx={{
        mr: 1,
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderRadius: "16px",
          },
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={sendHandler}
              color="primary"
              disabled={disabled}
            >
              {!isLoading ? <Send /> : <Loading />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  ) : (
    <Box sx={{ color: "primary.main", mt: 3, fontWeight: "500" }}>
      Thanks much we have received your precious feedback!
    </Box>
  );
};

export default FeedbackForm;
