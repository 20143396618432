import { TObjectiveViewMainContent } from "./types/TObjectiveViewMainContent";
import { Grid } from "@mui/material";
import NameForm from "../Name/NameForm";
import { EntityTypeEnum } from "../../../../../entities/enums/EntityTypeEnum";
import Description from "../../../../description/containers/Description/Description";
import Comments from "../../../../comment/containers/Comments/Comments";
import InfoTabs from "../InfoTabs/InfoTabs";

const ObjectiveViewMainContent = ({
  objective,
  isMobileView,
}: TObjectiveViewMainContent) => {
  return (
    <>
      <Grid container item mb="16px">
        <NameForm objective={objective} />
      </Grid>

      <Description
        entityType={EntityTypeEnum.OBJECTIVE}
        entityId={objective.id}
        isMobileView={isMobileView}
        showAiBreakdown={false}
      />

      <Grid container item mb="30px" flexDirection="column" gap="8px">
        <InfoTabs objective={objective} isMobileView={isMobileView} />
      </Grid>

      <Grid container item flexDirection="column" gap="40px">
        <Comments
          entityType={EntityTypeEnum.OBJECTIVE}
          entityId={objective.id}
          isMobileView={isMobileView}
        />
      </Grid>
    </>
  );
};

export default ObjectiveViewMainContent;
