import { TBacklogItemMenuProps } from "./types/TBacklogItemMenuProps";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import useUtils from "../../../../../linkList/hooks/useUtils";
import { linkActions } from "../../../../../link/slices/linkSlice";
import { EntityTypeEnum } from "../../../../../../entities/enums/EntityTypeEnum";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import { Box, Typography } from "@mui/material";
import { AddLinkOutlined } from "@mui/icons-material";

const BacklogItemLinkMenuItem = ({ backlogItem }: TBacklogItemMenuProps) => {
  const dispatch = useAppDispatch();
  const { addLinkLabel } = useUtils();

  const openModalHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      linkActions.openCreateLinkModal({
        entityType: EntityTypeEnum.BACKLOG_ITEM,
        entityId: backlogItem.id,
        projectRef: backlogItem.projectRef,
      })
    );
  };

  return menuItem.make(
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      onClick={(e) => openModalHandler(e)}
    >
      <AddLinkOutlined fontWeight="400" />
      <Typography variant="subtitle2" fontWeight="400">
        {addLinkLabel}
      </Typography>
    </Box>
  );
};

export default BacklogItemLinkMenuItem;
