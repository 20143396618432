import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TViewState } from "./types/TViewState";
import { IBacklogItemDetailsResource } from "../interfaces/IBacklogItemDetailsResource";
import { IBacklogItem } from "../../../../../entities/IBacklogItem";

const initialState: TViewState = {};

const slice = createSlice({
  name: "backlogItemView",
  initialState,
  reducers: {
    open: (state, action: PayloadAction<{ ref: string }>) => {
      state.backlogItemRef = action.payload.ref;
    },
    setDetailsResource: (
      state,
      action: PayloadAction<IBacklogItemDetailsResource>
    ) => {
      state.detailsResource = action.payload;
    },
    mergeBacklogItemProps: (
      state,
      action: PayloadAction<Partial<IBacklogItem>>
    ) => {
      if (state.detailsResource?.backlogItem) {
        state.detailsResource.backlogItem = {
          ...state.detailsResource.backlogItem,
          ...action.payload,
        };
      }
    },
    openMoveToTaskDialog: (
      state,
      action: PayloadAction<{
        backlogItemRef: IBacklogItem["ref"];
        boardRef: string;
      }>
    ) => {
      state.moveToTask = {
        openDialog: true,
        backlogItemRef: action.payload.backlogItemRef,
        boardRef: action.payload.boardRef,
      };
    },
    closeMoveToTaskDialog: (state) => {
      state.moveToTask = undefined;
    },
    reset: () => initialState,
  },
});

export const backlogItemViewReducer = slice.reducer;

export const backlogItemViewActions = slice.actions;
