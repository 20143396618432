import { Grid } from "@mui/material";
import { Navigate, useSearchParams } from "react-router-dom";
import { ROUTE_ON_SIGNIN } from "../../../../user/containers/Auth/Signin/TSSigninForm";
import React, { useState } from "react";
import MSRegisterStepper from "./SignUp/Stepper/MSRegisterStepper";

const MSOrgRegistration = () => {
  const [step, setStep] = useState(1);
  const [orgEmail, setOrgEmail] = useState<string>("");
  const [searchParams] = useSearchParams();
  const subscriptionPlan = searchParams.get("subscriptionPlan");

  const afterOrgCreationHandler = (email: string) => {
    setOrgEmail(email);
    setStep(step + 1);
  };

  return !subscriptionPlan ? (
    <Navigate to={ROUTE_ON_SIGNIN} replace={true} />
  ) : (
    <Grid item>
      <MSRegisterStepper
        activeStep={step - 1}
        orgRegistrationProps={{
          onNextHandler: afterOrgCreationHandler,
          subscriptionPlan: subscriptionPlan,
        }}
        checkoutProps={{
          subscriptionPlan: subscriptionPlan,
          orgEmail: orgEmail,
        }}
      />
    </Grid>
  );
};

export default MSOrgRegistration;
