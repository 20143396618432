import { TDetailsFormProps } from "./types/TDetailsFormProps";
import { Grid } from "@mui/material";
import DetailsLabel from "../../../../task/modules/view/containers/TaskViewDetails/DetailsLabel";
import { DatePicker } from "../../../../../common/modules/materialUI";
import React, { useEffect } from "react";
import useForm from "../../../../../common/hooks/useForm/useForm";
import { TDetailsFormAttributes } from "./types/TDetailsFormAttributes";
import useHandleObjectiveFormPatch from "../../../hooks/useHandleObjectiveFormPatch";

export default function DetailsForm({ objective }: TDetailsFormProps) {
  const { dispatchUpdateObjective } = useHandleObjectiveFormPatch({
    objective,
  });
  const form = useForm<TDetailsFormAttributes>({
    startDate: objective.startDate,
    endDate: objective.endDate,
  });

  useEffect(() => {
    form.isDirty() && submitHandler();
    form.notDirty();
  }, [form]);

  const submitHandler = () => {
    dispatchUpdateObjective({
      startDate: form.data?.startDate,
      endDate: form.data?.endDate,
    });
  };

  return (
    <Grid container spacing="20px" alignItems="center">
      <Grid item xs={4}>
        <DetailsLabel>Start Date</DetailsLabel>
      </Grid>
      <Grid item xs={8}>
        <DatePicker form={form} name="startDate" />
      </Grid>

      <Grid item xs={4}>
        <DetailsLabel>End Date</DetailsLabel>
      </Grid>
      <Grid item xs={8}>
        <DatePicker form={form} name="endDate" />
      </Grid>
    </Grid>
  );
}
