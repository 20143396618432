import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IBacklogViewGroupDataRequest } from "../../../interfaces/IBacklogViewGroupDataRequest";
import { IYearMonthGroupResource } from "../../../interfaces/IYearMonthGroupResource";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { boardViewActions } from "../../../slices/boardViewSlice";

export const useFetchGroupDataService = (props: {
  boardRef: string;
  query: IBacklogViewGroupDataRequest;
}) => {
  const dispatch = useAppDispatch();

  const response = useHttpClientDispatchable({
    url: `/board/${props.boardRef}/backlogView/groupData`,
    method: RequestMethodEnum.GET,
    query: props.query,
    events: {
      beforeTransform: (data: IYearMonthGroupResource[]) => {
        dispatch(boardViewActions.setBacklogYearMonthGroupResource(data));
      },
    },
  });

  return {
    ...response,
    data: response.data as IYearMonthGroupResource[] | null,
  };
};
