import MSSignUpLayout from "../MSSignUpLayout";

const MsOrgSignUpStep = (props: any) => {
  return {
    label: "Registration",
    content: <MSSignUpLayout {...props} />,
  };
};

export default MsOrgSignUpStep;
