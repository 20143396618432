import { IJWTPayload } from "../../interfaces/IJWTPayload";
import moment from "moment";
import { org } from "./org";

const JWT_STORAGE_KEY = "5755752285";

export const jwt = {
  store: (token: string): void => localStorage.setItem(JWT_STORAGE_KEY, token),
  fetch: (): string | null => localStorage.getItem(JWT_STORAGE_KEY),
  remove: (): void => localStorage.removeItem(JWT_STORAGE_KEY),
  bearer: (): string => "Bearer " + jwt.fetch(),
  parse: (): IJWTPayload | null => {
    const token = jwt.fetch();
    if (!token) {
      return null;
    }

    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload) ?? null;
  },
  valid: (): boolean => {
    // Reset organization information
    org.remove();

    try {
      const payload = jwt.parse();
      if (!payload) {
        return false;
      }

      if (!(payload.exp > moment().unix())) {
        jwt.remove();
        return false;
      }

      // Once detected valid set global variables
      org.set(payload.orgId);
      return true;
    } catch (error) {
      jwt.remove();
      return false;
    }
  },
};
