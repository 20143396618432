import { IGuesstimationBoardUsersRequest } from "../../../interfaces/IGuesstimationBoardUsersRequest";
import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IBoardUsersResource } from "../../../interfaces/IBoardUsersResource";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { userActions } from "../../../../user/slices/userSlice";

export const useGuesstimationBoardUsersService = (props: {
  query: IGuesstimationBoardUsersRequest;
}) => {
  const dispatch = useAppDispatch();
  return useHttpClientDispatchable({
    url: `/user/guesstimationUsers`,
    method: RequestMethodEnum.GET,
    query: props.query,
    events: {
      beforeTransform: (data: IBoardUsersResource) => {
        dispatch(userActions.setList(data));
      },
    },
  });
};
