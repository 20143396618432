import { TCommentEditorProps } from "./types/TCommentEditorProps";
import { useRef } from "react";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { keyboard } from "../../../../common/utils/keyboard";
import {
  Editor,
  useIOHandler,
  useUploadAttachmentHandler,
} from "../../../../common/modules/editor";
import Actions from "../../../task/modules/view/containers/Actions/Actions";
import ProTipSaveChanges from "../../../../common/modules/editor/tinymce/containers/ProTip/ProTipSaveChanges";
import useMentionHandler from "../../../../common/modules/editor/tinymce/hooks/useMentionHandler";

export default function CommentEditor({
  html,
  entityType,
  entityId,
  onSave,
  onSaveFailure,
  onSaveSuccess,
  onCancel,
  onInit,
  saveService,
  commentId,
  style,
  autoFocus,
}: TCommentEditorProps) {
  const dispatch = useAppDispatch();
  const ref = useRef<any>();
  const { fetchEditorState } = useIOHandler();
  const uploadAttachmentHandler = useUploadAttachmentHandler({
    entityType,
    entityId,
  });
  const mentionHandler = useMentionHandler(html ?? "");

  const submitHandler = () => {
    const editorState = fetchEditorState(ref);

    if (editorState.html.trim() === "") {
      return;
    }
    const mentions = mentionHandler.fetchNewMentions(editorState.html);

    onSave?.(editorState);
    saveService
      .dispatch({
        body: { html: editorState.html, mentions: mentions },
        urlPath: commentId ? `/${commentId}` : "",
      })
      .then(() => onSaveSuccess?.())
      .catch((_) => {
        onSaveFailure?.(editorState);

        dispatch(
          systemNotificationActions.open({
            message:
              "There was a problem with saving the comment. Please try again.",
            variant: "error",
          })
        );
      });
  };

  const cancelHandler = () => {
    onCancel?.(fetchEditorState(ref));
  };

  return (
    <div style={style}>
      <Editor
        html={html}
        ref={ref}
        onInit={onInit}
        onKeyDown={(event) => keyboard.onControlEnter(event, submitHandler)}
        uploadHandler={uploadAttachmentHandler}
        onSave={submitHandler}
        autoFocus={autoFocus}
      />
      <ProTipSaveChanges />
      <Actions submitHandler={submitHandler} cancelHandler={cancelHandler} />
    </div>
  );
}
