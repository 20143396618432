import Form from "../../../../common/components/Form/Form";
import { useCreateLinkService } from "../../services/useCreateLinkService";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { TCreateFormProps } from "./types/TCreateFormProps";
import { Autocomplete, Button } from "../../../../common/modules/materialUI";
import { useLinkEntityLookupService } from "../../services/useLinkEntityLookupService";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { TCreateFormAttributesTransformed } from "./types/TCreateFormAttributesTransformed";
import useFormListing from "../../hooks/useList";
import useForm from "../../../../common/hooks/useForm/useForm";
import { TCreateFormAttributes } from "./types/TCreateFormAttributes";
import { linkActions } from "../../slices/linkSlice";
import { ILinkEntityResource } from "../../interfaces/ILinkEntityResource";
import { EntityTypeEnum } from "../../../../entities/enums/EntityTypeEnum";

const CreateForm = (props: TCreateFormProps) => {
  const dispatch = useAppDispatch();
  const form = useForm<TCreateFormAttributes>({
    linkedEntities: [],
  });

  const { linkListFilteredTransformed } = useFormListing({
    projectRef: props.projectRef,
  });

  const userLookupService = useLinkEntityLookupService();

  const { isLoading, dispatch: dispatchCreateLink } = useCreateLinkService({
    entityType: props.entityType,
    entityId: props.entityId,
  });

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreateLink({
      body: form.getTransformed<TCreateFormAttributesTransformed>((data) => ({
        linkListId: data.linkListId?.id!,
        linkedEntities: data.linkedEntities.map((item) => {
          const [linkedEntityId, linkedEntityType] = item.id.split("|");

          return {
            linkedEntityType: linkedEntityType as EntityTypeEnum,
            linkedEntityId: +linkedEntityId,
          };
        }),
      })),
    })
      .then(() => {
        dispatch(linkActions.closeCreateLinkModal());
        dispatch(
          systemNotificationActions.open({
            message: "Task linked successfully",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Add
          </Button>
        </>
      }
    >
      <Autocomplete
        form={form}
        name="linkListId"
        label="Task Link"
        disabled={!linkListFilteredTransformed?.length}
        showRequired
        options={linkListFilteredTransformed}
      />
      <Autocomplete
        form={form}
        name="linkedEntities"
        label="Task"
        showRequired
        multiple={true}
        service={userLookupService}
        servicePayloadListRules={(payload: ILinkEntityResource[]) =>
          payload.map((item) => ({
            id: `${item.id}|${item.entityType}`,
            label: `${item.title} ${item.description}`,
          }))
        }
      />
    </Form>
  );
};

export default CreateForm;
