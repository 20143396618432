import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import {
  Button,
  DatePicker,
  TextField,
  Tooltip,
} from "../../../../common/modules/materialUI";
import { Grid } from "@mui/material";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { TOfftimeFormAttributes } from "./types/TOfftimeFormAttributes";
import { useCreateOfftimeService } from "../../services/useCreateOfftimeService";
import { useState } from "react";
import { offtimeActions } from "../../slices/offtimeSlice";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { IUserOfftime } from "../../../../entities/IUserOfftime";
import { MoreTime } from "@mui/icons-material";
import { TGeneralProps } from "./types/TGeneralProps";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../common/modules/accessControl/utils/featureUtil";

const OfftimeForm = (props: TGeneralProps) => {
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const isFeatureAllowed = useIsFeatureAllowed(FeatureEnum.OFFTIME_MANAGEMENT);
  const form = useForm<TOfftimeFormAttributes>();
  const dispatch = useAppDispatch();
  const { isLoading, dispatch: dispatchCreateOfftime } =
    useCreateOfftimeService();

  const isDisabled = isLoading;

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    dispatchCreateOfftime({
      body: form.data,
      urlPath: !props.userId ? "" : `/for/${props.userId}`,
    })
      .then((payload: IUserOfftime) => {
        formDisplayHandler();
        dispatch(offtimeActions.addOfftime(payload));
        dispatch(
          systemNotificationActions.open({
            message: "Offtime created",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        error.status === 406 &&
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );

        form.errorHandler(error);
      });
  };

  const formDisplayHandler = () => {
    if (!isFeatureAllowed) {
      dispatch(
        systemNotificationActions.open({
          message: featureUtil.notAvailableFullText,
          variant: "warning",
        })
      );
      return;
    }

    // On every open / close we reset the form
    form.reset();
    setFormOpen((prevState) => !prevState);
  };

  return (
    <>
      {!formOpen && (
        <Tooltip title={featureUtil.notAvailableText} when={!isFeatureAllowed}>
          <span>
            <Button
              variant="text"
              startIcon={<MoreTime />}
              onClick={formDisplayHandler}
            >
              Log new offtime
            </Button>
          </span>
        </Tooltip>
      )}
      {formOpen && (
        <Form
          onSubmit={submitHandler}
          actionPosition="left"
          actionElement={
            <>
              <Button
                onClick={formDisplayHandler}
                disabled={isDisabled}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                disabled={isDisabled || !form.isDirty()}
                variant="contained"
                type="submit"
                loading={isLoading}
              >
                Save
              </Button>
            </>
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                form={form}
                name="description"
                label="Description"
                showRequired
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DatePicker
                form={form}
                name="startDate"
                label="Start Date"
                showRequired
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DatePicker
                form={form}
                name="endDate"
                label="End Date"
                showRequired
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </>
  );
};

export default OfftimeForm;
