import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { customFieldActions } from "../../slices/customFieldSlice";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import UpdateForm from "./UpdateForm";

const UpdateModal = () => {
  const { showModal, editableRecord } = useAppSelector(
    (state) => state.customField.update
  );
  const dispatch = useAppDispatch();

  const closeHandler = () => dispatch(customFieldActions.closeUpdateModal());

  return (
    <Dialog
      open={showModal && editableRecord !== null}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Edit Custom Field</DialogTitle>
      <DialogContent>
        {editableRecord && (
          <UpdateForm customField={editableRecord} onCancel={closeHandler} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UpdateModal;
