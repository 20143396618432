import { TUseHttpClientDispatchable } from "./types/TUseHttpClientDispatchable";
import { useHttpClientCore } from "./useHttpClientCore";

// This will not trigger any state updates and will rely only on the dispatch
export const useHttpClientDispatchableOnly: TUseHttpClientDispatchable = (
  props
) => {
  return useHttpClientCore({
    ...props,
    ...{ autoDispatch: false, updateState: false },
  });
};
