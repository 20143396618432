import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TBoardIntegrationState } from "./types/TBoardIntegrationState";
import { IBoardIntegration } from "../../../entities/IBoardIntegration";

const initialState: TBoardIntegrationState = {
  list: [],
};

const slice = createSlice({
  name: "boardIntegration",
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<IBoardIntegration[]>) => {
      state.list = action.payload;
    },
    addBoardIntegration: (state, action: PayloadAction<IBoardIntegration>) => {
      state.list = [action.payload, ...state.list];
    },
    removeBoardIntegration: (state, action: PayloadAction<{ id: number }>) => {
      state.list = state.list.filter((item) => item.id !== action.payload.id);
    },
    clearBoardIntegrationList: (state) => {
      state.list = [];
    },
  },
});

export const boardIntegrationReducer = slice.reducer;

export const boardIntegrationActions = slice.actions;
