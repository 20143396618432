import { TDatePicker } from "./types/TDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import TextField from "../../components/TextField/TextField";
import {
  IconButton,
  InputAdornment,
  SvgIconProps,
  useTheme,
} from "@mui/material";
import { Clear, Event } from "@mui/icons-material";
import { useState } from "react";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";

const DatePicker = <TFormAttributes extends unknown>(
  props: TDatePicker<TFormAttributes>
) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);

  const {
    form,
    name,
    allowClear,
    textFieldProps,
    showRequired,
    textFieldInputIconsProps,
    ...datePickerProps
  } = props;
  const value = (!!name && form?.get(name as keyof TFormAttributes)) ?? null;

  const changeHandler: TDatePicker<TFormAttributes>["onChange"] = (
    value,
    keyboardInputValue
  ) => {
    props.onChange && props.onChange(value, keyboardInputValue);
    !!name && form?.changeHandler(name, value ?? keyboardInputValue);
  };

  const iconsProps = {
    fontSize: "small",
    sx: { color: theme.palette.taskView.textMainColor },
    ...textFieldInputIconsProps,
  } as SvgIconProps;

  return (
    <DesktopDatePicker
      value={value}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={(props) => (
        <TextField
          autoComplete="off"
          onKeyDown={(e) => e.preventDefault()}
          {...props}
          {...textFieldProps}
          form={form}
          name={name}
          label={datePickerProps.label}
          showRequired={showRequired}
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {allowClear && value && (
                  <IconButton onClick={() => changeHandler(null, undefined)}>
                    <Clear {...iconsProps} />
                  </IconButton>
                )}
                <IconButton onClick={() => setOpen((prevState) => !prevState)}>
                  <Event {...iconsProps} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      InputProps={{
        sx: {
          ".MuiInputBase-input": {
            fontSize: !isTouchDevice ? "15px" : "16px",
            fontWeight: 500,
            color: theme.palette.taskView.textSecondaryColor,
            WebkitTextFillColor: "unset !important",
          },
        },
      }}
      {...datePickerProps}
      onChange={changeHandler}
    />
  );
};

export default DatePicker;
