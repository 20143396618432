export const query = {
  filters: (
    queryFilters: Record<string, number[]>,
    prefix = "filters"
  ): string => {
    const buildQuery = (obj: any, parentPrefix?: string): string => {
      return Object.entries(obj)
        .map(([key, value]) => {
          const fullPrefix: any = parentPrefix
            ? `${parentPrefix}[${key}]`
            : key;

          if (Array.isArray(value)) {
            return value
              .map((item, index) => {
                if (typeof item === "object" && item !== null) {
                  return buildQuery(item, `${fullPrefix}[${index}]`);
                } else {
                  return `${encodeURIComponent(
                    `${fullPrefix}[]`
                  )}=${encodeURIComponent(item)}`;
                }
              })
              .join("&");
          } else if (typeof value === "object" && value !== null) {
            return buildQuery(value, fullPrefix);
          } else {
            return `${encodeURIComponent(fullPrefix)}=${encodeURIComponent(
              value as string
            )}`;
          }
        })
        .join("&");
    };

    return buildQuery(queryFilters, prefix);
  },
  transformToQueryObject(
    obj: Record<string, any> = {},
    prefix = ""
  ): Record<string, string | number> {
    return Object.keys(obj).reduce<Record<string, string | number>>(
      (acc, key) => {
        const value = obj[key];
        const nestedPrefix = prefix ? `${prefix}[${key}]` : key;
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            acc[`${nestedPrefix}[${index}]`] = item;
          });
        } else if (typeof value === "object" && value !== null) {
          Object.assign(acc, query.transformToQueryObject(value, nestedPrefix));
        } else {
          acc[nestedPrefix] = value;
        }
        return acc;
      },
      {}
    );
  },
};
