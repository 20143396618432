import * as Highcharts from "highcharts";
import { PointOptionsObject } from "highcharts";
import DarkUnica from "highcharts/themes/dark-unica";
import SandSignika from "highcharts/themes/sand-signika";
import HighchartsReact from "highcharts-react-official";
import React, { useRef } from "react";
import { useTheme } from "@mui/material";
import { TChartProps } from "./types/TChartProps";
import { useAppSelector } from "../../../../common/hooks/redux";
import DetailsLabel from "../../../task/modules/view/containers/TaskViewDetails/DetailsLabel";

const AccomplishmentChart = ({ objective }: TChartProps) => {
  const initiativeList = useAppSelector((state) => state.initiative.list);
  const chartData: PointOptionsObject[] = initiativeList
    ?.filter(function (initiative) {
      return (initiative?.progress || 0) > 0;
    })
    .map(function (initiative) {
      const obj: PointOptionsObject = {
        name: `${initiative.name} (${initiative.doneCount}/${initiative.allCount})`,
        y: initiative.progress || 0,
      };

      return obj;
    });

  const options: Highcharts.Options = {
    title: {
      text: `Total ${objective.progress}%`,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      series: {
        dataLabels: [
          {
            enabled: false,
            format: "{point.name}",
          },
          {
            enabled: true,
            format: "{point.percentage:.0f}%",
          },
        ],
        showInLegend: false,
      },
    },

    series: [
      {
        type: "pie",
        name: "Progress",
        colorByPoint: true,
        data: chartData,
      },
    ],
  };

  if (useTheme().palette.mode === "dark") {
    DarkUnica(Highcharts);
  } else {
    SandSignika(Highcharts);
  }

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  return (
    <>
      {objective.progress ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
        />
      ) : (
        <DetailsLabel noWrap={false}>
          There is not enough data to display Accomplishment
        </DetailsLabel>
      )}
    </>
  );
};

export default AccomplishmentChart;
