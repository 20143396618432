import { useTheme } from "@mui/material";
import { Button } from "../../../../../../common/modules/materialUI";
import { TMicrosoftAuthButton } from "./types/TMicrosoftAuthButton";

const MicrosoftAuthButton = (props: TMicrosoftAuthButton) => {
  const theme = useTheme();
  const imgSrc = props.login
    ? `${process.env.CDN_URL}/auth/microsoft-auth-button-v3-${theme.palette.mode}.png`
    : `${process.env.CDN_URL}/auth/microsoft-signup-button-${theme.palette.mode}.png`;

  return (
    <Button
      variant="text"
      onClick={props.onClick}
      loading={props.isLoading}
      disabled={props.isLoading}
    >
      <img
        src={imgSrc}
        alt="Microsoft Auth Button"
        style={{ width: "230px", opacity: props.isLoading ? 0.5 : 1 }}
      />
    </Button>
  );
};

export default MicrosoftAuthButton;
