import { useAppDispatch } from "../../../../common/hooks/redux";
import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  TextField,
} from "../../../../common/modules/materialUI";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { TUpdateFormAttributes } from "./types/TUpdateFormAttributes";
import { useDispatchEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import { INetwork } from "../../../../entities/INetwork";
import { enumUtil } from "../../../../common/utils/enum";
import { NetworkTypeEnum } from "../../enums/NetworkTypeEnum";
import { useUpdateService } from "../../services/useUpdateService";
import { TUpdateFormAttributesTransformed } from "./types/TUpdateFormAttributesTransformed";
import { networkingActions } from "../../slices/networkingSlice";
import { NetworkInteractionEnum } from "../../enums/NetworkInteractionEnum";

type TProps = {
  network: INetwork;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
};

const UpdateForm = ({ network, onCancel }: TProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();

  const form = useForm<TUpdateFormAttributes>({
    name: network.name,
    company: network.company,
    notes: network.notes,
    tags: network.tags?.map((tag) => ({
      label: tag,
      value: tag,
      id: tag as NetworkTypeEnum,
    })),
    interaction: network.interaction?.map((interaction) => ({
      label: interaction,
      value: interaction,
      id: interaction as NetworkInteractionEnum,
    })),
  });

  const { isLoading, dispatch: dispatchUpdate } = useUpdateService(network.id);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchUpdate({
      body: form.getTransformed<TUpdateFormAttributesTransformed>((data) => ({
        ...data,
        tags: data.tags?.map((tag) => tag.id!),
        interaction: data.interaction?.map((interaction) => interaction.id!),
      })),
    })
      .then(() => {
        dispatch(networkingActions.setEditableItem(undefined));
        dispatchEvent(EventEnum.ON_NETWORK_UPDATED);
        dispatch(
          systemNotificationActions.open({
            message: "Updated successfully",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button onClick={onCancel} disabled={isLoading} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Update
          </Button>
        </>
      }
    >
      <TextField form={form} name="name" label="Name" />

      <TextField form={form} name="company" label="Company" />

      <TextField form={form} name="notes" label="Notes" multiline />

      <Autocomplete
        form={form}
        name="tags"
        label="Tags"
        options={enumUtil.toList(NetworkTypeEnum)}
        multiple
      />

      <Autocomplete
        form={form}
        name="interaction"
        label="Interaction"
        options={enumUtil.toList(NetworkInteractionEnum)}
        multiple
      />
    </Form>
  );
};

export default UpdateForm;
