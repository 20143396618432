import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TCustomFieldState } from "./types/TCustomFieldState";
import { ICustomField } from "../../../entities/ICustomField";

const initialState: TCustomFieldState = {
  create: {
    showModal: false,
    // last time the record was created, analog to raising "RecordCreated" event
    createdAtTimestamp: null,
  },
  update: {
    showModal: false,
    // last time the record was updated, analog to raising "RecordUpdated" event
    updatedAtTimestamp: null,
    editableRecord: null,
  },
  delete: {
    showConfirmation: false,
    // last time the record was deleted, analog to raising "RecordDeleted" event
    deletedAtTimestamp: null,
    deletableRecord: null,
  },
};

const slice = createSlice({
  name: "customField",
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.create.showModal = true;
    },
    closeCreateModal: (state) => {
      state.create.showModal = false;
    },
    recordCreated: (state) => {
      state.create.createdAtTimestamp = Date.now();
    },

    openUpdateModal: (state, action: PayloadAction<ICustomField>) => {
      state.update.showModal = true;
      state.update.editableRecord = action.payload;
    },
    closeUpdateModal: (state) => {
      state.update.showModal = false;
      state.update.editableRecord = null;
    },
    recordUpdated: (state) => {
      state.update.updatedAtTimestamp = Date.now();
    },

    openDeleteConfirmation: (state, action: PayloadAction<ICustomField>) => {
      state.delete.showConfirmation = true;
      state.delete.deletableRecord = action.payload;
    },
    closeDeleteConfirmation: (state) => {
      state.delete.showConfirmation = false;
      state.delete.deletableRecord = null;
    },
    recordDeleted: (state) => {
      state.delete.deletedAtTimestamp = Date.now();
    },
  },
});

export const customFieldReducer = slice.reducer;

export const customFieldActions = slice.actions;
