import { TTaskTypeState } from "./types/TTaskTypeState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITaskType } from "../../../entities/ITaskType";

const initialState: TTaskTypeState = {
  create: {
    showModal: false,
  },
  update: {
    showModal: false,
    editableRecord: null,
  },
  delete: {
    showConfirmation: false,
    deletableRecord: null,
  },
};

const slice = createSlice({
  name: "taskType",
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.create.showModal = true;
    },
    closeCreateModal: (state) => {
      state.create.showModal = false;
    },

    openUpdateModal: (state, action: PayloadAction<ITaskType>) => {
      state.update.showModal = true;
      state.update.editableRecord = action.payload;
    },
    closeUpdateModal: (state) => {
      state.update.showModal = false;
      state.update.editableRecord = null;
    },

    openDeleteConfirmation: (state, action: PayloadAction<ITaskType>) => {
      state.delete.showConfirmation = true;
      state.delete.deletableRecord = action.payload;
    },
    closeDeleteConfirmation: (state) => {
      state.delete.showConfirmation = false;
      state.delete.deletableRecord = null;
    },
  },
});

export const taskTypeReducer = slice.reducer;

export const taskTypeActions = slice.actions;
