import useForm from "../../../../../../common/hooks/useForm/useForm";
import Form from "../../../../../../common/components/Form/Form";
import { Button, TextField } from "../../../../../../common/modules/materialUI";
import { TUpdateFormAttributes } from "./types/TUpdateFormAttributes";
import { Grid } from "@mui/material";
import TUpdateFormProps from "./types/TUpdateFormProps";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { useUpdateBoardSettingsService } from "../../../../services/useUpdateBoardSettingsService";

const UpdateForm = (props: TUpdateFormProps) => {
  const form = useForm<TUpdateFormAttributes>(props.data);
  const { isLoading, dispatch: dispatchUpdateBoardSettings } =
    useUpdateBoardSettingsService(props.boardRef);
  const isDisabled = isLoading || props.isDataLoading;

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchUpdateBoardSettings({ body: form.data })
      .then(() => {
        form.notDirty();
        props.onSuccess(form.data!.name, form.data!.workingHours);
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            disabled={isDisabled || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Save
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            form={form}
            name="name"
            label="Name"
            showRequired
            disabled={isDisabled}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            form={form}
            name="workingHours"
            label="Working Hours"
            showRequired
            disabled={true}
            helperText="This field is temporarily disabled, support for it will be added in no time."
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default UpdateForm;
