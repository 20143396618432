import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { TUseDeleteCustomFieldServiceProps } from "./types/TUseDeleteCustomFieldServiceProps";

export const useDeleteCustomFieldService = ({
  customFieldId,
}: TUseDeleteCustomFieldServiceProps) => {
  return useHttpClientDispatchable({
    url: `/customField/${customFieldId}`,
    method: RequestMethodEnum.DELETE,
  });
};
