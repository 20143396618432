import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { THolisticViewState } from "./types/THolisticViewState";
import { mergeUserLaneData } from "../hooks/useInitData";
import { windowHelper } from "../../../../../common/utils/windowHelper";
import moment from "moment/moment";
import { LoadDirectionEnum } from "../../../enums/LoadDirectionEnum";
import { resetScrollCheckpoints } from "../hooks/useVirtualizationHelper";
import { FORMAT_RAW_DATE_ONLY } from "../../../../../common/utils/date";

export const timelineDisplayMonths = 2;

const initialState: THolisticViewState = {
  workingHours: 8,
  havingBoardUsers: false,
  displayCostPerItem: false,
  virtualizationParams: {
    weeksToLoad: windowHelper.widthToWeeks(),
    loadFromDate: moment().toISOString(),
    loadDirection: LoadDirectionEnum.INIT,
  },
  timelineDisplayFromDate: moment()
    .subtract(timelineDisplayMonths / 2, "month")
    .startOf("day")
    .format(FORMAT_RAW_DATE_ONLY),
  timelineDisplayToDate: moment()
    .add(timelineDisplayMonths / 2, "month")
    .format(FORMAT_RAW_DATE_ONLY),
};

const slice = createSlice({
  name: "holisticView",
  initialState,
  reducers: {
    setWorkingHours: (
      state,
      action: PayloadAction<THolisticViewState["workingHours"]>
    ) => {
      state.workingHours = action.payload;
    },
    resetData: (state) => {
      state.data = undefined;
      state.virtualizationParams = initialState.virtualizationParams;
      state.timelineDisplayFromDate = initialState.timelineDisplayFromDate;
      state.timelineDisplayToDate = initialState.timelineDisplayToDate;
      resetScrollCheckpoints();
    },
    resetVirtualizationData: (state) => {
      state.virtualizationParams = {
        ...initialState.virtualizationParams,
        lastReset: moment().toISOString(),
      };
    },
    setData: (
      state,
      action: PayloadAction<{
        data: THolisticViewState["data"] | undefined;
        loadDirection: LoadDirectionEnum;
      }>
    ) => {
      state.data = mergeUserLaneData(
        state.data ? current(state.data) : undefined,
        action.payload.data,
        action.payload.loadDirection
      );
    },
    displayUserLaneOverlay: (state, action: PayloadAction<boolean>) => {
      !state.userLaneOverlay && (state.userLaneOverlay = {});
      state.userLaneOverlay.display = action.payload;
      !action.payload && (state.userLaneOverlay.animate = false);
    },
    animateUserLaneOverlay: (state, action: PayloadAction<boolean>) => {
      !state.userLaneOverlay && (state.userLaneOverlay = {});
      state.userLaneOverlay.animate = action.payload;
    },
    displayCostPerItem: (state, action: PayloadAction<boolean>) => {
      state.displayCostPerItem = action.payload;
    },
    setVirtualizationParams: (
      state,
      action: PayloadAction<{
        data: THolisticViewState["virtualizationParams"];
      }>
    ) => {
      if (
        state.virtualizationParams.weeksToLoad !==
          action.payload.data.weeksToLoad ||
        state.virtualizationParams.loadFromDate !==
          action.payload.data.loadFromDate ||
        state.virtualizationParams.loadDirection !==
          action.payload.data.loadDirection
      ) {
        state.virtualizationParams = action.payload.data;
      }
    },
    expandTimelineDisplayFromDate(state) {
      state.timelineDisplayFromDate = moment(state.timelineDisplayFromDate)
        .subtract(timelineDisplayMonths / 2, "month")
        .startOf("day")
        .format(FORMAT_RAW_DATE_ONLY);
    },
    expandTimelineDisplayToDate(state) {
      state.timelineDisplayToDate = moment(state.timelineDisplayToDate)
        .add(timelineDisplayMonths / 2, "month")
        .format(FORMAT_RAW_DATE_ONLY);
    },
    setHavingBoardUsers: (state, action: PayloadAction<boolean>) => {
      state.havingBoardUsers = action.payload;
    },
  },
});

export const holisticViewReducer = slice.reducer;

export const holisticViewActions = slice.actions;
