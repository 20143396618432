import { TListResource } from "../../../common/types/TListResource";

export enum TypeEnum {
  TEXT_FIELD = "TextField",
  OPTIONS = "Options",
  DATE = "Date",
}

export const typeLabels: { [key in TypeEnum]: string } = {
  [TypeEnum.TEXT_FIELD]: "Text Field",
  [TypeEnum.OPTIONS]: "Options",
  [TypeEnum.DATE]: "Date",
};

export const typeOptions: TListResource<TypeEnum> = Object.entries(
  typeLabels
).map(([id, label]) => ({ id: id as TypeEnum, label }));
