import Container from "./Container";
import React from "react";
import Item from "./Item";

export default function FeaturesUpcoming() {
  return (
    <Container>
      <Item est="hot">Event</Item>
      <Item
        est="live"
        description="See the user's involvement in other initiatives or projects on any board with our unique Occupied Space feature"
      >
        Occupied Space
      </Item>
      <Item est="hot">Non-Workday automation</Item>
      <Item est="hot">AI Sentinel Upgrade</Item>
      <Item est="live">Special Sprint Control</Item>
      <Item est="live">Board Default Filters</Item>
      <Item est="live">Virtual Users</Item>
      <Item est="live">Objectives</Item>
      <Item est="soon">Holistic Dashboard</Item>
      <Item est="live">Backlog / Icebox</Item>
      <Item est="hot">Search engine</Item>
      <Item est="live">Manual Split option</Item>
      <Item est="hot">Status flow Auto Transition</Item>
      <Item est="soon">Guesstimation Board</Item>
      <Item est="soon">Readonly Boards</Item>
      <Item est="soon">Monitors</Item>
      <Item est="soon">Task States</Item>
      <Item est="soon">Workflow Automation</Item>
      <Item est="hot">Interactive help me</Item>
      <Item est="live">Spike</Item>
      <Item est="soon">Monthly and Panoramic vision</Item>
      <Item est="hot">Mobile app</Item>
    </Container>
  );
}
