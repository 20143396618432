import classes from "../../common/Navigation.module.scss";
import { ArrowBack } from "@mui/icons-material";
import { TBillingSettingsNavigationContentProps } from "./types/TBillingSettingsNavigationContentProps";
import List from "../../common/List/List";
import { array } from "../../../../../../utils/array";
import BillingSettingsSection from "./BillingSettingsSection";
import { useAccessControl } from "../../../../../../modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../../../modules/accessControl/enums/AbilityEnum";

const BillingSettingsNavigationContent = ({
  matchesMax992Screen,
  pushContentClassName,
  navigationRequestedHandler,
}: TBillingSettingsNavigationContentProps) => {
  const { can } = useAccessControl();

  return (
    <nav
      className={array.toClassName([
        classes.navigation,
        !matchesMax992Screen ? pushContentClassName : "",
      ])}
    >
      <List
        title={"Billing Settings"}
        items={[
          {
            id: "billingSettingsBack",
            name: "Back",
            path: `/`,
            icon: <ArrowBack />,
          },
        ]}
      />
      {can(AbilityEnum.financing) && (
        <BillingSettingsSection onClick={navigationRequestedHandler} />
      )}
    </nav>
  );
};

export default BillingSettingsNavigationContent;
