import { TImageProps } from "./types/TImageProps";
import { useTheme } from "@mui/material";

export default function Image(props: TImageProps) {
  const theme = useTheme();

  const imagePath = `${process.env.CDN_URL}/icons/landingPage/`;
  const imageExtension = props.imageExtension ?? ".png";
  const themeSuffix = `-${theme.palette.mode}`;
  const imageSrc = `${imagePath}${props.imageName}${themeSuffix}${imageExtension}`;

  return (
    <img src={imageSrc} alt={props.imageName} width={props.width ?? "150"} />
  );
}
