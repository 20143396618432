import { Box, useTheme } from "@mui/material";
import useMedia from "../../../../../hooks/useMedia/useMedia";
import { useAppSelector } from "../../../../../hooks/redux";
import { QueryEnum } from "../../../../../hooks/useMedia/enums/QueryEnum";
import AiSentinel from "../../../../../../modules/aiSentinel/containers/AiSentinel/AiSentinel";
import HelpCenterIcon from "../../../../../../modules/helpCenter/containers/HelpCenterIcon";

const FloatingActions = () => {
  const maxWidth768 = useMedia(QueryEnum.MAX_WIDTH_768);
  const isOpen = useAppSelector((state) => state.aiSentinel.isOpen);
  const theme = useTheme();

  return (
    <Box
      position="fixed"
      zIndex={isOpen && maxWidth768 ? 0 : 1500}
      bottom="20px"
      right="20px"
      display="flex"
      gap="12px"
      sx={{
        "> button": {
          width: "40px",
          minWidth: "40px",
          height: "40px",
          borderRadius: "8px",
          backgroundColor: theme.palette.floatingActions.buttonBackgroundColor,
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px",
          "&:hover": {
            backgroundColor: "rgba(39,141,118,0.18)",
          },
        },
      }}
    >
      <AiSentinel />
      <HelpCenterIcon />
    </Box>
  );
};

export default FloatingActions;
