import { Groups3 } from "@mui/icons-material";
import { Button } from "../../../../common/modules/materialUI";
import { useAppDispatch } from "../../../../common/hooks/redux";
import CreateVirtualUserModal from "./CreateVirtualUserModal";
import { teamActions } from "../../slices/teamSlice";

const CreateVirtualUser = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Button
        variant="text"
        startIcon={<Groups3 />}
        onClick={() => dispatch(teamActions.openCreateVirtualUserModal())}
      >
        Add a Virtual User
      </Button>
      <CreateVirtualUserModal />
    </>
  );
};

export default CreateVirtualUser;
