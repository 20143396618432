import { Box, Grid } from "@mui/material";
import {
  Button,
  DataGrid,
  DatePicker,
  Tooltip,
} from "../../../../../common/modules/materialUI";
import { Columns } from "./Columns";
import Loader from "../../../../../common/containers/Loader/Loader";
import useForm from "../../../../../common/hooks/useForm/useForm";
import { TSearchFormAttributes } from "./types/TSearchFormAttributes";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Form from "../../../../../common/components/Form/Form";
import { useFetchResourceAvailabilityDataService } from "./services/useFetchResourceAvailabilityDataService";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { TSearchFormAttributesTransformed } from "./types/TSearchFormAttributesTransformed";
import { featureUtil } from "../../../../../common/modules/accessControl/utils/featureUtil";
import { useIsFeatureAllowed } from "../../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../common/modules/accessControl/enums/FeatureEnum";

const ResourceAvailability = () => {
  const hasTeamResourceAvailabilityAllowed = useIsFeatureAllowed(
    FeatureEnum.TEAM_RESOURCE_AVAILABILITY
  );
  const { isLoading: isLoading, dispatch: dispatchResourceAvailabilityData } =
    useFetchResourceAvailabilityDataService();
  const dispatch = useAppDispatch();

  const form = useForm<TSearchFormAttributes>({
    startDate: moment().startOf("week"),
    endDate: moment().startOf("week").add(3, "weeks"),
  });

  const [data, setData] = useState([]);

  const loadData = function () {
    if (!hasTeamResourceAvailabilityAllowed) {
      return;
    }

    dispatchResourceAvailabilityData({
      query: form.getTransformed<TSearchFormAttributesTransformed>((data) => ({
        startDate: data.startDate.toISOString(),
        endDate: data.endDate.toISOString(),
      })),
    })
      .then((payload) => {
        setData(payload);
      })
      .catch((error: THttpClientError) => {
        if (error.status === 422) {
          form.errorHandler(error);
        } else {
          dispatch(
            systemNotificationActions.open({
              variant: "error",
              message:
                error.status === 403
                  ? error.data.message
                  : "Something went wrong. Please try again.",
            })
          );
        }
      });
  };

  useEffect(loadData, []);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    loadData();
  };

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <DatePicker
              form={form}
              name="startDate"
              label="Start Date"
              showRequired
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DatePicker
              form={form}
              name="endDate"
              label="End Date"
              showRequired
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Tooltip
              withSpanWrapper
              title={featureUtil.notAvailableText}
              when={!hasTeamResourceAvailabilityAllowed}
            >
              <Button
                disabled={isLoading || !hasTeamResourceAvailabilityAllowed}
                variant="contained"
                type="submit"
                loading={isLoading}
              >
                Calculate
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Form>
      <Box sx={{ width: "100%" }}>
        <DataGrid
          columnHeaderHeight={0}
          rows={data}
          getRowId={(row) => row.id}
          columns={Columns({})}
          disableRowSelectionOnClick
          checkboxSelection={false}
        />
        {isLoading && <Loader />}
      </Box>
    </>
  );
};

export default ResourceAvailability;
