import classes from "./ChatHeader.module.css";
import { IconButton, useTheme } from "@mui/material";
import AiSentinelIcon from "../AiSentinel/AiSentinelIcon";
import { aiSentinelActions } from "../../slices/aiSentinelSlice";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { Close as CloseIcon } from "@mui/icons-material";

export default function ChatHeader() {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  return (
    <div
      className={classes.header}
      style={{
        backgroundColor: theme.palette.aiSentinel.headerBackgroundColor,
      }}
    >
      <AiSentinelIcon size={50} headerIcon padding="10px" />
      <IconButton
        className={classes.closeIcon}
        onClick={() => {
          dispatch(aiSentinelActions.toggleOpen());
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );
}
