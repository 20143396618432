import { Autocomplete } from "../../../../../common/modules/materialUI";
import { TCustomFieldInputProps } from "../types/TCustomFieldInputProps";
import { customFieldInputUtil } from "../../../utils/customFieldInputUtil";

const CustomOptionsField = <TFormAttributes extends unknown>({
  customField,
  form,
  showLabel,
  submitHandler,
  hideRequiredMark,
}: TCustomFieldInputProps<TFormAttributes>) => {
  return (
    <Autocomplete
      form={form}
      name={customFieldInputUtil.generateName(customField)}
      label={showLabel ? customField.name : undefined}
      showRequired={!hideRequiredMark && customField.isRequired}
      multiple={customField.isMultiple}
      options={customField.data?.options}
      onChange={submitHandler ? (_, value) => submitHandler(value) : undefined}
    />
  );
};

export default CustomOptionsField;
