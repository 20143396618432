import { TAppSettingsNavigationContentProps } from "./types/TAppSettingsNavigationContentProps";
import { array } from "../../../../../../utils/array";
import classes from "../../common/Navigation.module.scss";
import List from "../../common/List/List";
import { ArrowBack } from "@mui/icons-material";
import AppSettingsSection from "./AppSettingsSection";

const AppSettingsNavigationContent = ({
  matchesMax992Screen,
  pushContentClassName,
  navigationRequestedHandler,
}: TAppSettingsNavigationContentProps) => {
  return (
    <nav
      className={array.toClassName([
        classes.navigation,
        !matchesMax992Screen ? pushContentClassName : "",
      ])}
    >
      <List
        title="Return to Main Menu"
        items={[
          {
            id: "settingsBack",
            name: "Back",
            path: "/",
            icon: <ArrowBack />,
          },
        ]}
      />
      <AppSettingsSection onClick={navigationRequestedHandler} />
    </nav>
  );
};

export default AppSettingsNavigationContent;
