import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IGuesstimationItemDetailsResource } from "../interfaces/IGuesstimationItemDetailsResource";

export interface IResizeGuesstimationItemServiceRequest {
  dueDate: string;
}

export const useResizeGuesstimationItemService = (
  guesstimationItemId: number
) => {
  const response = useHttpClientDispatchable({
    url: `/guesstimationItem/${guesstimationItemId}/resize`,
    method: RequestMethodEnum.PATCH,
    body: {
      confirmed: true,
    },
  });

  return {
    ...response,
    data: response.data as IGuesstimationItemDetailsResource,
  };
};
