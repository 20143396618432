import { useAppDispatch } from "../../../../common/hooks/redux";
import { RequestMethodEnum } from "../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClientDispatchable } from "../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { IData } from "../../../../entities/columns/objective/IData";
import { initiativeActions } from "../../slices/initiativeSlice";

export const useFetchInitiativeListService = (objectiveId: number) => {
  const dispatch = useAppDispatch();

  return useHttpClientDispatchable({
    url: `/objective/${objectiveId}/initiative`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IData[]) =>
        dispatch(initiativeActions.setList(data)),
    },
  });
};
