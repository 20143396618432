import { Box, Step, StepButton, StepContent, Stepper } from "@mui/material";
import { Button } from "../../../materialUI";
import { TStepperProps } from "./types/TStepperProps";
import Steps from "../Steps/Steps";
import Congratulate from "./Congratulate";
import { landingPageActions } from "../../slices/landingPageSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

export default function StepperVertical(props: TStepperProps) {
  const state = useAppSelector((state) => state.landingPage);
  const dispatch = useAppDispatch();
  const steps = Steps();

  return (
    <Box sx={{ width: "100%", ...props.sx }}>
      <Stepper nonLinear activeStep={state.activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label} completed={props.completionState[index]}>
            <StepButton
              color="inherit"
              onClick={() => dispatch(landingPageActions.setActiveStep(index))}
            >
              {step.label}
            </StepButton>
            <StepContent>
              {!props.completionState[index]
                ? step.content
                : step.contentCompleted}
              <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
                <div>
                  <Button
                    color="inherit"
                    disabled={index === 0}
                    onClick={() => dispatch(landingPageActions.handleBack())}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={() => dispatch(landingPageActions.handleNext())}
                  >
                    Next
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {props.allStepsCompleted && <Congratulate />}
    </Box>
  );
}
