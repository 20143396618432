import TaskInfoTabsContainer from "../../../../../../common/modules/taskView/containers/InfoTabs/InfoTabs";
import { TGuesstimationItemInfoTabsProps } from "./types/TGuesstimationItemInfoTabsProps";
import { EntityTypeEnum } from "../../../../../../entities/enums/EntityTypeEnum";
import Attachment from "../../../../../attachment/containers/Attachment/Attachment";
import GuesstimationItemViewDetails from "../GuesstimationItemViewDetails/GuesstimationItemViewDetails";
import Assignees from "../Assignees/Assignees";

export default function GuesstimationItemInfoTabs({
  resource,
  isMobileView,
  isPageView,
}: TGuesstimationItemInfoTabsProps) {
  return (
    <TaskInfoTabsContainer
      mainPanels={[
        ...(isMobileView
          ? [
              {
                tabIndex: "details-tab",
                tabLabel: "Details",
                tabComponent: (
                  <GuesstimationItemViewDetails resource={resource} />
                ),
              },
            ]
          : []),
        {
          tabIndex: "guesstimation-item-assignees-tab",
          tabLabel: "Assignees",
          tabComponent: (
            <Assignees
              tabIndex="guesstimation-item-assignees-tab"
              resource={resource}
              isMobileView={isMobileView}
              isPageView={isPageView}
            />
          ),
        },
        {
          tabIndex: "attachments-tab",
          tabLabel: "Attachments",
          tabComponent: (
            <Attachment
              tabIndex="attachments-tab"
              entityType={EntityTypeEnum.GUESSTIMATION_ITEM}
              entityId={resource.guesstimationItem.id}
              isMobileView={isMobileView}
            />
          ),
        },
      ]}
      storageKey="unpinned-task-view-tabs"
      isMobileView={isMobileView}
    />
  );
}
