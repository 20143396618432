import { TTaskCardProps } from "../../../../../common/components/Task/components/TaskCard/types/TTaskCardProps";
import { useUpdateTaskSegmentStatusService } from "../../../../task/services/useUpdateTaskSegmentStatusService";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { DRAG_TYPE_TASK_CARD } from "../containers/Task/Task";
import { useEffect } from "react";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { kanbanViewTaskActions } from "../slices/kanbanViewTaskSlice";
import { ITaskPatchResource } from "../../../../task/modules/view/interfaces/ITaskPatchResource";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { IStatus } from "../../../../../entities/IStatus";
import { useUpdateTaskStatusService } from "../../../../task/services/useUpdateTaskStatusService";
import { useDispatchEvent } from "../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../common/modules/eventProvider/enums/EventEnum";

export const useDragAndDropHelper = (props: TTaskCardProps) => {
  const dispatch = useAppDispatch();
  const { dispatch: dispatchUpdateTaskSegmentStatus } =
    useUpdateTaskSegmentStatusService(props.task.segmentId);
  const { dispatch: dispatchUpdateTaskStatus } = useUpdateTaskStatusService(
    props.task.id
  );
  const dispatchEvent = useDispatchEvent();

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DRAG_TYPE_TASK_CARD,
      item: props,
      end(_, monitor) {
        const dropResult = monitor.getDropResult() as IStatus | null;
        if (!dropResult || props.statusColumn.id === dropResult.id) {
          return;
        }

        const dispatchService = props.task.segmentId
          ? dispatchUpdateTaskSegmentStatus
          : dispatchUpdateTaskStatus;

        dispatch(kanbanViewTaskActions.showShiftingProgress(true));

        dispatchService({
          body: {
            oldStatusId: props.statusColumn.id,
            statusId: dropResult.id,
          },
        })
          .then((payload: ITaskPatchResource) => {
            dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED, {}, () => {
              dispatch(kanbanViewTaskActions.showShiftingProgress(false));
            });
          })
          .catch((error: THttpClientError) => {
            dispatch(
              systemNotificationActions.open({
                message:
                  "There was a problem with updating the status. Please try again.",
                variant: "error",
              })
            );
            dispatch(kanbanViewTaskActions.showShiftingProgress(false));
          });
      },
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  );

  useEffect(() => {
    dispatch(kanbanViewTaskActions.setIsDragging(isDragging));
  }, [isDragging]);

  return {
    isDragging,
    drag,
  };
};
