import { BoardTypeEnum } from "../../entities/columns/board/BoardTypeEnum";
import { EntityTypeEnum } from "../../entities/enums/EntityTypeEnum";

export const boardUtil = {
  isBoardIntegrationAvailableForType: (boardType?: BoardTypeEnum) =>
    !!boardType &&
    [
      BoardTypeEnum.HOLISTIC,
      BoardTypeEnum.BACKLOG,
      BoardTypeEnum.INITIATIVE_MANAGEMENT,
    ].includes(boardType),
  isTaskDefaultValuesAvailableForType: (boardType?: BoardTypeEnum) =>
    !!boardType &&
    [
      BoardTypeEnum.HOLISTIC,
      BoardTypeEnum.BACKLOG,
      BoardTypeEnum.INITIATIVE_MANAGEMENT,
    ].includes(boardType),
  isCustomFieldsSupportedForType: (boardType?: BoardTypeEnum) =>
    !!boardType &&
    [
      BoardTypeEnum.HOLISTIC,
      BoardTypeEnum.BACKLOG,
      BoardTypeEnum.INITIATIVE_MANAGEMENT,
    ].includes(boardType),
  getEntityTypeByBoardType: (boardType?: BoardTypeEnum): EntityTypeEnum => {
    switch (boardType) {
      case BoardTypeEnum.GUESSTIMATION:
        return EntityTypeEnum.GUESSTIMATION_ITEM;
      case BoardTypeEnum.BACKLOG:
        return EntityTypeEnum.BACKLOG_ITEM;
      default:
        return EntityTypeEnum.TASK;
    }
  },
};
