import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TViewState } from "./types/TViewState";
import { ITask } from "../../../../../entities/ITask";
import { AddAssigneesTypeEnum } from "./enums/AddAssigneesTypeEnum";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";

const initialState: TViewState = {};

const slice = createSlice({
  name: "taskView",
  initialState,
  reducers: {
    open: (
      state,
      action: PayloadAction<{ taskRef: string; segmentNumber?: number }>
    ) => {
      state.taskRef = action.payload.taskRef;
      state.taskSegmentNumber = action.payload.segmentNumber;
    },
    setViewType: (state, action: PayloadAction<TViewState["viewType"]>) => {
      state.viewType = action.payload;
    },
    setTaskSegments: (
      state,
      action: PayloadAction<TViewState["taskSegments"]>
    ) => {
      state.taskSegments = action.payload;
    },
    openMoveToBacklogConfirmation: (
      state,
      action: PayloadAction<{ taskId: number }>
    ) => {
      state.moveToBacklog = {
        openConfirmation: true,
        taskId: action.payload.taskId,
      };
    },
    closeMoveToBacklogConfirmation: (state) => {
      state.moveToBacklog = undefined;
    },
    openConvertInternalTypeConfirmation: (
      state,
      action: PayloadAction<{
        taskId: number;
        taskInternalType: InternalTypeEnum;
        targetInternalType: InternalTypeEnum;
      }>
    ) => {
      state.convertToInternalType = {
        openConfirmation: true,
        taskId: action.payload.taskId,
        taskInternalType: action.payload.taskInternalType,
        targetInternalType: action.payload.targetInternalType,
      };
    },
    closeConvertInternalTypeConfirmation: (state) => {
      state.convertToInternalType = undefined;
    },
    openAddAssigneesDialog: (
      state,
      action: PayloadAction<{
        taskId: ITask["taskId"];
        type: AddAssigneesTypeEnum;
      }>
    ) => {
      state.addAssigneesDialog = {
        open: true,
        taskId: action.payload.taskId,
        type: action.payload.type,
      };
    },
    closeAddAssigneesDialog: (state) => {
      state.addAssigneesDialog = undefined;
    },
    openDeleteSegmentConfirmation: (
      state,
      action: PayloadAction<{
        segmentId: number;
        taskRef: ITask["ref"];
        isLastSegment: boolean;
      }>
    ) => {
      state.deleteSegment = {
        openConfirmation: true,
        segmentId: action.payload.segmentId,
        taskRef: action.payload.taskRef,
        isLastSegment: action.payload.isLastSegment,
      };
    },
    closeDeleteSegmentConfirmation: (state) => {
      state.deleteSegment = undefined;
    },
    reset: () => initialState,
  },
});

export const taskViewReducer = slice.reducer;

export const taskViewActions = slice.actions;
