import { TBacklogItemCreateState } from "./types/TBacklogItemCreateState";
import { createSlice } from "@reduxjs/toolkit";

const initialState: TBacklogItemCreateState = {
  displayModal: false,
};

const slice = createSlice({
  name: "backlogItemView",
  initialState,
  reducers: {
    openCreationModal: (state) => {
      state.displayModal = true;
    },
    closeCreationModal: (state) => {
      state.displayModal = false;
    },
  },
});

export const backlogItemCreateReducer = slice.reducer;

export const backlogItemCreateActions = slice.actions;
