import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IBacklogItem } from "../../../../../entities/IBacklogItem";

export const useCreateBacklogItemService = () => {
  const response = useHttpClientDispatchable({
    url: "/backlog/item",
    method: RequestMethodEnum.POST,
  });

  return {
    ...response,
    data: response.data as IBacklogItem,
  };
};
