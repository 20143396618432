import { useParams } from "react-router-dom";
import Loader from "../../../../common/containers/Loader/Loader";
import { HeaderRegistryEnum } from "../../../../common/modules/registry/enums/HeaderRegistryEnum";
import Title from "../../../../common/components/Title/Title";
import { TBoardParams } from "../../../board/containers/Board/types/TBoardParams";
import UpdateForm from "./UpdateForm";
import { useFetchProjectService } from "../../services/useFetchProjectService";
import useRegisterHeader from "../../../../common/modules/registry/hooks/useRegisterHeader";

const UpdateProjectPage = () => {
  const { projectAbbr } = useParams<TBoardParams>();
  const { data, isLoading } = useFetchProjectService({
    projectAbbr: projectAbbr!,
  });
  useRegisterHeader(HeaderRegistryEnum.headerBackButtonOnly, [projectAbbr]);

  return (
    <>
      <Title>Update Project</Title>
      {isLoading && <Loader />}
      <UpdateForm isDataLoading={isLoading} data={data} />
    </>
  );
};

export default UpdateProjectPage;
