import { Box, Typography } from "@mui/material";
import { AddLinkOutlined } from "@mui/icons-material";
import { TTaskMenuProps } from "./types/TTaskMenuProps";
import { menuItem } from "../../../../containers/Menu/utils/menuItem";
import useUtils from "../../../../../modules/linkList/hooks/useUtils";
import { useAppDispatch } from "../../../../hooks/redux";
import { linkActions } from "../../../../../modules/link/slices/linkSlice";
import { EntityTypeEnum } from "../../../../../entities/enums/EntityTypeEnum";

const TaskLinkMenuItem = (props: TTaskMenuProps) => {
  const dispatch = useAppDispatch();
  const { addLinkLabel } = useUtils();

  const openModalHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      linkActions.openCreateLinkModal({
        entityType: EntityTypeEnum.TASK,
        entityId: props.taskId,
        projectRef: props.projectRef,
      })
    );
  };

  return menuItem.make(
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      onClick={(e) => openModalHandler(e)}
    >
      <AddLinkOutlined fontWeight="400" />
      <Typography variant="subtitle2" fontWeight="400">
        {addLinkLabel}
      </Typography>
    </Box>
  );
};

export default TaskLinkMenuItem;
