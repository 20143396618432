import { TTaskResourceProp } from "./types/TTaskResourceProp";
import { Typography } from "@mui/material";

const DaysLeft = (props: TTaskResourceProp) => {
  const remainingTime = () => {
    let remainingTime = "";
    if (props.task.workingHours.daysRemaining > 0) {
      remainingTime += `${props.task.workingHours.daysRemaining}d`;
    }
    if (props.task.workingHours.hoursRemaining > 0) {
      remainingTime += ` ${props.task.workingHours.hoursRemaining}h`;
    }

    return remainingTime;
  };

  return (
    <Typography variant="caption">
      Time left:{" "}
      <Typography fontWeight="bold" variant="caption" color="error">
        {!props.task.workingHours.delayed ? remainingTime() : "delayed"}
      </Typography>
    </Typography>
  );
};

export default DaysLeft;
