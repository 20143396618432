import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TLandingPageState } from "./types/TLandingPageState";

const initialState: TLandingPageState = {
  activeStep: 0,
};

const slice = createSlice({
  name: "landingPage",
  initialState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    handleBack: (state) => {
      state.activeStep -= 1;
    },
    handleNext: (state) => {
      if (state.activeStep !== 2) {
        state.activeStep += 1;
      } else {
        state.activeStep = 0;
      }
    },
  },
});

export const landingPageReducer = slice.reducer;

export const landingPageActions = slice.actions;
