import { TBoardViewProps } from "../../../../board/types/TBoardViewProps";
import ListViewContent from "./ListViewContent";
import LinkModal from "../../../../link/containers/Create/LinkModal";
import TaskViewModal from "../../../../task/modules/view/containers/TaskViewModal";

const ListView = (props: TBoardViewProps) => {
  return (
    <>
      <ListViewContent
        board={props.board}
        projectAbbr={props.projectAbbr}
        boardRef={props.boardRef}
      />
      <TaskViewModal />
      <LinkModal />
    </>
  );
};

export default ListView;
