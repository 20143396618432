import { THeaderSchemaProps } from "./types/THeaderSchemaProps";
import classes from "./Mobile.module.scss";
import Back from "../../../../../components/Back/Back";
import { array } from "../../../../../utils/array";

const Mobile = (props: THeaderSchemaProps) => {
  const registerLeft = !!props.registerLeft;
  const registerRight = !!props.registerRight;
  const renderBack = !!props.displayBackButton;

  return (
    <div className={classes.container}>
      {renderBack && (
        <div className={classes["navigate-back"]}>
          <Back />
        </div>
      )}
      {(registerLeft || registerRight) && (
        <div
          className={array.toClassName([
            classes.actions,
            !renderBack ? classes["actions-stretch"] : "",
          ])}
        >
          {registerLeft && <div>{props.registerLeft}</div>}
          {/*{registerLeft && registerRight && (*/}
          {/*  <Divider orientation="vertical" className={classes.divider} />*/}
          {/*)}*/}
          {registerRight && <div>{props.registerRight}</div>}
        </div>
      )}
    </div>
  );
};

export default Mobile;
