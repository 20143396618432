import { ConfirmationDialog } from "../../../../common/modules/materialUI";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useDeleteLinkListService } from "../../services/useDeleteLinkListService";
import { linkListActions } from "../../slices/linkListSlice";

const DeleteLinkListDialog = () => {
  const { showConfirmation, deletableRecord } = useAppSelector(
    (state) => state.linkList.delete
  );
  const dispatch = useAppDispatch();
  const { isLoading, dispatch: dispatchDelete } = useDeleteLinkListService({
    id: (deletableRecord?.id as number) ?? 0,
  });

  const closeConfirmationHandler = () =>
    dispatch(linkListActions.closeDeleteConfirmation());

  const successHandler = () => {
    dispatch(linkListActions.closeDeleteConfirmation());
    dispatch(
      linkListActions.removeFromList({
        id: deletableRecord?.id as number,
      })
    );
    dispatchDelete().then(() => {
      dispatch(
        systemNotificationActions.open({
          message: "Link deleted successfully",
          variant: "success",
        })
      );
    });
  };

  return (
    <ConfirmationDialog
      open={showConfirmation}
      title="Delete Task Link"
      content={`Are you sure you want to delete the task link "${deletableRecord?.description}"?`}
      loading={isLoading}
      closeHandler={closeConfirmationHandler}
      successHandler={successHandler}
    />
  );
};

export default DeleteLinkListDialog;
