import UploadIconButton from "../../iconLibrary/containers/IconLibraryPicker/UploadIconButton";
import useGetSignedUploadUrlToCloud from "../../../common/hooks/useGetSignedUploadUrlToCloud/useGetSignedUploadUrlToCloud";
import { TFileData } from "../../../common/hooks/useGetSignedUploadUrlToCloud/types/TFileData";
import useUploadFileToCloud from "../../../common/hooks/useUploadFileToCloud/useUploadFileToCloud";
import { useAppDispatch } from "../../../common/hooks/redux";
import { useNetworkingGetSignedUploadUrlService } from "../services/useNetworkingGetSignedUploadUrlService";
import { useState } from "react";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAttachAssetService } from "../services/useAttachAssetService";
import { INetwork } from "../../../entities/INetwork";
import { EventEnum } from "../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../common/modules/eventProvider";

type TProps = {
  item: INetwork;
};

export default function AttachAsset({ item }: TProps) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();

  const { dispatch: dispatchGetSignedUploadUrlToCloud } =
    useGetSignedUploadUrlToCloud();
  const { dispatch: dispatchUploadIconToCloud } = useUploadFileToCloud();
  const { dispatch: dispatchNetworkingGetSignedUploadUrlService } =
    useNetworkingGetSignedUploadUrlService();
  const { dispatch: dispatchAttachAsset } = useAttachAssetService(item.id);

  const fileLoadHandler = (file: File) => {
    if (!file) {
      return;
    }

    setIsLoading(true);

    // Trying to get image resolution
    const fileURL = URL.createObjectURL(file);
    const img = new Image();
    img.onload = () => {
      const fileData: TFileData = {
        file,
        resolution: {
          width: img.width,
          height: img.height,
        },
      };

      // Get upload signed url
      dispatchGetSignedUploadUrlToCloud(
        fileData,
        dispatchNetworkingGetSignedUploadUrlService,
        (resource) => {
          // Intending to upload the file to the cloud using the signed url
          dispatchUploadIconToCloud(resource.signedUrl, fileData.file, () => {
            dispatchAttachAsset({
              body: { asset: resource.hashName },
            })
              .then((resource) => {
                dispatchEvent(EventEnum.ON_NETWORK_UPDATED);
                dispatch(
                  systemNotificationActions.open({
                    message: "File linked successfully",
                    variant: "success",
                  })
                );
              })
              .catch((error) => console.error("Error attaching asset", error))
              .finally(() => setIsLoading(false));
          });
        },
        (error) => console.error("Error fetching signed url", error)
      );

      // Clean up the temporary URL
      URL.revokeObjectURL(fileURL);
    };

    img.src = fileURL;
  };

  return (
    <UploadIconButton loading={isLoading} onFileLoad={fileLoadHandler}>
      {""}
    </UploadIconButton>
  );
}
