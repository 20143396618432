import { TNoteTabsProps } from "./types/TNoteTabsProps";
import NoteTabsPanels from "./NoteTabsPanels";
import Tabs from "../../../../../../../common/modules/taskView/containers/InfoTabs/Tabs";
import TabContent from "../../../../../../../common/modules/taskView/containers/InfoTabs/TabContent";
import { useState } from "react";

export default function NoteTabs({
  isBoardView = false,
  initiatives,
  isMobileView,
  boardId,
}: TNoteTabsProps) {
  const [activeTabKey, setActiveTabKey] = useState<number>(0);
  const tabPanels = NoteTabsPanels({
    isBoardView,
    initiatives,
    isMobileView,
    boardId,
  });

  const tabChangeHandler = (_: React.SyntheticEvent, newValue: number) =>
    setActiveTabKey(newValue);

  const activeTab = tabPanels[activeTabKey];

  return (
    <>
      <Tabs
        activeTabIndex={activeTabKey}
        tabChangeHandler={tabChangeHandler}
        tabs={tabPanels.map((item, index) => ({
          tabLabel: item.tabLabel,
          tabIndex: item.tabIndex,
          isActive: index === activeTabKey,
        }))}
      />
      <TabContent
        key={activeTab.tabIndex}
        tabIndex={activeTab.tabIndex}
        isMobileView={isMobileView}
        hasActionsPanel={false}
        sx={{
          bgcolor: "none",
        }}
      >
        {activeTab.tabComponent}
      </TabContent>
    </>
  );
}
