import { TAddAssigneesFormProps } from "./types/TAddAssigneesFormProps";
import {
  Autocomplete,
  Button,
} from "../../../../../../common/modules/materialUI";
import React from "react";
import Form from "../../../../../../common/components/Form/Form";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TAddAssigneesFormAttributes } from "./types/TAddAssigneesFormAttributes";
import { useUserListService } from "../../../../../user/services/useUserListService";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { TAddAssigneesFormAttributesTransformed } from "./types/TAddAssigneesFormAttributesTransformed";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAddAssigneesService } from "../../services/useAddAssigneesService";
import { TGuesstimationItemUpdatedEvent } from "../types/TGuesstimationItemUpdatedEvent";
import { guesstimationItemViewActions } from "../../slices/viewSlice";
import { IGuesstimationItemDetailsResource } from "../../interfaces/IGuesstimationItemDetailsResource";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";

const AddAssigneesForm = ({
  guesstimationItem,
  onCancel,
}: TAddAssigneesFormProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const form = useForm<TAddAssigneesFormAttributes>({
    assignees: [],
  });
  const { isLoading, dispatch: dispatchAddAssignees } = useAddAssigneesService({
    guesstimationItemId: guesstimationItem.id,
  });

  const userListService = useUserListService();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchAddAssignees({
      body: form.getTransformed<TAddAssigneesFormAttributesTransformed>(
        (data) => {
          return {
            assignees: data.assignees?.length
              ? data.assignees.map((assigneeItem) => assigneeItem.id)
              : undefined,
          };
        }
      ),
    })
      .then((payload: IGuesstimationItemDetailsResource) => {
        dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_UPDATED, {
          initGuesstimationItem: guesstimationItem,
          guesstimationItem: payload.guesstimationItem,
        } as TGuesstimationItemUpdatedEvent);

        dispatch(guesstimationItemViewActions.setDetailsResource(payload));

        // Closing dialog
        onCancel?.();
      })
      .catch((error: THttpClientError) => {
        if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        }

        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button onClick={onCancel} disabled={isLoading} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Add
          </Button>
        </>
      }
    >
      {/* Assignees */}
      <Autocomplete
        form={form}
        name="assignees"
        label="Assignees"
        multiple
        showRequired
        service={userListService}
        options={form.data?.assignees}
        textFieldProps={{ forceFocus: !isTouchDevice }}
      />
    </Form>
  );
};

export default AddAssigneesForm;
