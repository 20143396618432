import { Box, Grid, useTheme } from "@mui/material";
import { ArrowOutward, Check, Link } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { Tooltip } from "../../../../../../common/modules/materialUI";
import useCopyLink from "../../../../../../common/modules/taskView/hooks/useCopyLink";
import TaskTypeForm from "../TaskTypeForm/TaskTypeForm";
import TaskPriorityForm from "../TaskPriorityForm/TaskPriorityForm";
import { TIsMobileViewProp } from "../../../../../../common/modules/taskView/containers/types/TIsMobileViewProp";
import { TIsPageViewProp } from "../../../../../../common/modules/taskView/containers/types/TIsPageViewProp";
import { guesstimationItemUtil } from "../../../../../../common/utils/guesstimationItemUtil";
import { IGuesstimationItem } from "../../../../../../entities/IGuesstimationItem";

export const GUESSTIMATION_ITEM_VIEW_MAIN_CONTENT_HEADER_HEIGHT = 60;

type TProps = {
  guesstimationItem: IGuesstimationItem;
} & TIsMobileViewProp &
  TIsPageViewProp;

const GuesstimationItemViewHeader = ({
  guesstimationItem,
  isMobileView,
  isPageView,
}: TProps) => {
  const url = guesstimationItemUtil.urlByRef(guesstimationItem.ref);

  const { linkCopied, copyLinkHandler } = useCopyLink(url);
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        bgcolor={theme.palette.taskView.secondaryBackground}
        borderBottom={`1px solid ${theme.palette.taskView.headerBorderBottomBackground}`}
        height={GUESSTIMATION_ITEM_VIEW_MAIN_CONTENT_HEADER_HEIGHT}
        p={`16px ${isMobileView ? "40px 16px 16px" : "26px"}`}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container item width="auto" alignItems="center">
          <Grid
            item
            bgcolor={theme.palette.taskView.headerTitleBackground}
            borderRadius="4px"
            fontSize="12px"
            fontWeight={500}
            p="7px 8px"
          >
            <NavLink
              to={url}
              style={{ color: theme.palette.taskView.textMainColor }}
            >
              {guesstimationItem.ref}
            </NavLink>
          </Grid>

          {!isPageView && (
            <Tooltip placement="right" title={"Open page view"}>
              <Grid item ml="4px" display="flex" alignItems="center">
                <NavLink to={url}>
                  <ArrowOutward
                    fontSize="small"
                    sx={{
                      color: theme.palette.taskView.textMainColor,
                    }}
                  />
                </NavLink>
              </Grid>
            </Tooltip>
          )}

          <Tooltip
            placement="right"
            title={!linkCopied ? "Copy link" : "Copied!"}
          >
            <Grid item ml="4px" display="flex" alignItems="center">
              {!linkCopied ? (
                <Link
                  fontSize="small"
                  sx={{
                    color: theme.palette.taskView.textMainColor,
                    cursor: "pointer",
                  }}
                  onClick={copyLinkHandler}
                />
              ) : (
                <Check color="success" fontSize="small" />
              )}
            </Grid>
          </Tooltip>
        </Grid>

        <Box
          display="flex"
          alignItems="center"
          gap={isMobileView ? "8px" : "12px"}
        >
          <TaskTypeForm
            noLabel={isMobileView}
            guesstimationItem={guesstimationItem}
          />

          <TaskPriorityForm
            noLabel={isMobileView}
            guesstimationItem={guesstimationItem}
          />
        </Box>
      </Grid>
    </>
  );
};

export default GuesstimationItemViewHeader;
