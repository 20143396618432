import { IconTypeEnum } from "../../../entities/columns/orgIconLibrary/IconTypeEnum";
import { useAppDispatch } from "../../../common/hooks/redux";
import useForm from "../../../common/hooks/useForm/useForm";
import { useIconLibraryLinkService } from "../services/useIconLibraryLinkService";
import { IOrgIconLibrary } from "../../../entities/IOrgIconLibrary";
import { THttpClientError } from "../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { TFileData } from "../../../common/hooks/useGetSignedUploadUrlToCloud/types/TFileData";

export default function useLinkCloudIcon(iconType: IconTypeEnum) {
  const dispatch = useAppDispatch();
  const { getValidationErrorAtIndex } = useForm();
  const { dispatch: dispatchIconLibraryCreateService } =
    useIconLibraryLinkService({ iconType });

  return {
    dispatch: (
      fileData: TFileData,
      nameUuid: string,
      onSuccess?: (resource: IOrgIconLibrary) => void,
      onFinally?: () => void,
      onError?: (error: THttpClientError) => void
    ) => {
      dispatchIconLibraryCreateService({
        body: {
          name: fileData.file.name,
          nameUuid: nameUuid,
          size: fileData.file.size,
          width: fileData.resolution?.width,
          height: fileData.resolution?.height,
        },
      })
        .then((resource: IOrgIconLibrary) => onSuccess?.(resource))
        .catch((error: THttpClientError) => {
          onError?.(error);

          const validationFirstError = getValidationErrorAtIndex(0, error);
          validationFirstError &&
            dispatch(
              systemNotificationActions.open({
                variant: "error",
                message: validationFirstError,
              })
            );
        })
        .finally(() => onFinally?.());
    },
  };
}
