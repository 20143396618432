import { useAppSelector } from "../../../../../common/hooks/redux";
import { RoleEnum } from "../../../../../entities/columns/user/RoleEnum";

export const useRoleMapHelper = () => {
  const orgRoleMap = useAppSelector((state) => state.org.init.org?.roleMap);

  return {
    getRoleMapLabel: (role: RoleEnum) => {
      return orgRoleMap?.find((item) => item.role === role)?.label ?? role;
    },
  };
};
