import { TClassNameProp } from "../../../../../../types/TClassNameProp";
import { useAppDispatch } from "../../../../../../hooks/redux";
import { navigationActions } from "../../../../../../store/navigationSlice";
import classes from "./NavigationIcon.module.css";
import { MenuTwoTone } from "@mui/icons-material";

const NavigationIcon = (props: TClassNameProp) => {
  const dispatch = useAppDispatch();

  return (
    <span
      className={`${props.className ?? ""} ${classes.icon}`}
      onClick={() => {
        dispatch(navigationActions.toggle());
      }}
    >
      <MenuTwoTone fontSize="small" />
    </span>
  );
};

export default NavigationIcon;
