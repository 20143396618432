import { Card, CardContent } from "@mui/material";
import Section from "./Section";
import classes from "./Picker.module.css";
import { Fragment } from "react";
import VerticalDivider from "../../components/Divider/VerticalDivider";
import { TSubPickerProps } from "./types/TSubPickerProps";

const LinearPicker = (props: TSubPickerProps) => {
  const sectionsCount = props.sections.length;
  const secondary = props.secondary ?? false;

  return (
    <Card
      className={`${classes.container} ${props.className}`}
      style={props.style}
    >
      <CardContent className={classes.content}>
        {props.sections.map(
          (section, index) =>
            section.render !== false && (
              <Fragment key={index}>
                <Section
                  {...section}
                  secondary={secondary}
                  displayText={
                    section.displayText ?? props.selection === section.value
                  }
                  onClick={() => {
                    props.onClick(section.value);
                    section.onClick && section.onClick();
                  }}
                />
                {sectionsCount > index + 1 && (
                  <VerticalDivider sx={{ height: "4px", margin: "auto 0" }} />
                )}
              </Fragment>
            )
        )}
      </CardContent>
    </Card>
  );
};

export default LinearPicker;
