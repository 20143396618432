import { useCreateCommentService } from "../../services/useCreateCommentService";
import { TCreateFormProps } from "./types/TCreateFormProps";
import { commentActions } from "../../slices/commentSlice";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { jwt } from "../../../../common/utils/jwt";
import { string } from "../../../../common/utils/string";
import { array } from "../../../../common/utils/array";
import { useStateManager } from "../../../../common/modules/editor";
import CommentEditor from "../CommentEditor/CommentEditor";
import { useMemo, useRef } from "react";
import { TFnHandlerParams } from "../CommentEditor/types/TFnHandlerParams";
import { Box, Typography, useTheme } from "@mui/material";
import useKeyboardShortcut from "../../../../common/modules/keyboardShortcut/hooks/useKeyboardShortcut";
import ShortcutProTip from "../../../../common/modules/keyboardShortcut/containers/ProTip/ShortcutProTip";
import { IComment } from "../../../../entities/IComment";
import { KeyboardShortcutEnum } from "../../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";

const CreateForm = (props: TCreateFormProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    editorRequested,
    editorReady,
    immediateRequestEditorHandler,
    initEditorHandler,
    closeEditorHandler,
  } = useStateManager();
  const uniqueId = useMemo(() => string.random(10), []);
  const enableHotKey = props.enableHotKey ?? true;

  useKeyboardShortcut({
    key: KeyboardShortcutEnum.c,
    callback: () => {
      if (!enableHotKey) {
        return;
      }

      immediateRequestEditorHandler();
      ref.current?.scrollIntoView({ behavior: "smooth" });
    },
  });

  const createCommentService = useCreateCommentService({
    entityType: props.entityType,
    entityId: props.entityId,
  });

  const getTmpComment = (html?: string) =>
    ({
      html,
      userName: jwt.parse()?.name as string,
      id: uniqueId,
      entityType: props.entityType,
      entityId: props.entityId,
    } as IComment);

  const saveHandler = (handlerParams: TFnHandlerParams) => {
    closeEditorHandler();
    dispatch(commentActions.addComment(getTmpComment(handlerParams.html)));

    props.onSave?.(handlerParams);
  };

  return (
    <Box ref={ref}>
      {(!editorRequested || !editorReady) && (
        <Box
          className={array.toClassName([
            editorRequested && !editorReady ? "pulsating" : "",
          ])}
          onClick={immediateRequestEditorHandler}
          border={`1px solid ${theme.palette.taskView.dividerBackground}`}
          borderRadius="4px"
          p="8px"
        >
          <Typography
            color={theme.palette.taskView.textMainColor}
            fontSize="14px"
            lineHeight={1}
          >
            Add a {props.typeTitle}
          </Typography>
        </Box>
      )}
      {!editorRequested && enableHotKey && (
        <ShortcutProTip
          shortcutKeyString={KeyboardShortcutEnum.c}
          description={`add a ${props.typeTitle}`}
          sx={{ color: theme.palette.taskView.labelColor, mt: "12px" }}
          size="medium"
        />
      )}
      {editorRequested && (
        <CommentEditor
          entityType={props.entityType}
          entityId={props.entityId}
          onSave={saveHandler}
          onSaveSuccess={() =>
            dispatch(commentActions.removeComment(getTmpComment()))
          }
          onCancel={closeEditorHandler}
          onInit={initEditorHandler}
          saveService={createCommentService}
          style={{ display: editorReady ? undefined : "none" }}
          autoFocus={true}
        />
      )}
    </Box>
  );
};

export default CreateForm;
