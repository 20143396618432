import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { boardActions } from "../../slices/boardSlice";
import CreateForm from "./CreateForm";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";

const CreateModal = () => {
  const creation = useAppSelector((state) => state.board.creation);
  const dispatch = useAppDispatch();

  if (!creation.displayModal) {
    return null;
  }

  const closeHandler = () => {
    dispatch(boardActions.closeCreationModal());
  };

  return (
    <Dialog
      open={creation.displayModal}
      onClose={closeHandler}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Create New Board</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          For project <b>{creation.projectName}</b>
        </Typography>
        <CreateForm onCancel={closeHandler} projectRef={creation.projectRef!} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateModal;
