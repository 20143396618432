import { cloneElement, useState } from "react";
import { TConfirmationButtonProps } from "./types/TConfirmationButtonProps";
import { ConfirmationDialog } from "../../index";

const ConfirmationButton = (props: TConfirmationButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const clickHandler = () => {
    if (props.disabled) {
      return;
    }

    setOpen((prevState) => !prevState);
  };

  const successHandler = () => {
    props.successHandler(props.data);
    clickHandler();
  };

  return (
    <>
      {cloneElement(props.button, { onClick: clickHandler })}
      <ConfirmationDialog
        open={open}
        title={props.title}
        content={props.content}
        disagreeText={props.disagreeText}
        confirmText={props.confirmText}
        closeHandler={clickHandler}
        successHandler={successHandler}
      />
    </>
  );
};

export default ConfirmationButton;
