import { useAppSelector } from "../../../common/hooks/redux";
import { useOrgProjectData } from "./useOrgProjectData";
import { TListResource } from "../../../common/types/TListResource";

export const useOrgStatusFlowData = (projectRef: string) => {
  const project = useOrgProjectData().findProjectByRef(projectRef)?.project!;
  const statuses = useAppSelector((state) => state.org.init.statuses);

  const projectStatuses = statuses.filter(
    (item) => item.projectId === project.id
  );

  return {
    statuses: projectStatuses,
    statusList: projectStatuses.map((item) => ({
      label: item.name,
      id: item.id,
    })) as TListResource<number>,
  };
};
