import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { Box } from "@mui/material";
import { DataGrid } from "../../../../common/modules/materialUI";
import { Columns } from "./Columns";
import Loader from "../../../../common/containers/Loader/Loader";
import React from "react";
import UpdateModal from "../UpdateTaskType/UpdateModal";
import { taskTypeActions } from "../../slices/taskTypeSlice";
import DeleteConfirmationDialog from "../DeleteTaskType/DeleteConfirmationDialog";
import { gridClasses } from "@mui/x-data-grid";

const TaskTypeGrid = () => {
  const dispatch = useAppDispatch();
  const orgInit = useAppSelector((state) => state.org.init);
  const projects = orgInit.list.map(
    (projectWithBoard) => projectWithBoard.project
  );
  const { isLoading, taskTypes } = orgInit;
  const rows = taskTypes.filter((taskType) => taskType.isActive);

  return (
    <Box>
      <UpdateModal />

      <DeleteConfirmationDialog />

      <DataGrid
        columns={Columns({
          projects,
          onEditButtonClick: (taskType) =>
            dispatch(taskTypeActions.openUpdateModal(taskType)),
          onDeleteButtonClick: (taskType) =>
            dispatch(taskTypeActions.openDeleteConfirmation(taskType)),
        })}
        getRowHeight={() => "auto"}
        rows={rows}
        disableRowSelectionOnClick
        checkboxSelection={false}
        autoHeight={!rows.length}
        loading={isLoading}
        sx={{
          [`& .${gridClasses.row}`]: {
            minHeight: `52px !important`,
          },
        }}
      />
      {isLoading && <Loader />}
    </Box>
  );
};

export default TaskTypeGrid;
