import { useHttpClient } from "../../../common/modules/httpClient/hooks/useHttpClient";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { ISettingsResource } from "../interfaces/ISettingsResource";

export const useFetchBoardService = (props: { ref: string }) => {
  const response = useHttpClient({
    url: `/board/${props.ref}`,
    method: RequestMethodEnum.GET,
  });

  return {
    ...response,
    data: response.data as ISettingsResource,
  };
};
