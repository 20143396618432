import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";

export const useConvertTaskInternalTypeService = (props: {
  taskId: number;
}) => {
  return useHttpClientDispatchable({
    url: `/task/${props.taskId}/convertInternalType`,
    method: RequestMethodEnum.PATCH,
  });
};
