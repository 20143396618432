import TListItemProps from "../../../../../../components/Navigation/ListItem/types/TListItemProps";
import { Style } from "@mui/icons-material";
import { TSectionProps } from "../../types/TSectionProps";

const CustomFieldsListItem = (props: TSectionProps) =>
  ({
    id: "TaskTypesListItem",
    name: "Task Types",
    icon: <Style />,
    path: "/settings/taskTypes",
    navigateToPath: true,
    onNavigate: props.onClick,
  } as TListItemProps);

export default CustomFieldsListItem;
