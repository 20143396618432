import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { boardActions } from "../slices/boardSlice";
import { IData } from "../../../entities/columns/objective/IData";
import { useAppDispatch } from "../../../common/hooks/redux";

export const useFetchBoardInitiativesService = (props: { ref: string }) => {
  const dispatch = useAppDispatch();
  return useHttpClientDispatchable({
    url: `/board/${props.ref}/initiatives`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IData[]) =>
        dispatch(boardActions.setInitiatives(data)),
    },
  });
};
