import { Typography } from "@mui/material";
import { TDeleteGuesstimationItemProps } from "./types/TDeleteGuesstimationItemProps";
import QuickActionButton from "../../../../../../../common/modules/taskView/containers/QuickActions/QuickActionButton";
import { useAccessControl } from "../../../../../../../common/modules/accessControl/hooks/useAccessControl";
import { AggStatusEnum } from "../../../../../../../entities/columns/task/AggStatusEnum";
import { AbilityEnum } from "../../../../../../../common/modules/accessControl/enums/AbilityEnum";
import { guesstimationItemViewActions } from "../../../slices/viewSlice";
import { useAppDispatch } from "../../../../../../../common/hooks/redux";

const DeleteGuesstimationItem = ({
  guesstimationItem,
}: TDeleteGuesstimationItemProps) => {
  const dispatch = useAppDispatch();
  const { can } = useAccessControl();

  if (
    guesstimationItem.statusType === AggStatusEnum.DONE ||
    !can(AbilityEnum.administrate)
  ) {
    return null;
  }

  return (
    <QuickActionButton
      onClick={() =>
        dispatch(
          guesstimationItemViewActions.openDeleteItemConfirmation({
            item: guesstimationItem,
          })
        )
      }
      sx={{ color: "#7b4545", backgroundColor: "#e1bfc1" }}
    >
      <Typography fontSize="13px" fontWeight="bold">
        Delete Guesstimation Item
      </Typography>
    </QuickActionButton>
  );
};

export default DeleteGuesstimationItem;
