import { Box, Tab, Tabs as MuiTabs } from "@mui/material";
import { SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import { TTabsProps } from "./types/TTabsProps";
import { TeamManagementComponentEnum } from "../../enums/TeamManagementComponentEnum";

const Tabs = (props: TTabsProps) => {
  const navigate = useNavigate();

  const changeHandler = (
    event: SyntheticEvent,
    value: TeamManagementComponentEnum
  ) => {
    const route =
      value !== TeamManagementComponentEnum.MEMBERS ? `/${value}` : "";
    navigate(`/team${route}`);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <MuiTabs
        value={props.component}
        onChange={changeHandler}
        aria-label="user management tabs"
      >
        <Tab
          value={TeamManagementComponentEnum.MEMBERS}
          label="Members"
          aria-controls="members"
        />
        <Tab
          value={TeamManagementComponentEnum.RESOURCE_AVAILABILITY}
          label="Resource Availability"
          aria-controls="resource-availability"
        />
        {/*<Tab*/}
        {/*  value={TeamManagementComponentEnum.TEAMS}*/}
        {/*  label="Teams"*/}
        {/*  aria-controls="teams"*/}
        {/*/>*/}
      </MuiTabs>
    </Box>
  );
};

export default Tabs;
