import { DialogContent, DialogTitle, Typography } from "@mui/material";
import { exploreAndShareActions } from "../../slices/exploreAndShareSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { Dialog } from "../../../materialUI";
import FeedbackForm from "./FeedbackForm";

const FeedbackModal = () => {
  const openFeedback = useAppSelector(
    (state) => state.exploreAndShare.openFeedback
  );
  const dispatch = useAppDispatch();

  if (!openFeedback) {
    return null;
  }

  const closeHandler = () => {
    dispatch(exploreAndShareActions.closeFeedback());
  };

  return (
    <Dialog
      renderCloseButton
      closeButtonHandler={closeHandler}
      open={openFeedback}
      onClose={closeHandler}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Feedback</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          We love to hear from you! Please share your feedback with us.
        </Typography>
        <FeedbackForm />
      </DialogContent>
    </Dialog>
  );
};

export default FeedbackModal;
