import TExpandableIconProps from "./types/TExpandableIconProps";
import {
  ArrowDropDown,
  ArrowDropUp,
  ArrowLeft,
  ArrowRight,
  ExpandLess,
  ExpandMore,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import classes from "./ExpandableIcon.module.css";
import { array } from "../../../utils/array";

const ExpandableIcon = (props: TExpandableIconProps) => {
  if (!(props.display ?? true)) {
    return null;
  }

  const kind = props.kind ?? "linear";
  const classNames = array.toClassName([
    classes.icon,
    props.className ?? "",
    props.size ? classes[props.size] : "",
  ]);

  return kind === "linear" ? (
    <>
      {props.direction === "right" && (
        <KeyboardArrowRight onClick={props.onClick} className={classNames} />
      )}
      {props.direction === "left" && (
        <KeyboardArrowLeft onClick={props.onClick} className={classNames} />
      )}
      {props.direction === "down" && (
        <ExpandMore onClick={props.onClick} className={classNames} />
      )}
      {props.direction === "up" && (
        <ExpandLess onClick={props.onClick} className={classNames} />
      )}
    </>
  ) : (
    <>
      {props.direction === "right" && (
        <ArrowRight onClick={props.onClick} className={classNames} />
      )}
      {props.direction === "left" && (
        <ArrowLeft onClick={props.onClick} className={classNames} />
      )}
      {props.direction === "down" && (
        <ArrowDropDown onClick={props.onClick} className={classNames} />
      )}
      {props.direction === "up" && (
        <ArrowDropUp onClick={props.onClick} className={classNames} />
      )}
    </>
  );
};

export default ExpandableIcon;
