import PriorityViewContent from "./PriorityViewContent";
import TaskViewModal from "../../../task/modules/view/containers/TaskViewModal";

const PriorityView = () => {
  return (
    <>
      <PriorityViewContent />
      <TaskViewModal />
    </>
  );
};

export default PriorityView;
