import TFormProps from "./types/TFormProps";
import classes from "./Form.module.css";

const Form = (props: TFormProps) => {
  return (
    <form
      onSubmit={props.onSubmit}
      className={`${props.className} ${classes.form}`}
      style={props.style}
    >
      {props.children}
      <div
        style={props.actionStyle}
        className={`${
          props.actionPosition === "left"
            ? classes["text-align-left"]
            : classes["text-align-right"]
        } ${classes["actions-row"]}`}
      >
        {props.actionElement}
      </div>
    </form>
  );
};

export default Form;
