import { useAppSelector } from "../../../../../common/hooks/redux";
import { Typography, useTheme } from "@mui/material";
import { date } from "../../../../../common/utils/date";
import moment from "moment/moment";

const FreeTrial = () => {
  const theme = useTheme();
  const org = useAppSelector((state) => state.org.init.org);

  if (!org?.freeTrialEndDate) {
    return null;
  }
  const freeDaysRemaining = date
    .intervalToDays(moment(), moment(org.freeTrialEndDate))
    .toFixed(0);

  if (+freeDaysRemaining <= 0) {
    return null;
  }

  return (
    <div
      style={{
        width: "87px",
        margin: "0 5px 0 10px",
        padding: 0,
        display: "flex",
        flex: "row",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Typography
        fontWeight="bold"
        fontSize="large"
        sx={{
          color: theme.palette.warning.main,
          lineHeight: 1,
        }}
      >
        {freeDaysRemaining}
      </Typography>
      <Typography
        variant="overline"
        fontWeight="bold"
        sx={{
          color: theme.palette.primary.contrastText,
          fontSize: "9px",
          mt: 0.3,
        }}
        style={{ lineHeight: 1, fontFamily: "sans-serif" }}
      >
        Days Free
      </Typography>
    </div>
  );
};

export default FreeTrial;
