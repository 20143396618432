import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClient } from "../../../../../common/modules/httpClient/hooks/useHttpClient";
import { IPreviewSignedUrlResource } from "../interfaces/IPreviewSignedUrlResource";

export const useAttachmentPreviewUrlService = (props: {
  attachmentId?: number;
}) => {
  const response = useHttpClient({
    url: `/attachment/${props.attachmentId}/previewUrl`,
    method: RequestMethodEnum.GET,
  });

  return {
    ...response,
    data: response.data as IPreviewSignedUrlResource,
  };
};
