import { FormControlLabel, Typography } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../common/hooks/redux";
import { menuItem } from "../../../../../../../common/containers/Menu/utils/menuItem";
import Checkbox from "../../../../../../../common/modules/materialUI/components/Checkbox/Checkbox";
import { guesstimationViewActions } from "../../../slices/guesstimationViewSlice";

const DisplayDoneOption = () => {
  const dispatch = useAppDispatch();
  const checked = useAppSelector(
    (state) => state.guesstimationView.filters.showDone
  );

  const clickHandler = () => {
    dispatch(guesstimationViewActions.toggleShowDone());
  };

  return menuItem.make(
    <FormControlLabel
      control={
        <Checkbox checked={checked} sx={{ py: 0 }} onChange={clickHandler} />
      }
      label={
        <Typography
          sx={{
            color: !checked ? "text.primary" : "text.disabled",
          }}
          variant="body2"
        >
          Display done
        </Typography>
      }
    />
  );
};

export default DisplayDoneOption;
