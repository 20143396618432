import Title from "../../../../common/components/Title/Title";
import { TTeamsPageProps } from "./types/TTeamsPageProps";
import Tabs from "./Tabs";
import { TeamManagementComponentEnum } from "../../enums/TeamManagementComponentEnum";
import TeamsTab from "../Tabs/Teams";
import MembersTab from "../Tabs/Members/Members";
import classes from "./TeamsPage.module.css";
import { useAccessControl } from "../../../../common/modules/accessControl/hooks/useAccessControl";
import ResourceAvailabilityTab from "../Tabs/ResourceAvailability/ResourceAvailability";

const TeamsPage = (props: TTeamsPageProps) => {
  const { can, accessibleRoles } = useAccessControl();

  return (
    <>
      <Title>Teams</Title>
      <Tabs component={props.component} />
      <div className={classes.content}>
        {props.component === TeamManagementComponentEnum.MEMBERS && (
          <MembersTab accessibleRoles={accessibleRoles} can={can} />
        )}
        {props.component === TeamManagementComponentEnum.TEAMS && <TeamsTab />}
        {props.component ===
          TeamManagementComponentEnum.RESOURCE_AVAILABILITY && (
          <ResourceAvailabilityTab />
        )}
      </div>
    </>
  );
};

export default TeamsPage;
