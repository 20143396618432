import { TTimeArrowsProps } from "./types/TTimeArrowsProps";
import { TTimeArrowsRegistry } from "./types/TTimeArrowsRegistry";
import TimeArrow from "../TimeArrow/TimeArrow";
import { useMemo } from "react";
import { pointConverter } from "../../utils/pointConverter";

const TimeArrows = (props: TTimeArrowsProps) => {
  const timeArrowsRegistry = useMemo<TTimeArrowsRegistry>(() => {
    const registry: TTimeArrowsRegistry = {};

    // Build unique time arrows not to have the same kind of arrow at the same position
    // this case is possible if the end of the sprint and the start of the next one will
    // have the same position of the arrow
    props.timelineDates.forEach((timeLineDate) => {
      const timeArrow = timeLineDate.timeArrow;
      if (!timeArrow) {
        return;
      }
      const position =
        (props.positionShift ?? 0) +
        pointConverter.intervalToXPositionInPixels(
          props.startDate,
          timeLineDate.date
        ) +
        (timeLineDate.isEndDate ? pointConverter.pointToXSizeInPixels() : 0);

      if (!registry[position]?.includes(timeArrow)) {
        registry[position] = [...(registry[position] ?? []), timeArrow];
      }
    });

    return registry;
  }, [props.timelineDates]);

  return (
    <>
      {Object.keys(timeArrowsRegistry).map((position) =>
        timeArrowsRegistry[Number(position)].map((timeHighlight) => (
          <TimeArrow
            key={`ta-${position}-${timeHighlight}`}
            position={Number(position)}
            timeHighlight={timeHighlight}
          />
        ))
      )}
    </>
  );
};

export default TimeArrows;
