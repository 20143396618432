import { EntityTypeEnum } from "../../../entities/enums/EntityTypeEnum";
import { useRef } from "react";
import useUploadAttachmentServiceHelper from "./useUploadAttachmentServiceHelper";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/redux";

export default function useAttachmentFormHelper(
  entityType: EntityTypeEnum,
  entityId: number
) {
  const uploadInProgress = useAppSelector(
    (state) => state.attachment.uploadInProgress
  );
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { dispatch: dispatchUploadAttachmentService } =
    useUploadAttachmentServiceHelper(entityType, entityId);

  const fileSelectHandler = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    fileInputRef.current && fileInputRef.current.click();
  };

  const fileChangeHandler = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    const files = event.target.files;
    const keys = Object.keys(files);
    const maxLimit = +(process.env.MAX_ATTACHMENTS_COUNT ?? 0);

    if (keys.length > maxLimit) {
      dispatch(
        systemNotificationActions.open({
          variant: "warning",
          message: `You can upload maximum ${maxLimit} files at a time.`,
        })
      );

      return;
    }

    keys.map((key) => {
      dispatchUploadAttachmentService(files[key]);
    });
  };

  return {
    fileSelectHandler,
    InputComponent: (
      <input
        multiple
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={fileChangeHandler}
      />
    ),
    uploadInProgress,
  };
}
