import { useAppSelector } from "../../../../common/hooks/redux";
import { TEntityTaskTypeUpdateFormProps } from "./types/TEntityTaskTypeUpdateFormProps";
import { useEffect } from "react";
import useForm from "../../../../common/hooks/useForm/useForm";
import { TEntityTaskTypeUpdateFormAttributes } from "./types/TEntityTaskTypeUpdateFormAttributes";
import { SelectChangeEvent, useTheme } from "@mui/material";
import { taskTypeUtil } from "../../utils/taskTypeUtil";
import { iconLibraryUtil } from "../../../iconLibrary/utils/iconLibraryUtil";
import Dropdown from "../../../../common/modules/materialUI/components/Dropdown/Dropdown";
import { ArrowDropDownRounded, Style } from "@mui/icons-material";

const EntityTaskTypeUpdateForm = ({
  projectRef,
  taskTypeId,
  onChange,
  isLoading,
  noLabel,
}: TEntityTaskTypeUpdateFormProps) => {
  const theme = useTheme();
  const orgInit = useAppSelector((state) => state.org.init);
  const { taskTypes, list, isLoading: isOrgInitLoading } = orgInit;
  const projectId =
    (list.find((listItem) => listItem.project.ref === projectRef)?.project
      .id as number) ?? 0;
  const taskType = taskTypeId
    ? taskTypes.find((row) => row.id === taskTypeId)
    : undefined;

  const form = useForm<TEntityTaskTypeUpdateFormAttributes>({
    taskTypeId: taskType?.id ?? null,
  });

  // When switching entity, task type id can be changed.
  useEffect(() => {
    form.set({ taskTypeId: taskType?.id ?? null });
  }, [taskType?.id]);

  const submitHandler = (event: SelectChangeEvent<any>) => {
    event.preventDefault();

    onChange(event.target.value ?? null);
  };

  return (
    <Dropdown
      form={form}
      onChange={submitHandler}
      name="taskTypeId"
      withoutBorder
      options={taskTypeUtil.getDropdownOptions(
        taskTypes,
        projectId,
        "Task Type",
        taskType
      )}
      loading={isOrgInitLoading || isLoading}
      renderOption={(option) =>
        iconLibraryUtil.getDropdownRenderOption(
          option,
          taskTypeUtil.getSelectedTaskType(taskTypes, option)?.iconFilePath
        )
      }
      // This change is done for mobile not to display the label for the selected value but still have it inside
      // dropdown list
      renderValue={(optionId) => {
        const selectedOption = taskTypes.find((row) => row.id === optionId);

        const noLabelOption = {
          id: optionId,
          label: noLabel
            ? ""
            : !!optionId
            ? selectedOption?.name ?? ""
            : "Task Type",
        };

        return iconLibraryUtil.getDropdownRenderOption(
          noLabelOption,
          taskTypeUtil.getSelectedTaskType(taskTypes, noLabelOption)
            ?.iconFilePath,
          undefined,
          <Style style={{ display: "flex", fontSize: "16px" }} />,
          {
            iconSize: 16,
            optionContainerSpacing: 0,
            optionContainerGap: "4px",
          }
        );
      }}
      IconComponent={(props) => (
        <ArrowDropDownRounded
          {...props}
          fontSize="small"
          sx={{
            color: `${theme.palette.taskView.textMainColor} !important`,
            right: "0 !important",
          }}
        />
      )}
      sx={{
        height: "auto",
        "& .MuiSelect-select": {
          borderRadius: 0,
          color: theme.palette.taskView.textSecondaryColor,
          fontSize: "13px",
          fontWeight: 500,
          minHeight: "0 !important",
          p: "0 !important",
          pr: "24px !important",
        },
      }}
    />
  );
};

export default EntityTaskTypeUpdateForm;
