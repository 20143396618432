import { TVerifyPhoneProps } from "./types/TVerifyPhoneProps";
import { useVerifyPhoneService } from "../../../../user/services/useVerifyPhoneService";
import { Phone } from "@mui/icons-material";
import Verify from "../Verify/Verify";

const VerifyPhone = (props: TVerifyPhoneProps) => {
  const service = useVerifyPhoneService();

  return (
    <Verify
      resendService={props.resendService}
      inquiryUuid={props.inquiryUuid}
      verifyService={service}
      type="phone"
      resentMessage="A new verification code has been sent to your phone"
      helperText={
        <>
          We have sent you a 4 digit code to <b>your phone</b>. Please enter it
          here to verify it.
        </>
      }
      icon={<Phone sx={{ pr: 1 }} />}
      onNextHandler={props.onNextHandler}
    />
  );
};

export default VerifyPhone;
