import { Navigate } from "react-router-dom";
import { ROUTE_ON_SIGNIN } from "../../../../../modules/user/containers/Auth/Signin/TSSigninForm";
import { jwt } from "../../../../utils/jwt";
import GlobalStyle from "../../../../modules/theme/containers/GlobalStyle/GlobalStyle";
import { TChildren } from "../../../../types/TChildren";
import SystemNotification from "../../../../modules/systemNotification/containers/SystemNotification";
import LayoutWrapper from "./LayoutWrapper";

/**
 * @description !!! IMPORTANT - for layout redirecting purposes we should never use useNavigate() in such high
 * components because it is causing unnecessary re-renders every time the route changed, that is why <Navigate />
 * component is used instead
 */
const Layout = (props: TChildren) => {
  const hasAccessToken = jwt.valid();

  return (
    <>
      <GlobalStyle />
      <SystemNotification />
      {hasAccessToken ? (
        <Navigate to={ROUTE_ON_SIGNIN} replace={true} />
      ) : (
        <LayoutWrapper children={props.children} />
      )}
    </>
  );
};

export default Layout;
