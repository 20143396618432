import { useState } from "react";
import { TInfoTabsProps } from "./types/TInfoTabsProps";
import TabContent from "./TabContent";
import Tabs from "./Tabs";
import SectionWrapper from "./SectionWrapper";

const InfoTabs = ({ mainPanels, isMobileView, storageKey }: TInfoTabsProps) => {
  const [activeTabKey, setActiveTabKey] = useState<number>(0);
  const [detachedTabIndexes, setDetachedTabIndexes] = useState<string[]>(() => {
    const detachedTabsArray = localStorage.getItem(storageKey);
    return detachedTabsArray ? JSON.parse(detachedTabsArray) : [];
  });

  const tabPanels = mainPanels;
  let detached: typeof tabPanels = [];
  let grouped: typeof tabPanels = [];

  tabPanels.forEach((item) => {
    !detachedTabIndexes.includes(item.tabIndex)
      ? grouped.push(item)
      : detached.push(item);
  });

  const tabChangeHandler = (_: React.SyntheticEvent, newValue: number) =>
    setActiveTabKey(newValue);

  const detachHandler = (tabIndex: string) => {
    setDetachedTabIndexes((prevDetachedTabs) => {
      const filteredTabs = [...prevDetachedTabs, tabIndex];

      localStorage.setItem(storageKey, JSON.stringify(filteredTabs));

      return filteredTabs;
    });

    setActiveTabKey(0);
  };

  const groupHandler = (tabIndex: string) => {
    setDetachedTabIndexes((prevDetachedTabs) => {
      const filteredTabs = prevDetachedTabs.filter((item) => item !== tabIndex);

      localStorage.setItem(storageKey, JSON.stringify(filteredTabs));

      return filteredTabs;
    });
  };

  // This will return current active tab from unpinned tabs, if all are pinned already then an undefined will
  // be returned
  const activeTab = grouped[activeTabKey];

  return (
    <>
      {grouped.length > 0 && (
        <SectionWrapper
          isMobileView={isMobileView}
          contentExtension={activeTab.tabComponentExtension}
        >
          <Tabs
            activeTabIndex={activeTabKey}
            tabChangeHandler={tabChangeHandler}
            tabs={grouped.map((item, index) => ({
              tabLabel: item.tabLabel,
              tabIndex: item.tabIndex,
              isActive: index === activeTabKey,
            }))}
          />
          <TabContent
            key={activeTab.tabIndex}
            tabIndex={activeTab.tabIndex}
            isMobileView={isMobileView}
            hasActionsPanel={activeTab.tabIndex !== "details-tab"}
            detachHandler={grouped.length > 1 ? detachHandler : undefined}
          >
            {activeTab.tabComponent}
          </TabContent>
        </SectionWrapper>
      )}

      {detached.map(({ tabIndex, tabLabel, tabComponent }) => (
        <SectionWrapper key={tabIndex} isMobileView={isMobileView}>
          <Tabs
            activeTabIndex={0}
            tabChangeHandler={tabChangeHandler}
            tabs={[{ tabLabel, tabIndex, isActive: true }]}
          />
          <TabContent
            tabIndex={tabIndex}
            isMobileView={isMobileView}
            hasActionsPanel
            groupHandler={groupHandler}
          >
            {tabComponent}
          </TabContent>
        </SectionWrapper>
      ))}
    </>
  );
};

export default InfoTabs;
