import { useAppSelector } from "../../../common/hooks/redux";
import { TUseGetTaskTypeProps } from "./types/TUseGetTaskTypeProps";

const useGetTaskType = ({ taskTypeId }: TUseGetTaskTypeProps) => {
  const taskTypes = useAppSelector((state) => state.org.init.taskTypes);

  if (!taskTypeId) {
    return undefined;
  }

  return taskTypeId
    ? taskTypes.find((row) => row.id === taskTypeId)
    : undefined;
};

export default useGetTaskType;
