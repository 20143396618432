import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TBoardState } from "./types/TBoardState";
import { IData } from "../../../entities/columns/objective/IData";

const initialState: TBoardState = {
  creation: {
    displayModal: false,
    projectRef: "",
    projectName: "",
  },
  initiatives: [],
};

const slice = createSlice({
  name: "board",
  initialState,
  reducers: {
    openCreationModal: (
      state,
      action: PayloadAction<{ projectRef: string; projectName: string }>
    ) => {
      state.creation.displayModal = true;
      state.creation.projectRef = action.payload.projectRef;
      state.creation.projectName = action.payload.projectName;
    },
    closeCreationModal: (state) => {
      state.creation.displayModal = false;
    },
    setInitiatives: (state, payload: PayloadAction<IData[]>) => {
      state.initiatives = payload.payload;
    },
  },
});

export const boardReducer = slice.reducer;

export const boardActions = slice.actions;
