import classes from "./ViewContainer.module.css";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { array } from "../../../../common/utils/array";
import { IconButton, useTheme } from "@mui/material";
import { SideViewSectionEnum } from "../../enums/SideViewSectionEnum";
import CapacityPlannerSection from "../CapacityPlanner/Section";
import { Close } from "@mui/icons-material";
import { boardViewActions } from "../../slices/boardViewSlice";
import { TViewContainerProps } from "./types/TViewContainerProps";
import FinancialForecastSection from "../FinancialForecast/FinancialForecastSection";

const ViewContainer = (props: TViewContainerProps) => {
  const displaySideView = useAppSelector(
    (state) => state.boardView.displaySideView
  );
  const dispatch = useAppDispatch();
  const theme = useTheme();

  return (
    <div className={classes.container}>
      {displaySideView && (
        <div
          className={classes["side-view"]}
          style={{
            backgroundColor: theme.palette.cardsBackground.default,
            color: theme.palette.text.primary,
            boxShadow: theme.palette.boardSideViewShadow.main,
          }}
        >
          <div>
            <IconButton
              onClick={() =>
                dispatch(boardViewActions.displaySideView(undefined))
              }
            >
              <Close />
            </IconButton>
          </div>
          {displaySideView === SideViewSectionEnum.BUDGETARY && (
            <FinancialForecastSection boardRef={props.boardRef} />
          )}
          {displaySideView === SideViewSectionEnum.CAPACITY && (
            <CapacityPlannerSection />
          )}
        </div>
      )}
      <div
        className={array.toClassName([
          classes.content,
          displaySideView ? classes["right-handed"] : "",
        ])}
      >
        {props.children}
      </div>
    </div>
  );
};

export default ViewContainer;
