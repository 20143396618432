import { TColumnsProps } from "./types/TColumnsProps";
import { GridColDef } from "@mui/x-data-grid";
import { projectUtil } from "../../../../common/utils/projectUtil";
import { Chip, IconButton, Stack, Tooltip } from "@mui/material";
import Icon from "../../../iconLibrary/containers/IconLibraryPicker/Icon";
import { Delete, ModeEdit } from "@mui/icons-material";
import { ITaskPriority } from "../../../../entities/ITaskPriority";

export const Columns: (props: TColumnsProps) => GridColDef<ITaskPriority>[] = ({
  projects,
  onEditButtonClick,
  onDeleteButtonClick,
}) => {
  const projectsList = projectUtil.list(projects);

  return [
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit Task Priority">
              <IconButton
                onClick={() => onEditButtonClick(params.row)}
                color="primary"
              >
                <ModeEdit color="success" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Task Priority">
              <IconButton
                onClick={() => onDeleteButtonClick(params.row)}
                color="error"
              >
                <Delete color="error" fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      maxWidth: 300,
      hideable: false,
    },
    {
      disableColumnMenu: true,
      field: "projectIds",
      headerName: "Projects",
      flex: 1,
      minWidth: 300,
      maxWidth: 400,
      hideable: false,
      renderCell: (params) => {
        const projectIds = params.row.projectIds;

        if (!projectIds) {
          return <Chip size="small" label="All" />;
        }

        const rowProjectsList = projectsList.filter((project) =>
          projectIds.includes(project.id)
        );

        return (
          <Stack my={1} direction="row" spacing={1} useFlexGap flexWrap="wrap">
            {rowProjectsList.map((projectListItem) => (
              <Chip
                key={projectListItem.id}
                size="small"
                label={projectListItem.label}
              />
            ))}
          </Stack>
        );
      },
    },
    {
      disableColumnMenu: true,
      field: "iconFilePath",
      headerName: "Icon",
      flex: 1,
      minWidth: 150,
      hideable: false,
      renderCell: (params) => {
        return <Icon filePath={params.row.iconFilePath} isSelected={false} />;
      },
    },
    {
      field: "severity",
      headerName: "Severity",
      flex: 1,
      minWidth: 150,
      hideable: false,
    },
    {
      field: "createdAt",
      headerName: "Date Added",
      width: 170,
    },
    {
      field: "updatedAt",
      headerName: "Date Updated",
      width: 170,
    },
  ];
};
