import { Box, Grid, useTheme } from "@mui/material";
import { TObjectiveContentProps } from "./types/TObjectiveContentProps";
import ObjectiveViewHeader from "./Header/ObjectiveViewHeader";
import { TASK_VIEW_MAIN_CONTENT_HEADER_HEIGHT } from "../../../task/modules/view/containers/Header/TaskViewHeader";
import { MAIN_CONTENT_PADDING_BOTTOM } from "../../../../common/containers/Layout/App/Content/Content";
import Accordion from "../../../../common/modules/taskView/containers/Sections/Accordion";
import { useAccessControl } from "../../../../common/modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../common/modules/accessControl/enums/AbilityEnum";
import { AttachMoney, FormatListBulleted, Insights } from "@mui/icons-material";
import ObjectiveViewMainContent from "./ObjectiveViewMainContent/ObjectiveViewMainContent";
import AccomplishmentChart from "../Charts/AccomplishmentChart";
import CostChart from "../Charts/CostChart";
import DetailsForm from "./DetailsForm/DetailsForm";

const ObjectiveViewDesktop = ({
  objective,
  contentHeight,
  isMobileView,
  isPageView,
}: TObjectiveContentProps) => {
  const theme = useTheme();
  const { can } = useAccessControl();

  return (
    <Grid
      container
      height="100%"
      bgcolor={theme.palette.taskView.mainBackground}
      alignItems="flex-start"
      wrap="nowrap"
    >
      <Grid item height="100%" width="calc(100% - 405px)">
        <ObjectiveViewHeader
          objective={objective}
          isMobileView={isMobileView}
        />

        <Grid
          container
          height={`calc(${contentHeight} - ${TASK_VIEW_MAIN_CONTENT_HEADER_HEIGHT}px)`}
          sx={{ overflowY: "auto" }}
        >
          <Grid container p="20px 26px 40px" flexDirection="column">
            <ObjectiveViewMainContent
              objective={objective}
              isMobileView={isMobileView}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        bgcolor={theme.palette.taskView.secondaryBackground}
        borderLeft="1px solid rgba(0, 0, 0, 0.10)"
        height={contentHeight}
        p="60px 32px 16px"
        pb={isPageView ? `${MAIN_CONTENT_PADDING_BOTTOM}px` : undefined}
        position="relative"
        width="405px"
        flexDirection="column"
        flexWrap="nowrap"
        gap="20px"
      >
        <Box sx={{ overflowY: "auto" }}>
          <Accordion title="Details" icon={<FormatListBulleted />} mb>
            <DetailsForm objective={objective} />
          </Accordion>

          <Accordion title="Accomplishment" icon={<Insights />} mb>
            <AccomplishmentChart objective={objective} />
          </Accordion>

          {can(AbilityEnum.financing) && (
            <Accordion title="Cost Forecast" icon={<AttachMoney />}>
              <CostChart objective={objective} />
            </Accordion>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ObjectiveViewDesktop;
