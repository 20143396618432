import { TUseKeyboardShortcutProps } from "./types/TUseKeyboardShortcutProps";
import { useEffect } from "react";
import { keyboard } from "../../../utils/keyboard";

export default function useKeyboardShortcut(props: TUseKeyboardShortcutProps) {
  useEffect(() => {
    const event = function (event: KeyboardEvent) {
      if (
        !keyboard.isActiveElementEditable() &&
        keyboard.isSingleKeyPressed(event, props.key)
      ) {
        event.preventDefault();
        props.callback(event);
      }
    };
    window.addEventListener("keydown", event);
    return () => {
      window.removeEventListener("keydown", event);
    };
  }, []);
}
