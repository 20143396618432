import { TMoveToTaskDialogProps } from "./types/TMoveToTaskDialogProps";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { Dialog } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { backlogItemViewActions } from "../../view/slices/viewSlice";
import MoveToTaskForm from "./MoveToTaskForm";

const MoveToTaskDialog = ({ isOnViewPage }: TMoveToTaskDialogProps) => {
  const dispatch = useAppDispatch();
  const moveToTaskState = useAppSelector(
    (state) => state.backlogItemView.moveToTask
  );

  const closeHandler = () => {
    dispatch(backlogItemViewActions.closeMoveToTaskDialog());
  };

  if (!moveToTaskState?.openDialog) {
    return null;
  }

  return (
    <Dialog open={true} onClose={closeHandler} fullWidth maxWidth="sm">
      <DialogTitle>Add to timeline</DialogTitle>
      <DialogContent>
        <MoveToTaskForm
          backlogItemRef={moveToTaskState.backlogItemRef}
          boardRef={moveToTaskState.boardRef}
          onCancel={closeHandler}
          isOnViewPage={isOnViewPage}
        />
      </DialogContent>
    </Dialog>
  );
};

export default MoveToTaskDialog;
