import OrgSignUpStep from "./OrgSignUpStep";
import VerifyPhoneStep from "./VerifyPhoneStep";
import OrgPasswordStep from "./OrgPasswordStep";
import CheckoutStep from "./CheckoutStep";
import VerifyMailStep from "./VerifyMailStep";
import { TStepsProps } from "./types/TStepsProps";
import { env } from "../../../../../common/utils/env";

const Steps = (props: TStepsProps) => {
  return [
    OrgSignUpStep(props.orgSignUpProps),
    VerifyMailStep(props.verifyMailProps),
    ...(env.phoneVerificationEnabled
      ? [VerifyPhoneStep(props.verifyPhoneProps)]
      : []),
    OrgPasswordStep(props.orgPasswordProps),
    CheckoutStep(props.checkoutProps),
  ];
};

export default Steps;
