import { useHttpClient } from "../../../common/modules/httpClient/hooks/useHttpClient";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useAppDispatch } from "../../../common/hooks/redux";
import { stripeActions } from "../slices/stripeSlice";
import { IStripeInvoiceResource } from "../interfaces/IStripeInvoiceResource";

export const useFetchInvoiceListService = () => {
  const dispatch = useAppDispatch();

  return useHttpClient({
    url: `/stripe/invoiceList`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IStripeInvoiceResource[]) => {
        dispatch(stripeActions.setList(data));
      },
    },
  });
};
