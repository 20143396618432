import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IConvertBacklogItemToEntityResource } from "../interfaces/IConvertBacklogItemToEntityResource";

export const useConvertBacklogItemToEntityService = (props: {
  backlogItemId: number;
}) => {
  const response = useHttpClientDispatchable({
    url: `/backlog/${props.backlogItemId}/convertToEntity`,
    method: RequestMethodEnum.POST,
  });

  return {
    ...response,
    data: response.data as IConvertBacklogItemToEntityResource,
  };
};
