import Dropdown from "../../materialUI/components/Dropdown/Dropdown";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { SelectChangeEvent } from "@mui/material";
import { boardQueryActions } from "../../../../modules/boardView/slices/boardQuerySlice";

const useSprint = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.boardQuery.extraFilters);
  const sprintList = useAppSelector((state) => state.listing.sprintList)!;
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);

  const filterChangeHandler = (event: SelectChangeEvent<any>) => {
    event.preventDefault();
    const value = event.target.value as string;
    const label = sprintList.find((item) => item.id == value)!.label!;
    const updatedSprintRefs = [...filters.sprintRefs];
    const existingIndex = updatedSprintRefs.findIndex(
      (sprint) => sprint.id === event.target.value
    );

    if (existingIndex !== -1) {
      updatedSprintRefs.splice(existingIndex, 1);
    } else {
      updatedSprintRefs.push({
        id: event.target.value,
        label,
      });
    }

    dispatch(
      boardQueryActions.setExtraFiltering({
        ...filters,
        sprintRefs: updatedSprintRefs,
      })
    );
  };

  return {
    dropdownFilter: () => {
      return sprintList && sprintList.length > 0 ? (
        <Dropdown
          onChange={(e) => filterChangeHandler(e)}
          name={sprintLabel}
          sx={{ fontSize: "0.9rem" }}
          withoutBorder
          hideEmpty={true}
          renderBadge={(option) =>
            !!filters.sprintRefs.find((sprint) => sprint.id === option.id)
          }
          options={[
            ...[{ id: "", label: sprintLabel }],
            ...(sprintList || []).slice(-20),
          ]}
        />
      ) : null;
    },
  };
};

export default useSprint;
