import { IconTypeEnum } from "../../../entities/columns/orgIconLibrary/IconTypeEnum";
import { DefaultIconTaskTypeEnum } from "../../taskType/enums/DefaultIconTaskTypeEnum";
import { DefaultIconTaskPriorityEnum } from "../../taskPriority/enums/DefaultIconTaskPriorityEnum";
import { TListItem } from "../../../common/types/TListItem";
import { Badge, Grid, IconButton, InputAdornment } from "@mui/material";

export const iconLibraryUtil = {
  getDefaultIconEnum: (iconType: IconTypeEnum): Record<string, string> => {
    switch (iconType) {
      case IconTypeEnum.TASK_TYPE:
        return DefaultIconTaskTypeEnum;
      case IconTypeEnum.TASK_PRIORITY:
        return DefaultIconTaskPriorityEnum;
      default:
        throw new Error("Invalid icon type");
    }
  },
  getImg: (iconFilePath: string, iconSize: number = 25) => {
    return (
      <img
        src={`${process.env.CDN_URL}/${iconFilePath}`}
        alt="Icon"
        width={iconSize}
        height={iconSize}
        style={{ display: "flex" }}
      />
    );
  },
  getDropdownRenderOption: (
    option: TListItem<string | number>,
    iconFilePath?: string,
    renderBadge?: boolean,
    defaultIcon?: JSX.Element,
    dropdownRenderOptionStyles?: {
      optionContainerSpacing: number;
      optionContainerGap: string;
      iconSize: number;
    }
  ) => {
    return (
      <Grid
        container
        alignItems="center"
        spacing={dropdownRenderOptionStyles?.optionContainerSpacing ?? 1}
        gap={dropdownRenderOptionStyles?.optionContainerGap ?? 0}
      >
        <Grid item>
          {iconFilePath
            ? iconLibraryUtil.getImg(
                iconFilePath,
                dropdownRenderOptionStyles?.iconSize
              )
            : defaultIcon}
        </Grid>
        {!!option.label && (
          <Grid item display="flex" justifyContent="end">
            {option.label}
            {renderBadge && (
              <IconButton sx={{ display: "flex", justifyContent: "end" }}>
                <Badge color="error" variant={"dot"}></Badge>
              </IconButton>
            )}
          </Grid>
        )}
      </Grid>
    );
  },
  getAutocompleteRenderOption: (
    optionProps: React.HTMLAttributes<HTMLLIElement>,
    option: TListItem<string | number>,
    iconFilePath?: string
  ) => {
    return (
      <li {...optionProps} key={option.id}>
        {iconLibraryUtil.getDropdownRenderOption(option, iconFilePath)}
      </li>
    );
  },
  getAutocompleteInputStartAdornment: (iconFilePath?: string) => {
    if (!iconFilePath) {
      return undefined;
    }

    return (
      <InputAdornment position="start">
        {iconLibraryUtil.getImg(iconFilePath)}
      </InputAdornment>
    );
  },
};
