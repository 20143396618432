import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { statusFlowActions } from "../../../slices/statusFlowSlice";
import { TMoveDataFromStatusFormProps } from "./types/TMoveDataFromStatusFormProps";
import useForm from "../../../../../common/hooks/useForm/useForm";
import { TMoveDataFromStatusFormAttributes } from "./types/TMoveDataFromStatusFormAttributes";
import { TListResource } from "../../../../../common/types/TListResource";
import Form from "../../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  TextField,
} from "../../../../../common/modules/materialUI";
import { Grid } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";
import { useMoveDataAndDeleteStatusService } from "../../../services/useMoveDataAndDeleteStatusService";
import { TMoveDataFromStatusFormAttributesTransformed } from "./types/TMoveDataFromStatusFormAttributesTransformed";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { orgActions } from "../../../../org/slices/orgSlice";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";

const MoveDataFromStatusForm = ({
  onCancel,
  fromStatus,
}: TMoveDataFromStatusFormProps) => {
  const dispatch = useAppDispatch();
  const statusList = useAppSelector((state) => state.statusFlow.statusList);
  const { isLoading, dispatch: dispatchMoveAndDelete } =
    useMoveDataAndDeleteStatusService();
  const toStatusOptions: TListResource<number> = statusList
    .filter(
      (statusListItem) =>
        !statusListItem.isNew && statusListItem.id !== fromStatus.id
    )
    .map((statusListItem) => ({
      id: +statusListItem.id,
      label: statusListItem.name,
    }));

  const form = useForm<TMoveDataFromStatusFormAttributes>({
    fromStatusId: fromStatus.name,
    toStatusId: null,
  });

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchMoveAndDelete({
      body: form.getTransformed<TMoveDataFromStatusFormAttributesTransformed>(
        (data) => ({
          fromStatusId: +fromStatus.id,
          toStatusId: data.toStatusId?.id ?? null,
        })
      ),
    })
      .then(() => {
        dispatch(statusFlowActions.removeStatus({ status: fromStatus }));
        dispatch(orgActions.deleteStatus({ statusId: +fromStatus.id }));
        // Closing dialog
        dispatch(statusFlowActions.closeMoveDataFromStatusDialog());

        dispatch(
          systemNotificationActions.open({
            message: `Status ${fromStatus.name} is deleted successfully`,
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button onClick={onCancel} disabled={isLoading} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
            color="error"
          >
            Move and Delete
          </Button>
        </>
      }
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6}>
          <TextField
            form={form}
            name="fromStatusId"
            label="From Status"
            showRequired
            disabled
            InputProps={{
              endAdornment: <ArrowRightAlt />,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            form={form}
            name="toStatusId"
            label="To Status"
            showRequired
            options={toStatusOptions}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default MoveDataFromStatusForm;
