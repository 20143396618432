import { Box, FormControl, FormHelperText, InputLabel } from "@mui/material";
import IconLibraryPicker from "../IconLibraryPicker/IconLibraryPicker";
import { TIconLibraryPickerFormElementProps } from "./types/TIconLibraryPickerFormElementProps";

const IconLibraryPickerFormElement = <TFormAttributes extends unknown>(
  props: TIconLibraryPickerFormElementProps<TFormAttributes>
) => {
  return (
    <Box>
      <InputLabel sx={{ mb: 1 }}>{`${props.label}${
        props.showRequired ? " *" : ""
      }`}</InputLabel>
      <FormControl>
        <IconLibraryPicker
          iconType={props.iconType}
          selectedIconPath={props.form.get(props.name)}
          onSelect={(selectedIconPath) =>
            props.form.changeHandler(props.name as string, selectedIconPath)
          }
        />
        <FormHelperText error={props.form.hasError(props.name)}>
          {props.form.validationErrors?.[props.name]}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default IconLibraryPickerFormElement;
