import { FavoriteRounded, Grade } from "@mui/icons-material";
import List from "../../common/List/List";
import { TSectionProps } from "../../types/TSectionProps";
import WhatsNewModal from "../../../../../../modules/exploreAndShare/containers/WhatsNew/WhatsNewModal";
import FeedbackModal from "../../../../../../modules/exploreAndShare/containers/Feedback/FeedbackModal";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux";
import { exploreAndShareActions } from "../../../../../../modules/exploreAndShare/slices/exploreAndShareSlice";

export default function ExploreAndShare(props: TSectionProps) {
  const org = useAppSelector((state) => state.org.init);
  const dispatch = useAppDispatch();

  if (org.isLoading) {
    return null;
  }

  return (
    <>
      <List
        title="Explore & Share"
        items={[
          {
            navigateToPath: false,
            onClick: () => dispatch(exploreAndShareActions.openWhatsNew()),
            id: "whatsNew",
            name: "What's New?",
            path: "/",
            icon: <Grade />,
          },
          {
            navigateToPath: false,
            onClick: () => dispatch(exploreAndShareActions.openFeedback()),
            id: "feedback",
            name: "Feedback",
            path: "/",
            icon: <FavoriteRounded sx={{ color: "#FF5F5F" }} />,
          },
        ]}
      />
      <WhatsNewModal />
      <FeedbackModal />
    </>
  );
}
