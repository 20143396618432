import { SxProps, Theme } from "@mui/material";
import { CSSProperties } from "react";

export const spikeStyleConfig = (theme: Theme) => ({
  containerStyle: {
    backgroundColor: undefined,
    border: theme.palette.spike.border,
    height: theme.palette.mode === "dark" ? "85px" : "88px",
    borderRadius: "12px",
  } as CSSProperties,
  contentStyle: {
    backgroundColor: theme.palette.spike.backgroundColor,
    color: theme.palette.spike.color,
    borderRadius: "10px",
    width: "calc(100% - 8px)",
    height: "calc(100% - 8px)",
  } as CSSProperties,
  smallContainerStyle: {
    borderRadius: "5px",
  } as CSSProperties,
  smallContentStyle: {
    borderRadius: "3px",
    width: "calc(100% - 4px)",
    height: "calc(100% - 4px)",
  } as CSSProperties,
  moreOptionsStyle: {
    color: theme.palette.spike.color,
  } as SxProps,
  nameStyle: {
    textAlign: "center",
  } as CSSProperties,
});
