import { useAppDispatch } from "../redux";
import useForm from "../useForm/useForm";
import { TUseHttpClientCoreDispatch } from "../../modules/httpClient/hooks/types/TUseHttpClientCoreDispatch";
import { IUploadSignedUrlResource } from "./interfaces/IUploadSignedUrlResource";
import { THttpClientError } from "../../modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../modules/systemNotification/slices/systemNotificationSlice";
import { TFileData } from "./types/TFileData";

export default function useGetSignedUploadUrlToCloud() {
  const dispatch = useAppDispatch();
  const { getValidationErrorAtIndex } = useForm();

  return {
    dispatch: (
      fileData: TFileData,
      dispatchUploadService: TUseHttpClientCoreDispatch,
      onSuccess: (payload: IUploadSignedUrlResource) => void,
      onError?: (error: string) => void,
      extraServiceParams?: { [key: string]: any }
    ) => {
      dispatchUploadService({
        query: {
          name: fileData.file.name,
          contentType: fileData.file.type,
          size: fileData.file.size,
          width: fileData.resolution?.width,
          height: fileData.resolution?.height,
          ...extraServiceParams,
        },
      })
        .then((resource: IUploadSignedUrlResource) => {
          onSuccess(resource);
        })
        .catch((error: THttpClientError) => {
          if (error.status === 422) {
            const validationFirstError = getValidationErrorAtIndex(0, error);
            if (validationFirstError) {
              dispatch(
                systemNotificationActions.open({
                  variant: "error",
                  message: validationFirstError,
                })
              );
              onError?.(validationFirstError);
            }
          } else {
            const message =
              "Failed to upload the attachment. Please try again.";
            dispatch(
              systemNotificationActions.open({
                variant: "error",
                message: message,
              })
            );
            onError?.(message);
          }
        });
    },
  };
}
