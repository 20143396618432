import { TSubmitProps } from "./types/TSubmitProps";
import { Check } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export default function Submit(props: TSubmitProps) {
  return (
    <IconButton
      type="submit"
      disabled={props.disabled}
      onClick={props.submitHandler}
    >
      <Check
        fontSize="small"
        sx={{ fontSize: props.fontSize ? `${props.fontSize}px` : undefined }}
      />
    </IconButton>
  );
}
