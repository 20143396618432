import TProgressBarProps from "./types/TProgressBarProps";
import classes from "./ProgressBar.module.css";
import { array } from "../../utils/array";

const ProgressBar = (props: TProgressBarProps) => {
  return (
    <div
      className={array.toClassName([
        props.className ?? "",
        classes["progress-bar"],
        classes["size-" + (props.size ?? "md")],
        props.parentBgTransparent ?? false ? classes["bg-transparent"] : "",
      ])}
    >
      <div
        className={`${classes["bar"]} ${props.bg ?? "bg-default"}`}
        style={{ width: `${props.progress}%` }}
      />
    </div>
  );
};

export default ProgressBar;
