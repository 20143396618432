import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import Icon from "./Icon";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "../../../../common/modules/materialUI";
import { TMoreIconsDialogProps } from "./types/TMoreIconsDialogProps";
import { useIconLibraryListService } from "../../services/useIconLibraryListService";
import { useEffect, useState } from "react";
import Loading from "../../../../common/components/Icon/Loading/Loading";
import { iconLibraryUtil } from "../../utils/iconLibraryUtil";
import UploadIconButton from "./UploadIconButton";
import FileUploadProvider from "../../../../common/containers/FileUploadProvider/FileUploadProvider";
import useUploadIconServiceHelper from "../../hooks/useUploadIconServiceHelper";

const MoreIconsDialog = ({
  iconType,
  open,
  onSelect,
  onCancel,
}: TMoreIconsDialogProps) => {
  const [selectedDialogIconPath, setSelectedDialogIconPath] = useState<
    string | null
  >(null);
  const {
    dispatch: dispatchGetIconList,
    isLoading,
    data: iconLibraryData,
  } = useIconLibraryListService({ iconType });
  const { dispatch: dispatchUploadIconService } =
    useUploadIconServiceHelper(iconType);

  useEffect(() => {
    dispatchGetIconList();
  }, []);

  const iconPaths = [
    ...(iconLibraryData
      ? iconLibraryData.map((iconLibrary) => iconLibrary.filePath)
      : []),
    ...Object.values(iconLibraryUtil.getDefaultIconEnum(iconType)),
  ];

  const cancelHandler = () => {
    onCancel();
    setSelectedDialogIconPath(null);
  };
  const selectHandler = () => {
    selectedDialogIconPath && onSelect(selectedDialogIconPath);
    setSelectedDialogIconPath(null);
  };

  const fileLoadHandler = (file: File) =>
    dispatchUploadIconService(file, (resource) =>
      dispatchGetIconList().then(() =>
        setSelectedDialogIconPath(resource.filePath)
      )
    );

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <FileUploadProvider
        onFileLoad={fileLoadHandler}
        fileDropText="Drop icon here to upload."
        dropZoneId="iconDropZone"
        fileMaxLimit={1}
        allowedMimeTypes={["image/jpeg", "image/png"]}
        inputConfig={{
          id: "iconFileInput",
          name: "iconFileInput",
        }}
      >
        <DialogTitle>Choose an icon</DialogTitle>
        <DialogContent>
          <Box sx={{ position: "relative" }}>
            <Grid container justifyContent="center">
              <Grid item>
                <UploadIconButton onFileLoad={fileLoadHandler} />
              </Grid>
              <Grid item>
                <Typography color="textSecondary" sx={{ fontSize: "0.65rem" }}>
                  Upload Requirement: File must be between 150px and 300px, not
                  exceeding 50KB
                </Typography>
              </Grid>
            </Grid>

            <Grid container alignItems="center" spacing={1} mt={1}>
              {isLoading && (
                <Grid item>
                  <Loading />
                </Grid>
              )}
              {!isLoading &&
                iconPaths.map((iconPath) => (
                  <Grid item xs={4} md={2} key={iconPath}>
                    <Icon
                      filePath={iconPath}
                      isSelected={selectedDialogIconPath === iconPath}
                      onSelect={setSelectedDialogIconPath}
                      width={60}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelHandler}>Cancel</Button>
          <Button
            onClick={selectHandler}
            disabled={!selectedDialogIconPath}
            autoFocus
            variant="contained"
          >
            Select
          </Button>
        </DialogActions>
      </FileUploadProvider>
    </Dialog>
  );
};

export default MoreIconsDialog;
