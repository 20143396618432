import { IconButton, Typography, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { TMenuItemProps } from "../../../../common/containers/Menu/types/TMenuItemProps";
import Menu from "../../../../common/containers/Menu/Menu";
import { comingSoon } from "../../../../common/utils/comingSoon";
import {
  AddRoad,
  AddTask,
  Event,
  MultilineChart,
  Sensors,
} from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { taskActions } from "../../../task/slices/taskSlice";
import Section from "./Section";
import OfftimeManagementMenu from "../../../offtime/containers/Menu/OfftimeManagementMenu";
import ShortcutProTip from "../../../../common/modules/keyboardShortcut/containers/ProTip/ShortcutProTip";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { KeyboardShortcutEnum } from "../../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import { monitorActions } from "../../../monitor/slices/monitorSlice";
import { useNavigate, useParams } from "react-router-dom";
import { TBoardParams } from "../../../board/containers/Board/types/TBoardParams";
import { InternalTypeEnum } from "../../../../entities/columns/task/InternalTypeEnum";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { featureUtil } from "../../../../common/modules/accessControl/utils/featureUtil";

const AddButton = () => {
  const theme = useTheme();
  const taskLabel = useAppSelector((state) => state.customs.taskLabel);
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);
  const monitorLabel = useAppSelector((state) => state.customs.monitorLabel);
  const { projectAbbr } = useParams<TBoardParams>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSpikeCreationAllowed = useIsFeatureAllowed(FeatureEnum.SPIKE);

  const addTaskClickHandler = () => {
    dispatch(taskActions.openCreationModal(InternalTypeEnum.TASK));
  };

  const addSpikeClickHandler = () => {
    isSpikeCreationAllowed
      ? dispatch(taskActions.openCreationModal(InternalTypeEnum.SPIKE))
      : dispatch(
          systemNotificationActions.open({
            message: featureUtil.notAvailableFullText,
            variant: "warning",
          })
        );
  };

  const addMonitorClickHandler = () => {
    dispatch(monitorActions.openCreationModal());
  };

  const addSprintClickHandler = () => {
    navigate(`/settings/project/${projectAbbr}/sprints?modalOpen=true`);
  };

  return (
    <Menu
      stretchOnMobile={false}
      closeOnSelection={true}
      button={(clickHandler) => {
        return (
          <>
            <IconButton
              aria-label="delete"
              onClick={clickHandler}
              size="medium"
              style={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              <AddIcon />
            </IconButton>
          </>
        );
      }}
      menuItems={
        [
          menuItem.make(
            <Section
              helperText={
                <ShortcutProTip
                  shortcutKeyString={KeyboardShortcutEnum.t}
                  description="add a task"
                />
              }
              icon={<AddTask />}
              name={`Add ${taskLabel}`}
            />,
            addTaskClickHandler
          ),
          OfftimeManagementMenu({
            label: <Typography>Add Offtime</Typography>,
          }),
          menuItem.make(
            <Section icon={<Event />} name="Add Event" disabled />,
            comingSoon.alertHandler
          ),
          menuItem.make(
            <Section icon={<MultilineChart />} name="Add Spike" />,
            addSpikeClickHandler,
            undefined,
            !isSpikeCreationAllowed,
            featureUtil.notAvailableText
          ),
          menuItem.make(
            <Section icon={<AddRoad />} name={`Add ${sprintLabel}`} />,
            addSprintClickHandler
          ),
          menuItem.make(
            <Section icon={<Sensors />} name={`Add ${monitorLabel}`} />,
            comingSoon.alertHandler
          ),
        ] as TMenuItemProps[]
      }
    />
  );
};

export default AddButton;
