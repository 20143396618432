import { Box, Grid, useTheme } from "@mui/material";
import { FormatListBulleted } from "@mui/icons-material";
import { useAppSelector } from "../../../../../common/hooks/redux";
import TaskViewHeader, {
  TASK_VIEW_MAIN_CONTENT_HEADER_HEIGHT,
} from "./Header/TaskViewHeader";
import TaskViewMainContent from "./TaskViewMainContent/TaskViewMainContent";
import FileUploadProvider from "../../../../../common/containers/FileUploadProvider/FileUploadProvider";
import { MAIN_CONTENT_PADDING_BOTTOM } from "../../../../../common/containers/Layout/App/Content/Content";
import StatusForm from "./Status/StatusForm";
import TaskViewDetails from "./TaskViewDetails/TaskViewDetails";
import QuickActions from "../../../../../common/modules/taskView/containers/QuickActions/QuickActions";
import useUploadAttachmentServiceHelper from "../../../../attachment/hooks/useUploadAttachmentServiceHelper";
import { EntityTypeEnum } from "../../../../../entities/enums/EntityTypeEnum";
import { useUpdateTaskService } from "../../../services/useUpdateTaskService";
import CustomFieldSection from "../../../../customField/containers/CustomFieldSection/CustomFiledSection";
import { TTaskContentProps } from "./types/TTaskContentProps";
import AddAttachment from "../../../../../common/modules/taskView/containers/QuickActions/containers/AddAttachment";
import AddLink from "../../../../../common/modules/taskView/containers/QuickActions/containers/AddLink";
import MoveToBacklog from "../../../../../common/modules/taskView/containers/QuickActions/containers/MoveToBacklog";
import Accordion from "../../../../../common/modules/taskView/containers/Sections/Accordion";
import FinancialForecastAccordion from "./FinancialForecastSection/FinancialForecastAccordion";
import DeleteSegment from "../../../../../common/modules/taskView/containers/QuickActions/containers/DeleteSegment";
import ConvertInternalType from "../../../../../common/modules/taskView/containers/QuickActions/containers/ConvertInternalType";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";

const TaskViewDesktop = ({
  task,
  taskResource,
  contentHeight,
  isMobileView,
  isPageView,
}: TTaskContentProps) => {
  const theme = useTheme();

  const { dispatch: dispatchTaskPatchService } = useUpdateTaskService(
    task.ref!
  );
  const { dispatch: dispatchUploadAttachmentService } =
    useUploadAttachmentServiceHelper(EntityTypeEnum.TASK, task.taskId);

  const isSegmentView =
    useAppSelector((state) => state.taskView.viewType) === "segment";
  const taskLabel = useAppSelector((state) => state.customs.taskLabel);

  return (
    <Grid
      container
      height="100%"
      bgcolor={theme.palette.taskView.mainBackground}
      alignItems="flex-start"
      wrap="nowrap"
    >
      <Grid item height="100%" width="calc(100% - 405px)">
        <TaskViewHeader
          task={task}
          isMobileView={isMobileView}
          isPageView={isPageView}
        />

        <Grid
          container
          height={`calc(${contentHeight} - ${TASK_VIEW_MAIN_CONTENT_HEADER_HEIGHT}px)`}
          sx={{ overflowY: "auto" }}
        >
          <Grid container p="20px 26px 40px" flexDirection="column">
            <TaskViewMainContent
              resource={taskResource}
              task={task}
              isMobileView={isMobileView}
              isPageView={isPageView}
            />
          </Grid>
        </Grid>
      </Grid>

      <FileUploadProvider
        onFileLoad={(file) => dispatchUploadAttachmentService(file)}
        fileDropText={`Drop files here to attach to the ${taskLabel}`}
        dropZoneId="taskDropZone"
        inputConfig={{
          id: "taskAttachment",
          name: "taskAttachment",
        }}
        style={{
          bottom: 0,
          height: contentHeight,
          right: 0,
          width: "405px",
        }}
        fileDropZoneTextBoxStyles={{
          bottom: "150px !important",
          width: "90%",
        }}
        labelStyles={{
          height: "100%",
        }}
        shouldRender={true}
      >
        <Grid
          container
          item
          bgcolor={theme.palette.taskView.secondaryBackground}
          borderLeft="1px solid rgba(0, 0, 0, 0.10)"
          height={contentHeight}
          p="16px 32px"
          pb={isPageView ? `${MAIN_CONTENT_PADDING_BOTTOM}px` : undefined}
          position="relative"
          width="405px"
          flexDirection="column"
          flexWrap="nowrap"
          gap="20px"
        >
          {isSegmentView && (
            <Grid container>
              <StatusForm task={task} />
            </Grid>
          )}

          <Box sx={{ overflowY: "auto" }}>
            <Accordion title="Details" icon={<FormatListBulleted />} mb>
              <TaskViewDetails resource={taskResource} task={task} />
            </Accordion>

            <CustomFieldSection
              boardRef={task.boardRef}
              dispatchUpdateService={dispatchTaskPatchService}
              customFieldValues={task.customFieldValues}
              isMobileView={isMobileView}
            />

            <FinancialForecastAccordion task={task} />
          </Box>

          <QuickActions isMobileView={isMobileView}>
            <AddAttachment
              isMobileView={isMobileView}
              entityType={EntityTypeEnum.TASK}
              entityId={task.taskId}
            />
            <AddLink
              entityType={EntityTypeEnum.TASK}
              entityId={task.taskId}
              projectRef={task.projectRef}
            />
            <MoveToBacklog
              taskId={task.id}
              taskInternalType={task.internalType}
            />
            {[InternalTypeEnum.TASK, InternalTypeEnum.SPIKE].includes(
              task.internalType
            ) && (
              <ConvertInternalType
                taskId={task.id}
                taskInternalType={task.internalType}
              />
            )}
            {isSegmentView && <DeleteSegment task={task} />}
          </QuickActions>
        </Grid>
      </FileUploadProvider>
    </Grid>
  );
};

export default TaskViewDesktop;
