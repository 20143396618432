import { useAppDispatch } from "../redux";
import { systemNotificationActions } from "../../modules/systemNotification/slices/systemNotificationSlice";

export default function useUploadFileToCloud() {
  const dispatch = useAppDispatch();

  return {
    dispatch: (
      // https://storage.googleapis.com/ts-attachments/8523b538be7248d94a696d7cc2fb3c06e30ba7f5/6048c06979ce206ad78b27c8d6b1a7d43ba1ee43?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=ts-attachment-storage-user%40tasksensor-dev.iam.gserviceaccount.com%2F20240116%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240116T185439Z&X-Goog-Expires=10&X-Goog-SignedHeaders=content-disposition%3Bcontent-type%3Bhost&X-Goog-Signature=0d27fee24fc3c00960a6b0c75236d32dbe6d087eaf39b6c22b1054c208aa44b500e42e91d3194bb0eca1dadd00d6fc40c440305d692b585a3429ca9892b41a997e1cda9ca7954fbdc59170faa5c3760b87b773549c36a2f5ae81426a0f86f135b275644c4c0a6186a81734953a44d3d1f520f34f8955b47b9a6e461f67e23bb5fe993186c933c12434f43e629c93f5b90bf2340a3f78b0d6e8c8f817948a2f82a21521b61a856f325368dffe24fe7c3a4176d0bb62560134525abbcb35de49f2c21ee8b16e1c792bb75a5936d3fe655391c13cb7617eee4e6d5ef4fe70ec2eeac0d4110bcbbf1b925f3d0ec57419e659f38461b1db4427cf241203ceaaba323f
      signedUrl: string,
      file: File,
      onSuccess: () => void,
      onError?: (error: string) => void
    ) => {
      fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
          "Content-Disposition": "attachment",
        },
      })
        .then((response) => {
          if (response.ok) {
            onSuccess();
          } else {
            const message = `Unexpected ${response.status} status received while uploading the "${file.name}" to cloud`;
            dispatch(
              systemNotificationActions.open({
                variant: "error",
                message: message,
              })
            );
            onError?.(message);
          }
        })
        .catch((_) => {
          const message = `Something went wrong while uploading the "${file.name}" to cloud`;
          dispatch(
            systemNotificationActions.open({
              variant: "error",
              message: message,
            })
          );
          onError?.(message);
        });
    },
  };
}
