import { TOccupiedSpaceProps } from "./types/TOccupiedSpaceProps";
import { array } from "../../utils/array";
import classes from "./OccupiedSpaceBig.module.css";
import { useTheme } from "@mui/material";
import { pointConverter } from "../../utils/pointConverter";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Link from "./Link";

export default function OccupiedSpaceBig(props: TOccupiedSpaceProps) {
  const theme = useTheme();
  const sizeMd =
    props.size > pointConverter.workingHoursToPixels(3) &&
    props.size <= pointConverter.workingHoursToPixels(7);

  return (
    <div
      className={array.toClassName([classes.main])}
      style={{
        width: props.size,
        backgroundImage: `url(${theme.palette.occupied.backgroundImgSrc})`,
        color: theme.palette.occupied.color,
        ...props.style,
      }}
    >
      <div className={array.toClassName([classes.warning])}>
        <WarningRoundedIcon />
        <p>Occupied {!sizeMd && "space"}</p>
      </div>
      <p className={classes.ref}>
        <Link {...props}>{props.taskRef}</Link>{" "}
        {!sizeMd && props.mainDescription}
      </p>
      <p>
        {!sizeMd ? props.secondaryDescription : props.secondaryDescriptionShort}
      </p>
    </div>
  );
}
