import { ListItemIcon, Typography } from "@mui/material";
import { Logout as LogoutIcon } from "@mui/icons-material";
import { useSignOutHandler } from "../../hooks/useSignOutHandler";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";

const SignOut = () => {
  const signOutHandler = useSignOutHandler();

  return menuItem.make(
    <>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <Typography sx={{ pl: 2 }}>Sign Out</Typography>
    </>,
    signOutHandler
  );
};

export default SignOut;
