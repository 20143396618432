import { TSkeletonProps } from "./types/TSkeletonProps";
import { Skeleton as MuiSkeleton } from "@mui/material";

const Skeleton = (props: TSkeletonProps) => {
  return (
    <MuiSkeleton
      animation="wave"
      {...props}
      sx={{ ...{ bgcolor: "grey.105" }, ...props.sx }}
    />
  );
};

export default Skeleton;
