import Dropdown from "../../materialUI/components/Dropdown/Dropdown";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { SelectChangeEvent } from "@mui/material";
import { boardQueryActions } from "../../../../modules/boardView/slices/boardQuerySlice";
import { backlogBoardQueryActions } from "../../../../modules/boardView/modules/backlogView/slices/backlogBoardQuerySlice";

type TCustomField = {
  isBacklog?: boolean;
};

const useCustomField = (props: TCustomField) => {
  const dispatch = useAppDispatch();
  const boardFilters = useAppSelector((state) => state.boardQuery.extraFilters);
  const backlogFilters = useAppSelector(
    (state) => state.backlogBoardQuery.extraFilters
  );
  const filterableCustomFields = useAppSelector(
    (state) => state.listing.filterableCustomFieldList
  )!;
  const filters = props?.isBacklog ? backlogFilters : boardFilters;

  const filterChangeHandler = (
    event: SelectChangeEvent<any>,
    customFieldId: number
  ) => {
    event.preventDefault();
    const value = event.target.value as string;
    const label = filterableCustomFields
      .find((item) => item.id === customFieldId)
      ?.data?.options.find((item) => item.id == value)!.label!;
    const updatedCustomFields = [...filters.customFields];
    const existingIndex = updatedCustomFields.findIndex(
      (customField) =>
        customField.extraId === customFieldId && customField.value === value
    );

    if (existingIndex !== -1) {
      updatedCustomFields.splice(existingIndex, 1);
    } else {
      updatedCustomFields.push({
        id: customFieldId + value,
        label,
        value: value,
        extraId: customFieldId,
      });
    }

    props.isBacklog &&
      dispatch(
        backlogBoardQueryActions.setExtraFiltering({
          ...backlogFilters,
          customFields: updatedCustomFields,
        })
      );

    !props.isBacklog &&
      dispatch(
        boardQueryActions.setExtraFiltering({
          ...boardFilters,
          customFields: updatedCustomFields,
        })
      );
  };

  return {
    dropdownFilters: () => {
      return filterableCustomFields && filterableCustomFields.length > 0
        ? filterableCustomFields.map((item) => {
            return {
              component: (
                <Dropdown
                  onChange={(e) => filterChangeHandler(e, item.id)}
                  name={item.name}
                  sx={{ fontSize: "0.9rem" }}
                  withoutBorder
                  hideEmpty={true}
                  renderBadge={(option) => {
                    return !!filters.customFields.find(
                      (filter) =>
                        filter.value === option.id && filter.extraId === item.id
                    );
                  }}
                  options={[
                    ...[{ id: "", label: item.name }],
                    ...item.data!.options!.map((option) => {
                      return { ...option, extraId: item.id };
                    }),
                  ]}
                />
              ),
              label: item.name,
              id: item.name,
            };
          })
        : null;
    },
  };
};

export default useCustomField;
