import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Button, DatePicker } from "../../../common/modules/materialUI";
import { TExceptionModalProps } from "./types/TExceptionModalProps";
import { TExceptionModalFormAttributes } from "./types/TExceptionModalFormAttributes";
import Form from "../../../common/components/Form/Form";
import useForm from "../../../common/hooks/useForm/useForm";
import { useCreateExceptionService } from "../services/useCreateExceptionService";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../common/hooks/redux";
import { INonWorkday } from "../../../entities/INonWorkday";
import { THttpClientError } from "../../../common/modules/httpClient/types/THttpClientError";

const ExceptionModal = ({
  exceptionType,
  open,
  projectId,
  setOpenExceptionModal,
  setExceptions,
}: TExceptionModalProps) => {
  const form = useForm<TExceptionModalFormAttributes>();
  const dispatch = useAppDispatch();
  const { isLoading, dispatch: dispatchCreateExceptionService } =
    useCreateExceptionService();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreateExceptionService({
      body: { date: form?.data?.date, type: exceptionType },
      urlPath: `/${projectId}/exception`,
    })
      .then((payload: INonWorkday) => {
        setExceptions((prevExceptions) => [
          ...(prevExceptions ? prevExceptions : []),
          payload,
        ]);

        form.reset();
        setOpenExceptionModal((prevOpenState) => !prevOpenState);

        dispatch(
          systemNotificationActions.open({
            message: "Exception created",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);

        if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
        } else if (error.status !== 422) {
          dispatch(
            systemNotificationActions.open({
              message: "Unable to create exception, please try again.",
              variant: "error",
            })
          );
        }
      });
  };

  const formDisplayHandler = () => {
    form.reset();
    setOpenExceptionModal((prevOpenState) => !prevOpenState);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Add Exception</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submitHandler}
          actionElement={
            <>
              <Button
                disabled={isLoading}
                onClick={formDisplayHandler}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                disabled={!form.isDirty() || isLoading}
                loading={isLoading}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </>
          }
        >
          <DatePicker form={form} name="date" label="Exception Date" />
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ExceptionModal;
