import { useAppSelector } from "../../../common/hooks/redux";
import { TUseGetTaskPriorityProps } from "./types/TUseGetTaskPriorityProps";

const useGetTaskPriority = ({ taskPriorityId }: TUseGetTaskPriorityProps) => {
  const taskPriorities = useAppSelector(
    (state) => state.org.init.taskPriorities
  );

  if (!taskPriorityId) {
    return undefined;
  }

  return taskPriorityId
    ? taskPriorities.find((row) => row.id === taskPriorityId)
    : undefined;
};

export default useGetTaskPriority;
