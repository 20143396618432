import { Grid } from "@mui/material";
import { TBacklogItemViewMainContent } from "./types/TBacklogItemViewMainContent";
import Description from "../../../../../description/containers/Description/Description";
import Comments from "../../../../../comment/containers/Comments/Comments";
import { EntityTypeEnum } from "../../../../../../entities/enums/EntityTypeEnum";
import TitleForm from "../Title/TitleForm";
import BacklogItemInfoTabs from "../TaskInfoTabs/BacklogItemInfoTabs";

const BacklogItemViewMainContent = ({
  backlogItem,
  isMobileView,
}: TBacklogItemViewMainContent) => {
  return (
    <>
      <Grid container item mb="16px">
        <TitleForm backlogItem={backlogItem} />
      </Grid>

      <Description
        entityType={EntityTypeEnum.BACKLOG_ITEM}
        entityId={backlogItem.id}
        isMobileView={isMobileView}
      />

      <Grid container item mb="30px" flexDirection="column" gap="8px">
        <BacklogItemInfoTabs
          backlogItem={backlogItem}
          isMobileView={isMobileView}
        />
      </Grid>

      <Grid container item flexDirection="column" gap="40px">
        <Comments
          entityType={EntityTypeEnum.BACKLOG_ITEM}
          entityId={backlogItem.id}
          isMobileView={isMobileView}
        />
      </Grid>
    </>
  );
};

export default BacklogItemViewMainContent;
