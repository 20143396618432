import { Button } from "../../../../../common/modules/materialUI";
import Form from "../../../../../common/components/Form/Form";
import useForm from "../../../../../common/hooks/useForm/useForm";
import { TOrgPasswordFormAttributes } from "./types/TOrgPasswordFormAttributes";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { useOrgService } from "../../../../user/services/useOrgService";
import { TOrgPasswordProps } from "../SignUp/types/TOrgPasswordProps";
import Title from "../../../../../common/components/Title/Title";
import PasswordField from "../../../../user/containers/Auth/containers/Password/PasswordField";
import React from "react";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { IOrgRegistrationResource } from "../../../interfaces/IOrgRegistrationResource";

const OrgPassword = (props: TOrgPasswordProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const form = useForm<TOrgPasswordFormAttributes>({
    inquiryUuid: props.inquiryUuid,
    subscriptionPlan: props.subscriptionPlan,
  });
  const { isLoading, dispatch: dispatchOrgService } = useOrgService();
  const dispatch = useAppDispatch();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    dispatchOrgService({
      body: {
        ...form.data,
        referralCode: sessionStorage.getItem("referralCode"),
      },
    })
      .then((response: IOrgRegistrationResource) => {
        props.onNextHandler && props.onNextHandler(response.email);
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
        error.status === 406 &&
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "error",
            })
          );
      });
  };

  return (
    <>
      <Title>
        Please choose a strong password for your organization owner account.
      </Title>
      <Form
        onSubmit={submitHandler}
        actionElement={
          <>
            <Button
              variant="text"
              type="submit"
              size="large"
              disabled={isLoading}
              loading={isLoading}
            >
              Submit
            </Button>
          </>
        }
      >
        <PasswordField forceFocus={!isTouchDevice} form={form} showHint />

        <PasswordField
          form={form}
          name="password_confirmation"
          label="Password Confirmation"
        />
      </Form>
    </>
  );
};

export default OrgPassword;
