import { Typography, useTheme } from "@mui/material";
import { Button, Tooltip } from "../../../common/modules/materialUI";
import { useContactSupportHandler } from "../hooks/useContactSupportHandler";

const HelpCenterIcon = () => {
  const theme = useTheme();
  const { openSupportChat } = useContactSupportHandler();

  return (
    <Tooltip
      title={
        <Typography fontSize="small">
          We are here for you. <br />
          Contact us anytime if you need assistance.
        </Typography>
      }
    >
      <Button size="small" onClick={openSupportChat} disableFocusRipple={true}>
        <img
          src={theme.palette.helpCenter.liveChatIcon}
          alt="Live Chat Icon"
          style={{
            height: "auto",
            width: "30px",
          }}
        />
      </Button>
    </Tooltip>
  );
};

export default HelpCenterIcon;
