import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import CreateModal from "../../../../../modules/task/containers/CreateTask/CreateModal";
import { Button } from "../../../materialUI";
import { AddTask, ReplyAll } from "@mui/icons-material";
import { taskActions } from "../../../../../modules/task/slices/taskSlice";
import { useSubscribeEvent } from "../../../eventProvider";
import { EventEnum } from "../../../eventProvider/enums/EventEnum";
import { systemNotificationActions } from "../../../systemNotification/slices/systemNotificationSlice";
import Image from "./Card/Image";
import Card from "./Card/Card";
import CardText from "./CardText/CardText";
import { useTheme } from "@mui/material";
import CelebrationIcon from "./CardText/CelebrationIcon";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";

export default function TaskStep() {
  const theme = useTheme();
  const { list: projectsList } = useAppSelector((state) => state.org.init);
  const dispatch = useAppDispatch();
  const taskLabel = useAppSelector((state) => state.customs.taskLabel);
  useSubscribeEvent(EventEnum.ON_TASK_CREATED, () => {
    dispatch(
      systemNotificationActions.open({
        message: "Your first task is created successfully!",
        variant: "success",
      })
    );
  });
  const boardRef = projectsList[0]?.boards[0]?.ref ?? null;

  return {
    label: "Launch Your First Task",
    content: !!boardRef ? (
      <Card
        title="Add your first task"
        description={`${taskLabel}s help you organize your work and timeline.`}
        button={
          <>
            <CreateModal boardRef={boardRef} renderOptionalFields={false} />
            <Button
              variant="contained"
              size="small"
              startIcon={<AddTask />}
              onClick={() =>
                dispatch(taskActions.openCreationModal(InternalTypeEnum.TASK))
              }
            >
              Add {taskLabel}
            </Button>
          </>
        }
        icon={<Image imageName="task-step-v2" />}
      />
    ) : (
      <CardText
        title={<>You must create a project first to access task creation.</>}
        icon={
          <ReplyAll
            style={{ fontSize: "80px", color: theme.palette.primary.main }}
          />
        }
        iconStyle={{ top: "-8px", left: "8px" }}
      />
    ),
    contentCompleted: (
      <CardText
        title={
          <>
            Now that you have your first task set up, you can easily create new
            tasks from any board.
          </>
        }
        icon={<CelebrationIcon />}
      />
    ),
  };
}
