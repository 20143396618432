import { Grid } from "@mui/material";
import { TGuesstimationItemViewMainContentProps } from "./types/TGuesstimationItemViewMainContentProps";
import Description from "../../../../../description/containers/Description/Description";
import Comments from "../../../../../comment/containers/Comments/Comments";
import { EntityTypeEnum } from "../../../../../../entities/enums/EntityTypeEnum";
import TitleForm from "../Title/TitleForm";
import GuesstimationItemInfoTabs from "../GuesstimationItemInfoTabs/GuesstimationItemInfoTabs";

const GuesstimationItemViewMainContent = ({
  resource,
  isMobileView,
  isPageView,
}: TGuesstimationItemViewMainContentProps) => {
  return (
    <>
      <Grid container item mb="16px">
        <TitleForm guesstimationItem={resource.guesstimationItem} />
      </Grid>

      <Description
        entityType={EntityTypeEnum.GUESSTIMATION_ITEM}
        entityId={resource.guesstimationItem.id}
        isMobileView={isMobileView}
      />

      <Grid container item mb="30px" flexDirection="column" gap="8px">
        <GuesstimationItemInfoTabs
          resource={resource}
          isMobileView={isMobileView}
          isPageView={isPageView}
        />
      </Grid>

      <Grid container item flexDirection="column" gap="40px">
        <Comments
          entityType={EntityTypeEnum.GUESSTIMATION_ITEM}
          entityId={resource.guesstimationItem.id}
          isMobileView={isMobileView}
        />
      </Grid>
    </>
  );
};

export default GuesstimationItemViewMainContent;
