import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGuesstimationReportDataResource } from "../interfaces/IGuesstimationReportDataResource";

type TInitialState = {
  displayModal: boolean;
  data: IGuesstimationReportDataResource[];
};

const initialState: TInitialState = {
  displayModal: false,
  data: [],
};

const slice = createSlice({
  name: "guesstimationItemReport",
  initialState,
  reducers: {
    openReportModal: (state) => {
      state.displayModal = true;
    },
    closeReportModal: (state) => {
      state.displayModal = false;
      state.data = initialState.data;
    },
    setReportData: (
      state,
      action: PayloadAction<IGuesstimationReportDataResource[]>
    ) => {
      state.data = action.payload;
    },
  },
});

export const guesstimationItemReportReducer = slice.reducer;

export const guesstimationItemReportActions = slice.actions;
