import { Dialog, DialogContent } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { initiativeActions } from "../../../../slices/initiativeSlice";
import { TManageModalProps } from "./types/TManageModalProps";
import LinkForm from "./LinkForm";
import DialogTitle from "@mui/material/DialogTitle";

const LinkInitiativeModal = (props: TManageModalProps) => {
  const showModal = useAppSelector(
    (state) => state.initiative.update.showLinkModal
  );
  const initiative = useAppSelector(
    (state) => state.initiative.update.initiative
  );
  const dispatch = useAppDispatch();

  const closeHandler = () => dispatch(initiativeActions.closeLinkModal());

  return (
    <Dialog open={showModal} onClose={closeHandler} fullWidth maxWidth="sm">
      <DialogTitle>Link Initiative Board</DialogTitle>
      <DialogContent>
        <LinkForm
          onCancel={closeHandler}
          objective={props.objective}
          initiative={initiative}
        />
      </DialogContent>
    </Dialog>
  );
};

export default LinkInitiativeModal;
