import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { TLinearProgressWithLabel } from "./types/TLinearProgressWithLabel";
import Tooltip from "../Tooltip/Tooltip";

export default function LinearProgressWithLabel(
  props: TLinearProgressWithLabel
) {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Tooltip
            when={!!props.metadata}
            title={
              <>
                {props.metadata?.doneCount} / {props.metadata?.allCount}
                {props.metadata?.extraMessage}
              </>
            }
          >
            <Typography
              sx={{ fontSize: props.fontSize }}
              variant="body2"
              color="text.secondary"
            >
              {`${Math.round(props.value)}%`}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
}
