import { useEffect } from "react";
import { TListener } from "../types/TListener";
import { TEvent } from "../types/TEvent";
import EventProvider from "../EventProvider";

const useSubscribe = (event: TEvent, listener: TListener, deps: any[] = []) => {
  useEffect(() => {
    EventProvider.addEventListener(event, listener);

    return () => {
      EventProvider.removeEventListener(event, listener);
    };
  }, deps);
};

export default useSubscribe;
