import ViewSkeleton from "../View/ViewSkeleton";
import View from "../View/View";
import useInitData from "../../hooks/useInitData";
import EmptyView from "../../../../../board/containers/EmptyView/EmptyView";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { TBoardViewProps } from "../../../../../board/types/TBoardViewProps";
import ReactDOM from "react-dom";
import ViewPicker, {
  getViewPickerPortalElement,
} from "../../../../containers/ViewPicker/ViewPicker";
import Filter from "../../../../containers/Filter/Filter/Filter";
import LinkModal from "../../../../../link/containers/Create/LinkModal";
import { BoardViewEnum } from "../../../../enums/BoardViewEnum";
import TaskViewModal from "../../../../../task/modules/view/containers/TaskViewModal";
import { useEffect } from "react";
import { holisticViewActions } from "../../slices/holisticViewSlice";
import { FirebaseEventEnum } from "../../../../../../common/modules/firebase/hooks/types/FirebaseEventEnum";
import { FeatureEnum } from "../../../../../../common/modules/accessControl/enums/FeatureEnum";
import { useFirebaseEventHelper } from "../../../../../../common/modules/firebase/hooks/useFirebaseEventHelper";

const HolisticView = (props: TBoardViewProps) => {
  const { logFirebaseEvent } = useFirebaseEventHelper();
  const havingBoardUsers = useAppSelector(
    (state) => state.holisticView.havingBoardUsers
  );
  const dispatch = useAppDispatch();
  const { isLoading, isFirstTimeLoading } = useInitData(props);
  const data = useAppSelector((state) => state.holisticView.data);
  const viewPickerPortalElement = getViewPickerPortalElement();

  useEffect(() => {
    logFirebaseEvent(FirebaseEventEnum.FEATURE_ACCESS, {
      feature: FeatureEnum.HOLISTIC_VIEW,
    });

    return () => {
      dispatch(holisticViewActions.resetData());
    };
  }, [props.boardRef]);

  return (
    <>
      {viewPickerPortalElement &&
        ReactDOM.createPortal(
          <ViewPicker
            board={props.board}
            filterElement={
              <Filter
                board={props.board}
                projectAbbr={props.projectAbbr}
                boardRef={props.boardRef}
                renderFinancialForecastControl
                renderDisplaySplits={false}
                renderGroupBySprint={false}
                boardView={BoardViewEnum.HOLISTIC_VIEW}
                autoFetchBoardUsers={false}
              />
            }
            havingBoardUsers={havingBoardUsers}
          />,
          viewPickerPortalElement
        )}

      {isFirstTimeLoading && <ViewSkeleton />}

      {!isLoading && !havingBoardUsers && <EmptyView {...props} />}

      {!isFirstTimeLoading && havingBoardUsers && (
        <View {...props} data={data!} />
      )}

      <TaskViewModal />
      <LinkModal />
    </>
  );
};

export default HolisticView;
