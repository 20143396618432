import { Button } from "../../../modules/materialUI";
import { Logout as LogoutIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React from "react";

type TProps = {
  clickHandler: () => void;
};

export default function SignOutButton({ clickHandler }: TProps) {
  return (
    <Grid item xs={12}>
      <Button
        variant="text"
        color="inherit"
        startIcon={<LogoutIcon />}
        onClick={clickHandler}
      >
        Sign Out
      </Button>
    </Grid>
  );
}
