import List from "../../common/List/List";
import { ManageAccounts, Shield } from "@mui/icons-material";
import { TSectionProps } from "../../types/TSectionProps";

const UserProfileSection = (props: TSectionProps) => {
  return (
    <List
      title="Account Settings"
      items={[
        {
          id: "UserProfileInfoListItem",
          name: "User Account",
          icon: <ManageAccounts />,
          path: `/user/account`,
          navigateToPath: true,
          onNavigate: props.onClick,
        },
        {
          id: "UserProfileResetPassword",
          name: "Change Password",
          icon: <Shield />,
          path: `/user/resetPassword`,
          navigateToPath: true,
          onNavigate: props.onClick,
        },
      ]}
    ></List>
  );
};

export default UserProfileSection;
