import { THttpClientError } from "../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAttachmentLinkService } from "../services/useAttachmentLinkService";
import useForm from "../../../common/hooks/useForm/useForm";
import { useAppDispatch } from "../../../common/hooks/redux";
import { IAttachment } from "../../../entities/IAttachment";
import { EntityTypeEnum } from "../../../entities/enums/EntityTypeEnum";

export default function useLinkCloudAttachment(
  entityType: EntityTypeEnum,
  entityId: number
) {
  const dispatch = useAppDispatch();
  const { getValidationErrorAtIndex } = useForm();
  const { dispatch: dispatchAttachmentCreateService } =
    useAttachmentLinkService({
      entityType,
      entityId,
    });

  return {
    dispatch: (
      file: File,
      hashName: string,
      withPreview: boolean = false,
      onFinally?: () => void,
      onSuccess?: (resource: IAttachment) => void,
      onError?: (error: string) => void
    ) => {
      dispatchAttachmentCreateService({
        body: {
          name: file.name,
          hashName: hashName,
          size: file.size,
          withPreview,
        },
      })
        .then((resource: IAttachment) => onSuccess?.(resource))
        .catch((error: THttpClientError) => {
          if (error.status === 422) {
            const validationFirstError = getValidationErrorAtIndex(0, error);
            if (validationFirstError) {
              dispatch(
                systemNotificationActions.open({
                  variant: "error",
                  message: validationFirstError,
                })
              );
              onError?.(validationFirstError);
            }
          } else {
            const message = "Failed to link the attachment. Please try again.";
            dispatch(
              systemNotificationActions.open({
                variant: "error",
                message: message,
              })
            );
            onError?.(message);
          }
        })
        .finally(() => onFinally?.());
    },
  };
}
