import classes from "./VisionPicker.module.css";
import { VisionEnum } from "./enums/VisionEnum";
import {
  AddRoad,
  CalendarViewDay,
  CalendarViewMonth,
  CalendarViewWeek,
  DateRange,
  PanoramaPhotosphere,
} from "@mui/icons-material";
import Picker from "../../../../../../../common/containers/Picker/Picker";
import useMedia from "../../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../../common/hooks/useMedia/enums/QueryEnum";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../../../../../../board/containers/Board/types/TBoardParams";
import useNavigateBoard from "../../../../../hooks/useNavigateBoard";
import { useTransition } from "react";
import { BoardViewEnum } from "../../../../../enums/BoardViewEnum";
import { TVisionPickerProps } from "./types/TVisionPickerProps";
import { useAppSelector } from "../../../../../../../common/hooks/redux";
import { comingSoon } from "../../../../../../../common/utils/comingSoon";
import { useTheme } from "@mui/material";

const VisionPicker = (props: TVisionPickerProps) => {
  const theme = useTheme();
  const customs = useAppSelector((state) => state.customs);
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);
  const [, startTransition] = useTransition();
  const { boardView, holisticViewVision } = useParams<TBoardParams>();
  const navigate = useNavigateBoard();

  const changeVisionHandler = (vision: VisionEnum, shouldAlert = true) => {
    startTransition(() => {
      navigate(boardView as BoardViewEnum, vision);
      shouldAlert && comingSoon.alertHandler();
    });
  };

  return (
    <div className={classes.filters}>
      <Picker
        secondary
        style={{
          backgroundColor: theme.palette.background.default,
          backgroundImage: "none",
        }}
        defaultValue={holisticViewVision ?? VisionEnum.DAILY}
        renderDropdown={matchesMax768Screen}
        renderDropdownText={!matchesMax576Screen}
        sections={[
          {
            icon: <CalendarViewDay />,
            text: "Daily",
            value: VisionEnum.DAILY,
            onClick: () => changeVisionHandler(VisionEnum.DAILY, false),
          },
          {
            icon: <CalendarViewWeek />,
            text: "Weekly",
            value: VisionEnum.WEEKLY,
            onClick: () => changeVisionHandler(VisionEnum.WEEKLY),
          },
          {
            icon: <CalendarViewMonth />,
            text: "Monthly",
            value: VisionEnum.MONTHLY,
            onClick: () => changeVisionHandler(VisionEnum.MONTHLY),
          },
          {
            icon: <DateRange />,
            text: "Quarterly",
            value: VisionEnum.QUARTERLY,
            onClick: () => changeVisionHandler(VisionEnum.QUARTERLY),
          },
          {
            render: props.hasSprint ?? false,
            icon: <AddRoad />,
            text: `${customs.sprintLabel}`,
            value: VisionEnum.SPRINT,
            onClick: () => changeVisionHandler(VisionEnum.SPRINT),
          },
          {
            icon: <PanoramaPhotosphere />,
            text: "Panoramic",
            value: VisionEnum.PANORAMIC,
            onClick: () => changeVisionHandler(VisionEnum.PANORAMIC),
          },
        ]}
      />
    </div>
  );
};

export default VisionPicker;
