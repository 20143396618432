import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TListViewState } from "./types/TListViewState";
import { IListViewDataResource } from "../../../interfaces/IListViewDataResource";
import { ISprintDataResource } from "../../../interfaces/ISprintDataResource";

const initialState: TListViewState = {
  list: {
    sprints: [],
    tasks: [],
    users: [],
    sprintData: [],
    havingBoardUsers: false,
    filterableCustomFields: [],
  },
  isSegmentView: false,
  showSkeleton: true,
};

const slice = createSlice({
  name: "listView",
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<IListViewDataResource>) => {
      state.list = action.payload;
      state.showSkeleton = false;
    },
    setSprintDataList: (
      state,
      action: PayloadAction<ISprintDataResource[]>
    ) => {
      state.list.sprintData = action.payload;
    },
    setIsSegmentView: (state, action: PayloadAction<boolean>) => {
      state.isSegmentView = action.payload;
    },
    reset: () => initialState,
    setHavingBoardUsers: (state, action: PayloadAction<boolean>) => {
      state.list.havingBoardUsers = action.payload;
    },
  },
});

export const listViewReducer = slice.reducer;

export const listViewActions = slice.actions;
