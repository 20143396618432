import { Typography } from "@mui/material";
import classes from "./WelcomeBacklogBoard.module.scss";

const WelcomeBacklogBoard = () => {
  return (
    <div className={`text-center ${classes.container}`}>
      <img
        src={`${process.env.CDN_URL}/icons/welcoming/empty-backlog-icon.png`}
        alt=""
      />
      <Typography variant="h5" sx={{ mb: 1 }}>
        Welcome to task management excellence!
      </Typography>
      <Typography variant="body2" sx={{ color: "text.secondary", mb: 2 }}>
        Start adding tasks to your Backlog Board and take the first step towards
        organized and efficient project management.
      </Typography>
    </div>
  );
};

export default WelcomeBacklogBoard;
