import { Dialog, DialogContent } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { initiativeActions } from "../../../../slices/initiativeSlice";
import ManageForm from "./ManageForm";
import { TManageModalProps } from "./types/TManageModalProps";
import LinkForm from "./LinkForm";
import DialogTitle from "@mui/material/DialogTitle";
import { KeyboardShortcutEnum } from "../../../../../../common/modules/keyboardShortcut/hooks/enums/KeyboardShortcutEnum";
import ShortcutProTip from "../../../../../../common/modules/keyboardShortcut/containers/ProTip/ShortcutProTip";

const CreateModal = (props: TManageModalProps) => {
  const createState = useAppSelector((state) => state.initiative.create);
  const dispatch = useAppDispatch();

  const closeHandler = () => dispatch(initiativeActions.closeCreateModal());

  const isCreate = createState.creationType === "createNew";

  return (
    <Dialog
      open={createState.showModal}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {isCreate ? "Add Initiative" : "Link Initiative Board"}
      </DialogTitle>
      <DialogContent>
        {isCreate ? (
          <>
            <ManageForm onCancel={closeHandler} objective={props.objective} />
            <ShortcutProTip
              shortcutKeyString={KeyboardShortcutEnum.i}
              description="add initiative"
            />
          </>
        ) : (
          <>
            <LinkForm onCancel={closeHandler} objective={props.objective} />
            <ShortcutProTip
              shortcutKeyString={KeyboardShortcutEnum.l}
              description="link initiative board"
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CreateModal;
