import HeaderSchema from "../../../../../common/containers/Layout/App/Header/HeaderRegistry/HeaderSchema";
import React from "react";
import { IconButton, ListItemIcon, Tooltip, Typography } from "@mui/material";
import { AppRegistration, MoreVert } from "@mui/icons-material";
import { TMenuItemProps } from "../../../../../common/containers/Menu/types/TMenuItemProps";
import Menu from "../../../../../common/containers/Menu/Menu";
import { NavLink } from "react-router-dom";
import { menuItem } from "../../../../../common/containers/Menu/utils/menuItem";
import { useAccessControl } from "../../../../../common/modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../../common/modules/accessControl/enums/AbilityEnum";

const HeaderControl = () => {
  const { can } = useAccessControl();

  if (!can(AbilityEnum.administrate)) {
    return null;
  }

  return (
    <HeaderSchema
      registerRight={
        <Menu
          button={(clickHandler) => (
            <Tooltip title="Project preferences">
              <IconButton
                onClick={clickHandler}
                sx={{ color: "primary.contrastText" }}
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
          )}
          menuItems={
            [
              menuItem.make(
                <NavLink to={`settings/project`}>
                  <ListItemIcon>
                    <AppRegistration />
                  </ListItemIcon>
                  <Typography sx={{ pl: 2 }}>Project Management</Typography>
                </NavLink>
              ),
            ] as TMenuItemProps[]
          }
        />
      }
    />
  );
};

export default HeaderControl;
