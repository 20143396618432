import { IStatus } from "../../entities/IStatus";
import { TStatus } from "../../modules/statusFlow/containers/Flow/Status/types/TStatus";

export const statusUtil = {
  convertStatusesToStatusList: (statuses: IStatus[]): TStatus[] =>
    statuses.map((status) => ({
      id: status.id,
      isNew: false,
      name: status.name,
      type: status.type,
    })),
};
