import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TObjectiveState } from "./types/TObjectiveState";
import { IObjective } from "../../../entities/IObjective";
import { IObjectiveCost } from "../../../entities/IObjectiveCost";

const initialState: TObjectiveState = {
  create: {
    showModal: false,
  },
  delete: {
    showConfirmation: false,
    deletableRecord: null,
  },
  view: {},
  list: [],
  stats: [],
};

const slice = createSlice({
  name: "objective",
  initialState,
  reducers: {
    openCreateModal: (state) => {
      state.create.showModal = true;
    },
    closeCreateModal: (state) => {
      state.create.showModal = false;
    },
    openDeleteConfirmation: (state, action: PayloadAction<IObjective>) => {
      state.delete.showConfirmation = true;
      state.delete.deletableRecord = action.payload;
    },
    closeDeleteConfirmation: (state) => {
      state.delete.showConfirmation = false;
      state.delete.deletableRecord = null;
    },
    setList: (state, action: PayloadAction<IObjective[]>) => {
      state.list = action.payload;
    },
    addToList: (state, action: PayloadAction<IObjective>) => {
      state.list = [...state.list, action.payload];
    },
    removeFromList: (state, action: PayloadAction<{ id: number }>) => {
      state.list = state.list.filter((item) => item.id !== action.payload.id);
    },
    openViewModal: (state, action: PayloadAction<{ objectiveRef: string }>) => {
      state.view.objectiveRef = action.payload.objectiveRef;
    },
    setObjective: (state, action: PayloadAction<IObjective>) => {
      state.view.objective = action.payload;
    },
    setObjectiveCostStats: (state, action: PayloadAction<IObjectiveCost[]>) => {
      state.stats = action.payload;
    },
    resetView: (state) => {
      state.view = initialState.view;
    },
  },
});

export const objectiveReducer = slice.reducer;

export const objectiveActions = slice.actions;
