import { useAppDispatch } from "../../../common/hooks/redux";
import { commentActions } from "../slices/commentSlice";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClient } from "../../../common/modules/httpClient/hooks/useHttpClient";
import { IComment } from "../../../entities/IComment";
import { EntityTypeEnum } from "../../../entities/enums/EntityTypeEnum";

export const useFetchCommentService = (props: {
  entityType: EntityTypeEnum;
  entityId: number;
}) => {
  const dispatch = useAppDispatch();

  return useHttpClient({
    url: `/comment/${props.entityType}/${props.entityId}`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IComment[]) =>
        dispatch(commentActions.setList(data)),
    },
  });
};
