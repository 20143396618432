import { Grid } from "@mui/material";
import React, { useState } from "react";
import VerifyUserMail from "./VerifyUserMail";
import { useVerifyUserPasswordResetCodeService } from "../../../services/useVerifyUserPasswordResetCodeService";
import UpdatePassword from "./UpdatePassword";
import PasswordResetSuccess from "./PaswordResetSuccess";
import { jwt } from "../../../../../common/utils/jwt";
import Title from "../../../../../common/components/Title/Title";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import VerifyMail from "../../../../org/containers/OrgRegistration/VerifyMail/VerifyMail";
import { useResendUserVerificationService } from "../../../services/useResendUserVerificationService";

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(1);
  const [inquiryUuid, setInquiryUuid] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const verifyService = useVerifyUserPasswordResetCodeService();
  const resendService = useResendUserVerificationService();
  const user = jwt.parse();

  const nextHandler = (uuid?: string, userEmail?: string) => {
    !inquiryUuid && setInquiryUuid(uuid);
    !email && setEmail(userEmail);
    if (step + 1 === 4 && user) {
      setStep(1);
      dispatch(
        systemNotificationActions.open({
          message: "Your password has been successfully changed",
          variant: "success",
        })
      );
    } else {
      setStep(step + 1);
    }
  };

  return (
    <Grid item>
      {user && <Title>Change Password</Title>}
      {step === 1 && <VerifyUserMail onNextHandler={nextHandler} />}
      {step === 2 && (
        <VerifyMail
          resendService={resendService}
          inquiryUuid={inquiryUuid!}
          verifyService={verifyService}
          onNextHandler={nextHandler}
        />
      )}
      {step === 3 && (
        <UpdatePassword
          onNextHandler={nextHandler}
          inquiryUuid={inquiryUuid!}
          email={email!}
        />
      )}
      {step === 4 && !user && <PasswordResetSuccess />}
    </Grid>
  );
};

export default ResetPassword;
