import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { sprintActions } from "../../slices/sprintSlice";
import UpdateForm from "./UpdateForm";
import { useParams } from "react-router-dom";

const UpdateModal = () => {
  const { showModal, editableRecord } = useAppSelector(
    (state) => state.sprint.update
  );
  const { projectAbbr } = useParams();
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);
  const dispatch = useAppDispatch();
  const closeHandler = () => dispatch(sprintActions.closeUpdateModal());

  return (
    <Dialog
      open={showModal && editableRecord !== null}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Edit {sprintLabel}</DialogTitle>
      <DialogContent>
        {editableRecord && (
          <UpdateForm
            sprintResource={editableRecord}
            onCancel={closeHandler}
            projectAbbr={projectAbbr!}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UpdateModal;
