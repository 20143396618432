import classes from "./Mobile.module.scss";
import NavigationIcon from "../common/NavigationIcon/NavigationIcon";
import { THeaderProps } from "../types/THeaderProps";
import UserMenu from "../../../../../../modules/user/containers/Menu/UserMenu";
import TaskSensorIcon from "../../../../../components/Logo/TaskSensorIcon";
import { NavLink } from "react-router-dom";
import FreeTrial from "../../../../../../modules/user/modules/freeTrial/containers/FreeTrial";
import HelpMenu from "../../../../../../modules/user/containers/Menu/HelpMenu";

const Mobile = (props: THeaderProps) => {
  return (
    <div className={classes.container}>
      <div className={classes["left-container"]}>
        <NavigationIcon />
        <NavLink to={"/"}>
          <TaskSensorIcon size={70} colorCombo={"white-green"} width={22} />
        </NavLink>
      </div>
      <div className={classes["right-container"]}>
        <div className={classes["header-registry"]}>{props.controls}</div>
        <div className={classes["reserved-container"]}>
          <FreeTrial />
          <HelpMenu />
          <UserMenu />
        </div>
      </div>
    </div>
  );
};

export default Mobile;
