import * as Highcharts from "highcharts";
import { SeriesOptionsType } from "highcharts";
import DarkUnica from "highcharts/themes/dark-unica";
import SandSignika from "highcharts/themes/sand-signika";
import HighchartsReact from "highcharts-react-official";
import React, { useRef } from "react";
import { useTheme } from "@mui/material";
import { AggStatusEnum } from "../../../../entities/columns/task/AggStatusEnum";
import { useFetchObjectiveCostStatsService } from "../../services/useFetchObjectiveCostStatsService";
import { TChartProps } from "./types/TChartProps";
import { useAppSelector } from "../../../../common/hooks/redux";
import DetailsLabel from "../../../task/modules/view/containers/TaskViewDetails/DetailsLabel";

const CostChart = ({ objective }: TChartProps) => {
  useFetchObjectiveCostStatsService({ objectiveRef: objective.ref });

  const normalizeChartData = function () {
    const data: { [key: string]: number[] } = {};
    let chartData: SeriesOptionsType[] = [];
    const statusOrderIndex = {
      [AggStatusEnum.TO_DO]: 0,
      [AggStatusEnum.IN_PROGRESS]: 1,
      [AggStatusEnum.DONE]: 2,
    };

    costStats.forEach((costData) => {
      if (!data[costData.currency]) {
        data[costData.currency] = [0, 0, 0];
      }

      data[costData.currency][statusOrderIndex[costData.status]] =
        costData.cost;
    });

    chartData = Object.keys(data).map((key) => ({
      type: "column",
      name: key,
      data: data[key],
    }));

    return chartData;
  };

  const costStats = useAppSelector((state) => state.objective.stats);

  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    xAxis: {
      categories: [
        AggStatusEnum.TO_DO,
        AggStatusEnum.IN_PROGRESS,
        AggStatusEnum.DONE,
      ],
    },
    series: normalizeChartData(),
  };

  if (useTheme().palette.mode === "dark") {
    DarkUnica(Highcharts);
  } else {
    SandSignika(Highcharts);
  }

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  return (
    <>
      {costStats.length && costStats.some((costData) => costData.cost > 0) ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
        />
      ) : (
        <DetailsLabel noWrap={false}>
          There is not enough data to display Cost Forecast
        </DetailsLabel>
      )}
    </>
  );
};

export default CostChart;
