import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { Dialog } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import AddAssigneesForm from "./AddAssigneesForm";
import { guesstimationItemViewActions } from "../../slices/viewSlice";

const AddAssigneesDialog = () => {
  const dispatch = useAppDispatch();
  const addAssigneesState = useAppSelector(
    (state) => state.guesstimationItemView.addAssigneesDialog
  );

  const closeHandler = () => {
    dispatch(guesstimationItemViewActions.closeAddAssigneesDialog());
  };

  if (!addAssigneesState?.open) {
    return null;
  }

  return (
    <Dialog open={true} onClose={closeHandler} fullWidth maxWidth="sm">
      <DialogTitle>Add Assignees</DialogTitle>
      <DialogContent>
        <AddAssigneesForm
          guesstimationItem={addAssigneesState.item}
          onCancel={closeHandler}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddAssigneesDialog;
