import { TFirebaseEventParams } from "./types/TFirebaseEventParams";
import { analytics } from "../components/FirebaseConfig";
import { logEvent } from "firebase/analytics";
import { useAppSelector } from "../../../hooks/redux";

export function useFirebaseEventHelper() {
  const org = useAppSelector((state) => state.org.init.org);
  const userJwt = useAppSelector((state) => state.user.userInfo);

  return {
    logFirebaseEvent: (
      eventName: string,
      eventParams?: TFirebaseEventParams
    ) => {
      if (!analytics) {
        return;
      }

      logEvent(analytics, eventName, {
        ...eventParams,
        organization_id: org?.id,
        organization_name: org?.name,
        user_id: userJwt?.id,
        user_name: userJwt?.name,
      });
    },
  };
}
