import { taskTypeUtil } from "../../../../modules/taskType/utils/taskTypeUtil";
import { iconLibraryUtil } from "../../../../modules/iconLibrary/utils/iconLibraryUtil";
import Dropdown from "../../materialUI/components/Dropdown/Dropdown";
import React from "react";
import { useAppSelector } from "../../../hooks/redux";
import { TListResource } from "../../../types/TListResource";
import { useOrgProjectData } from "../../../../modules/org/hooks/useOrgProjectData";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../../../../modules/board/containers/Board/types/TBoardParams";

const useTaskType = () => {
  const orgInit = useAppSelector((state) => state.org.init);
  const {
    taskTypes,
    list: projectWithBoardResource,
    isLoading: isOrgInitLoading,
  } = orgInit;
  const projectData = useOrgProjectData();
  const { projectAbbr } = useParams<TBoardParams>();

  return {
    taskTypeAutocompleteData: (boardRef: string) => {
      const projectId =
        (projectWithBoardResource.find(
          (resourceItem) =>
            !!resourceItem.boards.find((board) => board.ref === boardRef)
        )?.project.id as number) ?? 0;

      return {
        taskTypes,
        taskTypeOptions: taskTypeUtil.getDropdownOptions(taskTypes, projectId),
        isLoading: isOrgInitLoading,
      };
    },
    dropdownFilter: (
      values: TListResource<number>,
      changeHandler: (values: TListResource<number>) => void
    ) => {
      const projectId = projectData.findProjectByAbbr(projectAbbr!)?.project
        .id!;
      const taskTypeActive = taskTypes.filter((taskType) => {
        if (taskType.projectIds && taskType.projectIds?.length > 0) {
          return taskType.isActive && taskType.projectIds.includes(projectId);
        }
        return taskType.isActive;
      });

      if (!taskTypeActive || taskTypeActive.length === 0) {
        return null;
      }
      const options = taskTypeUtil.getDropdownOptions(
        taskTypeActive,
        undefined,
        "Task Type"
      );
      return (
        <Dropdown
          onChange={(e) => {
            e.preventDefault();
            const value = e.target.value as string;
            const label = options.find((item) => item.id == value)!.label!;
            const changedValues = [...values];
            const existingIndex = changedValues.findIndex(
              (changedValue) => changedValue.id === e.target.value
            );

            if (existingIndex !== -1) {
              changedValues.splice(existingIndex, 1);
            } else {
              changedValues.push({
                id: e.target.value,
                label,
              });
            }

            changeHandler(changedValues);
          }}
          name="taskTypeId"
          sx={{ fontSize: "0.9rem" }}
          withoutBorder
          options={options}
          hideEmpty={true}
          renderOption={(option) =>
            iconLibraryUtil.getDropdownRenderOption(
              option,
              taskTypeUtil.getSelectedTaskType(taskTypeActive, option)
                ?.iconFilePath,
              !!values.find((taskType) => taskType.id === option.id)
            )
          }
        />
      );
    },
  };
};

export default useTaskType;
