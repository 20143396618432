import Title from "../../../common/components/Title/Title";
import { Box } from "@mui/material";
import { Button } from "../../../common/modules/materialUI";
import { Add } from "@mui/icons-material";
import { useAppDispatch } from "../../../common/hooks/redux";
import { linkListActions } from "../slices/linkListSlice";
import CreateModal from "./CreateLinkList/CreateModal";
import LinkListGrid from "./LinkListGrid/LinkListGrid";
import { useFetchProjectListService } from "../../project/services/useFetchProjectListService";

const LinkListPage = () => {
  const dispatch = useAppDispatch();
  useFetchProjectListService();

  return (
    <>
      <Title>Task Links</Title>
      <Box mb={3}>
        <Button
          variant="text"
          startIcon={<Add />}
          onClick={() => dispatch(linkListActions.openCreateModal())}
        >
          Add New Link
        </Button>
        <CreateModal />
      </Box>
      <LinkListGrid />
    </>
  );
};

export default LinkListPage;
