import { TTaskProps } from "./types/TTaskProps";
import classes from "./TaskHorizontalSmall.module.scss";
import TaskHorizontal from "./TaskHorizontal";
import { StateHelper } from "../State/helpers/StateHelper";
import { TaskHelper } from "../../helpers/TaskHelper";
import { Grid, useTheme } from "@mui/material";
import React, { ForwardedRef, forwardRef } from "react";
import { NavLink } from "react-router-dom";
import { taskUtil } from "../../../../utils/taskUtil";
import TaskMenu from "../Menu/TaskMenu";
import useGetTaskType from "../../../../../modules/taskType/hooks/useGetTaskType";
import { Tooltip } from "../../../../modules/materialUI";
import useGetTaskPriority from "../../../../../modules/taskPriority/hooks/useGetTaskPriority";
import { link } from "../../../../utils/link";
import { currencyUtil } from "../../../../utils/currencyUtil";
import { number } from "../../../../utils/number";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";
import { spikeStyleConfig } from "./spikeStyleConfig";

const TaskHorizontalSmall = forwardRef(
  (props: TTaskProps, ref: ForwardedRef<HTMLDivElement>) => {
    const theme = useTheme();
    const { task } = props;
    const sizeXs = TaskHelper.sizeXs(props.size!);
    const pushAsideClass =
      StateHelper.willDisplayState(task.statusType, task.riskStatus) && !sizeXs
        ? classes["push-aside"]
        : "";
    const taskType = useGetTaskType({
      taskTypeId: task.taskTypeId,
    });
    const taskPriority = useGetTaskPriority({
      taskPriorityId: task.taskPriorityId,
    });

    const isSpike = task.internalType === InternalTypeEnum.SPIKE;
    const spikeStyles = spikeStyleConfig(theme);

    return (
      <TaskHorizontal
        {...props}
        ref={ref}
        className={`${props.className} ${classes.container}`}
        contentClassName={classes.content}
        style={{
          ...{
            backgroundColor: theme.palette.mutedCardBackground.default,
            borderLeft: taskUtil.isSplitLeft(props.splitDirection)
              ? theme.palette.cardSplitBorder.main
              : undefined,
            borderRight: taskUtil.isSplitRight(props.splitDirection)
              ? theme.palette.cardSplitBorder.main
              : undefined,
          },
          ...props.style,
          ...(isSpike
            ? {
                ...spikeStyles.containerStyle,
                ...spikeStyles.smallContainerStyle,
              }
            : {}),
        }}
        contentStyle={{
          backgroundColor: theme.palette.mutedCardBackground.paper,
          color: theme.palette.mutedCardBackground.main,
          ...(isSpike
            ? { ...spikeStyles.contentStyle, ...spikeStyles.smallContentStyle }
            : {}),
        }}
      >
        {isSpike && !sizeXs && (
          <Tooltip title={InternalTypeEnum.SPIKE}>
            <img
              src={theme.palette.spike.xsImg}
              alt=""
              style={{
                width: "11px",
                height: "11px",
                position: "absolute",
                top: "5px",
                left: "5px",
              }}
            />
          </Tooltip>
        )}
        <NavLink
          to={taskUtil.url(task)}
          className={`${classes.ref} ${pushAsideClass}`}
          onClick={link.anchorClickHandler}
          style={{ color: "inherit" }}
        >
          {task.ref}
          {task.hasSplits ? ` #${task.number}` : ""}
        </NavLink>
        <div className={classes.metadata}>
          <TaskMenu
            taskRef={task.ref!}
            taskId={task.taskId}
            taskInternalType={task.internalType}
            projectRef={task.projectRef}
            hidden
            sx={isSpike ? spikeStyles.moreOptionsStyle : {}}
            indentSubheader={false}
            listSubheader={
              taskType ||
              taskPriority ||
              (props.displayCostPerItem && !!task.cost) ||
              (isSpike && sizeXs) ? (
                <Grid container alignItems="center" my={0.5} minWidth={140}>
                  <Grid item xs={6}>
                    {props.displayCostPerItem &&
                      !!task.cost &&
                      `${currencyUtil.getSymbol(
                        task.cost.costCurrency
                      )}${number.formatAsPrice(task.cost.cost)}`}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    justifyContent="flex-end"
                    spacing={0.5}
                    my={0.5}
                  >
                    {taskPriority && (
                      <Grid item>
                        <Tooltip title={taskPriority.name}>
                          <img
                            src={`${process.env.CDN_URL}/${taskPriority.iconFilePath}`}
                            alt={taskPriority.name}
                            width={16}
                            height={16}
                            style={{ display: "flex" }}
                          />
                        </Tooltip>
                      </Grid>
                    )}

                    {taskType && (
                      <Grid item>
                        <Tooltip title={taskType.name}>
                          <img
                            src={`${process.env.CDN_URL}/${taskType.iconFilePath}`}
                            alt={taskType.name}
                            width={16}
                            height={16}
                            style={{ display: "flex" }}
                          />
                        </Tooltip>
                      </Grid>
                    )}

                    {isSpike && sizeXs && (
                      <Grid item>
                        <Tooltip title={InternalTypeEnum.SPIKE}>
                          <img
                            src={theme.palette.spike.xsImg}
                            alt=""
                            style={{
                              width: "13px",
                              height: "13px",
                              position: "relative",
                              top: "-3px",
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ) : undefined
            }
          />
        </div>
      </TaskHorizontal>
    );
  }
);
export default TaskHorizontalSmall;
