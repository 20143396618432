import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IGetFinancialDataResource } from "../interfaces/IGetFinancialDataResource";
import { useHttpClient } from "../../../common/modules/httpClient/hooks/useHttpClient";

export const useFetchFinancialDataService = (userId: number) => {
  const response = useHttpClient({
    url: `/user/${userId}/financialData`,
    method: RequestMethodEnum.GET,
  });

  return {
    ...response,
    data: response.data as IGetFinancialDataResource,
  };
};
