import { Dialog, DialogContent } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { initiativeActions } from "../../../../slices/initiativeSlice";
import ManageForm from "./ManageForm";
import DialogTitle from "@mui/material/DialogTitle";
import { TUpdateModalProps } from "./types/TUpdateModalProps";

const UpdateModal = (props: TUpdateModalProps) => {
  const updateState = useAppSelector((state) => state.initiative.update);
  const dispatch = useAppDispatch();

  const closeHandler = () => dispatch(initiativeActions.closeUpdateModal());

  const isCreate = false;

  return (
    <Dialog
      open={updateState.showModal}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Update Initiative</DialogTitle>
      <DialogContent>
        <ManageForm
          onCancel={closeHandler}
          objective={props.objective}
          initiative={updateState.initiative}
        />
      </DialogContent>
    </Dialog>
  );
};

export default UpdateModal;
