import { GridColDef } from "@mui/x-data-grid";
import { IObjective } from "../../../../entities/IObjective";
import { IconButton, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { Delete } from "@mui/icons-material";
import { TColumnsProps } from "./types/TColumnsProps";
import LinearProgressWithLabel from "../../../../common/modules/materialUI/components/LinearProgress/LinearProgressWithLabel";

export const Columns: (props: TColumnsProps) => GridColDef<IObjective>[] = ({
  onDeleteButtonClick,
}) => {
  return [
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.canBeDeleted && (
              <Tooltip title="Delete Objective">
                <IconButton
                  onClick={(e) => onDeleteButtonClick(e, params.row)}
                  color="error"
                >
                  <Delete color="error" fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: "ref",
      headerName: "Ref",
      width: 150,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 170,
      renderCell: (params) => {
        return (
          <Typography variant="body2">
            {params.row.startDate
              ? moment(params.row.startDate).format("MM/DD/YYYY")
              : "not set"}
          </Typography>
        );
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 170,
      renderCell: (params) => {
        return (
          <Typography variant="body2">
            {params.row.endDate
              ? moment(params.row.endDate).format("MM/DD/YYYY")
              : "not set"}
          </Typography>
        );
      },
    },
    {
      field: "count",
      headerName: "Initiatives",
      width: 100,
      renderCell: (params) => {
        return (
          <Typography variant="body2">
            {params.row.data?.length ?? 0}
          </Typography>
        );
      },
    },
    {
      field: "progress",
      headerName: "Progress",
      width: 200,
      renderCell: (params) => {
        return <LinearProgressWithLabel value={params.row.progress} />;
      },
    },
  ];
};
