import type { Identifier, XYCoord } from "dnd-core";
import { TStatusProps } from "./types/TStatusProps";
import { useDrag, useDrop } from "react-dnd";
import classes from "./Status.module.css";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { statusFlowActions } from "../../../slices/statusFlowSlice";
import { useRef, useState } from "react";
import useStatusHelper from "../../../hooks/useStatusHelper";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTryDeleteStatusService } from "../../../services/useTryDeleteStatusService";
import { ITryDeleteResource } from "../../../interfaces/ITryDeleteResource";
import { orgActions } from "../../../../org/slices/orgSlice";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { ConfirmationDialog } from "../../../../../common/modules/materialUI";

export default function Status({ status, index, allowDelete }: TStatusProps) {
  const { getStatusTypeColor, theme } = useStatusHelper();
  const { isLoading: isTryingToDelete, dispatch: dispatchTryDeleteStatus } =
    useTryDeleteStatusService({
      statusId: !status.isNew ? +status.id : 0,
    });
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);

  const accept = "FLOW_STATUS";
  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
    TStatusProps,
    void,
    { handlerId: Identifier | null }
  >({
    accept,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: TStatusProps, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      dispatch(
        statusFlowActions.moveStatus({
          currentIndex: dragIndex,
          newIndex: hoverIndex,
        })
      );

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: accept,
    item: () => {
      return { status, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const deleteHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (status.isNew) {
      dispatch(statusFlowActions.removeStatus({ status }));
      return;
    }

    setShowDeleteConfirmation(true);
  };

  return (
    <>
      <div
        ref={ref}
        className={classes.container}
        style={{
          opacity,
          color: theme.palette.statusTypeSchema.textColor,
          position: "relative",
          backgroundColor: getStatusTypeColor(status.type),
        }}
        data-handler-id={handlerId}
        onClick={() => dispatch(statusFlowActions.openDrawer({ status }))}
      >
        {allowDelete && (
          <IconButton
            sx={{
              position: "absolute",
              padding: "4px",
              right: 0,
              top: 0,
              zIndex: 999,
            }}
            onClick={deleteHandler}
          >
            <Close
              sx={{
                fontSize: "0.7rem",
                color: theme.palette.statusTypeSchema.textColor,
              }}
            />
          </IconButton>
        )}

        {status.name}
      </div>

      {showDeleteConfirmation && (
        <ConfirmationDialog
          open
          title={`Deleting status`}
          content={`Are you sure you want to delete status "${status.name}"?`}
          loading={isTryingToDelete}
          closeHandler={() => setShowDeleteConfirmation(false)}
          successHandler={() => {
            dispatchTryDeleteStatus().then(
              (tryDeleteResource: ITryDeleteResource) => {
                if (tryDeleteResource.isDeleted) {
                  dispatch(statusFlowActions.removeStatus({ status }));
                  dispatch(orgActions.deleteStatus({ statusId: +status.id }));

                  dispatch(
                    systemNotificationActions.open({
                      message: `Status ${status.name} is deleted successfully`,
                      variant: "success",
                    })
                  );
                } else if (tryDeleteResource.hasUsages) {
                  dispatch(
                    statusFlowActions.openMoveDataFromStatusDialog({ status })
                  );
                }

                setShowDeleteConfirmation(false);
              }
            );
          }}
        />
      )}
    </>
  );
}
