import { Button } from "../../../../../common/modules/materialUI";
import { Box, ButtonProps, useTheme } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { statusFlowActions } from "../../../slices/statusFlowSlice";
import { AggStatusEnum } from "../../../../../entities/columns/task/AggStatusEnum";

export default function Add() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const commonProps: ButtonProps = {
    variant: "text",
    size: "small",
    sx: {
      textTransform: "capitalize",
      borderRadius: "8px",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  };
  const commonStyles = {
    backgroundColor: theme.palette.statusTypeSchema.buttonBackground,
    color: theme.palette.statusTypeSchema.textColor,
    borderBottom: `2px solid`,
  };

  const addHandler = (type: AggStatusEnum) =>
    dispatch(statusFlowActions.addStatus(type));

  return (
    <Box display="flex" gap={1} alignItems="center" sx={{ mb: 5 }}>
      <Button
        startIcon={<AddIcon />}
        onClick={() => addHandler(AggStatusEnum.TO_DO)}
        {...commonProps}
        style={{
          ...commonStyles,
          borderBottomColor: theme.palette.statusTypeSchema.toDo.color,
        }}
      >
        {AggStatusEnum.TO_DO.toLowerCase()}
      </Button>
      <Button
        startIcon={<AddIcon />}
        onClick={() => addHandler(AggStatusEnum.IN_PROGRESS)}
        {...commonProps}
        style={{
          ...commonStyles,
          borderBottomColor: theme.palette.statusTypeSchema.inProgress.color,
        }}
      >
        {AggStatusEnum.IN_PROGRESS.toLowerCase()}
      </Button>
      <Button
        startIcon={<AddIcon />}
        onClick={() => addHandler(AggStatusEnum.DONE)}
        {...commonProps}
        style={{
          ...commonStyles,
          borderBottomColor: theme.palette.statusTypeSchema.done.color,
        }}
      >
        {AggStatusEnum.DONE.toLowerCase()}
      </Button>
    </Box>
  );
}
