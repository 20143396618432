import { Box, Switch, Typography, useTheme } from "@mui/material";
import ExpandableIcon from "../../../../common/components/Icon/Expandable/ExpandableIcon";
import React from "react";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { holisticViewActions } from "../../modules/holisticView/slices/holisticViewSlice";

const Section = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  return (
    <>
      <Box display="flex" gap={0.5} alignItems="center" sx={{ mb: 2.25 }}>
        <Typography variant="overline" color={theme.palette.text.primary}>
          For <b>MVP Sprint</b>
        </Typography>
        <ExpandableIcon direction="down" />
      </Box>
      <Box>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          Available Capacity <b>480h</b>
        </Typography>
      </Box>
      <Box display="flex" gap={0.5} alignItems="center">
        <Typography variant="body2" color={theme.palette.text.secondary}>
          Display Remaining Capacity
        </Typography>
        <Switch
          color="success"
          onChange={(event, checked) =>
            dispatch(holisticViewActions.displayUserLaneOverlay(checked))
          }
        />
      </Box>
      <Box sx={{ mt: 3.5, mb: 1 }}>
        <Typography variant="overline" color={theme.palette.text.primary}>
          Velocity Based Statistics
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption" color={theme.palette.text.secondary}>
          65% Product initiatives
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption" color={theme.palette.text.secondary}>
          15% Technical debt initiatives
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption" color={theme.palette.text.secondary}>
          15% Meetings
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption" color={theme.palette.text.secondary}>
          5% Diverse initiatives
        </Typography>
      </Box>
    </>
  );
};

export default Section;
