import TListProps from "./types/TListProps";
import classes from "./List.module.css";
import Loading from "../../Icon/Loading/Loading";

const List = (props: TListProps) => {
  return (
    <>
      {props.title && (
        <div className={classes.title}>
          <div>
            {props.title}
            {props.loading && <Loading className={classes["loading-icon"]} />}
            {!props.loading && props.action1Element && props.action1Element}
          </div>
        </div>
      )}
      {props.children && <ul className={classes.ul}>{props.children}</ul>}
    </>
  );
};

export default List;
