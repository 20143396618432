import useGeneralIOHandler from "../../hooks/useIOHandler";

/**
 * Use input / output handler
 * Helps with purifying html input and preparing/fetching html output
 */
export default function useIOHandler() {
  const generalIOHandlers = useGeneralIOHandler();

  return {
    ...generalIOHandlers,
    fetchEditorState: (editorRef: any) => ({
      html: editorRef.current.getContent(),
    }),
  };
}
