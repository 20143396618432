import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TInitiativeState } from "./types/TInitiativeState";
import { IData } from "../../../entities/columns/objective/IData";

const initialState: TInitiativeState = {
  list: [],
  create: {
    showModal: false,
  },
  update: {
    showModal: false,
    showLinkModal: false,
    showNotesModal: false,
    initiative: undefined,
  },
};

const slice = createSlice({
  name: "initiative",
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<IData[]>) => {
      state.list = action.payload;
    },
    openCreateModal: (
      state,
      action: PayloadAction<TInitiativeState["create"]["creationType"]>
    ) => {
      state.create.showModal = true;
      state.create.creationType = action.payload;
    },
    closeCreateModal: (state) => {
      state.create.showModal = false;
    },
    openUpdateModal: (
      state,
      action: PayloadAction<TInitiativeState["update"]["initiative"]>
    ) => {
      state.update.showModal = true;
      state.update.initiative = action.payload;
    },
    closeUpdateModal: (state) => {
      state.update.showModal = false;
    },
    openNotesModal: (
      state,
      action: PayloadAction<TInitiativeState["update"]["initiative"]>
    ) => {
      state.update.showNotesModal = true;
      state.update.initiative = action.payload;
    },
    closeNotesModal: (state) => {
      state.update.showNotesModal = false;
    },
    openLinkModal: (
      state,
      action: PayloadAction<TInitiativeState["update"]["initiative"]>
    ) => {
      state.update.showLinkModal = true;
      state.update.initiative = action.payload;
    },
    closeLinkModal: (state) => {
      state.update.showLinkModal = false;
    },
  },
});

export const initiativeReducer = slice.reducer;

export const initiativeActions = slice.actions;
