import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { ConfirmationDialog } from "../../../../../../common/modules/materialUI";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { useDeleteGuesstimationItemService } from "../../services/useDeleteGuesstimationItemService";
import { guesstimationItemViewActions } from "../../slices/viewSlice";
import { TGuesstimationItemDeletedEvent } from "../types/TGuesstimationItemDeletedEvent";

const DeleteGuesstimationItemConfirmation = () => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { getValidationErrorAtIndex } = useForm();
  const deleteItemState = useAppSelector(
    (state) => state.guesstimationItemView.deleteItem
  );
  const { dispatch: dispatchDeleteGuesstimationItemService, isLoading } =
    useDeleteGuesstimationItemService({
      itemId: deleteItemState?.item.id ?? 0,
    });
  const closeConfirmationHandler = () =>
    dispatch(guesstimationItemViewActions.closeDeleteItemConfirmation());
  const cleanupGuesstimationItemStateData = () => {
    dispatch(guesstimationItemViewActions.reset());
  };
  const updateBoard = () => {
    // Updating boards to reflect the changes
    dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_DELETED, {
      guesstimationItem: deleteItemState!.item,
    } as TGuesstimationItemDeletedEvent);
  };

  const successHandler = () => {
    dispatchDeleteGuesstimationItemService()
      .then(() => {
        dispatch(
          systemNotificationActions.open({
            variant: "success",
            message: "Your Guesstimation Item has been deleted successfully",
          })
        );

        updateBoard();
        cleanupGuesstimationItemStateData();
        dispatch(
          guesstimationItemViewActions.addGuesstimationItemDeletedRef({
            guesstimationItemRef: deleteItemState!.item.ref,
          })
        );
      })
      .catch((error: THttpClientError) => {
        if (error.status === 422) {
          const validationFirstError = getValidationErrorAtIndex(0, error);
          if (validationFirstError) {
            dispatch(
              systemNotificationActions.open({
                variant: "error",
                message: validationFirstError,
              })
            );
          }
        } else if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        } else {
          dispatch(
            systemNotificationActions.open({
              variant: "error",
              message: "Failed to delete Guesstimation Item. Please try again.",
            })
          );
        }
      })
      .finally(() => {
        closeConfirmationHandler();
      });
  };

  if (!deleteItemState?.openConfirmation) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={true}
      title={`Delete Guesstimation Item`}
      content="The Guesstimation Item will be dropped, are you sure you want to delete it?"
      loading={isLoading}
      closeHandler={closeConfirmationHandler}
      successHandler={successHandler}
    />
  );
};

export default DeleteGuesstimationItemConfirmation;
