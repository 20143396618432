import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { DialogContent } from "@mui/material";
import { taskViewActions } from "../slices/viewSlice";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import { useGetTaskService } from "../services/useGetTaskService";
import { useEffect } from "react";
import { ITaskDetailsResource } from "../interfaces/ITaskDetailsResource";
import TaskSkeleton from "./TaskSkeleton";
import { taskActions } from "../../../slices/taskSlice";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import TaskContent from "./TaskContent";
import Dialog from "../../../../../common/modules/taskView/containers/Dialog/Dialog";

const TaskViewModal = () => {
  const dispatch = useAppDispatch();
  const { taskRef, taskSegmentNumber, viewType } = useAppSelector(
    (state) => state.taskView
  );
  const taskResource = useAppSelector((state) => state.task.resource);
  const { dispatch: dispatchGetTask, isLoading: isGetTaskServiceLoading } =
    useGetTaskService();
  const isMobileView = useMedia(QueryEnum.MAX_WIDTH_1200);

  const closeDialogHandler = () => {
    dispatch(taskActions.clearData());
    dispatch(taskViewActions.reset());
  };

  useEffect(() => {
    taskRef &&
      dispatchGetTask({
        urlPath: `/${taskRef}${
          taskSegmentNumber ? `/${taskSegmentNumber}` : ""
        }`,
      })
        .then((data: ITaskDetailsResource) => {
          dispatch(
            taskViewActions.setViewType(
              data.task.isSegmentData ? "segment" : "group"
            )
          );
        })
        .catch(() => {
          dispatch(
            systemNotificationActions.open({
              message: "Failed to fetch task details. Please try again.",
              variant: "error",
            })
          );
          closeDialogHandler();
        });
  }, [taskRef]);

  return (
    <Dialog
      open={!!taskRef}
      isMobileView={isMobileView}
      closeDialogHandler={closeDialogHandler}
    >
      <DialogContent>
        {!viewType && isGetTaskServiceLoading && (
          <TaskSkeleton isMobileView={isMobileView} />
        )}
        {viewType && (
          <TaskContent
            task={taskResource!.task}
            taskResource={taskResource!}
            contentHeight="100%"
            isMobileView={isMobileView}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TaskViewModal;
