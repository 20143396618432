import * as React from "react";
import { CSSProperties, useCallback } from "react";
import { DragPreviewImage } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useTheme } from "@mui/material";
import LaneItemHorizontalLarge from "./LaneItemHorizontalLarge";
import { useDragAndDropHelper } from "../../hooks/useDragAndDropHelper";
import { useResizeHelper } from "../../hooks/useResizeHelper";
import { TLaneItemProps } from "./types/TLaneItemProps";

export const DRAG_TYPE_GUESSTIMATION_ITEM_HORIZONTAL =
  "GuesstimationItemHorizontal";

function getStyles(shouldDisplay: boolean): CSSProperties {
  return {
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: shouldDisplay ? 1 : 0,
    height: shouldDisplay ? undefined : 0,
  };
}

const LaneItem = (props: TLaneItemProps) => {
  const theme = useTheme();

  const {
    drag,
    dragPreview,
    isDragging,
    dragStartHandler,
    showShiftingProgress,
  } = useDragAndDropHelper(props);

  const {
    days,
    xPositive,
    widthDiff,
    remainingPixelsToNextCheckpoint,
    metrics: resizeMetrics,
    resizeStartHandler,
    resizeHandler,
    resizeCompleteHandler,
    resizeInterruptHandler,
  } = useResizeHelper({ guesstimationItem: props.guesstimationItem });

  const resizerMetricsWidth = 41;
  const calculateResizeTargetMetricsPosition = useCallback(
    () =>
      props.positionX +
      props.size +
      widthDiff -
      resizerMetricsWidth +
      (xPositive
        ? remainingPixelsToNextCheckpoint
        : -remainingPixelsToNextCheckpoint),
    [days]
  );

  return (
    <>
      <DragPreviewImage connect={dragPreview} src={getEmptyImage().src} />
      <LaneItemHorizontalLarge
        {...props}
        className="no-select"
        style={{
          ...props.style,
          ...getStyles(!isDragging && !showShiftingProgress),
        }}
        ref={drag}
        onDragStart={dragStartHandler}
        resizable
        resizerConfig={{
          metrics: resizeMetrics,
          onResizeStart: resizeStartHandler,
          onResize: resizeHandler,
          onResizeComplete: resizeCompleteHandler,
          onResizeInterrupt: resizeInterruptHandler,
        }}
        users={props.users}
      />
      {resizeMetrics !== undefined && (
        <div
          style={{
            zIndex: 25,
            width: `${resizerMetricsWidth}px`,
            height: "90%",
            borderRadius: "29px",
            borderRight: `1px solid ${theme.palette.brightCardBackground.main}`,
            position: "absolute",
            left: `${calculateResizeTargetMetricsPosition()}px`,
          }}
        />
      )}
    </>
  );
};

export default LaneItem;
