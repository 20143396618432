import { Typography, useTheme } from "@mui/material";
import { TChildren } from "../../../../../../common/types/TChildren";

export default function DetailsLabel(
  props: { displayRequired?: boolean; noWrap?: boolean } & TChildren,
) {
  const theme = useTheme();

  return (
    <Typography
      noWrap={props.noWrap ?? true}
      color={theme.palette.taskView.labelColor}
      fontSize="15px"
    >
      {props.children}
      {props.displayRequired ? " *" : ""}
    </Typography>
  );
}
