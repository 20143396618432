import { ITaskType } from "../../../entities/ITaskType";
import { TListResource } from "../../../common/types/TListResource";
import { TListItem } from "../../../common/types/TListItem";

export const taskTypeUtil = {
  getDropdownOptions: (
    taskTypes: ITaskType[],
    projectId?: number,
    emptyOptionText?: string,
    selectedTaskType?: ITaskType
  ) => {
    const listResourceHandler = (taskTypes: ITaskType[]) => {
      return taskTypes.map((taskType) => ({
        id: taskType.id,
        label: taskType.name,
      }));
    };

    const options: TListResource<string | number> = projectId
      ? listResourceHandler(
          taskTypes.filter(
            (taskType) =>
              taskType.isActive &&
              (!taskType.projectIds || taskType.projectIds.includes(projectId))
          )
        )
      : listResourceHandler(taskTypes);

    // If no selected option in the list - add it to it at the beginning
    if (
      selectedTaskType &&
      !options.find((option) => option.id === selectedTaskType.id)
    ) {
      options.unshift({
        id: selectedTaskType.id,
        label: selectedTaskType.name,
      });
    }

    // Adding empty option at the beginning
    emptyOptionText && options.unshift({ id: "", label: emptyOptionText });

    return options;
  },
  getSelectedTaskType: (
    taskTypes: ITaskType[],
    option?: TListItem<string | number>
  ) => {
    if (!option) {
      return undefined;
    }

    return taskTypes.find((taskType) => taskType.id === option.id);
  },
};
