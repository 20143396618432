import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../config/authConfig";
import { MsalProvider } from "@azure/msal-react";
import MSSignUpForm from "./Form/MSSignUpForm";

const MSSignUpLayout = (props: any) => {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <MSSignUpForm {...props} />
    </MsalProvider>
  );
};

export default MSSignUpLayout;
