import ObjectiveViewMobile from "./ObjectiveViewMobile";
import ObjectiveViewDesktop from "./ObjectiveViewDesktop";
import { TObjectiveContentProps } from "./types/TObjectiveContentProps";
import CreateModal from "./Initiatives/ManageInitiative/CreateModal";
import UpdateModal from "./Initiatives/ManageInitiative/UpdateModal";
import LinkInitiativeModal from "./Initiatives/ManageInitiative/LinkInitiativeModal";
import NotesModal from "./Initiatives/ManageInitiative/NotesModal";
import { useAppSelector } from "../../../../common/hooks/redux";

const ObjectiveContent = (props: TObjectiveContentProps) => {
  const showNotesModal = useAppSelector(
    (state) => state.initiative.update.showNotesModal
  );
  const initiative = useAppSelector(
    (state) => state.initiative.update.initiative
  );

  return (
    <>
      {props.isMobileView ? (
        <ObjectiveViewMobile {...props} />
      ) : (
        <ObjectiveViewDesktop {...props} />
      )}
      <CreateModal objective={props.objective} />
      <UpdateModal objective={props.objective} />
      {initiative && (
        <NotesModal
          objective={props.objective}
          initiatives={[initiative]}
          boardId={initiative.boardId}
          isBoardView={false}
          show={showNotesModal}
        />
      )}
      <LinkInitiativeModal objective={props.objective} />
    </>
  );
};

export default ObjectiveContent;
