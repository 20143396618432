import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { backlogItemViewActions } from "../slices/viewSlice";
import { useEffect } from "react";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useGetBacklogItemDetailsService } from "../services/useGetBacklogItemDetailsService";
import { DialogContent } from "@mui/material";
import TaskSkeleton from "../../../../task/modules/view/containers/TaskSkeleton";
import BacklogItemContent from "./BacklogItemContent";
import Dialog from "../../../../../common/modules/taskView/containers/Dialog/Dialog";

const BacklogItemViewModal = () => {
  const isMobileView = useMedia(QueryEnum.MAX_WIDTH_1200);
  const dispatch = useAppDispatch();
  const { backlogItemRef, detailsResource } = useAppSelector(
    (state) => state.backlogItemView
  );

  const { dispatch: dispatchGetDetails, isLoading: isGetDetailsLoading } =
    useGetBacklogItemDetailsService();

  const closeDialogHandler = () => {
    dispatch(backlogItemViewActions.reset());
  };

  useEffect(() => {
    backlogItemRef &&
      dispatchGetDetails({
        urlPath: `/${backlogItemRef}`,
      }).catch(() => {
        dispatch(
          systemNotificationActions.open({
            message: "Failed to fetch backlog item details. Please try again.",
            variant: "error",
          })
        );
        closeDialogHandler();
      });
  }, [backlogItemRef]);

  return (
    <Dialog
      open={!!backlogItemRef}
      isMobileView={isMobileView}
      closeDialogHandler={closeDialogHandler}
    >
      <DialogContent>
        {/* If no backlog item yet exists and is loading then show skeleton */}
        {!detailsResource && isGetDetailsLoading && (
          <TaskSkeleton isMobileView={isMobileView} />
        )}

        {detailsResource && (
          <BacklogItemContent
            detailsResource={detailsResource}
            isMobileView={isMobileView}
            contentHeight="100%"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default BacklogItemViewModal;
