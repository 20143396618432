import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TStatusFormProps } from "./types/TStatusFormProps";
import { TStatusFormAttributes } from "./types/TStatusFormAttributes";
import Dropdown from "../../../../../../common/modules/materialUI/components/Dropdown/Dropdown";
import { useEffect } from "react";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { useOrgStatusFlowData } from "../../../../../org/hooks/useOrgStatusFlowData";
import { ArrowDropDownRounded } from "@mui/icons-material";
import { TGuesstimationItemUpdatedEvent } from "../types/TGuesstimationItemUpdatedEvent";
import { useUpdateGuesstimationItemService } from "../../services/useUpdateGuesstimationItemService";
import { IGuesstimationItemDetailsResource } from "../../interfaces/IGuesstimationItemDetailsResource";

const StatusForm = ({ guesstimationItem }: TStatusFormProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const form = useForm<TStatusFormAttributes>({
    statusId: guesstimationItem.statusId,
  });
  const { dispatch: dispatchUpdateGuesstimationItemService } =
    useUpdateGuesstimationItemService(guesstimationItem.id);
  const { statusList } = useOrgStatusFlowData(guesstimationItem.projectRef);

  useEffect(() => {
    form.set({ statusId: guesstimationItem.statusId });
  }, [guesstimationItem.statusId]);

  const submitHandler = (event: any, value: any) => {
    event.preventDefault();

    dispatchUpdateGuesstimationItemService({
      body: form.getTransformed<TStatusFormAttributes>((_) => ({
        statusId: value?.props?.value,
      })),
    })
      .then((payload: IGuesstimationItemDetailsResource) => {
        dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_UPDATED, {
          initGuesstimationItem: guesstimationItem,
          guesstimationItem: payload.guesstimationItem,
        } as TGuesstimationItemUpdatedEvent);

        form.notDirty();
      })
      .catch((error: THttpClientError) => {
        form.set({ statusId: guesstimationItem.statusId });

        if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        }
        error.status !== 422 &&
          dispatch(
            systemNotificationActions.open({
              message:
                "There was a problem with updating the status. Please try again.",
              variant: "error",
            })
          );

        form.errorHandler(error);
      });
  };

  return (
    <Dropdown
      form={form}
      withoutBorder
      onChange={submitHandler}
      name="statusId"
      options={statusList}
      IconComponent={(props) => (
        <ArrowDropDownRounded
          {...props}
          fontSize="small"
          sx={{
            color: "#4C5363 !important",
            right: "0 !important",
          }}
        />
      )}
      sx={{
        bgcolor: "#E6F5F6",
        border: "1px solid #BCE5E7",
        borderRadius: "4px",
        height: "auto",
        p: "6px 4px",
        "& .MuiSelect-select": {
          color: "#4C5363",
          fontSize: "15px",
          fontWeight: 500,
          lineHeight: 1,
          minHeight: "0 !important",
          p: "0 !important",
          pr: "24px !important",
          "::first-letter": {
            textTransform: "uppercase",
          },
        },
      }}
    />
  );
};

export default StatusForm;
