import { pointConverter } from "./pointConverter";

export const windowHelper = {
  deselectAllText: () => {
    // All browsers, except IE <9
    window.getSelection && window.getSelection()?.removeAllRanges();
  },
  hasSelection: () => window.getSelection()?.toString().length ?? 0 > 0,
  widthToWeeks: (ceil = true) => {
    const weeks = pointConverter.numberOfDaysInWidth(window.innerWidth) / 7;
    return ceil ? Math.ceil(weeks) : weeks;
  },
};
