import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { TUseFetchCustomFieldsByBoardRefServiceProps } from "./types/TUseFetchCustomFieldsByBoardRefServiceProps";
import { IGetCustomFieldsByBoardRefResource } from "../interfaces/IGetCustomFieldsByBoardRefResource";

export const useFetchCustomFieldsByBoardRefService = ({
  boardRef,
  query,
}: TUseFetchCustomFieldsByBoardRefServiceProps) => {
  const response = useHttpClientDispatchable({
    url: `/customField/${boardRef}`,
    method: RequestMethodEnum.GET,
    query: query,
    callbacks: {
      transformResponse: (data: IGetCustomFieldsByBoardRefResource) => {
        data.customFields.forEach((customField) => {
          if (customField.data?.options) {
            customField.data.options = JSON.parse(
              customField.data.options as unknown as string
            );
          }
        });

        return data;
      },
    },
  });

  return {
    ...response,
    data: response.data as IGetCustomFieldsByBoardRefResource | null,
  };
};
