import { IBoardUsersRequest } from "../interfaces/IBoardUsersRequest";
import { useAppDispatch } from "../../../common/hooks/redux";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { userActions } from "../slices/userSlice";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { IBoardUsersResource } from "../../boardView/interfaces/IBoardUsersResource";
import { boardQueryActions } from "../../boardView/slices/boardQuerySlice";

export const useBoardUsersService = (props: { query: IBoardUsersRequest }) => {
  const dispatch = useAppDispatch();
  return useHttpClientDispatchable({
    url: `/user/boardUsers`,
    method: RequestMethodEnum.GET,
    query: props.query,
    events: {
      beforeTransform: (data: IBoardUsersResource) => {
        dispatch(userActions.setList(data));
        dispatch(boardQueryActions.matchUserFilterToUserList(data.users));
      },
    },
  });
};
