import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { Box } from "@mui/material";
import { DataGrid } from "../../../../../common/modules/materialUI";
import { gridClasses } from "@mui/x-data-grid";
import Loader from "../../../../../common/containers/Loader/Loader";
import { Columns } from "./Columns";
import { useFetchInvoiceListService } from "../../../services/useFetchInvoiceListService";

const InvoiceGrid = () => {
  const { isLoading } = useFetchInvoiceListService();
  const invoiceList = useAppSelector((state) => state.stripe.list);

  return (
    <Box>
      <DataGrid
        columns={Columns()}
        getRowHeight={() => "auto"}
        rows={invoiceList ?? []}
        disableRowSelectionOnClick
        checkboxSelection={false}
        autoHeight={!invoiceList || !invoiceList.length}
        loading={invoiceList === null || isLoading}
        sx={{
          [`& .${gridClasses.row}`]: {
            minHeight: `52px !important`,
          },
        }}
      />
      {isLoading && <Loader />}
    </Box>
  );
};

export default InvoiceGrid;
