import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { ITimelineCostTotalsResource } from "../../../interfaces/ITimelineCostTotalsResource";

export const useFetchTimelineCostTotalsService = (boardRef: string) => {
  const response = useHttpClientDispatchable({
    url: `/board/${boardRef}/holisticView/costTotals`,
    method: RequestMethodEnum.GET,
  });

  return {
    ...response,
    data: response.data as ITimelineCostTotalsResource[],
  };
};
