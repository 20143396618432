import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { taskViewActions } from "../../slices/viewSlice";
import { Dialog } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { AddAssigneesTypeEnum } from "../../slices/enums/AddAssigneesTypeEnum";
import DialogContent from "@mui/material/DialogContent";
import AddAssigneesForm from "./AddAssigneesForm";
import { useSubscribeEvent } from "../../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";

const AddAssigneesDialog = () => {
  const dispatch = useAppDispatch();
  const addAssigneesState = useAppSelector(
    (state) => state.taskView.addAssigneesDialog
  );

  const closeHandler = () => {
    dispatch(taskViewActions.closeAddAssigneesDialog());
  };

  useSubscribeEvent(EventEnum.ON_TASK_CREATED, () => closeHandler());

  if (!addAssigneesState?.open) {
    return null;
  }

  const title =
    addAssigneesState.type === AddAssigneesTypeEnum.ADD_SPLIT
      ? "Add Split"
      : "Add Assignee";

  return (
    <Dialog open={true} onClose={closeHandler} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <AddAssigneesForm
          taskId={addAssigneesState.taskId}
          type={addAssigneesState.type}
          onCancel={closeHandler}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddAssigneesDialog;
