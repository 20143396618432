import { useEffect, useState } from "react";
import ObjectiveContent from "./ObjectiveContent";
import { useFetchObjectiveService } from "../../services/useFetchObjectiveService";
import { useAppSelector } from "../../../../common/hooks/redux";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import TaskSkeleton from "../../../task/modules/view/containers/TaskSkeleton";
import NotFound from "../../../../common/components/Page/NotFound/NotFound";
import useWindowInnerHeight from "../../../../common/hooks/useWindowInnerHeight";
import { HEADER_HEIGHT } from "../../../../common/containers/Layout/App/Header/Header";
import { useParams } from "react-router-dom";
import { TObjectivePageParams } from "./types/TObjectivePageParams";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";

const TaskPage = () => {
  const { objective } = useAppSelector((state) => state.objective.view);
  const { objectiveRef } = useParams<TObjectivePageParams>();
  const isObjectivesAllowed = useIsFeatureAllowed(FeatureEnum.OBJECTIVES);
  const { dispatch: dispatchFetchObjective, isLoading } =
    useFetchObjectiveService({ objectiveRef: objectiveRef ?? "" });
  const isMobileView = useMedia(QueryEnum.MAX_WIDTH_1200);
  const [exists, setExists] = useState<boolean>(true);
  const windowHeight = useWindowInnerHeight();

  useEffect(() => {
    objectiveRef &&
      dispatchFetchObjective().catch((_) => {
        setExists(false);
      });
  }, [objectiveRef]);

  if (!isObjectivesAllowed && !!objective) {
    return <NotFound />;
  }

  return (
    <>
      {isLoading && !objective && <TaskSkeleton isMobileView={isMobileView} />}
      {!isLoading && objective && (
        <ObjectiveContent
          objective={objective!}
          isMobileView={isMobileView}
          contentHeight={`calc(${windowHeight}px - ${HEADER_HEIGHT}px)`}
          isPageView
        />
      )}
      {!exists && <NotFound />}
    </>
  );
};

export default TaskPage;
