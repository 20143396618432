import { TIsMobileViewProp } from "../types/TIsMobileViewProp";
import { TChildren } from "../../../../types/TChildren";
import QuickActionsDesktop from "./QuickActionsDesktop";
import QuickActionsMobile from "./QuickActionsMobile";
import { TSxProp } from "../../../../types/TSxProp";

const QuickActions = ({
  isMobileView,
  children,
  ...props
}: TIsMobileViewProp & TChildren & TSxProp) => {
  return !isMobileView ? (
    <QuickActionsDesktop {...props}>{children}</QuickActionsDesktop>
  ) : (
    <QuickActionsMobile {...props}>{children}</QuickActionsMobile>
  );
};

export default QuickActions;
