import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { guesstimationItemViewActions } from "../slices/viewSlice";
import { IGuesstimationItemDetailsResource } from "../interfaces/IGuesstimationItemDetailsResource";

export const useGetGuesstimationItemDetailsService = (
  storeResourceInState: boolean = true
) => {
  const dispatch = useAppDispatch();

  const response = useHttpClientDispatchable({
    url: `/guesstimationItem`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IGuesstimationItemDetailsResource) => {
        if (storeResourceInState) {
          dispatch(guesstimationItemViewActions.setDetailsResource(data));
        }
      },
    },
  });

  return {
    ...response,
    data: response.data as IGuesstimationItemDetailsResource | null,
  };
};
