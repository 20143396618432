import Title from "../../../../common/components/Title/Title";
import ScanNew from "../ScanNew";
import List from "../List";
import { Box } from "@mui/material";
import UpdateModal from "../UpdateNetwork/UpdateModal";

export default function Ai4Network() {
  return (
    <>
      <Title>Ai4 Network</Title>
      <ScanNew />
      <Box mt={3}>
        {/*Same with Last Vegas*/}
        <List timezone="America/Los_Angeles" />
      </Box>
      <UpdateModal />
    </>
  );
}
