import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { ITask } from "../../../entities/ITask";
import { useDispatchEvent } from "../../../common/modules/eventProvider";
import { EventEnum } from "../../../common/modules/eventProvider/enums/EventEnum";

export const useCreateTaskService = () => {
  const dispatchEvent = useDispatchEvent();
  const response = useHttpClientDispatchable({
    url: "/task",
    method: RequestMethodEnum.POST,
    events: {
      beforeRequest: ({ body }) =>
        dispatchEvent(EventEnum.ON_BEFORE_TASK_CREATED, body),
    },
  });

  return {
    ...response,
    data: response.data as ITask[],
  };
};
