import { DatePicker } from "../../../../../common/modules/materialUI";
import React from "react";
import { TCustomFieldInputProps } from "../types/TCustomFieldInputProps";
import { customFieldInputUtil } from "../../../utils/customFieldInputUtil";

const CustomDateField = <TFormAttributes extends unknown>({
  customField,
  allowEmptyValue,
  form,
  showLabel,
  submitHandler,
  hideRequiredMark,
}: TCustomFieldInputProps<TFormAttributes>) => {
  return (
    <DatePicker
      form={form}
      name={customFieldInputUtil.generateName(customField)}
      allowClear={!customField.isRequired || allowEmptyValue}
      label={showLabel ? customField.name : undefined}
      showRequired={!hideRequiredMark && customField.isRequired}
      onChange={
        submitHandler
          ? (value, keyboardInputValue) =>
              submitHandler(value ?? keyboardInputValue)
          : undefined
      }
    />
  );
};

export default CustomDateField;
