import { TMessageWithLinksProps } from "./types/TMessageWithLinksProps";
import NotificationLink from "../NotificationLink";
import React from "react";

const MessageWithLinks = ({ message }: TMessageWithLinksProps) => {
  // Splitting each text and placeholder replacement as element of array
  const parts: (string | React.ReactNode)[] = [];
  let remainingText = message.text;

  Object.keys(message.placeholders).forEach((key) => {
    const placeholder = `{${key}}`;
    const index = remainingText.indexOf(placeholder);
    // If placeholder found
    if (index !== -1) {
      // Data for replacement
      const placeholderValue = message.placeholders[key];
      // If found NOT at the beginning of the string, then adding string part before the placeholder and removing from remaining text
      if (index > 0) {
        parts.push(remainingText.substring(0, index));
      }
      // Replacing placeholder and adding to parts
      parts.push(
        <NotificationLink to={placeholderValue.url}>
          {placeholderValue.label}
        </NotificationLink>
      );
      // Removing placeholder from remaining text
      remainingText = remainingText.substring(index + placeholder.length);
    }
  });

  // If something is left in the end, adding to the parts
  if (remainingText) {
    parts.push(remainingText);
  }

  return (
    <>
      {parts.map((part, index) => (
        <React.Fragment key={index}>{part}</React.Fragment>
      ))}
    </>
  );
};

export default MessageWithLinks;
