import { useEffect } from "react";
import { TUseScriptProps } from "./types/TUseScriptProps";

export const useScript = ({
  async,
  innerHTML,
  url,
  dataAttributes = [],
  placement = "body",
  onLoad,
}: TUseScriptProps) => {
  useEffect(() => {
    const script = document.createElement("script");
    if (url) {
      script.src = url;
    } else if (innerHTML) {
      script.innerHTML = innerHTML;
    } else {
      throw new Error("You must provide either a url or innerHTML");
    }

    if (async) {
      script.async = true;
    }

    dataAttributes.forEach(({ key, value }) => {
      script.setAttribute(`data-${key}`, value);
    });

    if (onLoad) {
      script.onload = onLoad;
    }

    document[placement]?.appendChild(script);

    return () => {
      document[placement]?.removeChild(script);
    };
  }, [async, innerHTML, url, dataAttributes, placement]);
};
