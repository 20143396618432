import React, { useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import Loader from "../../../../common/containers/Loader/Loader";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Insights,
  Notifications,
  NotificationsActive,
  StickyNote2,
} from "@mui/icons-material";
import VerticalDivider from "../../../../common/components/Divider/VerticalDivider";
import { useFetchOrgInitDataService } from "../../../org/services/useFetchOrgInitDataService";
import Title from "../../../../common/components/Title/Title";
import useRegisterHeader from "../../../../common/modules/registry/hooks/useRegisterHeader";
import { HeaderRegistryEnum } from "../../../../common/modules/registry/enums/HeaderRegistryEnum";
import { Tooltip } from "../../../../common/modules/materialUI";
import NotesModal from "./NotesModal";
import NeedsAttentionModal from "./NeedsAttentionModal";
import { DefaultComponentProps } from "@mui/types";
import { IProject } from "../../../../entities/IProject";
import { useUpdateProjectNeedsAttentionFlagService } from "../../../project/services/useUpdateProjectNeedsAttentionFlagService";
import { TUpdateFormAttributes } from "../../../project/containers/UpdateProject/types/TUpdateFormAttributes";
import { orgActions } from "../../../org/slices/orgSlice";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { useAccessControl } from "../../../../common/modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../common/modules/accessControl/enums/AbilityEnum";

const ProjectView = () => {
  const { can } = useAccessControl();
  const navigate = useNavigate();
  const theme = useTheme();
  useRegisterHeader(HeaderRegistryEnum.projectView, []);
  // The service is being called to get updated insights data, as of this moment that information is not
  // saved in states
  const { isLoading } = useFetchOrgInitDataService(false);
  const projectsList = useAppSelector((state) => state.org.init.list);
  const hasProjects = projectsList.length > 0;
  const dispatch = useAppDispatch();
  const [noteSelectedProject, setNoteSelectedProject] = useState<number>(0);
  const [needsAttentionSelectedProjectId, setNeedsAttentionSelectedProjectId] =
    useState<number>(0);
  const {
    dispatch: dispatchUpdateProjectNeedsAttentionFlagService,
    isLoading: updateNeedsAttentionFlagLoading,
  } = useUpdateProjectNeedsAttentionFlagService();

  const needsAttentionSelectedProject = projectsList.find(
    (item) => item.project.id === needsAttentionSelectedProjectId
  )?.project;

  const defaultBorder = `5px solid ${theme.palette.brightCardBackground.default}`;

  const undoNeedsAttentionHandler = (project: IProject) => {
    if (updateNeedsAttentionFlagLoading) {
      return;
    }

    dispatchUpdateProjectNeedsAttentionFlagService({
      urlPath: project.ref,
      body: {
        needsAttention: false,
      } as TUpdateFormAttributes,
    })
      .then(() => {
        dispatch(
          orgActions.updateProjectData({
            projectRef: project.ref,
            ...project,
            needsAttention: false,
          })
        );

        dispatch(
          systemNotificationActions.open({
            message:
              "Your project has been marked as not requiring immediate attention.",
            variant: "success",
          })
        );
      })
      .catch((_error: THttpClientError) => {
        dispatch(
          systemNotificationActions.open({
            variant: "error",
            message: "Something went wrong. Please try again.",
          })
        );
      });
  };

  return (
    <>
      <Title>Project view</Title>
      {!isLoading && hasProjects && (
        <Grid container spacing={3} style={{ padding: "20px" }}>
          {projectsList.map(({ project, boards }, index) => {
            const notificationIconProps: DefaultComponentProps<any> = {
              fontSize: "small",
              color: project.needsAttention
                ? !updateNeedsAttentionFlagLoading
                  ? "error"
                  : "disabled"
                : "inherit",
            };

            return (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                key={index}
                style={{ position: "relative" }}
              >
                <Box
                  style={{
                    position: "absolute",
                    right: 0,
                    zIndex: 1,
                  }}
                  display="flex"
                >
                  {can(AbilityEnum.manage) && (
                    <Tooltip
                      title={
                        project.needsAttention
                          ? "Undo needs attention"
                          : "Mark as needs attention"
                      }
                    >
                      <Box
                        p={1}
                        pr={0.5}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          project.needsAttention
                            ? undoNeedsAttentionHandler(project)
                            : setNeedsAttentionSelectedProjectId(project.id);
                        }}
                      >
                        {project.needsAttention ? (
                          <NotificationsActive {...notificationIconProps} />
                        ) : (
                          <Notifications {...notificationIconProps} />
                        )}
                      </Box>
                    </Tooltip>
                  )}
                  <Tooltip title="Notes">
                    <Box
                      p={1}
                      pl={0.5}
                      sx={{ cursor: "pointer" }}
                      onClick={function () {
                        setNoteSelectedProject(project.id);
                      }}
                    >
                      <StickyNote2 fontSize="small" />
                    </Box>
                  </Tooltip>
                </Box>
                <Card
                  onClick={() =>
                    navigate(
                      `/${project.abbr}/board/${project.holisticBoardRef}/holistic`
                    )
                  }
                  style={{
                    cursor: "pointer",
                    color: theme.palette.brightCardBackground.main,
                    backgroundColor: theme.palette.brightCardBackground.paper,
                    borderTop: defaultBorder,
                    borderLeft: defaultBorder,
                    borderRight: defaultBorder,
                    borderBottom: `2px solid ${
                      project.color || theme.palette.info.main
                    }`,
                  }}
                  sx={{
                    "& .MuiCardContent-root:last-child": {
                      paddingBottom: "5px",
                    },
                  }}
                >
                  <CardActionArea>
                    <CardContent sx={{ p: 1 }}>
                      <Box display="flex" gap={0} flexDirection="column">
                        <Typography
                          variant="body1"
                          style={{ color: theme.palette.text.primary }}
                        >
                          {project.name}
                        </Typography>
                        <Typography variant="caption" fontWeight="bold">
                          #{project.abbr}
                        </Typography>
                      </Box>
                    </CardContent>
                    <CardContent sx={{ p: 0 }}>
                      <Box
                        display="flex"
                        gap={1}
                        alignItems="center"
                        justifyContent="flex-end"
                        style={{ padding: "0 10px" }}
                      >
                        <Insights fontSize="small" />
                        <Typography variant="overline">
                          Boards <strong>{boards.length}</strong>
                        </Typography>
                        <VerticalDivider
                          sx={{ height: "3px", margin: "auto 0" }}
                        />
                        <Typography variant="overline">
                          Tasks <strong>{project.tasksCount}</strong>
                        </Typography>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
      {isLoading && <Loader />}
      {!isLoading && !hasProjects && <Navigate to={"/"} replace={true} />}
      {!!needsAttentionSelectedProject && (
        <NeedsAttentionModal
          closeHandler={function () {
            setNeedsAttentionSelectedProjectId(0);
          }}
          project={needsAttentionSelectedProject}
        />
      )}
      {!!noteSelectedProject && (
        <NotesModal
          closeHandler={function () {
            setNoteSelectedProject(0);
          }}
          projectId={noteSelectedProject}
        />
      )}
    </>
  );
};

export default ProjectView;
