import { Grid } from "@mui/material";
import React from "react";
import { TEntityCustomFieldFormProps } from "./types/TEntityCustomFieldFormProps";
import CustomFieldInput from "../CustomFieldInput/CustomFieldInput";
import useForm from "../../../../common/hooks/useForm/useForm";
import { TCustomFieldFormAttributes } from "./types/TCustomFieldFormAttributes";
import { customFieldInputUtil } from "../../utils/customFieldInputUtil";
import { TypeEnum } from "../../../../entities/columns/customField/TypeEnum";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../common/hooks/redux";
import DetailsLabel from "../../../task/modules/view/containers/TaskViewDetails/DetailsLabel";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../common/modules/eventProvider";

const EntityCustomFieldForm = ({
  customField,
  customFieldValues,
  dispatchUpdateService,
  allowEmptyValue,
}: TEntityCustomFieldFormProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();

  const name = customFieldInputUtil.generateName(customField);
  const defaultValue: any = customFieldInputUtil.getFormAttributeValue(
    customField,
    customFieldValues ?? []
  );

  const form = useForm<TCustomFieldFormAttributes>({
    [name as string]: defaultValue,
  });

  const submitHandler = (value: any) => {
    if (customField.type === TypeEnum.TEXT_FIELD) {
      if (!form.isDirty()) {
        return;
      }

      form.notDirty();
    }

    dispatchUpdateService({
      body: {
        ...customFieldInputUtil.getCustomFieldFormAttributesTransformed(
          [customField],
          { [name]: value }
        ),
      },
    })
      .then(() => {
        dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED);
        form.resetErrors();
      })
      .catch((error) => {
        error.status === 422 && form.errorHandler(error);
        error.status !== 422 &&
          dispatch(
            systemNotificationActions.open({
              message: `There was a problem with updating the ${customField.name}. Please try again.`,
              variant: "error",
            })
          );
      });
  };

  return (
    <>
      <Grid item xs={4}>
        <DetailsLabel displayRequired={customField.isRequired}>
          {customField.name}
        </DetailsLabel>
      </Grid>

      <Grid item xs={8}>
        <CustomFieldInput
          customField={customField}
          form={form}
          showLabel={false}
          submitHandler={submitHandler}
          allowEmptyValue={allowEmptyValue}
        />
      </Grid>
    </>
  );
};

export default EntityCustomFieldForm;
