import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IBreakdownTextResource } from "../interfaces/IBreakdownTextResource";

export const useBreakdownTextService = () => {
  const response = useHttpClientDispatchable({
    url: "/aiSentinel/breakdownText",
    method: RequestMethodEnum.POST,
  });

  return {
    ...response,
    data: response.data as IBreakdownTextResource,
  };
};
