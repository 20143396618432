import { useAppSelector } from "../../../../common/hooks/redux";
import { BoardViewEnum } from "../../enums/BoardViewEnum";
import { Tooltip } from "../../../../common/modules/materialUI";
import { AddRoad } from "@mui/icons-material";
import classes from "./Sprint.module.scss";
import React from "react";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import { Box, useTheme } from "@mui/material";
import { ITask } from "../../../../entities/ITask";

const useSprintHelper = (props: { view: BoardViewEnum }) => {
  const theme = useTheme();
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);
  const data =
    props.view === BoardViewEnum.LIST_VIEW
      ? useAppSelector((state) => state.listView.list.sprintData)
      : useAppSelector((state) => state.kanbanView.list.sprintData);
  const sprints =
    props.view === BoardViewEnum.LIST_VIEW
      ? useAppSelector((state) => state.listView.list.sprints)
      : useAppSelector((state) => state.kanbanView.list.sprints);

  const taskSprint = (taskId: number) => {
    const taskSprintRefs = data
      .filter((item) => item.taskId === taskId)
      .map((item) => item.sprintRef);
    if (!taskSprintRefs || taskSprintRefs.length === 0) {
      return null;
    }

    const sprintData = sprints.filter((item) =>
      taskSprintRefs.includes(item.ref!)
    );

    const sortedSprints = sprintData.sort((a, b) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });

    return sortedSprints.length > 1
      ? [sortedSprints[0], sortedSprints[sortedSprints.length - 1]]
      : sortedSprints;
  };

  const segmentSprint = (taskSegmentId: number) => {
    if (!taskSegmentId) {
      return [];
    }

    const segmentSprintRef = data.find(
      (item) => item.taskSegmentId == taskSegmentId
    )?.sprintRef;

    if (!segmentSprintRef) {
      return null;
    }

    const sprint = sprints.find((item) => item.ref === segmentSprintRef);

    return sprint ? [sprint] : [];
  };

  return {
    label: function (isSegmentView: boolean, task: ITask) {
      const sprints = isSegmentView
        ? segmentSprint(task.segmentId)
        : taskSprint(task.id);

      if (!sprints || sprints.length === 0) {
        return null;
      }

      const label = sprints?.map((sprint) => sprint.name).join(" / ");

      return (
        <Tooltip title={label}>
          <Box
            className={classes.container}
            style={{
              backgroundColor: theme.palette.sprint.main,
              color: theme.palette.sprint.contrastText,
            }}
          >
            {!matchesMax768Screen ? (
              <div className={classes["sprint-label"]}>{label}</div>
            ) : (
              <AddRoad />
            )}
          </Box>
        </Tooltip>
      );
    },
  };
};

export default useSprintHelper;
