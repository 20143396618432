import { Settings } from "@mui/icons-material";
import TListItemProps from "../../../../../../../components/Navigation/ListItem/types/TListItemProps";
import { TSectionProps } from "../../../types/TSectionProps";

const AppSettingsListItem = (props: TSectionProps) => {
  return {
    id: "SettingsListItem",
    name: "Settings",
    icon: <Settings />,
    path: "/settings/project",
    navigateToPath: true,
    onNavigate: props.onClick,
  } as TListItemProps;
};

export default AppSettingsListItem;
