import { TKeyboardKeyProps } from "./types/TKeyboardKeyProps";
import classes from "./KeyboardKey.module.css";
import { useTheme } from "@mui/material";

export default function KeyboardKey(props: TKeyboardKeyProps) {
  const theme = useTheme();

  return (
    <div
      className={classes.key}
      style={{
        backgroundColor: theme.palette.background.paper,
        fontSize: props.size,
      }}
    >
      {props.keyName}
    </div>
  );
}
