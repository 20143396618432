import { useAppSelector } from "../../../../../../common/hooks/redux";
import { useDrop } from "react-dnd";
import { useTheme } from "@mui/material";
import { TSectionProps } from "../Section/types/TSectionProps";
import { DRAG_TYPE_TASK_CARD } from "../Task/Task";
import useDragOverHelper from "../../../../hooks/useDragOverHelper";
import animateClasses from "./OverlayAnimate.module.css";
import { array } from "../../../../../../common/utils/array";

export default function DropTargetContainer(props: TSectionProps) {
  const { isOver, dragOverHandlers } = useDragOverHelper();
  const theme = useTheme();
  const isDragging = useAppSelector((state) => state.kanbanViewTask.isDragging);
  const showShiftingProgress = useAppSelector(
    (state) => state.kanbanViewTask.showShiftingProgress
  );

  const [, drop] = useDrop(
    () => ({
      accept: DRAG_TYPE_TASK_CARD,
      drop: (_, monitor) => props.status,
    }),
    []
  );

  if (!isDragging && !showShiftingProgress) {
    return null;
  }

  return (
    <div
      className={array.toClassName([
        showShiftingProgress ? animateClasses.animate : "",
      ])}
      {...dragOverHandlers}
      ref={drop}
      style={{
        backgroundColor: theme.palette.dropTargetBackground.default,
        opacity:
          theme.palette.mode === "dark"
            ? isOver
              ? 0.4
              : 0.2
            : isOver
            ? 0.2
            : 0.1,
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 20,
      }}
    />
  );
}
