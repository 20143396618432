import { TCancelProps } from "./types/TCancelProps";
import { Clear } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export default function Cancel(props: TCancelProps) {
  return (
    <IconButton disabled={props.disabled} onClick={props.cancelHandler}>
      <Clear
        fontSize="small"
        sx={{ fontSize: props.fontSize ? `${props.fontSize}px` : undefined }}
      />
    </IconButton>
  );
}
