import { TTaskState } from "./types/TTaskState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITask } from "../../../entities/ITask";
import { ITaskDetailsResource } from "../modules/view/interfaces/ITaskDetailsResource";
import { IUser } from "../../../entities/IUser";
import { TListItem } from "../../../common/types/TListItem";

const initialState: TTaskState = {
  creation: {
    displayModal: false,
    internalType: undefined,
  },
  resource: undefined,
  deletedTaskRefs: [],
  statusReason: undefined,
  dueDateChangedAt: undefined,
};

const slice = createSlice({
  name: "task",
  initialState,
  reducers: {
    openCreationModal: (
      state,
      action: PayloadAction<TTaskState["creation"]["internalType"]>
    ) => {
      state.creation.displayModal = true;
      state.creation.internalType = action.payload;
    },
    closeCreationModal: (state) => {
      state.creation.displayModal = false;
      state.creation.internalType = undefined;
    },
    setData: (state, action: PayloadAction<ITaskDetailsResource>) => {
      state.resource = action.payload;
    },
    setStatusReason: (state, action: PayloadAction<string>) => {
      state.statusReason = action.payload;
    },
    setTask: (state, action: PayloadAction<ITask>) => {
      state.resource = {
        ...state.resource,
        task: action.payload,
      } as ITaskDetailsResource;
    },
    mergeTaskProps: (state, action: PayloadAction<Partial<ITask>>) => {
      if (state.resource?.task) {
        state.resource.task = { ...state.resource.task, ...action.payload };
      }
    },
    setAssignedBy: (state, action: PayloadAction<TListItem<string>>) => {
      state.resource = {
        ...state.resource,
        assignedBy: action.payload,
      } as ITaskDetailsResource;
    },
    addUser: (state, action: PayloadAction<IUser>) => {
      if (state.resource) {
        const listUser: TListItem<string> = {
          id: action.payload.id.toString(),
          label: action.payload.name,
        };
        state.resource = {
          ...state.resource,
          users: [...state.resource.users, action.payload],
          userList: [...state.resource.userList, listUser],
        };
      }
    },
    setUserData: (
      state,
      action: PayloadAction<Pick<ITaskDetailsResource, "users" | "userList">>
    ) => {
      if (state.resource) {
        state.resource.users = action.payload.users;
        state.resource.userList = action.payload.userList;
      }
    },
    clearData: (state) => {
      state.resource = undefined;
    },
    addTaskDeletedRef: (state, action: PayloadAction<{ taskRef: string }>) => {
      if (!state.deletedTaskRefs.includes(action.payload.taskRef)) {
        state.deletedTaskRefs.push(action.payload.taskRef);
      }
    },
    setDueDateChangedAt: (state) => {
      state.dueDateChangedAt = Date.now();
    },
  },
});

export const taskReducer = slice.reducer;

export const taskActions = slice.actions;
