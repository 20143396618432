import {
  Box,
  Grid,
  IconButton,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { holisticViewActions } from "../../modules/holisticView/slices/holisticViewSlice";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { number } from "../../../../common/utils/number";
import useForm from "../../../../common/hooks/useForm/useForm";
import { TFinancialForecastFilterFormAttributes } from "./types/TFinancialForecastFilterFormAttributes";
import { DatePicker } from "../../../../common/modules/materialUI";
import { Check } from "@mui/icons-material";
import { TFinancialForecastSectionProps } from "./types/TFinancialForecastSectionProps";
import { useFetchTimelineCostTotalsService } from "../../modules/holisticView/services/useFetchTimelineCostTotalsService";
import { date, FORMAT_RAW_DATE_ONLY } from "../../../../common/utils/date";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import Loading from "../../../../common/components/Icon/Loading/Loading";
import { UserTimelineTypeEnum } from "../../../../entities/columns/userTimeline/UserTimelineTypeEnum";
import { currencyUtil } from "../../../../common/utils/currencyUtil";
import { ITimelineCostTotalsRequest } from "../../interfaces/ITimelineCostTotalsRequest";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";

const costTimelineTypeToLabelMap = {
  [UserTimelineTypeEnum.TASK as string]: "Tasks",
  [UserTimelineTypeEnum.SPIKE as string]: "Spikes",
  [UserTimelineTypeEnum.EVENT as string]: "Events",
};

const FinancialForecastSection = ({
  boardRef,
}: TFinancialForecastSectionProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const displayCostPerItem = useAppSelector(
    (state) => state.holisticView.displayCostPerItem
  );
  const {
    isLoading: isFetchingCostTotals,
    dispatch: dispatchFetchTotalCosts,
    data: currencyTotalCosts,
  } = useFetchTimelineCostTotalsService(boardRef);

  const form = useForm<TFinancialForecastFilterFormAttributes>({
    fromDate: null,
    toDate: null,
  });

  const submitHandler = () => {
    dispatchFetchTotalCosts({
      query: form.getTransformed(
        (data) =>
          ({
            fromDate: data.fromDate
              ? date.format(data.fromDate, FORMAT_RAW_DATE_ONLY)
              : null,
            toDate: data.toDate
              ? date.format(data.toDate, FORMAT_RAW_DATE_ONLY)
              : null,
          } as ITimelineCostTotalsRequest)
      ),
    })
      .then(() => {
        form.notDirty();
      })
      .catch((error: THttpClientError) => {
        if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        }

        form.errorHandler(error);
      });
  };

  return (
    <>
      <Typography
        variant="body2"
        color={theme.palette.text.secondary}
        fontWeight={500}
        fontSize={16}
      >
        For interval
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <DatePicker form={form} name="fromDate" showRequired label="From" />
        </Grid>
        <Grid item xs={6}>
          <DatePicker form={form} name="toDate" showRequired label="To" />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <IconButton
            type="submit"
            disabled={
              isFetchingCostTotals ||
              !form.get("fromDate") ||
              !form.get("toDate")
            }
            onClick={submitHandler}
          >
            <Check fontSize="small" sx={{ fontSize: "16px" }} />
          </IconButton>
        </Grid>
      </Grid>

      {isFetchingCostTotals && <Loading />}
      {!isFetchingCostTotals &&
        currencyTotalCosts !== null &&
        !currencyTotalCosts.length && (
          <Typography variant="body2" color={theme.palette.text.secondary}>
            Costs have not been found
          </Typography>
        )}
      {!isFetchingCostTotals && !!currencyTotalCosts?.length && (
        <Grid container direction="column" spacing={2} mb={2}>
          {currencyTotalCosts.map((currencyTotalCost) => (
            <Grid
              key={currencyTotalCost.costCurrency}
              container
              item
              direction="column"
              spacing={0.5}
              bgcolor={theme.palette.dataGrid.borderColor}
              sx={{ mt: 2, ml: 0, pb: 1, pr: 1 }}
            >
              {/* Total Currency Cost */}
              <Grid container item direction="column" spacing={0.1}>
                <Grid item>
                  <Typography
                    variant="body2"
                    color={theme.palette.text.secondary}
                    fontWeight={500}
                  >
                    Total Cost ({currencyTotalCost.costCurrency})
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    color={theme.palette.text.secondary}
                    fontWeight={500}
                  >
                    {currencyUtil.getSymbol(currencyTotalCost.costCurrency)}
                    {number.formatAsPrice(currencyTotalCost.currencyTotalCost)}
                  </Typography>
                </Grid>
              </Grid>

              {/* Type Cost */}
              <Grid container item direction="column" spacing={1}>
                {currencyTotalCost.typeTotalCosts.map((typeTotalCost) => (
                  <Grid key={typeTotalCost.type} item>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          component="span"
                          color={theme.palette.text.secondary}
                          style={{
                            fontSize: "3rem",
                            fontWeight: "lighter",
                            lineHeight: 0.5,
                            paddingRight: "2px",
                            paddingTop: "10px",
                          }}
                        >
                          •
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          color={theme.palette.text.secondary}
                          paddingTop={1}
                        >
                          {costTimelineTypeToLabelMap[typeTotalCost.type]}
                        </Typography>
                      </div>
                      <Typography
                        variant="body2"
                        component="span"
                        color={theme.palette.text.secondary}
                        paddingTop={1}
                      >
                        {currencyUtil.getSymbol(currencyTotalCost.costCurrency)}
                        {number.formatAsPrice(typeTotalCost.totalCost)}
                      </Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}

      <Box display="flex" gap={0.5} alignItems="center" sx={{ mt: 0.6 }}>
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          fontWeight={500}
          fontSize={14}
        >
          Display Cost Per Item
        </Typography>
        <Switch
          color="success"
          checked={displayCostPerItem}
          onChange={(event, checked) =>
            dispatch(holisticViewActions.displayCostPerItem(checked))
          }
        />
      </Box>
    </>
  );
};

export default FinancialForecastSection;
