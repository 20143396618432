import { ITask } from "../../entities/ITask";
import { SplitDirectionEnum } from "../../entities/columns/taskSegment/SplitDirectionEnum";

export const taskUtil = {
  url: (task: ITask, number?: number) =>
    taskUtil.urlByRef(
      task.ref!,
      task.hasSplits ? number ?? task.number : undefined
    ),
  urlByTask: (task: ITask) =>
    `/task/${task.ref}${task.hasSplits ? `/${task.number}` : ""}`,
  urlByRef: (ref: string, number?: number) =>
    `/task/${ref}${number ? `/${number}` : ""}`,
  isSplitLeft: (direction?: SplitDirectionEnum) =>
    direction === SplitDirectionEnum.LEFT ||
    direction === SplitDirectionEnum.BOTH,
  isSplitRight: (direction?: SplitDirectionEnum) =>
    direction === SplitDirectionEnum.RIGHT ||
    direction === SplitDirectionEnum.BOTH,
  getEstimationPoints: (isSegmentView: boolean, task: ITask) =>
    !isSegmentView ? task.aggEstimationPoints : task.estimationPoints,
  getEstimationUom: (isSegmentView: boolean, task: ITask) =>
    !isSegmentView ? task.aggEstimationUom : task.estimationUom,
};
