import { useAppDispatch } from "../../../common/hooks/redux";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { linkListActions } from "../slices/linkListSlice";
import { IListResource } from "../../../interfaces/IListResource";
import { ILinkList } from "../../../entities/ILinkList";
import { useHttpClient } from "../../../common/modules/httpClient/hooks/useHttpClient";

export const useFetchLinkListService = () => {
  const dispatch = useAppDispatch();
  const response = useHttpClient({
    url: `/linkList`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: ILinkList[]) => {
        dispatch(linkListActions.setList(data));
        dispatch(
          linkListActions.setAutocompleteList(
            data.map(
              (linkList) =>
                ({
                  id: linkList.id,
                  label: linkList.description,
                } as IListResource)
            )
          )
        );
      },
    },
  });

  return {
    ...response,
    data: response.data as IListResource[],
  };
};
