import { TTeamState } from "./types/TTeamState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserResource } from "../interfaces/IUserResource";
import { StatusEnum } from "../../../entities/columns/user/StatusEnum";

const initialState: TTeamState = {
  invitation: {
    displayModal: false,
    email: null,
  },
  financialData: {
    showModal: false,
    userId: undefined,
  },
  virtualUser: {
    displayModal: false,
  },
  roleMap: {
    displayModal: false,
  },
  changeStatus: {
    message: null,
    userId: null,
    userCount: null,
  },
  list: [],
};

const slice = createSlice({
  name: "team",
  initialState,
  reducers: {
    openInvitationModal: (state, action: PayloadAction<string | undefined>) => {
      state.invitation.displayModal = true;
      if (action.payload) {
        state.invitation.email = action.payload;
      }
    },
    closeInvitationModal: (state) => {
      state.invitation.email = null;
      state.invitation.displayModal = false;
    },
    openFinancialDataModal: (state, action: PayloadAction<number>) => {
      state.financialData.showModal = true;
      state.financialData.userId = action.payload;
    },
    closeFinancialDataModal: (state) => {
      state.financialData.showModal = false;
      state.financialData.userId = undefined;
    },
    openCreateVirtualUserModal: (state) => {
      state.virtualUser.displayModal = true;
    },
    closeCreateVirtualUserModal: (state) => {
      state.virtualUser.displayModal = false;
    },
    setList: (state, action: PayloadAction<IUserResource[]>) => {
      state.list = action.payload;
    },
    removeFromList: (state, action: PayloadAction<string>) => {
      state.list = state.list.filter(
        (item) => item.identifier !== action.payload
      );
    },
    addToList: (state, action: PayloadAction<IUserResource>) => {
      state.list = [action.payload, ...state.list];
    },
    openManageRoleMapModal: (state) => {
      state.roleMap.displayModal = true;
    },
    closeManageRoleMapModal: (state) => {
      state.roleMap.displayModal = false;
    },
    openConfirmationDialog: (
      state,
      action: PayloadAction<{
        userId: number;
        userCount: number;
        message: string;
      }>
    ) => {
      state.changeStatus.message = action.payload.message;
      state.changeStatus.userId = action.payload.userId;
      state.changeStatus.userCount = action.payload.userCount;
    },
    closeConfirmationDialog: (state) => {
      state.changeStatus = initialState.changeStatus;
    },
    updateVirtualDeactivationUserStatuses: (
      state,
      action: PayloadAction<{
        userId: number | null;
        virtualUserIds: number[];
        status: StatusEnum;
      }>
    ) => {
      state.list = state.list.map((user) => {
        if (
          user.id === action.payload.userId ||
          action.payload.virtualUserIds.includes(user.id as number)
        ) {
          user.status = action.payload.status;
        }
        return user;
      });
    },
  },
});

export const teamReducer = slice.reducer;

export const teamActions = slice.actions;
