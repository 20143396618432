import List from "../../common/List/List";
import { Receipt } from "@mui/icons-material";
import { TSectionProps } from "../../types/TSectionProps";
import ManageSubscription from "../../../../../../../modules/stripe/containers/BillingSettings/ManageSubscription";
import TListItemProps from "../../../../../../components/Navigation/ListItem/types/TListItemProps";

const BillingSettingsSection = (props: TSectionProps) => {
  return (
    <List
      title="Billing"
      items={[
        {
          id: "Billing",
          name: "Invoices",
          icon: <Receipt />,
          path: `/billingSettings/invoice`,
          navigateToPath: true,
          onNavigate: props.onClick,
        },
        ManageSubscription() as TListItemProps,
      ]}
    />
  );
};

export default BillingSettingsSection;
