import { Grid, useTheme } from "@mui/material";
import { useAppSelector } from "../../../../../common/hooks/redux";
import TaskViewHeader from "./Header/TaskViewHeader";
import { MAIN_CONTENT_PADDING_BOTTOM } from "../../../../../common/containers/Layout/App/Content/Content";
import StatusForm from "./Status/StatusForm";
import TaskViewMainContent from "./TaskViewMainContent/TaskViewMainContent";
import { EntityTypeEnum } from "../../../../../entities/enums/EntityTypeEnum";
import { TTaskContentProps } from "./types/TTaskContentProps";
import QuickActions from "../../../../../common/modules/taskView/containers/QuickActions/QuickActions";
import AddAttachment from "../../../../../common/modules/taskView/containers/QuickActions/containers/AddAttachment";
import AddLink from "../../../../../common/modules/taskView/containers/QuickActions/containers/AddLink";
import MoveToBacklog from "../../../../../common/modules/taskView/containers/QuickActions/containers/MoveToBacklog";
import DeleteSegment from "../../../../../common/modules/taskView/containers/QuickActions/containers/DeleteSegment";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";
import ConvertInternalType from "../../../../../common/modules/taskView/containers/QuickActions/containers/ConvertInternalType";

const TaskViewMobile = ({
  taskResource,
  task,
  contentHeight,
  isMobileView,
  isPageView,
}: TTaskContentProps) => {
  const theme = useTheme();

  const isSegmentView =
    useAppSelector((state) => state.taskView.viewType) === "segment";

  return (
    <Grid
      container
      minHeight={contentHeight}
      flexDirection="column"
      wrap="nowrap"
    >
      <Grid item>
        <TaskViewHeader
          task={task}
          isMobileView={isMobileView}
          isPageView={isPageView}
        />
      </Grid>

      <Grid
        container
        item
        bgcolor={theme.palette.taskView.mainBackground}
        p="20px 16px 40px"
        pb={`${MAIN_CONTENT_PADDING_BOTTOM}px`}
        flex={1}
        flexDirection="column"
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb="20px"
        >
          <Grid item>{isSegmentView && <StatusForm task={task} />}</Grid>

          <Grid item>
            <QuickActions isMobileView={isMobileView}>
              <AddAttachment
                isMobileView={isMobileView}
                entityType={EntityTypeEnum.TASK}
                entityId={task.taskId}
              />
              <AddLink
                entityType={EntityTypeEnum.TASK}
                entityId={task.taskId}
                projectRef={task.projectRef}
              />
              <MoveToBacklog
                taskId={task.id}
                taskInternalType={task.internalType}
              />
              {[InternalTypeEnum.TASK, InternalTypeEnum.SPIKE].includes(
                task.internalType
              ) && (
                <ConvertInternalType
                  taskId={task.id}
                  taskInternalType={task.internalType}
                />
              )}
              {isSegmentView && <DeleteSegment task={task} />}
            </QuickActions>
          </Grid>
        </Grid>

        <TaskViewMainContent
          resource={taskResource}
          task={task}
          isMobileView={isMobileView}
          isPageView={isPageView}
        />
      </Grid>
    </Grid>
  );
};

export default TaskViewMobile;
