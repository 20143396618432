import { TIsMobileViewProp } from "../../types/TIsMobileViewProp";
import { Grid, Typography, useTheme } from "@mui/material";
import { AttachFile } from "@mui/icons-material";
import Loading from "../../../../../components/Icon/Loading/Loading";
import useAttachmentFormHelper from "../../../../../../modules/attachment/hooks/useAttachmentFormHelper";
import { TEntityTypeProp } from "../../types/TEntityTypeProp";
import { TEntityIdProp } from "../../types/TEntityIdProp";
import QuickActionButton from "../QuickActionButton";
import { TChildren } from "../../../../../types/TChildren";

const Button = ({
  uploadInProgress,
  children,
}: { uploadInProgress: boolean } & TChildren) => {
  return (
    <>
      {!uploadInProgress ? (
        <AttachFile fontSize="small" />
      ) : (
        <Loading size={17} />
      )}
      <Typography fontSize="13px" fontWeight={500}>
        {children}
      </Typography>
    </>
  );
};

export default function AddAttachment(
  props: TIsMobileViewProp & TEntityTypeProp & TEntityIdProp
) {
  const { fileSelectHandler, InputComponent, uploadInProgress } =
    useAttachmentFormHelper(props.entityType, props.entityId);
  const theme = useTheme();

  return (
    <>
      {!props.isMobileView ? (
        <Grid
          container
          item
          border={`1px dashed ${theme.palette.taskView.labelColor}`}
          borderRadius="8px"
          color={theme.palette.taskView.labelColor}
          lineHeight={1}
          p="16px 0"
          alignItems="center"
          justifyContent="center"
          gap="8px"
          sx={{
            cursor: "pointer",
            ":hover": {
              borderStyle: "solid",
            },
          }}
          onClick={(event) => fileSelectHandler(event)}
        >
          <Button uploadInProgress={uploadInProgress}>Drop Attachments</Button>
        </Grid>
      ) : (
        <QuickActionButton onClick={(event) => fileSelectHandler(event)}>
          <Button uploadInProgress={uploadInProgress}>Add Attachment</Button>
        </QuickActionButton>
      )}
      {InputComponent}
    </>
  );
}
