import { ConfirmationDialog } from "../../../../../../common/modules/materialUI";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { taskViewActions } from "../../slices/viewSlice";
import { useConvertTaskToEntityService } from "../../services/useConvertTaskToEntityService";
import { IConvertTaskToEntityRequest } from "../../interfaces/IConvertTaskToEntityRequest";
import { EntityTypeEnum } from "../../../../../../entities/enums/EntityTypeEnum";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { TMoveToBacklogConfirmationProps } from "./types/TMoveToBacklogConfirmationProps";
import { taskActions } from "../../../../slices/taskSlice";
import { IConvertTaskToEntityResource } from "../../interfaces/IConvertTaskToEntityResource";
import { useNavigate } from "react-router-dom";
import { backlogItemUtil } from "../../../../../../common/utils/backlogItemUtil";

const MoveToBacklogConfirmation = ({
  isOnPage,
}: TMoveToBacklogConfirmationProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const navigate = useNavigate();
  const { getValidationErrorAtIndex } = useForm();
  const moveToBacklog = useAppSelector((state) => state.taskView.moveToBacklog);
  const { dispatch: dispatchConvertToBacklogService, isLoading } =
    useConvertTaskToEntityService({ taskId: moveToBacklog?.taskId ?? 0 });
  const closeConfirmationHandler = () => {
    dispatch(taskViewActions.closeMoveToBacklogConfirmation());
  };
  const closeTaskViewDialog = () => {
    // Closing task view dialog
    dispatch(taskActions.clearData());
    dispatch(taskViewActions.reset());

    // Updating boards to reflect the changes
    dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED);
  };
  const redirectToCreatedBacklogItem = (
    convertedEntityResource: IConvertTaskToEntityResource
  ) => {
    navigate(convertedEntityResource.url);
  };

  const successHandler = () => {
    dispatchConvertToBacklogService({
      body: {
        targetEntityType: EntityTypeEnum.BACKLOG_ITEM,
      } as IConvertTaskToEntityRequest,
    })
      .then((resourceData: IConvertTaskToEntityResource) => {
        dispatch(
          systemNotificationActions.open({
            message: {
              type: "withLinks",
              text: "Task is converted to backlog item {link} successfully",
              placeholders: {
                link: {
                  url: backlogItemUtil.urlByRef(resourceData.ref),
                  label: resourceData.ref,
                },
              },
            },
            variant: "success",
          })
        );

        // Redirect to the created backlog item if it's done on a page, or close the task view dialog and trigger
        // task main field update event
        isOnPage
          ? redirectToCreatedBacklogItem(resourceData)
          : closeTaskViewDialog();
      })
      .catch((error: THttpClientError) => {
        if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
        } else if (error.status === 422) {
          const validationFirstError = getValidationErrorAtIndex(0, error);
          if (validationFirstError) {
            dispatch(
              systemNotificationActions.open({
                variant: "error",
                message: validationFirstError,
              })
            );
          }
        } else {
          dispatch(
            systemNotificationActions.open({
              variant: "error",
              message:
                "Failed to convert task to backlog item. Please try again.",
            })
          );
        }
      })
      .finally(() => {
        closeConfirmationHandler();
      });
  };

  if (!moveToBacklog?.openConfirmation) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={true}
      title={"Move to backlog"}
      content="Are you sure you want to move this to the backlog? It might effect your current timeline."
      loading={isLoading}
      closeHandler={() => {
        closeConfirmationHandler();
      }}
      successHandler={successHandler}
    />
  );
};

export default MoveToBacklogConfirmation;
