import { TDescriptionServiceParams } from "./types/TDescriptionServiceParams";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";

export const useDescriptionService = ({
  method,
  entityType,
  entityId,
}: TDescriptionServiceParams) =>
  useHttpClientDispatchable({
    url: `/description/${entityType}/${entityId}`,
    method,
  });
