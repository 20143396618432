import { TBacklogViewProps } from "./types/TBacklogViewProps";
import BacklogViewContent from "./BacklogViewContent";
import LinkModal from "../../../../link/containers/Create/LinkModal";
import BacklogItemViewModal from "../../../../backlog/modules/view/containers/BacklogItemViewModal";
import MoveToTaskDialog from "../../../../backlog/modules/moveToTask/containers/MoveToTaskDialog";
import { Grid } from "@mui/material";
import AddButton from "./AddButton/AddButton";
import CreateBacklogItemModal from "../../../../backlog/modules/create/containers/CreateBacklogItem/CreateBacklogItemModal";

const BacklogView = (props: TBacklogViewProps) => {
  return (
    <>
      <Grid container spacing={3} mb={2} alignItems="center">
        <Grid item>
          <AddButton />
        </Grid>
      </Grid>

      <BacklogViewContent board={props.board} projectAbbr={props.projectAbbr} />

      <BacklogItemViewModal />

      <LinkModal />

      <MoveToTaskDialog isOnViewPage={false} />

      <CreateBacklogItemModal boardRef={props.board.ref!} />
    </>
  );
};

export default BacklogView;
