import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { useAppDispatch } from "../../../common/hooks/redux";
import { teamActions } from "../slices/teamSlice";
import { IUserResource } from "../interfaces/IUserResource";

export const useFetchUserListService = () => {
  const dispatch = useAppDispatch();
  return useHttpClientDispatchable({
    url: `/user`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IUserResource[]) => {
        dispatch(teamActions.setList(data));
      },
    },
  });
};
