import classes from "./Card.module.css";
import { TCardProps } from "./types/TCardProps";
import { useTheme } from "@mui/material";

export default function Card(props: TCardProps) {
  const theme = useTheme();

  return (
    <div className={classes.content}>
      <div
        className={classes["inner-container"]}
        style={{ backgroundColor: theme.palette.landingPageCard.default }}
      >
        <div
          className={classes["inner-content"]}
          style={{ backgroundColor: theme.palette.landingPageCard.paper }}
        >
          <div className={classes.icon}>{props.icon}</div>
          <div className={classes.data}>
            <div>
              <span style={{ fontSize: "19px" }}>{props.title}</span>
            </div>
            <div className={classes.description}>
              <span style={{ fontSize: "14px" }}>{props.description}</span>
            </div>
            <div className={classes.button}>{props.button}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
