import { ListItemIcon, Typography } from "@mui/material";
import { ManageHistory } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { TOfftimeManagementMenuProps } from "./types/TOfftimeManagementMenuProps";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";

const OfftimeManagementMenu = (props?: TOfftimeManagementMenuProps) => {
  return menuItem.make(
    <NavLink to={"/offtime"}>
      <ListItemIcon>
        <ManageHistory />
      </ListItemIcon>
      {props?.label ?? (
        <Typography sx={{ pl: 2 }}>Offtime Management</Typography>
      )}
    </NavLink>
  );
};

export default OfftimeManagementMenu;
