export default function CelebrationIcon() {
  return (
    <img
      src={`${process.env.CDN_URL}/icons/celebration-125x125.png`}
      alt={"celebration"}
      width={110}
      height={110}
    />
  );
}
