import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment/moment";
import { FORMAT_RAW_DATE_ONLY } from "../../../../../common/utils/date";
import { IGuesstimationViewDataResource } from "../../../interfaces/IGuesstimationViewDataResource";
import { IGuesstimationItem } from "../../../../../entities/IGuesstimationItem";

export const timelineDisplayMonths = 2;

const calculateTimelineDisplayFromDate = (
  minDate: IGuesstimationViewDataResource["minDate"]
) =>
  moment(minDate ?? undefined)
    .subtract(timelineDisplayMonths / 2, "month")
    .startOf("day")
    .format(FORMAT_RAW_DATE_ONLY);
const calculateTimelineDisplayToDate = (
  maxDate: IGuesstimationViewDataResource["maxDate"]
) =>
  moment(maxDate ?? undefined)
    .add(timelineDisplayMonths / 2, "month")
    .format(FORMAT_RAW_DATE_ONLY);
const shouldExpandTimelineDisplayFromDate = (
  currentValue: string,
  newValue: IGuesstimationViewDataResource["minDate"]
) => newValue && moment(newValue).isBefore(moment(currentValue));
const shouldExpandTimelineDisplayToDate = (
  currentValue: string,
  newValue: IGuesstimationViewDataResource["maxDate"]
) => newValue && moment(newValue).isAfter(moment(currentValue));

type TInitialState = {
  data: IGuesstimationViewDataResource;
  // Contains all guesstimation items from all loaded pages
  gridItems: IGuesstimationItem[];
  showSkeleton: boolean;
  workingHours: number;
  laneOverlay?: {
    display?: boolean;
    animate?: boolean;
  };
  timelineDisplayFromDate: string;
  timelineDisplayToDate: string;
  filters: {
    showDone: boolean;
  };
};

const initialState: TInitialState = {
  workingHours: 8,
  data: {
    sprints: [],
    guesstimationItems: {},
    page: 1,
    minDate: null,
    maxDate: null,
    users: [],
    nonWorkdays: [],
    loadedItemCount: 0,
  },
  gridItems: [],
  showSkeleton: true,
  timelineDisplayFromDate: calculateTimelineDisplayFromDate(null),
  timelineDisplayToDate: calculateTimelineDisplayToDate(null),
  filters: {
    showDone: false,
  },
};

const slice = createSlice({
  name: "guesstimationView",
  initialState,
  reducers: {
    setWorkingHours: (
      state,
      action: PayloadAction<TInitialState["workingHours"]>
    ) => {
      state.workingHours = action.payload;
    },
    setData: (state, action: PayloadAction<IGuesstimationViewDataResource>) => {
      state.data = {
        ...action.payload,
        loadedItemCount: Object.keys(action.payload.guesstimationItems).length,
        guesstimationItems:
          action.payload.page === 1
            ? action.payload.guesstimationItems
            : {
                ...state.data.guesstimationItems,
                ...action.payload.guesstimationItems,
              },
        users:
          action.payload.page === 1
            ? action.payload.users
            : [...state.data.users, ...action.payload.users].filter(
                (user, index, self) =>
                  index === self.findIndex((u) => u.id === user.id)
              ),
      };

      let guesstimationFirstPhaseArr: IGuesstimationItem[] = [];
      Object.keys(action.payload.guesstimationItems).forEach((key) => {
        guesstimationFirstPhaseArr.push(state.data.guesstimationItems[key][0]);
      });

      state.showSkeleton = false;

      // Concatenating guesstimation items from all loaded pages
      state.gridItems =
        action.payload.page === 1
          ? guesstimationFirstPhaseArr
          : [...state.gridItems, ...guesstimationFirstPhaseArr];

      const newTimelineDisplayFromDate = calculateTimelineDisplayFromDate(
        action.payload.minDate
      );
      const newTimelineDisplayToDate = calculateTimelineDisplayToDate(
        action.payload.maxDate
      );
      if (
        shouldExpandTimelineDisplayFromDate(
          state.timelineDisplayFromDate,
          newTimelineDisplayFromDate
        )
      ) {
        state.timelineDisplayFromDate = newTimelineDisplayFromDate;
      }
      if (
        shouldExpandTimelineDisplayToDate(
          state.timelineDisplayToDate,
          newTimelineDisplayToDate
        )
      ) {
        state.timelineDisplayToDate = newTimelineDisplayToDate;
      }
    },
    updateGuesstimationItem: (
      state,
      action: PayloadAction<IGuesstimationItem>
    ) => {
      state.data.guesstimationItems[action.payload.phaseRef] =
        state.data.guesstimationItems[action.payload.phaseRef].map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
    },
    displayLaneOverlay: (state, action: PayloadAction<boolean>) => {
      !state.laneOverlay && (state.laneOverlay = {});
      state.laneOverlay.display = action.payload;
      !action.payload && (state.laneOverlay.animate = false);
    },
    animateLaneOverlay: (state, action: PayloadAction<boolean>) => {
      !state.laneOverlay && (state.laneOverlay = {});
      state.laneOverlay.animate = action.payload;
    },
    expandTimelineDisplayFromDate(state) {
      state.data.minDate = moment(state.data.minDate)
        .subtract(timelineDisplayMonths / 2, "month")
        .startOf("day")
        .format(FORMAT_RAW_DATE_ONLY);
    },
    expandTimelineDisplayToDate(state) {
      state.data.maxDate = moment(state.data.maxDate)
        .add(timelineDisplayMonths / 2, "month")
        .format(FORMAT_RAW_DATE_ONLY);
    },

    // filters
    toggleShowDone: (state) => {
      state.filters.showDone = !state.filters.showDone;
    },

    reset: () => initialState,
  },
});

export const guesstimationViewReducer = slice.reducer;

export const guesstimationViewActions = slice.actions;
