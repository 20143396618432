import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { ICustomField } from "../../../entities/ICustomField";

export const useFetchCustomFieldsService = () => {
  const response = useHttpClientDispatchable({
    url: "/customField",
    method: RequestMethodEnum.GET,
    callbacks: {
      transformResponse: (data: ICustomField[]) => {
        return data.map((customField) => {
          if (customField.data?.options) {
            customField.data.options = JSON.parse(
              customField.data.options as unknown as string
            );
          }

          return customField;
        });
      },
    },
  });

  return {
    ...response,
    data: response.data as ICustomField[],
  };
};
