import classes from "./LandingPage.module.scss";
import { Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import { useAccessControl } from "../../../accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../accessControl/enums/AbilityEnum";
import StepperHorizontal from "../Stepper/StepperHorizontal";
import StepperVertical from "../Stepper/StepperVertical";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";
import useLandingPageHelper from "../../hooks/useLandingPageHelper";

const LandingPage = () => {
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);
  const { can } = useAccessControl();
  const {
    isLoading,
    needsQuickStart,
    completionState,
    allStepsCompleted,
    isOneStepRemaining,
  } = useLandingPageHelper();

  return (
    <div className={classes.container}>
      <div className={classes.avatar}>
        <img
          src={`${process.env.CDN_URL}/icons/welcoming/landing-page.png`}
          alt=""
          className={classes.rocket}
        />
        {isLoading && (
          <img
            src={`${process.env.CDN_URL}/icons/welcoming/landing-page-loading-arc.png`}
            alt=""
            className={classes.arc}
          />
        )}
      </div>
      <div className={classes.content}>
        {!isLoading &&
          (!needsQuickStart ? (
            can(AbilityEnum.manage) ? (
              <Navigate to={"/projectView"} />
            ) : (
              <Navigate to={"/priorityView"} />
            )
          ) : (
            <>
              <Typography variant="h5" sx={{ mb: 1 }}>
                Welcome to Your Quick Start Guide!
              </Typography>
              <Typography variant="body2" sx={{ mb: 4 }}>
                Let's kick off your journey with a few simple steps:
              </Typography>
              {!matchesMax576Screen && (
                <StepperHorizontal
                  completionState={completionState}
                  allStepsCompleted={allStepsCompleted}
                  isOneStepRemaining={isOneStepRemaining}
                  sx={{ mt: 8 }}
                />
              )}
              {matchesMax576Screen && (
                <StepperVertical
                  completionState={completionState}
                  allStepsCompleted={allStepsCompleted}
                  isOneStepRemaining={isOneStepRemaining}
                  sx={{ mt: 8 }}
                />
              )}
            </>
          ))}
        {isLoading && (
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Loading your experience...
          </Typography>
        )}
      </div>
    </div>
  );
};

export default LandingPage;
