import { env } from "../../../common/utils/env";
import useWindow from "../../../common/hooks/useWindow";
import { useContactSupportService } from "../../user/services/useContactSupportService";

const useContactSupportHandler = () => {
  const { openNewTab } = useWindow();
  const { isLoading, dispatch: dispatchContactSupportService } =
    useContactSupportService();

  const openSupportChat = async () => {
    if (isLoading) {
      return;
    }

    const result = await dispatchContactSupportService();
    openNewTab(`${env.liveChatUrl}?internalSupportHash=${result.hash}`, true);
  };

  return { openSupportChat };
};

export { useContactSupportHandler };
