import { CompareArrows } from "@mui/icons-material";
import { Button } from "../../../../common/modules/materialUI";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { teamActions } from "../../slices/teamSlice";
import ManageRoleMapModal from "./ManageRoleMapModal";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { featureUtil } from "../../../../common/modules/accessControl/utils/featureUtil";

const ManageRoleMap = () => {
  const dispatch = useAppDispatch();
  const isRoleMappingAllowed = useIsFeatureAllowed(FeatureEnum.ROLE_MAPPING);

  return (
    <>
      <Button
        variant="text"
        startIcon={<CompareArrows />}
        onClick={() =>
          isRoleMappingAllowed
            ? dispatch(teamActions.openManageRoleMapModal())
            : dispatch(
                systemNotificationActions.open({
                  message: featureUtil.notAvailableFullText,
                  variant: "warning",
                })
              )
        }
      >
        Role Label Mapping
      </Button>
      <ManageRoleMapModal />
    </>
  );
};

export default ManageRoleMap;
