import { useAppDispatch } from "../../../../../common/hooks/redux";
import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { listViewActions } from "../slices/listViewSlice";
import { IListViewDataRequest } from "../../../interfaces/IListViewDataRequest";
import { IListViewDataResource } from "../../../interfaces/IListViewDataResource";
import { listingActions } from "../../../../../common/modules/listing/slices/listingSlice";

export const useFetchListViewDataService = (props: {
  boardRef: string;
  query: IListViewDataRequest;
}) => {
  const dispatch = useAppDispatch();
  const response = useHttpClientDispatchable({
    url: `/board/${props.boardRef}/listView/data`,
    method: RequestMethodEnum.GET,
    query: props.query,
    events: {
      beforeTransform: (data: IListViewDataResource) => {
        dispatch(listViewActions.setList(data));
        dispatch(listViewActions.setIsSegmentView(!!props.query?.showSegments));
        dispatch(listingActions.setSprintList(data.sprints));
        dispatch(listViewActions.setSprintDataList(data.sprintData));
        dispatch(listViewActions.setHavingBoardUsers(data.havingBoardUsers));
        dispatch(
          listingActions.setFilterableCustomFields(data.filterableCustomFields)
        );
      },
    },
  });

  return {
    ...response,
    data: response.data as IListViewDataResource,
  };
};
