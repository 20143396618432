import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TStripeState } from "./types/TStripeState";
import { IStripeInvoiceResource } from "../interfaces/IStripeInvoiceResource";

const initialState: TStripeState = {
  list: [],
};

const slice = createSlice({
  name: "stripe",
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<IStripeInvoiceResource[]>) => {
      state.list = action.payload;
    },
  },
});

export const stripeReducer = slice.reducer;

export const stripeActions = slice.actions;
