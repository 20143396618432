import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../modules/integrations/modules/MSAuth/config/authConfig";
import MicrosoftAuthButton from "../../../modules/integrations/modules/MSAuth/components/MicrosoftAuthButton/MicrosoftAuthButton";
import { useEffect, useState } from "react";
import { TChildren } from "../../types/TChildren";

const useMSAuth = () => {
  const { instance } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    if (!accessToken) {
      instance.handleRedirectPromise().then((response) => {
        if (!response) {
          return;
        }
        setAccessToken(response?.accessToken);
      });
    }
  }, []);

  const authRedirectHandler = () => {
    instance.loginRedirect({
      ...loginRequest,
    });
  };

  return {
    renderComponent: (props: TChildren) => {
      if (accessToken) {
        return props.children;
      }

      return (
        <MicrosoftAuthButton isLoading={false} onClick={authRedirectHandler} />
      );
    },
    getAccessToken: () => {
      return accessToken;
    },
  };
};

export default useMSAuth;
