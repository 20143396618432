import { TEditorContentProps } from "./types/TEditorContentProps";
import { useTheme } from "@mui/material";
import parse, { Element } from "html-react-parser";
import ResilientImage from "../../../../../../modules/attachment/modules/preview/containers/ResilientImage";
import "./EditorContent.scss";

export default function EditorContent({ html, style }: TEditorContentProps) {
  const theme = useTheme();

  return (
    <div
      style={style}
      className={`editor-content-external ${theme.palette.mode}`}
    >
      {parse(html, {
        replace: (domNode) => {
          if (
            domNode instanceof Element &&
            domNode.name === "img" &&
            domNode.attribs
          ) {
            return <ResilientImage {...domNode.attribs} />;
          }
        },
      })}
    </div>
  );
}
