import { Box, Drawer as DrawerMui } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import Loader from "../../../../../common/containers/Loader/Loader";
import UpdateForm from "./UpdateForm";
import { statusFlowActions } from "../../../slices/statusFlowSlice";

export default function UpdateDrawer() {
  const dispatch = useAppDispatch();
  const open = useAppSelector(
    (state) => state.statusFlow.updateStatus?.openDrawer
  );
  const status = useAppSelector(
    (state) => state.statusFlow.updateStatus?.status
  );

  const closeHandler = () => dispatch(statusFlowActions.closeDrawer());

  return (
    <DrawerMui anchor="right" open={open} onClose={closeHandler}>
      {!status && <Loader />}
      {status && (
        <Box sx={{ width: 300, p: 3 }} role="presentation">
          <UpdateForm status={status} closeHandler={closeHandler} />
        </Box>
      )}
    </DrawerMui>
  );
}
