import { TGroupSectionProps } from "./types/TGroupSectionProps";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { ExpandMore } from "@mui/icons-material";
import VerticalDivider from "../../../../../../common/components/Divider/VerticalDivider";
import { useFetchItemDataService } from "../../services/useFetchItemDataService";
import Loading from "../../../../../../common/components/Icon/Loading/Loading";
import { useEffect, useState } from "react";
import { IBacklogItem } from "../../../../../../entities/IBacklogItem";
import BacklogItemRow from "../BacklogItemRow/BacklogItemRow";
import { useSubscribeEvent } from "../../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { TBacklogItemUpdatedEvent } from "../../../../../backlog/modules/view/containers/types/TBacklogItemUpdatedEvent";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { boardViewActions } from "../../../../slices/boardViewSlice";
import { IBacklogViewDataResource } from "../../interfaces/IBacklogViewDataResource";

const GroupSection = ({
  board,
  projectAbbr,
  expanded,
  yearMonthGroupResource,
  queryParams,
}: TGroupSectionProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [backlogItems, setBacklogItems] = useState<IBacklogItem[] | null>(null);
  const queryParamsString = JSON.stringify(queryParams);

  const { dispatch: dispatchFetchItemDataService, isLoading: isFetchingData } =
    useFetchItemDataService({
      boardRef: board.ref!,
      query: {
        projectAbbr,
        yearMonthGroup: yearMonthGroupResource.yearMonthGroup,
      },
    });

  const fetchItems = () => {
    dispatchFetchItemDataService({
      query: queryParams,
    }).then((backlogItemsResource: IBacklogViewDataResource) => {
      setBacklogItems(backlogItemsResource.backlogItems);
    });
  };

  // If we expand the group, or if the yearMonthGroup is changed (e.g. because of filtering) then re-fetch the items
  useEffect(() => {
    expanded && fetchItems();
    !expanded && setBacklogItems(null);
  }, [expanded, JSON.stringify(yearMonthGroupResource), queryParamsString]);

  useSubscribeEvent(
    EventEnum.ON_BACKLOG_ITEM_UPDATED,
    (backlogItemUpdatedEvent: TBacklogItemUpdatedEvent) => {
      // If backlog item is updated and the group is opened for this backlog item (year month group later can be changed)
      // Then update this open group
      if (
        expanded &&
        [
          backlogItemUpdatedEvent.backlogItem.yearMonthGroup,
          backlogItemUpdatedEvent.initBacklogItem.yearMonthGroup,
        ].includes(yearMonthGroupResource.yearMonthGroup)
      ) {
        fetchItems();
      }
    },
    [expanded, yearMonthGroupResource.yearMonthGroup, queryParamsString]
  );

  return (
    <Accordion
      expanded={expanded}
      sx={{ margin: "0 0 16px 0 !important" }}
      onChange={(_: React.SyntheticEvent, expanded: boolean) => {
        dispatch(
          boardViewActions.setBacklogYearMonthGroupExpanded({
            groupKey: yearMonthGroupResource.yearMonthGroup,
            isExpanded: expanded,
          })
        );
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          // Non expended height
          minHeight: "35px",
          "& .MuiAccordionSummary-content": {
            margin: "0px !important",
          },
          // Expended height
          "&.Mui-expanded": {
            minHeight: "35px !important",
          },
        }}
      >
        <Typography variant="caption">
          {moment(yearMonthGroupResource.yearMonthGroup, "YYYYMM").format(
            "YYYY MMM"
          )}
        </Typography>
        <VerticalDivider sx={{ height: "4px", margin: "9px" }} />
        <Typography variant="caption" component="span">
          {yearMonthGroupResource.itemsCount} item
          {yearMonthGroupResource.itemsCount > 1 ? "s" : ""}
        </Typography>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          padding: "2px !important",
          backgroundColor: theme.palette.cardsBackground.default,
        }}
      >
        {isFetchingData && (
          <Box p={1}>
            <Loading />
          </Box>
        )}

        {!isFetchingData && backlogItems !== null && !backlogItems.length && (
          <Typography variant="caption" p={1}>
            No items found
          </Typography>
        )}

        {!isFetchingData &&
          !!backlogItems?.length &&
          backlogItems.map((backlogItem) => {
            return (
              <BacklogItemRow key={backlogItem.id} backlogItem={backlogItem} />
            );
          })}
      </AccordionDetails>
    </Accordion>
  );
};

export default GroupSection;
