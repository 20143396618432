import { useAppDispatch } from "../../../../../common/hooks/redux";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IGuesstimationReportDataResource } from "../interfaces/IGuesstimationReportDataResource";
import { guesstimationItemReportActions } from "../slices/guesstimationItemReportSlice";
import { useHttpClient } from "../../../../../common/modules/httpClient/hooks/useHttpClient";

export const useFetchGuesstimationReportDataService = (props: {
  boardRef: string;
}) => {
  const dispatch = useAppDispatch();
  const response = useHttpClient({
    url: `/guesstimationItem/${props.boardRef}/reportData`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IGuesstimationReportDataResource[]) => {
        dispatch(guesstimationItemReportActions.setReportData(data));
      },
    },
  });

  return {
    ...response,
    data: response.data as IGuesstimationReportDataResource[],
  };
};
