import { useParams } from "react-router-dom";
import { TBoardParams } from "../../../../containers/Board/types/TBoardParams";
import Title from "../../../../../../common/components/Title/Title";
import EntityFieldValuesForm from "./EntityFieldValuesForm";
import NotFound from "../../../../../../common/components/Page/NotFound/NotFound";
import React from "react";
import Loader from "../../../../../../common/containers/Loader/Loader";
import { BoardTypeEnum } from "../../../../../../entities/columns/board/BoardTypeEnum";
import { useIsFeatureAllowed } from "../../../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../../common/modules/accessControl/enums/FeatureEnum";
import { useGetBoardByRef } from "../../../../hooks/useGetBoardByRef";
import { boardUtil } from "../../../../../../common/utils/boardUtil";

const TaskFieldValuesPage = () => {
  const { boardRef, projectAbbr } = useParams<TBoardParams>();
  const { board, isLoading: isBoardLoading } = useGetBoardByRef(boardRef);
  const isPageAllowedForBoard =
    !!board && boardUtil.isTaskDefaultValuesAvailableForType(board.type);
  const isDefaultValuesAllowed = useIsFeatureAllowed(
    FeatureEnum.TASK_DEFAULT_VALUES
  );
  const entityLabel =
    board?.type === BoardTypeEnum.BACKLOG ? "Backlog Item" : "Task";

  if (!isDefaultValuesAllowed) {
    return <NotFound />;
  }

  return (
    <>
      {isBoardLoading && <Loader />}

      {!isBoardLoading && !isPageAllowedForBoard && <NotFound />}

      {!isBoardLoading && isPageAllowedForBoard && (
        <>
          <Title>{entityLabel} Field Values</Title>
          <EntityFieldValuesForm
            board={board}
            projectAbbr={projectAbbr!}
            entityLabel={entityLabel}
          />
        </>
      )}
    </>
  );
};

export default TaskFieldValuesPage;
