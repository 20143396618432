import { useState, useEffect, useRef } from "react";
import AiSentinelIcon from "./AiSentinelIcon";
import { Dialog, Popper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { aiSentinelActions } from "../../slices/aiSentinelSlice";
import ChatBox from "../ChatBox/ChatBox";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";
import { Button, Tooltip } from "../../../../common/modules/materialUI";
import { featureUtil } from "../../../../common/modules/accessControl/utils/featureUtil";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";

const AiSentinel = () => {
  const [popperMargin, setPopperMargin] = useState<string>("");
  const maxWidth768 = useMedia(QueryEnum.MAX_WIDTH_768);
  const dispatch = useAppDispatch();
  const isAllowed = useIsFeatureAllowed(FeatureEnum.AI_SENTINEL);
  const isOpen = useAppSelector((state) => state.aiSentinel.isOpen);
  const aiSentinelButtonRef = useRef<HTMLButtonElement>(null);
  const projectList = useAppSelector((state) => state.org.init.list);

  const shouldAiRender = projectList.length > 0;

  const updatePopperMargin = () => {
    if (aiSentinelButtonRef.current) {
      const aiSentinelButtonPosition =
        aiSentinelButtonRef.current.getBoundingClientRect();

      setPopperMargin(
        `${aiSentinelButtonPosition.top - 4}px 0 0 ${
          aiSentinelButtonPosition.right - aiSentinelButtonPosition.width / 2
        }px !important`
      );
    }
  };

  useEffect(() => {
    const handleResize = () => {
      updatePopperMargin();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    updatePopperMargin();
  }, [isOpen]);

  useEffect(() => {
    dispatch(aiSentinelActions.toggleAiRendered(shouldAiRender));
  }, [shouldAiRender]);

  if (!shouldAiRender) {
    return null;
  }

  return (
    <>
      <Tooltip
        when={!isAllowed}
        title={featureUtil.notAvailableText}
        followCursor
        withSpanWrapper
      >
        <Button
          ref={aiSentinelButtonRef}
          onClick={() => {
            isAllowed
              ? dispatch(aiSentinelActions.toggleOpen())
              : dispatch(
                  systemNotificationActions.open({
                    message: featureUtil.notAvailableFullText,
                    variant: "warning",
                  })
                );
          }}
          size="small"
          disableFocusRipple={true}
        >
          <AiSentinelIcon size={24} border="none" padding="0" />
        </Button>
      </Tooltip>
      {isOpen &&
        (maxWidth768 ? (
          <Dialog open={isOpen} fullScreen scroll="paper">
            <ChatBox />
          </Dialog>
        ) : (
          <Popper
            open
            anchorEl={document.body}
            placement="right-start"
            sx={{
              position: "fixed !important",
              transform: "translate(-100%, -100%) !important",
              zIndex: 1500,
              width: "90%",
              maxWidth: { sm: 400 },
              margin: popperMargin,
              boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.2)",
              borderRadius: 5,
            }}
          >
            <ChatBox />
          </Popper>
        ))}
    </>
  );
};

export default AiSentinel;
