import { EntityTypeEnum } from "../../entities/enums/EntityTypeEnum";
import { backlogItemUtil } from "./backlogItemUtil";
import { taskUtil } from "./taskUtil";
import { guesstimationItemUtil } from "./guesstimationItemUtil";

export const entityUtil = {
  getUrlByEntityType: (entityType: EntityTypeEnum, ref: string) => {
    switch (entityType) {
      case EntityTypeEnum.GUESSTIMATION_ITEM:
        return guesstimationItemUtil.urlByRef(ref);
      case EntityTypeEnum.BACKLOG_ITEM:
        return backlogItemUtil.urlByRef(ref);
      default:
        return taskUtil.urlByRef(ref);
    }
  },
};
