import { useAppDispatch } from "../../../common/hooks/redux";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { objectiveActions } from "../slices/objectiveSlice";
import { IObjective } from "../../../entities/IObjective";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";

export const useFetchObjectiveListService = () => {
  const dispatch = useAppDispatch();

  return useHttpClientDispatchable({
    url: `/objective`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IObjective[]) =>
        dispatch(objectiveActions.setList(data)),
    },
  });
};
