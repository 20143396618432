import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TLinkListState } from "./types/TLinkListState";
import { IListResource } from "../../../interfaces/IListResource";
import { ILinkList } from "../../../entities/ILinkList";

const initialState: TLinkListState = {
  list: [],
  create: {
    showModal: false,
  },
  update: {
    showModal: false,
    editableRecord: null,
  },
  delete: {
    showConfirmation: false,
    deletableRecord: null,
  },
};

const slice = createSlice({
  name: "linkList",
  initialState,
  reducers: {
    setAutocompleteList: (state, action: PayloadAction<IListResource[]>) => {
      state.autocompleteList = action.payload;
    },
    setList: (state, action: PayloadAction<ILinkList[]>) => {
      state.list = action.payload;
    },
    addToList: (state, action: PayloadAction<ILinkList[]>) => {
      state.list = [...state.list, ...action.payload];
    },
    removeFromList: (state, action: PayloadAction<{ id: number }>) => {
      const linkList = state.list.find(
        (item) => item.id === action.payload.id
      )!;
      state.list = state.list.filter(
        (item) => item.connector !== linkList.connector
      );
    },
    updateList: (
      state,
      action: PayloadAction<{
        id: number;
        description: string;
        linkedDescription: string | null;
        timeSensitive: boolean;
        projectIds?: number[];
      }>
    ) => {
      state.list = state.list.map((item) => {
        if (item.id == action.payload.id) {
          item.description = action.payload.description;
          item.linkedDescription =
            action.payload.linkedDescription || action.payload.description;
          item.timeSensitive = action.payload.timeSensitive;
          item.projectIds =
            action.payload?.projectIds && action.payload?.projectIds?.length > 0
              ? action.payload.projectIds
              : undefined;
        }
        return item;
      });
    },
    openUpdateModal: (state, action: PayloadAction<ILinkList>) => {
      state.update.showModal = true;
      state.update.editableRecord = action.payload;
    },
    closeUpdateModal: (state) => {
      state.update.showModal = false;
      state.update.editableRecord = null;
    },
    openCreateModal: (state) => {
      state.create.showModal = true;
    },
    closeCreateModal: (state) => {
      state.create.showModal = false;
    },
    openDeleteConfirmation: (state, action: PayloadAction<ILinkList>) => {
      state.delete.showConfirmation = true;
      state.delete.deletableRecord = action.payload;
    },
    closeDeleteConfirmation: (state) => {
      state.delete.showConfirmation = false;
      state.delete.deletableRecord = null;
    },
  },
});

export const linkListReducer = slice.reducer;

export const linkListActions = slice.actions;
