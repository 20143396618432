import { TTaskSensorLogoProps } from "./types/TTaskSensorLogoProps";

const TaskSensorLogo = (props: TTaskSensorLogoProps) => {
  const logoUrl = `${process.env.CDN_URL}/assets/tasksensor-logo-${props.colorCombo}-v2-${props.size}.png`;

  return (
    <img
      className={props.className}
      src={logoUrl}
      width={props.width}
      alt="Logo"
    />
  );
};

export default TaskSensorLogo;
