import React, { useEffect, useState, useTransition } from "react";
import { TEstimationFormProps } from "./types/TEstimationFormProps";
import { TEstimationFormAttributes } from "./types/TEstimationFormAttributes";
import { Grid } from "@mui/material";
import {
  Autocomplete,
  TextField,
} from "../../../../../../common/modules/materialUI";
import { enumUtil } from "../../../../../../common/utils/enum";
import { EstimationUomEnum } from "../../../../../../entities/enums/EstimationUomEnum";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TEstimationFormAttributesTransformed } from "./types/TEstimationFormAttributesTransformed";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { TTaskEvent } from "../types/TTaskEvent";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { useTaskSegmentPatchService } from "../../../../services/useTaskSegmentPatchService";
import { IChangeEstimationRequest } from "../../../../interfaces/IChangeEstimationRequest";
import { keyboard } from "../../../../../../common/utils/keyboard";
import { IResizeResource } from "../../../../interfaces/IResizeResource";
import { taskActions } from "../../../../slices/taskSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import Actions from "../Actions/Actions";
import { TEventOnTaskResizeInTaskViewPayload } from "../../../../../../common/modules/eventProvider/types/TEventOnTaskResizeInTaskViewPayload";
import { AggStatusEnum } from "../../../../../../entities/columns/task/AggStatusEnum";
import { taskUtil } from "../../../../../../common/utils/taskUtil";
import DetailsLabel from "../TaskViewDetails/DetailsLabel";

const EstimationForm = ({ task }: TEstimationFormProps) => {
  const isSegmentView =
    useAppSelector((state) => state.taskView.viewType) === "segment";
  const dispatchEvent = useDispatchEvent();
  const dispatch = useAppDispatch();
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const estimationPoints = taskUtil.getEstimationPoints(isSegmentView, task);
  const estimationUom = taskUtil.getEstimationUom(isSegmentView, task);
  const form = useForm<TEstimationFormAttributes>({
    estimationPoints: estimationPoints,
    estimationUom: enumUtil.toListItem(estimationUom),
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [, startTransition] = useTransition();

  const { dispatch: dispatchTaskSegmentPatchService } =
    useTaskSegmentPatchService();

  // Used when switching between tasks/segments
  useEffect(() => {
    form.changeDefaultData({
      estimationUom: enumUtil.toListItem(estimationUom),
      estimationPoints: estimationPoints,
    });
  }, [isSegmentView, task.number, task.segmentCount]);

  useEffect(() => {
    form.isDirty() && setFormOpen(true);
    if (!form.isDirty()) {
      setFormOpen(false);
      form.resetErrors();
    }
  }, [form]);

  const submitHandler = () => {
    if (!formOpen || isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    startTransition(() => {
      const formData =
        form.getTransformed<TEstimationFormAttributesTransformed>((data) => ({
          estimationPoints: data.estimationPoints,
          estimationUom: data.estimationUom?.id,
        }));
      dispatchTaskSegmentPatchService({
        urlPath: `${task.segmentId}/changeEstimation`,
        body: {
          estimationPoints: formData.estimationPoints,
          estimationUom: formData.estimationUom,
        } as IChangeEstimationRequest,
      })
        .then((payload: IResizeResource) => {
          const taskEvent = {
            initTask: task,
            task: payload.task,
          } as TTaskEvent;

          dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED, taskEvent);
          dispatchEvent(EventEnum.ON_TASK_RESIZE_IN_TASK_VIEW, {
            prevSegmentCount: task.segmentCount,
            currentSegmentCount: payload.task.segmentCount,
          } as TEventOnTaskResizeInTaskViewPayload);

          dispatch(taskActions.setTask(taskEvent.task));

          // Always updating form default data from the payload task. Form will be considered as not dirty.
          form.changeDefaultData({
            estimationUom: enumUtil.toListItem(
              taskUtil.getEstimationUom(isSegmentView, payload.task)
            ),
            estimationPoints: taskUtil.getEstimationPoints(
              isSegmentView,
              payload.task
            ),
          });
        })
        .catch((error: THttpClientError) => {
          error.status !== 422 &&
            dispatch(
              systemNotificationActions.open({
                message:
                  "There was a problem with updating the estimation. Please try again.",
                variant: "error",
              })
            );

          form.errorHandler(error);
        })
        .finally(() => setIsSubmitting(false));
    });
  };

  const formDisplayHandler = () => {
    setFormOpen(false);
    form.reset();
  };

  return (
    <>
      <Grid item xs={4}>
        <DetailsLabel>Estimation</DetailsLabel>
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          disabled={
            !isSegmentView ||
            task.statusType === AggStatusEnum.DONE ||
            isSubmitting
          }
          onKeyDown={(event) => keyboard.onEnter(event, submitHandler)}
          form={form}
          name="estimationUom"
          options={enumUtil.toList(EstimationUomEnum)}
          textFieldProps={{
            InputProps: {
              sx: {
                paddingRight: "16px !important",
                "&.Mui-focused.MuiAutocomplete-inputRoot": {
                  paddingRight: "46px !important",
                },
                ":hover, :active": {
                  paddingRight: "40px !important",
                },
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          onKeyDown={(event) => keyboard.onEnter(event, submitHandler)}
          form={form}
          name="estimationPoints"
          disabled={
            !isSegmentView ||
            task.statusType === AggStatusEnum.DONE ||
            isSubmitting
          }
          autoComplete="off"
        />
      </Grid>
      {formOpen && (
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="flex-end"
          sx={{ pt: "5px !important" }}
        >
          <Actions
            submitHandler={submitHandler}
            cancelHandler={formDisplayHandler}
            disabled={isSubmitting}
          />
        </Grid>
      )}
    </>
  );
};

export default EstimationForm;
