import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { useEffect } from "react";
import { guesstimationViewActions } from "../../slices/guesstimationViewSlice";
import ViewSkeleton from "../../../holisticView/containers/View/ViewSkeleton";
import EmptyView from "../../../../../board/containers/EmptyView/EmptyView";
import GuesstimationViewData from "./GuesstimationViewData";
import { TBoardViewProps } from "../../../../../board/types/TBoardViewProps";
import { useGuesstimationViewQueryParams } from "../../hooks/useGuesstimationViewQueryParams";
import { useFetchGuesstimationViewDataService } from "../../services/useFetchGuesstimationViewDataService";
import { useSubscribeEvent } from "../../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { TGuesstimationItemCreatedEvent } from "../../../../../guesstimation/modules/create/containers/CreateItem/types/TGuesstimationItemCreatedEvent";
import { TGuesstimationItemUpdatedEvent } from "../../../../../guesstimation/modules/view/containers/types/TGuesstimationItemUpdatedEvent";
import { TGuesstimationItemDeletedEvent } from "../../../../../guesstimation/modules/view/containers/types/TGuesstimationItemDeletedEvent";
import { IGuesstimationViewDataResource } from "../../../../interfaces/IGuesstimationViewDataResource";
import { useInfiniteScrollPagination } from "../../../../../../common/hooks/useInfiniteScrollPagination";
import { IGuesstimationItem } from "../../../../../../entities/IGuesstimationItem";
import Loading from "../../../../../../common/components/Icon/Loading/Loading";
import { Box } from "@mui/material";

const PAGE_LIMIT = 50;

type TProps = {
  data: IGuesstimationViewDataResource;
  gridItems: IGuesstimationItem[];
  showSkeleton: boolean;
} & TBoardViewProps;

const GuesstimationViewContent = (props: TProps) => {
  const dispatch = useAppDispatch();
  const { data, gridItems, showSkeleton } = props;
  const queryParams = useGuesstimationViewQueryParams({
    projectAbbr: props.projectAbbr,
    limit: PAGE_LIMIT,
  });
  const queryParamsString = JSON.stringify(queryParams);
  const {
    dispatch: dispatchFetchGuesstimationViewDataService,
    isLoading: isGuesstimationViewDataLoading,
  } = useFetchGuesstimationViewDataService({
    boardRef: props.boardRef,
  });
  const hasData = !!gridItems.length;

  const { page, resetPage } = useInfiniteScrollPagination({
    bottomBuffer: 200,
    isLoading: isGuesstimationViewDataLoading,
    stopPageIncrement: data.loadedItemCount < PAGE_LIMIT,
    loadMore: (page: number) => {
      return dispatchFetchGuesstimationViewDataService({
        // Overwriting only pagination
        query: {
          ...queryParams,
          pagination: {
            ...queryParams.pagination,
            page,
          },
        },
      });
    },
  });

  const refreshGridData = () => {
    dispatchFetchGuesstimationViewDataService({
      query: queryParams,
    });
    // Whenever refreshing grid data - resetting page
    resetPage();
  };

  useSubscribeEvent(
    EventEnum.ON_GUESSTIMATION_ITEM_CREATED,
    (_: TGuesstimationItemCreatedEvent) => {
      refreshGridData();
    },
    [queryParamsString, props.boardRef]
  );

  useSubscribeEvent(
    EventEnum.ON_GUESSTIMATION_ITEM_UPDATED,
    (_: TGuesstimationItemUpdatedEvent) => {
      refreshGridData();
    },
    [queryParamsString, props.boardRef]
  );

  useSubscribeEvent(
    EventEnum.ON_GUESSTIMATION_ITEM_DELETED,
    (_: TGuesstimationItemDeletedEvent) => {
      refreshGridData();
    },
    [queryParamsString, props.boardRef]
  );

  useEffect(() => {
    refreshGridData();
  }, [props.boardRef, queryParamsString]);

  useEffect(() => {
    dispatch(
      guesstimationViewActions.setWorkingHours(props.board.workingHours)
    );
  }, [props.boardRef]);

  return (
    <>
      {/*@TODO build own view skeleton maybe*/}
      {showSkeleton && <ViewSkeleton />}

      {/*@TODO build own maybe empty view*/}
      {!showSkeleton && !hasData && <EmptyView {...props} />}

      {!showSkeleton && hasData && (
        <GuesstimationViewData {...props} data={data} />
      )}

      {page > 1 && isGuesstimationViewDataLoading && (
        <Box sx={{ textAlign: "center", pt: "10px" }}>
          <Loading />
        </Box>
      )}
    </>
  );
};

export default GuesstimationViewContent;
