import IntegrationList from "./IntegrationList";
import Title from "../../../common/components/Title/Title";

const IntegrationPage = () => {
  return (
    <div>
      <Title>App Integrations</Title>
      <IntegrationList />
    </div>
  );
};

export default IntegrationPage;
