import Attachment from "../../../../../modules/attachment/containers/Attachment/Attachment";
import { TMainPanelsProps } from "./types/TMainPanelsProps";
import { TTabPanel } from "../../../../../common/modules/taskView/containers/InfoTabs/types/TTabPanel";
import Initiatives from "../Initiatives/Initiatives";
import DetailsForm from "../DetailsForm/DetailsForm";
import AccomplishmentChart from "../../Charts/AccomplishmentChart";
import CostChart from "../../Charts/CostChart";

export default function MainPanels({
  objective,
  entityType,
  entityId,
  isMobileView,
}: TMainPanelsProps) {
  return [
    {
      tabIndex: "initiatives-tab",
      tabLabel: "Initiatives",
      tabComponent: (
        <Initiatives
          objective={objective}
          tabIndex="initiatives-tab"
          isMobileView={isMobileView}
        />
      ),
    },
    ...(isMobileView
      ? [
          {
            tabIndex: "details-tab",
            tabLabel: "Details",
            tabComponent: <DetailsForm objective={objective} />,
          },
        ]
      : []),
    {
      tabIndex: "attachments-tab",
      tabLabel: "Attachments",
      tabComponent: (
        <Attachment
          tabIndex="attachments-tab"
          entityType={entityType}
          entityId={entityId}
          isMobileView={isMobileView}
        />
      ),
    },
    ...(isMobileView
      ? [
          {
            tabIndex: "accomplishment-tab",
            tabLabel: "Accomplishment",
            tabComponent: <AccomplishmentChart objective={objective} />,
          },
          {
            tabIndex: "cost-forecast-tab",
            tabLabel: "Cost Forecast",
            tabComponent: <CostChart objective={objective} />,
          },
        ]
      : []),
  ] as TTabPanel[];
}
