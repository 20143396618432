import { TAssigneesProps } from "./types/TAssigneesProps";
import React from "react";
import { Grid, IconButton, Typography, useTheme } from "@mui/material";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { Add, PersonRemove as PersonRemoveIcon } from "@mui/icons-material";
import usePortalTabActions from "../../../../../task/modules/view/hooks/usePortalTabActions";
import { guesstimationItemViewActions } from "../../slices/viewSlice";
import { IUser } from "../../../../../../entities/IUser";

const Assignees = ({ resource, isMobileView, tabIndex }: TAssigneesProps) => {
  const { CountsPortalComponent, ActionsPortalComponent } =
    usePortalTabActions(tabIndex);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const removeAssigneeClickHandler = (assignee: IUser) => {
    dispatch(
      guesstimationItemViewActions.openRemoveAssigneeConfirmation({
        item: resource.guesstimationItem,
        assignee,
      })
    );
  };

  return (
    <>
      {CountsPortalComponent(
        <>
          {!resource.assignees.length && "Not Assigned"}
          {!!resource.assignees.length &&
            `${resource.assignees.length} Assignee${
              resource.assignees.length > 1 ? "s" : ""
            }`}
        </>
      )}
      {ActionsPortalComponent(
        <Typography
          color="#818692"
          fontSize="13px"
          display="flex"
          alignItems="center"
          gap="4px"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(
              guesstimationItemViewActions.openAddAssigneesDialog({
                item: resource.guesstimationItem,
              })
            );
          }}
        >
          <Add fontSize="small" />
          Add {!isMobileView && "assignees"}
        </Typography>
      )}

      {resource.assignees.length > 0 && (
        <Grid container flexDirection="column" gap="8px">
          {resource.assignees.map((assignee, _) => {
            const color = theme.palette.taskView.labelColor;

            return (
              <Grid
                item
                key={assignee.id}
                px={isMobileView ? 0 : "8px"}
                py="2px"
                display="flex"
                justifyContent="space-between"
                gap="32px"
              >
                <Grid container alignItems="center" spacing={3}>
                  <Grid item>
                    <IconButton
                      size="small"
                      onClick={() => removeAssigneeClickHandler(assignee)}
                    >
                      <PersonRemoveIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography
                      noWrap
                      fontSize="13px"
                      fontWeight={500}
                      sx={{ cursor: "pointer", color }}
                    >
                      {assignee.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default Assignees;
