import { TFilterProps } from "./types/TFilterProps";
import { Divider, Grid } from "@mui/material";
import React, { useEffect } from "react";
import MoreOptionsMenu from "../../../../../common/containers/Menu/MoreOptionsMenu";
import GroupBySprintOption from "../../../modules/listView/containers/Filter/More/GroupBySprintOption";
import Avatar from "../Avatar/Avatar";
import DisplaySplitsOption from "../../../modules/listView/containers/Filter/More/DisplaySplitsOption";
import FinancialForecastMenuItem from "../../FinancialForecast/FinancialForecastMenuItem";
import CapacityPlanner from "../../CapacityPlanner/CapacityPlanner";
import { useBoardUsersService } from "../../../../user/services/useBoardUsersService";
import { useSubscribeEvent } from "../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../common/modules/eventProvider/enums/EventEnum";
import { ITask } from "../../../../../entities/ITask";
import { TTaskEvent } from "../../../../task/modules/view/containers/types/TTaskEvent";
import { useAppSelector } from "../../../../../common/hooks/redux";
import { BoardViewEnum } from "../../../enums/BoardViewEnum";
import ControlSection from "../ControlSection/ControlSection";
import Sorting from "../ControlSection/Sorting/Sorting";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";

const Filter = (props: TFilterProps) => {
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);
  const users = useAppSelector((state) => state.user.userList);
  const { isLoading: usersIsLoading, dispatch: dispatchBoardUsersService } =
    useBoardUsersService({
      query: {
        projectAbbr: props.projectAbbr,
        boardRef: props.boardRef,
      },
    });

  useSubscribeEvent(EventEnum.ON_TASK_CREATED, (_: ITask) => {
    dispatchBoardUsersService();
  });

  useSubscribeEvent(EventEnum.ON_TASK_ASSIGNED_TO_UPDATED, (_: TTaskEvent) => {
    dispatchBoardUsersService();
  });

  useEffect(() => {
    !(users.length > 0) &&
      (props.autoFetchBoardUsers ?? true) &&
      dispatchBoardUsersService();
  }, []);

  return (
    <>
      <ControlSection />
      <Grid container alignItems="center">
        <Grid item xs={2} md={5}>
          {!usersIsLoading && users && <Avatar users={users} />}
        </Grid>
        <Grid
          container
          item
          xs={10}
          md={7}
          alignItems="center"
          justifyContent="flex-end"
          direction="row"
        >
          <Grid item>
            <div id="filters-bottom"></div>
          </Grid>
          {[BoardViewEnum.KANBAN_VIEW, BoardViewEnum.LIST_VIEW].includes(
            props.boardView
          ) &&
            !matchesMax768Screen && (
              <>
                <Grid item>
                  <Sorting />
                </Grid>
                <Grid item>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ height: 10 }}
                  />
                </Grid>
              </>
            )}
          <Grid item>
            <MoreOptionsMenu
              closeOnSelection={false}
              menuItems={[
                FinancialForecastMenuItem(props),
                CapacityPlanner(props),
                DisplaySplitsOption(props),
                GroupBySprintOption(props),
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Filter;
