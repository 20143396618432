import { NavLink, NavLinkProps } from "react-router-dom";
import React from "react";

export default function NotificationLink(props: NavLinkProps) {
  return (
    <NavLink
      target="_blank"
      style={{
        display: "inline",
        textDecoration: "underline",
        color: "inherit",
        fontWeight: "bold",
      }}
      {...props}
    >
      {props.children}
    </NavLink>
  );
}
