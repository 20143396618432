import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IGuesstimationItem } from "../../../../../entities/IGuesstimationItem";

export const usePhaseGuesstimationItemService = () => {
  const response = useHttpClientDispatchable({
    url: "/guesstimationItem/phase",
    method: RequestMethodEnum.POST,
  });

  return {
    ...response,
    data: response.data as IGuesstimationItem,
  };
};
