import { TActionsProps } from "./types/TActionsProps";
import Submit from "./Submit";
import Cancel from "./Cancel";

export default function Actions(props: TActionsProps) {
  return (
    <>
      <Submit {...props} />
      <Cancel {...props} />
    </>
  );
}
