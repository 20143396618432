import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TKanbanViewState } from "./types/TKanbanViewState";
import { IKanbanViewDataResource } from "../../../interfaces/IKanbanViewDataResource";
import { ISprintDataResource } from "../../../interfaces/ISprintDataResource";

const initialState: TKanbanViewState = {
  list: {
    tasks: [],
    users: [],
    sprints: [],
    sprintData: [],
    havingBoardUsers: false,
    filterableCustomFields: [],
  },
  isSegmentView: false,
  showSkeleton: true,
};

const slice = createSlice({
  name: "kanbanView",
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<IKanbanViewDataResource>) => {
      state.list = action.payload;
      state.showSkeleton = false;
    },
    setIsSegmentView: (state, action: PayloadAction<boolean>) => {
      state.isSegmentView = action.payload;
    },
    setSprintDataList: (
      state,
      action: PayloadAction<ISprintDataResource[]>
    ) => {
      state.list.sprintData = action.payload;
    },
    reset: () => initialState,
    setHavingBoardUsers: (state, action: PayloadAction<boolean>) => {
      state.list.havingBoardUsers = action.payload;
    },
  },
});

export const kanbanViewReducer = slice.reducer;

export const kanbanViewActions = slice.actions;
