import Title from "../../../../../common/components/Title/Title";
import Loader from "../../../../../common/containers/Loader/Loader";
import UpdateForm from "./UpdateUser/UpdateForm";
import { useFetchUserService } from "../services/useFetchUserService";

const AccountPage = () => {
  const { data, isLoading } = useFetchUserService();

  return (
    <>
      <Title>User Account</Title>
      {isLoading && <Loader />}
      <UpdateForm isDataLoading={isLoading} data={data} />
    </>
  );
};

export default AccountPage;
