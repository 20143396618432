import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IObjective } from "../../../entities/IObjective";

export const useUpdateObjectiveService = (objectiveId: number) => {
  const response = useHttpClientDispatchable({
    url: `/objective/${objectiveId}`,
    method: RequestMethodEnum.PATCH,
  });

  return {
    ...response,
    data: response.data as IObjective,
  };
};
