import { useAppSelector } from "../../../hooks/redux";
import { AbilityEnum } from "../enums/AbilityEnum";
import { FeatureEnum } from "../enums/FeatureEnum";
import { featureUtil } from "../utils/featureUtil";

export const useAccessControl = () => {
  const { abilities, accessibleRoles, plan } = useAppSelector(
    (state) => state.accessControl
  );

  return {
    acLoaded: !!abilities,
    can: (ability: AbilityEnum) => abilities?.includes(ability),
    hasFeature: (feature: FeatureEnum) =>
      plan && featureUtil.planHasFeature(plan, feature),
    accessibleRoles,
  };
};
