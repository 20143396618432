import React, { useEffect } from "react";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { useFetchBoardIntegrationService } from "../../services/useFetchBoardIntegrationService";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../Board/types/TBoardParams";
import Loader from "../../../../common/containers/Loader/Loader";
import { LinkOff } from "@mui/icons-material";
import { useUnlinkBoardIntegrationService } from "../../services/useUnlinkBoardIntegrationService";
import { boardIntegrationActions } from "../../slices/boardIntegrationSlice";
import { IntegrationEnum } from "../../../../entities/enums/IntegrationEnum";
import SlackDataSnippet from "./Slack/SlackDataSnippet";
import { ISlackData } from "../../../../entities/columns/boardIntegration/ISlackData";
import { IJiraData } from "../../../../entities/columns/boardIntegration/IJiraData";
import { IUnlinkBoardIntegrationRequest } from "../../interfaces/IUnlinkBoardIntegrationRequest";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { IBoardIntegration } from "../../../../entities/IBoardIntegration";
import { useFetchIntegrationMetadataService } from "../../../integrations/services/useFetchIntegrationMetadataService";

const LinkedIntegrationList = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { boardRef } = useParams<TBoardParams>();
  const { isLoading } = useFetchBoardIntegrationService({
    ref: boardRef!,
  });
  const { dispatch: dispatchUnlinkBoardIntegration } =
    useUnlinkBoardIntegrationService({ boardRef: boardRef! });
  const { data: integrationList } = useFetchIntegrationMetadataService();
  const boardIntegrationList = useAppSelector(
    (state) => state.boardIntegration.list
  );

  useEffect(() => {
    return () => {
      dispatch(boardIntegrationActions.clearBoardIntegrationList());
    };
  }, [boardRef]);

  const unlinkBoardIntegrationHandler = (item: IBoardIntegration) => {
    const payload: IUnlinkBoardIntegrationRequest = {
      id: item.id,
    };
    dispatch(boardIntegrationActions.removeBoardIntegration({ id: item.id }));
    dispatchUnlinkBoardIntegration({ body: payload })
      .then(() => {
        const message = successfulFeedbackHandler(item.integration, item.data);
        message &&
          dispatch(
            systemNotificationActions.open({
              message,
              variant: "success",
            })
          );
      })
      .catch(() => {
        dispatch(boardIntegrationActions.addBoardIntegration(item));
        dispatch(
          systemNotificationActions.open({
            message: "Unable to unlink, please try again.",
            variant: "error",
          })
        );
      });
  };

  const successfulFeedbackHandler = (
    integration: IntegrationEnum,
    data: IJiraData | ISlackData
  ) => {
    switch (integration) {
      case IntegrationEnum.SLACK:
        return `Unlink for channel '${
          (data as ISlackData)?.channelName
        }' successful.`;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Grid container>
        <Grid item xs={12} md={6}>
          <List>
            {boardIntegrationList?.map((item, index) => {
              return (
                <ListItem
                  key={index}
                  secondaryAction={
                    <Tooltip title="Unlink integration">
                      <IconButton
                        onClick={(_) => unlinkBoardIntegrationHandler(item)}
                        color="primary"
                      >
                        <LinkOff color="success" />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <ListItemAvatar>
                    {integrationList?.length > 0 && (
                      <img
                        src={
                          integrationList.find(
                            (listItem) =>
                              listItem.integration === item.integration
                          )?.logo[theme.palette.mode]
                        }
                        width={20}
                        alt="Logo"
                      />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      marginRight: "2rem",
                    }}
                    primary={
                      <>
                        {item.integration === IntegrationEnum.SLACK && (
                          <SlackDataSnippet data={item.data as ISlackData} />
                        )}
                      </>
                    }
                    secondary={
                      <Typography component="span" variant="caption">
                        {item.type}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default LinkedIntegrationList;
