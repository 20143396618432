import { useHttpClient } from "../../../common/modules/httpClient/hooks/useHttpClient";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useAppDispatch } from "../../../common/hooks/redux";
import { sprintActions } from "../slices/sprintSlice";
import { ISprintListResource } from "../interfaces/ISprintListResource";

export const useFetchSprintListService = (props: { projectId: number }) => {
  const dispatch = useAppDispatch();

  return useHttpClient({
    url: `/sprint/${props.projectId}/list`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: ISprintListResource) => {
        dispatch(sprintActions.setList(data.list));
        dispatch(sprintActions.setRepresentatives(data.representatives));
      },
    },
  });
};
