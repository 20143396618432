export enum DefaultIconTaskPriorityEnum {
  BLOCKER = "iconLibrary/defaultIcons/TaskPriority/blocker.png",
  CRITICAL = "iconLibrary/defaultIcons/TaskPriority/critical.png",
  HIGH = "iconLibrary/defaultIcons/TaskPriority/high.png",
  NORMAL = "iconLibrary/defaultIcons/TaskPriority/normal.png",
  LOW = "iconLibrary/defaultIcons/TaskPriority/low.png",
  BACKLOG = "iconLibrary/defaultIcons/TaskPriority/backlog.png",
  CRUCIAL = "iconLibrary/defaultIcons/TaskPriority/crucial.png",
  DANGER = "iconLibrary/defaultIcons/TaskPriority/danger.png",
  NICE_TO_HAVE = "iconLibrary/defaultIcons/TaskPriority/nice-to-have.png",
  TRIVIAL = "iconLibrary/defaultIcons/TaskPriority/trivial.png",
}
