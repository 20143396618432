import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { teamActions } from "../../slices/teamSlice";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ManageRoleMapForm from "./ManageRoleMapForm";

const ManageRoleMapModal = () => {
  const isOpen = useAppSelector((state) => state.team.roleMap.displayModal);
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(teamActions.closeManageRoleMapModal());
  };

  return (
    <Dialog open={isOpen} onClose={closeHandler} fullWidth maxWidth="xs">
      <DialogTitle>Role Label Mapping</DialogTitle>
      <DialogContent>
        <ManageRoleMapForm onCancel={closeHandler} />
      </DialogContent>
    </Dialog>
  );
};

export default ManageRoleMapModal;
