import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { teamActions } from "../../slices/teamSlice";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import FinancialDataStoreForm from "./FinancialDataStoreForm";

const FinancialDataModal = () => {
  const { showModal, userId } = useAppSelector(
    (state) => state.team.financialData
  );
  const dispatch = useAppDispatch();

  const closeHandler = () => dispatch(teamActions.closeFinancialDataModal());

  return (
    <Dialog
      open={showModal && !!userId}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>User Financial Data</DialogTitle>
      <DialogContent>
        {!!userId && (
          <FinancialDataStoreForm userId={userId} onCancel={closeHandler} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FinancialDataModal;
