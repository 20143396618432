import Container from "./Container";
import React from "react";
import Item from "./Item";

export default function FeaturesExisting() {
  return (
    <Container>
      <Item description="Speed up batch tasks creation with Ai Sentinel. From raw text and meeting notes as well as user stories to tasks within a second">
        The AI Sentinel
      </Item>
      <Item description="Auto splitting automatically works in case of any collision in the timeline and splits Your task into smaller timeline units">
        Auto Splitting
      </Item>
      <Item description="Auto adjusting all the tasks in the timeline of the user to maintain deadline's integrity">
        Auto Adjusting
      </Item>
      <Item description="Our uniquely crafted view that brings all elements into the user-centric timeline. Designed and virtualized to drop all the boundaries out of Your vision">
        Holistic View
      </Item>
      <Item description="Your encapsulated space to work on specific initiatives where nothing else can interfere. Enjoy the tranquility">
        Initiative Boards
      </Item>
      <Item description="Be in control of Your estimated costs at any stage of Your workflow">
        Financial Forecast
      </Item>
      <Item description="Make Your off-time that automatically adjusts Your timeline expectations where everyone is on the same page">
        Offtime Management
      </Item>
      <Item description="Set days that are considered non-working for the whole team">
        Non-Workday support
      </Item>
      <Item description="Invite Your team members to work with You, send / resend invitations">
        Teams Management / Access Control
      </Item>
      <Item description="Make the organizing process easier by customizing Your set of statuses">
        Status Flow
      </Item>
      <Item description="Our special elements that work with auto-splitting and auto-adjusting to visualize Your expectation changes">
        Auto Splittable Tasks
      </Item>
      <Item description="Give Your tasks additional details to organize Your work with ease">
        Custom Fields
      </Item>
      <Item description="Enter values for repeatative fields once and let our system autopopulate those for any new task that enters the board">
        Task Field Values
      </Item>
      <Item description="The view gives You single access point to individual and to a group of tasks">
        Task View
      </Item>
      <Item description="Use our existing list of priorities or customize it to fit Your needs">
        Task Priority
      </Item>
      <Item description="Use our existing list of task types or customize it to fit Your needs">
        Task Type
      </Item>
      <Item description="Use our existing dependency options or customize it to fit Your needs">
        Task links / dependencies
      </Item>
      <Item description="The view that transcends project boundaries by giving You a personalized list of Your priorities">
        Priority View
      </Item>
      <Item description="You can display Your elements in the Kanban View">
        Kanban View
      </Item>
      <Item description="You can display Your elements in the List View">
        List View
      </Item>
      <Item description="See all Your projects in one general project view with basic statistics about those">
        Project View
      </Item>
      <Item description="Edit, archieve, configure workdays and do much more with Your projects">
        Project Management
      </Item>
      <Item description="Stream Your productive slack notes directly to TaskSensor to turn them into meaningful task blocks">
        Slack integration
      </Item>
    </Container>
  );
}
