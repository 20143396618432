import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { taskPriorityActions } from "../../slices/taskPrioritySlice";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CreateForm from "./CreateForm";

const CreateModal = () => {
  const createState = useAppSelector((state) => state.taskPriority.create);
  const dispatch = useAppDispatch();

  const closeHandler = () => dispatch(taskPriorityActions.closeCreateModal());

  return (
    <Dialog
      open={createState.showModal}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Add Task Priority</DialogTitle>
      <DialogContent>
        <CreateForm onCancel={closeHandler} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateModal;
