import { TCreateFormProps } from "./types/TCreateFormProps";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import useForm from "../../../../common/hooks/useForm/useForm";
import { TCreateFormAttributes } from "./types/TCreateFormAttributes";
import Form from "../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  Checkbox,
  TextField,
} from "../../../../common/modules/materialUI";
import { useCreateCustomFieldService } from "../../services/useCreateCustomFieldService";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { customFieldActions } from "../../slices/customFieldSlice";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { Box, FormControlLabel, Typography } from "@mui/material";
import {
  TypeEnum,
  typeOptions,
} from "../../../../entities/columns/customField/TypeEnum";
import { projectUtil } from "../../../../common/utils/projectUtil";
import { TCreateFormAttributesTransformed } from "./types/TCreateFormAttributesTransformed";
import AddNewOptions from "../AddNewOptions/AddNewOptions";

const CreateForm = (props: TCreateFormProps) => {
  const dispatch = useAppDispatch();
  const form = useForm<TCreateFormAttributes>({
    name: "",
    isRequired: false,
    isMultiple: false,
    isFilterable: false,
    addOption: "",
    options: [],
  });
  const { isLoading, dispatch: dispatchCreate } = useCreateCustomFieldService();
  const projects = useAppSelector((state) => state.project.projectList);
  const selectedType = form.get("type");

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreate({
      body: form.getTransformed<TCreateFormAttributesTransformed>((data) => ({
        ...data,
        projectIds: data.projectIds?.length
          ? data.projectIds.map((item) => item.id)
          : null,
        type: data.type?.id,
        options: data.options?.length ? data.options : null,
      })),
    })
      .then(() => {
        dispatch(customFieldActions.closeCreateModal());
        // Raising "record created event"
        dispatch(customFieldActions.recordCreated());
        dispatch(
          systemNotificationActions.open({
            message: "Custom Field created successfully",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);

        error.status === 403 &&
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Add
          </Button>
        </>
      }
    >
      <TextField form={form} name="name" label="Name" showRequired />

      <Autocomplete
        form={form}
        name="projectIds"
        label={!form.data?.projectIds?.length ? "All projects" : "Projects"}
        multiple
        disabled={!projects.length}
        options={projectUtil.list(projects)}
      />

      <Box display="flex" alignItems="center">
        <FormControlLabel
          control={<Checkbox form={form} name="isRequired" />}
          label={<Typography variant="body2">Required</Typography>}
        />
      </Box>

      <Autocomplete
        form={form}
        name="type"
        label="Type"
        showRequired
        options={typeOptions}
      />

      {selectedType?.id === TypeEnum.OPTIONS && (
        <>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={<Checkbox form={form} name="isMultiple" value="" />}
              label={<Typography variant="body2">Allow Multiple</Typography>}
            />
          </Box>

          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={<Checkbox form={form} name="isFilterable" value="" />}
              label={<Typography variant="body2">Is Filterable</Typography>}
            />
          </Box>

          <AddNewOptions
            form={form}
            label="Options"
            attributeName="options"
            addOptionName="addOption"
          />
        </>
      )}
    </Form>
  );
};

export default CreateForm;
