import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import React from "react";
import { TStepperProps } from "./types/TStepperProps";
import Steps from "../Steps/Steps";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";

const RegisterStepper = (props: TStepperProps) => {
  const matchesMax992Screen = useMedia(QueryEnum.MAX_WIDTH_992);
  const steps = Steps({ ...props });
  return (
    <>
      <Stepper
        nonLinear
        activeStep={props.activeStep}
        sx={{ mb: 3 }}
        orientation={matchesMax992Screen ? "vertical" : "horizontal"}
      >
        {steps.map(({ label }, index) => {
          return (
            <Step key={label} completed={index < props.activeStep}>
              <StepLabel
                sx={{
                  ".MuiStepLabel-labelContainer span": {
                    fontSize: "0.75rem",
                  },
                }}
              >
                {label}
              </StepLabel>
              {matchesMax992Screen && (
                <StepContent>{steps[props.activeStep].content}</StepContent>
              )}
            </Step>
          );
        })}
      </Stepper>
      {!matchesMax992Screen && steps[props.activeStep].content}
    </>
  );
};

export default RegisterStepper;
