import { useParams } from "react-router-dom";
import { TBoardParams } from "./types/TBoardParams";
import BoardTypePicker from "../BoardTypePicker/BoardTypePicker";
import { BoardTypeEnum } from "../../../../entities/columns/board/BoardTypeEnum";
import HolisticBoard from "../HolisticBoard/HolisticBoard";
import { useAppSelector } from "../../../../common/hooks/redux";
import Loader from "../../../../common/containers/Loader/Loader";
import NotFound from "../../../../common/components/Page/NotFound/NotFound";
import BacklogBoard from "../BacklogBoard/BacklogBoard";
import GuesstimationBoard from "../GuesstimationBoard/GuesstimationBoard";

const Board = () => {
  const isProjectListLoading = useAppSelector(
    (state) => state.org.init.isLoading
  );
  const { projectAbbr, boardRef } = useParams<TBoardParams>();
  const board = useAppSelector((state) =>
    state.org.init.list
      .find((data) => data.project.abbr === projectAbbr)
      ?.boards.find((board) => board.ref === boardRef)
  );
  const type = board?.type;

  return !(!isProjectListLoading && !board) ? (
    <>
      {!board && <Loader />}
      {!!board && !type && (
        <BoardTypePicker projectAbbr={projectAbbr!} boardRef={boardRef!} />
      )}
      {!!board &&
        (type === BoardTypeEnum.HOLISTIC ||
          type === BoardTypeEnum.INITIATIVE_MANAGEMENT) && (
          <HolisticBoard
            board={board}
            boardRef={boardRef!}
            projectAbbr={projectAbbr!}
          />
        )}

      {!!board && type === BoardTypeEnum.BACKLOG && (
        <BacklogBoard board={board} projectAbbr={projectAbbr!} />
      )}

      {!!board && type === BoardTypeEnum.GUESSTIMATION && (
        <GuesstimationBoard
          board={board}
          boardRef={boardRef!}
          projectAbbr={projectAbbr!}
        />
      )}
    </>
  ) : (
    <NotFound />
  );
};

export default Board;
