import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { TTaskViewDetails } from "./types/TTaskViewDetails";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import AssignedToForm from "../AssignedTo/AssignedToForm";
import EstimationForm from "../Estimation/EstimationForm";
import StartDateForm from "../StartDate/StartDateForm";
import DueDateForm from "../DueDate/DueDateForm";
import { date, FORMAT_TWO } from "../../../../../../common/utils/date";
import DetailsLabel from "./DetailsLabel";
import AvatarGroup from "../../../../../user/containers/AvatarGroup/AvatarGroup";
import {
  ConfirmationDialog,
  Tooltip,
} from "../../../../../../common/modules/materialUI";
import React, { useState } from "react";
import AddAssigneesButton from "../AssignedTo/AddAssigneesButton";
import RiskStatusIcon from "../../../../../../common/components/Task/components/RiskStatus/RiskStatusIcon";
import { RiskStatusHelper } from "../../../../../../common/components/Task/components/RiskStatus/helpers/RiskStatusHelper";
import { Close } from "@mui/icons-material";
import { useResetTaskSegmentRiskStatusService } from "../../../../services/useResetTaskSegmentRiskStatusService";
import { ITaskPatchResource } from "../../interfaces/ITaskPatchResource";
import { TTaskEvent } from "../types/TTaskEvent";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import RepForm from "../Rep/RepForm";

const TaskViewDetails = (props: TTaskViewDetails) => {
  const { task, resource } = props;
  const userList = resource.userList;

  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const [showRiskStatusResetConfirmation, setShowRiskStatusResetConfirmation] =
    useState<boolean>(false);

  const {
    dispatch: dispatchResetTaskSegmentRiskStatusService,
    isLoading: isResettingRiskStatus,
  } = useResetTaskSegmentRiskStatusService(task.segmentId ?? 0);

  const customs = useAppSelector((state) => state.customs);
  const sprintList = useAppSelector((state) => state.task.resource?.sprints);
  const isSegmentView =
    useAppSelector((state) => state.taskView.viewType) === "segment";
  const users = useAppSelector((state) => state.task.resource?.users) ?? [];
  const theme = useTheme();

  const assigneeUsers = users.filter((user) =>
    task.assignees.includes(user.id)
  );

  const riskStatus = isSegmentView ? task.riskStatus : task.aggRiskStatus;
  const resetRiskStatusHandler = () => {
    dispatchResetTaskSegmentRiskStatusService()
      .then((payload: ITaskPatchResource) => {
        const taskEvent = {
          initTask: task,
          task: {
            ...task,
            riskStatus: payload.task.riskStatus,
            aggRiskStatus: payload.task.aggRiskStatus,
            updatedAt: payload.task.updatedAt,
          },
        } as TTaskEvent;
        dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED, taskEvent);
        dispatchEvent(EventEnum.ON_TASK_STATUS_UPDATED, taskEvent);
      })
      .catch(() => {
        dispatch(
          systemNotificationActions.open({
            message:
              "There was a problem with resetting the risk status. Please try again.",
            variant: "error",
          })
        );
      })
      .finally(() => setShowRiskStatusResetConfirmation(false));
  };
  const createdAt = moment(task.createdAt);
  const updatedAt = moment(task.updatedAt);

  return (
    <Grid container spacing="20px" alignItems="center">
      {task.objective.length > 0 && (
        <>
          <Grid item xs={4}>
            <DetailsLabel>Objective</DetailsLabel>
          </Grid>
          <Grid item xs={8}>
            <Tooltip
              when={task.objective.length > 1}
              title={task.objective.map((initiativeName) => (
                <div>{initiativeName}</div>
              ))}
            >
              <Typography
                color={theme.palette.taskView.textSecondaryColor}
                fontSize="15px"
                fontWeight={500}
              >
                {task.objective[0]}
                {task.objective.length > 1 ? "..." : ""}
              </Typography>
            </Tooltip>
          </Grid>
        </>
      )}

      <Grid item xs={4}>
        <DetailsLabel>Board</DetailsLabel>
      </Grid>
      <Grid item xs={8}>
        <Typography
          color={theme.palette.taskView.textSecondaryColor}
          fontSize="15px"
          fontWeight={500}
        >
          {task.boardName}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <DetailsLabel>{customs.sprintLabel}</DetailsLabel>
      </Grid>
      <Grid item xs={8}>
        <Typography
          color={theme.palette.taskView.textSecondaryColor}
          fontSize="15px"
          fontWeight={500}
        >
          {sprintList?.find((sprint) => sprint.ref === props.task.sprintRef)
            ?.name ?? `Is not in any ${customs.sprintLabel}`}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <DetailsLabel>Assigned To</DetailsLabel>
      </Grid>

      <Grid item xs={8}>
        {isSegmentView ? (
          <AssignedToForm userList={userList} task={task} />
        ) : (
          <>
            <AvatarGroup users={assigneeUsers} count={9} />
            <AddAssigneesButton taskId={task.id} />
          </>
        )}
      </Grid>

      <EstimationForm task={task}></EstimationForm>

      <Grid item xs={4}>
        <DetailsLabel>Risk Status</DetailsLabel>
      </Grid>

      <Grid container item xs={8} alignItems="center" gap="8px">
        {!!riskStatus && (
          <RiskStatusIcon
            placement="left"
            riskStatus={riskStatus}
            style={{ display: "flex" }}
          />
        )}
        <Box position="relative" display="flex" alignItems="center" gap="4px">
          <Typography
            color={theme.palette.taskView.textSecondaryColor}
            fontSize="15px"
            fontWeight={500}
            sx={{
              "::first-letter": {
                textTransform: "uppercase",
              },
            }}
          >
            {RiskStatusHelper.getValue(riskStatus).toLowerCase()}
          </Typography>
          {!!riskStatus && isSegmentView && (
            <>
              <Tooltip title="Reset Risk Status">
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "-6px",
                    right: "-18px",
                    padding: "2px",
                    fontSize: "0.7rem",
                  }}
                  onClick={() => setShowRiskStatusResetConfirmation(true)}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <ConfirmationDialog
                open={showRiskStatusResetConfirmation}
                loading={isResettingRiskStatus}
                title="Reset Risk Status"
                content="Are you sure you want to reset Risk Status?"
                closeHandler={() => setShowRiskStatusResetConfirmation(false)}
                successHandler={() => resetRiskStatusHandler()}
              />
            </>
          )}
        </Box>
      </Grid>

      <StartDateForm task={task} />

      <DueDateForm task={task} />

      <Grid item xs={4}>
        <DetailsLabel>Representative</DetailsLabel>
      </Grid>
      <Grid item xs={8}>
        <RepForm userList={userList} task={task} />
      </Grid>

      {isSegmentView && (
        <>
          <Grid item xs={4}>
            <DetailsLabel>Assigned By</DetailsLabel>
          </Grid>

          <Grid item xs={8}>
            <Typography
              color={theme.palette.taskView.textSecondaryColor}
              fontSize="15px"
              fontWeight={500}
            >
              {userList.find(
                (userListItem) => +userListItem.id === task.assignedBy
              )?.label ?? "AI Sentinel"}
            </Typography>
          </Grid>
        </>
      )}

      {!isSegmentView && (
        <Grid item xs={12}>
          <Typography
            color={theme.palette.taskView.labelColor}
            fontSize="12px"
            fontWeight={700}
          >
            Choose a segment to see details
          </Typography>
        </Grid>
      )}

      <Grid container item mt="16px" gap="4px">
        <Grid item xs={12}>
          <Typography color={theme.palette.taskView.labelColor} fontSize="13px">
            Created {date.format(createdAt, FORMAT_TWO)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography color={theme.palette.taskView.labelColor} fontSize="13px">
            Updated {date.format(updatedAt, FORMAT_TWO)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaskViewDetails;
