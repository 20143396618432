import { TCommentsProps } from "./types/TCommentsProps";
import CreateForm from "../CreateComment/CreateForm";
import CommentList from "./CommentList";
import DeleteCommentDialog from "../Comment/DeleteCommentDialog";
import { IComment } from "../../../../entities/IComment";

const Comments = ({
  entityType,
  entityId,
  onSave,
  isMobileView,
  typeTitle = "comment",
  enableHotKey,
}: TCommentsProps) => {
  return (
    <>
      <CreateForm
        entityType={entityType}
        entityId={entityId}
        onSave={onSave}
        typeTitle={typeTitle}
        enableHotKey={enableHotKey}
      />
      <CommentList
        entityType={entityType}
        entityId={entityId}
        onSave={onSave}
        isMobileView={isMobileView}
      />
      <DeleteCommentDialog
        typeTitle={typeTitle}
        comment={{ entityType: entityType, entityId: entityId } as IComment}
      />
    </>
  );
};

export default Comments;
