import { THeaderSchemaProps } from "./types/THeaderSchemaProps";
import { Grid } from "@mui/material";
import Back from "../../../../../components/Back/Back";

const Desktop = (props: THeaderSchemaProps) => {
  return (
    <Grid container>
      {!props.displayBackButton ? (
        <Grid item xs={10} sx={{ pl: 2 }}>
          {props.registerLeft}
        </Grid>
      ) : (
        <>
          <Grid item xs={2} sx={{ pl: 2 }}>
            <Back />
          </Grid>
          <Grid item xs={8} sx={{ pl: 2 }}>
            {props.registerLeft}
          </Grid>
        </>
      )}
      <Grid item xs={2} display="flex" justifyContent="flex-end">
        {props.registerRight}
      </Grid>
    </Grid>
  );
};

export default Desktop;
