import Loading from "../../../../common/components/Icon/Loading/Loading";
import { Box } from "@mui/material";
import { useAppSelector } from "../../../../common/hooks/redux";
import BreakdownFormData from "./BreakdownFormData";

const BreakdownMessage = () => {
  const { isLoading, data } = useAppSelector(
    (state) => state.aiSentinel.breakdownMessage
  );

  return (
    <Box>
      {isLoading && <Loading />}
      {!isLoading && data?.actions.length && <BreakdownFormData data={data} />}
    </Box>
  );
};

export default BreakdownMessage;
