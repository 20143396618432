import { ListItemIcon, Typography } from "@mui/material";
import { AccountBalance } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";

const BillingSettingsMenu = () => {
  return menuItem.make(
    <NavLink to={"/billingSettings/invoice"}>
      <ListItemIcon>
        <AccountBalance />
      </ListItemIcon>
      <Typography sx={{ pl: 2 }}>Billing Settings</Typography>
    </NavLink>
  );
};

export default BillingSettingsMenu;
