import { Box } from "@mui/material";
import { DataGrid } from "../../../../common/modules/materialUI";
import Loader from "../../../../common/containers/Loader/Loader";
import { Columns } from "./Columns";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { gridClasses } from "@mui/x-data-grid";
import { useFetchLinkListService } from "../../services/useFetchLinkListService";
import { linkListActions } from "../../slices/linkListSlice";
import DeleteConfirmationDialog from "../DeleteLinkList/DeleteLinkListDialog";
import UpdateModal from "../UpdateLinkList/UpdateModal";

const LinkListGrid = () => {
  const projects = useAppSelector((state) => state.project.projectList);
  const linkListState = useAppSelector((state) => state.linkList.list);
  const linkList = linkListState.filter((item) => item.isActive);
  const dispatch = useAppDispatch();
  const { isLoading } = useFetchLinkListService();

  return (
    <Box>
      <UpdateModal />
      <DeleteConfirmationDialog />
      <DataGrid
        columns={Columns({
          onEditButtonClick: (linkList) =>
            dispatch(linkListActions.openUpdateModal(linkList)),
          onDeleteButtonClick: (linkList) =>
            dispatch(linkListActions.openDeleteConfirmation(linkList)),
          projects,
        })}
        getRowHeight={() => "auto"}
        rows={linkList ?? []}
        disableRowSelectionOnClick
        checkboxSelection={false}
        autoHeight={!linkList || !linkList.length}
        loading={linkList === null || isLoading}
        sx={{
          [`& .${gridClasses.row}`]: {
            minHeight: `52px !important`,
          },
        }}
      />
      {isLoading && <Loader />}
    </Box>
  );
};

export default LinkListGrid;
