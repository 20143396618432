export enum DefaultIconTaskTypeEnum {
  CLUSTER = "iconLibrary/defaultIcons/TaskType/cluster.png",
  BOOKMARK = "iconLibrary/defaultIcons/TaskType/bookmark.png",
  TASK = "iconLibrary/defaultIcons/TaskType/task.png",
  IMPROVEMENT = "iconLibrary/defaultIcons/TaskType/improvement.png",
  BUG = "iconLibrary/defaultIcons/TaskType/bug.png",
  BIKE = "iconLibrary/defaultIcons/TaskType/bike.png",
  CAR = "iconLibrary/defaultIcons/TaskType/car.png",
  CODE = "iconLibrary/defaultIcons/TaskType/code.png",
  DOLLAR = "iconLibrary/defaultIcons/TaskType/dollar.png",
  EXCLAMATION = "iconLibrary/defaultIcons/TaskType/exclamation.png",
  FEEDBACK_1 = "iconLibrary/defaultIcons/TaskType/feedback-1.png",
  FEEDBACK_2 = "iconLibrary/defaultIcons/TaskType/feedback-2.png",
  IDEA = "iconLibrary/defaultIcons/TaskType/idea.png",
  IMPROVEMENT_2 = "iconLibrary/defaultIcons/TaskType/improvement-2.png",
  LOCK = "iconLibrary/defaultIcons/TaskType/lock.png",
  MAIL = "iconLibrary/defaultIcons/TaskType/mail.png",
  MINUS = "iconLibrary/defaultIcons/TaskType/minus.png",
  ORDER = "iconLibrary/defaultIcons/TaskType/order.png",
  PACKAGING = "iconLibrary/defaultIcons/TaskType/packaging.png",
  PLUS = "iconLibrary/defaultIcons/TaskType/plus.png",
  QUESTION = "iconLibrary/defaultIcons/TaskType/question.png",
  RESEARCH = "iconLibrary/defaultIcons/TaskType/research.png",
  SPIKE_1 = "iconLibrary/defaultIcons/TaskType/spike-1.png",
  SPIKE = "iconLibrary/defaultIcons/TaskType/spike.png",
  STATEMENT_1 = "iconLibrary/defaultIcons/TaskType/statement-1.png",
  STATEMENT_2 = "iconLibrary/defaultIcons/TaskType/statement-2.png",
  SUB_TASK = "iconLibrary/defaultIcons/TaskType/sub-task.png",
  SUPPORT_1 = "iconLibrary/defaultIcons/TaskType/support-1.png",
  SUPPORT_2 = "iconLibrary/defaultIcons/TaskType/support-2.png",
  SUPPORT_3 = "iconLibrary/defaultIcons/TaskType/support-3.png",
  TASK_2 = "iconLibrary/defaultIcons/TaskType/task-2.png",
  TASKS = "iconLibrary/defaultIcons/TaskType/tasks.png",
}
