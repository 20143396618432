import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import { createPortal } from "react-dom";
import Filter from "./Filter/Filter";
import { Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";

const ControlSection = () => {
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const element = document.getElementById(
      matchesMax768Screen ? "filters-top" : "filters-bottom"
    );
    setTargetElement(element);
  }, [matchesMax768Screen]);

  if (!targetElement) {
    return null;
  }

  return createPortal(
    <Box display="flex" alignItems="center">
      <Filter />
      {!matchesMax768Screen && (
        <Divider orientation="vertical" sx={{ height: 10 }} />
      )}
    </Box>,
    targetElement
  );
};

export default ControlSection;
