import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { DatePicker } from "../../../../../../common/modules/materialUI";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TStartDateFormProps } from "./types/TStartDateFormProps";
import { TStartDateFormAttributes } from "./types/TStartDateFormAttributes";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { IShiftRequest } from "../../../../interfaces/IShiftRequest";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { TTaskEvent } from "../types/TTaskEvent";
import { useTaskSegmentPatchService } from "../../../../services/useTaskSegmentPatchService";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import moment from "moment";
import { taskActions } from "../../../../slices/taskSlice";
import { IShiftWithDataResource } from "../../../../interfaces/IShiftWithDataResource";
import usePrevious from "../../../../../../common/hooks/usePrevious/usePrevious";
import { taskViewActions } from "../../slices/viewSlice";
import { TEventOnTaskResizeInTaskViewPayload } from "../../../../../../common/modules/eventProvider/types/TEventOnTaskResizeInTaskViewPayload";
import { AggStatusEnum } from "../../../../../../entities/columns/task/AggStatusEnum";
import DetailsLabel from "../TaskViewDetails/DetailsLabel";

const StartDateForm = ({ task }: TStartDateFormProps) => {
  const isSegmentView =
    useAppSelector((state) => state.taskView.viewType) === "segment";
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const startDate = !isSegmentView ? task.aggStartDate : task.startDate;

  const form = useForm<TStartDateFormAttributes>({
    startDate: startDate,
  });
  const { dispatch: dispatchTaskSegmentPatchService } =
    useTaskSegmentPatchService();
  const prevDate = usePrevious(form?.data?.startDate);

  useEffect(() => {
    form.set({ startDate: startDate });
  }, [startDate]);

  const submitHandler = (value: Date | null, keyboardInputValue?: string) => {
    const startDate = moment(value ?? keyboardInputValue)
      .startOf("day")
      .format("DD-MM-YYYY HH:mm")
      .toString();

    dispatchTaskSegmentPatchService({
      urlPath: `${task.segmentId}/shift/withData`,
      body: {
        startDate: startDate!,
        assignedTo: task.assignedTo ?? 0,
      } as IShiftRequest,
    })
      .then((payload: IShiftWithDataResource) => {
        const taskEvent = {
          initTask: task,
          task: payload.task,
        } as TTaskEvent;

        // Potential resize is when start date change is causing a collapse event
        dispatchEvent(EventEnum.ON_TASK_RESIZE_IN_TASK_VIEW, {
          prevSegmentCount: task.segmentCount,
          currentSegmentCount: payload.task.segmentCount,
          isPrevSegmentRemoved: payload.task.segmentId !== task.segmentId,
        } as TEventOnTaskResizeInTaskViewPayload);
        // Update task state because we are using ShiftWithDataResource instead of Patch service as in
        // rest of the forms, so we need to update data updatedAt, updatedBy, etc.
        dispatch(taskActions.setTask(payload.task));
        // Because it can collapse, the task view might switch to group view
        dispatch(
          taskViewActions.setViewType(
            payload.task.isSegmentData ? "segment" : "group"
          )
        );
        dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED, taskEvent);
        dispatchEvent(EventEnum.ON_TASK_VIEW_MODE_CHANGED, payload.task);
        form.notDirty();
      })
      .catch((error: THttpClientError) => {
        form.set({ startDate: prevDate! });

        error.status === 406 &&
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
        error.status !== 406 &&
          dispatch(
            systemNotificationActions.open({
              message:
                "There was a problem with updating the start date. Please try again.",
              variant: "error",
            })
          );
      });
  };

  return (
    <>
      <Grid item xs={4}>
        <DetailsLabel>Start Date</DetailsLabel>
      </Grid>
      <Grid item xs={8}>
        <DatePicker
          form={form}
          name="startDate"
          disabled={!isSegmentView || task.statusType === AggStatusEnum.DONE}
          showRequired
          onChange={submitHandler}
        />
      </Grid>
    </>
  );
};

export default StartDateForm;
