import { Box, Typography, useTheme } from "@mui/material";
import { TSectionProps } from "./types/TSectionProps";
import classes from "./Section.module.css";
import { Tooltip } from "../../modules/materialUI";

const Section = (props: TSectionProps) => {
  const theme = useTheme();

  return (
    <Tooltip
      when={!props.displayText}
      title={<Typography variant="caption">{props.text}</Typography>}
      placement="top"
    >
      <Box
        style={{
          color: !props.secondary
            ? theme.palette.pickerPallet.primary
            : theme.palette.pickerPallet.secondary,
          ...props.style,
        }}
        onClick={props.onClick}
        className={classes.content}
        display="flex"
        alignItems="center"
        gap={0.5}
      >
        {props.icon}
        {props.displayText && (
          <Typography variant="subtitle2" fontWeight="bold">
            {props.text}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};

export default Section;
