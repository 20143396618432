import { TUseUpdateTaskTypeServiceProps } from "./types/TUseUpdateTaskTypeServiceProps";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";

export const useUpdateTaskTypeService = ({
  taskTypeId,
}: TUseUpdateTaskTypeServiceProps) => {
  return useHttpClientDispatchable({
    url: `/taskType/${taskTypeId}`,
    method: RequestMethodEnum.PATCH,
  });
};