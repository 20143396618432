import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IEntityFieldDefaultValue } from "../../../entities/IEntityFieldDefaultValue";

export const useFetchEntityFieldDefaultValuesByBoardRefService = () => {
  const response = useHttpClientDispatchable({
    url: `/entityFieldDefaultValue/byBoard/`,
    method: RequestMethodEnum.GET,
  });

  return {
    ...response,
    data: response.data as IEntityFieldDefaultValue[],
  };
};
