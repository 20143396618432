import { GridColDef } from "@mui/x-data-grid";
import { IGuesstimationReportDataResource } from "../../interfaces/IGuesstimationReportDataResource";
import { useRoleMapHelper } from "../../../../../boardView/modules/guesstimationView/hooks/useRoleMapHelper";
import { RoleEnum } from "../../../../../../entities/columns/user/RoleEnum";

export const generateColumns = (
  uniqueRoles: RoleEnum[]
): GridColDef<IGuesstimationReportDataResource>[] => {
  const roleMapHelper = useRoleMapHelper();
  const columns: GridColDef<IGuesstimationReportDataResource>[] = [
    {
      field: "intervalLabel",
      headerName: "Interval",
      width: 200,
    },
  ];

  uniqueRoles.forEach((role) => {
    columns.push({
      field: role,
      headerName: roleMapHelper.getRoleMapLabel(role),
      width: 150,
      type: "number",
    });
  });

  return columns;
};

export const generateRows = (
  data: IGuesstimationReportDataResource[],
  uniqueRoles: string[]
): any[] => {
  return data.map((item) => {
    const row: any = {
      intervalLabel: item.intervalLabel,
    };

    uniqueRoles.forEach((role) => {
      row[role] = 0;
    });

    item.collisionsKpi.forEach((kpi) => {
      row[kpi.userRole] = `-${kpi.count}`;
    });

    return row;
  });
};
