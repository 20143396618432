import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";

export const useDeleteGuesstimationItemService = (props: {
  itemId: number;
}) => {
  return useHttpClientDispatchable({
    url: `/guesstimationItem/${props.itemId}`,
    method: RequestMethodEnum.DELETE,
  });
};
