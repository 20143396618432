import React from "react";
import { DialogContent, Grid } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  Dialog,
  TextField,
} from "../../../../common/modules/materialUI";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { TNeedsAttentionModalProps } from "./types/TNeedsAttentionModalProps";
import Form from "../../../../common/components/Form/Form";
import useForm from "../../../../common/hooks/useForm/useForm";
import { TNeedsAttentionFormAttributes } from "./types/TNeedsAttentionFormAttributes";
import { orgActions } from "../../../org/slices/orgSlice";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { TUpdateFormAttributes } from "../../../project/containers/UpdateProject/types/TUpdateFormAttributes";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useUpdateProjectNeedsAttentionFlagService } from "../../../project/services/useUpdateProjectNeedsAttentionFlagService";

const NeedsAttentionModal = (props: TNeedsAttentionModalProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const isMobileView = useMedia(QueryEnum.MAX_WIDTH_1200);
  const {
    isLoading,
    dispatch: dispatchUpdateProjectNeedsAttentionFlagService,
  } = useUpdateProjectNeedsAttentionFlagService();
  const dispatch = useAppDispatch();
  const { getValidationErrorAtIndex } = useForm();

  const form = useForm<TNeedsAttentionFormAttributes>({
    needsAttentionNote: "",
  });

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchUpdateProjectNeedsAttentionFlagService({
      body: {
        needsAttention: true,
        needsAttentionNote: form.data?.needsAttentionNote,
      } as TUpdateFormAttributes,
      urlPath: props.project.ref,
    })
      .then(() => {
        dispatch(
          orgActions.updateProjectData({
            projectRef: props.project.ref,
            ...props.project,
            needsAttention: true,
          })
        );
        form.notDirty();
        props.closeHandler?.();

        dispatch(
          systemNotificationActions.open({
            message:
              "Your project has been marked as needs attention and all the representatives will be notified.",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        if (error.status === 422) {
          form.errorHandler(error);
        } else {
          dispatch(
            systemNotificationActions.open({
              variant: "error",
              message: "Something went wrong. Please try again.",
            })
          );
        }
      });
  };

  return (
    <Dialog
      closeButtonHandler={props.closeHandler}
      renderCloseButton
      open={true}
      onClose={props.closeHandler}
      fullWidth
      maxWidth={"lg"}
      fullScreen={isMobileView}
    >
      <DialogTitle>Mark as needs attention</DialogTitle>
      <DialogContent>
        <Grid container item mb="30px" flexDirection="column" gap="8px">
          <Form
            onSubmit={submitHandler}
            actionElement={
              <>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  type="submit"
                  loading={isLoading}
                >
                  Save
                </Button>
              </>
            }
          >
            <TextField
              forceFocus={!isTouchDevice}
              form={form}
              name="needsAttentionNote"
              label="Note"
              disabled={isLoading}
              helperText={`An email will be sent to all the representative of this project`}
            />
          </Form>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NeedsAttentionModal;
