import { TNameFormProps } from "./types/TNameFormProps";
import EditableTitleForm from "../../../../../common/modules/taskView/containers/EditableTitleForm/EditableTitleForm";
import { TEditableTitleFormProps } from "../../../../../common/modules/taskView/containers/EditableTitleForm/types/TEditableTitleFormProps";
import useHandleObjectiveFormPatch from "../../../hooks/useHandleObjectiveFormPatch";

const NameForm = ({ objective }: TNameFormProps) => {
  const { dispatchUpdateObjective } = useHandleObjectiveFormPatch({
    objective,
  });

  const changeHandler: TEditableTitleFormProps["onChange"] = (
    name,
    onSuccess,
    onError
  ) => {
    dispatchUpdateObjective({ name }, onSuccess, onError);
  };

  return (
    <EditableTitleForm
      label="Objective"
      name={objective.name}
      onChange={changeHandler}
    />
  );
};

export default NameForm;
