import { TCreateFormProps } from "./types/TCreateFormProps";
import { useAppDispatch } from "../../../../common/hooks/redux";
import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { ISprint } from "../../../../entities/ISprint";
import { useCreateSprintService } from "../../services/useCreateSprintService";
import { sprintActions } from "../../slices/sprintSlice";
import { useUserListService } from "../../../user/services/useUserListService";
import {
  Autocomplete,
  Button,
  DatePicker,
  TextField,
} from "../../../../common/modules/materialUI";
import { listingActions } from "../../../../common/modules/listing/slices/listingSlice";
import { Grid } from "@mui/material";
import { TCreateFormAttributes } from "./types/TCreateFormAttributes";
import { TCreateFormAttributesTransformed } from "./types/TCreateFormAttributesTransformed";
import { IUser } from "../../../../entities/IUser";
import { useOrgProjectData } from "../../../org/hooks/useOrgProjectData";
import { EventProvider } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";

const CreateForm = (props: TCreateFormProps) => {
  const dispatch = useAppDispatch();
  const projectData = useOrgProjectData();
  const projectId = projectData.findProjectByAbbr(props.projectAbbr)?.project
    .id!;
  const form = useForm<TCreateFormAttributes>({
    projectId: projectId,
  });
  const { isLoading, dispatch: dispatchCreateSprint } =
    useCreateSprintService();
  const userListService = useUserListService();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreateSprint({
      body: form.getTransformed<TCreateFormAttributesTransformed>((data) => ({
        ...data,
        rep: data.rep?.id,
      })),
    })
      .then((payload: ISprint) => {
        dispatch(sprintActions.closeCreationModal());
        dispatch(listingActions.addToSprintList(payload));
        props.isSprintPage &&
          dispatch(
            sprintActions.addRepresentative({
              id: +form.data?.rep?.id!,
              name: form.data?.rep?.label!,
            } as IUser)
          );
        props.isSprintPage && dispatch(sprintActions.addToList(payload));
        !props.isSprintPage &&
          EventProvider.dispatch(EventEnum.ON_SPRINT_CREATED);
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Add
          </Button>
        </>
      }
    >
      <TextField form={form} name="name" label="Name" showRequired />

      <Autocomplete
        form={form}
        name="rep"
        label="Representative"
        showRequired
        service={userListService}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            form={form}
            name="startDate"
            label="Start Date"
            showRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            form={form}
            name="dueDate"
            label="Due Date"
            showRequired
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default CreateForm;
