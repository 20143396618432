import { TMenuItemProps } from "../../../../common/containers/Menu/types/TMenuItemProps";
import SignOut from "./SignOut";
import AccountSettings from "./AccountSettings";
import AvatarIcon from "../AvatarIcon/AvatarIcon";
import Menu from "../../../../common/containers/Menu/Menu";
import OfftimeManagementMenu from "../../../offtime/containers/Menu/OfftimeManagementMenu";
import { useAccessControl } from "../../../../common/modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../common/modules/accessControl/enums/AbilityEnum";
import BillingSettingsMenu from "../../../stripe/containers/Menu/BillingSettingsMenu";

const UserMenu = () => {
  const { can } = useAccessControl();
  return (
    <Menu
      sx={{ mt: 1 }}
      button={(clickHandler) => (
        <AvatarIcon clickHandler={clickHandler} tooltip="Account Settings" />
      )}
      menuItems={
        [
          AccountSettings(),
          ...(can(AbilityEnum.financing) ? [BillingSettingsMenu()] : []),
          OfftimeManagementMenu(),
          SignOut(),
        ] as TMenuItemProps[]
      }
    />
  );
};

export default UserMenu;
