import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import { Button, TextField } from "../../../../common/modules/materialUI";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { useAppDispatch } from "../../../../common/hooks/redux";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import { useFetchOrgInitDataService } from "../../../org/services/useFetchOrgInitDataService";
import React from "react";
import { useCreateVirtualUserService } from "../../services/useCreateVirtualUserService";
import { TCreateVirtualUserFormAttributes } from "./types/TCreateVirtualUserFormAttributes";
import { TCreateVirtualUserFormProps } from "./types/TCreateVirtualUserFormProps";
import { Typography } from "@mui/material";
import { teamActions } from "../../slices/teamSlice";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { string } from "../../../../common/utils/string";
import { date } from "../../../../common/utils/date";
import { IUserResource } from "../../interfaces/IUserResource";
import { StatusEnum } from "../../../../entities/columns/user/StatusEnum";
import { RoleEnum } from "../../../../entities/columns/user/RoleEnum";
import { IUser } from "../../../../entities/IUser";

const CreateVirtualUserForm = (props: TCreateVirtualUserFormProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const dispatch = useAppDispatch();
  const form = useForm<TCreateVirtualUserFormAttributes>();
  const { dispatch: dispatchFetchOrgInitDataService } =
    useFetchOrgInitDataService(true);
  const { isLoading, dispatch: dispatchCreateVirtualUser } =
    useCreateVirtualUserService();
  useFetchOrgInitDataService(true);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreateVirtualUser({
      body: form.data,
    })
      .then((response: IUser) => {
        dispatchFetchOrgInitDataService();
        dispatch(
          teamActions.addToList({
            id: response.id,
            name: form.data!.name,
            email: form.data!.email,
            status: StatusEnum.ACTIVE,
            identifier: string.random(10),
            createdAt: date.nowString(),
            isVirtual: true,
            role: RoleEnum.MEMBER,
          } as IUserResource)
        );
        dispatch(teamActions.closeCreateVirtualUserModal());
        dispatch(
          systemNotificationActions.open({
            message: `Virtual member ${form.data!.name} successfully created.`,
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Create
          </Button>
        </>
      }
    >
      <TextField
        forceFocus={!isTouchDevice}
        form={form}
        name="name"
        label="Name"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        showRequired
      />

      <TextField form={form} name="email" label="Email" showRequired />
      <Typography sx={{ color: "warning.main" }} variant="caption">
        To convert later this virtual user to a real team member, please enter a
        valid email address. Verification will not be done at this stage. This
        virtual person will not receive any emails.
      </Typography>
    </Form>
  );
};

export default CreateVirtualUserForm;
