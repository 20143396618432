import useForm from "../../../../../common/hooks/useForm/useForm";
import Form from "../../../../../common/components/Form/Form";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { Button, TextField } from "../../../../../common/modules/materialUI";
import { TOrgSignUpFormAttributes } from "./types/TOrgSignUpFormAttributes";
import { TOrgSignUpFormProps } from "./types/TOrgSignUpFormProps";
import { useInquiryService } from "../../../../user/services/useInquiryService";
import { IOrgInquiry } from "../../../../../entities/IOrgInquiry";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";

const OrgSignUpForm = (props: TOrgSignUpFormProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const form = useForm<TOrgSignUpFormAttributes>();
  const { isLoading, dispatch: dispatchInquiryService } = useInquiryService();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchInquiryService({ body: form.data })
      .then((payload: IOrgInquiry) => {
        props.onNextHandler && props.onNextHandler(payload.uuid);
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <Button
          disabled={isLoading}
          loading={isLoading}
          variant="text"
          type="submit"
          fullWidth
          size="large"
        >
          Next
        </Button>
      }
    >
      <TextField
        forceFocus={!isTouchDevice}
        form={form}
        name="orgName"
        label="Organization Name"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        showRequired
      />

      <TextField
        form={form}
        name="userName"
        label="User Name"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        showRequired
      />

      <TextField
        form={form}
        name="email"
        label="Email"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        showRequired
      />
      <TextField
        form={form}
        name="phone"
        label="Phone Number"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        showRequired
      />
    </Form>
  );
};

export default OrgSignUpForm;
