import { IComment } from "../../../entities/IComment";
import { useAppDispatch } from "../../../common/hooks/redux";
import { commentActions } from "../slices/commentSlice";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { EntityTypeEnum } from "../../../entities/enums/EntityTypeEnum";

export const useCreateCommentService = (props: {
  entityType: EntityTypeEnum;
  entityId: number;
}) => {
  const dispatch = useAppDispatch();
  const response = useHttpClientDispatchable({
    url: `/comment/${props.entityType}/${props.entityId}`,
    method: RequestMethodEnum.POST,
    events: {
      beforeTransform: (data: IComment) =>
        dispatch(commentActions.addComment(data)),
    },
  });

  return {
    ...response,
    data: response.data as IComment,
  };
};
