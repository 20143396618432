import InviteMember from "../../../../../modules/team/containers/Invitation/InviteMember";
import { NavLink } from "react-router-dom";
import Image from "./Card/Image";
import Card from "./Card/Card";
import CardText from "./CardText/CardText";
import { useAccessControl } from "../../../accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../accessControl/enums/AbilityEnum";
import CelebrationIcon from "./CardText/CelebrationIcon";

export default function MemberStep() {
  const { can } = useAccessControl();
  const teamsPageLink = can(AbilityEnum.administrate) ? (
    <NavLink style={{ textDecoration: "underline" }} to="/team">
      Teams
    </NavLink>
  ) : (
    <>Teams</>
  );

  return {
    label: "Invite Team Members",
    content: (
      <Card
        title="Invite your team"
        description="Add your first team member to collaborate with ease."
        button={
          <InviteMember
            buttonProps={{
              variant: "contained",
              children: "Invite",
              size: "small",
            }}
          />
        }
        icon={<Image imageName="members-step-v2" />}
      />
    ),
    contentCompleted: (
      <CardText
        title={
          <>
            Now that you have your first member onboarded, you can invite new
            members from the {teamsPageLink} page.
          </>
        }
        icon={<CelebrationIcon />}
      />
    ),
  };
}
