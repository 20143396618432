import { DragSourceMonitor, useDrag } from "react-dnd";
import { TTaskProps } from "../containers/Task/types/TTaskProps";
import { TDropResult } from "../containers/DropTarget/types/TDropResult";
import { date } from "../../../../../common/utils/date";
import { IShiftRequest } from "../../../../task/interfaces/IShiftRequest";
import { IShiftResource } from "../../../../task/interfaces/IShiftResource";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { holisticViewTaskActions } from "../slices/holisticViewTaskSlice";
import * as React from "react";
import { useState } from "react";
import { holisticViewActions } from "../slices/holisticViewSlice";
import { DRAG_TYPE_TASK_HORIZONTAL } from "../containers/Task/Task";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../../../../board/containers/Board/types/TBoardParams";
import { useUpdateUserLanes } from "./useUpdateUserLanes";
import { EventEnum } from "../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../common/modules/eventProvider";
import { useTaskSegmentPatchService } from "../../../../task/services/useTaskSegmentPatchService";

export const useDragAndDropHelper = (props: TTaskProps) => {
  const { projectAbbr, boardRef } = useParams<TBoardParams>();
  const { dispatch: dispatchUpdateUserLane } = useUpdateUserLanes({
    projectAbbr: projectAbbr!,
    boardRef: boardRef!,
  });
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { dispatch: dispatchTaskSegmentShift, errorHandler } =
    useTaskSegmentPatchService();
  const [showShiftingProgress, setShowShiftingProgress] =
    useState<boolean>(false);

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: DRAG_TYPE_TASK_HORIZONTAL,
      item: props,
      end(item: TTaskProps, monitor) {
        const dropResult = monitor.getDropResult() as TDropResult | null;
        if (!dropResult) {
          return;
        }

        showShiftingProgressHandler(true);

        // Trigger the shift request
        dispatchTaskSegmentShift({
          urlPath: `${dropResult.item.task.segmentId}/shift`,
          body: {
            startDate: `${dropResult.timeTick.date} ${date.getRealWorldTime(
              dropResult.timeTick.hour,
              dropResult.workingHours
            )}`,
            assignedTo: dropResult.userId ?? 0,
            timeTick: dropResult.timeTick,
          } as IShiftRequest,
        })
          .then((data: IShiftResource) => {
            dispatch(
              systemNotificationActions.open({
                message: `Task ${props.task.ref} has been moved to ${dropResult.timeTick.label}`,
                variant: "success",
              })
            );

            dispatchUpdateUserLane().finally(() => {
              showShiftingProgressHandler(false);
            });
            if (data.isReAssigned) {
              dispatchEvent(EventEnum.ON_TASK_ASSIGNED_TO_UPDATED);
            }
          })
          .catch((error: THttpClientError) => {
            errorHandler(error);
            showShiftingProgressHandler(false);
          });

        // Set drop coordinates and task related data, this will be used by other components
        dispatch(
          holisticViewTaskActions.setDropData({
            coords: {
              x: dropResult.timeTick.dropXCoord,
              y: 3,
            },
            taskLaneIndex: dropResult.taskLaneIndex,
            task: props.task,
          })
        );
      },
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  );

  const showShiftingProgressHandler = (showShiftingProgress: boolean) => {
    // This flag is used by other components that want to track the progress
    showShiftingProgress
      ? setTimeout(
          () => dispatch(holisticViewActions.animateUserLaneOverlay(true)),
          10
        )
      : dispatch(holisticViewActions.displayUserLaneOverlay(false));

    dispatch(
      holisticViewTaskActions.showShiftingProgress(showShiftingProgress)
    );
    // This flag is very specifically for this corresponding task that finished dropping
    setShowShiftingProgress(showShiftingProgress);
  };

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>) =>
    dispatch(
      holisticViewTaskActions.setDragStartCoords({
        x: e.clientX,
        y: e.clientY,
      })
    );

  return {
    dragPreview,
    isDragging,
    drag,
    dragStartHandler,
    showShiftingProgress,
  };
};
