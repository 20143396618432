import Section from "./Section/Section";
import { TBoardViewProps } from "../../../../board/types/TBoardViewProps";
import React, { useEffect } from "react";
import KanbanViewSkeleton from "./KanbanViewSkeleton";
import EmptyView from "../../../../board/containers/EmptyView/EmptyView";
import ReactDOM from "react-dom";
import ViewPicker, {
  getViewPickerPortalElement,
} from "../../../containers/ViewPicker/ViewPicker";
import Filter from "../../../containers/Filter/Filter/Filter";
import ViewContainer from "../../../containers/ViewContainer/ViewContainer";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { useSubscribeEvent } from "../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../common/modules/eventProvider/enums/EventEnum";
import { TTaskEvent } from "../../../../task/modules/view/containers/types/TTaskEvent";
import { ITask } from "../../../../../entities/ITask";
import { kanbanViewActions } from "../slices/kanbanViewSlice";
import { BoardViewEnum } from "../../../enums/BoardViewEnum";
import { useKanbanViewQueryParams } from "../hooks/useKanbanViewQueryParams";
import { useFetchKanbanViewDataService } from "../services/useKanbanViewDispatchService";
import { useOrgStatusFlowData } from "../../../../org/hooks/useOrgStatusFlowData";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { FirebaseEventEnum } from "../../../../../common/modules/firebase/hooks/types/FirebaseEventEnum";
import { FeatureEnum } from "../../../../../common/modules/accessControl/enums/FeatureEnum";
import { useFirebaseEventHelper } from "../../../../../common/modules/firebase/hooks/useFirebaseEventHelper";

const KanbanViewContent = (props: TBoardViewProps) => {
  const { logFirebaseEvent } = useFirebaseEventHelper();
  const dispatch = useAppDispatch();
  const { displaySplits: showSegments } = useAppSelector(
    (state) => state.boardView.listView
  );
  const {
    list: data,
    showSkeleton,
    isSegmentView,
  } = useAppSelector((state) => state.kanbanView);
  const queryParams = useKanbanViewQueryParams({
    projectAbbr: props.projectAbbr,
    showSegments,
  });
  const queryParamsString = JSON.stringify(queryParams);
  const { statuses } = useOrgStatusFlowData(props.board.projectRef);

  const { dispatch: dispatchFetchKanbanViewDataService } =
    useFetchKanbanViewDataService({
      boardRef: props.boardRef,
      query: queryParams,
    });
  const hasData = !!data?.tasks.length;
  const viewPickerPortalElement = getViewPickerPortalElement();

  useSubscribeEvent(
    EventEnum.ON_TASK_CREATED,
    (_: ITask) => {
      dispatchFetchKanbanViewDataService({
        query: queryParams,
      });
    },
    [queryParamsString]
  );

  useSubscribeEvent(
    EventEnum.ON_TASK_MAIN_DATA_UPDATED,
    (_: TTaskEvent, callback) => {
      dispatchFetchKanbanViewDataService({
        query: queryParams,
      }).finally(() => callback?.());
    },
    [queryParamsString]
  );

  useEffect(() => {
    // Do not fetch data on first render if this component wasn't yet mounted. Default data will be fetched in another useEffect.
    dispatchFetchKanbanViewDataService({
      query: queryParams,
    });
  }, [queryParamsString]);

  useEffect(() => {
    logFirebaseEvent(FirebaseEventEnum.FEATURE_ACCESS, {
      feature: FeatureEnum.KANBAN_VIEW,
    });

    return () => {
      dispatch(kanbanViewActions.reset());
    };
  }, []);

  return (
    <>
      {viewPickerPortalElement &&
        ReactDOM.createPortal(
          <ViewPicker
            board={props.board}
            filterElement={
              <Filter
                projectAbbr={props.projectAbbr}
                boardRef={props.boardRef}
                renderFinancialForecastControl={false}
                renderDisplaySplits={true}
                renderGroupBySprint={false}
                boardView={BoardViewEnum.KANBAN_VIEW}
              />
            }
            havingBoardUsers={data.havingBoardUsers}
          />,
          viewPickerPortalElement
        )}

      {showSkeleton && <KanbanViewSkeleton />}

      {!showSkeleton && !hasData && <EmptyView {...props} />}

      {!showSkeleton && hasData && (
        <>
          <ViewContainer boardRef={props.boardRef}>
            <div
              style={{
                overflowX: "auto",
                display: "flex",
              }}
            >
              <DndProvider backend={HTML5Backend}>
                {statuses.map((status, index) => {
                  const tasks = data.tasks.filter(
                    (item) => item.status === status.name
                  );
                  return (
                    <Section
                      key={index}
                      status={status}
                      tasks={tasks}
                      users={data.users}
                      showSegments={isSegmentView}
                    />
                  );
                })}
              </DndProvider>
            </div>
          </ViewContainer>
        </>
      )}
    </>
  );
};

export default KanbanViewContent;
