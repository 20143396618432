import TListProps from "../../../../../../components/Navigation/List/types/TListProps";
import TListItemProps from "../../../../../../components/Navigation/ListItem/types/TListItemProps";
import ListComponent from "../../../../../../components/Navigation/List/List";
import ListItem from "../ListItem/ListItem";

const List = (props: TListProps) => {
  return (
    <ListComponent
      loading={props.loading}
      title={props.title}
      action1Element={props.action1Element}
      action1ClickHandler={props.action1ClickHandler}
    >
      {props.items &&
        props.items.map((item: TListItemProps) => (
          <ListItem {...item} key={item.id} />
        ))}
      {props.children}
    </ListComponent>
  );
};

export default List;
