import { Typography } from "@mui/material";
import { useAppDispatch } from "../../../../hooks/redux";

export default function Congratulate() {
  const dispatch = useAppDispatch();

  return (
    <>
      <Typography sx={{ mt: 2, mb: 1 }}>
        All steps completed - you&apos;re finished
      </Typography>
      {/*<Button onClick={() => dispatch(landingPageActions.done())}>*/}
      {/*  Done*/}
      {/*</Button>*/}
    </>
  );
}
