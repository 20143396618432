import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { TConfirmationDialogProps } from "./types/TConfirmationDialogProps";
import MuiDialog from "@mui/material/Dialog";
import { Button } from "../../index";

const ConfirmationDialog = (props: TConfirmationDialogProps) => {
  return (
    <MuiDialog
      open={props.open}
      onClose={props.closeHandler}
      PaperProps={{ sx: { position: "fixed", top: 100 } }}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeHandler} disabled={props.loading}>
          {props.disagreeText ?? "No"}
        </Button>
        <Button
          loading={props.loading}
          onClick={props.successHandler}
          autoFocus
          disabled={props.loading}
        >
          {props.confirmText ?? "Yes"}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

export default ConfirmationDialog;
