import TaskInfoTabsContainer from "../../../../../../common/modules/taskView/containers/InfoTabs/InfoTabs";
import CustomFieldSection from "../../../../../customField/containers/CustomFieldSection/CustomFiledSection";
import { TBacklogItemInfoTabsProps } from "./types/TBacklogItemInfoTabsProps";
import { EntityTypeEnum } from "../../../../../../entities/enums/EntityTypeEnum";
import { useUpdateBacklogItemService } from "../../services/useUpdateBacklogItemService";
import { useState } from "react";
import useFetchCustomFieldData from "../../../../../customField/hooks/useFetchCustomFieldData";
import MainPanels from "../../../../../../common/modules/taskView/containers/InfoTabs/MainPanels";

export default function BacklogItemInfoTabs({
  backlogItem,
  isMobileView,
}: TBacklogItemInfoTabsProps) {
  const { dispatch: dispatchUpdateBacklogItemService } =
    useUpdateBacklogItemService(backlogItem.ref);
  const [havingCustomFields, setHavingCustomFields] = useState(false);

  // In case of backlog there are no details and custom fields are also options
  // that's why fetching outside of component and prevent component to auto fetch itself
  const { customFieldsResource } = useFetchCustomFieldData(
    backlogItem.boardRef,
    isMobileView,
    setHavingCustomFields
  );

  return (
    <TaskInfoTabsContainer
      mainPanels={MainPanels({
        additionalPanels: [
          ...(isMobileView && havingCustomFields
            ? [
                {
                  tabIndex: "details-tab",
                  tabLabel: "Details",
                  tabComponent: (
                    <CustomFieldSection
                      autoFetch={false}
                      boardRef={backlogItem.boardRef}
                      dispatchUpdateService={dispatchUpdateBacklogItemService}
                      customFieldValues={backlogItem.customFieldValues}
                      isMobileView={isMobileView}
                      resource={customFieldsResource}
                    />
                  ),
                },
              ]
            : []),
        ],
        entityType: EntityTypeEnum.BACKLOG_ITEM,
        entityId: backlogItem.id,
        projectRef: backlogItem.projectRef,
        isMobileView: isMobileView,
      })}
      storageKey="unpinned-task-view-tabs"
      isMobileView={isMobileView}
    />
  );
}
