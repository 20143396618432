import { TAttachmentIdProps } from "./types/TAttachmentIdProps";
import Loading from "../../../../../common/components/Icon/Loading/Loading";
import { useAttachmentPreviewUrlService } from "../services/useAttachmentPreviewUrlService";

export default function Image({ attachmentId }: TAttachmentIdProps) {
  const { isLoading, data } = useAttachmentPreviewUrlService({
    attachmentId: attachmentId,
  });

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && data && (
        <img
          src={data.previewUrl}
          alt="attachment"
          style={{ maxWidth: "100%", maxHeight: "95%" }}
        />
      )}
    </>
  );
}
