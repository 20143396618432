import { ITask } from "../../../entities/ITask";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";

export const useAssignService = (props: { taskSegmentId: number }) => {
  const response = useHttpClientDispatchable({
    url: `/task/segment/${props.taskSegmentId}/assign`,
    method: RequestMethodEnum.PATCH,
  });

  return {
    ...response,
    data: response.data as ITask,
  };
};
