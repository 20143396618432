import { Box } from "@mui/material";
import { gridClasses, GridColDef } from "@mui/x-data-grid";
import { useFetchGuesstimationReportDataService } from "../../services/useFetchGuesstimationReportDataService";
import { useAppSelector } from "../../../../../../common/hooks/redux";
import DataGrid from "../../../../../../common/modules/materialUI/components/DataGrid/DataGrid";
import Loader from "../../../../../../common/containers/Loader/Loader";
import { IGuesstimationReportDataResource } from "../../interfaces/IGuesstimationReportDataResource";
import { generateColumns, generateRows } from "./Columns";
import { RoleEnum } from "../../../../../../entities/columns/user/RoleEnum";

type TProps = {
  boardRef: string;
};

const ReportGrid = (props: TProps) => {
  const { isLoading } = useFetchGuesstimationReportDataService({
    boardRef: props.boardRef,
  });
  const data = useAppSelector((state) => state.guesstimationItemReport.data);

  const getUniqueRoles = (
    data: IGuesstimationReportDataResource[]
  ): RoleEnum[] => {
    const rolesArray: RoleEnum[] = [];

    data.forEach((item) => {
      item.collisionsKpi.forEach((kpi) => {
        if (!rolesArray.includes(kpi.userRole)) {
          rolesArray.push(kpi.userRole);
        }
      });
    });

    return rolesArray;
  };

  const uniqueRoles = getUniqueRoles(data);
  const columns: GridColDef<IGuesstimationReportDataResource>[] =
    generateColumns(uniqueRoles);
  const rows = generateRows(data, uniqueRoles);

  return (
    <Box>
      <DataGrid
        columns={columns}
        getRowHeight={() => "auto"}
        getRowId={(row) => row.intervalLabel}
        rows={rows ?? []}
        disableRowSelectionOnClick
        checkboxSelection={false}
        autoHeight={!data || !data.length}
        loading={data === null || isLoading}
        sx={{
          [`& .${gridClasses.row}`]: {
            minHeight: `52px !important`,
          },
        }}
      />
      {isLoading && <Loader />}
    </Box>
  );
};

export default ReportGrid;
