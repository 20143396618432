import { useState } from "react";

/**
 * Fixing issue of dnd changing monitor.isOver() for true>false>true
 * https://github.com/react-dnd/react-dnd/issues/3489#issuecomment-1292896279
 */
const useDragOverHelper = () => {
  const [isOver, setIsOver] = useState<boolean>(false);

  const dragEnterHandler = () => setIsOver(true);

  const dragLeaveHandler = () => setIsOver(false);

  const dragDropHandler = () => setIsOver(false);

  return {
    isOver,
    dragOverHandlers: {
      onDragEnter: dragEnterHandler,
      onDragLeave: dragLeaveHandler,
      onDrop: dragDropHandler,
    },
  };
};

export default useDragOverHelper;
