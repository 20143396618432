import DOMPurify from "dompurify";

/**
 * Use input / output handler
 * Helps with purifying html input and preparing/fetching html output
 */
export default function useIOHandler() {
  return {
    sanitize: (html?: string) => {
      if (!html) {
        return;
      }

      // Hook to mark <a> tags with target="_blank" before sanitization
      DOMPurify.addHook("beforeSanitizeElements", function (node) {
        // Check if the node is an anchor tag with target="_blank"
        if (node.tagName === "A" && node.getAttribute("target") === "_blank") {
          // Use a custom data attribute to mark the node
          node.setAttribute("data-keep-target-blank", "true");
        }
      });

      DOMPurify.addHook("afterSanitizeAttributes", function (node) {
        // Check if the node is an anchor tag that was marked earlier
        if (
          node.tagName === "A" &&
          node.getAttribute("data-keep-target-blank") === "true"
        ) {
          // Set the target attribute to _blank
          node.setAttribute("target", "_blank");
          // Ensure it also has rel="noopener noreferrer" for security
          node.setAttribute("rel", "noopener noreferrer");
          // Clean up by removing the custom data attribute
          node.removeAttribute("data-keep-target-blank");
        }
      });

      return DOMPurify.sanitize(html);
    },
  };
}
