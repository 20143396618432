import { string } from "./string";

export const array = {
  /**
   * - Merge items to string by space
   * - Replace all the spaces by one space
   * -- \s: matches any whitespace symbol: spaces, tabs, and line breaks
   * -- +: match one or more of the preceding tokens (referencing \s)
   * -- g: the g at the end indicates iterative searching throughout the full string
   * - Trim start and end spaces
   */
  toClassName: (data: string[]): string =>
    data.join(" ").replace(/\s+/g, " ").trim(),

  groupBy: (array: any[], key: string) => {
    return array.reduce((result, currentItem) => {
      const keyValue = currentItem[key];
      (result[keyValue] = result[keyValue] || []).push(currentItem);
      return result;
    }, {} as Record<string, any[]>);
  },

  intersect: (array1: any[], array2: any[]) => {
    const array2Set = new Set(array2);

    return array1.filter((value) => array2Set.has(value));
  },

  sortByColumn: <T>(data: T[], column: string) => {
    return [...data].sort((a, b) => {
      // Capitalize the first letter of the column so that the sorting is case-insensitive
      const columnA = string.capitalize(
        (a as { [key: string]: string })[column]
      );
      const columnB = string.capitalize(
        (b as { [key: string]: string })[column]
      );

      if (columnA < columnB) {
        return -1;
      }

      if (columnA > columnB) {
        return 1;
      }

      return 0;
    });
  },
};
