import { useAppDispatch, useAppSelector } from "../../../common/hooks/redux";
import { useGetListService } from "../services/useGetListService";
import { INetwork } from "../../../entities/INetwork";
import { networkingActions } from "../slices/networkingSlice";
import { Fragment, useEffect } from "react";
import { useSubscribeEvent } from "../../../common/modules/eventProvider";
import { EventEnum } from "../../../common/modules/eventProvider/enums/EventEnum";
import { Grid, Typography } from "@mui/material";
import ListItem from "./ListItem";
import moment from "moment/moment";

type TProps = {
  timezone: "America/Los_Angeles";
};

export default function List({ timezone }: TProps) {
  const dispatch = useAppDispatch();
  const list = useAppSelector((state) => state.networking.list);

  const { dispatch: dispatchGetListService } = useGetListService();

  const fetchListHandler = () => {
    dispatchGetListService()
      .then((resource: INetwork[]) =>
        dispatch(networkingActions.setList(resource))
      )
      .catch((error) => console.error("Error on fetching the list", error));
  };

  useEffect(() => {
    fetchListHandler();
  }, []);

  useSubscribeEvent(EventEnum.ON_NETWORK_UPDATED, fetchListHandler);

  const listByDays: {
    [key: string]: { list: INetwork[]; tagCount: { [key: string]: number } };
  } = {};
  list.forEach((item) => {
    const date = moment(item.createdAt).tz(timezone).format("YYYY-MM-DD");
    if (!listByDays[date]) {
      listByDays[date] = { list: [], tagCount: {} };
    }
    listByDays[date].list.push(item);
    item.tags?.forEach((tag) => {
      if (!listByDays[date].tagCount[tag]) {
        listByDays[date].tagCount[tag] = 0;
      }
      listByDays[date].tagCount[tag]++;
    });
  });

  return (
    <>
      {Object.keys(listByDays).map((key) => (
        <Grid key={key} container spacing={2} mb={4}>
          <Grid item xs={12}>
            <Typography variant="h5">{key}</Typography>
            <Typography variant="caption">
              Count: {listByDays[key].list.length}
            </Typography>
            {Object.keys(listByDays[key].tagCount).map((tag) => (
              <Fragment key={`${key}-${tag}`}>
                <br />
                <Typography key={`${tag}-${key}`} variant="caption">
                  {tag}: {listByDays[key].tagCount[tag]} (
                  {(
                    (100 * listByDays[key].tagCount[tag]) /
                    listByDays[key].list.length
                  ).toFixed(2)}
                  %)
                </Typography>
              </Fragment>
            ))}
          </Grid>
          {listByDays[key].list.map((item) => (
            <Grid item xs={6} lg={3} key={item.id}>
              <ListItem item={item} />
            </Grid>
          ))}
        </Grid>
      ))}
    </>
  );
}
