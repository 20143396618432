import { IconTypeEnum } from "../../../entities/columns/orgIconLibrary/IconTypeEnum";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IOrgIconLibrary } from "../../../entities/IOrgIconLibrary";

export const useIconLibraryListService = (props: {
  iconType: IconTypeEnum;
}) => {
  const response = useHttpClientDispatchable({
    url: `/iconLibrary/${props.iconType}/list`,
    method: RequestMethodEnum.GET,
  });

  return {
    ...response,
    data: response.data as IOrgIconLibrary[] | null,
  };
};
