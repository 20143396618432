import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../config/authConfig";
import { MsalProvider } from "@azure/msal-react";
import SignInRedirectButton from "./SignInRedirectButton";
import { TSignLayout } from "../types/TSignLayout";

const SignInLayout = (props: TSignLayout) => {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <SignInRedirectButton isLogin={props.isLogin} />
    </MsalProvider>
  );
};

export default SignInLayout;
