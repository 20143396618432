import { TBacklogItemContentProps } from "./types/TBacklogItemContentProps";
import BacklogItemViewMobile from "./BacklogItemViewMobile";
import BacklogItemViewDesktop from "./BacklogItemViewDesktop";

const BacklogItemContent = (props: TBacklogItemContentProps) => {
  return props.isMobileView ? (
    <BacklogItemViewMobile {...props} />
  ) : (
    <BacklogItemViewDesktop {...props} />
  );
};

export default BacklogItemContent;
