import { TCreateFormProps } from "./types/TCreateFormProps";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import useForm from "../../../../common/hooks/useForm/useForm";
import { TCreateFormAttributes } from "./types/TCreateFormAttributes";
import { useCreateTaskTypeService } from "../../services/useCreateTaskTypeService";
import Form from "../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  TextField,
} from "../../../../common/modules/materialUI";
import { projectUtil } from "../../../../common/utils/projectUtil";
import { IconTypeEnum } from "../../../../entities/columns/orgIconLibrary/IconTypeEnum";
import { TCreateFormAttributesTransformed } from "./types/TCreateFormAttributesTransformed";
import { taskTypeActions } from "../../slices/taskTypeSlice";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../common/modules/eventProvider";
import { ITaskType } from "../../../../entities/ITaskType";
import IconLibraryPickerFormElement from "../../../iconLibrary/containers/IconLibraryPickerFormElement/IconLibraryPickerFormElement";

const CreateForm = (props: TCreateFormProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const form = useForm<TCreateFormAttributes>({
    name: "",
    iconFilePath: null,
  });
  const { isLoading, dispatch: dispatchCreate } = useCreateTaskTypeService();
  const projects = useAppSelector((state) => state.org.init.list).map(
    (projectWithBoard) => projectWithBoard.project
  );

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreate({
      body: form.getTransformed<TCreateFormAttributesTransformed>((data) => ({
        ...data,
        projectIds: data.projectIds?.length
          ? data.projectIds.map((item) => item.id)
          : null,
      })),
    })
      .then((createdEntity: ITaskType) => {
        dispatch(taskTypeActions.closeCreateModal());
        dispatchEvent(EventEnum.ON_TASK_TYPE_CREATED, createdEntity);
        dispatch(
          systemNotificationActions.open({
            message: "Task Type is created successfully",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Add
          </Button>
        </>
      }
    >
      <TextField form={form} name="name" label="Name" showRequired />

      <Autocomplete
        form={form}
        name="projectIds"
        label={!form.data?.projectIds?.length ? "All projects" : "Projects"}
        multiple
        disabled={!projects.length}
        options={projectUtil.list(projects)}
      />

      <IconLibraryPickerFormElement
        form={form}
        name="iconFilePath"
        iconType={IconTypeEnum.TASK_TYPE}
        label="Icon"
        showRequired
      />
    </Form>
  );
};

export default CreateForm;
