import { Box, useTheme } from "@mui/material";
import { TCircularProgressBarProps } from "./types/TCircularProgressBarProps";
import CircularProgressBarTitle from "./CircularProgressBarTitle";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

const CircularProgressBar = (props: TCircularProgressBarProps) => {
  const theme = useTheme();

  const progressBarSize = props.size || 40;
  const strokeColor =
    props.color || theme.palette.circularProgressBar.strokeColor;

  return (
    <Box
      position="relative"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      gap="12px"
    >
      <Box
        position="relative"
        height={`${progressBarSize}px`}
        width={`${progressBarSize}px`}
      >
        <CircularProgress
          size={progressBarSize}
          variant="determinate"
          value={100}
          sx={{
            color: theme.palette.circularProgressBar.strokeBackgroundColor,
          }}
        />

        <CircularProgress
          size={progressBarSize}
          variant="determinate"
          value={props.value}
          sx={{
            color: strokeColor,
            position: "absolute",
            top: 0,
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
        />

        <Box
          height="100%"
          width="100%"
          position="absolute"
          top="0"
          left="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgressBarTitle progressBarSize={progressBarSize}>
            {`${Math.round(props.value)}%`}
          </CircularProgressBarTitle>
        </Box>
      </Box>

      {props.title && (
        <CircularProgressBarTitle
          progressBarSize={progressBarSize}
          fontSize={3}
        >
          {props.title}
        </CircularProgressBarTitle>
      )}
    </Box>
  );
};

export default CircularProgressBar;
