import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClient } from "../../../../../common/modules/httpClient/hooks/useHttpClient";
import { IUser } from "../../../../../entities/IUser";

export const useFetchUserService = () => {
  const response = useHttpClient({
    url: `/user/account`,
    method: RequestMethodEnum.GET,
  });

  return {
    ...response,
    data: response.data as IUser,
  };
};
