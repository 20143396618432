import { ConfirmationDialog } from "../../../../common/modules/materialUI";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { useDeleteObjectiveService } from "../../services/useDeleteObjectiveService";
import { objectiveActions } from "../../slices/objectiveSlice";

const DeleteConfirmationDialog = () => {
  const { showConfirmation, deletableRecord } = useAppSelector(
    (state) => state.objective.delete
  );
  const dispatch = useAppDispatch();
  const { isLoading, dispatch: dispatchDelete } = useDeleteObjectiveService({
    objectiveId: +deletableRecord?.id!,
  });

  const closeConfirmationHandler = () =>
    dispatch(objectiveActions.closeDeleteConfirmation());

  const successHandler = () => {
    dispatch(objectiveActions.closeDeleteConfirmation());

    dispatchDelete()
      .then(() => {
        dispatch(
          objectiveActions.removeFromList({ id: +deletableRecord?.id! })
        );
        dispatch(
          systemNotificationActions.open({
            message: `Objective is deleted successfully`,
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        dispatch(
          systemNotificationActions.open({
            message: `The "${deletableRecord?.name}" cannot be deleted. Please try again.`,
            variant: "error",
          })
        );
      });
  };

  return (
    <ConfirmationDialog
      open={showConfirmation}
      title={`Delete Objective`}
      content={`Are you sure you want to delete the objective "${deletableRecord?.name}"?`}
      loading={isLoading}
      closeHandler={closeConfirmationHandler}
      successHandler={successHandler}
    />
  );
};

export default DeleteConfirmationDialog;
