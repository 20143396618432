import { TUseHttpClientDispatchable } from "./types/TUseHttpClientDispatchable";
import { useHttpClientCore } from "./useHttpClientCore";

export const useHttpClientDispatchable: TUseHttpClientDispatchable = (
  props
) => {
  return useHttpClientCore({
    ...props,
    ...{ autoDispatch: false, updateState: true },
  });
};
