import { ImgHTMLAttributes, useState } from "react";
import usePreviewUrlHelper, {
  emptyPixelImage,
} from "../hooks/usePreviewUrlHelper";
import { string as stringUtil } from "../../../../../common/utils/string";

export default function ResilientImage(
  props: ImgHTMLAttributes<HTMLImageElement>
) {
  const [originalSrc, setOriginalSrc] = useState<string | undefined>(props.src);
  const [src, setSrc] = useState<string | undefined>(props.src);
  const { refreshAttachmentPreviewUrl, resetFailedRetrialsCount } =
    usePreviewUrlHelper();

  const errorHandler = () => {
    setSrc(emptyPixelImage);
    refreshAttachmentPreviewUrl(originalSrc!)
      .then((previewUrl) => {
        setSrc(previewUrl);
        setOriginalSrc(previewUrl);
      })
      .catch((_) => {});
  };

  // This can be a string or an object because of saved data from the editor
  const style =
    typeof props.style === "string"
      ? stringUtil.styleStringToObject(props.style)
      : props.style;

  return (
    <img
      onError={errorHandler}
      onLoad={(e) => {
        resetFailedRetrialsCount(src);
        (e.target as HTMLImageElement).style.visibility = "visible";
      }}
      {...props}
      src={src}
      // Visibility is set to hidden to prevent the image from flickering when the initial src is broken
      style={{ ...style, visibility: "hidden" }}
    />
  );
}
