import { Box, Typography, useTheme } from "@mui/material";
import React, { ForwardedRef, forwardRef, Fragment } from "react";
import classes from "./LaneItemHorizontalLarge.module.scss";
import { NavLink } from "react-router-dom";
import { StateHelper } from "../../../../../../common/components/Task/components/State/helpers/StateHelper";
import { link } from "../../../../../../common/utils/link";
import LaneItemHorizontal from "./LaneItemHorizontal";
import AvatarGroup from "../../../../../user/containers/AvatarGroup/AvatarGroup";
import { pointConverter } from "../../../../../../common/utils/pointConverter";
import { guesstimationItemUtil } from "../../../../../../common/utils/guesstimationItemUtil";
import { THorizontalItemProps } from "./types/THorizontalItemProps";
import VerticalDividerComponent from "../../../../../../common/components/Divider/VerticalDivider";
import { TChildren } from "../../../../../../common/types/TChildren";
import { Tooltip } from "../../../../../../common/modules/materialUI";
import { useRoleMapHelper } from "../../hooks/useRoleMapHelper";
import StateIcon from "../../../../../../common/components/Task/components/State/StateIcon";

const numberOfCollisionsToShow = 2;

const VerticalDivider = () => (
  <VerticalDividerComponent sx={{ height: "2px" }} />
);

const MetadataElement = (props: { label: string } & TChildren) => (
  <Typography variant="caption">
    {props.label}: <strong>{props.children}</strong>
  </Typography>
);

const LaneItemHorizontalLarge = forwardRef(
  (props: THorizontalItemProps, ref: ForwardedRef<HTMLDivElement>) => {
    const theme = useTheme();
    const { guesstimationItem } = props;
    const roleMapHelper = useRoleMapHelper();
    const users =
      props.users.filter((user) =>
        guesstimationItem.assignees?.includes(user.id)
      ) ?? [];

    // 0.7 is 70% of the card to show the users, 30 is for the state icon, 5 is for matching the real width (e.g. on the board 1 ts point is 195, not 200)
    const pixelsForUsers = Math.floor(
      pointConverter.oneTSPointToPixels * guesstimationItem.tsPoints * 0.7 -
        30 -
        5
    );
    const pixelsPerUser = 19;
    const showUsersNum = Math.floor(pixelsForUsers / pixelsPerUser);
    const leftUsersNum =
      users.length - showUsersNum > 0 ? users.length - showUsersNum : 0;
    const pushAsideClass = StateHelper.willDisplayState(
      guesstimationItem.statusType
    )
      ? classes["push-aside"]
      : "";

    return (
      <LaneItemHorizontal
        {...props}
        ref={ref}
        className={`${props.className} ${classes.container}`}
        contentClassName={classes.content}
        style={{
          ...{
            backgroundColor: theme.palette.brightCardBackground.default,
            boxShadow: theme.palette.brightCardShadowColor.primary,
          },
          ...props.style,
        }}
        contentStyle={{
          backgroundColor: theme.palette.brightCardBackground.paper,
          color: theme.palette.brightCardBackground.main,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "fit-content",
            position: "sticky",
            left: "30px",
            marginRight: "3rem",
          }}
        >
          <StateIcon
            className={classes["state-icon"]}
            statusType={props.guesstimationItem.statusType}
            status={props.guesstimationItem.status}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <NavLink
                to={guesstimationItemUtil.urlByRef(guesstimationItem.ref)}
                className={classes.ref}
                onClick={link.anchorClickHandler}
                style={{
                  color: "inherit",
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                }}
              >
                {`${guesstimationItem.phaseRef}${
                  props.groupSize === 1 ? "" : "-" + props.phaseIndex
                }`}
              </NavLink>
              <div className={`${classes.name}`}>{guesstimationItem.name}</div>
            </div>
            <div className={`${classes["main-section"]} ${pushAsideClass}`}>
              <div>
                {!!props.users.length && (
                  <Typography variant="caption">
                    <AvatarGroup
                      users={users}
                      avatarSize={19}
                      count={showUsersNum}
                      tooltipCallback={(user) =>
                        `${user.name} ${
                          guesstimationItem.userOffDays &&
                          Object.keys(guesstimationItem.userOffDays).includes(
                            `${user.id}`
                          )
                            ? " | " +
                              guesstimationItem.userOffDays[user.id] +
                              " days off"
                            : ""
                        }`
                      }
                      isColorErrorCallback={(user) =>
                        !!guesstimationItem.collisions?.some(
                          (collison) => collison.userId === user.id
                        )
                      }
                      isColorWarningCallback={(user) =>
                        guesstimationItem.userOffDays &&
                        Object.keys(guesstimationItem.userOffDays).includes(
                          `${user.id}`
                        )
                      }
                    />
                    {leftUsersNum > 0 && (
                      <span
                        style={{
                          marginLeft: "12px",
                          fontSize: "0.6875rem",
                          height: "1rem",
                        }}
                      >
                        +{leftUsersNum}
                      </span>
                    )}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*3rd Row*/}
        <Box display="flex" justifyContent="flex-end">
          {/*Collisions Info*/}
          <Box
            sx={{ paddingRight: "5px" }}
            display="flex"
            gap={1}
            alignItems="center"
            position={"sticky"}
            right={"-20px"}
            marginLeft={"5rem"}
          >
            {guesstimationItem.estimatedResources && (
              <>
                <Tooltip
                  title={
                    <Typography variant="caption">
                      Assignees/ Estimated Resource Units
                    </Typography>
                  }
                  withSpanWrapper
                >
                  <MetadataElement label="Resources">
                    <span>{guesstimationItem.assignees?.length ?? 0}</span> /{" "}
                    <span>{guesstimationItem.estimatedResources}</span>
                  </MetadataElement>
                </Tooltip>
                <VerticalDivider />
              </>
            )}

            {guesstimationItem.estimatedResources && (
              <>
                <Tooltip
                  title={
                    <Typography variant="caption">
                      Total Per-Person Effort / Estimated Resource Units
                      <br />
                      <br />
                      Total Per-Person Effort: Assigned Resources x Working Days
                      - Person Off Days
                      <br />
                      <br />
                      Estimated Resource Units: Estimated Resources Count x
                      Working Days
                    </Typography>
                  }
                  withSpanWrapper
                >
                  <MetadataElement label="Effort units">
                    <span>{guesstimationItem.allocatedResourceDays}</span> /{" "}
                    <span>{guesstimationItem.estimatedResourceDays}</span>
                  </MetadataElement>
                </Tooltip>
                <VerticalDivider />
              </>
            )}

            <Tooltip
              title={
                <Typography variant="caption">
                  Number of working days available in the interval / Total days
                  in the interval
                </Typography>
              }
              withSpanWrapper
            >
              <MetadataElement label="Workdays">
                {guesstimationItem.workingDaysCount} /{" "}
                {guesstimationItem.tsPoints}
              </MetadataElement>
            </Tooltip>

            {!!guesstimationItem.collisionsOverview?.length && (
              <>
                <VerticalDivider />
                <Tooltip
                  when={
                    guesstimationItem.collisionsOverview.length >
                    numberOfCollisionsToShow
                  }
                  withSpanWrapper
                  title={
                    <Typography>
                      {guesstimationItem.collisionsOverview.map(
                        (collisionOverview, index) => {
                          return (
                            <Fragment key={index}>
                              <span>
                                {roleMapHelper.getRoleMapLabel(
                                  collisionOverview.userRole
                                )}{" "}
                                -{collisionOverview.count}
                              </span>
                              <br />
                            </Fragment>
                          );
                        }
                      )}
                    </Typography>
                  }
                >
                  <MetadataElement label="Collisions">
                    {guesstimationItem.collisionsOverview
                      .slice(0, numberOfCollisionsToShow)
                      .map((collisionOverview, index) => {
                        return (
                          <Fragment key={index}>
                            <span>
                              {roleMapHelper.getRoleMapLabel(
                                collisionOverview.userRole
                              )}{" "}
                              -{collisionOverview.count}
                            </span>
                            {guesstimationItem.collisionsOverview!.length -
                              1 !==
                              index && <span>, </span>}
                          </Fragment>
                        );
                      })}
                    {guesstimationItem.collisionsOverview.length >
                      numberOfCollisionsToShow && <>...</>}
                  </MetadataElement>
                </Tooltip>
              </>
            )}
          </Box>
        </Box>
      </LaneItemHorizontal>
    );
  }
);
export default LaneItemHorizontalLarge;
