import { TTaskResourceProp } from "./types/TTaskResourceProp";
import moment from "moment";
import { Typography } from "@mui/material";
import React from "react";

const DueDate = (props: TTaskResourceProp) => {
  return (
    <Typography variant="caption">
      Due at <b>{moment(props.task.dueDate).format("MMM D, YYYY")}</b>
    </Typography>
  );
};

export default DueDate;
