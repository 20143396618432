import { TTaskSensorIconProps } from "./types/TTaskSensorIconProps";

const TaskSensorIcon = (props: TTaskSensorIconProps) => {
  const iconUrl = `${process.env.CDN_URL}/assets/tasksensor-icon-${props.colorCombo}-v2-${props.size}.png`;

  return (
    <img
      className={props.className}
      src={iconUrl}
      width={props.width}
      alt="Icon"
    />
  );
};

export default TaskSensorIcon;
