import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { ITaskSegmentsResource } from "../interfaces/ITaskSegmentsResource";

export const useGetTaskSegmentsService = (props: { taskId: number }) => {
  const response = useHttpClientDispatchable({
    url: `/task/${props.taskId}/segment`,
    method: RequestMethodEnum.GET,
  });

  return {
    ...response,
    data: response.data as ITaskSegmentsResource,
  };
};
