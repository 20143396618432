import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IOrgInquiry } from "../../../entities/IOrgInquiry";

export const useInquiryService = () => {
  const response = useHttpClientDispatchable({
    url: "/org/inquiry",
    method: RequestMethodEnum.POST,
  });

  return {
    ...response,
    data: response.data as IOrgInquiry,
  };
};
