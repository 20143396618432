import Title from "../../../../common/components/Title/Title";
import OfftimeForm from "./OfftimeForm";
import { HeaderRegistryEnum } from "../../../../common/modules/registry/enums/HeaderRegistryEnum";
import OfftimeList from "./OfftimeList";
import useRegisterHeader from "../../../../common/modules/registry/hooks/useRegisterHeader";
import UserPicker from "./UserPicker";
import { useState } from "react";
import { useAccessControl } from "../../../../common/modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../common/modules/accessControl/enums/AbilityEnum";

const OfftimePage = () => {
  const { can } = useAccessControl();
  const [userId, setUserId] = useState<number | undefined>(undefined);
  useRegisterHeader(HeaderRegistryEnum.headerBackButtonOnly, []);

  return (
    <>
      <Title>Offtime Management</Title>
      {can(AbilityEnum.manage) && (
        <UserPicker onChange={(userId) => setUserId(userId)} />
      )}
      <OfftimeForm userId={userId} />
      <OfftimeList userId={userId} />
    </>
  );
};

export default OfftimePage;
