import { TUseHttpClient } from "./types/TUseHttpClient";
import { useHttpClientCore } from "./useHttpClientCore";

export const useHttpClient: TUseHttpClient = (props) => {
  // Extract dispatch from result into variable "dispatch" and everything else into "response"
  const { dispatch, ...response } = useHttpClientCore({
    ...props,
    ...{ autoDispatch: true, updateState: true },
  });

  return response;
};
