import { TOrgPasswordProps } from "../SignUp/types/TOrgPasswordProps";
import OrgPassword from "../Password/OrgPassword";

const OrgPasswordStep = (props: TOrgPasswordProps) => {
  return {
    label: "Password",
    content: <OrgPassword {...props} />,
  };
};

export default OrgPasswordStep;
