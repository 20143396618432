import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { DatePicker } from "../../../../../../common/modules/materialUI";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TStartDateFormProps } from "./types/TStartDateFormProps";
import { TStartDateFormAttributes } from "./types/TStartDateFormAttributes";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import moment from "moment";
import usePrevious from "../../../../../../common/hooks/usePrevious/usePrevious";
import { AggStatusEnum } from "../../../../../../entities/columns/task/AggStatusEnum";
import { TGuesstimationItemUpdatedEvent } from "../types/TGuesstimationItemUpdatedEvent";
import DetailsLabel from "../../../../../task/modules/view/containers/TaskViewDetails/DetailsLabel";
import {
  IShiftGuesstimationItemServiceRequest,
  useShiftGuesstimationItemService,
} from "../../services/useShiftGuesstimationItemService";
import { IGuesstimationItemDetailsResource } from "../../interfaces/IGuesstimationItemDetailsResource";
import { guesstimationItemViewActions } from "../../slices/viewSlice";

const StartDateForm = ({ guesstimationItem }: TStartDateFormProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const startDate = guesstimationItem.startDate;

  const form = useForm<TStartDateFormAttributes>({
    startDate: startDate,
  });
  const { dispatch: dispatchShiftGuesstimationItemService } =
    useShiftGuesstimationItemService(guesstimationItem.id);
  const prevDate = usePrevious(form?.data?.startDate);

  useEffect(() => {
    form.set({ startDate: startDate });
  }, [startDate]);

  const submitHandler = (value: Date | null, keyboardInputValue?: string) => {
    const startDate = moment(value ?? keyboardInputValue)
      .startOf("day")
      .format("YYYY-MM-DD")
      .toString();

    dispatchShiftGuesstimationItemService({
      body: {
        startDate: startDate!,
      } as IShiftGuesstimationItemServiceRequest,
    })
      .then((payload: IGuesstimationItemDetailsResource) => {
        dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_UPDATED, {
          initGuesstimationItem: guesstimationItem,
          guesstimationItem: payload.guesstimationItem,
        } as TGuesstimationItemUpdatedEvent);

        dispatch(guesstimationItemViewActions.setDetailsResource(payload));

        form.notDirty();
      })
      .catch((error: THttpClientError) => {
        form.set({ startDate: prevDate! });

        if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        }
        error.status === 406 &&
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
        error.status !== 406 &&
          dispatch(
            systemNotificationActions.open({
              message:
                "There was a problem with updating the start date. Please try again.",
              variant: "error",
            })
          );
      });
  };

  return (
    <>
      <Grid item xs={4}>
        <DetailsLabel>Start Date</DetailsLabel>
      </Grid>
      <Grid item xs={8}>
        <DatePicker
          form={form}
          name="startDate"
          disabled={guesstimationItem.statusType === AggStatusEnum.DONE}
          showRequired
          onChange={submitHandler}
        />
      </Grid>
    </>
  );
};

export default StartDateForm;
