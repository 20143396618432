import React from "react";
import { useAppSelector } from "../../../../../common/hooks/redux";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Task } from "../../../../../common/components/Task";
import moment from "moment/moment";
import DueDate from "./components/DueDate";
import VerticalDivider from "../../../../../common/components/Divider/VerticalDivider";
import Section from "../../../../../common/components/Section/Section";

const ComingNextSection = () => {
  const theme = useTheme();
  const comingNextTasks = useAppSelector(
    (state) => state.priorityView.comingNextTaskList
  );

  return (
    <>
      {comingNextTasks.length > 0 && (
        <Section title="Coming next" sx={{ mt: 5 }}>
          <Grid container spacing={2}>
            {comingNextTasks.map((task, index) => (
              <Grid key={index} item xs={12} sm={12} lg={4}>
                <Box display="flex" alignItems="center" gap={2} sx={{ mb: 1 }}>
                  <Typography variant="caption">
                    Starts at {moment(task.startDate).format("MMM D, YYYY")}
                  </Typography>
                  <VerticalDivider />
                  <DueDate task={task} />
                </Box>
                <Task task={task} />
              </Grid>
            ))}
          </Grid>
        </Section>
      )}
    </>
  );
};

export default ComingNextSection;
