import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";

export const useAttachmentAddToDriveService = (props: {
  attachmentId: number;
}) =>
  useHttpClientDispatchable({
    url: `/attachment/${props.attachmentId}/addToDrive`,
    method: RequestMethodEnum.POST,
  });
