export const device = {
  isIosSafari: () => {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iP(ad|hone|od)/i);
    const webkit = !!ua.match(/WebKit/i);
    const safari =
      !!ua.match(/Safari/i) && !ua.match(/CriOS/i) && !ua.match(/FxiOS/i);

    return iOS && webkit && safari;
  },
};
