import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { linkActions } from "../../../../link/slices/linkSlice";
import { backlogItemViewActions } from "../slices/viewSlice";
import { IBacklogItemDetailsResource } from "../interfaces/IBacklogItemDetailsResource";

export const useGetBacklogItemDetailsService = (
  storeResourceInState: boolean = true
) => {
  const dispatch = useAppDispatch();

  const response = useHttpClientDispatchable({
    url: `/backlog`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IBacklogItemDetailsResource) => {
        if (storeResourceInState) {
          dispatch(backlogItemViewActions.setDetailsResource(data));
          dispatch(linkActions.setLinkList(data.links));
        }
      },
    },
  });

  return {
    ...response,
    data: response.data as IBacklogItemDetailsResource | null,
  };
};
