import { DependencyList, useEffect } from "react";
import { headerActions } from "../../../store/headerSlice";
import { useAppDispatch } from "../../../hooks/redux";

import { HeaderRegistryEnum } from "../enums/HeaderRegistryEnum";

const useRegisterHeader = (
  registry: HeaderRegistryEnum,
  deps?: DependencyList,
  payload?: {}
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      headerActions.setHeader({
        controls: {
          registry: registry,
          payload: payload ?? {},
        },
      })
    );

    return () => {
      dispatch(headerActions.setHeader({ controls: null }));
    };
  }, deps);
};

export default useRegisterHeader;
