import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { ITryDeleteResource } from "../interfaces/ITryDeleteResource";
import { TUseTryDeleteStatusServiceProps } from "./types/TUseTryDeleteStatusServiceProps";

export const useTryDeleteStatusService = ({
  statusId,
}: TUseTryDeleteStatusServiceProps) => {
  const response = useHttpClientDispatchable({
    url: `/status/${statusId}/tryDelete`,
    method: RequestMethodEnum.DELETE,
  });

  return {
    ...response,
    data: response.data as ITryDeleteResource,
  };
};
