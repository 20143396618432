import { useParams } from "react-router-dom";
import { TBoardParams } from "../../../../containers/Board/types/TBoardParams";
import { useFetchBoardService } from "../../../../services/useFetchBoardService";
import UpdateForm from "./UpdateForm";
import Loader from "../../../../../../common/containers/Loader/Loader";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import Title from "../../../../../../common/components/Title/Title";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { orgActions } from "../../../../../org/slices/orgSlice";

const UpdateInfoPage = () => {
  const dispatch = useAppDispatch();
  const { boardRef, projectAbbr } = useParams<TBoardParams>();
  const { data, isLoading } = useFetchBoardService({ ref: boardRef! });

  const successHandler = (name: string, workingHours: number) => {
    dispatch(
      systemNotificationActions.open({
        message: "Board settings have been successfully updated.",
        variant: "success",
      })
    );
    // Update board name in the project list menu
    dispatch(
      orgActions.updateBoardData({
        projectAbbr: projectAbbr!,
        boardRef: boardRef!,
        name,
        workingHours,
      })
    );
  };

  return (
    <>
      <Title>Update Info</Title>
      {isLoading && <Loader />}
      <UpdateForm
        isDataLoading={isLoading}
        boardRef={boardRef!}
        data={data}
        onSuccess={successHandler}
      />
    </>
  );
};

export default UpdateInfoPage;
