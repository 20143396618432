import React from "react";
import { useCreateStripePortalSessionService } from "../../services/useCreateStripePortalSessionService";
import { IStripeSessionResource } from "../../interfaces/IStripeSessionResource";
import { OpenInNew } from "@mui/icons-material";
import Loading from "../../../../common/components/Icon/Loading/Loading";
import useWindow from "../../../../common/hooks/useWindow";

const ManageSubscription = () => {
  const { openNewTab } = useWindow();
  const { isLoading, dispatch: dispatchCreateStripePortalSessionService } =
    useCreateStripePortalSessionService();

  const manageSubscriptionHandler = () => {
    dispatchCreateStripePortalSessionService().then(
      (resource: IStripeSessionResource) => {
        openNewTab(resource.url, true);
      }
    );
  };

  return {
    id: "ManageSubscription",
    name: "Manage Subscription",
    icon: isLoading ? <Loading /> : <OpenInNew />,
    path: ``,
    navigateToPath: false,
    onClick: manageSubscriptionHandler,
  };
};

export default ManageSubscription;
