import { IProject } from "../../entities/IProject";
import { TListResource } from "../types/TListResource";
import { TListItem } from "../types/TListItem";

export const projectUtil = {
  list: (
    projects: IProject[],
    idType: "id" | "ref" = "id"
  ): TListResource<number> => {
    return projects.map(
      (project) =>
        ({
          id: project[idType],
          label: `${project.name} (${project.abbr})`,
        } as TListItem<number>)
    );
  },
};
