import { useHttpClient } from "../../../common/modules/httpClient/hooks/useHttpClient";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IProject } from "../../../entities/IProject";
import { useAppDispatch } from "../../../common/hooks/redux";
import { projectActions } from "../slices/projectSlice";

export const useFetchProjectListService = () => {
  const dispatch = useAppDispatch();

  return useHttpClient({
    url: `/project/list`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IProject[]) =>
        dispatch(projectActions.setProjectList(data)),
    },
  });
};
