import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClientDispatchableOnly } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchableOnly";
import { ILinkEntityResource } from "../interfaces/ILinkEntityResource";

export const useLinkEntityLookupService = () => {
  const response = useHttpClientDispatchableOnly({
    url: "/link/entity/lookup/",
    method: RequestMethodEnum.GET,
  });

  return {
    ...response,
    data: response.data as ILinkEntityResource[],
  };
};
