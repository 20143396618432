import useForm from "../../../../../common/hooks/useForm/useForm";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import Form from "../../../../../common/components/Form/Form";
import { Button } from "../../../../../common/modules/materialUI";
import PasswordField from "../containers/Password/PasswordField";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import { useResetUserPasswordService } from "../../../services/useResetUserPasswordService";
import { IUser } from "../../../../../entities/IUser";
import { TUpdatePasswordProps } from "./types/TUpdatePasswordProps";
import { jwt } from "../../../../../common/utils/jwt";
import { TUpdatePasswordFormAttributes } from "./types/TUpdatePasswordFormAttributes";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";

const UpdatePassword = (props: TUpdatePasswordProps) => {
  const user = jwt.parse();
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const form = useForm<TUpdatePasswordFormAttributes>({
    inquiryUuid: props.inquiryUuid,
  });
  const { isLoading, dispatch: dispatchResetUserPasswordService } =
    useResetUserPasswordService();
  const dispatch = useAppDispatch();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchResetUserPasswordService({ body: form.data })
      .then((payload: IUser) => {
        props.onNextHandler && props.onNextHandler();
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
        error.status === 406 &&
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "error",
            })
          );
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <Button
          disabled={isLoading}
          loading={isLoading}
          variant={user ? "contained" : "text"}
          type="submit"
          fullWidth={!user}
          size={user ? "small" : "large"}
        >
          Confirm
        </Button>
      }
    >
      <PasswordField forceFocus={!isTouchDevice} form={form} showHint />

      <PasswordField
        form={form}
        name="password_confirmation"
        label="Password Confirmation"
      />
    </Form>
  );
};

export default UpdatePassword;
