import React from "react";
import { useAppSelector } from "../../../../../common/hooks/redux";
import { Box, Grid, useTheme } from "@mui/material";
import { Task } from "../../../../../common/components/Task";
import DueDate from "./components/DueDate";
import DaysLeft from "./components/DaysLeft";
import VerticalDivider from "../../../../../common/components/Divider/VerticalDivider";
import EarlyStart from "./components/EarlyStart";
import Section from "../../../../../common/components/Section/Section";

const OngoingSection = () => {
  const theme = useTheme();
  const ongoingTasks = useAppSelector(
    (state) => state.priorityView.ongoingTaskList
  );

  return (
    <>
      {ongoingTasks.length > 0 && (
        <Section title="Ongoing">
          <Grid container spacing={2}>
            {ongoingTasks.map((task, index) => (
              <Grid key={index} item xs={12} sm={12} lg={4}>
                <Box display="flex" alignItems="center" gap={2} sx={{ mb: 1 }}>
                  {task.workingHours.notStarted ? (
                    <EarlyStart task={task} />
                  ) : (
                    <>
                      <DueDate task={task} />
                      <VerticalDivider />
                      <DaysLeft task={task} />
                    </>
                  )}
                </Box>
                <Task task={task} />
              </Grid>
            ))}
          </Grid>
        </Section>
      )}
    </>
  );
};

export default OngoingSection;
