import { Typography, useTheme } from "@mui/material";
import classes from "./NotFound.module.scss";

const NotFound = () => {
  const theme = useTheme();

  return (
    <div className={`text-center ${classes.container}`}>
      <img
        src={`${process.env.CDN_URL}/icons/${
          theme.palette.mode === "dark"
            ? "not-found-404-dark.png"
            : "not-found-404-light.png"
        }`}
        alt=""
      />
      <Typography variant="h5" sx={{ mb: 1 }}>
        The resource you are looking for does not exist.
      </Typography>
    </div>
  );
};

export default NotFound;
