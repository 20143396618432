import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { taskTypeActions } from "../../slices/taskTypeSlice";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CreateForm from "./CreateForm";

const CreateModal = () => {
  const createState = useAppSelector((state) => state.taskType.create);
  const dispatch = useAppDispatch();

  const closeHandler = () => dispatch(taskTypeActions.closeCreateModal());

  return (
    <Dialog
      open={createState.showModal}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Add Task Type</DialogTitle>
      <DialogContent>
        <CreateForm onCancel={closeHandler} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateModal;
