import classes from "./CardText.module.css";
import { TCardProps } from "./types/TCardTextProps";
import { useTheme } from "@mui/material";

export default function CardText(props: TCardProps) {
  const theme = useTheme();

  return (
    <div className={classes.content} style={{ marginBottom: "30px" }}>
      <div
        className={classes["inner-container"]}
        style={{ backgroundColor: theme.palette.landingPageCard.default }}
      >
        <div
          className={classes["inner-content"]}
          style={{ backgroundColor: theme.palette.landingPageCard.paper }}
        >
          <div className={classes.data}>
            {props.icon && (
              <div style={props.iconStyle} className={classes.icon}>
                {props.icon}
              </div>
            )}
            <div>
              <span style={{ fontSize: "16px" }}>{props.title}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
