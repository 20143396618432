import { useAppDispatch } from "../../../common/hooks/redux";
import { taskActions } from "../slices/taskSlice";
import { ITask } from "../../../entities/ITask";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { ITaskPatchResource } from "../modules/view/interfaces/ITaskPatchResource";

export const useUpdateTaskService = (taskRef: string) => {
  const dispatch = useAppDispatch();
  const response = useHttpClientDispatchable({
    url: `/task/${taskRef}`,
    method: RequestMethodEnum.PATCH,
    events: {
      beforeTransform: (data: ITaskPatchResource) => {
        dispatch(taskActions.mergeTaskProps(data.task));
        dispatch(taskActions.setStatusReason(data.statusReason));
      },
    },
  });

  return {
    ...response,
    data: response.data as ITask,
  };
};
