import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TSystemNotificationState } from "./types/TSystemNotificationState";

const initialState: TSystemNotificationState = {
  open: false,
  message: "",
};

const slice = createSlice({
  name: "systemNotification",
  initialState,
  reducers: {
    open: (
      state,
      action: PayloadAction<Omit<TSystemNotificationState, "open">>
    ) => ({ ...action.payload, open: true }),
    close: (state) => {
      state.open = false;
    },
  },
});

export const systemNotificationReducer = slice.reducer;

export const systemNotificationActions = slice.actions;
