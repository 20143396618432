import { FormControlLabel, Typography } from "@mui/material";
import Checkbox from "../../../../../../../common/modules/materialUI/components/Checkbox/Checkbox";
import React, { useState, useTransition } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../common/hooks/redux";
import {
  boardViewActions,
  STORAGE_GROUP_BY_SPRINT_KEY,
} from "../../../../../slices/boardViewSlice";
import { TFilterProps } from "../../../../../containers/Filter/Filter/types/TFilterProps";
import { menuItem } from "../../../../../../../common/containers/Menu/utils/menuItem";

const GroupBySprintOption = (props: TFilterProps) => {
  const customs = useAppSelector((state) => state.customs);
  const [checked, setChecked] = useState<boolean>(
    useAppSelector((state) => state.boardView.listView.groupBySprint)
  );
  const [, startTransition] = useTransition();
  const dispatch = useAppDispatch();

  const clickHandler = () => {
    setChecked((prev) => {
      const checked = !prev;
      localStorage.setItem(
        STORAGE_GROUP_BY_SPRINT_KEY,
        checked ? "true" : "false"
      );
      startTransition(() => {
        dispatch(boardViewActions.setGroupBySprint(checked));
      });
      return checked;
    });
  };

  return menuItem.make(
    <FormControlLabel
      control={
        <Checkbox checked={checked} onChange={clickHandler} sx={{ py: 0 }} />
      }
      label={
        <Typography
          sx={{
            color: !checked ? "text.primary" : "text.disabled",
          }}
          variant="body2"
        >
          Group by {customs.sprintLabel}
        </Typography>
      }
    />,
    undefined,
    props.renderGroupBySprint
  );
};

export default GroupBySprintOption;
