import useForm from "../../../common/hooks/useForm/useForm";
import { TCreateFormAttributes } from "../containers/CreateProject/types/TCreateFormAttributes";
import { useAppDispatch } from "../../../common/hooks/redux";
import { useCreateProjectService } from "../services/useCreateProjectService";
import { IProject } from "../../../entities/IProject";
import { navigationActions } from "../../../common/store/navigationSlice";
import { projectActions } from "../slices/projectSlice";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../common/modules/httpClient/types/THttpClientError";
import { useDispatchEvent } from "../../../common/modules/eventProvider";
import { EventEnum } from "../../../common/modules/eventProvider/enums/EventEnum";
import { TUseCreateProjectProps } from "./types/TUseCreateProjectProps";

export default function useCreateProject({
  formDefaultState,
}: TUseCreateProjectProps) {
  const form = useForm<TCreateFormAttributes>(formDefaultState);
  const dispatch = useAppDispatch();
  const { isLoading, dispatch: dispatchCreateProject } =
    useCreateProjectService();
  const dispatchEvent = useDispatchEvent();

  const submitHandler = (
    successCallback?: () => void,
    errorCallback?: () => void
  ) => {
    dispatchCreateProject({
      body: {
        ...form.data,
        abbr: form.data?.abbr?.toUpperCase(),
        statusFlowProjectId:
          (form.data?.statusFlowProject?.id ?? 0) > 0
            ? form.data?.statusFlowProject.id
            : null,
        reps: form.data?.reps?.map((rep) => rep.id),
      },
    })
      .then((payload: IProject) => {
        dispatch(navigationActions.refresh());
        dispatch(projectActions.addProject(payload));
        dispatch(
          systemNotificationActions.open({
            message: "Your project has been created",
            variant: "success",
          })
        );
        dispatchEvent(EventEnum.ON_PROJECT_CREATED, payload);
        successCallback?.();
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
        errorCallback?.();
      });
  };

  return {
    form,
    isLoading,
    submitHandler,
  };
}
