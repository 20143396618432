import { Grid, useTheme } from "@mui/material";

import { TObjectiveViewHeaderProps } from "./types/TObjectiveViewHeaderProps";
import { Check, Link } from "@mui/icons-material";
import { Tooltip } from "../../../../../common/modules/materialUI";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { objectiveUtil } from "../../../utils/objectiveUtil";

const ObjectiveViewHeader = ({
  objective,
  isMobileView,
}: TObjectiveViewHeaderProps) => {
  const theme = useTheme();
  const [linkCopied, setLinkCopied] = useState(false);

  const objectiveUrl = objectiveUtil.url(objective.ref);

  const copyLinkHandler = () => {
    setLinkCopied((prevState) => !prevState);
    navigator.clipboard.writeText(objectiveUrl);
    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  };

  return (
    <Grid
      container
      bgcolor={theme.palette.taskView.secondaryBackground}
      borderBottom={`1px solid ${theme.palette.taskView.headerBorderBottomBackground}`}
      height={60}
      p={`16px ${isMobileView ? "40px 16px 16px" : "26px"}`}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid container item width="auto" alignItems="center">
        <Grid
          item
          bgcolor={theme.palette.taskView.headerTitleBackground}
          borderRadius="4px"
          fontSize="12px"
          fontWeight={500}
          p="7px 8px"
        >
          <NavLink
            to={objectiveUrl}
            style={{ color: theme.palette.taskView.textMainColor }}
          >
            {objective.ref}
          </NavLink>
        </Grid>

        <Tooltip
          placement="right"
          title={!linkCopied ? "Copy link" : "Copied!"}
        >
          <Grid item ml="4px" display="flex" alignItems="center">
            {!linkCopied ? (
              <Link
                fontSize="small"
                sx={{
                  color: theme.palette.taskView.textMainColor,
                  cursor: "pointer",
                }}
                onClick={copyLinkHandler}
              />
            ) : (
              <Check color="success" fontSize="small" />
            )}
          </Grid>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default ObjectiveViewHeader;
