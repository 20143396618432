import { useHttpClient } from "../../../common/modules/httpClient/hooks/useHttpClient";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IProject } from "../../../entities/IProject";

export const useFetchProjectService = (props: { projectAbbr: string }) => {
  const response = useHttpClient({
    url: `/project/${props.projectAbbr}`,
    method: RequestMethodEnum.GET,
  });

  return {
    ...response,
    data: response.data as IProject,
  };
};
