import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IntegrationEnum } from "../../../entities/enums/IntegrationEnum";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";

export const useFetchInstallResourceService = (props: {
  integration: IntegrationEnum;
}) =>
  useHttpClientDispatchable({
    url: `/integration/install/resource`,
    method: RequestMethodEnum.GET,
    query: { integration: props.integration },
  });
