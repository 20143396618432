import { SvgIconProps, Typography, useTheme } from "@mui/material";
import { Tooltip } from "../../../../modules/materialUI";
import { Error, RunningWithErrors, Warning } from "@mui/icons-material";
import { RiskStatusEnum } from "../../../../../entities/enums/RiskStatusEnum";
import { TClassNameProp } from "../../../../types/TClassNameProp";
import { TStyleProp } from "../../../../types/TStyleProp";
import { RiskStatusHelper } from "./helpers/RiskStatusHelper";

type TProps = {
  riskStatus?: RiskStatusEnum;
  placement?: "left" | "right" | "top" | "bottom";
  hideTooltip?: boolean;
  iconProps?: SvgIconProps;
} & TClassNameProp &
  TStyleProp;

const RiskStatusIcon = (props: TProps) => {
  const label = RiskStatusHelper.getValue(props.riskStatus);
  const theme = useTheme();

  return (
    <Tooltip
      when={!props.hideTooltip}
      placement={props.placement ?? "top"}
      title={<Typography variant="body2">{label}</Typography>}
      arrow
    >
      <div className={props.className} style={props.style}>
        {props.riskStatus === RiskStatusEnum.AT_RISK && (
          <Warning
            fontSize="small"
            {...props.iconProps}
            style={{ color: theme.palette.risk.main }}
          />
        )}
        {props.riskStatus === RiskStatusEnum.AT_DANGER && (
          <Error
            fontSize="small"
            {...props.iconProps}
            style={{ color: theme.palette.warning.main }}
          />
        )}
        {props.riskStatus === RiskStatusEnum.DELAYED && (
          <RunningWithErrors
            fontSize="small"
            {...props.iconProps}
            style={{ color: theme.palette.error.main }}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default RiskStatusIcon;
