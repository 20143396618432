import { useEffect, useState } from "react";
import { TValidationError } from "./types/TValidationError";
import { THttpClientError } from "../../modules/httpClient/types/THttpClientError";
import { TForm } from "./types/TForm";

const useForm = <TAttributes>(
  defaultState: TAttributes = {} as TAttributes
) => {
  const [defaultData, setDefaultData] = useState<TAttributes>(defaultState);
  const [data, setData] = useState<TAttributes>(defaultData);
  const [validationErrors, setValidationErrors] =
    useState<TValidationError<TAttributes>["errors"]>();

  // This is the case when default data is loaded through service, e.g. update page
  useEffect(() => {
    !data && defaultState && setData(defaultState);
    !defaultData && defaultState && setDefaultData(defaultState);
  }, [data, defaultData, defaultState]);

  const validationErrorsHandler = (
    errorPayload: TValidationError<TAttributes>
  ) => {
    const keys = Object.keys(errorPayload.errors) as (keyof TAttributes)[];
    const errors: TValidationError<TAttributes>["errors"] =
      {} as TValidationError<TAttributes>["errors"];
    for (let i = 0; i < keys.length; i++) {
      const attribute = keys[i];
      errors[attribute] = errorPayload.errors[attribute][0];
    }
    // Set processed errors to state
    setValidationErrors(errors);
  };

  return {
    data,
    getTransformed: (transformer) => transformer(data),
    validationErrors,
    get: (attribute) => data?.[attribute],
    changeHandler: (name, value) =>
      setData(
        (prevState) =>
          ({
            ...prevState,
            [name]: value,
          } as TAttributes)
      ),
    set: (changedData) =>
      setData((prevState) => ({ ...prevState, ...changedData })),
    errorHandler: (error: THttpClientError) => {
      if (error.status === 422) {
        validationErrorsHandler(error.data as TValidationError<TAttributes>);
      }
    },
    hasError: (attribute) => !!validationErrors?.[attribute],
    getError: (attribute) => validationErrors?.[attribute],
    isDirty: () => {
      const isDirty =
        data &&
        Object.keys(data as {}).some(
          (key) =>
            data[key as keyof TAttributes] !=
            defaultData[key as keyof TAttributes]
        );
      const notDirty =
        data &&
        defaultData &&
        Object.keys(defaultData).length === 0 &&
        Object.keys(data).every((key) => !data[key as keyof TAttributes]);

      return isDirty && !notDirty;
    },
    reset: () => {
      setData(defaultData);
      setValidationErrors(undefined);
    },
    resetErrors: () => {
      setValidationErrors(undefined);
    },
    notDirty: () => {
      setDefaultData(data);
      setValidationErrors(undefined);
    },
    changeDefaultData: (changedData) => {
      setDefaultData((prevState) => ({ ...prevState, ...changedData }));
      setData((prevState) => ({ ...prevState, ...changedData }));
    },
    getValidationErrorAtIndex: (index: number = 0, error: THttpClientError) => {
      const data = error.data as TValidationError<TAttributes>;
      const keys = Object.keys(data.errors) as (keyof TAttributes)[];
      for (let i = 0; i < keys.length; i++) {
        if (i === index) {
          return data.errors[keys[i]][0];
        }
      }
      return null;
    },
  } as TForm<TAttributes>;
};

export default useForm;
