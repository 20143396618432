import { Timeline } from "@mui/icons-material";
import { Typography } from "@mui/material";
import QuickActionButton from "../QuickActionButton";
import { useAppDispatch } from "../../../../../hooks/redux";
import { TBacklogItemRefProp } from "../../types/TBacklogItemRefProp";
import { backlogItemViewActions } from "../../../../../../modules/backlog/modules/view/slices/viewSlice";
import { TBoardRefProp } from "../../types/TBoardRefProp";

export default function AddToTimeline({
  backlogItemRef,
  boardRef,
}: TBacklogItemRefProp & TBoardRefProp) {
  const dispatch = useAppDispatch();

  return (
    <QuickActionButton
      onClick={() =>
        dispatch(
          backlogItemViewActions.openMoveToTaskDialog({
            backlogItemRef,
            boardRef,
          })
        )
      }
    >
      <Timeline fontSize="small" />
      <Typography fontSize="13px" fontWeight={500}>
        Add to Timeline
      </Typography>
    </QuickActionButton>
  );
}
