import { createSlice } from "@reduxjs/toolkit";
import { NAV_STATE_KEY } from "../containers/Layout/App/Navigation/Navigation";
import { TNavigationState } from "./types/TNavigationState";

const initialState: TNavigationState = {
  display: true,
  refresh: false,
};

const slice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    toggle: (state) => {
      state.display = !state.display;
      localStorage.setItem(NAV_STATE_KEY, `${+state.display}`);
    },
    show: (state) => {
      state.display = true;
    },
    hide: (state) => {
      state.display = false;
    },
    refresh: (state) => {
      state.refresh = !state.refresh;
    },
  },
});

export const navigationReducer = slice.reducer;

export const navigationActions = slice.actions;
