import classes from "./ViewPicker.module.css";
import useNavigateBoard from "../../hooks/useNavigateBoard";
import { BoardViewEnum } from "../../enums/BoardViewEnum";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../../../board/containers/Board/types/TBoardParams";
import IconImage from "../../../../common/components/Icon/IconImage/IconImage";
import React, { useEffect, useState, useTransition } from "react";
import Picker from "../../../../common/containers/Picker/Picker";
import { TViewPickerProps } from "./types/TViewPickerProps";
import AddButton from "../AddButton/AddButton";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import ExtraFilters from "../Filter/ExtraFilters/ExtraFilters";
import NotesModal from "../../../objective/containers/View/Initiatives/ManageInitiative/NotesModal";
import { useFetchBoardInitiativesService } from "../../../board/services/useFetchBoardInitiativesService";
import { useAppSelector } from "../../../../common/hooks/redux";
import { Tooltip } from "../../../../common/modules/materialUI";
import { StickyNote2 } from "@mui/icons-material";

export const VIEW_PICKER_ID = "view-picker";

export const getViewPickerPortalElement = () =>
  document.getElementById(VIEW_PICKER_ID);

const ViewPicker = (props: TViewPickerProps) => {
  const [noteModalOpen, setNoteModalOpen] = useState<boolean>(false);
  const initiatives = useAppSelector((state) => state.board.initiatives);
  const { boardView } = useParams<TBoardParams>();
  const [, startTransition] = useTransition();
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);
  const navigate = useNavigateBoard();
  const iconsPath = "/icons/boardView/";
  const size = 13;

  const { dispatch: dispatchFetchBoardInitiativesService } =
    useFetchBoardInitiativesService({
      ref: props.board.ref!,
    });

  const changeViewHandler = (view: BoardViewEnum) => {
    startTransition(() => {
      navigate(view);
    });
  };

  useEffect(() => {
    dispatchFetchBoardInitiativesService();
  }, []);

  return (
    <>
      <div className={classes.container}>
        <AddButton />
        <Picker
          defaultValue={boardView ?? BoardViewEnum.HOLISTIC_VIEW}
          sections={[
            {
              icon: (
                <IconImage
                  img={`${iconsPath}timeline-light-v5.png`}
                  imgDark={`${iconsPath}timeline-dark-v5.png`}
                  size={size}
                />
              ),
              text: !matchesMax576Screen ? "Holistic View" : "Holistic",
              value: BoardViewEnum.HOLISTIC_VIEW,
              onClick: () => changeViewHandler(BoardViewEnum.HOLISTIC_VIEW),
            },
            {
              icon: (
                <IconImage
                  img={`${iconsPath}kanban-light-v5.png`}
                  imgDark={`${iconsPath}kanban-dark-v5.png`}
                  size={size}
                />
              ),
              text: !matchesMax576Screen ? "Kanban View" : "Kanban",
              value: BoardViewEnum.KANBAN_VIEW,
              onClick: () => changeViewHandler(BoardViewEnum.KANBAN_VIEW),
            },
            {
              icon: (
                <IconImage
                  img={`${iconsPath}list-light-v5.png`}
                  imgDark={`${iconsPath}list-dark-v5.png`}
                  size={size}
                />
              ),
              text: !matchesMax576Screen ? "List View" : "List",
              value: BoardViewEnum.LIST_VIEW,
              onClick: () => changeViewHandler(BoardViewEnum.LIST_VIEW),
            },
          ]}
        />
        {props.middleElement && (
          <div className={classes["middle-container"]}>
            {props.middleElement}
          </div>
        )}
        <div className={classes["right-container"]}>
          {props.havingBoardUsers && props.filterElement}
          <Tooltip title="Notes">
            <StickyNote2
              style={{
                cursor: "pointer",
                marginLeft: 15,
              }}
              onClick={function () {
                setNoteModalOpen(true);
              }}
            />
          </Tooltip>
        </div>
      </div>

      <ExtraFilters />

      <NotesModal
        show={noteModalOpen}
        boardId={props.board.id}
        initiatives={initiatives}
        isBoardView={true}
        closeHandler={function () {
          setNoteModalOpen(false);
        }}
      />
    </>
  );
};

export default ViewPicker;
