import { guesstimationItemViewActions } from "../slices/viewSlice";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IGuesstimationItemDetailsResource } from "../interfaces/IGuesstimationItemDetailsResource";

export const useUpdateGuesstimationItemService = (
  guesstimationItemId: number
) => {
  const dispatch = useAppDispatch();
  const response = useHttpClientDispatchable({
    url: `/guesstimationItem/${guesstimationItemId}`,
    method: RequestMethodEnum.PATCH,
    body: {
      confirmed: true,
    },
    events: {
      beforeTransform: (data: IGuesstimationItemDetailsResource) => {
        dispatch(guesstimationItemViewActions.setDetailsResource(data));
      },
    },
  });

  return {
    ...response,
    data: response.data as IGuesstimationItemDetailsResource,
  };
};
