import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import UpdateForm from "./UpdateForm";
import { networkingActions } from "../../slices/networkingSlice";

const UpdateModal = () => {
  const editableItem = useAppSelector((state) => state.networking.editableItem);
  const dispatch = useAppDispatch();

  const closeHandler = () =>
    dispatch(networkingActions.setEditableItem(undefined));

  return (
    <Dialog open={!!editableItem} onClose={closeHandler} fullScreen>
      <DialogTitle>Edit Network Record</DialogTitle>
      <DialogContent>
        {!!editableItem && (
          <UpdateForm network={editableItem} onCancel={closeHandler} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UpdateModal;
