import { enumUtil } from "../../../../../common/utils/enum";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  ListItem,
  useTheme,
} from "@mui/material";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import { Skeleton } from "../../../../../common/modules/materialUI";
import { useMemo } from "react";
import { AggStatusEnum } from "../../../../../entities/columns/task/AggStatusEnum";

const KanbanViewSkeleton = () => {
  const theme = useTheme();
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);

  return (
    <div
      style={{
        overflowX: "auto",
        display: "flex",
      }}
    >
      {enumUtil.toList(AggStatusEnum).map((status, index) => {
        return (
          <Card
            key={index}
            style={{
              marginRight: "10px",
              flexGrow: 0,
              flexShrink: 0,
            }}
          >
            <CardContent
              style={{
                ...{
                  width: "300px",
                  padding: 0,
                  height: "100%",
                },
                ...(matchesMax576Screen
                  ? {
                      width: "calc(100vw - 73px)",
                      height: "calc(100vh - 250px)",
                      overflowY: "auto",
                    }
                  : {}),
              }}
            >
              <Grid container>
                <Grid item xs={12} style={{ padding: 5 }}>
                  <ListItem disableGutters>
                    <Skeleton variant="text" width="40%" />
                  </ListItem>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundColor: theme.palette.cardsBackground.default,
                    padding: "3px 4px 0 4px",
                    height: "100%",
                  }}
                >
                  {useMemo(
                    () =>
                      Array.from({
                        length: Math.floor(Math.random() * 3) + 1,
                      }).map((value, index) => (
                        <Card
                          key={index}
                          sx={{
                            height: "127px",
                          }}
                          style={{ margin: "4px 0" }}
                        >
                          <CardContent>
                            <Skeleton variant="text" width="20%" />
                          </CardContent>
                          <CardActions disableSpacing>
                            <Skeleton variant="text" width="40%" />
                            <Skeleton
                              variant="text"
                              width="40%"
                              style={{ marginLeft: "auto" }}
                            />
                          </CardActions>
                        </Card>
                      )),
                    []
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export default KanbanViewSkeleton;
