import { useDragLayer, XYCoord } from "react-dnd";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { guesstimationViewItemActions } from "../../slices/guesstimationViewItemSlice";
import { guesstimationViewActions } from "../../slices/guesstimationViewSlice";
import LaneItem, { DRAG_TYPE_GUESSTIMATION_ITEM_HORIZONTAL } from "./LaneItem";
import { pointConverter } from "../../../../../../common/utils/pointConverter";
import { TLaneItemProps } from "./types/TLaneItemProps";

function getStyles(coords?: XYCoord) {
  if (!coords) {
    return {};
  }

  const transform = `translate(${coords.x}px, ${coords.y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

const LaneItemCustomDragLayer = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const dragStartCoords = useAppSelector(
    (state) => state.guesstimationViewItem.dragStartCoords
  );
  const dragSummary = useAppSelector(
    (state) => state.guesstimationViewItem.dragSummary
  );
  const { itemType, isDragging, item, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem() as TLaneItemProps,
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );
  const [distance, setDistance] = useState<XYCoord>();

  // !!! We need to have a constant distance calculated that does not change
  // with current offset change
  useEffect(() => {
    if (!dragStartCoords || !currentOffset) {
      return;
    }

    setDistance({
      x: dragStartCoords.x - currentOffset.x,
      y: currentOffset.y,
    });
  }, [isDragging, dragStartCoords?.x]);

  useEffect(() => {
    dispatch(guesstimationViewActions.displayLaneOverlay(isDragging));
    dispatch(
      guesstimationViewItemActions.setIsDragging({
        isDragging,
        draggingItemLaneIndex: item?.guesstimationItem?.phaseRef,
      })
    );
  }, [isDragging]);

  if (
    itemType !== DRAG_TYPE_GUESSTIMATION_ITEM_HORIZONTAL ||
    !isDragging ||
    !dragStartCoords
  ) {
    return null;
  }

  return (
    <div
      style={{
        ...{
          position: "fixed",
          pointerEvents: "none",
          zIndex: 100,
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        },
        ...getStyles(
          currentOffset && distance
            ? {
                x: currentOffset.x + distance.x - 2,
                y: distance.y - 4,
              }
            : undefined
        ),
      }}
    >
      {dragSummary && (
        <div
          style={{
            position: "absolute",
            top: -20,
            fontSize: "0.9rem",
            fontWeight: 500,
            color: theme.palette.dropTargetBackground.paper,
          }}
        >
          {dragSummary}
        </div>
      )}
      <LaneItem
        itemLaneIndex={item.guesstimationItem.id}
        phaseIndex={item.phaseIndex}
        guesstimationItem={item.guesstimationItem}
        size={pointConverter.pointToXSizeInPixels(
          item.guesstimationItem.tsPoints
        )}
        positionX={0}
        users={item.users}
        groupSize={item.groupSize}
      />
    </div>
  );
};

export default LaneItemCustomDragLayer;
