import { Typography } from "@mui/material";
import { Error } from "@mui/icons-material";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import { TLinkInfoProps } from "./types/TLinkInfoProps";
import { Tooltip } from "../../../../common/modules/materialUI";
import useFormListing from "../../hooks/useList";

const LinkInfo = (props: TLinkInfoProps) => {
  const matchesMax992Screen = useMedia(QueryEnum.MAX_WIDTH_992);
  const { linkListFiltered } = useFormListing({
    projectRef: props.projectRef,
  });
  const linkListExists = linkListFiltered.find(
    (item) => item.id == props.linkListId
  );

  if (linkListExists) {
    return null;
  }

  return (
    <Typography
      variant="body2"
      style={{
        display: matchesMax992Screen ? "inline-flex" : "flex",
        alignItems: "center",
      }}
    >
      <Tooltip title="This task link is not active for this project anymore.">
        <Error style={{ fontSize: "1rem" }} />
      </Tooltip>
    </Typography>
  );
};

export default LinkInfo;
