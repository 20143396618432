import { useAppDispatch } from "../../../common/hooks/redux";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { ILinkList } from "../../../entities/ILinkList";
import { ILinkResource } from "../interfaces/ILinkResource";
import { EntityTypeEnum } from "../../../entities/enums/EntityTypeEnum";
import { linkActions } from "../slices/linkSlice";

export const useCreateLinkService = (props: {
  entityType: EntityTypeEnum;
  entityId: number;
}) => {
  const dispatch = useAppDispatch();
  const response = useHttpClientDispatchable({
    url: `/link/${props.entityType}/${props.entityId}/create`,
    method: RequestMethodEnum.POST,
    events: {
      beforeTransform: (data: ILinkResource[]) =>
        dispatch(linkActions.addLinkToList(data)),
    },
  });

  return {
    ...response,
    data: response.data as ILinkList,
  };
};
