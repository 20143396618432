import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { teamActions } from "../../slices/teamSlice";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CreateVirtualUserForm from "./CreateVirtualUserForm";

const CreateVirtualUserModal = () => {
  const isOpen = useAppSelector((state) => state.team.virtualUser.displayModal);
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(teamActions.closeCreateVirtualUserModal());
  };

  return (
    <Dialog open={isOpen} onClose={closeHandler} fullWidth maxWidth="xs">
      <DialogTitle>Add a Virtual User</DialogTitle>
      <DialogContent>
        <CreateVirtualUserForm onCancel={closeHandler} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateVirtualUserModal;
