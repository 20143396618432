import { useHttpClient } from "../../../common/modules/httpClient/hooks/useHttpClient";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IOrgIntegration } from "../../../entities/IOrgIntegration";
import { integrationActions } from "../slices/integrationSlice";
import { useAppDispatch } from "../../../common/hooks/redux";

export const useFetchIntegrationInstalledListService = () => {
  const dispatch = useAppDispatch();

  const response = useHttpClient({
    url: `/integration/list/installed`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IOrgIntegration[]) =>
        dispatch(integrationActions.setOrgIntegrationList(data)),
    },
  });

  return {
    ...response,
    data: response.data as IOrgIntegration[],
  };
};
