import { ListItemIcon, Typography } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { TBoardDefaultProps } from "../../types/TBoardDefaultProps";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";

const SettingsMenuItem = (props: TBoardDefaultProps) => {
  return menuItem.make(
    <NavLink
      to={`${props.projectAbbr}/board/${props.boardRef}/settings/updateInfo`}
    >
      <ListItemIcon>
        <Settings />
      </ListItemIcon>
      <Typography sx={{ pl: 2 }}>Settings</Typography>
    </NavLink>
  );
};

export default SettingsMenuItem;
