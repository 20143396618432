import { TMessageProps } from "./types/TMessageProps";
import MessageWithLinks from "./MessageWithLinks";

const Message = (props: TMessageProps) => {
  if (typeof props.message === "string") {
    return <>{props.message}</>;
  }

  return <MessageWithLinks message={props.message} />;
};

export default Message;
