import Form from "../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  Checkbox,
  TextField,
} from "../../../../common/modules/materialUI";
import useCreateProject from "../../hooks/useCreateProject";
import { TCreateFormProps } from "./types/TCreateFormProps";
import { FormEvent, useEffect } from "react";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import { useAppSelector } from "../../../../common/hooks/redux";
import { projectUtil } from "../../../../common/utils/projectUtil";
import ColorField from "../../../../common/modules/materialUI/components/TextField/ColorField";
import { useUserListService } from "../../../user/services/useUserListService";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../common/modules/accessControl/utils/featureUtil";
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import { TCreateFormAttributes } from "./types/TCreateFormAttributes";

const CreateForm = (props: TCreateFormProps) => {
  const hasProjectColorCoding = useIsFeatureAllowed(
    FeatureEnum.PROJECT_COLOR_CODING
  );
  const hasRiskStatusMonitor = useIsFeatureAllowed(
    FeatureEnum.RISK_STATUS_MONITOR
  );
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const { list } = useAppSelector((state) => state.org.init);
  const projects = list.map((listItem) => listItem.project);
  const defaultStatusFlow = { id: 0, label: "Default" };
  const statusFlowList = [defaultStatusFlow, ...projectUtil.list(projects)];
  const userJwt = useAppSelector((state) => state.user.userInfo);
  const userListService = useUserListService();
  const {
    form,
    isLoading,
    submitHandler: formSubmitHandler,
  } = useCreateProject({
    formDefaultState: {
      name: "",
      abbr: "",
      color: "",
      reps: [
        {
          id: userJwt?.id!,
          label: userJwt?.name! || "",
        },
      ],
      statusFlowProject: defaultStatusFlow,
    },
  });

  const submitHandler = (event: FormEvent) => {
    event.preventDefault();
    formSubmitHandler(props.onCreate);
  };

  // When monitoring is disabled we should also disable notifications
  useEffect(() => {
    if (form.data?.riskAlertCalculationEnabled === false) {
      form.set({
        ...form.data,
        riskAlertNotificationEnabled: false,
      } as TCreateFormAttributes);
    }
  }, [form.data?.riskAlertCalculationEnabled]);

  return (
    <Form
      onSubmit={submitHandler}
      actionPosition={props.actionPosition ?? "left"}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Create
          </Button>
        </>
      }
    >
      <TextField
        forceFocus={!isTouchDevice}
        form={form}
        name="name"
        label="Name"
        showRequired
        disabled={isLoading}
      />

      <TextField
        form={form}
        name="abbr"
        label="Abbreviation"
        showRequired
        disabled={isLoading}
        inputProps={{ style: { textTransform: "uppercase" } }}
      />

      <ColorField
        form={form}
        name="color"
        label="Color"
        disabled={isLoading || !hasProjectColorCoding}
        helperText={
          !hasProjectColorCoding ? featureUtil.notAvailableText : undefined
        }
      />

      {statusFlowList.length > 1 && (
        <Autocomplete
          form={form}
          name="statusFlowProject"
          label="Status Flow"
          options={statusFlowList}
          disableClearable
          textFieldProps={{
            helperText:
              "Select a status flow template from existing projects to accelerate your setup. If no template is chosen, a default option will be applied. If you select a template, you can customize the flow for your current project later.",
          }}
        />
      )}

      <Autocomplete
        form={form}
        name="reps"
        label="Representatives"
        multiple
        disabled={isLoading}
        service={userListService}
        options={[
          {
            id: userJwt?.id!,
            label: userJwt?.name! || "",
          },
        ]}
      />

      <Box
        display="flex"
        alignItems="center"
        sx={{ marginBottom: "0 !important" }}
      >
        <FormControlLabel
          disabled={!hasRiskStatusMonitor}
          control={<Checkbox form={form} name="riskAlertCalculationEnabled" />}
          label={
            <Typography variant="body2">Risk Status Monitor Enabled</Typography>
          }
        />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        sx={{ marginBottom: "0 !important" }}
      >
        <FormControlLabel
          disabled={
            !form?.data?.riskAlertCalculationEnabled || !hasRiskStatusMonitor
          }
          control={<Checkbox form={form} name="riskAlertNotificationEnabled" />}
          label={
            <Typography variant="body2">
              Risk Status Notifications Enabled
            </Typography>
          }
        />
      </Box>
      {!hasRiskStatusMonitor && (
        <FormHelperText sx={{ position: "relative", top: "-7px" }} disabled>
          {featureUtil.notAvailableText}
        </FormHelperText>
      )}
    </Form>
  );
};

export default CreateForm;
