import { useAppDispatch } from "../../../common/hooks/redux";
import { taskActions } from "../slices/taskSlice";
import { ITask } from "../../../entities/ITask";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { ITaskPatchResource } from "../modules/view/interfaces/ITaskPatchResource";

export const useResetTaskSegmentRiskStatusService = (taskSegmentId: number) => {
  const dispatch = useAppDispatch();

  const response = useHttpClientDispatchable({
    url: `/task/segment/${taskSegmentId}/resetRiskStatus`,
    method: RequestMethodEnum.PATCH,
    events: {
      beforeTransform: (data: ITaskPatchResource) => {
        dispatch(taskActions.setTask(data.task));
        dispatch(taskActions.setStatusReason(data.statusReason));
      },
    },
  });

  return {
    ...response,
    data: response.data as ITask,
  };
};
