import { Button as MuiButton } from "@mui/material";
import { TButtonProps } from "./types/TButtonProps";
import Loading from "../../../../components/Icon/Loading/Loading";
import { forwardRef } from "react";

const Button = forwardRef<HTMLButtonElement, TButtonProps>((props, ref) => {
  const { loading, ...buttonProps } = props;

  return (
    <MuiButton
      ref={ref}
      {...buttonProps}
      startIcon={!loading ? props.startIcon : <Loading />}
    />
  );
});

export default Button;
