import Title from "../../../common/components/Title/Title";
import { useAccessControl } from "../../../common/modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../common/modules/accessControl/enums/AbilityEnum";
import NotFound from "../../../common/components/Page/NotFound/NotFound";
import { Box } from "@mui/material";
import { Button, Tooltip } from "../../../common/modules/materialUI";
import { useAppDispatch } from "../../../common/hooks/redux";
import { customFieldActions } from "../slices/customFieldSlice";
import CreateModal from "./CreateCustomField/CreateModal";
import CustomFieldGrid from "./CustomFieldGrid/CustomFieldGrid";
import { useFetchProjectListService } from "../../project/services/useFetchProjectListService";
import { Add } from "@mui/icons-material";
import { useIsFeatureAllowed } from "../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../common/modules/accessControl/utils/featureUtil";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";

const CustomFieldsPage = () => {
  const { can, acLoaded } = useAccessControl();
  const dispatch = useAppDispatch();
  const isCustomFieldsAllowed = useIsFeatureAllowed(FeatureEnum.CUSTOM_FIELDS);
  // Fetching projects data, it's needed for: grid, create and update forms
  useFetchProjectListService();

  if (!can(AbilityEnum.administrate)) {
    return <>{acLoaded && <NotFound />}</>;
  }

  return (
    <>
      <Title>Custom Fields</Title>
      <Box mb={3}>
        <Tooltip
          title={featureUtil.notAvailableText}
          when={!isCustomFieldsAllowed}
          withSpanWrapper
        >
          <Button
            variant="text"
            startIcon={<Add />}
            onClick={() =>
              isCustomFieldsAllowed
                ? dispatch(customFieldActions.openCreateModal())
                : dispatch(
                    systemNotificationActions.open({
                      message: featureUtil.notAvailableFullText,
                      variant: "warning",
                    })
                  )
            }
          >
            Add Custom Field
          </Button>
        </Tooltip>
        <CreateModal />
      </Box>
      <CustomFieldGrid />
    </>
  );
};

export default CustomFieldsPage;
