import { NavLink } from "react-router-dom";
import TLinkProps from "./types/TLinkProps";
import classes from "./ListItem.module.css";
import { useEffect, useRef } from "react";
import { useTheme } from "@mui/material";

const Link = (props: TLinkProps) => {
  const theme = useTheme();
  const className = "text-truncate";
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    // Set css variable color based on the template palette
    ref?.current?.style.setProperty(
      "--clr-menu-active-item",
      theme.palette.primary.main
    );
  }, []);

  return (
    <>
      {props.navigateToPath ? (
        <NavLink
          ref={ref}
          className={(navData) =>
            navData.isActive ? `${classes.active} ${className}` : className
          }
          title={props.title}
          to={props.path}
          onClick={(event) => {
            props.onClick && props.onClick(event);
            props.onNavigate && props.onNavigate(event);
          }}
        >
          {props.children}
        </NavLink>
      ) : (
        <a
          className={className}
          title={props.title}
          href="#"
          onClick={(event) => {
            event.preventDefault();
            props.onClick && props.onClick(event);
          }}
        >
          {props.children}
        </a>
      )}
    </>
  );
};

export default Link;
