import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IGuesstimationItemDetailsResource } from "../interfaces/IGuesstimationItemDetailsResource";

export const useAddAssigneesService = (props: {
  guesstimationItemId: number;
}) => {
  const response = useHttpClientDispatchable({
    url: `/guesstimationItem/${props.guesstimationItemId}/addAssignees`,
    method: RequestMethodEnum.PATCH,
    body: {
      confirmed: true,
    },
  });

  return {
    ...response,
    data: response.data as IGuesstimationItemDetailsResource,
  };
};
