import { TCommentsProps } from "./types/TCommentsProps";
import { useFetchCommentService } from "../../services/useFetchCommentService";
import { useAppSelector } from "../../../../common/hooks/redux";
import Comment from "../Comment/Comment";
import { Box } from "@mui/material";

export default function CommentList({
  entityType,
  entityId,
  onSave,
  isMobileView,
}: TCommentsProps) {
  useFetchCommentService({
    entityType,
    entityId,
  });
  const comments = useAppSelector(
    (state) => state.comment.list[`${entityType}-${entityId}`]
  );

  return (
    <>
      {comments?.length > 0 && (
        <Box display="flex" flexDirection="column" gap="20px">
          {comments.map((comment) => (
            <Comment
              comment={comment}
              key={comment.id}
              entityType={entityType}
              entityId={entityId}
              onSave={onSave}
              isMobileView={isMobileView}
            />
          ))}
        </Box>
      )}
    </>
  );
}
