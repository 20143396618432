import { useAppDispatch } from "../../../../common/hooks/redux";
import { useHttpClientDispatchable } from "../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IPriorityViewDataResource } from "../interfaces/IPriorityViewDataResource";
import { priorityViewActions } from "../slices/priorityViewSlice";

export const usePriorityViewDispatchService = () => {
  const dispatch = useAppDispatch();
  return useHttpClientDispatchable({
    url: `/priorityView`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IPriorityViewDataResource) => {
        dispatch(priorityViewActions.setOngoingTaskList(data.ongoingTasks));
        dispatch(
          priorityViewActions.setComingNextTaskList(data.comingNextTasks)
        );
        dispatch(priorityViewActions.setUpcomingTaskList(data.upcomingTasks));
        dispatch(priorityViewActions.setDataCount(data.count));
      },
    },
  });
};
