import { TMonitorState } from "./types/TMonitorState";
import { createSlice } from "@reduxjs/toolkit";

const initialState: TMonitorState = {
  creation: {
    displayModal: false,
  },
};

const slice = createSlice({
  name: "monitor",
  initialState,
  reducers: {
    openCreationModal: (state) => {
      state.creation.displayModal = true;
    },
    closeCreationModal: (state) => {
      state.creation.displayModal = false;
    },
  },
});

export const monitorReducer = slice.reducer;

export const monitorActions = slice.actions;
