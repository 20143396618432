import { TDialogProps } from "./types/TDialogProps";
import { Dialog as DialogContainer } from "../../../materialUI";
import { useTheme } from "@mui/material";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";

export default function Dialog({
  isMobileView,
  open,
  closeDialogHandler,
  children,
}: TDialogProps) {
  const theme = useTheme();
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);

  return (
    <DialogContainer
      fullScreen={isMobileView}
      disableEnforceFocus
      open={open}
      onClose={closeDialogHandler}
      renderCloseButton
      closeButtonHandler={closeDialogHandler}
      fullWidth
      maxWidth="xl"
      closeButtonStyles={{
        color: theme.palette.taskView.textSecondaryColor,
        padding: 0,
        right: matchesMax576Screen ? "8px" : "20px",
        top: "8px",
      }}
      PaperProps={{
        sx: {
          height: isMobileView ? undefined : "90%",
        },
      }}
      sx={{
        "& .MuiDialogContent-root": {
          padding: 0,
        },
      }}
    >
      {children}
    </DialogContainer>
  );
}
