import { Box, Step, StepButton, Stepper } from "@mui/material";
import { Button } from "../../../materialUI";
import { TStepperProps } from "./types/TStepperProps";
import Steps from "../Steps/Steps";
import Congratulate from "./Congratulate";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { landingPageActions } from "../../slices/landingPageSlice";

export default function StepperHorizontal(props: TStepperProps) {
  const state = useAppSelector((state) => state.landingPage);
  const dispatch = useAppDispatch();
  const steps = Steps();

  return (
    <Box sx={{ width: "85%", mx: "auto", ...props.sx }}>
      <Stepper nonLinear activeStep={state.activeStep}>
        {steps.map(({ label }, index) => (
          <Step key={label} completed={props.completionState[index]}>
            <StepButton
              color="inherit"
              onClick={() => dispatch(landingPageActions.setActiveStep(index))}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {props.allStepsCompleted ? (
          <Congratulate />
        ) : (
          <>
            {!props.completionState[state.activeStep]
              ? steps[state.activeStep]?.content
              : steps[state.activeStep].contentCompleted}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={state.activeStep === 0}
                onClick={() => dispatch(landingPageActions.handleBack())}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={() => dispatch(landingPageActions.handleNext())}>
                Next
              </Button>
            </Box>
          </>
        )}
      </div>
    </Box>
  );
}
