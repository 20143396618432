import { useAppDispatch } from "../../../common/hooks/redux";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { objectiveActions } from "../slices/objectiveSlice";
import { IObjectiveCost } from "../../../entities/IObjectiveCost";
import { useHttpClient } from "../../../common/modules/httpClient/hooks/useHttpClient";

export const useFetchObjectiveCostStatsService = (props: {
  objectiveRef: string;
}) => {
  const dispatch = useAppDispatch();

  return useHttpClient({
    url: `/objective/${props.objectiveRef}/cost`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IObjectiveCost[]) =>
        dispatch(objectiveActions.setObjectiveCostStats(data)),
    },
  });
};
