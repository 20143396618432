import { TListResource } from "../types/TListResource";
import { TListItem } from "../types/TListItem";

export const enumUtil = {
  /**
   * This function will build a list of items each of those having the value as id and label
   */
  toList: (data: object): TListResource<string | number> =>
    Object.values(data).map((value) => ({ id: value, label: value })),

  toListItem: <Enum>(item: Enum): TListItem<Enum> => ({
    label: item as string,
    id: item,
  }),

  includes: (data: object, value: string | number) =>
    enumUtil.toList(data).some((item) => item.label === value),
};
