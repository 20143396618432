import TCreateFormProps from "./types/TCreateFormProps";
import { useAppDispatch } from "../../../../common/hooks/redux";
import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import { IBoard } from "../../../../entities/IBoard";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { boardActions } from "../../slices/boardSlice";
import { useCreateBoardService } from "../../services/useCreateBoardService";
import { Button, TextField } from "../../../../common/modules/materialUI";
import { TCreateFormAttributes } from "./types/TCreateFormAttributes";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { orgActions } from "../../../org/slices/orgSlice";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";

const CreateForm = (props: TCreateFormProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const dispatch = useAppDispatch();
  const form = useForm<TCreateFormAttributes>({ projectRef: props.projectRef });
  const { isLoading, dispatch: dispatchCreateBoard } = useCreateBoardService();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreateBoard({ body: form.data })
      .then((payload: IBoard) => {
        dispatch(orgActions.addBoard(payload));
        dispatch(boardActions.closeCreationModal());
        dispatch(
          systemNotificationActions.open({
            message: "Your board has been created",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Create
          </Button>
        </>
      }
    >
      <TextField
        forceFocus={!isTouchDevice}
        form={form}
        name="name"
        label="Board Name"
        showRequired
      />
    </Form>
  );
};

export default CreateForm;
