import { Divider, Grid, Typography, useTheme } from "@mui/material";
import { TTabContentProps } from "./types/TTabContentProps";
import { AllOut, PushPinSharp } from "@mui/icons-material";

export default function TabContent({
  tabIndex,
  isMobileView,
  hasActionsPanel,
  groupHandler,
  detachHandler,
  children,
  sx,
}: TTabContentProps) {
  const theme = useTheme();

  return (
    <Grid
      container
      item
      bgcolor={theme.palette.taskView.mainContentBackground}
      borderRadius="12px"
      flexDirection="column"
      gap="12px"
      sx={sx}
    >
      {/*Counter summary, section actions and unpin*/}
      {hasActionsPanel && (
        <Grid container item alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography fontSize="13px" id={`${tabIndex}-count`} />
          </Grid>

          {/*Unpin button*/}
          <Grid item display="flex" alignItems="center" gap="20px">
            <div id={`${tabIndex}-actions`} />

            {(!!detachHandler || !!groupHandler) && (
              <Typography
                color="#818692"
                fontSize="13px"
                display="flex"
                alignItems="center"
                gap="4px"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  detachHandler
                    ? detachHandler?.(tabIndex)
                    : groupHandler?.(tabIndex)
                }
              >
                {!!detachHandler && (
                  <>
                    <AllOut fontSize="small" /> Unpin
                  </>
                )}
                {!!groupHandler && (
                  <>
                    <PushPinSharp fontSize="small" /> Pin
                  </>
                )}{" "}
                {!isMobileView && "Section"}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}

      <Grid item>
        <Divider
          sx={{
            backgroundColor: theme.palette.taskView.dividerBackground,
            border: "none",
            height: "1px",
          }}
        />
      </Grid>

      <Grid container item>
        <div
          role="tabpanel"
          id={`taskInfo-tabpanel-${tabIndex}`}
          aria-labelledby={`taskInfo-tab-${tabIndex}`}
          style={{ width: "100%" }}
        >
          {children}
        </div>
      </Grid>
    </Grid>
  );
}
