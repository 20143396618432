import { TIconImageProps } from "./types/TIconImageProps";
import { useTheme } from "@mui/material";

const IconImage = (props: TIconImageProps) => {
  const theme = useTheme();
  const cdnUrl = process.env.CDN_URL;
  const imgPath =
    theme.palette.mode === "light" ? props.img : props.imgDark ?? props.img;

  return (
    <img
      style={{
        width: props.size ?? 24,
        height: props.size ?? 24,
      }}
      src={`${cdnUrl}${imgPath}`}
      alt={props.alt ?? ""}
    />
  );
};

export default IconImage;
