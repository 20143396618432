import { createSlice } from "@reduxjs/toolkit";
import { TCustomizationState } from "./types/TCustomizationState";

const initialState: TCustomizationState = {
  taskLabel: "Task",
  sprintLabel: "Sprint",
  monitorLabel: "Monitor",
  settingsLabel: "Settings",
};

const slice = createSlice({
  name: "customization",
  initialState,
  reducers: {},
});

export const customizationReducer = slice.reducer;

export const customizationActions = slice.actions;
