import { jwt } from "../../../common/utils/jwt";
import { resetStore } from "../../../common/store/store";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../common/hooks/redux";
import { TUseSignOutHandlerProps } from "./types/TUseSignOutHandlerProps";
import { ROUTE_TO_AUTH } from "../containers/Auth/Signin/TSSigninForm";

export const useSignOutHandler = (props?: TUseSignOutHandlerProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return () => {
    jwt.remove();
    dispatch(resetStore());
    navigate(ROUTE_TO_AUTH, {
      state: props?.rememberState ? { from: location } : undefined,
    });
  };
};
