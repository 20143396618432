import { useAppDispatch } from "../../../../../../common/hooks/redux";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import Form from "../../../../../../common/components/Form/Form";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { useUserListService } from "../../../../../user/services/useUserListService";
import {
  Autocomplete,
  Button,
  DatePicker,
  TextField,
} from "../../../../../../common/modules/materialUI";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { EstimationUomEnum } from "../../../../../../entities/enums/EstimationUomEnum";
import { Grid } from "@mui/material";
import { TCreateFormAttributesTransformed } from "./types/TCreateFormAttributesTransformed";
import { TCreateFormAttributes } from "./types/TCreateFormAttributes";
import React, { useEffect } from "react";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import TaskTypeAutocomplete from "../../../../../taskType/containers/TaskTypeAutocomplete/TaskTypeAutocomplete";
import TaskPriorityAutocomplete from "../../../../../taskPriority/containers/TaskPriorityAutocomplete/TaskPriorityAutocomplete";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";
import useTaskType from "../../../../../../common/modules/task/hooks/useTaskType";
import useTaskPriority from "../../../../../../common/modules/task/hooks/useTaskPriority";
import { TGuesstimationItemCreatedEvent } from "./types/TGuesstimationItemCreatedEvent";
import { guesstimationItemUtil } from "../../../../../../common/utils/guesstimationItemUtil";
import { useCreateGuesstimationItemService } from "../../services/useCreateGuesstimationItemService";
import { IGuesstimationItem } from "../../../../../../entities/IGuesstimationItem";
import { orgActions } from "../../../../../org/slices/orgSlice";

type TProps = {
  boardRef: string;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
};

const CreateForm = (props: TProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { taskTypeAutocompleteData } = useTaskType();
  const { taskPriorityAutocompleteData } = useTaskPriority();
  const form = useForm<TCreateFormAttributes>({
    boardRef: props.boardRef,
    assignees: [],
  });
  const { isLoading, dispatch: dispatchCreateGuesstimationItem } =
    useCreateGuesstimationItemService();
  const {
    taskTypes,
    taskTypeOptions,
    isLoading: isTaskTypesLoading,
  } = taskTypeAutocompleteData(props.boardRef);
  const {
    taskPriorities,
    taskPriorityOptions,
    isLoading: isTaskPrioritiesLoading,
  } = taskPriorityAutocompleteData(props.boardRef);

  const userListService = useUserListService();

  const isDefaultDataLoading = isTaskTypesLoading || isTaskPrioritiesLoading;

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreateGuesstimationItem({
      body: form.getTransformed<TCreateFormAttributesTransformed>((data) => {
        return {
          ...data,
          // Making sure not to pass this field if it's empty
          taskTypeId: data.taskTypeId?.id,
          taskPriorityId: data.taskPriorityId?.id,
          estimationPoints: !!(data.estimationPoints ?? null)
            ? data.estimationPoints
            : undefined,
          estimationUom: data.estimationUom?.id,
          rep: data.rep?.id,
          assignees: data.assignees?.length
            ? data.assignees.map((assignee) => assignee.id)
            : undefined,
          startDate: data.startDate
            ?.startOf("day")
            .format("YYYY-MM-DD")
            .toString(),
          dueDate: data.dueDate?.startOf("day").format("YYYY-MM-DD").toString(),
          confirmed: true,
        };
      }),
    })
      .then((payload: IGuesstimationItem) => {
        dispatch(orgActions.setHasTasks(true));
        dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_CREATED, {
          item: payload,
        } as TGuesstimationItemCreatedEvent);

        dispatch(
          systemNotificationActions.open({
            message: {
              type: "withLinks",
              text: "{link} guesstimation item has been created successfully",
              placeholders: {
                link: {
                  url: guesstimationItemUtil.urlByRef(payload.ref),
                  label: payload.ref,
                },
              },
            },
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        }

        form.errorHandler(error);
      });
  };

  useEffect(() => {
    if (!form.get("startDate")) {
      form.set({ ...form.data!, dueDate: undefined });
    }
  }, [!!form.get("startDate")]);

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading || isDefaultDataLoading || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading || isDefaultDataLoading}
          >
            Add
          </Button>
        </>
      }
    >
      <TextField
        forceFocus={!isTouchDevice}
        form={form}
        name="name"
        label="Summary"
        showRequired
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TaskTypeAutocomplete
            form={form}
            name="taskTypeId"
            loading={isTaskTypesLoading}
            options={taskTypeOptions}
            taskTypes={taskTypes}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TaskPriorityAutocomplete
            form={form}
            name="taskPriorityId"
            loading={isTaskPrioritiesLoading}
            options={taskPriorityOptions}
            taskPriorities={taskPriorities}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            form={form}
            name="estimationUom"
            label="Estimation UOM"
            disabled={form.get("dueDate")}
            options={[
              { id: EstimationUomEnum.DAYS, label: EstimationUomEnum.DAYS },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            form={form}
            disabled={form.get("dueDate")}
            name="estimationPoints"
            label="Estimation"
          />
        </Grid>
      </Grid>

      <Autocomplete
        form={form}
        name="rep"
        label="Representative"
        service={userListService}
        options={
          form.data?.rep
            ? [{ id: form.data.rep.id, label: form.data.rep.label }]
            : []
        }
      />

      <Autocomplete
        form={form}
        name="assignees"
        label="Assigned To"
        multiple
        service={userListService}
        options={form.data?.assignees}
      />

      <DatePicker
        form={form}
        name="startDate"
        label="Start Date"
        allowClear
        textFieldProps={{
          helperText: !form.get("startDate")
            ? "If not selected the guesstimation item will start now"
            : undefined,
        }}
      />

      <DatePicker
        form={form}
        name="dueDate"
        label="Due Date"
        allowClear
        disabled={!form.get("startDate")}
      />

      <TextField
        form={form}
        name="estimatedResources"
        label="Estimated Resources Count"
        helperText="Estimated number of resources needed to complete the assignment within your estimated period"
      />
    </Form>
  );
};

export default CreateForm;
