import { ConfirmationDialog } from "../../../../common/modules/materialUI";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { useDeleteTaskPriorityService } from "../../services/useDeleteTaskPriorityService";
import { taskPriorityActions } from "../../slices/taskPrioritySlice";
import { useDispatchEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";

const DeleteConfirmationDialog = () => {
  const { showConfirmation, deletableRecord } = useAppSelector(
    (state) => state.taskPriority.delete
  );
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { isLoading, dispatch: dispatchDelete } = useDeleteTaskPriorityService({
    taskPriorityId: deletableRecord?.id ?? 0,
  });

  const closeConfirmationHandler = () =>
    dispatch(taskPriorityActions.closeDeleteConfirmation());

  const successHandler = () => {
    dispatch(taskPriorityActions.closeDeleteConfirmation());

    dispatchDelete()
      .then(() => {
        dispatchEvent(
          EventEnum.ON_TASK_PRIORITY_DELETED,
          deletableRecord?.id ?? 0
        );
        dispatch(
          systemNotificationActions.open({
            message: "Task Priority is deleted successfully",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        if (error.status === 422) {
          dispatch(
            systemNotificationActions.open({
              message: `The Task Priority "${deletableRecord?.name}" cannot be deleted. Please contact support.`,
              variant: "error",
            })
          );
        }
      });
  };

  return (
    <ConfirmationDialog
      open={showConfirmation}
      title="Delete Task Priority"
      content={`Are you sure you want to delete the task priority "${deletableRecord?.name}"?`}
      loading={isLoading}
      closeHandler={closeConfirmationHandler}
      successHandler={successHandler}
    />
  );
};

export default DeleteConfirmationDialog;
