import { useAppSelector } from "../../../../../common/hooks/redux";
import { query } from "../../../../../common/utils/query";
import { TUseListViewQueryParamsProps } from "./types/TUseListViewQueryParamsProps";
import { IListViewDataRequest } from "../../../interfaces/IListViewDataRequest";

export const useListViewQueryParams = ({
  projectAbbr,
  showSegments,
  isGroupedBySprint,
}: TUseListViewQueryParamsProps): IListViewDataRequest => {
  const { extraFilters, filters, sorting } = useAppSelector(
    (state) => state.boardQuery
  );
  const { userIds } = filters;
  const { taskTypeIds, taskPriorityIds, sprintRefs, customFields } =
    extraFilters;

  return {
    projectAbbr: projectAbbr,
    showSegments: showSegments ? 1 : 0,
    groupBySprint: isGroupedBySprint ? 1 : 0,
    filters: {
      ...(userIds.length > 0 && { users: userIds }),
      ...(taskTypeIds.length > 0 && {
        taskTypeIds: taskTypeIds.map((item) => item.id),
      }),
      ...(taskPriorityIds.length > 0 && {
        taskPriorityIds: taskPriorityIds.map((item) => item.id),
      }),
      ...(sprintRefs.length > 0 && {
        sprintRefs: sprintRefs.map((item) => item.id),
      }),
      ...(customFields.length > 0 && {
        customFields: customFields.map((item) => ({
          id: item.extraId,
          label: item.value,
        })),
      }),
    },
    ...(sorting && query.transformToQueryObject({ sorting })),
  };
};
