import { useDeleteCommentService } from "../../services/useDeleteCommentService";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import ConfirmationDialog from "../../../../common/modules/materialUI/containers/ConfirmationDialog/ConfirmationDialog";
import { commentActions, storageKey } from "../../slices/commentSlice";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { TDeleteCommentProps } from "./types/TDeleteCommentProps";
import { string } from "../../../../common/utils/string";

const DeleteConfirmationDialog = ({
  typeTitle,
  comment: entity,
}: TDeleteCommentProps) => {
  const dialogOpen = useAppSelector(
    (state) => state.comment.deletion.dialogOpen[storageKey(entity)]
  );
  const comment = useAppSelector((state) => state.comment.deletion.comment);
  const dispatch = useAppDispatch();
  const { isLoading, dispatch: dispatchDelete } = useDeleteCommentService();

  const closeConfirmationHandler = () =>
    dispatch(commentActions.closeDeletionDialog());

  const successHandler = () => {
    dispatchDelete({ urlPath: `/${comment!.id}` })
      .then(() => {
        dispatch(commentActions.removeComment(comment!));
        dispatch(
          systemNotificationActions.open({
            message: `${string.capitalize(typeTitle)} deleted successfully`,
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        dispatch(
          systemNotificationActions.open({
            message: `Failed to delete the ${typeTitle}, please try again.`,
            variant: "error",
          })
        );
      })
      .finally(() => closeConfirmationHandler());
  };

  return (
    <ConfirmationDialog
      open={dialogOpen ?? false}
      title={`Delete ${typeTitle}`}
      content={`Are you sure you want to delete this ${typeTitle}?`}
      loading={isLoading}
      closeHandler={closeConfirmationHandler}
      successHandler={successHandler}
    />
  );
};

export default DeleteConfirmationDialog;
