import { TDeleteSegmentProps } from "./types/TDeleteSegmentProps";
import { useAppDispatch } from "../../../../../hooks/redux";
import QuickActionButton from "../QuickActionButton";
import { Typography } from "@mui/material";
import { taskViewActions } from "../../../../../../modules/task/modules/view/slices/viewSlice";
import { AggStatusEnum } from "../../../../../../entities/columns/task/AggStatusEnum";
import { useAccessControl } from "../../../../accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../accessControl/enums/AbilityEnum";

const DeleteSegment = ({ task }: TDeleteSegmentProps) => {
  const dispatch = useAppDispatch();
  const { can } = useAccessControl();
  const isLastSegment = task.segmentCount === 1;

  if (
    task.statusType === AggStatusEnum.DONE ||
    (isLastSegment && !can(AbilityEnum.administrate))
  ) {
    return null;
  }

  return (
    <QuickActionButton
      onClick={() =>
        dispatch(
          taskViewActions.openDeleteSegmentConfirmation({
            segmentId: task.segmentId,
            taskRef: task.ref,
            isLastSegment,
          })
        )
      }
      sx={{ color: "#7b4545", backgroundColor: "#e1bfc1" }}
    >
      <Typography fontSize="13px" fontWeight="bold">
        Delete {isLastSegment ? task.internalType : "Segment"}
      </Typography>
    </QuickActionButton>
  );
};

export default DeleteSegment;
