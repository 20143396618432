import { TTaskContentProps } from "./types/TTaskContentProps";
import { useSubscribeEvent } from "../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../common/modules/eventProvider/enums/EventEnum";
import { TEventOnTaskResizeInTaskViewPayload } from "../../../../../common/modules/eventProvider/types/TEventOnTaskResizeInTaskViewPayload";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import TaskViewMobile from "./TaskViewMobile";
import TaskViewDesktop from "./TaskViewDesktop";

const TaskContent = (props: TTaskContentProps) => {
  const dispatch = useAppDispatch();

  useSubscribeEvent(
    EventEnum.ON_TASK_RESIZE_IN_TASK_VIEW,
    ({
      prevSegmentCount,
      currentSegmentCount,
      isPrevSegmentRemoved,
    }: TEventOnTaskResizeInTaskViewPayload) => {
      let message: string | undefined;

      if (currentSegmentCount > prevSegmentCount) {
        message =
          "Your actions have resulted in additional splits within the task";
      } else if (currentSegmentCount < prevSegmentCount) {
        message =
          currentSegmentCount === 1
            ? "Due to your actions, all splits have been condensed. You have been redirected to the task view."
            : "Due to your actions, certain splits have been condensed. You have been redirected to the task view.";
      } else if (
        currentSegmentCount === prevSegmentCount &&
        isPrevSegmentRemoved
      ) {
        message =
          "Due to your actions, certain splits have been condensed and additional splits appeared within the task. You have been redirected to the task view.";
      }

      message &&
        dispatch(
          systemNotificationActions.open({
            message: message,
            variant: "info",
          })
        );
    },
    []
  );

  return props.isMobileView ? (
    <TaskViewMobile {...props} />
  ) : (
    <TaskViewDesktop {...props} />
  );
};

export default TaskContent;
