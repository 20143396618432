import { useAppSelector } from "../../../common/hooks/redux";
import { IProjectWithBoardResource } from "../interfaces/IProjectWithBoardResource";

export const findProjectByRef = (
  data: IProjectWithBoardResource,
  ref: string
) => data.find((item) => item.project.ref === ref);

export const findProjectByAbbr = (
  data: IProjectWithBoardResource,
  abbr: string
) => data.find((item) => item.project.abbr === abbr);

export const useOrgProjectData = () => {
  const projectWithBoards = useAppSelector((state) => state.org.init.list);

  return {
    findProjectByRef: (ref: string) => findProjectByRef(projectWithBoards, ref),
    findProjectByAbbr: (abbr: string) =>
      findProjectByAbbr(projectWithBoards, abbr),
    projectsList: projectWithBoards.map(
      (projectWithBoard) => projectWithBoard.project
    ),
    boardList: (projectRef: string) =>
      findProjectByRef(projectWithBoards, projectRef)?.boards,
  };
};
