import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { ISprint } from "../../../entities/ISprint";

export const useCreateSprintService = () => {
  const response = useHttpClientDispatchable({
    url: "/sprint",
    method: RequestMethodEnum.POST,
  });

  return {
    ...response,
    data: response.data as ISprint,
  };
};
