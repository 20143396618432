import { holisticViewActions } from "../slices/holisticViewSlice";
import { IResizeRequest } from "../../../../task/interfaces/IResizeRequest";
import { IResizeResource } from "../../../../task/interfaces/IResizeResource";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { useTaskSegmentPatchService } from "../../../../task/services/useTaskSegmentPatchService";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { useUpdateUserLanes } from "./useUpdateUserLanes";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../../../../board/containers/Board/types/TBoardParams";
import { TTaskProps } from "../containers/Task/types/TTaskProps";
import { pointConverter } from "../../../../../common/utils/pointConverter";
import { useRef, useState } from "react";

const initialState = {
  hours: 0,
  remainingPixelsToNextCheckpoint: 0,
  widthDiff: 0,
  xPositive: undefined,
};

export const useResizeHelper = ({ task }: TTaskProps) => {
  const [state, setState] = useState<{
    hours: number;
    remainingPixelsToNextCheckpoint: number;
    widthDiff: number;
    xPositive?: boolean;
  }>(initialState);
  const hoursRef = useRef<number>(0);

  const workingHours = useAppSelector(
    (state) => state.holisticView.workingHours
  );
  const { projectAbbr, boardRef } = useParams<TBoardParams>();
  const { dispatch: dispatchUpdateUserLane } = useUpdateUserLanes({
    projectAbbr: projectAbbr!,
    boardRef: boardRef!,
  });
  const dispatch = useAppDispatch();
  const { dispatch: dispatchTaskSegmentResize, errorHandler } =
    useTaskSegmentPatchService();

  const stepInPx = pointConverter.oneWorkingHourInPixels(workingHours);

  const resizeStartHandler = () =>
    dispatch(holisticViewActions.displayUserLaneOverlay(true));

  const calculatePixelsToNextCheckpoint = (
    width: number,
    increasing = true
  ) => {
    const remainder = Math.abs(width % stepInPx);

    if (increasing) {
      if (width >= 0) {
        return stepInPx - remainder;
      } else {
        return remainder;
      }
    } else {
      if (width >= 0) {
        return remainder;
      } else {
        return stepInPx - remainder;
      }
    }
  };

  const resizeHandler = (
    e: MouseEvent,
    widthDiff: number,
    xPositive?: boolean
  ) => {
    const toWorkingHours = widthDiff / stepInPx;
    const roundedHours =
      toWorkingHours > 0
        ? Math.ceil(toWorkingHours)
        : Math.floor(toWorkingHours);

    hoursRef.current = roundedHours;
    setState({
      hours: roundedHours,
      remainingPixelsToNextCheckpoint: calculatePixelsToNextCheckpoint(
        widthDiff,
        xPositive
      ),
      widthDiff: widthDiff,
      xPositive: xPositive,
    });
  };

  const resizeCompleteHandler = (resizeResetHandler: () => void) => {
    // Fetch the latest state
    const hours = hoursRef.current;
    if (hours === 0) {
      reset(resizeResetHandler);
      return;
    }

    dispatch(holisticViewActions.animateUserLaneOverlay(true));
    // Trigger the resize request
    dispatchTaskSegmentResize({
      urlPath: `${task.segmentId}/resize`,
      body: { hours } as IResizeRequest,
    })
      .then((data: IResizeResource) => {
        dispatch(
          systemNotificationActions.open({
            message: `Task ${task.ref} has been successfully resized`,
            variant: "success",
          })
        );

        dispatchUpdateUserLane().finally(() => {
          resizeEndHandler();
        });
      })
      .catch((error: THttpClientError) => {
        errorHandler(error);
        reset(resizeResetHandler);
      });
  };

  const reset = (resizeResetHandler: () => void) => {
    resizeEndHandler();
    // Reset the task width to its initial value
    resizeResetHandler();
  };

  const resizeEndHandler = () => {
    dispatch(holisticViewActions.displayUserLaneOverlay(false));
    setState(initialState);
    hoursRef.current = 0;
  };

  const resizeInterruptHandler = () => {
    resizeEndHandler();
  };

  const calculateMetrics = (hours: number) => {
    if (hours === 0) {
      return undefined;
    }

    const absoluteHours = Math.abs(hours);

    const days = Math.floor(absoluteHours / workingHours);
    const remainingHours = absoluteHours % workingHours;

    const daysString = days !== 0 ? `${days} day${days > 1 ? "s" : ""}` : "";
    const hoursString =
      remainingHours !== 0
        ? `${remainingHours} hour${remainingHours > 1 ? "s" : ""}`
        : "";

    return `${!(hours > 0) ? "-" : "+"} ${daysString} ${hoursString}`.trim();
  };

  return {
    hours: state.hours,
    xPositive: state.xPositive,
    widthDiff: state.widthDiff,
    remainingPixelsToNextCheckpoint:
      state.remainingPixelsToNextCheckpoint > 0
        ? state.remainingPixelsToNextCheckpoint
        : stepInPx,
    metrics: calculateMetrics(state.hours),
    resizeHandler,
    resizeStartHandler,
    resizeCompleteHandler,
    resizeInterruptHandler,
  };
};
