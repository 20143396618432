import { AttachMoney } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { boardViewActions } from "../../slices/boardViewSlice";
import { SideViewSectionEnum } from "../../enums/SideViewSectionEnum";
import { useAppDispatch } from "../../../../common/hooks/redux";
import React from "react";
import { useAccessControl } from "../../../../common/modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../common/modules/accessControl/enums/AbilityEnum";
import { TFilterProps } from "../Filter/Filter/types/TFilterProps";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { useIsFeatureAllowed } from "../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../common/modules/accessControl/enums/FeatureEnum";
import { featureUtil } from "../../../../common/modules/accessControl/utils/featureUtil";

const FinancialForecastMenuItem = (props: TFilterProps) => {
  const { can } = useAccessControl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isFinancialForecastAllowed = useIsFeatureAllowed(
    FeatureEnum.FINANCIAL_FORECAST
  );

  const clickHandler = () =>
    dispatch(boardViewActions.displaySideView(SideViewSectionEnum.BUDGETARY));

  return menuItem.make(
    <Box
      style={{
        color: theme.palette.pickerPallet.secondary,
      }}
      display="flex"
      alignItems="center"
      gap={1}
    >
      <AttachMoney fontWeight="400" />
      <Typography variant="subtitle2" fontWeight="400">
        Financial Forecast
      </Typography>
    </Box>,
    clickHandler,
    can(AbilityEnum.financing) &&
      (props.renderFinancialForecastControl ?? true),
    !isFinancialForecastAllowed,
    !isFinancialForecastAllowed ? featureUtil.notAvailableText : undefined
  );
};

export default FinancialForecastMenuItem;
