import { CurrencyEnum } from "../../entities/columns/user/financialData/CurrencyEnum";

export const currencyUtil = {
  getSymbol: (currency: CurrencyEnum) => {
    switch (currency) {
      case CurrencyEnum.USD:
        return "$";
      case CurrencyEnum.EUR:
        return "€";
      case CurrencyEnum.AMD:
        return "֏";
      default:
        return "";
    }
  },
};
