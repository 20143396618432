import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { projectActions } from "../../slices/projectSlice";
import CreateForm from "./CreateForm";

export default function CreateModal() {
  const creation = useAppSelector((state) => state.project.creation);
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(projectActions.closeCreationModal());
  };

  return (
    <Dialog
      open={creation.displayModal}
      onClose={closeHandler}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Create project</DialogTitle>
      <DialogContent>
        <CreateForm
          onCancel={closeHandler}
          onCreate={closeHandler}
          actionPosition="right"
        />
      </DialogContent>
    </Dialog>
  );
}
