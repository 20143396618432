import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { THolisticViewTaskState } from "./types/THolisticViewTaskState";
import { XYCoord } from "dnd-core";

const initialState: THolisticViewTaskState = {
  dropContainerData: {},
};

const slice = createSlice({
  name: "holisticViewTask",
  initialState,
  reducers: {
    setIsDragging: (state, action: PayloadAction<boolean>) => {
      state.isDragging = action.payload;
    },
    showShiftingProgress: (state, action: PayloadAction<boolean>) => {
      state.showShiftingProgress = action.payload;
    },
    setDragStartCoords: (
      state,
      action: PayloadAction<THolisticViewTaskState["dragStartCoords"]>
    ) => {
      state.dragStartCoords = action.payload;
    },
    setDropData: (
      state,
      action: PayloadAction<THolisticViewTaskState["dropData"]>
    ) => {
      state.dropData = action.payload;
    },
    updateDragSummary: (state, action: PayloadAction<string>) => {
      state.dragSummary = action.payload;
    },
    setDropContainerCoords: (state, action: PayloadAction<XYCoord>) => {
      state.dropContainerData.dragPosition = action.payload;
    },
    setDropContainerData: (
      state,
      action: PayloadAction<THolisticViewTaskState["dropContainerData"]>
    ) => {
      state.dropContainerData.currentDateStartXCoord =
        action.payload.currentDateStartXCoord;
      state.dropContainerData.currentXCoord = action.payload.currentXCoord;
      state.dropContainerData.currentHours = action.payload.currentHours;
      state.dropContainerData.currentDate = action.payload.currentDate;
    },
  },
});

export const holisticViewTaskReducer = slice.reducer;

export const holisticViewTaskActions = slice.actions;
