import { TInviteFormProps } from "./types/TInviteFormProps";
import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import { Button, TextField } from "../../../../common/modules/materialUI";
import { TInviteFormAttributes } from "./types/TInviteFormAttributes";
import { useInviteUserService } from "../../services/useInviteUserService";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { teamActions } from "../../slices/teamSlice";
import { useDispatchEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import { orgActions } from "../../../org/slices/orgSlice";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import { IUserResource } from "../../interfaces/IUserResource";
import { ExtraStatusEnum } from "../../../../entities/columns/user/ExtraStatusEnum";
import { string } from "../../../../common/utils/string";
import { date } from "../../../../common/utils/date";
import { useFetchOrgInitDataService } from "../../../org/services/useFetchOrgInitDataService";
import { useEffect } from "react";

const InviteForm = (props: TInviteFormProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const dispatch = useAppDispatch();
  const virtualEmail = useAppSelector((state) => state.team.invitation.email);
  const form = useForm<TInviteFormAttributes>();
  const { isLoading, dispatch: dispatchInviteUser } = useInviteUserService();
  const { dispatch: dispatchFetchOrgInitDataService } =
    useFetchOrgInitDataService(true);
  const dispatchEvent = useDispatchEvent();

  useEffect(() => {
    virtualEmail && form.set({ email: virtualEmail });
  }, [virtualEmail]);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchInviteUser({
      body: form.data,
    })
      .then(() => {
        dispatchFetchOrgInitDataService();
        dispatch(
          teamActions.addToList({
            email: form.data!.email,
            status: ExtraStatusEnum.PENDING,
            identifier: string.random(10),
            createdAt: date.nowString(),
          } as IUserResource)
        );
        dispatch(teamActions.closeInvitationModal());
        dispatch(
          systemNotificationActions.open({
            message: `Invitation sent to ${
              form.data!.email
            }. Once accepted they will appear in the members list.`,
            variant: "success",
          })
        );
        dispatch(orgActions.setHasMembers(true));
        dispatchEvent(EventEnum.ON_INVITATION_SENT);
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
        error.status === 406 &&
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "error",
            })
          );
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Invite
          </Button>
        </>
      }
    >
      <TextField
        forceFocus={!isTouchDevice}
        disabled={!!virtualEmail}
        form={form}
        name="email"
        label="Email"
        showRequired
      />
    </Form>
  );
};

export default InviteForm;
