import classes from "./LaneItemHorizontal.module.scss";
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import Resizer from "../../../../../../common/components/Task/components/Resizer/Resizer";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { guesstimationItemViewActions } from "../../../../../guesstimation/modules/view/slices/viewSlice";
import { TChildren } from "../../../../../../common/types/TChildren";
import { THorizontalItemProps } from "./types/THorizontalItemProps";
import StateIndicator from "../../../../../../common/components/Task/components/State/StateIndicator";

type TProps = {
  contentClassName?: string;
  contentStyle?: React.CSSProperties;
} & THorizontalItemProps &
  TChildren;

const LaneItemHorizontal = forwardRef(
  (props: TProps, ref: ForwardedRef<HTMLDivElement>) => {
    const dispatch = useAppDispatch();
    const [parentNode, setParentNode] = useState<HTMLDivElement | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const openItemViewHandler = () => {
      dispatch(
        guesstimationItemViewActions.open({ ref: props.guesstimationItem.ref })
      );
    };

    useEffect(() => {
      setParentNode(containerRef.current);
    }, [containerRef.current]);

    return (
      <div
        ref={containerRef}
        className={`${props.className} ${classes.container ?? ""}`}
        style={{
          ...{
            top: props.positionY ? props.positionY : undefined,
            left: props.positionX,
            width: props.size,
          },
          ...props.style,
        }}
        onDragStart={props.onDragStart}
      >
        <StateIndicator
          className={classes.state}
          statusType={props.guesstimationItem.statusType}
          status={props.guesstimationItem.status}
        />
        <div
          onClick={openItemViewHandler}
          ref={ref}
          className={`${props.contentClassName} ${classes.content ?? ""}`}
          style={props.contentStyle}
        >
          <div>{props.children}</div>
        </div>
        {props.resizable && (
          <Resizer parentNode={parentNode} {...props.resizerConfig} />
        )}
      </div>
    );
  }
);

export default LaneItemHorizontal;
