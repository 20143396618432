import { Grid, useTheme } from "@mui/material";
import OrSection from "../components/OrSection/OrSection";
import MSSignInLayout from "../../../../integrations/modules/MSAuth/containers/SignIn/SignInLayout";
import TSSignupForm from "./TSSignupForm";
import { Navigate, useSearchParams } from "react-router-dom";
import { ROUTE_ON_SIGNIN } from "../Signin/TSSigninForm";

const SignUp = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key");

  return key ? (
    <>
      <Grid item>
        <TSSignupForm signupKey={key} />
      </Grid>
      <Grid item>
        <OrSection />
      </Grid>
      <Grid item pt={theme.spacing(2)} pb={theme.spacing(6)}>
        <MSSignInLayout isLogin={false} />
      </Grid>
    </>
  ) : (
    <Navigate to={ROUTE_ON_SIGNIN} replace={true} />
  );
};

export default SignUp;
