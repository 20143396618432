import { Alert, IconButton, Snackbar, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { systemNotificationActions } from "../slices/systemNotificationSlice";
import { Close } from "@mui/icons-material";
import ReactDOM from "react-dom";
import { string } from "../../../utils/string";
import Message from "./Message/Message";

/**
 * Example usage:
 *
 * dispatch(
 *   systemNotificationActions.open({
 *     message: "Any string message to display",
 *   })
 * );
 */
const SystemNotification = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.systemNotification);
  const theme = useTheme();
  const backgroundColor = theme.palette.brightCardBackground.paper;
  const borderRadius = "10px";
  const fontWeight = 500;

  const closeHandler = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway" && !(state.closeOnBlur ?? true)) {
      return;
    }

    dispatch(systemNotificationActions.close());
  };

  const message = <Message message={state.message} />;

  return ReactDOM.createPortal(
    <Snackbar
      sx={{
        marginBottom: "1.3rem",
        width: {
          xs: "93%",
          sm: "auto",
        },
        position: "absolute",
        left: "3.5%",
        ".MuiAlert-standardSuccess": {
          backgroundColor: backgroundColor,
          backgroundImage: "none",
          borderRadius: borderRadius,
          ".MuiAlert-icon": {
            color: theme.palette.successLight.main,
          },
          ".MuiAlert-message": {
            color: theme.palette.successLight.main,
            fontWeight: fontWeight,
          },
        },
        ".MuiAlert-standardError": {
          backgroundColor: backgroundColor,
          backgroundImage: "none",
          borderRadius: borderRadius,
          ".MuiAlert-icon": {
            color: theme.palette.error.main,
          },
          ".MuiAlert-message": {
            color: theme.palette.error.main,
            fontWeight: fontWeight,
          },
        },
        ".MuiAlert-standardInfo": {
          backgroundColor: backgroundColor,
          backgroundImage: "none",
          borderRadius: borderRadius,
          ".MuiAlert-icon": {
            color: theme.palette.info.main,
          },
          ".MuiAlert-message": {
            color: theme.palette.info.main,
            fontWeight: fontWeight,
          },
        },
        ".MuiAlert-standardWarning": {
          backgroundColor: backgroundColor,
          backgroundImage: "none",
          borderRadius: borderRadius,
          ".MuiAlert-icon": {
            color: theme.palette.warning.main,
          },
          ".MuiAlert-message": {
            color: theme.palette.warning.main,
            fontWeight: fontWeight,
          },
        },
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={state.open}
      onClose={closeHandler}
      message={!state.variant ? message : undefined}
      key={string.random(10)}
      autoHideDuration={
        state.autoHideDuration !== null ? state.autoHideDuration ?? 10000 : null
      }
      action={
        !state.variant && state.closeUsingButton ? (
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={closeHandler}
          >
            <Close />
          </IconButton>
        ) : null
      }
    >
      {state.variant && (
        <Alert
          onClose={state.closeUsingButton ?? false ? closeHandler : undefined}
          elevation={6}
          variant="standard"
          severity={state.variant}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      )}
    </Snackbar>,
    document.getElementById("notification-root")!
  );
};

export default SystemNotification;
