import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TPriorityViewState } from "./types/TPriorityViewState";
import { ITaskResource } from "../interfaces/ITaskResource";

const initialState: TPriorityViewState = {
  ongoingTaskList: [],
  comingNextTaskList: [],
  upcomingTaskList: [],
  dataCount: 0,
};

const slice = createSlice({
  name: "priorityView",
  initialState,
  reducers: {
    setOngoingTaskList: (state, action: PayloadAction<ITaskResource[]>) => {
      state.ongoingTaskList = action.payload;
    },
    setComingNextTaskList: (state, action: PayloadAction<ITaskResource[]>) => {
      state.comingNextTaskList = action.payload;
    },
    setUpcomingTaskList: (state, action: PayloadAction<ITaskResource[]>) => {
      state.upcomingTaskList = action.payload;
    },
    setDataCount: (state, action: PayloadAction<number>) => {
      state.dataCount = action.payload;
    },
  },
});

export const priorityViewReducer = slice.reducer;

export const priorityViewActions = slice.actions;
