/**
 * !!! Since organization information is intricately tied to the user who logs into the system, it is therefore
 * included in the validation process of the user's JWT token.
 * Once validated, this information is made readily available throughout the system...
 */
export const org = {
  id: "",
  set: (id: string) => {
    org.id = id;
  },
  remove: (): void => {
    org.set("");
  },
};
