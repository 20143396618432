import { TAutocompleteProps } from "./types/TAutocompleteProps";
import { Autocomplete as MuiAutocomplete, useTheme } from "@mui/material";
import React, { SyntheticEvent, useState } from "react";
import { TListResource } from "../../../../types/TListResource";
import { debounce } from "../../../../utils/debounce";
import TextField from "../../components/TextField/TextField";
import { ArrowDropDownRounded, ClearRounded } from "@mui/icons-material";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";

const Autocomplete = <TFormAttributes extends unknown>(
  props: TAutocompleteProps<TFormAttributes>
) => {
  const theme = useTheme();
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const [options, setOptions] = useState<TListResource<string | number>>();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    service,
    servicePayloadListRules,
    form,
    name,
    textFieldProps,
    label,
    showRequired,
    ...autocompleteProps
  } = props;
  // If Autocomplete component is used as a multiple, then service might return error per option item, adding support
  // for showing error in such case
  const optionErrorName = props.multiple
    ? Object.keys(form?.validationErrors ?? {}).find((key) =>
        key.startsWith(`${name}.`)
      )
    : undefined;
  const fieldHasError =
    form?.hasError(name as keyof TFormAttributes) ||
    form?.hasError(optionErrorName as keyof TFormAttributes);
  const fieldError =
    form?.validationErrors?.[name as keyof TFormAttributes] ??
    form?.validationErrors?.[optionErrorName as keyof TFormAttributes];

  const serviceInputChangeHandler = debounce((e: SyntheticEvent) => {
    const term = (e?.target as HTMLInputElement)?.value;
    if (!term) return;

    setLoading(true);
    service!
      .dispatch({ urlPath: term })
      .then((payload: any) =>
        setOptions(servicePayloadListRules?.(payload) ?? payload)
      )
      .catch(() => setOptions([]))
      .finally(() => setLoading(false));
  });

  return (
    <MuiAutocomplete
      noOptionsText="Type to search"
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          error={!!name && fieldHasError}
          helperText={
            !!name && fieldHasError ? fieldError : textFieldProps?.helperText
          }
          InputProps={{
            ...params.InputProps,
            ...textFieldProps?.InputProps,
          }}
          form={form}
          name={`${name}-text`}
          label={label}
          placeholder={props.placeholder}
          showRequired={showRequired}
          // We do not want the text information added to the form since onInputChange is the driven
          // event for this component
          onChange={(_) => {}}
        />
      )}
      loading={loading}
      onInputChange={service && serviceInputChangeHandler}
      value={
        props.options
          ? // the scenario of manual setting options and potentially value to be auto selected
            (!!name && form?.get(name as keyof TFormAttributes)) ??
            (props.multiple ? [] : null)
          : // the scenario of async select, since no options are logical for async pick only
            undefined
      }
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      clearIcon={
        <ClearRounded
          fontSize="small"
          sx={{ color: theme.palette.taskView.textMainColor }}
        />
      }
      popupIcon={
        <ArrowDropDownRounded
          fontSize="small"
          sx={{ color: theme.palette.taskView.textMainColor }}
        />
      }
      {...autocompleteProps}
      sx={{
        opacity: props.disabled ? 0.5 : 1,
        ".MuiInputBase-input": {
          color: theme.palette.taskView.textSecondaryColor,
          fontSize: !isTouchDevice ? "15px" : "16px",
          fontWeight: 500,
          WebkitTextFillColor: "unset !important",
        },
        ...props.sx,
      }}
      options={options ?? props.options ?? []}
      onChange={(e, value, reason, details) => {
        props.onChange && props.onChange(e, value, reason, details);
        !!name && form?.changeHandler(name, value);
      }}
    />
  );
};

export default Autocomplete;
