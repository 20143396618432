export enum RatePeriodEnum {
  MONTHLY = "Monthly",
  WEEKLY = "Weekly",
  DAILY = "Daily",
  HOURLY = "Hourly",
}

export const getRatePeriodLabelForRate = (ratePeriod?: string) => {
  const list = {
    [RatePeriodEnum.MONTHLY]: "Monthly Salary",
    [RatePeriodEnum.WEEKLY]: "Weekly Salary",
    [RatePeriodEnum.DAILY]: "Daily Wage",
    [RatePeriodEnum.HOURLY]: "Hourly Rate",
  };

  return list[ratePeriod as RatePeriodEnum] ?? "Rate";
};
