import ReactDOM from "react-dom";
import { TBackdropProps } from "./types/TBackdropProps";
import classes from "./Backdrop.module.css";

const Backdrop = (props: TBackdropProps) => {
  const usePortal = props.usePortal ?? true;
  // Build JSX content
  const content = (
    <div
      id={props.id}
      className={`${classes.backdrop} ${usePortal ? classes.fullscreen : ""}`}
      onClick={(event) => {
        props.closeOnClick &&
          event.target === document.getElementById(props.id) &&
          props.closeHandler(event);
      }}
    >
      {props.children}
    </div>
  );

  if (!usePortal) {
    return content;
  }

  return ReactDOM.createPortal(
    content,
    document.getElementById(`backdrop-root`)!
  );
};

export default Backdrop;
