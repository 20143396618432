import { TBacklogItemMenuProps } from "./types/TBacklogItemMenuProps";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import { Box, Typography } from "@mui/material";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { backlogItemViewActions } from "../../../../../backlog/modules/view/slices/viewSlice";
import { Timeline } from "@mui/icons-material";

const MoveToTaskMenuItem = (props: TBacklogItemMenuProps) => {
  const dispatch = useAppDispatch();

  return menuItem.make(
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      onClick={() => {
        dispatch(
          backlogItemViewActions.openMoveToTaskDialog({
            backlogItemRef: props.backlogItem.ref,
            boardRef: props.backlogItem.boardRef,
          })
        );
      }}
    >
      <Timeline />
      <Typography variant="subtitle2" fontWeight="400">
        Add to timeline
      </Typography>
    </Box>
  );
};

export default MoveToTaskMenuItem;
