import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IBacklogViewItemDataRequest } from "../../../interfaces/IBacklogViewItemDataRequest";
import { IBacklogViewDataResource } from "../interfaces/IBacklogViewDataResource";
import { listingActions } from "../../../../../common/modules/listing/slices/listingSlice";
import { useAppDispatch } from "../../../../../common/hooks/redux";

export const useFetchItemDataService = (props: {
  boardRef: string;
  query: IBacklogViewItemDataRequest;
}) => {
  const dispatch = useAppDispatch();
  const response = useHttpClientDispatchable({
    url: `/board/${props.boardRef}/backlogView/itemData`,
    method: RequestMethodEnum.GET,
    query: props.query,
    events: {
      beforeTransform: (data: IBacklogViewDataResource) => {
        dispatch(
          listingActions.setFilterableCustomFields(data.filterableCustomFields)
        );
      },
    },
  });

  return {
    ...response,
    data: response.data as IBacklogViewDataResource | null,
  };
};
