import moment, { Moment } from "moment";
import { pointConverter } from "../../../../../common/utils/pointConverter";
import { useMemo } from "react";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import { string } from "../../../../../common/utils/string";
import { FORMAT_LABEL } from "../../../../../common/utils/date";

let scrollCheckpoints: number[] = [];
let timelineScrollCheckpoints: number[] = [];

let lastAllowedDate: Moment | null = null;
let lastScrollPosition: number | null = null;
export let lastContainerRef: HTMLDivElement | null = null;
let lastTimelineMinDate: Moment;

export let daysRegistry: { [key: string]: { day: string; coord: number } } = {};

let timeout: any;

export const resetScrollCheckpoints = () => {
  scrollCheckpoints = [];
  timelineScrollCheckpoints = [];
  lastAllowedDate = null;
  resetDaysRegistry();
};

export const resetDaysRegistry = () => {
  daysRegistry = {};
};

const getLastAllowedDate = () => lastAllowedDate;

export const captureTimelineMinDate = (date: Moment) => {
  lastTimelineMinDate = date;
};

export const captureLastScrollPosition = (position: number) => {
  lastScrollPosition = position;
};

export const captureLastContainerRef = (ref: HTMLDivElement) => {
  lastContainerRef = ref;
};

export const addDayToRegistry = (day: string, index: number) => {
  daysRegistry[index] = {
    day,
    coord: index * pointConverter.oneTSPointToPixels,
  };
};

export const scrollTo = (coord?: number) => {
  lastContainerRef && coord && (lastContainerRef.scrollLeft = coord);
};

export const getDayByCoords = (xCoord: number) => {
  let keys = Object.keys(daysRegistry).map((key) => parseInt(key));
  for (let i = 0; i < keys.length; i++) {
    if (i === keys.length - 1) {
      // Check if it's the last element
      // If it's the last element, and xCoord is greater than or equal to this coordinate, return this day
      if (xCoord >= daysRegistry[keys[i]].coord) {
        return {
          day: daysRegistry[keys[i]].day,
          coord: daysRegistry[keys[i]].coord,
        };
      }
    } else if (
      daysRegistry[keys[i]].coord <= xCoord &&
      xCoord < daysRegistry[keys[i + 1]].coord
    ) {
      // If xCoord is between the current and the next, return the current day
      return {
        day: daysRegistry[keys[i]].day,
        coord: daysRegistry[keys[i]].coord,
      };
    }
  }
};

const getCoordsByDay = (day: string) => {
  const keys = Object.keys(daysRegistry).map((key) => parseInt(key));
  for (let i = 0; i < keys.length; i++) {
    // Check if the day is the same as the day in the registry
    if (daysRegistry[keys[i]].day.substring(0, 10) === day.substring(0, 10)) {
      return daysRegistry[keys[i]].coord;
    }
  }
};

export const buildLabel = (date: string, hours: number) => {
  const dateObject = moment(date);

  return `${dateObject.date()} ${dateObject.format(
    FORMAT_LABEL
  )}, ${string.getOrdinal(hours)} hour`;
};

export const useVirtualizationHelper = () => {
  const todayDate = useMemo(() => moment(), []);
  const maxWidth576 = useMedia(QueryEnum.MAX_WIDTH_576);

  return {
    canLoad: (date: Moment, type: "data" | "timeline" = "data") => {
      const data =
        type === "data" ? scrollCheckpoints : timelineScrollCheckpoints;
      const xCoord = pointConverter.intervalToXPositionInPixels(
        todayDate,
        date
      );

      if (!data.includes(xCoord)) {
        data.push(xCoord);
        lastAllowedDate = date;
        return true;
      }

      return false;
    },
    getLastAllowedDate,
    getDayByCoords,
    getCoordsByDay,
    scrollToToday: (timelineMinDate: Moment | null = null) => {
      const todayPixels = pointConverter.intervalToXPositionInPixels(
        timelineMinDate ?? lastTimelineMinDate,
        todayDate
      );
      scrollTo(
        // On desktops nice to show few days behind today
        !maxWidth576
          ? todayPixels - pointConverter.oneTSPointToPixels * 3
          : todayPixels
      );
    },
    lastScrollPositionToDate: () => getDayByCoords(lastScrollPosition!),
    jumpOverDetected: (
      coords: number,
      minDateStr: string,
      maxDateStr: string,
      callback: (date: string) => void
    ) => {
      const scrolledDate = getDayByCoords(coords)?.day;
      if (!scrolledDate) {
        return;
      }

      timeout && clearTimeout(timeout);

      if (scrolledDate < minDateStr || scrolledDate > maxDateStr) {
        timeout = setTimeout(() => {
          callback(scrolledDate as string);
        }, 500);
      }
    },
  };
};
