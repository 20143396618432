import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClientDispatchableOnly } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchableOnly";
import { EntityTypeEnum } from "../../../entities/enums/EntityTypeEnum";

export const useAttachmentLinkService = (props: {
  entityType: EntityTypeEnum;
  entityId: number;
}) =>
  useHttpClientDispatchableOnly({
    url: `/attachment/${props.entityType}/${props.entityId}`,
    method: RequestMethodEnum.POST,
  });
