import { Autocomplete } from "../../../../../common/modules/materialUI";
import { TCommonProps } from "./types/TCommonProps";
import { TCreateFormAttributes } from "../types/TCreateFormAttributes";
import { useEffect } from "react";
import { FormHelper } from "../helpers/FormHelper";
import { enumUtil } from "../../../../../common/utils/enum";
import { NotificationMethodEnum } from "../../../../../entities/enums/NotificationMethodEnum";

const NotificationMethod = ({ form }: TCommonProps<TCreateFormAttributes>) => {
  const formHelper = FormHelper.withForm(form.data);

  useEffect(() => {
    return () => {
      form.changeHandler("notificationMethods", undefined);
    };
  }, []);

  return (
    <Autocomplete
      form={form}
      name="notificationMethods"
      label="Notification Methods"
      options={enumUtil.toList(NotificationMethodEnum)}
      showRequired
      disabled={!formHelper.hasSelectedValue()}
      multiple
    />
  );
};

export default NotificationMethod;
