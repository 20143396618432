import UploadIconButton from "../../iconLibrary/containers/IconLibraryPicker/UploadIconButton";
import { Box } from "@mui/material";
import useGetSignedUploadUrlToCloud from "../../../common/hooks/useGetSignedUploadUrlToCloud/useGetSignedUploadUrlToCloud";
import { TFileData } from "../../../common/hooks/useGetSignedUploadUrlToCloud/types/TFileData";
import useUploadFileToCloud from "../../../common/hooks/useUploadFileToCloud/useUploadFileToCloud";
import { useAppDispatch } from "../../../common/hooks/redux";
import { useNetworkingGetSignedUploadUrlService } from "../services/useNetworkingGetSignedUploadUrlService";
import { useState } from "react";
import { useCreateService } from "../services/useCreateService";
import { networkingActions } from "../slices/networkingSlice";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";

export default function ScanNew() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const { dispatch: dispatchGetSignedUploadUrlToCloud } =
    useGetSignedUploadUrlToCloud();
  const { dispatch: dispatchUploadIconToCloud } = useUploadFileToCloud();
  const { dispatch: dispatchNetworkingGetSignedUploadUrlService } =
    useNetworkingGetSignedUploadUrlService();
  const { dispatch: dispatchCreateNetworkingRecord } = useCreateService();

  const fileLoadHandler = (file: File) => {
    if (!file) {
      return;
    }

    setIsLoading(true);

    // Trying to get image resolution
    const fileURL = URL.createObjectURL(file);
    const img = new Image();
    img.onload = () => {
      const fileData: TFileData = {
        file,
        resolution: {
          width: img.width,
          height: img.height,
        },
      };

      // Get upload signed url
      dispatchGetSignedUploadUrlToCloud(
        fileData,
        dispatchNetworkingGetSignedUploadUrlService,
        (resource) => {
          // Intending to upload the file to the cloud using the signed url
          dispatchUploadIconToCloud(resource.signedUrl, fileData.file, () => {
            dispatchCreateNetworkingRecord({
              body: { asset: resource.hashName },
            })
              .then((resource) => {
                dispatch(networkingActions.addItem(resource));
                dispatch(
                  systemNotificationActions.open({
                    message: "Created successfully",
                    variant: "success",
                  })
                );
              })
              .catch((error) =>
                console.error("Error creating networking record", error)
              )
              .finally(() => setIsLoading(false));
          });
        },
        (error) => console.error("Error fetching signed url", error)
      );

      // Clean up the temporary URL
      URL.revokeObjectURL(fileURL);
    };

    img.src = fileURL;
  };

  return (
    <Box>
      <UploadIconButton loading={isLoading} onFileLoad={fileLoadHandler}>
        Scan new badge
      </UploadIconButton>
    </Box>
  );
}
