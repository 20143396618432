import { ListItemIcon, Typography } from "@mui/material";
import { SmartToy } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { TBoardDefaultProps } from "../../types/TBoardDefaultProps";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";

const IntegrationMenuItem = (props: TBoardDefaultProps) => {
  return menuItem.make(
    <NavLink to={`${props.projectAbbr}/board/${props.boardRef}/integration`}>
      <ListItemIcon>
        <SmartToy />
      </ListItemIcon>
      <Typography sx={{ pl: 2 }}>Board Integrations</Typography>
    </NavLink>
  );
};

export default IntegrationMenuItem;
