import { useTheme } from "@mui/material";
import { AggStatusEnum } from "../../../entities/columns/task/AggStatusEnum";

export default function useStatusHelper() {
  const theme = useTheme();
  const statusTypeSchema = theme.palette.statusTypeSchema;

  return {
    getStatusTypeColor: (type: AggStatusEnum) => {
      const colorMap = {
        [AggStatusEnum.TO_DO]: statusTypeSchema.toDo.color,
        [AggStatusEnum.IN_PROGRESS]: statusTypeSchema.inProgress.color,
        [AggStatusEnum.DONE]: statusTypeSchema.done.color,
      };

      return colorMap[type];
    },
    theme,
  };
}
