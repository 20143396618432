import { useDispatchEvent } from "../../../common/modules/eventProvider";
import { useAppDispatch } from "../../../common/hooks/redux";
import { useUpdateObjectiveService } from "../services/useUpdateObjectiveService";
import { THandleObjectiveFormPatchProps } from "./types/THandleObjectiveFormPatchProps";
import { EventEnum } from "../../../common/modules/eventProvider/enums/EventEnum";
import { THttpClientError } from "../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useFetchObjectiveService } from "../services/useFetchObjectiveService";

export default function useHandleObjectiveFormPatch({
  objective,
}: THandleObjectiveFormPatchProps) {
  const dispatchEvent = useDispatchEvent();
  const dispatch = useAppDispatch();
  const { dispatch: dispatchUpdateObjectiveService } =
    useUpdateObjectiveService(objective.id);

  const { dispatch: dispatchFetchObjective } = useFetchObjectiveService({
    objectiveRef: objective.ref,
  });

  return {
    dispatchUpdateObjective: (
      attributes: {
        name?: string;
        startDate?: string;
        endDate?: string;
      },
      onSuccess?: () => void,
      onError?: (error: THttpClientError) => void
    ) => {
      dispatchUpdateObjectiveService({
        body: attributes,
      })
        .then(() => {
          dispatchEvent(EventEnum.ON_OBJECTIVE_MAIN_DATA_UPDATED, objective);
          dispatchFetchObjective();
          onSuccess?.();
        })
        .catch((error: THttpClientError) => {
          error.status === 422 &&
            dispatch(
              systemNotificationActions.open({
                message: (error.data.errors as any)[
                  Object.keys(error.data.errors)[0]
                ][0],
                variant: "error",
              })
            );
          error.status !== 422 &&
            dispatch(
              systemNotificationActions.open({
                message: `There was a problem with updating the objective data. Please try again.`,
                variant: "error",
              })
            );

          onError?.(error);
        });
    },
  };
}
