import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IConnectSlackUserRequest } from "../interfaces/IConnectSlackUserRequest";
import { IConnectSlackUserResource } from "../interfaces/IConnectSlackUserResource";

export const useConnectSlackUserService = (props: {
  body: IConnectSlackUserRequest;
}) => {
  const response = useHttpClientDispatchable({
    url: "/integration/slackConfig/connectUser",
    method: RequestMethodEnum.POST,
    body: props.body,
  });

  return {
    ...response,
    data: response.data as IConnectSlackUserResource,
  };
};
