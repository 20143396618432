import CreateProjectWithModal from "../../../../../modules/project/containers/CreateProject/CreateProjectWithModal";
import { NavLink } from "react-router-dom";
import Card from "./Card/Card";
import Image from "./Card/Image";
import CardText from "./CardText/CardText";
import CelebrationIcon from "./CardText/CelebrationIcon";

export default function ProjectStep() {
  return {
    label: "Create Your First Project",
    content: (
      <Card
        title="Create your project"
        description="Projects are the top level organizational units."
        button={
          <CreateProjectWithModal
            buttonProps={{
              variant: "contained",
              children: "Create",
              size: "small",
            }}
          />
        }
        icon={<Image imageName="project-step-v2" />}
      />
    ),
    contentCompleted: (
      <CardText
        title={
          <>
            Now that you have your first project set up, you can easily create
            new projects from the{" "}
            <NavLink
              style={{ textDecoration: "underline" }}
              to="/settings/project"
            >
              Project Management
            </NavLink>{" "}
            page.
          </>
        }
        icon={<CelebrationIcon />}
      />
    ),
  };
}
