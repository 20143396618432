import { Grid, useTheme } from "@mui/material";
import ObjectiveViewHeader from "./Header/ObjectiveViewHeader";
import { TObjectiveContentProps } from "./types/TObjectiveContentProps";
import { MAIN_CONTENT_PADDING_BOTTOM } from "../../../../common/containers/Layout/App/Content/Content";
import ObjectiveViewMainContent from "./ObjectiveViewMainContent/ObjectiveViewMainContent";

const ObjectiveViewMobile = ({
  objective,
  contentHeight,
  isMobileView,
}: TObjectiveContentProps) => {
  const theme = useTheme();

  return (
    <Grid
      container
      minHeight={contentHeight}
      flexDirection="column"
      wrap="nowrap"
    >
      <Grid item>
        <ObjectiveViewHeader
          objective={objective}
          isMobileView={isMobileView}
        />
      </Grid>

      <Grid
        container
        item
        bgcolor={theme.palette.taskView.mainBackground}
        p="20px 16px 40px"
        pb={`${MAIN_CONTENT_PADDING_BOTTOM}px`}
        flex={1}
        flexDirection="column"
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb="20px"
        >
          <ObjectiveViewMainContent
            objective={objective}
            isMobileView={isMobileView}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ObjectiveViewMobile;
