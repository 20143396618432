import Attachment from "../../../../../modules/attachment/containers/Attachment/Attachment";
import Link from "../../../../../modules/link/containers/Link/Link";
import { TMainPanelsProps } from "./types/TMainPanelsProps";
import { TTabPanel } from "./types/TTabPanel";

export default function MainPanels({
  additionalPanels,
  entityType,
  entityId,
  isMobileView,
  projectRef,
}: TMainPanelsProps) {
  return [
    ...(additionalPanels ?? []),
    {
      tabIndex: "attachments-tab",
      tabLabel: "Attachments",
      tabComponent: (
        <Attachment
          tabIndex="attachments-tab"
          entityType={entityType}
          entityId={entityId}
          isMobileView={isMobileView}
        />
      ),
    },
    {
      tabIndex: "task-links-tab",
      tabLabel: "Links",
      tabComponent: (
        <Link
          tabIndex="task-links-tab"
          isMobileView={isMobileView}
          entityType={entityType}
          entityId={entityId}
          projectRef={projectRef}
        />
      ),
    },
  ] as TTabPanel[];
}
