import { TTextFieldProps } from "./types/TTextFieldProps";
import { useTheme } from "@mui/material";
import React, { useEffect, useRef } from "react";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";
import { MuiColorInput } from "mui-color-input";
import { keyboard } from "../../../../utils/keyboard";

const ColorField = <TFormAttributes extends unknown>(
  props: TTextFieldProps<TFormAttributes>
) => {
  const theme = useTheme();
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const { showRequired, form, error, onEnter, forceFocus, ...textFieldProps } =
    props;
  const { name } = textFieldProps;
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    forceFocus && ref?.current?.focus();
  }, [forceFocus]);

  return (
    <MuiColorInput
      inputRef={ref}
      error={(!!name && form?.hasError(name as keyof TFormAttributes)) || error}
      value={(!!name && form?.get(name as keyof TFormAttributes)) ?? ""}
      fullWidth
      variant="standard"
      isAlphaHidden
      format="hex"
      InputProps={{
        sx: {
          ".MuiInputBase-input": {
            color: theme.palette.taskView.textSecondaryColor,
            fontSize: !isTouchDevice ? "15px" : "16px",
            fontWeight: 500,
          },
        },
      }}
      {...textFieldProps}
      sx={{ opacity: props.disabled ? 0.5 : 1, ...textFieldProps?.sx }}
      InputLabelProps={{
        required: showRequired || false,
        ...props.InputLabelProps,
      }}
      helperText={
        !!name && form?.hasError(name as keyof TFormAttributes)
          ? (form.validationErrors?.[name! as keyof TFormAttributes] as string)
          : textFieldProps.helperText
      }
      onChange={(color) => {
        !!name && form?.changeHandler(name, color);
      }}
      onKeyDown={(e) => {
        props.onKeyDown?.(e);
        onEnter && keyboard.onEnter(e, () => onEnter(e, ref));
      }}
    />
  );
};

export default ColorField;
