import { QueryEnum } from "../../../hooks/useMedia/enums/QueryEnum";
import { TThemeState } from "../slices/types/TThemeState";

export const themeUtil: {
  STORAGE_KEY: string;
  userHasPreferredMode: () => boolean;
  getUserPreferredMode: () => TThemeState["mode"];
  setUserPreferredMode: (mode: TThemeState["mode"]) => void;
  getBrowserPreferredMode: () => TThemeState["mode"];
  getPreferredMode: () => TThemeState["mode"];
  setBrowserMeta: (mode: TThemeState["mode"]) => void;
} = {
  STORAGE_KEY: "appearance",

  userHasPreferredMode: () =>
    ["dark", "light"].includes(
      localStorage.getItem(themeUtil.STORAGE_KEY) ?? ""
    ),

  getUserPreferredMode: () =>
    localStorage.getItem(themeUtil.STORAGE_KEY) as TThemeState["mode"],

  setUserPreferredMode: (mode) => {
    themeUtil.setBrowserMeta(mode);
    localStorage.setItem(themeUtil.STORAGE_KEY, mode);
  },

  getBrowserPreferredMode: () =>
    window.matchMedia(QueryEnum.PREFERS_DARK_THEME).matches ? "dark" : "light",

  getPreferredMode: () => {
    const browserPreferredMode = themeUtil.getBrowserPreferredMode();

    if (themeUtil.userHasPreferredMode()) {
      const userPreferredMode = themeUtil.getUserPreferredMode();
      browserPreferredMode !== userPreferredMode &&
        themeUtil.setBrowserMeta(userPreferredMode);

      return userPreferredMode;
    }

    return browserPreferredMode;
  },

  setBrowserMeta: (mode) => {
    // Set the meta tag so browser default behavior
    // is changed also, e.g. for scrollbars
    document
      .querySelector('meta[name="color-scheme"]')
      ?.setAttribute("content", mode);
  },
};
