import { TShortcutProTipProps } from "./types/TShortcutProTipProps";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";
import KeyboardKey from "../KeyboardKey/KeyboardKey";
import { Typography } from "@mui/material";

export default function ShortcutProTip(props: TShortcutProTipProps) {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);

  const fontSize = (props.size ?? "small") === "small" ? "0.625rem" : "0.75rem";

  return !isTouchDevice ? (
    <Typography
      component="div"
      sx={{ color: "text.secondary", fontSize, ...props.sx }}
    >
      <strong>Pro tip</strong>: Press{" "}
      {props.shortcutKeyString && (
        <KeyboardKey
          size={fontSize}
          keyName={props.shortcutKeyString.toUpperCase()}
        />
      )}
      {props.shortcutKeyElement && props.shortcutKeyElement} to{" "}
      {props.description}
    </Typography>
  ) : null;
}
