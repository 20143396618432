import { Grid } from "@mui/material";
import Title from "../../../../../common/components/Title/Title";
import { Button } from "../../../../../common/modules/materialUI";
import { useNavigate } from "react-router-dom";
import { env } from "../../../../../common/utils/env";

const RegistrationSuccess = () => {
  const navigate = useNavigate();

  const loginPageHandler = () => {
    navigate("/auth");
  };

  return (
    <Grid item>
      <Title>
        Thank you for joining {env.appName}! Please login to continue.
      </Title>
      <Button variant="text" size="large" onClick={loginPageHandler}>
        Login
      </Button>
    </Grid>
  );
};

export default RegistrationSuccess;
