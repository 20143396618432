import { Grid, ListItemIcon, Typography } from "@mui/material";
import AvatarIcon from "../AvatarIcon/AvatarIcon";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { env } from "../../../../common/utils/env";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { useEffect, useMemo } from "react";
import { userActions } from "../../slices/userSlice";
import { jwt } from "../../../../common/utils/jwt";

const AccountSettings = () => {
  const dispatch = useAppDispatch();
  const userJwt = useAppSelector((state) => state.user.userInfo);
  const url = "/user/account";

  const jwtParsed = useMemo(() => jwt.parse(), [userJwt?.id]);

  useEffect(() => {
    jwtParsed?.id && dispatch(userActions.setUserInfo(jwtParsed));
  }, [jwtParsed?.id]);

  return menuItem.make(
    <>
      <NavLink to={url}>
        <ListItemIcon>
          <AvatarIcon />
        </ListItemIcon>
      </NavLink>
      <Grid container>
        <Grid item xs={12} sx={{ pl: 2 }}>
          <Typography>
            <NavLink to={url}>{userJwt?.name}</NavLink>
          </Typography>
          <Typography color="gray" fontSize="small">
            <NavLink to={url}>{userJwt?.email}</NavLink>
          </Typography>
          <Typography fontSize={11} sx={{ mt: 1 }}>
            <NavLink to={url}>Manage your {env.appName} account</NavLink>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountSettings;
