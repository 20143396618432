import { INetwork } from "../../../entities/INetwork";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Collapse,
  IconButton,
  IconButtonProps,
  Typography,
} from "@mui/material";
import { env } from "../../../common/utils/env";
import { Edit, ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { useState } from "react";
import { networkingActions } from "../slices/networkingSlice";
import { useAppDispatch } from "../../../common/hooks/redux";
import AttachAsset from "./AttachAsset";
import { getInteractionColor } from "../enums/NetworkInteractionEnum";

type TProps = {
  item: INetwork;
};

type TExpandMoreProps = { expand: boolean } & IconButtonProps;

const ExpandMore = (props: TExpandMoreProps) => {
  const { expand, ...other } = props;

  return (
    <IconButton
      {...other}
      sx={{
        transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
        marginLeft: "auto",
      }}
    />
  );
};

const getAssetUrl = (name: string) =>
  `${env.cdnUrl}/ai4-2mu0diwdvNchcpzaugOxQ1dyGz4zbbqf/${name}`;

export default function ListItem({ item }: TProps) {
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Card>
      {(item.name || item.company) && (
        <CardHeader title={item.name} subheader={item.company} />
      )}
      <CardMedia
        component="img"
        height="194"
        image={getAssetUrl(item.assets[0])}
        alt="Network record image"
      />
      {!!item.tags?.length && (
        <CardContent>
          <Box display="flex" gap={1} sx={{ overflowX: "auto" }}>
            {item.tags.map((tag, index) => (
              <Chip key={index} label={tag} />
            ))}
          </Box>
        </CardContent>
      )}
      {!!item.interaction?.length && (
        <CardContent sx={{ pt: 0 }}>
          <Box display="flex" flexDirection="column" gap={1}>
            {item.interaction.map((interaction, index) => (
              <Chip
                key={index}
                label={interaction}
                sx={{ backgroundColor: getInteractionColor(interaction) }}
              />
            ))}
          </Box>
        </CardContent>
      )}
      <CardActions disableSpacing>
        <IconButton
          aria-label="Edit"
          onClick={() => dispatch(networkingActions.setEditableItem(item))}
        >
          <Edit fontSize="small" />
        </IconButton>
        <AttachAsset item={item} />
        <ExpandMore
          expand={expanded}
          onClick={() => setExpanded((prevState) => !prevState)}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Box display="flex" gap={1} sx={{ overflowX: "auto" }}>
            {item.assets.map((asset, index) => (
              <a
                key={index}
                href={getAssetUrl(asset)}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  width="50"
                  height="50"
                  src={getAssetUrl(asset)}
                  alt="Network record image"
                />
              </a>
            ))}
          </Box>
        </CardContent>
        <CardContent>
          <Typography paragraph>
            {item.notes || "No notes available"}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
