import { TInfoTabsProps } from "./types/TInfoTabsProps";
import MainPanels from "./MainPanels";
import { EntityTypeEnum } from "../../../../../entities/enums/EntityTypeEnum";
import CommonInfoTabs from "../../../../../common/modules/taskView/containers/InfoTabs/InfoTabs";

export default function InfoTabs({ objective, isMobileView }: TInfoTabsProps) {
  return (
    <CommonInfoTabs
      mainPanels={MainPanels({
        objective,
        entityType: EntityTypeEnum.OBJECTIVE,
        entityId: objective.id,
        isMobileView,
      })}
      storageKey="unpinned-objective-view-tabs"
      isMobileView={isMobileView}
    />
  );
}
