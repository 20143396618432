import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { IPatchResource } from "../interfaces/IPatchResource";

export const usePatchUserService = () => {
  const response = useHttpClientDispatchable({
    url: "/user/",
    method: RequestMethodEnum.PATCH,
  });

  return {
    ...response,
    data: response.data as IPatchResource,
  };
};
