import { TVerifyMailProps } from "./types/TVerifyMailProps";
import { useVerifyMailService } from "../../../../user/services/useVerifyMailService";
import Verify from "../Verify/Verify";
import { Email } from "@mui/icons-material";

const VerifyMail = (props: TVerifyMailProps) => {
  const service = useVerifyMailService();

  return (
    <Verify
      resendService={props.resendService}
      inquiryUuid={props.inquiryUuid}
      verifyService={props.verifyService ?? service}
      type="email"
      resentMessage="A new verification code has been sent to your email address"
      helperText={
        <>
          We have sent you a 4 digit code to <b>your email</b> address. Please
          enter it here to verify it.
        </>
      }
      icon={<Email sx={{ pr: 1 }} />}
      onNextHandler={props.onNextHandler}
    />
  );
};

export default VerifyMail;
