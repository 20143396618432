import { TTaskTypeAutocompleteProps } from "./types/TTaskTypeAutocompleteProps";
import { iconLibraryUtil } from "../../../iconLibrary/utils/iconLibraryUtil";
import { Autocomplete } from "../../../../common/modules/materialUI";
import { taskTypeUtil } from "../../utils/taskTypeUtil";

const TaskTypeAutocomplete = <TFormAttributes extends unknown>({
  form,
  name,
  loading,
  options,
  taskTypes,
}: TTaskTypeAutocompleteProps<TFormAttributes>) => {
  return (
    <Autocomplete
      form={form}
      name={name}
      label="Task Type"
      loading={loading}
      options={options}
      renderOption={(props, option) =>
        iconLibraryUtil.getAutocompleteRenderOption(
          props,
          option,
          taskTypeUtil.getSelectedTaskType(taskTypes, option)?.iconFilePath
        )
      }
      textFieldProps={{
        InputProps: {
          startAdornment: iconLibraryUtil.getAutocompleteInputStartAdornment(
            taskTypeUtil.getSelectedTaskType(
              taskTypes,
              form?.get("taskTypeId" as keyof TFormAttributes)
            )?.iconFilePath
          ),
        },
      }}
    />
  );
};

export default TaskTypeAutocomplete;
