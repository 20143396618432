import TDescriptionEditorProps from "./types/TDescriptionEditorProps";
import { useRef } from "react";
import { Box } from "@mui/material";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { keyboard } from "../../../../common/utils/keyboard";
import {
  Editor,
  useIOHandler,
  useUploadAttachmentHandler,
} from "../../../../common/modules/editor";
import Actions from "../../../task/modules/view/containers/Actions/Actions";
import ProTipSaveChanges from "../../../../common/modules/editor/tinymce/containers/ProTip/ProTipSaveChanges";
import useMentionHandler from "../../../../common/modules/editor/tinymce/hooks/useMentionHandler";

export default function DescriptionEditor({
  entityType,
  entityId,
  html,
  onCancel,
  onSave,
  onSaveFailure,
  onInit,
  style,
  saveService,
  isMobileView,
}: TDescriptionEditorProps) {
  const dispatch = useAppDispatch();
  const ref = useRef<any>();
  const { fetchEditorState } = useIOHandler();
  const uploadAttachmentHandler = useUploadAttachmentHandler({
    entityType,
    entityId,
  });
  const mentionHandler = useMentionHandler(html ?? "");

  const submitHandler = () => {
    const editorState = fetchEditorState(ref);
    onSave?.(editorState);
    const mentions = mentionHandler.fetchNewMentions(editorState.html);

    saveService
      .dispatch({ body: { html: editorState.html, mentions: mentions } })
      .catch((_) => {
        onSaveFailure?.(editorState);
        dispatch(
          systemNotificationActions.open({
            message:
              "There was a problem with changing the description. Please try again.",
            variant: "error",
          })
        );
      });
  };

  const cancelHandler = () => {
    onCancel?.(fetchEditorState(ref));
  };

  return (
    <div style={style}>
      <Editor
        autoFocus
        html={html}
        ref={ref}
        onInit={onInit}
        onKeyDown={(event) => keyboard.onControlEnter(event, submitHandler)}
        uploadHandler={uploadAttachmentHandler}
        onSave={submitHandler}
      />
      <Box p={isMobileView ? "0 16px" : 0}>
        <ProTipSaveChanges />
        <Actions submitHandler={submitHandler} cancelHandler={cancelHandler} />
      </Box>
    </div>
  );
}
