import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

const Loader = () => {
  const [progress, setProgress] = useState<number>(0);

  // It will take almost 1 second (95 / 5 * 50) until the progress bar gets to 95% that is more than enough since the
  // service is never supposed to take such a long response time
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevState) => (prevState < 95 ? prevState + 5 : prevState));
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return ReactDOM.createPortal(
    <ProgressBar
      progress={progress}
      size="xxs"
      bg="bg-green-g"
      parentBgTransparent
    />,
    document.getElementById("content-loader")!
  );
};

export default Loader;
