import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../hooks/redux";
import { DarkMode, LightMode } from "@mui/icons-material";
import { themeActions } from "../../../../../../../modules/theme/slices/themeSlice";
import TListItemProps from "../../../../../../../components/Navigation/ListItem/types/TListItemProps";
import { themeUtil } from "../../../../../../../modules/theme/utils/themeUtil";
import { useState, useTransition } from "react";
import { TSectionProps } from "../../../types/TSectionProps";

const ThemeToggleListItem = (props: TSectionProps) => {
  const dispatch = useAppDispatch();
  const currentMode = useAppSelector((state) => state.theme.mode);
  const [mode, setMode] = useState(currentMode);
  const isLightMode = mode === "light";
  const [, startTransition] = useTransition();

  return {
    id: "themeToggle",
    name: isLightMode ? "Dark mode" : "Light mode",
    icon: isLightMode ? <DarkMode /> : <LightMode />,
    onClick: () => {
      const mode = isLightMode ? "dark" : "light";
      setMode(mode);
      startTransition(() => {
        dispatch(themeActions.setMode(mode));
        themeUtil.setUserPreferredMode(mode);
        props.onClick?.();
      });
    },
    path: "#",
    navigateToPath: false,
  } as TListItemProps;
};

export default ThemeToggleListItem;
