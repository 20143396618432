import { IBoard } from "../../../../entities/IBoard";
import ProgressColumn from "../../../objective/containers/View/Initiatives/Columns/ProgressColumn";
import { IData } from "../../../../entities/columns/objective/IData";
import React, { useState } from "react";
import { useSubscribeEvent } from "../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import { useFetchBoardDispatchableService } from "../../services/useFetchBoardDispatchableService";
import { BoardTypeEnum } from "../../../../entities/columns/board/BoardTypeEnum";

type TProps = {
  board: IBoard;
};

export default function Progress({ board }: TProps) {
  const [data, setData] = useState<IData>({
    progress: board.progress,
    allCount: board.task_count,
    doneCount: board.task_agg_done_count,
  } as IData);
  const { dispatch } = useFetchBoardDispatchableService({
    ref: board.ref!,
  });

  useSubscribeEvent(
    EventEnum.ON_TASK_STATUS_UPDATED,
    () => {
      // Setting timeout since progress calculation is done on background
      setTimeout(() => {
        dispatch()
          .then((payload: IBoard) => {
            setData({
              progress: payload.progress,
              allCount: payload.task_count,
              doneCount: payload.task_agg_done_count,
            } as IData);
          })
          .catch(() => {});
      }, 5000);
    },
    []
  );

  return (
    <ProgressColumn
      initiative={data}
      sx={{ position: "relative", top: "-20px" }}
      extraMessage={
        board.type === BoardTypeEnum.HOLISTIC ? (
          <div>
            Here the progress is calculated only for the items created in the
            Holistic Board. Progresses of initiatives can be seen in their
            respective boards.
          </div>
        ) : undefined
      }
    />
  );
}
