import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { Box, Typography } from "@mui/material";
import { Clear, FilterList } from "@mui/icons-material";
import React from "react";
import { TBoardQueryState } from "../../../slices/types/TBoardQueryState";
import { boardQueryActions } from "../../../slices/boardQuerySlice";
import { TListItem } from "../../../../../common/types/TListItem";

const ExtraFilters = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.boardQuery.extraFilters);
  const filterExists = Object.values(filters).some(
    (value) => value !== null && value.length > 0
  );

  const removeFilterHandler = (
    filterItem: TListItem<number | string>,
    key: keyof TBoardQueryState["extraFilters"]
  ) => {
    const filter = filters[key].find((item) => item.id === filterItem.id);
    if (filter) {
      const filtersList = filters[key].filter(
        (filterItem) => filterItem.id !== filter.id
      );
      dispatch(
        boardQueryActions.setExtraFiltering({
          ...filters,
          [key]: filtersList,
        })
      );
    } else {
      const filtersList = [...filters.taskTypeIds, filterItem];
      boardQueryActions.setExtraFiltering({
        ...filters,
        [key]: filtersList,
      });
    }
  };

  if (!filters || !filterExists) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      gap={2}
      sx={{ my: 2, color: "text.secondary" }}
    >
      <FilterList />
      {Object.keys(filters).map((key) => {
        const filterKey = key as keyof TBoardQueryState["extraFilters"];
        return filters[filterKey].map((filterItem, index) => (
          <Box
            key={key + index}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            sx={{ cursor: "pointer" }}
            gap={0.2}
            onClick={() => removeFilterHandler(filterItem, filterKey)}
          >
            <Typography sx={{ fontSize: "0.875rem" }}>
              {filterItem.label}
            </Typography>
            <Clear sx={{ fontSize: "14px" }} />
          </Box>
        ));
      })}
    </Box>
  );
};

export default ExtraFilters;
