import { Button } from "../../../../common/modules/materialUI";
import { useState } from "react";
import { Add } from "@mui/icons-material";
import CreateForm from "./CreateForm";
import { Grid } from "@mui/material";

export default function CreateProject() {
  const [formOpen, setFormOpen] = useState<boolean>(false);

  const formDisplayHandler = () => {
    setFormOpen((prevState) => !prevState);
  };

  return (
    <>
      {!formOpen && (
        <Button variant="text" startIcon={<Add />} onClick={formDisplayHandler}>
          Create new project
        </Button>
      )}
      {formOpen && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CreateForm
              onCreate={formDisplayHandler}
              onCancel={formDisplayHandler}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
