import { DialogContent } from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { useFetchObjectiveService } from "../../services/useFetchObjectiveService";
import { objectiveActions } from "../../slices/objectiveSlice";
import useMedia from "../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../common/hooks/useMedia/enums/QueryEnum";
import Dialog from "../../../../common/modules/taskView/containers/Dialog/Dialog";
import TaskSkeleton from "../../../task/modules/view/containers/TaskSkeleton";
import ObjectiveContent from "./ObjectiveContent";

const ObjectiveViewModal = () => {
  const dispatch = useAppDispatch();
  const { objective, objectiveRef } = useAppSelector(
    (state) => state.objective.view
  );
  const { dispatch: dispatchFetchObjective, isLoading } =
    useFetchObjectiveService({ objectiveRef: objectiveRef ?? "" });
  const isMobileView = useMedia(QueryEnum.MAX_WIDTH_1200);

  const closeDialogHandler = () => {
    dispatch(objectiveActions.resetView());
  };

  useEffect(() => {
    objectiveRef && dispatchFetchObjective();
  }, [objectiveRef]);

  return (
    <Dialog
      open={!!objectiveRef}
      isMobileView={isMobileView}
      closeDialogHandler={closeDialogHandler}
    >
      <DialogContent>
        {isLoading && !objective && (
          <TaskSkeleton isMobileView={isMobileView} />
        )}
        {!isLoading && objective && (
          <ObjectiveContent
            objective={objective!}
            isMobileView={isMobileView}
            contentHeight="100%"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ObjectiveViewModal;
