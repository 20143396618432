import { useAppDispatch } from "../../../../../common/hooks/redux";
import { TTaskViewHandlerProps } from "./types/TTaskViewHandlerProps";
import { taskViewActions } from "../slices/viewSlice";

export const useTaskViewHandler = (props: TTaskViewHandlerProps) => {
  const dispatch = useAppDispatch();

  return {
    openTaskViewHandler: () => {
      props.taskRef &&
        dispatch(
          taskViewActions.open({
            taskRef: props.taskRef,
            segmentNumber: props.segmentNumber,
          })
        );
    },
  };
};
