import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { TUseUpdateCustomFieldServiceProps } from "./types/TUseUpdateCustomFieldServiceProps";

export const useUpdateCustomFieldService = ({
  customFieldId,
}: TUseUpdateCustomFieldServiceProps) => {
  return useHttpClientDispatchable({
    url: `/customField/${customFieldId}/update`,
    method: RequestMethodEnum.PATCH,
  });
};
