import { IconButton, Tooltip } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import Menu from "./Menu";
import React from "react";
import { TMoreOptionsMenuProps } from "./types/TMoreOptionsMenuProps";

const MoreOptionsMenu = (props: TMoreOptionsMenuProps) => {
  return (
    <Menu
      stretchOnMobile={false}
      closeOnSelection={props.closeOnSelection ?? true}
      button={(clickHandler) => (
        <Tooltip title="More options" placement="top">
          <IconButton onClick={clickHandler}>
            <MoreHoriz />
          </IconButton>
        </Tooltip>
      )}
      menuItems={props.menuItems}
    />
  );
};

export default MoreOptionsMenu;
