import { ManageAccounts } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { boardViewActions } from "../../slices/boardViewSlice";
import { SideViewSectionEnum } from "../../enums/SideViewSectionEnum";
import { useAppDispatch } from "../../../../common/hooks/redux";
import React from "react";
import { comingSoon } from "../../../../common/utils/comingSoon";
import { useAccessControl } from "../../../../common/modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../common/modules/accessControl/enums/AbilityEnum";
import { TFilterProps } from "../Filter/Filter/types/TFilterProps";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";

const CapacityPlanner = (props: TFilterProps) => {
  const { can } = useAccessControl();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const clickHandler = () =>
    dispatch(boardViewActions.displaySideView(SideViewSectionEnum.CAPACITY));

  return menuItem.make(
    <Box
      style={{
        color: theme.palette.pickerPallet.secondary,
        cursor: "pointer",
      }}
      display="flex"
      alignItems="center"
      gap={1}
    >
      <ManageAccounts fontWeight="400" />
      <Typography variant="subtitle2" fontWeight="400">
        Capacity Planner
      </Typography>
    </Box>,
    comingSoon.alertHandler,
    can(AbilityEnum.manage) && (props.renderCapacityPlanner ?? true)
  );
};

export default CapacityPlanner;
