import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import useForm from "../../../../../common/hooks/useForm/useForm";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import Form from "../../../../../common/components/Form/Form";
import { Button, TextField } from "../../../../../common/modules/materialUI";
import { useVerifyUserMailService } from "../../../services/useVerifyUserMailService";
import { TVerifyUserMailFormAttributes } from "./types/TVerifyUserMailFormAttributes";
import { IUserPasswordResetInquiry } from "../../../../../entities/IUserPasswordResetInquiry";
import { TResetPasswordProps } from "./types/TResetPasswordProps";
import { jwt } from "../../../../../common/utils/jwt";
import { useEffect } from "react";

const VerifyUserMail = (props: TResetPasswordProps) => {
  const user = jwt.parse();
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const form = useForm<TVerifyUserMailFormAttributes>();
  const { isLoading, dispatch: dispatchVerifyMailService } =
    useVerifyUserMailService();

  useEffect(() => {
    if (user) {
      form.set({ email: user.email });
    }
  }, []);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchVerifyMailService({ body: form.data })
      .then((payload: IUserPasswordResetInquiry) => {
        props.onNextHandler && props.onNextHandler(payload.uuid, payload.email);
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <Button
          disabled={isLoading}
          loading={isLoading}
          variant={user ? "contained" : "text"}
          type="submit"
          size={user ? "medium" : "large"}
        >
          Confirm
        </Button>
      }
    >
      <TextField
        forceFocus={!isTouchDevice}
        form={form}
        name="email"
        disabled={!!user}
        label="Email address"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
    </Form>
  );
};

export default VerifyUserMail;
