import { Button } from "../../../common/modules/materialUI";
import { TOffDayButtonProps } from "./types/TOffDayButtonProps";

const OffDayButton = ({
  disabled,
  offDayIndex,
  selected,
  setOffDayData,
  weekDay,
}: TOffDayButtonProps) => {
  const nonWorkingDayHandler = () => {
    setOffDayData((selectedOffDays: number[]) =>
      selected
        ? selectedOffDays.filter((item) => item !== offDayIndex)
        : [...selectedOffDays, offDayIndex].sort()
    );
  };

  return (
    <Button
      disabled={disabled}
      onClick={nonWorkingDayHandler}
      variant={selected ? "contained" : "outlined"}
    >
      {weekDay}
    </Button>
  );
};

export default OffDayButton;
