import { TTaskRowProps } from "./types/TTaskRowProps";
import classes from "./RightContainer.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { string } from "../../../../utils/string";
import { number } from "../../../../utils/number";
import { Tooltip } from "../../../../modules/materialUI";
import TaskMenu from "../Menu/TaskMenu";
import React from "react";
import useGetTaskType from "../../../../../modules/taskType/hooks/useGetTaskType";
import useGetTaskPriority from "../../../../../modules/taskPriority/hooks/useGetTaskPriority";
import AvatarGroup from "../../../../../modules/user/containers/AvatarGroup/AvatarGroup";
import useSprintHelper from "../../../../../modules/boardView/hooks/sprintHelper/useSprintHelper";
import { BoardViewEnum } from "../../../../../modules/boardView/enums/BoardViewEnum";
import { useAppSelector } from "../../../../hooks/redux";

const RightContainer = (props: TTaskRowProps) => {
  const sprintHelper = useSprintHelper({ view: BoardViewEnum.LIST_VIEW });
  const taskType = useGetTaskType({
    taskTypeId: props.task.taskTypeId,
  });
  const taskPriority = useGetTaskPriority({
    taskPriorityId: props.task.taskPriorityId,
  });
  const groupedBySprint = useAppSelector(
    (state) => state.boardView.listView.groupBySprint
  );

  const users =
    props?.users?.filter((user) =>
      props.isSegmentView
        ? user.id === props.task.assignedTo
        : props.task.assignees.includes(user.id)
    ) ?? [];

  return (
    <div className={classes.container}>
      {!groupedBySprint &&
        sprintHelper.label(!!props.isSegmentView, props.task)}

      <Box display="flex" gap={0.5}>
        <Typography variant="caption" lineHeight="normal">
          {number.format(
            props.isSegmentView
              ? props.task.estimationPoints
              : props.task.aggEstimationPoints
          )}
        </Typography>
        <Typography variant="caption" lineHeight="normal">
          {string.acronym(
            props.isSegmentView
              ? props.task.estimationUom
              : props.task.aggEstimationUom
          )}
        </Typography>
      </Box>

      <div>
        {taskPriority ? (
          <Tooltip title={taskPriority.name}>
            <img
              src={`${process.env.CDN_URL}/${taskPriority.iconFilePath}`}
              alt={taskPriority.name}
              width={18}
              height={18}
              style={{ display: "flex" }}
            />
          </Tooltip>
        ) : (
          <Box width={18} />
        )}
      </div>

      <div>
        {taskType ? (
          <Tooltip title={taskType.name}>
            <img
              src={`${process.env.CDN_URL}/${taskType.iconFilePath}`}
              alt={taskType.name}
              width={18}
              height={18}
              style={{ display: "flex" }}
            />
          </Tooltip>
        ) : (
          <Box width={18} />
        )}
      </div>

      {!props.hideAvatar && (
        <div>
          {users.length ? (
            <Typography variant="caption">
              <AvatarGroup users={users} />
            </Typography>
          ) : (
            <Grid item width={22} />
          )}
        </div>
      )}

      <TaskMenu
        taskRef={props.task.ref!}
        taskId={props.task.id}
        taskInternalType={props.task.internalType}
        projectRef={props.task.projectRef}
      />
    </div>
  );
};

export default RightContainer;
