import { IEntityFieldDefaultValue } from "../../../entities/IEntityFieldDefaultValue";
import { FieldTypeEnum } from "../../../entities/columns/entityFieldDefaultValue/FieldTypeEnum";
import { TListResource } from "../../../common/types/TListResource";
import { enumUtil } from "../../../common/utils/enum";
import { EstimationUomEnum } from "../../../entities/enums/EstimationUomEnum";
import { ICustomField } from "../../../entities/ICustomField";
import { customFieldInputUtil } from "../../customField/utils/customFieldInputUtil";
import { TCustomFieldValue } from "../../customField/utils/types/TCustomFieldValue";

export const entityFieldDefaultValueUtil = {
  getEntityFieldDefaultValueByName: (
    name: string,
    entityFieldDefaultValues: IEntityFieldDefaultValue[]
  ) => {
    return entityFieldDefaultValues.find(
      (entityFieldDefaultValue) =>
        entityFieldDefaultValue.fieldType === FieldTypeEnum.NATIVE &&
        entityFieldDefaultValue.name === name
    );
  },
  getMultipleEntityFieldDefaultValuesByName: (
    name: string,
    entityFieldDefaultValues: IEntityFieldDefaultValue[]
  ) => {
    return entityFieldDefaultValues.filter(
      (entityFieldDefaultValue) =>
        entityFieldDefaultValue.fieldType === FieldTypeEnum.NATIVE &&
        entityFieldDefaultValue.name === name
    );
  },
  getNativeEntityDefaultValues: (
    entityFieldDefaultValues: IEntityFieldDefaultValue[]
  ) => ({
    taskTypeId: entityFieldDefaultValueUtil.getEntityFieldDefaultValueByName(
      "taskTypeId",
      entityFieldDefaultValues
    )?.value,
    taskPriorityId:
      entityFieldDefaultValueUtil.getEntityFieldDefaultValueByName(
        "taskPriorityId",
        entityFieldDefaultValues
      )?.value,
    estimationPoints:
      entityFieldDefaultValueUtil.getEntityFieldDefaultValueByName(
        "estimationPoints",
        entityFieldDefaultValues
      )?.value,
    estimationUom: entityFieldDefaultValueUtil.getEntityFieldDefaultValueByName(
      "estimationUom",
      entityFieldDefaultValues
    )?.value,
    rep: entityFieldDefaultValueUtil.getEntityFieldDefaultValueByName(
      "rep",
      entityFieldDefaultValues
    ),
    assignedTo: entityFieldDefaultValueUtil
      .getMultipleEntityFieldDefaultValuesByName(
        "assignedTo",
        entityFieldDefaultValues
      )
      .filter(
        (entityFieldDefaultValue) => !!entityFieldDefaultValue.userListItem
      )
      .map((entityFieldDefaultValue) => entityFieldDefaultValue.userListItem!),
  }),
  getSubmittableNativeEntityDefaultValues: (
    entityFieldDefaultValues: IEntityFieldDefaultValue[]
  ) => {
    const fieldValues =
      entityFieldDefaultValueUtil.getNativeEntityDefaultValues(
        entityFieldDefaultValues
      );

    return {
      taskTypeId: fieldValues.taskTypeId ?? undefined,
      taskPriorityId: fieldValues.taskPriorityId ?? undefined,
      estimationPoints: fieldValues.estimationPoints ?? undefined,
      estimationUom: fieldValues.estimationUom ?? undefined,
      rep: fieldValues.rep?.userListItem?.id ?? undefined,
      assignedTo: fieldValues.assignedTo.length
        ? fieldValues.assignedTo.map((assignedTo) => assignedTo.id)
        : undefined,
    };
  },
  getFormNativeEntityDefaultValues: (
    entityFieldDefaultValues: IEntityFieldDefaultValue[],
    taskTypeOptions?: TListResource<string | number>,
    taskPriorityOptions?: TListResource<string | number>
  ) => {
    const estimationUomOptions = enumUtil.toList(
      EstimationUomEnum
    ) as TListResource<string>;

    // Filtered task field default values by name
    const fieldValues =
      entityFieldDefaultValueUtil.getNativeEntityDefaultValues(
        entityFieldDefaultValues
      );

    // Form supported values
    return {
      taskTypeId: fieldValues.taskTypeId
        ? taskTypeOptions?.find(
            (taskTypeOption) =>
              +taskTypeOption.id === +(fieldValues.taskTypeId ?? 0)
          )
        : undefined,
      taskPriorityId: fieldValues.taskPriorityId
        ? taskPriorityOptions?.find(
            (taskPriorityOption) =>
              +taskPriorityOption.id === +(fieldValues.taskPriorityId ?? 0)
          )
        : undefined,
      estimationPoints: fieldValues.estimationPoints
        ? +fieldValues.estimationPoints
        : undefined,
      estimationUom: fieldValues.estimationUom
        ? estimationUomOptions.find(
            (estimationUomOption) =>
              estimationUomOption.id === fieldValues.estimationUom
          )
        : undefined,
      rep: fieldValues.rep?.userListItem ?? undefined,
      assignedTo: fieldValues.assignedTo,
    };
  },
  getFormCustomFieldDefaultValues: (
    entityFieldDefaultValues: IEntityFieldDefaultValue[],
    customFields?: ICustomField[]
  ) => {
    const customFieldValues = entityFieldDefaultValues.filter(
      (entityFieldDefaultValue) =>
        entityFieldDefaultValue.fieldType === FieldTypeEnum.CUSTOM_FIELD
    );

    return customFieldInputUtil.getFormAttributeValues(
      customFieldValues as unknown as TCustomFieldValue[],
      customFields
    );
  },
  getFormDefaultValues: (
    entityFieldDefaultValues: IEntityFieldDefaultValue[],
    taskTypeOptions?: TListResource<string | number>,
    taskPriorityOptions?: TListResource<string | number>,
    customFields?: ICustomField[]
  ) => {
    return {
      ...entityFieldDefaultValueUtil.getFormNativeEntityDefaultValues(
        entityFieldDefaultValues,
        taskTypeOptions,
        taskPriorityOptions
      ),
      ...entityFieldDefaultValueUtil.getFormCustomFieldDefaultValues(
        entityFieldDefaultValues,
        customFields
      ),
    };
  },
};
