import { FormControlLabel, Typography } from "@mui/material";
import Checkbox from "../../../../../../../common/modules/materialUI/components/Checkbox/Checkbox";
import { useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../common/hooks/redux";
import {
  boardViewActions,
  STORAGE_SPLITS_KEY,
} from "../../../../../slices/boardViewSlice";
import { TFilterProps } from "../../../../../containers/Filter/Filter/types/TFilterProps";
import { menuItem } from "../../../../../../../common/containers/Menu/utils/menuItem";

const DisplaySplitsOption = (props: TFilterProps) => {
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState<boolean>(
    useAppSelector((state) => state.boardView.listView.displaySplits)
  );

  const clickHandler = () => {
    setChecked((prevState) => {
      const checked = !prevState;
      localStorage.setItem(STORAGE_SPLITS_KEY, checked ? "true" : "false");
      dispatch(boardViewActions.displaySplits(checked));
      return checked;
    });
  };

  return menuItem.make(
    <FormControlLabel
      control={
        <Checkbox checked={checked} sx={{ py: 0 }} onChange={clickHandler} />
      }
      label={
        <Typography
          sx={{
            color: !checked ? "text.primary" : "text.disabled",
          }}
          variant="body2"
        >
          Display splits
        </Typography>
      }
    />,
    undefined,
    props.renderDisplaySplits
  );
};

export default DisplaySplitsOption;
