import { Box, Grid, Typography } from "@mui/material";
import { TItemProps } from "./types/TItemProps";

const Label = (props: { color: string } & TItemProps) => {
  return (
    <Typography
      component="span"
      variant="caption"
      sx={{
        float: "right",
        fontWeight: "500",
        color: props.color,
      }}
    >
      {props.children}
    </Typography>
  );
};

export default function Item(props: TItemProps) {
  return (
    <Box sx={{ mb: 3 }}>
      <Grid
        container
        spacing={2}
        display="flex"
        justifyContent="space-between"
        flexWrap="nowrap"
      >
        <Grid item flex={1}>
          {props.children}
          {props.description && (
            <Typography
              component="div"
              variant="body2"
              sx={{ color: "text.secondary", fontSize: "0.75rem" }}
            >
              {props.description}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Typography component="p" variant="body1">
            <>
              {props.est === "hot" && (
                <Label color="warning.main">hot lane</Label>
              )}
              {props.est === "soon" && (
                <Label color="text.secondary">soon</Label>
              )}
              {props.est === "live" && (
                <Label color="primary.main">now live</Label>
              )}
            </>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
