import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TRepFormAttributes } from "./types/TRepFormAttributes";
import { Autocomplete } from "../../../../../../common/modules/materialUI";
import { useUserListService } from "../../../../../user/services/useUserListService";
import React, { useEffect, useState } from "react";
import { TRepFormAttributesTransformed } from "./types/TRepFormAttributesTransformed";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { TListItem } from "../../../../../../common/types/TListItem";
import { taskActions } from "../../../../slices/taskSlice";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { TListResource } from "../../../../../../common/types/TListResource";
import { AggStatusEnum } from "../../../../../../entities/columns/task/AggStatusEnum";
import { ITask } from "../../../../../../entities/ITask";
import { useUpdateTaskService } from "../../../../services/useUpdateTaskService";
import { ITaskPatchResource } from "../../interfaces/ITaskPatchResource";
import { TTaskEvent } from "../types/TTaskEvent";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";

export type TProps = {
  userList: TListItem<string>[];
  task: ITask;
};

const RepForm = (props: TProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();

  const repListItem = props.userList.find(
    (user) => +user.id === props.task.rep
  );
  const [options, setOptions] = useState<TListResource<string | number>>(
    repListItem ? [repListItem] : []
  );
  const form = useForm<TRepFormAttributes>({
    rep: repListItem,
  });
  const { dispatch: dispatchUpdateTaskService } = useUpdateTaskService(
    props.task.ref
  );
  const userListService = useUserListService();

  useEffect(() => {
    form.set({
      rep: repListItem,
    });
    setOptions(repListItem ? [repListItem] : []);
  }, [props.task.rep]);

  const submitHandler = (value?: TListItem<string>) => {
    dispatchUpdateTaskService({
      body: {
        rep: value?.id ?? null,
      } as TRepFormAttributesTransformed,
    })
      .then((payload: ITaskPatchResource) => {
        serviceSuccessHandler(payload, value);

        form.notDirty();
      })
      .catch((error: THttpClientError) => {
        notificationHandler(error.status, error.data?.message);
      });
  };

  const serviceSuccessHandler = (
    payload: ITaskPatchResource,
    value?: TListItem<string>
  ) => {
    dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED, {
      initTask: props.task,
      task: payload.task,
    } as TTaskEvent);

    // Add user to the list, so it can be shown as avatar
    value?.id &&
      dispatch(
        taskActions.addUser({
          id: +value.id!,
          name: value.label!,
          email: "",
          orgId: "",
        })
      );
  };

  const notificationHandler = (errorStatus?: number, errorMessage?: string) => {
    errorStatus === 406 &&
      dispatch(
        systemNotificationActions.open({
          message: errorMessage ?? "Something went wrong, please try again.",
          variant: "warning",
          autoHideDuration: 3000,
          closeOnBlur: false,
        })
      );
    errorStatus !== 406 &&
      dispatch(
        systemNotificationActions.open({
          message:
            "There was a problem with changing the representative. Please try again.",
          variant: "error",
          autoHideDuration: 3000,
          closeOnBlur: false,
        })
      );
  };

  return (
    <Autocomplete
      form={form}
      name="rep"
      placeholder="Unassigned"
      disabled={props.task?.statusType === AggStatusEnum.DONE}
      onBlur={() => submitHandler(form.data?.rep)}
      service={userListService}
      options={options}
    />
  );
};

export default RepForm;
