import { TBacklogBoardQueryState } from "./types/TBacklogBoardQueryState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TBoardQueryState } from "../../../slices/types/TBoardQueryState";

const initialState: TBacklogBoardQueryState = {
  extraFilters: {
    taskTypeIds: [],
    taskPriorityIds: [],
    customFields: [],
  },
  sorting: undefined,
};

const slice = createSlice({
  name: "backlogBoardQuery",
  initialState,
  reducers: {
    setExtraFiltering: (
      state,
      action: PayloadAction<TBacklogBoardQueryState["extraFilters"]>
    ) => {
      state.extraFilters = action.payload;
    },
    clearExtraFiltering: (state) => {
      state.extraFilters = initialState.extraFilters;
    },
    setSorting: (state, action: PayloadAction<TBoardQueryState["sorting"]>) => {
      state.sorting = action.payload;
    },
    reset: () => initialState,
  },
});

export const backlogBoardQueryReducer = slice.reducer;

export const backlogBoardQueryActions = slice.actions;
