import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { sprintActions } from "../../slices/sprintSlice";
import CreateForm from "./CreateForm";
import { TCreateModalProps } from "./types/TCreateModalProps";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const CreateModal = (props: TCreateModalProps) => {
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);
  const creation = useAppSelector((state) => state.sprint.creation);
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(sprintActions.closeCreationModal());
  };

  return (
    <Dialog
      open={creation.displayModal}
      onClose={closeHandler}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Create {sprintLabel}</DialogTitle>
      <DialogContent>
        <CreateForm
          onCancel={closeHandler}
          projectAbbr={props.projectAbbr}
          isSprintPage={props.isSprintPage}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateModal;
