import { Badge, Box, Grid, IconButton, Typography } from "@mui/material";
import Menu from "../../../../../../common/containers/Menu/Menu";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import { Close, FilterList } from "@mui/icons-material";
import React from "react";
import useTaskType from "../../../../../../common/modules/task/hooks/useTaskType";
import useTaskPriority from "../../../../../../common/modules/task/hooks/useTaskPriority";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { boardQueryActions } from "../../../../slices/boardQuerySlice";
import useSprint from "../../../../../../common/modules/task/hooks/useSprint";
import useCustomField from "../../../../../../common/modules/task/hooks/useCustomField";

const Filter = () => {
  const dispatch = useAppDispatch();
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);
  const filters = useAppSelector((state) => state.boardQuery.extraFilters);
  const filterExists = Object.values(filters).some(
    (value) => value !== null && value.length > 0
  );
  const matchesMax768Screen = useMedia(QueryEnum.MAX_WIDTH_768);
  const taskTypeUtil = useTaskType();
  const taskPriorityUtil = useTaskPriority();
  const sprintComponent = useSprint().dropdownFilter();
  const customFieldComponent = useCustomField({
    isBacklog: false,
  }).dropdownFilters();
  const taskTypeComponent = taskTypeUtil.dropdownFilter(
    filters.taskTypeIds,
    (values) =>
      dispatch(
        boardQueryActions.setExtraFiltering({
          ...filters,
          taskTypeIds: values,
        })
      )
  );
  const taskPriorityComponent = taskPriorityUtil.dropdownFilter(
    filters.taskPriorityIds,
    (values) =>
      dispatch(
        boardQueryActions.setExtraFiltering({
          ...filters,
          taskPriorityIds: values,
        })
      )
  );

  const filteringItems = [
    {
      id: "taskTypeId",
      label: "Task Type",
      component: taskTypeComponent,
      when: !!taskTypeComponent,
    },
    {
      id: "taskPriorityId",
      label: "Task Priority",
      component: taskPriorityComponent,
      when: !!taskPriorityComponent,
    },
    {
      id: "sprintRef",
      label: sprintLabel,
      component: sprintComponent,
      when: !!sprintComponent,
    },
    ...(customFieldComponent?.map((item) => ({
      id: item.id,
      label: item.label,
      component: item.component,
      when: !!item.component,
    })) || []),
  ];

  const menuItems = [
    ...(filterExists
      ? [
          menuItem.make(
            <Typography
              sx={{ color: "text.secondary", fontSize: "0.8rem" }}
              display="flex"
              alignItems="center"
              gap={0.5}
            >
              <Close sx={{ fontSize: "0.8rem" }} /> Clear Filtering
            </Typography>,
            () => dispatch(boardQueryActions.clearExtraFiltering())
          ),
        ]
      : []),
    ...filteringItems.map((filteringItem, index) => {
      return menuItem.make(
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{
            position: "relative",
            left: [0, 1].includes(index) ? "-8px" : undefined,
          }}
        >
          <Grid item>{filteringItem.component}</Grid>
        </Grid>,
        undefined,
        filteringItem.when
      );
    }),
  ];

  return (
    <Menu
      stretchOnMobile={false}
      closeOnSelection={false}
      listSubheader="Filtering by"
      button={(clickHandler) => (
        <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
          <Box onClick={clickHandler}>
            <IconButton>
              <Badge color="error" variant={filterExists ? "dot" : undefined}>
                <FilterList />
              </Badge>
            </IconButton>
          </Box>
          {!matchesMax768Screen && (
            <Typography
              component="span"
              variant="body2"
              sx={{ mr: 1 }}
              onClick={clickHandler}
            >
              Filtering
            </Typography>
          )}
        </Box>
      )}
      menuItems={menuItems}
    />
  );
};

export default Filter;
