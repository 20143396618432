import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { taskPriorityActions } from "../../slices/taskPrioritySlice";
import UpdateForm from "./UpdateForm";

const UpdateModal = () => {
  const { showModal, editableRecord } = useAppSelector(
    (state) => state.taskPriority.update
  );
  const dispatch = useAppDispatch();

  const closeHandler = () => dispatch(taskPriorityActions.closeUpdateModal());

  return (
    <Dialog
      open={showModal && editableRecord !== null}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Edit Task Priority</DialogTitle>
      <DialogContent>
        {editableRecord && (
          <UpdateForm taskPriority={editableRecord} onCancel={closeHandler} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UpdateModal;
