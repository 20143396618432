import { TOccupiedSpaceProps } from "./types/TOccupiedSpaceProps";
import { TChildren } from "../../types/TChildren";
import { NavLink } from "react-router-dom";

export default function Link(props: TOccupiedSpaceProps & TChildren) {
  return (
    <>
      {props.link ? (
        <NavLink to={props.link} target="_blank">
          {props.children}
        </NavLink>
      ) : (
        props.children
      )}
    </>
  );
}
