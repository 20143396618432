import { TBoardViewProps } from "../../../../board/types/TBoardViewProps";
import KanbanViewContent from "./KanbanViewContent";
import LinkModal from "../../../../link/containers/Create/LinkModal";
import TaskViewModal from "../../../../task/modules/view/containers/TaskViewModal";

const KanbanView = (props: TBoardViewProps) => {
  return (
    <>
      <KanbanViewContent
        board={props.board}
        projectAbbr={props.projectAbbr}
        boardRef={props.boardRef}
      />
      <TaskViewModal />
      <LinkModal />
    </>
  );
};

export default KanbanView;
