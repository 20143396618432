import { TBoardDefaultProps } from "../../../../board/types/TBoardDefaultProps";
import useInitData from "./useInitData";
import { LoadDirectionEnum } from "../../../enums/LoadDirectionEnum";
import {
  resetScrollCheckpoints,
  useVirtualizationHelper,
} from "./useVirtualizationHelper";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { holisticViewActions } from "../slices/holisticViewSlice";
import { TBoardQueryState } from "../../../slices/types/TBoardQueryState";

export const useUpdateUserLanes = (props: TBoardDefaultProps) => {
  const { dispatchService } = useInitData(props, false);
  const { lastScrollPositionToDate } = useVirtualizationHelper();
  const dispatch = useAppDispatch();

  return {
    dispatch: (filters?: TBoardQueryState["extraFilters"]) => {
      // In case when new task is created and there is no container still, we will need to
      // first load a single user lane or a timeline and then call this update service
      if (!lastScrollPositionToDate()?.day) {
        dispatch(holisticViewActions.setHavingBoardUsers(true));
      }

      return new Promise<any>((resolve, reject) => {
        const handler = () => {
          dispatchService(
            LoadDirectionEnum.INIT,
            lastScrollPositionToDate()!.day,
            (data) => {
              resetScrollCheckpoints();
              resolve(data);
            },
            (error) => reject(error),
            filters
          );
        };

        // In the case when scroll last day is not available, means this is a fresh board and we need
        // to wait until the earlier check to load the timeline is done and ready
        if (lastScrollPositionToDate()?.day) {
          handler();
        } else {
          setTimeout(() => {
            if (lastScrollPositionToDate()?.day) {
              handler();
            } else {
              // !!! This is an extreme case, but we will wait for another 500ms and then try again
              // during testing it has been found a case when initial 500ms was not enough
              setTimeout(() => handler(), 500);
            }
          }, 500);
        }
      });
    },
  };
};
