import { useParams } from "react-router-dom";
import { TConnectParams } from "./types/TConnectParams";
import { Button } from "../../../../../../common/modules/materialUI";
import { useConnectSlackUserService } from "../../services/useConnectSlackUserService";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { jwt } from "../../../../../../common/utils/jwt";
import { useFetchIntegrationMetadataService } from "../../../../services/useFetchIntegrationMetadataService";
import { useAppSelector } from "../../../../../../common/hooks/redux";
import { IntegrationEnum } from "../../../../../../entities/enums/IntegrationEnum";
import { env } from "../../../../../../common/utils/env";

const Connect = () => {
  const theme = useTheme();
  useFetchIntegrationMetadataService({ integration: IntegrationEnum.SLACK });
  const integrationList = useAppSelector((state) => state.integration.list);
  const jwtPayload = jwt.parse();
  const { key } = useParams<TConnectParams>();
  // @TODO would be nice to receive status/ok values from the response together similarly as we receive data/error objects
  const {
    dispatch: dispatchConnect,
    isLoading,
    data: connectData,
    error: connectError,
  } = useConnectSlackUserService({
    body: { authKey: key ?? "" },
  });

  const connectToSlackHandler = () => {
    dispatchConnect().catch(() => {});
  };

  return (
    <Card sx={{ maxWidth: 400, mx: "auto", mt: "50px" }}>
      <CardContent sx={{ height: 150, textAlign: "center" }}>
        {integrationList.length > 0 && (
          <img
            src={integrationList[0].banner[theme.palette.mode]}
            width="300"
            height="125"
            alt=""
          />
        )}
      </CardContent>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Connecting slack account
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Before proceeding, we just want to double-check: are you sure you want
          to connect your Slack account to your {env.appName} account -{" "}
          <strong>{jwtPayload?.name}</strong>? This integration promises to
          boost your productivity and streamline your workflow, offering a new
          level of efficiency and collaboration.
        </Typography>
      </CardContent>
      {!connectError && !connectData?.message && (
        <CardActions>
          <Button
            variant="outlined"
            loading={isLoading}
            disabled={isLoading}
            onClick={connectToSlackHandler}
          >
            Connect
          </Button>
        </CardActions>
      )}
      {connectData?.message && (
        <CardContent>
          <Typography>{connectData.message}</Typography>
        </CardContent>
      )}
      {connectError && (
        <CardContent>
          <Typography>Invalid Request</Typography>
        </CardContent>
      )}
    </Card>
  );
};

export default Connect;
