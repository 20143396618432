import { useAttachmentPreviewUrlByHashService } from "../services/useAttachmentPreviewUrlByHashService";
import { IPreviewSignedUrlResource } from "../interfaces/IPreviewSignedUrlResource";

export const emptyPixelImage = `${process.env.CDN_URL}/assets/empty-pixel.png`;

const failedRetrialsCount: { [key: string]: number } = {};
const retryLimit = 4;

export default function usePreviewUrlHelper() {
  const { dispatch: dispatchGetPreviewUrlByHash } =
    useAttachmentPreviewUrlByHashService();

  const refreshAttachmentImageUrl = (
    image: HTMLImageElement,
    extraAttributesWithSrc: string[] = []
  ) => {
    const originalSrcAttributeName = "data-ts-original-src";
    if (!image.hasAttribute(originalSrcAttributeName)) {
      image.setAttribute(originalSrcAttributeName, image.src);
    }

    image.src = emptyPixelImage;
    refreshAttachmentPreviewUrl(image.getAttribute(originalSrcAttributeName)!)
      .then((previewUrl) => {
        image.src = previewUrl;
        image.setAttribute(originalSrcAttributeName, image.src);

        // Set src to extra attributes if requested
        extraAttributesWithSrc.forEach((attributeName) => {
          image.setAttribute(attributeName, image.src);
        });
      })
      .catch((_) => {});
  };

  /**
   * Each hash is permitted to fail only a certain number of times, even if successfully loaded once
   * it won't reset the failedRetrialsCount, since it might successfully load previewUrl while the
   * google can still fail to load/locate the image
   *
   * This is done to prevent the infinite loop of failed requests since this can knock the server down
   *
   * The onload event of the image is used to reset the failedRetrialsCount if the hash has already been failed
   */
  const refreshAttachmentPreviewUrl = async (url: string) => {
    const hashName = hashFromAttachmentPreviewUrl(url);

    // Increment the failedRetrialsCount, since this function is designed to be called on error
    failedRetrialsCount[hashName] = (failedRetrialsCount[hashName] ?? 0) + 1;

    // If the failedRetrialsCount is more than the retryLimit skip the request
    if ((failedRetrialsCount[hashName] ?? 0) > retryLimit) {
      throw new Error(`Max retries reached for the hash ${hashName}`);
    }

    try {
      const resource = (await dispatchGetPreviewUrlByHash({
        urlPath: `${hashName}/previewUrl`,
      })) as IPreviewSignedUrlResource;
      // If the resource is not fetched throw an error
      if (!resource?.previewUrl) {
        throw new Error("Resource not fetched");
      }

      return resource.previewUrl;
    } catch (error) {
      throw error;
    }
  };

  const hashFromAttachmentPreviewUrl = (url: string) => {
    const pathSegments = new URL(url).pathname.split("/");

    return pathSegments[pathSegments.length - 1];
  };

  const resetFailedRetrialsCount = (url?: string) => {
    const hashName = hashFromAttachmentPreviewUrl(url ?? "");
    if (!(failedRetrialsCount[hashName] ?? false)) {
      return;
    }

    failedRetrialsCount[hashName] = 0;
  };

  return {
    refreshAttachmentImageUrl,
    refreshAttachmentPreviewUrl,
    resetFailedRetrialsCount,
  };
}
