import { TListItem } from "../../../common/types/TListItem";
import { StartDateTypeEnum } from "../enums/StartDateTypeEnum";

export const taskStartDateUtil = {
  getStartDateTypeHelperText: (startDateType: TListItem<string> | null) => {
    if (!startDateType) {
      return "Will start after the last workable item in user's timeline";
    }

    if (startDateType.id === StartDateTypeEnum.IMMEDIATELY) {
      return "Will start right now";
    }

    if (startDateType.id === StartDateTypeEnum.AFTER_CURRENT_ITEM) {
      return "Will start right after the ongoing item. If no ongoing item, will behave as 'Immediately'";
    }

    if (startDateType.id === StartDateTypeEnum.ON_SELECTED_DATE) {
      return "Will start at the first hour of the selected date";
    }
  },
};
