import { useHttpClient } from "../../../common/modules/httpClient/hooks/useHttpClient";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IUserOfftime } from "../../../entities/IUserOfftime";
import { useAppDispatch } from "../../../common/hooks/redux";
import { offtimeActions } from "../slices/offtimeSlice";
import { TGeneralProps } from "../containers/OfftimeManagement/types/TGeneralProps";

export const useFetchOfftimeService = (props: TGeneralProps) => {
  const dispatch = useAppDispatch();

  const response = useHttpClient({
    url: !props.userId
      ? "/offtime/findAll"
      : `/offtime/findAll/for/${props.userId}`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IUserOfftime[]) =>
        dispatch(offtimeActions.setList(data)),
    },
  });

  return {
    ...response,
    data: response.data as IUserOfftime[],
  };
};
