import { TCreateBacklogItemModalProps } from "./types/TCreateBacklogItemModalProps";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { backlogItemCreateActions } from "../../slices/backlogItemCreateSlice";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CreateBacklogItemForm from "./CreateBacklogItemForm";
import { useSubscribeEvent } from "../../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";

const CreateBacklogItemModal = ({ boardRef }: TCreateBacklogItemModalProps) => {
  const dispatch = useAppDispatch();
  const openCreationDialog = useAppSelector(
    (state) => state.backlogItemCreate.displayModal
  );

  const closeHandler = () => {
    dispatch(backlogItemCreateActions.closeCreationModal());
  };

  useSubscribeEvent(EventEnum.ON_BACKLOG_ITEM_CREATED, () => closeHandler());

  return (
    <Dialog
      open={openCreationDialog}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Create Backlog Item</DialogTitle>
      <DialogContent>
        <CreateBacklogItemForm boardRef={boardRef} onCancel={closeHandler} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateBacklogItemModal;
