import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IMonitor } from "../../../entities/IMonitor";

export const useCreateMonitorService = () => {
  const response = useHttpClientDispatchable({
    url: "/monitor",
    method: RequestMethodEnum.POST,
  });

  return {
    ...response,
    data: response.data as IMonitor,
  };
};
