import { Grid } from "@mui/material";
import { TTaskViewMainContent } from "./types/TTaskViewMainContent";
import TitleForm from "../Title/TitleForm";
import Description from "../../../../../description/containers/Description/Description";
import Comments from "../../../../../comment/containers/Comments/Comments";
import { EntityTypeEnum } from "../../../../../../entities/enums/EntityTypeEnum";
import TaskInfoTabs from "../TaskInfoTabs/TaskInfoTabs";

const TaskViewMainContent = ({
  task,
  resource,
  isMobileView,
  isPageView,
}: TTaskViewMainContent) => {
  return (
    <>
      <Grid container item mb="16px">
        <TitleForm task={task} />
      </Grid>

      <Description
        entityType={EntityTypeEnum.TASK}
        entityId={task.id}
        isMobileView={isMobileView}
      />

      <Grid container item mb="30px" flexDirection="column" gap="8px">
        <TaskInfoTabs
          task={task}
          resource={resource}
          isMobileView={isMobileView}
          isPageView={isPageView}
        />
      </Grid>

      <Grid container item flexDirection="column" gap="40px">
        <Comments
          entityType={EntityTypeEnum.TASK}
          entityId={task.id}
          isMobileView={isMobileView}
        />
      </Grid>
    </>
  );
};

export default TaskViewMainContent;
