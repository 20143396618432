import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { TListItem } from "../../../../../../common/types/TListItem";
import { Box, Typography } from "@mui/material";
import { Clear, FilterList } from "@mui/icons-material";
import React from "react";
import { TBacklogBoardQueryState } from "../../slices/types/TBacklogBoardQueryState";
import { backlogBoardQueryActions } from "../../slices/backlogBoardQuerySlice";

const SelectedFiltersView = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(
    (state) => state.backlogBoardQuery.extraFilters
  );
  const filterExists = Object.values(filters).some(
    (value) => value !== null && value.length > 0
  );
  const filterKeys = Object.keys(filters) as Array<
    keyof TBacklogBoardQueryState["extraFilters"]
  >;

  const removeFilterHandler = (
    filterItem: TListItem<number | string>,
    key: keyof TBacklogBoardQueryState["extraFilters"]
  ) => {
    dispatch(
      backlogBoardQueryActions.setExtraFiltering({
        ...filters,
        [key]: filters[key].filter((item) => item.id !== filterItem.id),
      })
    );
  };

  if (!filters || !filterExists) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      gap={2}
      sx={{ my: 2, color: "text.secondary" }}
    >
      <FilterList />
      {filterKeys.map((key) => {
        return filters[key].map((filterItem, index) => (
          <Box
            key={key + index}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            sx={{ cursor: "pointer" }}
            gap={0.2}
            onClick={() => removeFilterHandler(filterItem, key)}
          >
            <Typography sx={{ fontSize: "0.875rem" }}>
              {filterItem.label}
            </Typography>
            <Clear sx={{ fontSize: "14px" }} />
          </Box>
        ));
      })}
    </Box>
  );
};

export default SelectedFiltersView;
