import { createSlice } from "@reduxjs/toolkit";

type TInitialState = {
  displayModal: boolean;
};

const initialState: TInitialState = {
  displayModal: false,
};

const slice = createSlice({
  name: "guesstimationItemCreate",
  initialState,
  reducers: {
    openCreationModal: (state) => {
      state.displayModal = true;
    },
    closeCreationModal: (state) => {
      state.displayModal = false;
    },
  },
});

export const guesstimationItemCreateReducer = slice.reducer;

export const guesstimationItemCreateActions = slice.actions;
