import { Divider as MuiDivider } from "@mui/material";
import { TVerticalDividerProps } from "./types/TVerticalDividerProps";

const VerticalDivider = (props: TVerticalDividerProps) => {
  return (
    <MuiDivider
      orientation="vertical"
      flexItem
      variant="middle"
      color="grey"
      sx={props.sx}
    />
  );
};

export default VerticalDivider;
