import { Grid, Typography } from "@mui/material";
import { TTitleProps } from "./types/TTitleProps";

const Title = (props: TTitleProps) => {
  return (
    <Grid container sx={props.sx ?? { mb: 3 }} direction="column">
      <Grid item>
        <Typography variant="h5" component="h1" sx={{ fontSize: "1.1rem" }}>
          {props.children}
        </Typography>
      </Grid>
      {props.subtitle && (
        <Grid item>
          <Typography variant="subtitle2" sx={{ fontSize: "0.75rem" }}>
            {props.subtitle}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default Title;
