import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IBoard } from "../../../entities/IBoard";

export const useCreateBoardService = () => {
  const response = useHttpClientDispatchable({
    url: "/board",
    method: RequestMethodEnum.POST,
  });

  return {
    ...response,
    data: response.data as IBoard,
  };
};
