import { device } from "../utils/device";
import { link } from "../utils/link";
import { useAppDispatch } from "./redux";
import { systemActions } from "../store/systemSlice";

export default function useWindow() {
  const dispatch = useAppDispatch();

  return {
    openNewTab: (url: string, async: boolean = false) => {
      device.isIosSafari() && async
        ? dispatch(systemActions.openConfirmRedirectionDialog(url))
        : link.openNewTab(url);
    },
  };
}
