import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TCommentState } from "./types/TCommentState";
import { jwt } from "../../../common/utils/jwt";
import { IComment } from "../../../entities/IComment";

const initialState: TCommentState = {
  list: {},
  deletion: {
    dialogOpen: {},
  },
};

export const storageKey = (comment: IComment) =>
  `${comment.entityType}-${comment.entityId}`;

const slice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<IComment[]>) => {
      if (action.payload[0]) {
        state.list[storageKey(action.payload[0])] = action.payload;
      }
    },
    addComment: (state, action: PayloadAction<IComment>) => {
      if (!action.payload.userName && jwt.parse()?.name) {
        action.payload.userName = jwt.parse()?.name as string;
      }
      const key = storageKey(action.payload);
      state.list[key] = [action.payload, ...(state.list[key] ?? [])];
    },
    removeComment: (state, action: PayloadAction<IComment>) => {
      const key = storageKey(action.payload);
      state.list[key] = state.list[key].filter(
        (item) => item.id !== action.payload.id
      );
    },
    updateComment: (
      state,
      action: PayloadAction<{ html?: string; comment: IComment }>
    ) => {
      state.list[storageKey(action.payload.comment)].find(
        (item) => item.id === action.payload.comment.id
      )!.html = action.payload.html;
    },
    openDeletionDialog: (state, action: PayloadAction<IComment>) => {
      state.deletion.dialogOpen[storageKey(action.payload)] = true;
      state.deletion.comment = action.payload;
    },
    closeDeletionDialog: (state) => {
      state.deletion.dialogOpen = {};
      state.deletion.comment = undefined;
    },
  },
});

export const commentReducer = slice.reducer;

export const commentActions = slice.actions;
