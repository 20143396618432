import Form from "../../../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
} from "../../../../../../common/modules/materialUI";
import { TFormProps } from "./types/TFormProps";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TLinkFormAttributes } from "./types/TLinkFormAttributes";
import { projectUtil } from "../../../../../../common/utils/projectUtil";
import { useOrgProjectData } from "../../../../../org/hooks/useOrgProjectData";
import { TListResource } from "../../../../../../common/types/TListResource";
import { BoardTypeEnum } from "../../../../../../entities/columns/board/BoardTypeEnum";
import { IData } from "../../../../../../entities/columns/objective/IData";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { initiativeActions } from "../../../../slices/initiativeSlice";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { TLinkFormAttributesTransformed } from "./types/TLinkFormAttributesTransformed";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useLinkExistingInitiativeService } from "../../../../services/initiative/useLinkExistingInitiativeService";
import { useUpdateInitiativeService } from "../../../../services/initiative/useUpdateInitiativeService";

const LinkForm = (props: TFormProps) => {
  const { projectsList, boardList } = useOrgProjectData();
  const dispatch = useAppDispatch();
  const { isLoading, dispatch: dispatchLinkInitiativeService } =
    useLinkExistingInitiativeService(props.objective.id);
  const { dispatch: dispatchUpdateInitiativeService, isLoading: isLinking } =
    useUpdateInitiativeService(props.objective.id);
  const form = useForm<TLinkFormAttributes>();
  const dispatchEvent = useDispatchEvent();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    if (props.initiative) {
      dispatchUpdateInitiativeService({
        body: form.getTransformed<TLinkFormAttributesTransformed>((data) => ({
          ...data,
          isActive: true,
          projectRef: data.projectRef?.id ?? "",
          boardRef: data.boardRef?.id ?? "",
        })),
        urlPath: props.initiative?.id,
      })
        .then(() => {
          dispatchEvent(EventEnum.ON_INITIATIVE_UPDATED);
          dispatch(initiativeActions.closeLinkModal());
        })
        .catch((error: THttpClientError) => {
          if (error.status === 422) {
            form.errorHandler(error);
          } else {
            dispatch(
              systemNotificationActions.open({
                variant: "error",
                message:
                  "Unable to link the board to initiative. Please try again.",
              })
            );
          }
        });
    } else {
      dispatchLinkInitiativeService({
        body: form.getTransformed<TLinkFormAttributesTransformed>((data) => ({
          ...data,
          projectRef: data.projectRef?.id ?? "",
          boardRef: data.boardRef?.id ?? "",
        })),
      })
        .then((payload: IData) => {
          dispatchEvent(EventEnum.ON_INITIATIVE_CREATED);
          dispatchEvent(EventEnum.ON_OBJECTIVE_MAIN_DATA_UPDATED);
          dispatch(initiativeActions.closeCreateModal());
        })
        .catch((error: THttpClientError) => {
          if (error.status === 422) {
            form.errorHandler(error);
          } else {
            dispatch(
              systemNotificationActions.open({
                variant: "error",
                message: "Unable to create the initiative. Please try again.",
              })
            );
          }
        });
    }
  };

  const disabled = isLoading || isLinking;

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={disabled}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={disabled}
            variant="contained"
            type="submit"
            loading={disabled}
          >
            {props.initiative ? "Link" : "Add"}
          </Button>
        </>
      }
    >
      <Autocomplete
        form={form}
        name="projectRef"
        label="Project"
        showRequired
        options={projectUtil.list(projectsList, "ref")}
      />
      <Autocomplete
        form={form}
        name="boardRef"
        label="Board"
        showRequired={!props.initiative}
        disabled={!form.data?.projectRef}
        options={
          form.data!.projectRef!
            ? (boardList(form.data!.projectRef!.id)
                ?.filter(
                  (board) =>
                    board.type &&
                    [
                      BoardTypeEnum.INITIATIVE_MANAGEMENT,
                      BoardTypeEnum.HOLISTIC,
                    ].includes(board.type)
                )
                .map((board) => ({
                  id: board.ref,
                  label: board.name,
                })) as TListResource<string>)
            : []
        }
        textFieldProps={{
          helperText: !props.initiative
            ? "* A new initiative will be created and linked to the selected board"
            : "* Selected board will be linked to your initiative, not selecting a board will create a new one then link it to your initiative",
        }}
      />
    </Form>
  );
};

export default LinkForm;
