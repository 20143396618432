import { Grid } from "@mui/material";
import React from "react";
import { enumUtil } from "../../../../../common/utils/enum";
import { BoardIntegrationTypeEnum } from "../../../../../entities/columns/boardIntegration/BoardIntegrationTypeEnum";
import Autocomplete from "../../../../../common/modules/materialUI/containers/Autocomplete/Autocomplete";
import { useChannelListService } from "../../../../integrations/modules/slack/services/useChannelListService";
import { Button } from "../../../../../common/modules/materialUI";
import Form from "../../../../../common/components/Form/Form";
import useForm from "../../../../../common/hooks/useForm/useForm";
import { TSlackChannelFormAttributes } from "./types/TSlackChannelFormAttributes";
import { IntegrationEnum } from "../../../../../entities/enums/IntegrationEnum";
import { TSlackChannelFormProps } from "./types/TSlackChannelFormProps";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { useCreateBoardIntegrationService } from "../../../services/useCreateBoardIntegrationService";
import { useParams } from "react-router-dom";
import { TBoardParams } from "../../Board/types/TBoardParams";
import { TSlackChannelFormAttributesTransformed } from "./types/TSlackChannelFormAttributesTransformed";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { IBoardIntegration } from "../../../../../entities/IBoardIntegration";
import { boardIntegrationActions } from "../../../slices/boardIntegrationSlice";
import { ISlackData } from "../../../../../entities/columns/boardIntegration/ISlackData";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";

const SlackChannelForm = (props: TSlackChannelFormProps) => {
  const dispatch = useAppDispatch();
  const channelListService = useChannelListService();
  const { boardRef } = useParams<TBoardParams>();
  const { isLoading, dispatch: dispatchCreateBoardIntegration } =
    useCreateBoardIntegrationService({ ref: boardRef! });
  const form = useForm<TSlackChannelFormAttributes>({
    integration: enumUtil.toListItem(IntegrationEnum.SLACK),
    type: enumUtil.toListItem(BoardIntegrationTypeEnum.POST_TO_BOARD),
  });

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchCreateBoardIntegration({
      body: form.getTransformed<TSlackChannelFormAttributesTransformed>(
        (data) => ({
          integrationRef: data.integrationRef?.id,
          type: data.type?.id,
          integration: data.integration?.id,
          data: { channelName: data.integrationRef?.label } as ISlackData,
        })
      ),
    })
      .then((payload: IBoardIntegration) => {
        dispatch(boardIntegrationActions.addBoardIntegration(payload));
        props.formDisplayHandler();
        dispatch(
          systemNotificationActions.open({
            message: "Integration added successfully",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  const formDisplayHandler = () => {
    form.reset();
    props.formDisplayHandler();
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionPosition="left"
      actionElement={
        <>
          <Button
            onClick={formDisplayHandler}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Save
          </Button>
        </>
      }
    >
      <Grid container>
        <Grid item xs={12} md={6}>
          <Autocomplete
            form={form}
            name="integrationRef"
            label="Channel"
            service={channelListService}
            showRequired
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Autocomplete
            form={form}
            name="type"
            label="Type"
            options={enumUtil.toList(BoardIntegrationTypeEnum)}
            showRequired
            readOnly
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default SlackChannelForm;
