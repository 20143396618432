export enum NetworkInteractionEnum {
  CONNECTION_REQUESTED = "Connection requested",
  CONNECTION_FOLLOW_UP = "Connection follow up",
  CONNECTED_IN_PERSON = "Connected in person",
  CONNECTED_EMAIL = "Connected via email",
  CONNECTED_LINKEDIN = "Connected via linkedin",
  CONNECTED_PHONE = "Connected via phone",
  CHANNEL_EMAIL = "Channel email",
  CHANNEL_LINKEDIN = "Channel linkedin",
  CHANNEL_PHONE = "Channel phone",
  VENDOR_LINK_SENT = "Vendor link sent",
  DISCUSSION = "Discussion",
  MEETING_SCHEDULED = "Meeting scheduled",
  MEETING_DONE = "Meeting done",
  POST_MEETING_FOLLOW_UP = "Post meeting follow up",
  WAITING = "Waiting",
  NO_RESPONSE = "No response",
  DISCONNECTED = "Disconnected",
  CONVERTED = "Converted!",
}

export const getInteractionColor = (networkInteraction: string) => {
  switch (networkInteraction) {
    case NetworkInteractionEnum.CONNECTION_REQUESTED:
      return "info.main";
    case NetworkInteractionEnum.CONVERTED:
      return "success.main";
    case NetworkInteractionEnum.DISCONNECTED:
      return "error.dark";
    default:
      return undefined;
  }
};
