import { Add } from "@mui/icons-material";
import { Button } from "../../../../common/modules/materialUI";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { projectActions } from "../../slices/projectSlice";
import CreateModal from "./CreateModal";
import { TCreateProjectWithModalProps } from "./types/TCreateProjectWithModalProps";

export default function CreateProjectWithModal(
  props: TCreateProjectWithModalProps
) {
  const dispatch = useAppDispatch();

  return (
    <>
      <Button
        variant="text"
        startIcon={<Add />}
        onClick={() => dispatch(projectActions.openCreationModal())}
        {...props.buttonProps}
      >
        {props.buttonProps?.children ?? "Create new project"}
      </Button>
      <CreateModal />
    </>
  );
}
