import { useHttpClient } from "../../../common/modules/httpClient/hooks/useHttpClient";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IBoardIntegration } from "../../../entities/IBoardIntegration";
import { useAppDispatch } from "../../../common/hooks/redux";
import { boardIntegrationActions } from "../slices/boardIntegrationSlice";

export const useFetchBoardIntegrationService = (props: { ref: string }) => {
  const dispatch = useAppDispatch();
  const response = useHttpClient({
    url: `/boardIntegration/${props.ref}`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IBoardIntegration[]) =>
        dispatch(boardIntegrationActions.setList(data)),
    },
  });

  return {
    ...response,
    data: response.data as IBoardIntegration[],
  };
};
