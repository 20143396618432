import { DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import { TDataGridProps } from "./types/TDataGridProps";
import { useTheme } from "@mui/material";

const DataGrid = (props: TDataGridProps) => {
  const theme = useTheme();

  return (
    <MuiDataGrid
      {...props}
      sx={{
        ...{
          "& .MuiDataGrid-footerContainer p": {
            margin: 0,
          },

          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.dataGrid.footerBackground,
          },

          "& .MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: theme.palette.dataGrid.rowOddBackground,
          },

          "& .MuiDataGrid-row:nth-of-type(even)": {
            backgroundColor: theme.palette.dataGrid.rowEvenBackground,
          },

          "& .MuiDataGrid-row:hover": {
            backgroundColor: theme.palette.dataGrid.rowHoverBackground,
          },

          "& .css-1iyq7zh-MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.dataGrid.headerBackground,
          },

          "& .MuiDataGrid-withBorderColor": {
            borderColor: theme.palette.dataGrid.borderColor,
          },

          "&.MuiDataGrid-root": {
            borderColor: theme.palette.dataGrid.borderColor,
          },
        },
        ...props.sx,
      }}
    />
  );
};

export default DataGrid;
