import { useAppSelector } from "../../../../common/hooks/redux";
import { TEntityTaskPriorityUpdateFormProps } from "./types/TEntityTaskPriorityUpdateFormProps";
import { useEffect } from "react";
import useForm from "../../../../common/hooks/useForm/useForm";
import { TEntityTaskPriorityUpdateFormAttributes } from "./types/TEntityTaskPriorityUpdateFormAttributes";
import { SelectChangeEvent, useTheme } from "@mui/material";
import { iconLibraryUtil } from "../../../iconLibrary/utils/iconLibraryUtil";
import Dropdown from "../../../../common/modules/materialUI/components/Dropdown/Dropdown";
import { taskPriorityUtil } from "../../utils/taskPriorityUtil";
import { ArrowDropDownRounded, PriorityHigh } from "@mui/icons-material";

const EntityTaskPriorityUpdateForm = ({
  projectRef,
  taskPriorityId,
  onChange,
  isLoading,
  noLabel,
}: TEntityTaskPriorityUpdateFormProps) => {
  const theme = useTheme();
  const orgInit = useAppSelector((state) => state.org.init);
  const { taskPriorities, list, isLoading: isOrgInitLoading } = orgInit;
  const projectId =
    (list.find((listItem) => listItem.project.ref === projectRef)?.project
      .id as number) ?? 0;
  const taskPriority = taskPriorityId
    ? taskPriorities.find((row) => row.id === taskPriorityId)
    : undefined;

  const form = useForm<TEntityTaskPriorityUpdateFormAttributes>({
    taskPriorityId: taskPriority?.id ?? null,
  });

  // When switching entity, task priority id can be changed.
  useEffect(() => {
    form.set({ taskPriorityId: taskPriority?.id ?? null });
  }, [taskPriority?.id]);

  const submitHandler = (event: SelectChangeEvent<any>) => {
    event.preventDefault();

    onChange(event.target.value ?? null);
  };

  return (
    <Dropdown
      form={form}
      onChange={submitHandler}
      name="taskPriorityId"
      withoutBorder
      options={taskPriorityUtil.getDropdownOptions(
        taskPriorities,
        projectId,
        "Task Priority",
        taskPriority
      )}
      loading={isOrgInitLoading || isLoading}
      renderOption={(option) =>
        iconLibraryUtil.getDropdownRenderOption(
          option,
          taskPriorityUtil.getSelectedTaskPriority(taskPriorities, option)
            ?.iconFilePath
        )
      }
      // This change is done for mobile not to display the label for the selected value but still have it inside
      // dropdown list
      renderValue={(optionId) => {
        const selectedOption = taskPriorities.find(
          (row) => row.id === optionId
        );

        const noLabelOption = {
          id: optionId,
          label: noLabel
            ? ""
            : !!optionId
            ? selectedOption?.name ?? ""
            : "Task Priority",
        };

        return iconLibraryUtil.getDropdownRenderOption(
          noLabelOption,
          taskPriorityUtil.getSelectedTaskPriority(
            taskPriorities,
            noLabelOption
          )?.iconFilePath,
          undefined,
          <PriorityHigh style={{ display: "flex", fontSize: "16px" }} />,
          {
            iconSize: 16,
            optionContainerSpacing: 0,
            optionContainerGap: "4px",
          }
        );
      }}
      IconComponent={(props) => (
        <ArrowDropDownRounded
          {...props}
          fontSize="small"
          sx={{
            color: `${theme.palette.taskView.textMainColor} !important`,
            right: "0 !important",
          }}
        />
      )}
      sx={{
        height: "auto",
        "& .MuiSelect-select": {
          borderRadius: 0,
          color: theme.palette.taskView.textSecondaryColor,
          fontSize: "13px",
          fontWeight: 500,
          minHeight: "0 !important",
          p: "0 !important",
          pr: "24px !important",
        },
      }}
    />
  );
};

export default EntityTaskPriorityUpdateForm;
