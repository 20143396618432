import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import { Button, TextField } from "../../../../common/modules/materialUI";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { teamActions } from "../../slices/teamSlice";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { RoleEnum } from "../../../../entities/columns/user/RoleEnum";
import { TManageRoleMapFormAttributes } from "./types/TManageRoleMapFormAttributes";
import { enumUtil } from "../../../../common/utils/enum";
import { Grid } from "@mui/material";
import { useManageRoleMapService } from "../../services/useManageRoleMapService";
import React, { useEffect } from "react";
import { orgActions } from "../../../org/slices/orgSlice";
import { IOrg } from "../../../../entities/IOrg";

type TProps = {
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
};

const ManageRoleMapForm = (props: TProps) => {
  const dispatch = useAppDispatch();
  const orgRoleMapData = useAppSelector((state) => state.org.init.org?.roleMap);

  const form = useForm<TManageRoleMapFormAttributes>();
  const { isLoading, dispatch: dispatchManageRoleMapService } =
    useManageRoleMapService();

  useEffect(() => {
    if (orgRoleMapData && orgRoleMapData.length > 0) {
      orgRoleMapData.map((item) => {
        form.changeDefaultData({ [`roleMap.${item.role}.label`]: item.label });
      });
    }
  }, []);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    let formData = Object.keys(form.data ?? {})
      .map((key) => {
        return form.data?.[key]
          ? { role: key.split(".")[1], label: form.data?.[key] ?? "" }
          : undefined;
      })
      .filter((item) => item !== undefined);

    dispatchManageRoleMapService({
      body: { roleMap: formData },
    })
      .then((response: IOrg) => {
        dispatch(orgActions.updateRoleMap(response));
        dispatch(teamActions.closeManageRoleMapModal());
        dispatch(
          systemNotificationActions.open({
            message: `Role map successfully changed.`,
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        }

        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading || !form.isDirty()}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Save
          </Button>
        </>
      }
    >
      {enumUtil.toList(RoleEnum).map((role) => {
        return (
          <Grid container key={role.label}>
            <Grid item xs={4}>
              {role.label}
            </Grid>
            <Grid item xs={8}>
              <TextField
                form={form}
                name={`roleMap.${role.id}.label`}
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                showRequired
              />
            </Grid>
          </Grid>
        );
      })}
    </Form>
  );
};

export default ManageRoleMapForm;
