import { useTheme } from "@mui/material";
import DarkThemeGlobalStyle from "./DarkThemeGlobalStyle";
import LightThemeGlobalStyle from "./LightThemeGlobalStyle";

const GlobalStyle = () => {
  const theme = useTheme();

  return theme.palette.mode === "dark" ? (
    <DarkThemeGlobalStyle />
  ) : (
    <LightThemeGlobalStyle />
  );
};

export default GlobalStyle;
