import { array } from "../../../../../../utils/array";
import classes from "../../common/Navigation.module.scss";
import List from "../../common/List/List";
import { ArrowBack } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { TUserProfileNavigationContentProps } from "./types/TUserProfileNavigationContentProps";
import { useAppSelector } from "../../../../../../hooks/redux";
import UserProfileSection from "./UserProfileSection";

const UserProfileNavigationContent = ({
  matchesMax992Screen,
  pushContentClassName,
  navigationRequestedHandler,
}: TUserProfileNavigationContentProps) => {
  const userJwt = useAppSelector((state) => state.user.userInfo);

  return (
    <nav
      className={array.toClassName([
        classes.navigation,
        !matchesMax992Screen ? pushContentClassName : "",
      ])}
    >
      <List
        title={
          userJwt?.name ? (
            <Typography variant="h6" sx={{ color: "primary.contrastText" }}>
              {userJwt.name}
            </Typography>
          ) : (
            "Loading..."
          )
        }
        items={[
          {
            id: "userProfileBack",
            name: "Back",
            path: `/`,
            icon: <ArrowBack />,
          },
        ]}
      />
      <UserProfileSection onClick={navigationRequestedHandler} />
    </nav>
  );
};

export default UserProfileNavigationContent;
