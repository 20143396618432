import { TTaskRowProps } from "./types/TTaskRowProps";
import classes from "./Row.module.scss";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import { useTheme } from "@mui/material";

const TaskRow = (props: TTaskRowProps) => {
  const theme = useTheme();
  const color = props.color ?? theme.palette.brightCardBackground.main;

  const showLeftBorder = !props.isSegmentView && props.task.segmentCount > 1;

  return (
    <div
      className={classes.container}
      style={{
        backgroundColor: theme.palette.brightCardBackground.paper,
        color: color,
        borderLeft: showLeftBorder
          ? theme.palette.cardSplitBorder.main
          : "2px solid transparent",
        borderWidth: showLeftBorder ? "3px" : undefined,
        ...props.style,
      }}
    >
      <LeftContainer {...props} color={color} />
      <RightContainer {...props} color={color} />
    </div>
  );
};

export default TaskRow;
