import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Skeleton } from "../../../../../common/modules/materialUI";
import VerticalDivider from "../../../../../common/components/Divider/VerticalDivider";

const BacklogViewSkeleton = () => {
  const divider = (
    <VerticalDivider
      sx={{ borderWidth: "1px", height: "1px", margin: "auto 0" }}
    />
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {Array.from({ length: Math.floor(Math.random() * 7) + 3 }).map(
            (value, index) => (
              <TableRow
                key={index}
                sx={{
                  "td, th": {
                    borderBottom: "1px solid rgb(255 255 255 / 6%)",
                  },
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    padding: "0 10px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "70%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: 7,
                    }}
                  >
                    <Skeleton variant="circular" width={15} height={15} />
                    <Skeleton variant="text" width={30} />
                    {divider}
                    <Skeleton
                      variant="text"
                      width={Math.floor(Math.random() * 200) + 300}
                    />
                  </div>
                  <div
                    style={{
                      width: "30%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: 7,
                    }}
                  >
                    <Skeleton variant="text" width={50} />
                    {divider}
                    <Skeleton variant="text" width={20} />
                    <Skeleton variant="text" width={20} />
                    <Skeleton variant="circular" width={18} height={18} />
                  </div>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BacklogViewSkeleton;
