import { TTaskCostProps } from "./types/TTaskCostProps";
import { currencyUtil } from "../../../../utils/currencyUtil";
import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Tooltip } from "../../../../modules/materialUI";
import { number } from "../../../../utils/number";

const TaskCost = ({
  cost,
  size,
  showOnlySymbol,
  hideTooltip,
  sx,
}: TTaskCostProps) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

  const currencySymbol = currencyUtil.getSymbol(cost.costCurrency);
  const costFullText = currencySymbol + number.formatAsPrice(cost.cost);
  const costText = !!showOnlySymbol ? currencySymbol : costFullText;

  // Check if the text is overflowing its container
  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(
        textRef.current.scrollWidth > textRef.current.clientWidth
      );
    }
  }, [costText]);

  return (
    <Box
      className="timelineCost"
      sx={{
        position: "absolute",
        top: "5px",
        right: "6px",
        maxWidth: size ? `${size}px` : undefined,
        ...sx,
      }}
    >
      <Tooltip
        title={costFullText}
        disableHoverListener={hideTooltip ?? !isOverflowing}
      >
        <Typography
          ref={textRef}
          noWrap
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontSize: "0.65rem",
            height: "1.4rem",
          }}
        >
          {costText}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default TaskCost;
