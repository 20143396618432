import { useCallback, useEffect, useState } from "react";
import { debounce } from "@mui/material";

export type TUseInfiniteScrollProps = {
  isLoading: boolean;
  bottomBuffer?: number;
  stopPageIncrement?: boolean;
  scrollContainerRef?: React.RefObject<HTMLElement>;
  loadMore?: (page: number) => Promise<void>;
};

export const useInfiniteScrollPagination = ({
  isLoading,
  stopPageIncrement,
  scrollContainerRef,
  loadMore,
  bottomBuffer = 1,
}: TUseInfiniteScrollProps) => {
  const [page, setPage] = useState<number>(1);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState<boolean>(false);
  const [loadMoreFailedCount, setLoadMoreFailedCount] = useState<number>(0);
  const incrementPage = () => setPage((prev) => prev + 1);
  const decrementPage = () => setPage((prev) => (prev > 1 ? prev - 1 : 1));
  const resetPage = () => setPage(1);

  // This is for the huge screens when all items fit the screen, then scroll event is not triggered, hence loading automatically more items
  const incrementPageIfItemsFitPage = useCallback(
    debounce(() => {
      const container = scrollContainerRef?.current || document.documentElement;

      if (
        container.clientHeight >= container.scrollHeight &&
        !isLoading &&
        !isLoadMoreLoading &&
        !stopPageIncrement
      ) {
        incrementPage();
      }
    }, 200),
    [scrollContainerRef, isLoading, isLoadMoreLoading, stopPageIncrement]
  );

  // Incrementing page if all items fit the page
  useEffect(() => {
    incrementPageIfItemsFitPage();
  }, [incrementPageIfItemsFitPage]);

  const handleScroll = useCallback(
    debounce(() => {
      const container = scrollContainerRef?.current || document.documentElement;

      const isBottomReached =
        container.scrollTop + container.clientHeight >=
        container.scrollHeight - bottomBuffer;

      if (
        isBottomReached &&
        !isLoading &&
        !isLoadMoreLoading &&
        !stopPageIncrement
      ) {
        // Changing container scrollTop to 1 pixel up, so that browser won't automatically scroll to bottom by itself
        // if the data was reset and the viewer position was much lower than the new data
        container.scrollTop = container.scrollTop - 1;
        incrementPage();
      }
    }, 200),
    [
      scrollContainerRef,
      isLoading,
      isLoadMoreLoading,
      stopPageIncrement,
      bottomBuffer,
    ]
  );

  // Attaching scroll event listener to the container
  useEffect(() => {
    const container = scrollContainerRef?.current || window;

    container.addEventListener("scroll", handleScroll);

    return () => container.removeEventListener("scroll", handleScroll);
  }, [handleScroll, scrollContainerRef]);

  // Loading more data if page is changed
  useEffect(() => {
    // Loading more only starting from 2nd page
    if (!(page > 1) || !loadMore || loadMoreFailedCount > 0) return;

    const fetchMoreData = async () => {
      setIsLoadMoreLoading(true);

      try {
        await loadMore(page);
      } catch (error) {
        decrementPage();
        setLoadMoreFailedCount((prev) => prev + 1);
      }

      setIsLoadMoreLoading(false);
    };

    fetchMoreData();
  }, [page]);

  return {
    page,
    incrementPage,
    resetPage,
    isLoadMoreLoading,
    loadMoreFailedCount,
  };
};
