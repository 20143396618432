import classes from "./Resizer.module.css";
import { TResizerProps } from "./types/TResizerProps";
import { DragIndicator } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { windowHelper } from "../../../../utils/windowHelper";

const Resizer = (props: TResizerProps) => {
  const container = props.parentNode;
  const theme = useTheme();

  if (!container) {
    return null;
  }

  let isResizing = false;
  let initialMouseX = 0;
  let containerInitialWidth: number | undefined = 0;
  let containerInitialZIndex: string | undefined;
  let lastXPosition = 0;
  let xPositive: boolean | undefined;

  const resizeStartHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    // If there is anything selected, unslect it otherwise getting browser strange experience
    windowHelper.deselectAllText();

    isResizing = true;
    initialMouseX = e.clientX;
    containerInitialWidth = container.offsetWidth;
    containerInitialZIndex = container.style.zIndex;

    container.style.zIndex = "22";

    document.addEventListener("mousemove", resizeHandler);
    document.addEventListener("mouseup", resizeCompleteHandler);
    document.addEventListener("keydown", escapeHandler);

    props.onResizeStart?.(e);
  };

  const resizeHandler = (e: MouseEvent) => {
    if (!isResizing || !containerInitialWidth) {
      return;
    }

    if (e.clientX > lastXPosition) {
      xPositive = true;
    } else if (e.clientX < lastXPosition) {
      xPositive = false;
    }

    const widthDiff = e.clientX - initialMouseX;
    container.style.width = `${containerInitialWidth + widthDiff}px`;

    lastXPosition = e.clientX;

    props.onResize?.(e, widthDiff, xPositive);
  };

  const cleanupHandler = () => {
    isResizing = false;
    container.style.zIndex = containerInitialZIndex ?? "auto";

    document.removeEventListener("mousemove", resizeHandler);
    document.removeEventListener("mouseup", resizeCompleteHandler);
    document.removeEventListener("keydown", escapeHandler);
  };

  const resizeCompleteHandler = () => {
    cleanupHandler();
    props.onResizeComplete?.(resetHandler);
  };

  const resizeInterruptHandler = () => {
    cleanupHandler();
    props.onResizeInterrupt?.();
  };

  const resetHandler = () => {
    container.style.width = `${containerInitialWidth}px`;
    resizeInterruptHandler();
  };

  const escapeHandler = (e: KeyboardEvent) => {
    e.key === "Escape" && resetHandler();
  };

  return (
    <>
      {props.metrics && (
        <div className={`${classes.metrics} no-select`}>{props.metrics}</div>
      )}
      <div className={classes.resizer} onMouseDown={resizeStartHandler}>
        <div>
          <DragIndicator
            style={{ color: theme.palette.brightCardBackground.main }}
          />
        </div>
      </div>
    </>
  );
};

export default Resizer;
