import KeyboardKey from "../../../../keyboardShortcut/containers/KeyboardKey/KeyboardKey";
import { keyboard } from "../../../../../utils/keyboard";
import ShortcutProTip from "../../../../keyboardShortcut/containers/ProTip/ShortcutProTip";
import { TProTipSaveChangesProps } from "./types/TProTipSaveChangesProps";

export default function ProTipSaveChanges(props: TProTipSaveChangesProps) {
  return (
    <ShortcutProTip
      size={props.size ?? "medium"}
      shortcutKeyElement={
        <>
          <KeyboardKey keyName={`${keyboard.getControlKeyLabel()} + S`} /> or{" "}
          <KeyboardKey keyName={`${keyboard.getControlKeyLabel()} + Enter`} />
        </>
      }
      description="to save the changes"
      sx={{ my: 0.5, ...props.sx }}
    />
  );
}
