import { useHttpClient } from "../../../common/modules/httpClient/hooks/useHttpClient";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IIntegration } from "../../../entities/IIntegration";
import { useAppDispatch } from "../../../common/hooks/redux";
import { integrationActions } from "../slices/integrationSlice";
import { IntegrationEnum } from "../../../entities/enums/IntegrationEnum";

export const useFetchIntegrationMetadataService = (
  props:
    | {
        integration?: IntegrationEnum;
        installed?: string;
      }
    | undefined = undefined
) => {
  const dispatch = useAppDispatch();

  const response = useHttpClient({
    url: `/integration/metadata`,
    method: RequestMethodEnum.GET,
    query: { ...(props?.integration && { integration: props.integration }) },
    events: {
      beforeTransform: (data: IIntegration[]) => {
        dispatch(
          integrationActions.setList({ data, installed: props?.installed })
        );
        dispatch(integrationActions.setAutocompleteList(data));
      },
    },
  });

  return {
    ...response,
    data: response.data as IIntegration[],
  };
};
