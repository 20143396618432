export const config = {
  full: {
    plugins: [
      "lists",
      "advlist",
      "checklist",
      "table",
      "advtable",
      "autolink",
      "link",
      "mentions",
      "autoresize",
      "codesample",
      "emoticons",
      "help",
      "image",
      "quickbars",
      "save",
    ],
    toolbar:
      "blocks | bold italic underline strikethrough | forecolor | backcolor | align | numlist bullist checklist | link image | table advtablerownumbering | codesample blockquote hr | help",
  },
};
