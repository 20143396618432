export enum NetworkTypeEnum {
  POTENTIAL_CLIENT = "Potential client",
  VENDOR = "Vendor",
  VC = "Venture capital",
  INVESTOR = "Investor",
  INVESTOR_VENDOR = "Investor vendor",
  HEDGE_FUND = "Hedge fund",
  POTENTIAL_COLLABORATION = "Potential collab",
  GOOD_NETWORK = "Good network",
  NOT_INTERESTED = "No interest",
}
