import { useAppSelector } from "../../../../../../../hooks/redux";
import { Box, Skeleton, Typography } from "@mui/material";

export default function Org() {
  const org = useAppSelector((state) => state.org.init.org);

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      sx={{
        height: "30px",
      }}
    >
      {!org?.name && <Skeleton variant="text" width="50%" />}
      {!!org?.name && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={1}
          sx={{
            maxWidth: "95%",
            p: "4px 15px 3px 15px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
          }}
        >
          <img
            src={`${process.env.CDN_URL}/icons/buildings.png`}
            width="16"
            height="16"
            alt="org"
          />
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            variant="subtitle2"
          >
            {org.name}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
