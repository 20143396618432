import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { TColumnsProps } from "./types/TColumnsProps";
import { IUserResource } from "../../../interfaces/IUserResource";
import { Box, useTheme } from "@mui/material";
import AvatarIcon from "../../../../user/containers/AvatarIcon/AvatarIcon";
import React from "react";
import CircularProgressBar from "../../../../../common/components/CircularProgressBar/CircularProgressBar";

export const Columns: (props: TColumnsProps) => GridColDef<IUserResource>[] = (
  props
) => {
  const theme = useTheme();

  return [
    {
      field: "userName",
      headerName: "Full Name",
      flex: 1,
      minWidth: 200,
      maxWidth: 300,
      hideable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return null;
        }

        return (
          <Box display="flex" gap={1} alignItems={"center"}>
            <AvatarIcon userName={params.value} size={25} fontSize={10} />
            <span>{params.value}</span>
          </Box>
        );
      },
    },
    {
      field: "daysOff",
      headerName: "",
      flex: 1,
      minWidth: 200,
      maxWidth: 300,
      hideable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <CircularProgressBar
              value={(params.value / params.row.daysAll) * 100}
              title={`${params.value} days off`}
              color={theme.palette.text.primary}
            />
          </>
        );
      },
    },
    {
      field: "daysInProgress",
      headerName: "",
      flex: 1,
      minWidth: 200,
      maxWidth: 300,
      hideable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <CircularProgressBar
              value={(params.value / params.row.daysAll) * 100}
              title={`${params.value} days work assigned`}
              color={theme.palette.info.main}
            />
          </>
        );
      },
    },
    {
      field: "daysAvailable",
      headerName: "",
      flex: 1,
      minWidth: 200,
      maxWidth: 300,
      hideable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <CircularProgressBar
              value={(params.value / params.row.daysAll) * 100}
              title={`${params.value} days open to work`}
              color={theme.palette.success.main}
            />
          </>
        );
      },
    },
  ];
};
