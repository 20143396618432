import { Rocket } from "@mui/icons-material";
import List from "../../common/List/List";
import useLandingPageHelper from "../../../../../../modules/landingPage/hooks/useLandingPageHelper";
import { Chip } from "@mui/material";
import { TSectionProps } from "../../types/TSectionProps";

export default function QuickStartMenuItem(props: TSectionProps) {
  const { isLoading, needsQuickStart, progress } = useLandingPageHelper();

  if (isLoading || !needsQuickStart) {
    return null;
  }

  return (
    <List
      title="Welcome!"
      items={[
        {
          id: "landingPage",
          name: "Quick Start",
          path: "/",
          icon: <Rocket />,
          onNavigate: props.onClick,
        },
      ]}
      action1Element={
        <Chip
          sx={{
            float: "right",
            fontSize: "0.7rem",
            transform: "translateY(-4px)",
            marginRight: "0.5rem",
          }}
          label={`${progress}%`}
          color="primary"
          size="small"
        />
      }
    />
  );
}
