import Form from "../../../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  Checkbox,
  DatePicker,
  TextField,
} from "../../../../../../common/modules/materialUI";
import { TFormProps } from "./types/TFormProps";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { useCreateInitiativeService } from "../../../../services/initiative/useCreateInitiativeService";

import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TManageFormAttributes } from "./types/TManageFormAttributes";
import { Box, FormControlLabel, Typography } from "@mui/material";
import { projectUtil } from "../../../../../../common/utils/projectUtil";
import { useOrgProjectData } from "../../../../../org/hooks/useOrgProjectData";
import { IData } from "../../../../../../entities/columns/objective/IData";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { TManageFormAttributesTransformed } from "./types/TManageFormAttributesTransformed";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { initiativeActions } from "../../../../slices/initiativeSlice";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useUpdateInitiativeService } from "../../../../services/initiative/useUpdateInitiativeService";

const ManageForm = (props: TFormProps) => {
  const { projectsList } = useOrgProjectData();
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const dispatch = useAppDispatch();
  const { isLoading, dispatch: dispatchCreateInitiativeService } =
    useCreateInitiativeService(props.objective.id);
  const { isLoading: isUpdating, dispatch: dispatchUpdateInitiativeService } =
    useUpdateInitiativeService(props.objective.id);
  const form = useForm<TManageFormAttributes>({
    name: props.initiative?.name || "",
    projectRef:
      props.initiative?.projectRef && props.initiative?.projectRef !== "0"
        ? {
            label: props.initiative.projectRef,
            id: props.initiative.projectRef,
          }
        : undefined,
    startDate: props.initiative?.startDate || undefined,
    endDate: props.initiative?.endDate || undefined,
    isWorkReady: props.initiative?.isActive || false,
  });
  const dispatchEvent = useDispatchEvent();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    if (props.initiative) {
      dispatchUpdateInitiativeService({
        body: form.getTransformed<TManageFormAttributesTransformed>((data) => ({
          ...data,
          projectRef: data.isWorkReady ? data.projectRef?.id : 0,
          isActive: data.isWorkReady,
          startDate: data.startDate || null,
          endDate: data.endDate || null,
        })),
        urlPath: props.initiative.id,
      })
        .then((payload: IData) => {
          dispatchEvent(EventEnum.ON_INITIATIVE_UPDATED);
          dispatch(initiativeActions.closeUpdateModal());
        })
        .catch((error: THttpClientError) => {
          if (error.status === 422) {
            form.errorHandler(error);
          } else {
            dispatch(
              systemNotificationActions.open({
                variant: "error",
                message: "Unable to update the initiative. Please try again.",
              })
            );
          }
        });
    } else {
      dispatchCreateInitiativeService({
        body: form.getTransformed<TManageFormAttributesTransformed>((data) => ({
          ...data,
          projectRef: data.isWorkReady ? data.projectRef?.id : undefined,
          isActive: data.isWorkReady,
        })),
      })
        .then((payload: IData) => {
          dispatchEvent(EventEnum.ON_INITIATIVE_CREATED);
          dispatchEvent(EventEnum.ON_OBJECTIVE_MAIN_DATA_UPDATED);
          dispatch(initiativeActions.closeCreateModal());
        })
        .catch((error: THttpClientError) => {
          if (error.status === 422) {
            form.errorHandler(error);
          } else {
            dispatch(
              systemNotificationActions.open({
                variant: "error",
                message: "Unable to create the initiative. Please try again.",
              })
            );
          }
        });
    }
  };

  const disabled = isLoading || isUpdating;

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button
            onClick={props.onCancel}
            disabled={disabled}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={disabled}
            variant="contained"
            type="submit"
            loading={disabled}
          >
            {props.initiative ? "Update" : "Add"}
          </Button>
        </>
      }
    >
      <TextField
        forceFocus={!isTouchDevice}
        form={form}
        name="name"
        label="Name"
        showRequired
      />

      <Box
        display="flex"
        alignItems="center"
        sx={{ marginBottom: "0 !important" }}
      >
        {!props.initiative && (
          <FormControlLabel
            control={<Checkbox form={form} name="isWorkReady" />}
            label={<Typography variant="body2">Ready for work</Typography>}
          />
        )}
      </Box>
      {!props.initiative && !!form.data?.isWorkReady && (
        <Autocomplete
          form={form}
          name="projectRef"
          label="Project"
          options={projectUtil.list(projectsList, "ref")}
          textFieldProps={{
            helperText:
              "Select a project where your initiative board will get auto created for you.",
          }}
        />
      )}

      <DatePicker form={form} name="startDate" label="Start Date" allowClear />
      <DatePicker form={form} name="endDate" label="End Date" allowClear />
    </Form>
  );
};

export default ManageForm;
