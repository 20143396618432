import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TKanbanViewTaskState } from "../hooks/types/TKanbanViewTaskState";

const initialState: TKanbanViewTaskState = {};

const slice = createSlice({
  name: "kanbanViewTask",
  initialState,
  reducers: {
    setIsDragging: (state, action: PayloadAction<boolean>) => {
      state.isDragging = action.payload;
    },
    showShiftingProgress: (state, action: PayloadAction<boolean>) => {
      state.showShiftingProgress = action.payload;
    },
  },
});

export const kanbanViewTaskReducer = slice.reducer;

export const kanbanViewTaskActions = slice.actions;
