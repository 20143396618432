import { IBacklogItemPatchResource } from "../interfaces/IBacklogItemPatchResource";
import { backlogItemViewActions } from "../slices/viewSlice";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";

export const useUpdateBacklogItemService = (backlogItemRef: string) => {
  const dispatch = useAppDispatch();
  const response = useHttpClientDispatchable({
    url: `/backlog/${backlogItemRef}`,
    method: RequestMethodEnum.PATCH,
    events: {
      beforeTransform: (data: IBacklogItemPatchResource) => {
        dispatch(
          backlogItemViewActions.mergeBacklogItemProps(data.backlogItem)
        );
      },
    },
  });

  return {
    ...response,
    data: response.data as IBacklogItemPatchResource,
  };
};
