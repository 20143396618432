import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { ITask } from "../../../entities/ITask";

export const useAddAssigneesService = (props: { taskId: number }) => {
  const response = useHttpClientDispatchable({
    url: `/task/${props.taskId}/addAssignees`,
    method: RequestMethodEnum.POST,
  });

  return {
    ...response,
    data: response.data as ITask[],
  };
};
