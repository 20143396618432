import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { Dialog, DialogTitle } from "@mui/material";
import { statusFlowActions } from "../../../slices/statusFlowSlice";
import DialogContent from "@mui/material/DialogContent";
import MoveDataFromStatusForm from "./MoveDataFromStatusForm";

const MoveDataFromStatusDialog = () => {
  const dispatch = useAppDispatch();
  const { showDialog, fromStatus } = useAppSelector(
    (state) => state.statusFlow.moveDataFromStatus
  );

  const dialogCloseHandler = () =>
    dispatch(statusFlowActions.closeMoveDataFromStatusDialog());

  return (
    <Dialog
      open={showDialog}
      onClose={dialogCloseHandler}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Move Data From Status</DialogTitle>
      <DialogContent>
        {fromStatus && (
          <MoveDataFromStatusForm
            onCancel={dialogCloseHandler}
            fromStatus={fromStatus}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MoveDataFromStatusDialog;
