import { TStatusFlowState } from "./types/TStatusFlowState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { string } from "../../../common/utils/string";
import { TStatus } from "../containers/Flow/Status/types/TStatus";
import { AggStatusEnum } from "../../../entities/columns/task/AggStatusEnum";

const initialState: TStatusFlowState = {
  statusList: [],
  updateStatusList: {
    isDirty: false,
  },
  moveDataFromStatus: {
    showDialog: false,
    fromStatus: undefined,
  },
};

const slice = createSlice({
  name: "statusFlow",
  initialState,
  reducers: {
    setStatusList: (state, action: PayloadAction<TStatus[]>) => {
      state.statusList = action.payload;
    },
    setListIsDirty: (state, action: PayloadAction<boolean>) => {
      state.updateStatusList.isDirty = action.payload;
    },
    addStatus: (state, action: PayloadAction<AggStatusEnum>) => {
      state.statusList.push({
        id: string.random(5),
        isNew: true,
        name: action.payload,
        type: action.payload,
      });

      state.updateStatusList.isDirty = true;
    },
    removeStatus: (state, action: PayloadAction<{ status: TStatus }>) => {
      state.statusList = state.statusList.filter(
        (status) => status.id !== action.payload.status.id
      );
    },
    updateName: (
      state,
      action: PayloadAction<{ status: TStatus; name: string }>
    ) => {
      state.statusList.find(
        (status) => status.id === action.payload.status.id
      )!.name = action.payload.name;

      state.updateStatusList.isDirty = true;
    },
    openDrawer: (state, action: PayloadAction<{ status: TStatus }>) => {
      state.updateStatus = {
        openDrawer: true,
        status: action.payload.status,
      };
    },
    closeDrawer: (state) => {
      state.updateStatus = undefined;
    },
    moveStatus: (
      state,
      action: PayloadAction<{ currentIndex: number; newIndex: number }>
    ) => {
      const { currentIndex, newIndex } = action.payload;
      const statusList = [...state.statusList];
      const [removed] = statusList.splice(currentIndex, 1);
      statusList.splice(newIndex, 0, removed);

      state.statusList = statusList;

      state.updateStatusList.isDirty = true;
    },
    openMoveDataFromStatusDialog: (
      state,
      action: PayloadAction<{ status: TStatus }>
    ) => {
      state.moveDataFromStatus.showDialog = true;
      state.moveDataFromStatus.fromStatus = action.payload.status;
    },
    closeMoveDataFromStatusDialog: (state) => {
      state.moveDataFromStatus = initialState.moveDataFromStatus;
    },
    resetState: () => initialState,
  },
});

export const statusFlowReducer = slice.reducer;

export const statusFlowActions = slice.actions;
