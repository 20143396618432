import { TCheckoutFormProps } from "../../../../stripe/containers/Checkout/Form/types/TCheckoutFormProps";
import CheckoutForm from "../../../../stripe/containers/Checkout/Form/CheckoutForm";

const CheckoutStep = (props: TCheckoutFormProps) => {
  return {
    label: "Checkout",
    content: <CheckoutForm {...props} />,
  };
};

export default CheckoutStep;
