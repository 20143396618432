import Title from "../../../../common/components/Title/Title";
import SprintGrid from "../SprintGrid/SprintGrid";
import useRegisterHeader from "../../../../common/modules/registry/hooks/useRegisterHeader";
import { HeaderRegistryEnum } from "../../../../common/modules/registry/enums/HeaderRegistryEnum";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { sprintActions } from "../../slices/sprintSlice";
import { Add } from "@mui/icons-material";
import { Button } from "../../../../common/modules/materialUI";
import CreateSprint from "../CreateSprint/CreateSprint";
import { Box } from "@mui/material";

const SprintPage = () => {
  const { projectAbbr } = useParams();
  const [searchParams] = useSearchParams();
  const [modalOpen] = useState<boolean>(
    searchParams.get("modalOpen") == "true"
  );
  const sprintLabel = useAppSelector((state) => state.customs.sprintLabel);
  const dispatch = useAppDispatch();
  const formOpen = useAppSelector(
    (state) => state.sprint.creation.displayModal
  );
  useRegisterHeader(HeaderRegistryEnum.headerBackButtonOnly, [projectAbbr]);

  useEffect(() => {
    if (modalOpen) {
      dispatch(sprintActions.openCreationModal());
    }
    return () => {
      dispatch(sprintActions.reset());
    };
  }, [modalOpen]);

  const formDisplayHandler = () => {
    formOpen
      ? dispatch(sprintActions.closeCreationModal())
      : dispatch(sprintActions.openCreationModal());
  };

  return (
    <>
      <Title>Sprints</Title>
      <Box mb={3}>
        <Button variant="text" startIcon={<Add />} onClick={formDisplayHandler}>
          Create new {sprintLabel}
        </Button>
      </Box>
      <CreateSprint projectAbbr={projectAbbr!} isSprintPage={true} />
      <SprintGrid />
    </>
  );
};

export default SprintPage;
