import { useAppDispatch } from "../../../common/hooks/redux";
import { attachmentActions } from "../slices/attachmentSlice";
import { IAttachment } from "../../../entities/IAttachment";
import { string } from "../../../common/utils/string";
import useLinkCloudAttachment from "./useLinkCloudAttachment";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { date } from "../../../common/utils/date";
import { useAttachmentGetSignedUploadUrlService } from "../services/useAttachmentUploadService";
import useGetSignedUploadUrlToCloud from "../../../common/hooks/useGetSignedUploadUrlToCloud/useGetSignedUploadUrlToCloud";
import useUploadFileToCloud from "../../../common/hooks/useUploadFileToCloud/useUploadFileToCloud";
import { EntityTypeEnum } from "../../../entities/enums/EntityTypeEnum";

const useUploadAttachmentServiceHelper = (
  entityType: EntityTypeEnum,
  entityId: number
) => {
  const dispatch = useAppDispatch();
  const { dispatch: dispatchAttachmentUploadService } =
    useAttachmentGetSignedUploadUrlService();
  const { dispatch: dispatchGetSignedUploadUrlToCloud } =
    useGetSignedUploadUrlToCloud();
  const { dispatch: dispatchUploadAttachmentToCloud } = useUploadFileToCloud();
  const { dispatch: dispatchLinkCloudAttachment } = useLinkCloudAttachment(
    entityType,
    entityId
  );

  const dropTempAttachmentHandler = (uniqueId: string) => {
    dispatch(attachmentActions.removeFromList({ id: uniqueId }));
    dispatch(attachmentActions.trackUploadProgress());
  };

  return {
    dispatch: (
      file: File,
      withPreview: boolean = false,
      onSuccess?: (resource: IAttachment) => void,
      onError?: (error: string) => void
    ) => {
      const uniqueId = string.random(10);

      // Add temp attachment to the list
      dispatch(
        attachmentActions.addToList({
          id: uniqueId,
          name: file.name,
          size: file.size,
          createdAt: date.nowString(),
          isUploading: true,
        } as IAttachment)
      );
      dispatch(attachmentActions.trackUploadProgress());

      // Get upload signed url
      dispatchGetSignedUploadUrlToCloud(
        { file },
        dispatchAttachmentUploadService,
        (resource) => {
          // Intending to upload the file to the cloud using the signed url
          dispatchUploadAttachmentToCloud(
            resource.signedUrl,
            file,
            () => {
              // Link the uploaded file to the entity as attachment
              dispatchLinkCloudAttachment(
                file,
                resource.hashName,
                withPreview,
                () => dropTempAttachmentHandler(uniqueId),
                (resource) => {
                  onSuccess?.(resource);

                  dispatch(attachmentActions.addToList(resource));
                  dispatch(
                    systemNotificationActions.open({
                      variant: "success",
                      message: `The "${file.name}" linked successfully`,
                    })
                  );
                },
                (error) => onError?.(error)
              );
            },
            (error) => {
              onError?.(error);
              dropTempAttachmentHandler(uniqueId);
            }
          );
        },
        (error) => {
          onError?.(error);
          dropTempAttachmentHandler(uniqueId);
        }
      );
    },
  };
};

export default useUploadAttachmentServiceHelper;
