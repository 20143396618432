import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { useUpdateGuesstimationItemService } from "../../services/useUpdateGuesstimationItemService";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { TGuesstimationItemUpdatedEvent } from "../types/TGuesstimationItemUpdatedEvent";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import EntityTaskPriorityUpdateForm from "../../../../../taskPriority/containers/EntityTaskPriorityUpdateForm/EntityTaskPriorityUpdateForm";
import { IGuesstimationItem } from "../../../../../../entities/IGuesstimationItem";
import { IGuesstimationItemDetailsResource } from "../../interfaces/IGuesstimationItemDetailsResource";

type TProps = {
  guesstimationItem: IGuesstimationItem;
  noLabel?: boolean;
};

const TaskPriorityForm = ({ guesstimationItem, noLabel }: TProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { dispatch: dispatchUpdateGuesstimationItemService } =
    useUpdateGuesstimationItemService(guesstimationItem.id);

  const changeHandler = (taskPriorityId: number | null) => {
    dispatchUpdateGuesstimationItemService({
      body: {
        taskPriorityId: taskPriorityId,
      },
    })
      .then((patchResource: IGuesstimationItemDetailsResource) => {
        dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_UPDATED, {
          initGuesstimationItem: guesstimationItem,
          guesstimationItem: patchResource.guesstimationItem,
        } as TGuesstimationItemUpdatedEvent);
      })
      .catch((error: THttpClientError) => {
        if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        }

        dispatch(
          systemNotificationActions.open({
            message:
              "There was a problem with updating the Task Priority. Please try again.",
            variant: "error",
          })
        );
      });
  };

  return (
    <EntityTaskPriorityUpdateForm
      projectRef={guesstimationItem.projectRef}
      taskPriorityId={guesstimationItem.taskPriorityId}
      onChange={changeHandler}
      isLoading={!guesstimationItem.id}
      noLabel={noLabel}
    />
  );
};

export default TaskPriorityForm;
