import { useAppDispatch } from "../../../../../common/hooks/redux";
import { useDispatchEvent } from "../../../../../common/modules/eventProvider";
import * as React from "react";
import { useState } from "react";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { guesstimationViewItemActions } from "../slices/guesstimationViewItemSlice";
import { guesstimationViewActions } from "../slices/guesstimationViewSlice";
import { TDropResult } from "../containers/DropTarget/types/TDropResult";
import { TLaneItemProps } from "../containers/LaneItem/types/TLaneItemProps";
import { DRAG_TYPE_GUESSTIMATION_ITEM_HORIZONTAL } from "../containers/LaneItem/LaneItem";
import {
  IShiftGuesstimationItemServiceRequest,
  useShiftGuesstimationItemService,
} from "../../../../guesstimation/modules/view/services/useShiftGuesstimationItemService";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { IGuesstimationItemDetailsResource } from "../../../../guesstimation/modules/view/interfaces/IGuesstimationItemDetailsResource";
import { EventEnum } from "../../../../../common/modules/eventProvider/enums/EventEnum";
import { TGuesstimationItemUpdatedEvent } from "../../../../guesstimation/modules/view/containers/types/TGuesstimationItemUpdatedEvent";

export const useDragAndDropHelper = (props: TLaneItemProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { dispatch: dispatchShiftGuesstimationItemService } =
    useShiftGuesstimationItemService(props.guesstimationItem.id);
  const errorHandler = (error: THttpClientError) => {
    if ([406, 403, 422].includes(error.status)) {
      dispatch(
        systemNotificationActions.open({
          message:
            error.status === 422
              ? (error.data.errors as { collapseError: string }).collapseError
              : error.data.message,
          variant: "warning",
        })
      );
      return;
    }

    dispatch(
      systemNotificationActions.open({
        message: "Something went wrong, please try again.",
        variant: "error",
      })
    );
  };
  const [showShiftingProgress, setShowShiftingProgress] =
    useState<boolean>(false);

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: DRAG_TYPE_GUESSTIMATION_ITEM_HORIZONTAL,
      item: props,
      end(item: TLaneItemProps, monitor) {
        const dropResult = monitor.getDropResult() as TDropResult | null;
        if (!dropResult) {
          return;
        }

        showShiftingProgressHandler(true);

        // Trigger the shift request
        dispatchShiftGuesstimationItemService({
          body: {
            startDate: dropResult.timeTick.date,
            timeTick: dropResult.timeTick,
          } as IShiftGuesstimationItemServiceRequest,
        })
          .then((resource: IGuesstimationItemDetailsResource) => {
            // Updating the guesstimation item in the state (for better UI experience)
            dispatch(
              guesstimationViewActions.updateGuesstimationItem(
                resource.guesstimationItem
              )
            );

            // Raising an update event to update the board (e.g. so that min/max dates would be recalculated)
            dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_UPDATED, {
              initGuesstimationItem: props.guesstimationItem,
              guesstimationItem: resource.guesstimationItem,
            } as TGuesstimationItemUpdatedEvent);

            dispatch(
              systemNotificationActions.open({
                message: `Guesstimation Item ${props.guesstimationItem.ref} has been moved to ${dropResult.timeTick.label}`,
                variant: "success",
              })
            );
          })
          .catch((error: THttpClientError) => {
            errorHandler(error);
          })
          .finally(() => {
            showShiftingProgressHandler(false);
          });

        // Set drop coordinates and task related data, this will be used by other components
        dispatch(
          guesstimationViewItemActions.setDropData({
            coords: {
              x: dropResult.timeTick.dropXCoord,
              y: 3,
            },
            itemLaneIndex: dropResult.itemLaneIndex,
            guesstimationItem: props.guesstimationItem,
            users: props.users,
          })
        );
      },
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [JSON.stringify(props.guesstimationItem)]
  );

  const showShiftingProgressHandler = (showShiftingProgress: boolean) => {
    // This flag is used by other components that want to track the progress
    showShiftingProgress
      ? setTimeout(
          () => dispatch(guesstimationViewActions.animateLaneOverlay(true)),
          10
        )
      : dispatch(guesstimationViewActions.displayLaneOverlay(false));

    dispatch(
      guesstimationViewItemActions.showShiftingProgress(showShiftingProgress)
    );
    // This flag is very specifically for this corresponding task that finished dropping
    setShowShiftingProgress(showShiftingProgress);
  };

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>) =>
    dispatch(
      guesstimationViewItemActions.setDragStartCoords({
        x: e.clientX,
        y: e.clientY,
      })
    );

  return {
    dragPreview,
    isDragging,
    drag,
    dragStartHandler,
    showShiftingProgress,
  };
};
