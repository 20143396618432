import React, { useEffect, useState, useTransition } from "react";
import { TEstimationFormProps } from "./types/TEstimationFormProps";
import { TEstimationFormAttributes } from "./types/TEstimationFormAttributes";
import { Grid } from "@mui/material";
import {
  Autocomplete,
  TextField,
} from "../../../../../../common/modules/materialUI";
import { enumUtil } from "../../../../../../common/utils/enum";
import { EstimationUomEnum } from "../../../../../../entities/enums/EstimationUomEnum";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TEstimationFormAttributesTransformed } from "./types/TEstimationFormAttributesTransformed";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { keyboard } from "../../../../../../common/utils/keyboard";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { AggStatusEnum } from "../../../../../../entities/columns/task/AggStatusEnum";
import { useUpdateGuesstimationItemService } from "../../services/useUpdateGuesstimationItemService";
import { TListResource } from "../../../../../../common/types/TListResource";
import { IChangeEstimationRequest } from "../../../../../task/interfaces/IChangeEstimationRequest";
import { TGuesstimationItemUpdatedEvent } from "../types/TGuesstimationItemUpdatedEvent";
import Actions from "../../../../../task/modules/view/containers/Actions/Actions";
import DetailsLabel from "../../../../../task/modules/view/containers/TaskViewDetails/DetailsLabel";
import { IGuesstimationItemDetailsResource } from "../../interfaces/IGuesstimationItemDetailsResource";

const EstimationForm = ({ guesstimationItem }: TEstimationFormProps) => {
  const dispatchEvent = useDispatchEvent();
  const dispatch = useAppDispatch();
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const estimationPoints = guesstimationItem.estimationPoints;
  const estimationUom = guesstimationItem.estimationUom;
  const uomList: TListResource<string> = [
    { id: EstimationUomEnum.DAYS, label: EstimationUomEnum.DAYS },
  ];
  const form = useForm<TEstimationFormAttributes>({
    estimationPoints: estimationPoints,
    estimationUom: enumUtil.toListItem(estimationUom),
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [, startTransition] = useTransition();

  const { dispatch: dispatchUpdateGuesstimationItemService } =
    useUpdateGuesstimationItemService(guesstimationItem.id);

  useEffect(() => {
    form.changeDefaultData({
      estimationUom: enumUtil.toListItem(estimationUom),
      estimationPoints: estimationPoints,
    });
  }, [estimationUom, estimationPoints]);

  useEffect(() => {
    form.isDirty() && setFormOpen(true);
    if (!form.isDirty()) {
      setFormOpen(false);
      form.resetErrors();
    }
  }, [form]);

  const submitHandler = () => {
    if (!formOpen || isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    startTransition(() => {
      const formData =
        form.getTransformed<TEstimationFormAttributesTransformed>((data) => ({
          estimationPoints: data.estimationPoints,
          estimationUom: data.estimationUom?.id,
        }));
      dispatchUpdateGuesstimationItemService({
        body: {
          estimationPoints: formData.estimationPoints,
          estimationUom: formData.estimationUom,
        } as IChangeEstimationRequest,
      })
        .then((payload: IGuesstimationItemDetailsResource) => {
          dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_UPDATED, {
            initGuesstimationItem: guesstimationItem,
            guesstimationItem: payload.guesstimationItem,
          } as TGuesstimationItemUpdatedEvent);
        })
        .catch((error: THttpClientError) => {
          error.status === 422 &&
            (error.data.errors as { collapseError: string }).collapseError &&
            dispatch(
              systemNotificationActions.open({
                message: (error.data.errors as { collapseError: string })
                  .collapseError,
                variant: "warning",
              })
            );

          if (error.status === 403) {
            dispatch(
              systemNotificationActions.open({
                message: error.data.message,
                variant: "warning",
              })
            );
            return;
          }

          error.status !== 422 &&
            dispatch(
              systemNotificationActions.open({
                message:
                  "There was a problem with updating the estimation. Please try again.",
                variant: "error",
              })
            );

          form.errorHandler(error);
        })
        .finally(() => setIsSubmitting(false));
    });
  };

  const formDisplayHandler = () => {
    setFormOpen(false);
    form.reset();
  };

  return (
    <>
      <Grid item xs={4}>
        <DetailsLabel>Estimation</DetailsLabel>
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          disabled={
            guesstimationItem.statusType === AggStatusEnum.DONE || isSubmitting
          }
          onKeyDown={(event) => keyboard.onEnter(event, submitHandler)}
          form={form}
          name="estimationUom"
          options={uomList}
          textFieldProps={{
            InputProps: {
              sx: {
                paddingRight: "16px !important",
                "&.Mui-focused.MuiAutocomplete-inputRoot": {
                  paddingRight: "46px !important",
                },
                ":hover, :active": {
                  paddingRight: "40px !important",
                },
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          onKeyDown={(event) => keyboard.onEnter(event, submitHandler)}
          form={form}
          name="estimationPoints"
          disabled={
            guesstimationItem.statusType === AggStatusEnum.DONE || isSubmitting
          }
          autoComplete="off"
        />
      </Grid>
      {formOpen && (
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="flex-end"
          sx={{ pt: "5px !important" }}
        >
          <Actions
            submitHandler={submitHandler}
            cancelHandler={formDisplayHandler}
            disabled={isSubmitting}
          />
        </Grid>
      )}
    </>
  );
};

export default EstimationForm;
