import { TVerificationProps } from "../types/TVerificationProps";
import OrgSignUp from "../SignUp/OrgSignUp";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../../../integrations/modules/MSAuth/config/authConfig";
import { MsalProvider } from "@azure/msal-react";

const OrgSignUpStep = (props: TVerificationProps) => {
  const msalInstance = new PublicClientApplication(msalConfig);

  return {
    label: "Registration",
    content: (
      <MsalProvider instance={msalInstance}>
        <OrgSignUp {...props} />
      </MsalProvider>
    ),
  };
};

export default OrgSignUpStep;
