export const number = {
  randomBetween: (min: number, max: number, round: boolean = false) => {
    const randomNumber = Math.random() * (max - min) + min;

    return round ? Math.round(randomNumber) : randomNumber;
  },

  format: (number: number, decimalPoints: number = 2) =>
    number % 1 === 0 ? number : number.toFixed(decimalPoints),

  formatAsPrice: (
    number: number,
    minDecimalPoints: number = 0,
    maxDecimalPoints: number = 2
  ) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: minDecimalPoints,
      maximumFractionDigits: maxDecimalPoints,
    }).format(number),

  formatToUSD: (number: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  },

  formatBytes: (bytes: number, decimals: number = 2) => {
    bytes = parseInt(bytes.toString());

    if (bytes === 0) {
      return "0 Bytes";
    }

    const kb = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(kb));
    const formattedSize = (bytes / Math.pow(kb, i)).toFixed(decimals);

    return parseFloat(formattedSize) + " " + sizes[i];
  },
};
