import { TVerifyPhoneProps } from "../VerifyPhone/types/TVerifyPhoneProps";
import VerifyPhone from "../VerifyPhone/VerifyPhone";

const VerifyPhoneStep = (props: TVerifyPhoneProps) => {
  return {
    label: "Verify Phone",
    content: <VerifyPhone {...props} />,
  };
};

export default VerifyPhoneStep;
