import { TFormHelper } from "./types/TFormHelper";
import { NotificationMethodEnum } from "../../../../../entities/enums/NotificationMethodEnum";
import { TypeEnum } from "../../../../../entities/columns/monitor/TypeEnum";
import { ThresholdOperatorEnum } from "../../../../../entities/enums/ThresholdOperatorEnum";

export const FormHelper: TFormHelper = {
  withForm: (form) => {
    const formHelper = FormHelper;
    formHelper.form = form;
    return formHelper;
  },

  hasSelectedReps: () => (FormHelper.form?.reps?.length ?? 0) > 0,
  hasSelectedUserNotificationMethod: () =>
    !!FormHelper.form?.notificationMethods?.find(
      (notificationMethod) =>
        notificationMethod.id === NotificationMethodEnum.SMS ||
        notificationMethod.id === NotificationMethodEnum.EMAIL ||
        notificationMethod.id === NotificationMethodEnum.PHONE_CALL
    ),
  hasSelectedSlackNotificationMethod: () =>
    !!FormHelper.form?.notificationMethods?.find(
      (notificationMethod) =>
        notificationMethod.id === NotificationMethodEnum.SLACK
    ),
  hasSelectedValue: () => !!FormHelper.form?.type && !!FormHelper.form?.value,
  hasNotificationRequested: () =>
    !!FormHelper.form?.firstThresholdEnabled ||
    !!FormHelper.form?.secondThresholdEnabled,

  renderTextForValue: () => {
    const type = FormHelper.form?.type?.id;

    return !type || type === TypeEnum.NUMBER;
  },
  renderDateForValue: () => {
    const type = FormHelper.form?.type?.id;

    return (
      type === TypeEnum.DATE ||
      type === TypeEnum.START_DATE ||
      type === TypeEnum.DUE_DATE
    );
  },
  renderTextForThresholdValue: (operator) =>
    FormHelper.renderTextForValue() ||
    operator === ThresholdOperatorEnum.PERCENT,
  renderDateForThresholdValue: (operator) =>
    FormHelper.renderDateForValue() &&
    operator !== ThresholdOperatorEnum.PERCENT,

  shouldResetValue: (type, prevType) => {
    const dateTypes = [TypeEnum.DATE, TypeEnum.START_DATE, TypeEnum.DUE_DATE];
    const numberTypes = [TypeEnum.NUMBER];

    return (
      !type ||
      !prevType ||
      (dateTypes.includes(type) && numberTypes.includes(prevType)) ||
      (numberTypes.includes(type) && dateTypes.includes(prevType))
    );
  },
  shouldResetThresholdValue: (operator, prevOperator) => {
    const numberTypes = [ThresholdOperatorEnum.PERCENT];
    const newNumeric = numberTypes.includes(operator);
    const prevNumeric = numberTypes.includes(prevOperator);

    return (
      FormHelper.renderDateForValue() &&
      ((newNumeric && !prevNumeric) || (prevNumeric && !newNumeric))
    );
  },
};
