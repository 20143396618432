import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { array } from "../../../../common/utils/array";
import classes from "./Description.module.css";
import { EditorContent } from "../../../../common/modules/editor";
import { TDescriptionContentProps } from "./types/TDescriptionContentProps";

const descriptionBlockMaxHeight = 240;

const DescriptionContent = ({
  editorReady,
  editorRequested,
  expanded,
  html,
  isLoading,
  requestEditorHandler,
  setExpanded,
}: TDescriptionContentProps) => {
  const [showCollapseExpand, setShowCollapseExpand] = useState(false);
  const descriptionBlockRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const descriptionBlock = descriptionBlockRef.current;
    if (!descriptionBlock) {
      return;
    }

    const observer = new ResizeObserver(() => {
      const shouldShow =
        descriptionBlock.scrollHeight > descriptionBlockMaxHeight;
      setShowCollapseExpand(shouldShow);

      if (expanded && !shouldShow) {
        setExpanded(false);
      }
    });

    observer.observe(descriptionBlock);

    return () => {
      observer.unobserve(descriptionBlock);
    };
  }, []);

  return (
    <Box
      ref={descriptionBlockRef}
      className={array.toClassName([
        classes["description"],
        (editorRequested && !editorReady) || isLoading ? "pulsating" : "",
      ])}
      color={theme.palette.taskView.textMainColor}
      maxHeight={expanded ? "none" : `${descriptionBlockMaxHeight}px`}
      onClick={requestEditorHandler}
    >
      {html ? (
        <EditorContent html={html} style={{ overflowWrap: "anywhere" }} />
      ) : (
        <Typography className={classes["description-placeholder"]}>
          {!isLoading ? "Add a description" : "Loading description"}
        </Typography>
      )}

      {showCollapseExpand && (
        <Box
          className={classes["description-collapse-expand-block"]}
          sx={{
            backgroundImage: theme.palette.taskView.editorExpandGradient,
            height: expanded ? "auto" : undefined,
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Typography
            color={theme.palette.taskView.textMainColor}
            fontSize="13px"
            fontWeight={500}
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              setExpanded((prevExpanded) => !prevExpanded);
            }}
          >
            {expanded ? "Read less" : "Read more"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default DescriptionContent;
