import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CreateForm from "./CreateForm";
import { linkListActions } from "../../slices/linkListSlice";

const CreateModal = () => {
  const createState = useAppSelector((state) => state.linkList.create);
  const dispatch = useAppDispatch();

  const closeHandler = () => dispatch(linkListActions.closeCreateModal());

  return (
    <Dialog
      open={createState.showModal}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Add New Link</DialogTitle>
      <DialogContent>
        <CreateForm onCancel={closeHandler} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateModal;
