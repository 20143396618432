import { INonWorkday } from "../../../entities/INonWorkday";
import { ITypeEnum } from "../../../entities/columns/nonWorkday/ITypeEnum";

export const useNonWorkdayData = () => {
  const findOffDays = (data: INonWorkday[]) =>
    data.find((item) => item.type === ITypeEnum.OFF_DAYS);

  return {
    getOffDaysId: (data: INonWorkday[]) => findOffDays(data)?.id,
    getOffDays: (data: INonWorkday[]) =>
      findOffDays(data)?.offDayData.days ?? [],
    getExceptions: (data: INonWorkday[]) =>
      data
        .filter((item) => item.type === ITypeEnum.EXCEPTION)
        .map(
          (item) =>
            ({
              ...item,
              exceptionData: item.exceptionData,
            } as INonWorkday)
        ),
  };
};
