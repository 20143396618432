import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TThemeState } from "./types/TThemeState";
import { themeUtil } from "../utils/themeUtil";

const initialState: TThemeState = {
  mode: themeUtil.getPreferredMode(),
};

const slice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<TThemeState["mode"]>) => {
      state.mode = action.payload;
    },
  },
});

export const themeReducer = slice.reducer;

export const themeActions = slice.actions;
