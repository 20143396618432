import useUploadAttachmentServiceHelper from "../../../../../modules/attachment/hooks/useUploadAttachmentServiceHelper";
import { TUploadAttachmentHandlerProps } from "./types/TUploadAttachmentHandlerProps";
import { IAttachment } from "../../../../../entities/IAttachment";

export default function useUploadAttachmentHandler({
  entityType,
  entityId,
}: TUploadAttachmentHandlerProps) {
  const { dispatch: dispatchUploadAttachmentService } =
    useUploadAttachmentServiceHelper(entityType, entityId);

  return (
    file: File,
    onSuccess: (previewUrl?: string | null) => void,
    onError: (error: string) => void
  ) =>
    dispatchUploadAttachmentService(
      file,
      true,
      (resource: IAttachment) => onSuccess(resource.previewUrl),
      (error) => onError(error)
    );
}
