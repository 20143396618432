import InviteModal from "./InviteModal";
import { PersonAdd } from "@mui/icons-material";
import { Button } from "../../../../common/modules/materialUI";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { teamActions } from "../../slices/teamSlice";
import { TInviteMemberProps } from "./types/TInviteMemberProps";

const InviteMember = (props: TInviteMemberProps) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Button
        variant="text"
        startIcon={<PersonAdd />}
        onClick={() => dispatch(teamActions.openInvitationModal())}
        {...props.buttonProps}
      >
        {props.buttonProps?.children ?? "Invite a member"}
      </Button>
      <InviteModal />
    </>
  );
};

export default InviteMember;
