import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { monitorActions } from "../../slices/monitorSlice";
import CreateForm from "./CreateForm";
import { TCreateModalProps } from "./types/TCreateModalProps";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const CreateModal = (props: TCreateModalProps) => {
  const monitorLabel = useAppSelector((state) => state.customs.monitorLabel);
  const creation = useAppSelector((state) => state.monitor.creation);
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(monitorActions.closeCreationModal());
  };

  return (
    <Dialog
      open={creation.displayModal}
      onClose={closeHandler}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Add {monitorLabel}</DialogTitle>
      <DialogContent>
        <CreateForm onCancel={closeHandler} boardRef={props.boardRef} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateModal;
