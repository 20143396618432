import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { TUseDeleteTaskPriorityServiceProps } from "./types/TUseDeleteTaskPriorityServiceProps";

export const useDeleteTaskPriorityService = ({
  taskPriorityId,
}: TUseDeleteTaskPriorityServiceProps) => {
  return useHttpClientDispatchable({
    url: `/taskPriority/${taskPriorityId}`,
    method: RequestMethodEnum.DELETE,
  });
};
