import { linkActions } from "../../../../../../modules/link/slices/linkSlice";
import { AddLinkRounded } from "@mui/icons-material";
import { Typography } from "@mui/material";
import QuickActionButton from "../QuickActionButton";
import { useAppDispatch } from "../../../../../hooks/redux";
import { TEntityTypeProp } from "../../types/TEntityTypeProp";
import { TEntityIdProp } from "../../types/TEntityIdProp";
import { TProjectRefProp } from "../../types/TProjectRefProp";

export default function AddLink(
  props: TEntityTypeProp & TEntityIdProp & TProjectRefProp
) {
  const dispatch = useAppDispatch();

  return (
    <QuickActionButton
      onClick={() =>
        dispatch(
          linkActions.openCreateLinkModal({
            entityType: props.entityType,
            entityId: props.entityId,
            projectRef: props.projectRef,
          })
        )
      }
    >
      <AddLinkRounded fontSize="small" />
      <Typography fontSize="13px" fontWeight={500}>
        Link a Task
      </Typography>
    </QuickActionButton>
  );
}
