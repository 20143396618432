import { DialogContent, DialogTitle } from "@mui/material";
import { exploreAndShareActions } from "../../slices/exploreAndShareSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import Tabs from "./Tabs";
import { Dialog } from "../../../materialUI";

const WhatsNewModal = () => {
  const openWhatsNew = useAppSelector(
    (state) => state.exploreAndShare.openWhatsNew
  );
  const dispatch = useAppDispatch();

  if (!openWhatsNew) {
    return null;
  }

  const closeHandler = () => {
    dispatch(exploreAndShareActions.closeWhatsNew());
  };

  return (
    <Dialog
      open={openWhatsNew}
      renderCloseButton
      closeButtonHandler={closeHandler}
      onClose={closeHandler}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>What's New</DialogTitle>
      <DialogContent sx={{ p: 1 }}>
        <Tabs />
      </DialogContent>
    </Dialog>
  );
};

export default WhatsNewModal;
