import classes from "./BottomSection.module.scss";
import ThemeToggleListItem from "./ThemeToggleListItem";
import List from "../../../common/List/List";
import IntegrationListItem from "./IntegrationListItem";
import TeamsListItem from "./TeamsListItem";
import { useAccessControl } from "../../../../../../../modules/accessControl/hooks/useAccessControl";
import { AbilityEnum } from "../../../../../../../modules/accessControl/enums/AbilityEnum";
import AppSettingsListItem from "./AppSettingsListItem";
import { TSectionProps } from "../../../types/TSectionProps";

const BottomSection = (props: TSectionProps) => {
  const { can } = useAccessControl();

  let list = [ThemeToggleListItem({ onClick: props.onClick })];
  if (can(AbilityEnum.administrate)) {
    list = [
      ...list,
      ...[
        IntegrationListItem({ onClick: props.onClick }),
        TeamsListItem({ onClick: props.onClick }),
        AppSettingsListItem({}),
      ],
    ];
  }

  return (
    <div className={classes.container}>
      <List items={list} />
    </div>
  );
};

export default BottomSection;
