import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { TChildren } from "../../../../types/TChildren";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { darkTheme } from "../../darkTheme";
import { lightTheme } from "../../lightTheme";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";
import { themeUtil } from "../../utils/themeUtil";
import { themeActions } from "../../slices/themeSlice";

const ThemeProvider = (props: TChildren) => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector((state) => state.theme.mode);
  useMedia(QueryEnum.PREFERS_DARK_THEME, (prefersDark) => {
    !themeUtil.userHasPreferredMode() &&
      dispatch(themeActions.setMode(prefersDark ? "dark" : "light"));
  });

  return (
    <MuiThemeProvider theme={mode === "light" ? lightTheme : darkTheme}>
      {props.children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
