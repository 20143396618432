import { Typography, useTheme } from "@mui/material";
import { TCircularProgressBarTitleProps } from "./types/TCircularProgressBarTitleProps";

const CircularProgressBarTitle = ({
  progressBarSize,
  fontSize = 3.5,
  children,
}: TCircularProgressBarTitleProps) => {
  const theme = useTheme();

  return (
    <Typography
      align="center"
      color={theme.palette.text.primary}
      fontSize={`${progressBarSize / fontSize}px`}
      lineHeight={1}
      letterSpacing="normal"
    >
      {children}
    </Typography>
  );
};

export default CircularProgressBarTitle;
