import { taskViewActions } from "../../../../../../modules/task/modules/view/slices/viewSlice";
import { Archive } from "@mui/icons-material";
import { Typography } from "@mui/material";
import QuickActionButton from "../QuickActionButton";
import { useAppDispatch } from "../../../../../hooks/redux";
import { InternalTypeEnum } from "../../../../../../entities/columns/task/InternalTypeEnum";
import { TMoveToBacklogProps } from "./types/TMoveToBacklogProps";
import { Tooltip } from "../../../../materialUI";
import { featureUtil } from "../../../../accessControl/utils/featureUtil";
import { useIsFeatureAllowed } from "../../../../accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../accessControl/enums/FeatureEnum";
import { systemNotificationActions } from "../../../../systemNotification/slices/systemNotificationSlice";

export default function MoveToBacklog({
  taskId,
  taskInternalType,
}: TMoveToBacklogProps) {
  const dispatch = useAppDispatch();
  const isBacklogAllowed = useIsFeatureAllowed(FeatureEnum.BACKLOG_ICEBOX);

  if (taskInternalType !== InternalTypeEnum.TASK) {
    return null;
  }

  return (
    <Tooltip title={featureUtil.notAvailableText} when={!isBacklogAllowed}>
      <span style={{ width: "100%" }}>
        <QuickActionButton
          onClick={() =>
            isBacklogAllowed
              ? dispatch(
                  taskViewActions.openMoveToBacklogConfirmation({
                    taskId,
                  })
                )
              : dispatch(
                  systemNotificationActions.open({
                    message: featureUtil.notAvailableFullText,
                    variant: "warning",
                  })
                )
          }
        >
          <Archive fontSize="small" />
          <Typography fontSize="13px" fontWeight={500}>
            Move to Backlog
          </Typography>
        </QuickActionButton>
      </span>
    </Tooltip>
  );
}
