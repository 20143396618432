import classes from "./Desktop.module.scss";
import NavigationIcon from "../common/NavigationIcon/NavigationIcon";
import TaskSensorLogo from "../../../../../components/Logo/TaskSensorLogo";
import { THeaderProps } from "../types/THeaderProps";
import UserMenu from "../../../../../../modules/user/containers/Menu/UserMenu";
import { NavLink } from "react-router-dom";
import FreeTrial from "../../../../../../modules/user/modules/freeTrial/containers/FreeTrial";
import HelpMenu from "../../../../../../modules/user/containers/Menu/HelpMenu";

const Desktop = (props: THeaderProps) => {
  return (
    <div className={classes.container}>
      <div className={classes["left-container"]}>
        <NavLink to={"/"}>
          <TaskSensorLogo
            className={classes.logo}
            size={300}
            colorCombo={"white-green"}
            width={155}
          />
        </NavLink>
        <NavigationIcon className={classes["navigation-toggle"]} />
      </div>
      <div className={classes["right-container"]}>
        <div className={classes["header-registry"]}>{props.controls}</div>
        <div className={classes["reserved-container"]}>
          <FreeTrial />
          <HelpMenu />
          <UserMenu />
        </div>
      </div>
    </div>
  );
};

export default Desktop;
