import { Grid, Typography, useTheme } from "@mui/material";
import { TLinkProps } from "./types/TLinkProps";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { array } from "../../../../common/utils/array";
import { ILinkResource } from "../../interfaces/ILinkResource";
import LinkInfo from "./LinkInfo";
import { linkActions } from "../../slices/linkSlice";
import LinkEntityRow from "../LinkEntityRow/LinkEntityRow";
import usePortalTabActions from "../../../task/modules/view/hooks/usePortalTabActions";
import React from "react";
import { AddLinkOutlined } from "@mui/icons-material";

const Link = (props: TLinkProps) => {
  const { CountsPortalComponent, ActionsPortalComponent } = usePortalTabActions(
    props.tabIndex
  );
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const entityLinkList = useAppSelector((state) => state.link.entityLinkList);
  const linkList = useAppSelector((state) => state.org.init.linkList);

  const openModalHandler = (
    event: React.MouseEvent<HTMLElement | SVGSVGElement>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(
      linkActions.openCreateLinkModal({
        entityId: props.entityId,
        entityType: props.entityType,
        projectRef: props.projectRef,
      })
    );
  };

  return (
    <>
      {CountsPortalComponent(
        <>{entityLinkList.length > 0 ? entityLinkList.length : 0} Links</>
      )}
      {ActionsPortalComponent(
        <Typography
          color="#818692"
          fontSize="13px"
          display="flex"
          alignItems="center"
          gap="4px"
          sx={{ cursor: "pointer" }}
          onClick={(event) => openModalHandler(event)}
        >
          <AddLinkOutlined fontSize="small" />
          Add {!props.isMobileView && "Link"}
        </Typography>
      )}
      <Grid container flexDirection="column" gap="8px">
        {Object.entries(array.groupBy(entityLinkList, "linkListId")).map(
          ([linkListIdStr, data]) => {
            const item = data as ILinkResource[];
            const linkListId = parseInt(linkListIdStr);
            const linkListItem = linkList.find(
              (item) => item.id === linkListId
            );
            return (
              <Grid container item key={linkListId}>
                <Grid
                  container
                  item
                  bgcolor={theme.palette.taskView.headerTitleBackground}
                  color="#818692"
                  mb="8px"
                  p="4px 8px"
                  alignItems="center"
                  gap="8px"
                >
                  <Typography
                    fontSize="13px"
                    fontWeight={500}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {linkListItem?.description}
                  </Typography>
                  <LinkInfo
                    linkList={linkList}
                    linkListId={linkListId}
                    projectRef={props.projectRef}
                  />
                </Grid>

                <Grid container item flexDirection="column" gap="8px">
                  {item.map((link: ILinkResource) => (
                    <LinkEntityRow
                      key={`lv-tr-${link.linkEntity.title}`}
                      linkId={link.link.id}
                      linkListId={link.link.linkListId}
                      linkEntity={link.linkEntity}
                    />
                  ))}
                </Grid>
              </Grid>
            );
          }
        )}
      </Grid>
    </>
  );
};

export default Link;
