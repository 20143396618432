import { Button } from "../../modules/materialUI";
import { ArrowBack } from "@mui/icons-material";
import useMedia from "../../hooks/useMedia/useMedia";
import { QueryEnum } from "../../hooks/useMedia/enums/QueryEnum";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Back = () => {
  const matchesMax992Screen = useMedia(QueryEnum.MAX_WIDTH_992);
  const navigate = useNavigate();
  const backLabel = "Back";

  const clickHandler = () => {
    navigate(-1);
  };

  return (
    <>
      {!matchesMax992Screen ? (
        <Button variant="text" startIcon={<ArrowBack />} onClick={clickHandler}>
          {backLabel}
        </Button>
      ) : (
        <IconButton onClick={clickHandler} color="primary">
          <ArrowBack />
        </IconButton>
      )}
    </>
  );
};

export default Back;
