import { useAppSelector } from "../../../../../common/hooks/redux";
import { IBacklogBoardViewDataRequest } from "../interfaces/IBacklogBoardViewDataRequest";
import { query } from "../../../../../common/utils/query";

export const useBacklogBoardViewQueryParams =
  (): IBacklogBoardViewDataRequest => {
    const { extraFilters, sorting } = useAppSelector(
      (state) => state.backlogBoardQuery
    );
    const { taskTypeIds, taskPriorityIds, customFields } = extraFilters;

    return {
      filters: {
        ...(taskTypeIds.length > 0 && {
          taskTypeIds: taskTypeIds.map((item) => item.id),
        }),
        ...(taskPriorityIds.length > 0 && {
          taskPriorityIds: taskPriorityIds.map((item) => item.id),
        }),
        ...(customFields.length > 0 && {
          customFields: customFields.map((item) => ({
            id: item.extraId,
            label: item.value,
          })),
        }),
      },
      ...(sorting && query.transformToQueryObject({ sorting })),
    };
  };
