import { useState } from "react";
import { TPickerProps } from "./types/TPickerProps";
import LinearPicker from "./LinearPicker";
import DropdownPicker from "./DropdownPicker";

const Picker = (props: TPickerProps) => {
  const [selection, setSelection] = useState<string | undefined>(
    props.defaultValue
  );

  const selectionHandler = (value?: string) => {
    setSelection(value);
  };

  return (
    <>
      {!props.renderDropdown && (
        <LinearPicker
          selection={selection}
          onClick={selectionHandler}
          {...props}
        />
      )}
      {!!props.renderDropdown && (
        <DropdownPicker
          selection={selection}
          onClick={selectionHandler}
          {...props}
        />
      )}
    </>
  );
};

export default Picker;
