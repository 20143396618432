import { useEffect, useRef } from "react";
import { TUsePrevious } from "./types/TUsePrevious";

const usePrevious: TUsePrevious = (value) => {
  const ref = useRef<any>();
  // The trick here is done only thanks to a fact that the useEffect is being executed at the very end of the function
  // means after the return processed by react for the current component/function.
  // So initially it will return undefined and on the next calls it will return the previous captured value
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export default usePrevious;
