import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import {
  TextField,
  Tooltip,
} from "../../../../../../common/modules/materialUI";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useUpdateGuesstimationItemService } from "../../services/useUpdateGuesstimationItemService";
import { TGuesstimationItemUpdatedEvent } from "../types/TGuesstimationItemUpdatedEvent";
import DetailsLabel from "../../../../../task/modules/view/containers/TaskViewDetails/DetailsLabel";
import { IGuesstimationItemDetailsResource } from "../../interfaces/IGuesstimationItemDetailsResource";
import { TEstimatedResourcesFormAttributes } from "./types/TEstimatedResourcesFormAttributes";
import { TEstimatedResourcesFormProps } from "./types/TEstimatedResourcesFormProps";
import { IChangeEstimatedResourcesRequest } from "../../../../../task/interfaces/IChangeEstimatedResourcesRequest";

const EstimatedResourcesForm = ({
  guesstimationItem,
}: TEstimatedResourcesFormProps) => {
  const dispatchEvent = useDispatchEvent();
  const dispatch = useAppDispatch();
  const form = useForm<TEstimatedResourcesFormAttributes>({
    estimatedResources: guesstimationItem.estimatedResources,
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { dispatch: dispatchUpdateGuesstimationItemService } =
    useUpdateGuesstimationItemService(guesstimationItem.id);

  useEffect(() => {
    form.changeDefaultData({
      estimatedResources: guesstimationItem.estimatedResources,
    });
  }, [guesstimationItem.estimatedResources]);

  useEffect(() => {
    if (!form.isDirty()) {
      form.resetErrors();
    }
  }, [form]);

  const submitHandler = (estimatedResources?: number) => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    dispatchUpdateGuesstimationItemService({
      body: {
        estimatedResources: estimatedResources ? estimatedResources : null,
      } as IChangeEstimatedResourcesRequest,
    })
      .then((payload: IGuesstimationItemDetailsResource) => {
        dispatchEvent(EventEnum.ON_GUESSTIMATION_ITEM_UPDATED, {
          initGuesstimationItem: guesstimationItem,
          guesstimationItem: payload.guesstimationItem,
        } as TGuesstimationItemUpdatedEvent);
      })
      .catch((error: THttpClientError) => {
        if (error.status === 403) {
          dispatch(
            systemNotificationActions.open({
              message: error.data.message,
              variant: "warning",
            })
          );
          return;
        }

        error.status !== 422 &&
          dispatch(
            systemNotificationActions.open({
              message:
                "There was a problem with updating the estimated resource. Please try again.",
              variant: "error",
            })
          );

        form.errorHandler(error);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <Grid item xs={4}>
        <Tooltip title="Estimated Resources Count" withSpanWrapper>
          <DetailsLabel>Estimated Resources Count</DetailsLabel>
        </Tooltip>
      </Grid>
      <Grid item xs={8}>
        <TextField
          form={form}
          name="estimatedResources"
          onEnter={(_, ref) => ref.current?.blur()}
          onBlur={() => {
            submitHandler && submitHandler(form.data?.estimatedResources);
          }}
        />
      </Grid>
    </>
  );
};

export default EstimatedResourcesForm;
