import { TUpdateFormProps } from "./types/TUpdateFormProps";
import { TUpdateFormAttributes } from "./types/TUpdateFormAttributes";
import { useAppDispatch } from "../../../../../common/hooks/redux";
import { statusFlowActions } from "../../../slices/statusFlowSlice";
import useForm from "../../../../../common/hooks/useForm/useForm";
import Form from "../../../../../common/components/Form/Form";
import { Button, TextField } from "../../../../../common/modules/materialUI";
import useMedia from "../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../common/hooks/useMedia/enums/QueryEnum";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";

const validateName = (name: string) => {
  let validationMessage = "";

  if (name.trim() === "") {
    validationMessage = "The Name field is required.";
  }
  if (name.trim().length > 20) {
    validationMessage =
      "The Name field must not be greater than 20 characters.";
  }

  return validationMessage
    ? ({
        status: 422,
        data: {
          message: "Validation Failed",
          errors: {
            name: [validationMessage],
          },
        },
      } as THttpClientError)
    : undefined;
};

const UpdateForm = ({ status, closeHandler }: TUpdateFormProps) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const dispatch = useAppDispatch();
  const form = useForm<TUpdateFormAttributes>({
    name: status.name,
  });

  const applyHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const name = form.data!.name.trim();
    const validationErrors = validateName(name);
    if (validationErrors) {
      form.errorHandler(validationErrors);
      return;
    }

    dispatch(statusFlowActions.updateName({ status, name }));
    closeHandler();
  };

  return (
    <Form
      onSubmit={applyHandler}
      actionElement={
        <>
          <Button onClick={closeHandler} variant="outlined" size="small">
            Cancel
          </Button>
          <Button variant="contained" type="submit" size="small">
            Apply
          </Button>
        </>
      }
    >
      <TextField
        autoFocus={!isTouchDevice}
        form={form}
        name="name"
        label="Name"
        showRequired
      />
    </Form>
  );
};

export default UpdateForm;
