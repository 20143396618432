import BoardHeaderControls from "../../../modules/board/containers/HeaderControl/HeaderControl";
import ProjectViewHeaderControl from "../../../modules/view/projectView/containers/HeaderControl/HeaderControl";
import { THeaderControlProps as TBoardHeaderControlProps } from "../../../modules/board/containers/HeaderControl/types/THeaderControlProps";
import { HeaderRegistryEnum } from "./enums/HeaderRegistryEnum";
import HeaderSchema from "../../containers/Layout/App/Header/HeaderRegistry/HeaderSchema";

export const HeaderRegistry = (registry: HeaderRegistryEnum, payload: {}) => {
  switch (registry) {
    case HeaderRegistryEnum.board: {
      return <BoardHeaderControls {...(payload as TBoardHeaderControlProps)} />;
    }
    case HeaderRegistryEnum.headerBackButtonOnly: {
      return <HeaderSchema displayBackButton />;
    }
    case HeaderRegistryEnum.projectView: {
      return <ProjectViewHeaderControl />;
    }
  }

  return null;
};
