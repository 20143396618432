import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TAssignedToFormProps } from "./types/TAssignedToFormProps";
import { TAssignedToFormAttributes } from "./types/TAssignedToFormAttributes";
import { Autocomplete } from "../../../../../../common/modules/materialUI";
import { useUserListService } from "../../../../../user/services/useUserListService";
import React, { useEffect, useState } from "react";
import { TAssignedToFormAttributesTransformed } from "./types/TAssignedToFormAttributesTransformed";
import { useAssignService } from "../../../../services/useAssignService";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { TListItem } from "../../../../../../common/types/TListItem";
import { useUnassignService } from "../../../../services/useUnassignService";
import { taskActions } from "../../../../slices/taskSlice";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { jwt } from "../../../../../../common/utils/jwt";
import { IAssignmentResource } from "../../../../interfaces/IAssignmentResource";
import { TListResource } from "../../../../../../common/types/TListResource";
import { AggStatusEnum } from "../../../../../../entities/columns/task/AggStatusEnum";
import { Grid } from "@mui/material";
import AddAssigneesButton from "./AddAssigneesButton";

const AssignedToForm = (props: TAssignedToFormProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();

  const assignedToListItem = props.userList.find(
    (user) => +user.id === props.task.assignedTo
  );
  const [options, setOptions] = useState<TListResource<string | number>>(
    assignedToListItem ? [assignedToListItem] : []
  );
  const form = useForm<TAssignedToFormAttributes>({
    assignedTo: assignedToListItem,
  });
  const { dispatch: dispatchAssignService } = useAssignService({
    taskSegmentId: +props.task.segmentId!,
  });
  const { dispatch: dispatchUnassignService } = useUnassignService({
    taskSegmentId: +props.task.segmentId!,
  });
  const userListService = useUserListService();

  useEffect(() => {
    form.set({
      assignedTo: assignedToListItem,
    });
    setOptions(assignedToListItem ? [assignedToListItem] : []);
  }, [props.task.assignedTo]);

  const submitHandler = (value?: TListItem<string>) => {
    if (!value?.id) {
      dispatchUnassignService({
        body: form.getTransformed<TAssignedToFormAttributesTransformed>(
          (_) => ({
            assignedTo: 0,
          })
        ),
      })
        .then((payload: IAssignmentResource) => {
          serviceSuccessHandler(payload);

          form.notDirty();
        })
        .catch((error: THttpClientError) => {
          notificationHandler(error.status, error.data?.message);
        });
      return;
    }

    dispatchAssignService({
      body: form.getTransformed<TAssignedToFormAttributesTransformed>((_) => ({
        assignedTo: value.id,
      })),
    })
      .then((payload: IAssignmentResource) => {
        form.notDirty();
        serviceSuccessHandler(payload, value);
      })
      .catch((error: THttpClientError) => {
        notificationHandler(error.status, error.data?.message);
      });
  };

  const serviceSuccessHandler = (
    payload: IAssignmentResource,
    value?: TListItem<string>
  ) => {
    // Update current split with assigned to and assigned by user because our split is merged as single task
    const jwtUser = jwt.parse();

    const { task, ...restPayload } = payload;

    dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED, {
      initTask: props.task,
      task: task,
      shift: restPayload,
    });

    dispatchEvent(EventEnum.ON_TASK_ASSIGNED_TO_UPDATED);

    // Add user to the list, so it can be shown as avatar
    value?.id &&
      dispatch(
        taskActions.addUser({
          id: +value.id!,
          name: value.label!,
          email: "",
          orgId: "",
        })
      );

    // Set assigned by to the current logged user
    dispatch(
      taskActions.setAssignedBy({
        id: jwtUser?.id.toString()!,
        label: jwtUser?.name!,
      })
    );

    dispatch(taskActions.setTask(task));
  };

  const notificationHandler = (errorStatus?: number, errorMessage?: string) => {
    errorStatus === 406 &&
      dispatch(
        systemNotificationActions.open({
          message: errorMessage ?? "Something went wrong, please try again.",
          variant: "warning",
          autoHideDuration: 3000,
          closeOnBlur: false,
        })
      );
    errorStatus !== 406 &&
      dispatch(
        systemNotificationActions.open({
          message:
            "There was a problem with changing the assignee. Please try again.",
          variant: "error",
          autoHideDuration: 3000,
          closeOnBlur: false,
        })
      );
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={9}>
        <Autocomplete
          form={form}
          name="assignedTo"
          placeholder="Unassigned"
          disabled={props.task?.statusType === AggStatusEnum.DONE}
          onBlur={() => submitHandler(form.data?.assignedTo)}
          service={userListService}
          options={options}
        />
      </Grid>
      <Grid item xs={3}>
        <AddAssigneesButton taskId={props.task.id} />
      </Grid>
    </Grid>
  );
};

export default AssignedToForm;
