import { useAppDispatch } from "../../../common/hooks/redux";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { objectiveActions } from "../slices/objectiveSlice";
import { IObjective } from "../../../entities/IObjective";
import { useHttpClientDispatchableOnly } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchableOnly";

export const useFetchObjectiveService = (props: { objectiveRef: string }) => {
  const dispatch = useAppDispatch();

  return useHttpClientDispatchableOnly({
    url: `/objective/${props.objectiveRef}`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IObjective) =>
        dispatch(objectiveActions.setObjective(data)),
    },
  });
};
