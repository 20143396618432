import { TextField } from "../../../../common/modules/materialUI";
import { keyboard } from "../../../../common/utils/keyboard";
import {
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { TAddNewOptionsProps } from "./types/TAddNewOptionsProps";

const AddNewOptions = <TFormAttributes extends { [key: string]: any }>({
  form,
  label,
  addOptionName,
  attributeName,
}: TAddNewOptionsProps<TFormAttributes>) => {
  // Get attribute name of the first option item error (e.g. "options.2")
  const firstOptionItemErrorAttr = Object.keys(
    form.validationErrors ?? {}
  ).find((key) => key.startsWith(`${attributeName}.`)) as keyof TFormAttributes;

  const addOptionHandler = () => {
    const newOption =
      form.data?.[addOptionName as keyof TFormAttributes]?.trim();
    // Adding option only if it's not empty text and not already added
    if (
      newOption &&
      !((form.data?.[attributeName] ?? []) as string[]).includes(newOption)
    ) {
      form.set({
        ...form.data!,
        [addOptionName]: "",
        [attributeName]: [...(form.data?.[attributeName] ?? []), newOption],
      });
    }
  };

  return (
    <>
      <TextField
        form={form}
        name={addOptionName as string}
        error={form.hasError(attributeName)}
        helperText={form.validationErrors?.[attributeName]}
        label={label}
        onKeyDown={(event) =>
          keyboard.onEnter(event, (event) => {
            // Preventing form submission on enter
            event.preventDefault();

            addOptionHandler();
          })
        }
        showRequired
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="Add Option" onClick={addOptionHandler}>
                <Add />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <FormControl>
        <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
          {(form.data?.[attributeName] as string[])?.map((item) => (
            <Chip
              key={item}
              onDelete={() => {
                form.set({
                  ...form.data!,
                  [attributeName]: (
                    form.data?.[attributeName] as string[]
                  )?.filter((option) => option !== item),
                });
              }}
              label={item}
              size="small"
            />
          ))}
        </Stack>
        <FormHelperText error={form.hasError(firstOptionItemErrorAttr)}>
          {form.validationErrors?.[firstOptionItemErrorAttr]}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default AddNewOptions;
