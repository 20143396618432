import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CreateForm from "./CreateForm";
import { objectiveActions } from "../../slices/objectiveSlice";

const CreateModal = () => {
  const createState = useAppSelector((state) => state.objective.create);
  const dispatch = useAppDispatch();

  const closeHandler = () => dispatch(objectiveActions.closeCreateModal());

  return (
    <Dialog
      open={createState.showModal}
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Add Objective</DialogTitle>
      <DialogContent>
        <CreateForm onCancel={closeHandler} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateModal;
