import { Moment } from "moment";
import moment from "moment/moment";

const FORMAT_RAW = "YYYY-MM-DD HH:mm:ss";
export const FORMAT_RAW_DATE_ONLY = "YYYY-MM-DD";
const FORMAT_ONE = "MMMM D, YYYY [at] h:mm A";
export const FORMAT_TWO = "MMMM DD, YYYY [at] HH:mm:ss";
export const FORMAT_MONTH_DAY = "MM/DD";
export const FORMAT_HOUR_MINUTE = "h:mm A";
export const FORMAT_LABEL = "MMM";
export const FORMAT_WITH_ORDINAL = "MMM Do";
export const FORMAT_YEAR_MONTH_DAY = "MM/DD/YY";
export const FORMAT_MONTH_DAY_YEAR_HOUR_MINUTE = "MM/DD/YY h:mma";

export const date = {
  intervalToDays: (
    startDate: Moment,
    endDate: Moment,
    inclusive: boolean = false,
    precise: boolean = true
  ) => {
    let diff = endDate.diff(startDate, "days", precise);
    if (inclusive) {
      diff += 1;
    }

    return diff;
  },

  dayMatches: (date1: Moment, date2: Moment) => {
    return (
      date1.clone().hours(0).minutes(0).seconds(0).milliseconds(0).unix() ===
      date2.clone().hours(0).minutes(0).seconds(0).milliseconds(0).unix()
    );
  },

  getRealWorldTime: (workingHour: number, workingHoursInDay: number) => {
    if (
      workingHour < 1 ||
      workingHour > 24 ||
      workingHoursInDay < 1 ||
      workingHoursInDay > 24
    ) {
      throw new Error(
        `getRealWorldTime: Invalid arguments, workingHour: ${workingHour}, workingHoursInDay: ${workingHoursInDay}`
      );
    }

    const realWorldTime = (workingHour - 1) * (24 / workingHoursInDay);
    // Extract whole hours and minutes
    const realWorldHours = Math.floor(realWorldTime);
    const realWorldMinutes = Math.round((realWorldTime - realWorldHours) * 60);

    // Format as a string with leading zeroes
    return (
      String(realWorldHours).padStart(2, "0") +
      ":" +
      String(realWorldMinutes).padStart(2, "0")
    );
  },

  nowString: (format = FORMAT_RAW) => {
    return date.format(moment(), format);
  },

  format: (date: string | Moment, format = FORMAT_ONE) =>
    moment(date).format(format),

  convertedToPrevDayEnd: (date: Moment) => {
    return date.format("HH:mm:ss") === "00:00:00"
      ? date.subtract(1, "day").endOf("day")
      : date;
  },
};
