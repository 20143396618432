import { IUserDataResource } from "../../../interfaces/IUserDataResource";
import { useRegisterOrgService } from "../../../services/useRegisterOrgService";
import {
  Button,
  TextField,
} from "../../../../../../../common/modules/materialUI";
import useMedia from "../../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../../common/hooks/useMedia/enums/QueryEnum";
import useForm from "../../../../../../../common/hooks/useForm/useForm";
import { TMSSignUpFormAttributes } from "./types/TMSSignUpFormAttributes";
import Form from "../../../../../../../common/components/Form/Form";
import useMSAuth from "../../../../../../../common/hooks/useMSAuth/useMSAuth";

const MSSignUpForm = (props: any) => {
  const isTouchDevice = useMedia(QueryEnum.IS_TOUCH_DEVICE);
  const form = useForm<TMSSignUpFormAttributes>();
  const useMsAuth = useMSAuth();
  const { isLoading, dispatch: dispatchRegisterOrgService } =
    useRegisterOrgService();

  const submitHandler = (e: any) => {
    const accessToken = useMsAuth.getAccessToken();
    if (!accessToken) {
      return;
    }

    e.preventDefault();
    dispatchRegisterOrgService({
      body: {
        accessToken: accessToken,
        subscriptionPlan: props.subscriptionPlan,
        orgName: form.data!.orgName,
        referralCode: sessionStorage.getItem("referralCode"),
      },
    })
      .then((payload: IUserDataResource) => {
        props.onNextHandler(payload.mail);
      })
      .catch((error) => {
        form.errorHandler(error);
      });
  };

  return (
    <>
      {useMsAuth.renderComponent({
        children: (
          <Form
            onSubmit={submitHandler}
            actionElement={
              <Button
                disabled={isLoading || !form.data?.orgName}
                loading={isLoading}
                variant="text"
                type="submit"
                fullWidth
                size="large"
              >
                Next
              </Button>
            }
          >
            <TextField
              sx={{ marginBottom: 2 }}
              forceFocus={!isTouchDevice}
              form={form}
              name="orgName"
              label="Organization Name"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              showRequired
            />
          </Form>
        ),
      })}
    </>
  );
};

export default MSSignUpForm;
