import Title from "../../../../common/components/Title/Title";
import ProjectList from "./ProjectList";
import CreateProject from "../CreateProject/CreateProject";

const ProjectManagementPage = () => {
  return (
    <>
      <Title>Project Management</Title>
      <CreateProject />
      <ProjectList />
    </>
  );
};

export default ProjectManagementPage;
