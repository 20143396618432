import { TListResource } from "../../../common/types/TListResource";
import { ITaskPriority } from "../../../entities/ITaskPriority";
import { TListItem } from "../../../common/types/TListItem";

export const taskPriorityUtil = {
  getDropdownOptions: (
    taskPriorities: ITaskPriority[],
    projectId?: number,
    emptyOptionText?: string,
    selectedTaskPriority?: ITaskPriority
  ) => {
    const listResourceHandler = (taskPriorities: ITaskPriority[]) => {
      return taskPriorities.map((taskPriority) => ({
        id: taskPriority.id,
        label: taskPriority.name,
      }));
    };
    const options: TListResource<string | number> = projectId
      ? listResourceHandler(
          taskPriorities.filter(
            (taskPriority) =>
              taskPriority.isActive &&
              (!taskPriority.projectIds ||
                taskPriority.projectIds.includes(projectId))
          )
        )
      : listResourceHandler(taskPriorities);

    // If no selected option in the list - add it to it at the beginning
    if (
      selectedTaskPriority &&
      !options.find((option) => option.id === selectedTaskPriority.id)
    ) {
      options.unshift({
        id: selectedTaskPriority.id,
        label: selectedTaskPriority.name,
      });
    }

    // Adding empty option at the beginning
    emptyOptionText && options.unshift({ id: "", label: emptyOptionText });

    return options;
  },
  getSelectedTaskPriority: (
    taskPriorities: ITaskPriority[],
    option?: TListItem<string | number>
  ) => {
    if (!option) {
      return undefined;
    }

    return taskPriorities.find((taskPriority) => taskPriority.id === option.id);
  },
};
