import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { IBaseEntity } from "../../../entities/interfaces/IBaseEntity";
import Title from "../../../common/components/Title/Title";
import NotFound from "../../../common/components/Page/NotFound/NotFound";
import Loader from "../../../common/containers/Loader/Loader";
import useRegisterHeader from "../../../common/modules/registry/hooks/useRegisterHeader";
import { useFetchNonWorkdayService } from "../services/useFetchNonWorkdayService";
import { useNonWorkdayData } from "../hooks/useNonWorkdayData";
import { INonWorkday } from "../../../entities/INonWorkday";
import { HeaderRegistryEnum } from "../../../common/modules/registry/enums/HeaderRegistryEnum";
import { useOrgProjectData } from "../../org/hooks/useOrgProjectData";
import { useAppSelector } from "../../../common/hooks/redux";
import Exceptions from "./Exceptions";
import OffDays from "./OffDays";

const ConfigNonWorkingDaysPage = () => {
  const [offDayData, setOffDayData] = useState<number[]>([]);
  const [offDayDataId, setOffDayDataId] = useState<IBaseEntity["id"]>(null);
  const [exceptions, setExceptions] = useState<INonWorkday[]>();
  const [nonWorkdayLoaded, setNonWorkdayLoaded] = useState<boolean>(false);

  const initialOffDayData = useRef<number[]>(offDayData);

  const { projectAbbr } = useParams();
  const project = useOrgProjectData().findProjectByAbbr(projectAbbr!)?.project;
  const { isLoading: isOrgInitLoading } = useAppSelector(
    (state) => state.org.init
  );

  useRegisterHeader(HeaderRegistryEnum.headerBackButtonOnly, [projectAbbr]);

  const { dispatch: dispatchFetchNonWorkingDay } = useFetchNonWorkdayService();
  const { getOffDays, getOffDaysId, getExceptions } = useNonWorkdayData();

  useEffect(() => {
    project?.id &&
      dispatchFetchNonWorkingDay({ urlPath: `/${project.id}` }).then(
        (data: INonWorkday[]) => {
          const nonWorkdayData = getOffDays(data);
          const nonWorkdayDataId = getOffDaysId(data);
          const exceptionsData = getExceptions(data);

          setOffDayData(nonWorkdayData);
          setOffDayDataId(nonWorkdayDataId);
          setExceptions(exceptionsData);

          initialOffDayData.current = nonWorkdayData;
          setNonWorkdayLoaded(true);
        }
      );
  }, [project?.id]);

  return (
    <>
      {isOrgInitLoading && <Loader />}

      {!isOrgInitLoading && !project ? (
        <NotFound />
      ) : (
        <>
          {!nonWorkdayLoaded && <Loader />}
          <Title subtitle={project?.name}>Non Working Days</Title>
          <OffDays
            disableButton={!nonWorkdayLoaded}
            initialOffDayData={initialOffDayData}
            offDayData={offDayData}
            offDayDataId={offDayDataId}
            setOffDayData={setOffDayData}
          />
          <Exceptions
            disableButton={!nonWorkdayLoaded}
            exceptions={exceptions!}
            setExceptions={setExceptions}
          />
        </>
      )}
    </>
  );
};

export default ConfigNonWorkingDaysPage;
