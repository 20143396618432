import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { TBoardRefProp } from "../../../../board/types/TBoardRefProp";

type TProps = {} & TBoardRefProp;

export const useFillTimelineService = (props: TProps) =>
  useHttpClientDispatchable({
    url: `/board/${props.boardRef}/holisticView/fillTimeline`,
    method: RequestMethodEnum.POST,
  });
