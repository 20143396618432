import { Grid, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { TGuesstimationItemViewDetailsProps } from "./types/TGuesstimationItemViewDetailsProps";
import { StateHelper } from "../../../../../../common/components/Task/components/State/helpers/StateHelper";
import StateIcon from "../../../../../../common/components/Task/components/State/StateIcon";
import { date, FORMAT_TWO } from "../../../../../../common/utils/date";
import AvatarGroup from "../../../../../user/containers/AvatarGroup/AvatarGroup";
import React from "react";
import DetailsLabel from "../../../../../task/modules/view/containers/TaskViewDetails/DetailsLabel";
import EstimationForm from "../Estimation/EstimationForm";
import StartDateForm from "../StartDate/StartDateForm";
import DueDateForm from "../DueDate/DueDateForm";
import AddAssigneesButton from "../AddAssignees/AddAssigneesButton";
import EstimatedResourcesForm from "../EstimatedResources/EstimatedResourcesForm";

const GuesstimationItemViewDetails = (
  props: TGuesstimationItemViewDetailsProps
) => {
  const { resource } = props;

  const theme = useTheme();

  const createdAt = moment(resource.guesstimationItem.createdAt);
  const updatedAt = moment(resource.guesstimationItem.updatedAt);

  return (
    <Grid container spacing="20px" alignItems="center">
      <Grid item xs={4}>
        <DetailsLabel>Board</DetailsLabel>
      </Grid>
      <Grid item xs={8}>
        <Typography
          color={theme.palette.taskView.textSecondaryColor}
          fontSize="15px"
          fontWeight={500}
        >
          {resource.guesstimationItem.boardName}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <DetailsLabel>Assigned By</DetailsLabel>
      </Grid>
      <Grid item xs={8}>
        <Typography
          color={theme.palette.taskView.textSecondaryColor}
          fontSize="15px"
          fontWeight={500}
        >
          {resource.createdBy?.name ?? "AI Sentinel"}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <DetailsLabel>Assigned To</DetailsLabel>
      </Grid>
      <Grid item xs={8}>
        <AvatarGroup users={resource.assignees} count={6} />
        <AddAssigneesButton guesstimationItem={resource.guesstimationItem} />
      </Grid>

      <EstimationForm guesstimationItem={resource.guesstimationItem} />

      {StateHelper.willDisplayState(resource.guesstimationItem.statusType) && (
        <>
          <Grid item xs={4}>
            <DetailsLabel>State</DetailsLabel>
          </Grid>

          <Grid container item xs={8} alignItems="center" gap="8px">
            <StateIcon
              placement="left"
              status={resource.guesstimationItem.status}
              statusType={resource.guesstimationItem.statusType}
              style={{ display: "flex" }}
            />
            <Typography
              color={theme.palette.taskView.textSecondaryColor}
              fontSize="15px"
              fontWeight={500}
              sx={{
                "::first-letter": {
                  textTransform: "uppercase",
                },
              }}
            >
              {StateHelper.getLabel(
                resource.guesstimationItem.statusType,
                resource.guesstimationItem.status
              ).toLowerCase()}
            </Typography>
          </Grid>
        </>
      )}

      <StartDateForm guesstimationItem={resource.guesstimationItem} />

      <DueDateForm guesstimationItem={resource.guesstimationItem} />

      {/*<Grid item xs={4}>*/}
      {/*  <DetailsLabel>Collisions</DetailsLabel>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={8}>*/}
      {/*  {!!resource.guesstimationItem.collisions?.length*/}
      {/*    ? resource.guesstimationItem.collisions.map((collision) => {*/}
      {/*        const user = resource.assignees.find(*/}
      {/*          (assignee) => assignee.id === collision.userId*/}
      {/*        );*/}

      {/*        return (*/}
      {/*          <Box*/}
      {/*            key={`${collision.userId}-${collision.taskId}`}*/}
      {/*            color={theme.palette.taskView.textSecondaryColor}*/}
      {/*            fontSize="15px"*/}
      {/*            fontWeight={500}*/}
      {/*          >*/}
      {/*            Task id: {collision.taskId}, user:{" "}*/}
      {/*            <AvatarIcon*/}
      {/*              userName={user?.name}*/}
      {/*              tooltip={`${user?.name} (${collision.userRole})`}*/}
      {/*              size={17}*/}
      {/*            />*/}
      {/*          </Box>*/}
      {/*        );*/}
      {/*      })*/}
      {/*    : "None"}*/}
      {/*</Grid>*/}

      {/*<Grid item xs={4}>*/}
      {/*  <DetailsLabel>Cls Overview</DetailsLabel>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={8}>*/}
      {/*  <Box*/}
      {/*    color={theme.palette.taskView.textSecondaryColor}*/}
      {/*    fontSize="15px"*/}
      {/*    fontWeight={500}*/}
      {/*  >*/}
      {/*    {!!resource.guesstimationItem.collisionsOverview?.length*/}
      {/*      ? resource.guesstimationItem.collisionsOverview.map(*/}
      {/*          (collisionOverview) => {*/}
      {/*            return (*/}
      {/*              <div key={collisionOverview.userRole}>*/}
      {/*                User Role: {collisionOverview.userRole}, Count:{" "}*/}
      {/*                {collisionOverview.count}*/}
      {/*              </div>*/}
      {/*            );*/}
      {/*          }*/}
      {/*        )*/}
      {/*      : "None"}*/}
      {/*  </Box>*/}
      {/*</Grid>*/}

      <EstimatedResourcesForm guesstimationItem={resource.guesstimationItem} />

      <Grid container item mt="16px" gap="4px">
        <Grid item xs={12}>
          <Typography color={theme.palette.taskView.labelColor} fontSize="13px">
            Created {date.format(createdAt, FORMAT_TWO)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography color={theme.palette.taskView.labelColor} fontSize="13px">
            Updated {date.format(updatedAt, FORMAT_TWO)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GuesstimationItemViewDetails;
