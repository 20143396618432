import { useAppDispatch } from "../../../../../common/hooks/redux";
import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IKanbanViewDataResource } from "../../../interfaces/IKanbanViewDataResource";
import { kanbanViewActions } from "../slices/kanbanViewSlice";
import { IKanbanViewDataRequest } from "../../../interfaces/IKanbanViewDataRequest";
import { listingActions } from "../../../../../common/modules/listing/slices/listingSlice";

export const useFetchKanbanViewDataService = (props: {
  boardRef: string;
  query: IKanbanViewDataRequest;
}) => {
  const dispatch = useAppDispatch();
  return useHttpClientDispatchable({
    url: `/board/${props.boardRef}/kanbanView/data`,
    method: RequestMethodEnum.GET,
    query: props.query,
    events: {
      beforeTransform: (data: IKanbanViewDataResource) => {
        dispatch(kanbanViewActions.setList(data));
        dispatch(
          kanbanViewActions.setIsSegmentView(!!props.query?.showSegments)
        );
        dispatch(listingActions.setSprintList(data.sprints));
        dispatch(kanbanViewActions.setSprintDataList(data.sprintData));
        dispatch(kanbanViewActions.setHavingBoardUsers(data.havingBoardUsers));
        dispatch(
          listingActions.setFilterableCustomFields(data.filterableCustomFields)
        );
      },
    },
  });
};
