import useForm from "../../../../../../common/hooks/useForm/useForm";
import { TStatusFormProps } from "./types/TStatusFormProps";
import { TStatusFormAttributes } from "./types/TStatusFormAttributes";
import Dropdown from "../../../../../../common/modules/materialUI/components/Dropdown/Dropdown";
import { useEffect } from "react";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { TTaskEvent } from "../types/TTaskEvent";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { ITaskPatchResource } from "../../interfaces/ITaskPatchResource";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { useUpdateTaskSegmentStatusService } from "../../../../services/useUpdateTaskSegmentStatusService";
import { useOrgStatusFlowData } from "../../../../../org/hooks/useOrgStatusFlowData";
import { ArrowDropDownRounded } from "@mui/icons-material";

const StatusForm = ({ task }: TStatusFormProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const form = useForm<TStatusFormAttributes>({
    statusId: task.statusId,
  });
  const { dispatch: dispatchUpdateTaskStatusService } =
    useUpdateTaskSegmentStatusService(task.segmentId);
  const { statusList } = useOrgStatusFlowData(task.projectRef);

  useEffect(() => {
    form.set({ statusId: task.statusId });
  }, [task.statusId]);

  const submitHandler = (event: any, value: any) => {
    event.preventDefault();

    dispatchUpdateTaskStatusService({
      body: form.getTransformed<TStatusFormAttributes>((_) => ({
        statusId: value?.props?.value,
      })),
    })
      .then((payload: ITaskPatchResource) => {
        const taskEvent = {
          initTask: task,
          task: {
            ...task,
            status: value?.props?.value,
            updatedAt: payload.task.updatedAt,
          },
        } as TTaskEvent;
        dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED, taskEvent);
        dispatchEvent(EventEnum.ON_TASK_STATUS_UPDATED, taskEvent);
        form.notDirty();
      })
      .catch((error: THttpClientError) => {
        error.status !== 422 &&
          dispatch(
            systemNotificationActions.open({
              message:
                "There was a problem with updating the status. Please try again.",
              variant: "error",
            })
          );

        form.errorHandler(error);
      });
  };

  return (
    <Dropdown
      form={form}
      withoutBorder
      onChange={submitHandler}
      name="statusId"
      options={statusList}
      IconComponent={(props) => (
        <ArrowDropDownRounded
          {...props}
          fontSize="small"
          sx={{
            color: "#4C5363 !important",
            right: "0 !important",
          }}
        />
      )}
      sx={{
        bgcolor: "#E6F5F6",
        border: "1px solid #BCE5E7",
        borderRadius: "4px",
        height: "auto",
        p: "6px 4px",
        "& .MuiSelect-select": {
          color: "#4C5363",
          fontSize: "15px",
          fontWeight: 500,
          lineHeight: 1,
          minHeight: "0 !important",
          p: "0 !important",
          pr: "24px !important",
          "::first-letter": {
            textTransform: "uppercase",
          },
        },
      }}
    />
  );
};

export default StatusForm;
