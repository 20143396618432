import { TUseScriptProps } from "./useScript/types/TUseScriptProps";
import { useScript } from "./useScript/useScript";
import { useEffect } from "react";
import { useDispatchEvent } from "../modules/eventProvider";
import { EventEnum } from "../modules/eventProvider/enums/EventEnum";

const DATA_ATTRIBUTES: TUseScriptProps["dataAttributes"] = [
  { key: "rewardful", value: "dd86c1" },
];

declare global {
  interface Window {
    rewardful: any;
    Rewardful: {
      referral: string | null;
    };
  }
}

export const useRewardful = () => {
  const dispatchEvent = useDispatchEvent();

  useScript({
    innerHTML:
      "(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');",
    placement: "head",
  });

  useScript({
    async: true,
    url: "https://r.wdfl.co/rw.js",
    dataAttributes: DATA_ATTRIBUTES,
    placement: "head",
    onLoad: () => {
      // @ts-ignore
      if (Rewardful.referral) {
        // @ts-ignore
        sessionStorage.setItem("referralCode", Rewardful.referral);
      }
    },
  });

  useEffect(() => {
    if (window.rewardful) {
      window.rewardful("ready", () => {
        if (window.Rewardful.referral) {
          sessionStorage.setItem("referralCode", window.Rewardful.referral);
        }
        dispatchEvent(EventEnum.ON_REWARDFUL_READY);
      });
    } else {
      console.error("Rewardful is not available");
    }
  }, []);
};
