import classes from "./Header.module.scss";
import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";
import useMedia from "../../../../hooks/useMedia/useMedia";
import { array } from "../../../../utils/array";
import { QueryEnum } from "../../../../hooks/useMedia/enums/QueryEnum";
import { useAppSelector } from "../../../../hooks/redux";
import { HeaderRegistry } from "../../../../modules/registry/HeaderRegistry";

// Also defined in CSS file src/common/styles/variables/layout.scss
export const HEADER_HEIGHT = 56;

const Header = () => {
  const matchesMax992Screen = useMedia(QueryEnum.MAX_WIDTH_992);
  const controls = useAppSelector((state) => state.header.controls);

  return (
    <nav
      className={array.toClassName([
        classes.header,
        matchesMax992Screen ? classes["header-mobile"] : "",
      ])}
    >
      {!matchesMax992Screen ? (
        <Desktop
          controls={
            controls && HeaderRegistry(controls.registry, controls.payload)
          }
        />
      ) : (
        <Mobile
          controls={
            controls && HeaderRegistry(controls.registry, controls.payload)
          }
        />
      )}
    </nav>
  );
};

export default Header;
