import CheckoutStep from "../../../../../../org/containers/OrgRegistration/Steps/CheckoutStep";
import MsOrgSignUpStep from "./MsOrgSignUpStep";
import { TStepsProps } from "./types/TStepsProps";

const Steps = (props: TStepsProps) => {
  return [
    MsOrgSignUpStep(props.orgRegistrationProps),
    CheckoutStep(props.checkoutProps),
  ];
};

export default Steps;
