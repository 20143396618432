import GlobalStyle from "../../../../modules/theme/containers/GlobalStyle/GlobalStyle";
import SystemNotification from "../../../../modules/systemNotification/containers/SystemNotification";
import LayoutWrapper from "./LayoutWrapper";
import { TChildren } from "../../../../types/TChildren";

const LayoutNoRedirect = (props: TChildren) => {
  return (
    <>
      <GlobalStyle />
      <SystemNotification />
      <LayoutWrapper children={props.children} />
    </>
  );
};

export default LayoutNoRedirect;
