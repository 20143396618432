import { useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import { Button, ConfirmationButton } from "../../../common/modules/materialUI";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { TExceptionsProps } from "./types/TExceptionsProps";
import { IExceptionTypeEnum } from "../../../entities/columns/nonWorkday/IExceptionTypeEnum";
import { useAppDispatch } from "../../../common/hooks/redux";
import { useOrgProjectData } from "../../org/hooks/useOrgProjectData";
import { useDeleteExceptionService } from "../services/useDeleteExceptionService";
import { systemNotificationActions } from "../../../common/modules/systemNotification/slices/systemNotificationSlice";
import Menu from "../../../common/containers/Menu/Menu";
import { menuItem } from "../../../common/containers/Menu/utils/menuItem";
import ExceptionModal from "./ExceptionModal";
import { INonWorkday } from "../../../entities/INonWorkday";
import { THttpClientError } from "../../../common/modules/httpClient/types/THttpClientError";

const Exceptions = ({
  disableButton,
  exceptions,
  setExceptions,
}: TExceptionsProps) => {
  const [openExceptionModal, setOpenExceptionModal] = useState<boolean>(false);
  const [exceptionType, setExceptionType] = useState<IExceptionTypeEnum>();

  const dispatch = useAppDispatch();

  const { projectAbbr } = useParams();
  const project = useOrgProjectData().findProjectByAbbr(projectAbbr!)?.project;

  const { dispatch: dispatchDeleteExceptionService } =
    useDeleteExceptionService();

  const deleteExceptionHandler = (deleteException: INonWorkday) => {
    dispatchDeleteExceptionService({
      urlPath: `/${deleteException.id}`,
    })
      .then(() => {
        setExceptions((prevExceptions) =>
          prevExceptions?.filter(
            (exception) => exception.id !== deleteException.id
          )
        );
        dispatch(
          systemNotificationActions.open({
            message: "Your exception has been deleted successfully.",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        error.status === 403
          ? dispatch(
              systemNotificationActions.open({
                message: error.data.message,
                variant: "warning",
              })
            )
          : dispatch(
              systemNotificationActions.open({
                message: "Unable to delete exception, please try again.",
                variant: "error",
              })
            );
      });
  };

  return (
    <>
      <Box>
        <Menu
          stretchOnMobile={false}
          closeOnSelection={true}
          button={(clickHandler) => (
            <Button
              startIcon={<Add />}
              onClick={clickHandler}
              disabled={disableButton}
            >
              Add Exception
            </Button>
          )}
          menuItems={[
            menuItem.make(<>Non working day</>, () => {
              setOpenExceptionModal(true);
              setExceptionType(IExceptionTypeEnum.OFF);
            }),
            menuItem.make(<> Working day</>, () => {
              setOpenExceptionModal(true);
              setExceptionType(IExceptionTypeEnum.ON);
            }),
          ]}
        />
      </Box>

      <ExceptionModal
        exceptionType={exceptionType}
        open={openExceptionModal}
        projectId={project?.id}
        setExceptions={setExceptions}
        setOpenExceptionModal={setOpenExceptionModal}
      />

      <Grid container>
        <Grid item xs={12} md={10} lg={6} xl={4}>
          <List dense={true}>
            {exceptions?.map((exception) => (
              <ListItem
                key={exception.id}
                secondaryAction={
                  <ConfirmationButton
                    title="Delete Exception"
                    content="Are you sure you want to delete exception?"
                    button={
                      <IconButton edge="end" aria-label="delete">
                        <Delete color="error" />
                      </IconButton>
                    }
                    data={exception}
                    successHandler={deleteExceptionHandler}
                  />
                }
              >
                <ListItemText
                  primary={moment(exception.exceptionData.date).format(
                    "ddd, MMM D, YYYY"
                  )}
                  secondary={`Exceptionally ${exception.exceptionData.type}`}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default Exceptions;
