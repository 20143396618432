import { useHttpClientDispatchable } from "../../../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../../../common/modules/httpClient/enums/RequestMethodEnum";
import { ITask } from "../../../../../entities/ITask";

export const useDeleteSegmentService = (props: { taskSegmentId: number }) => {
  const response = useHttpClientDispatchable({
    url: `/task/segment/${props.taskSegmentId}/delete`,
    method: RequestMethodEnum.DELETE,
  });

  return {
    ...response,
    data: response.data as ITask,
  };
};
