import { useAppDispatch } from "../../../../common/hooks/redux";
import { boardActions } from "../../slices/boardSlice";
import { TCreateBoardProps } from "./types/TCreateBoardProps";
import { Add } from "@mui/icons-material";

const CreateBoard = (props: TCreateBoardProps) => {
  const dispatch = useAppDispatch();

  const clickHandler = () => {
    props.onClick && props.onClick();
    dispatch(
      boardActions.openCreationModal({
        projectRef: props.projectRef,
        projectName: props.projectName,
      })
    );
  };

  return (
    <span className={props.className} onClick={clickHandler}>
      <Add fontSize="small" />
    </span>
  );
};

export default CreateBoard;
