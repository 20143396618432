import { ConfirmationDialog } from "../../../../../../common/modules/materialUI";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { taskViewActions } from "../../slices/viewSlice";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import { useConvertTaskInternalTypeService } from "../../services/useConvertTaskInternalTypeService";
import { taskActions } from "../../../../slices/taskSlice";

const ConvertInternalTypeConfirmation = () => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { getValidationErrorAtIndex } = useForm();
  const convertToInternalType = useAppSelector(
    (state) => state.taskView.convertToInternalType
  );
  const taskResource = useAppSelector((state) => state.task.resource);
  const { dispatch: dispatchConvertInternalTypeService, isLoading } =
    useConvertTaskInternalTypeService({
      taskId: convertToInternalType?.taskId ?? 0,
    });
  const closeConfirmationHandler = () => {
    dispatch(taskViewActions.closeConvertInternalTypeConfirmation());
  };

  const successHandler = () => {
    if (!convertToInternalType) {
      return;
    }

    dispatchConvertInternalTypeService({
      body: {
        targetInternalType: convertToInternalType.targetInternalType,
      },
    })
      .then(() => {
        dispatch(
          systemNotificationActions.open({
            variant: "success",
            message: `Converted to a ${convertToInternalType.targetInternalType} successfully`,
          })
        );

        // If the task in a view mode (page, dialog), then update internal type of it
        !!taskResource?.task &&
          dispatch(
            taskActions.mergeTaskProps({
              internalType: convertToInternalType.targetInternalType,
            })
          );

        // Updating boards to reflect the changes
        dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED);
      })
      .catch((error: THttpClientError) => {
        if (error.status === 422) {
          const validationFirstError = getValidationErrorAtIndex(0, error);
          if (validationFirstError) {
            dispatch(
              systemNotificationActions.open({
                variant: "error",
                message: validationFirstError,
              })
            );
          }
        } else {
          dispatch(
            systemNotificationActions.open({
              variant: "error",
              message: `Failed to convert ${convertToInternalType.taskInternalType} to a ${convertToInternalType.targetInternalType}. Please try again.`,
            })
          );
        }
      })
      .finally(() => {
        closeConfirmationHandler();
      });
  };

  if (!convertToInternalType?.openConfirmation) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={true}
      title={`Convert to a ${convertToInternalType.targetInternalType}`}
      content={`The conversion will effect ${convertToInternalType.taskInternalType} and all the splits. Are you sure you want to convert this ${convertToInternalType.taskInternalType} to ${convertToInternalType.targetInternalType}?`}
      loading={isLoading}
      closeHandler={() => {
        closeConfirmationHandler();
      }}
      successHandler={successHandler}
    />
  );
};

export default ConvertInternalTypeConfirmation;
