import { TSectionProps } from "./types/TSectionProps";
import {
  Card,
  CardContent,
  Grid,
  ListItem,
  ListItemText,
  useTheme,
} from "@mui/material";
import useMedia from "../../../../../../common/hooks/useMedia/useMedia";
import { QueryEnum } from "../../../../../../common/hooks/useMedia/enums/QueryEnum";
import classes from "./Section.module.css";
import Task from "../Task/Task";
import DropTargetContainer from "../DropTarget/DropTargetContainer";

const Section = (props: TSectionProps) => {
  const theme = useTheme();
  const matchesMax576Screen = useMedia(QueryEnum.MAX_WIDTH_576);

  return (
    <Card
      style={{
        backgroundColor: theme.palette.cardsBackground.default,
        position: "relative",
      }}
      className={classes.container}
    >
      <DropTargetContainer {...props} />
      <CardContent
        className={classes.content}
        style={{
          ...(matchesMax576Screen
            ? {
                width: "calc(100vw - 20px)",
                height: "calc(100vh - 270px)",
                overflowY: "auto",
              }
            : {}),
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: theme.palette.cardsBackground.paper,
            }}
            className={classes.header}
          >
            <ListItem disableGutters>
              <ListItemText
                primary={`${props.status.name} ${props.tasks.length}`}
              />
            </ListItem>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              padding: "3px 4px 0 4px",
            }}
          >
            {props?.tasks.map((task, index) => (
              <Task
                statusColumn={props.status}
                style={{ margin: "4px 0" }}
                key={`task-kv-${task.id}-${task.status}-${task.segmentId}`}
                task={task}
                users={props.users}
                isSegmentView={props.showSegments}
              />
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Section;
