import { TTaskCardProps } from "./types/TTaskCardProps";
import {
  Box,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import StateIcon from "../State/StateIcon";
import classes from "./TaskCard.module.css";
import { useTaskViewHandler } from "../../../../../modules/task/modules/view/hooks/useTaskViewHandler";
import { NavLink } from "react-router-dom";
import { taskUtil } from "../../../../utils/taskUtil";
import TaskMenu from "../Menu/TaskMenu";
import useGetTaskType from "../../../../../modules/taskType/hooks/useGetTaskType";
import { Tooltip } from "../../../../modules/materialUI";
import React, { ForwardedRef, forwardRef } from "react";
import useGetTaskPriority from "../../../../../modules/taskPriority/hooks/useGetTaskPriority";
import { link } from "../../../../utils/link";
import AvatarGroup from "../../../../../modules/user/containers/AvatarGroup/AvatarGroup";
import useSprintHelper from "../../../../../modules/boardView/hooks/sprintHelper/useSprintHelper";
import { BoardViewEnum } from "../../../../../modules/boardView/enums/BoardViewEnum";
import { InternalTypeEnum } from "../../../../../entities/columns/task/InternalTypeEnum";

const TaskCard = forwardRef(
  (
    { task, style, isSegmentView, users }: TTaskCardProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const theme = useTheme();
    const { openTaskViewHandler } = useTaskViewHandler({
      taskRef: task.ref,
      segmentNumber: task.number,
    });
    const sprintHelper = useSprintHelper({ view: BoardViewEnum.KANBAN_VIEW });
    const riskStatus = isSegmentView ? task.riskStatus : task.aggRiskStatus;
    const taskUrl = taskUtil.urlByRef(
      task.ref,
      isSegmentView ? task.number : undefined
    );
    const taskType = useGetTaskType({
      taskTypeId: task.taskTypeId,
    });
    const taskPriority = useGetTaskPriority({
      taskPriorityId: task.taskPriorityId,
    });
    const taskUsers =
      users?.filter((user) =>
        isSegmentView
          ? user.id === task.assignedTo
          : task.assignees.includes(user.id)
      ) ?? [];
    const showLeftBorder = !isSegmentView && task.segmentCountInGroup > 1;

    return (
      <div
        ref={ref}
        className={classes.container}
        style={{
          backgroundColor: theme.palette.brightCardBackground.default,
          boxShadow: theme.palette.brightCardShadowColor.secondary,
          borderLeft: showLeftBorder
            ? theme.palette.cardSplitBorder.main
            : undefined,
          borderWidth: showLeftBorder ? "3px" : undefined,
          ...style,
        }}
      >
        <div
          onClick={openTaskViewHandler}
          className={classes.content}
          style={{
            backgroundColor: theme.palette.brightCardBackground.paper,
            color: theme.palette.brightCardBackground.main,
          }}
        >
          <CardActions
            disableSpacing
            sx={{ p: 0.5, justifyContent: "space-between", display: "flex" }}
          >
            <NavLink
              to={taskUrl}
              onClick={link.anchorClickHandler}
              style={{ color: theme.palette.brightCardBackground.main }}
            >
              <Typography sx={{ pl: "4px" }} fontWeight="bold" variant="button">
                {task.internalType === InternalTypeEnum.SPIKE && (
                  <>
                    <Tooltip title={InternalTypeEnum.SPIKE}>
                      <img
                        src={theme.palette.spike.xsImg}
                        alt=""
                        style={{
                          width: "11px",
                          height: "11px",
                          backgroundColor: theme.palette.spike.backgroundColor,
                          padding: "1px",
                          borderRadius: "2px",
                        }}
                      />
                    </Tooltip>{" "}
                  </>
                )}
                {task.ref}
                {isSegmentView && task.segmentCount > 1
                  ? ` #${task.number}`
                  : ""}
              </Typography>
            </NavLink>

            <Typography variant="caption">
              {!isSegmentView && task.segmentCount > 1
                ? `${task.segmentCountInGroup} / ${task.segmentCount}`
                : ""}
            </Typography>
          </CardActions>
          <CardContent sx={{ p: 0.8 }}>
            <Typography variant="body2">{task.name}</Typography>
          </CardContent>
          <CardActions disableSpacing sx={{ p: 0.5 }}>
            <StateIcon
              className={classes["state-icon"]}
              riskStatus={riskStatus}
              statusType={task.statusType}
              status={task.status}
            />

            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              gap={0.7}
            >
              {sprintHelper.label(!!isSegmentView, task)}

              {taskPriority && (
                <Grid item>
                  <Box>
                    <Tooltip title={taskPriority.name}>
                      <img
                        src={`${process.env.CDN_URL}/${taskPriority.iconFilePath}`}
                        alt={taskPriority.name}
                        width={18}
                        height={18}
                        style={{ display: "flex" }}
                      />
                    </Tooltip>
                  </Box>
                </Grid>
              )}

              {taskType && (
                <Grid item>
                  <Box>
                    <Tooltip title={taskType.name}>
                      <img
                        src={`${process.env.CDN_URL}/${taskType.iconFilePath}`}
                        alt={taskType.name}
                        width={18}
                        height={18}
                        style={{ display: "flex" }}
                      />
                    </Tooltip>
                  </Box>
                </Grid>
              )}

              {taskUsers.length > 0 && (
                <Grid item>
                  <AvatarGroup users={taskUsers} />
                </Grid>
              )}
              <Grid item>
                <TaskMenu
                  taskRef={task.ref!}
                  taskId={task.id}
                  taskInternalType={task.internalType}
                  projectRef={task.projectRef}
                />
              </Grid>
            </Grid>
          </CardActions>
        </div>
      </div>
    );
  }
);

export default TaskCard;
