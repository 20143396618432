import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAttachmentState } from "./types/TAttachmentState";
import { IAttachment } from "../../../entities/IAttachment";

const initialState: TAttachmentState = {
  list: [],
  preview: {
    attachmentId: undefined,
    previewType: undefined,
  },
  uploadInProgress: false,
  googleDriveIntegrationEnabled: false,
};

const slice = createSlice({
  name: "attachment",
  initialState,
  reducers: {
    openPreviewModal: (
      state,
      action: PayloadAction<TAttachmentState["preview"]>
    ) => {
      state.preview = action.payload;
    },
    closePreviewModal: (state) => {
      state.preview.attachmentId = undefined;
    },
    setList: (state, action: PayloadAction<IAttachment[]>) => {
      state.list = action.payload;
    },
    addToList: (state, action: PayloadAction<IAttachment>) => {
      state.list = [...state.list, action.payload];
    },
    removeFromList: (state, action: PayloadAction<{ id: string | number }>) => {
      state.list = state.list.filter((item) => item.id !== action.payload.id);
    },
    trackUploadProgress: (state) => {
      state.uploadInProgress = state.list.some((item) => item.isUploading);
    },
    updateAttachment: (state, action: PayloadAction<IAttachment>) => {
      state.list = state.list.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    setGoogleDriveIntegrationEnabled: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.googleDriveIntegrationEnabled = action.payload;
    },
  },
});

export const attachmentReducer = slice.reducer;

export const attachmentActions = slice.actions;
