import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useEffect } from "react";
import { landingPageActions } from "../slices/landingPageSlice";

export default function useLandingPageHelper() {
  const totalSteps = 3;
  const dispatch = useAppDispatch();
  const org = useAppSelector((state) => state.org.init);
  // Has projects, has tasks, has members will make 100% of progress
  const hasProjects = org.list.length > 0;
  const hasTasks = org.hasTasks;
  const hasMembers = org.hasMembers;
  // Calculate progress of completion
  const completedCount =
    (hasProjects ? 1 : 0) + (hasTasks ? 1 : 0) + (hasMembers ? 1 : 0);
  const progress = Math.round(
    completedCount > 0 ? (completedCount / totalSteps) * 100 : 0
  );
  const completionState = {
    0: hasProjects,
    1: !!hasTasks,
    2: !!hasMembers,
  };

  // Move to the next available step when any completion detected
  useEffect(() => {
    dispatch(
      landingPageActions.setActiveStep(
        Object.values(completionState).indexOf(false)
      )
    );
  }, [completionState]);

  return {
    // We will consider service loading only the first time, other events
    // will be handled by the landing page itself
    isLoading: !org.org ? org.isLoading : false,
    hasProjects,
    hasTasks,
    hasMembers,
    progress,
    needsQuickStart: !hasProjects || !hasTasks || !hasMembers,
    completionState,
    allStepsCompleted: completedCount === totalSteps,
    isOneStepRemaining: completedCount === totalSteps - 1,
  };
}
