import { TUserPickerProps } from "./types/TUserPickerProps";
import ExpandableIcon from "../../../../common/components/Icon/Expandable/ExpandableIcon";
import React, { useState } from "react";
import { Autocomplete } from "../../../../common/modules/materialUI";
import { useUserListService } from "../../../user/services/useUserListService";
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";

const UserPicker = (props: TUserPickerProps) => {
  const [showUserPicker, setShowUserPicker] = useState<boolean>(false);
  const [manageFor, setManageFor] = useState<string | undefined>(undefined);
  const userListService = useUserListService({ roleLimited: true });

  return (
    <Box pb={3}>
      <Box
        style={{ cursor: "pointer", fontSize: "0.9rem" }}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        gap={1}
      >
        <Box
          display="flex"
          alignItems="center"
          onClick={() => setShowUserPicker((prevState) => !prevState)}
        >
          <span>
            Manage offtime for {manageFor ? <b>{manageFor}</b> : "others"}
          </span>
          <ExpandableIcon direction={!showUserPicker ? "down" : "up"} />
        </Box>
        {manageFor && (
          <Close
            style={{ fontSize: "1.1rem" }}
            onClick={() => {
              setManageFor(undefined);
              setShowUserPicker(false);
              props.onChange(undefined);
            }}
          />
        )}
      </Box>
      {showUserPicker && (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Box width="250px">
            <Autocomplete
              name="userId"
              label="For user"
              service={userListService}
              onChange={(event, value) => {
                setManageFor(value.label);
                setShowUserPicker(false);
                props.onChange(value.id);
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserPicker;
