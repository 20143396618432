import { Box, Typography } from "@mui/material";
import { menuItem } from "../../../../../../common/containers/Menu/utils/menuItem";
import { useAppDispatch } from "../../../../../../common/hooks/redux";
import { taskViewActions } from "../../slices/viewSlice";
import { AddTask, MultilineChart } from "@mui/icons-material";
import { InternalTypeEnum } from "../../../../../../entities/columns/task/InternalTypeEnum";
import { TTaskIdProp } from "../../../../../../common/modules/taskView/containers/types/TTaskIdProp";
import { useIsFeatureAllowed } from "../../../../../../common/modules/accessControl/hooks/useIsFeatureAllowed";
import { FeatureEnum } from "../../../../../../common/modules/accessControl/enums/FeatureEnum";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { featureUtil } from "../../../../../../common/modules/accessControl/utils/featureUtil";

type TProps = {
  taskInternalType: InternalTypeEnum;
  shouldRender: boolean;
} & TTaskIdProp;

const ConvertInternalTypeMenuItem = ({
  taskId,
  taskInternalType,
  shouldRender,
}: TProps) => {
  const dispatch = useAppDispatch();
  const isSpikeCreationAllowed = useIsFeatureAllowed(FeatureEnum.SPIKE);
  const targetInternalType =
    taskInternalType === InternalTypeEnum.TASK
      ? InternalTypeEnum.SPIKE
      : InternalTypeEnum.TASK;

  if (!shouldRender) {
    return [];
  }

  return menuItem.make(
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      onClick={() =>
        targetInternalType === InternalTypeEnum.SPIKE && isSpikeCreationAllowed
          ? dispatch(
              taskViewActions.openConvertInternalTypeConfirmation({
                taskId,
                taskInternalType,
                targetInternalType,
              })
            )
          : dispatch(
              systemNotificationActions.open({
                message: featureUtil.notAvailableFullText,
                variant: "warning",
              })
            )
      }
    >
      {targetInternalType === InternalTypeEnum.SPIKE ? (
        <MultilineChart fontWeight="400" />
      ) : (
        <AddTask fontWeight="400" />
      )}
      <Typography variant="subtitle2" fontWeight="400">
        Convert to a {targetInternalType}
      </Typography>
    </Box>
  );
};

export default ConvertInternalTypeMenuItem;
