import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TBoardQueryState } from "./types/TBoardQueryState";
import { IUser } from "../../../entities/IUser";
import { array } from "../../../common/utils/array";
import { EventProvider } from "../../../common/modules/eventProvider";
import { EventEnum } from "../../../common/modules/eventProvider/enums/EventEnum";

const initialState: TBoardQueryState = {
  filters: {
    userIds: [],
  },
  extraFilters: {
    taskTypeIds: [],
    taskPriorityIds: [],
    sprintRefs: [],
    customFields: [],
  },
  sorting: undefined,
};

const slice = createSlice({
  name: "boardQuery",
  initialState,
  reducers: {
    addUserFilter: (state, action: PayloadAction<number>) => {
      if (state.filters.userIds.includes(action.payload)) {
        state.filters.userIds = state.filters.userIds.filter(
          (item) => item !== action.payload
        );
      } else {
        state.filters.userIds = [...state.filters.userIds, action.payload];
      }
    },
    matchUserFilterToUserList: (state, action: PayloadAction<IUser[]>) => {
      state.filters.userIds = array.intersect(
        state.filters.userIds,
        action.payload.map((user) => user.id)
      );
    },
    reset: () => initialState,
    setSorting: (state, action: PayloadAction<TBoardQueryState["sorting"]>) => {
      state.sorting = action.payload;
    },
    setExtraFiltering: (
      state,
      action: PayloadAction<TBoardQueryState["extraFilters"]>
    ) => {
      state.extraFilters = action.payload;
      // dispatch events
      EventProvider.dispatch(
        EventEnum.ON_BOARD_FILTERS_CHANGED,
        action.payload
      );
    },
    clearExtraFiltering: (state) => {
      state.extraFilters = initialState.extraFilters;
      // dispatch events
      EventProvider.dispatch(
        EventEnum.ON_BOARD_FILTERS_CHANGED,
        initialState.extraFilters
      );
    },
  },
});

export const boardQueryReducer = slice.reducer;

export const boardQueryActions = slice.actions;
