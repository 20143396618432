import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { attachmentActions } from "../slices/attachmentSlice";
import { useAppDispatch } from "../../../common/hooks/redux";
import { EntityTypeEnum } from "../../../entities/enums/EntityTypeEnum";
import { IAttachmentListResource } from "../interfaces/IAttachmentListResource";

export const useAttachmentsService = (props: {
  entityType: EntityTypeEnum;
  entityId: number;
}) => {
  const dispatch = useAppDispatch();
  return useHttpClientDispatchable({
    url: `/attachment/${props.entityType}/${props.entityId}/list`,
    method: RequestMethodEnum.GET,
    events: {
      beforeTransform: (data: IAttachmentListResource) => {
        dispatch(attachmentActions.setList(data.attachments));
        dispatch(
          attachmentActions.setGoogleDriveIntegrationEnabled(
            data.googleDriveIntegrationEnabled
          )
        );
      },
    },
  });
};
