import { DialogContent, DialogTitle } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { guesstimationItemReportActions } from "../slices/guesstimationItemReportSlice";
import ReportGrid from "./Grid/ReportGrid";
import { Dialog } from "../../../../../common/modules/materialUI";

type TProps = {
  boardRef: string;
};

const ReportModal = (props: TProps) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.guesstimationItemReport.displayModal
  );

  const closeHandler = () => {
    dispatch(guesstimationItemReportActions.closeReportModal());
  };

  return (
    <Dialog
      closeButtonHandler={closeHandler}
      renderCloseButton
      open={isOpen}
      onClose={closeHandler}
      fullWidth
      maxWidth="lg"
    >
      {isOpen && (
        <>
          <DialogTitle>Guesstimation Report</DialogTitle>
          <DialogContent>
            <ReportGrid boardRef={props.boardRef} />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default ReportModal;
