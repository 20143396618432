import Button from "../../../../common/modules/materialUI/components/Button/Button";
import { Delete } from "@mui/icons-material";
import { useArchiveBoardService } from "../../services/useArchiveBoardService";
import { useNavigate, useParams } from "react-router-dom";
import { TBoardParams } from "../Board/types/TBoardParams";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { orgActions } from "../../../org/slices/orgSlice";
import { ConfirmationButton } from "../../../../common/modules/materialUI";
import { useTheme } from "@mui/material";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import useForm from "../../../../common/hooks/useForm/useForm";

const ArchiveBoard = () => {
  const dispatch = useAppDispatch();
  const { getValidationErrorAtIndex } = useForm();
  const navigate = useNavigate();
  const { projectAbbr, boardRef } = useParams<TBoardParams>();
  const { isLoading, dispatch: dispatchArchiveBoardService } =
    useArchiveBoardService(boardRef!);
  const theme = useTheme();

  const archiveBoardHandler = () => {
    dispatchArchiveBoardService()
      .then(() => {
        navigate(`/`);
        setTimeout(
          () =>
            dispatch(
              orgActions.removeBoard({
                ref: boardRef!,
                projectAbbr: projectAbbr!,
              })
            ),
          1000
        );
        dispatch(
          systemNotificationActions.open({
            message:
              "Your archive request has been received successfully. It may take some time for all board-related components to be archived across all locations.",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        if (error.status === 422) {
          const validationFirstError = getValidationErrorAtIndex(0, error);

          validationFirstError &&
            dispatch(
              systemNotificationActions.open({
                message: validationFirstError,
                variant: "warning",
              })
            );
        } else {
          dispatch(
            systemNotificationActions.open({
              message: "Failed to archive board. Please try again.",
              variant: "error",
            })
          );
        }
      });
  };

  return (
    <ConfirmationButton
      title="Archive Board"
      content="Are you sure you want to archive board? You won't be able to unarchived it manually."
      button={
        <Button
          variant="text"
          startIcon={<Delete />}
          sx={{ padding: "3px 15px", color: theme.palette.error.dark }}
          loading={isLoading}
          disabled={isLoading}
        >
          Archive Board
        </Button>
      }
      successHandler={archiveBoardHandler}
    />
  );
};

export default ArchiveBoard;
