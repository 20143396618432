import * as React from "react";
import { CSSProperties } from "react";
import { TTaskCardProps } from "../../../../../../common/components/Task/components/TaskCard/types/TTaskCardProps";
import TaskCard from "../../../../../../common/components/Task/components/TaskCard/TaskCard";
import { useDragAndDropHelper } from "../../hooks/useDragAndDropHelper";

export const DRAG_TYPE_TASK_CARD = "TaskCard";

function getStyles(shouldDisplay: boolean): CSSProperties {
  return {
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: shouldDisplay ? 1 : 0,
    height: shouldDisplay ? undefined : 0,
  };
}

const Task = (props: TTaskCardProps) => {
  const { drag, isDragging } = useDragAndDropHelper(props);

  return (
    <TaskCard
      ref={drag}
      style={{
        ...props.style,
        ...getStyles(!isDragging),
      }}
      {...props}
    />
  );
};

export default Task;
