import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import { THolisticViewData } from "../slices/types/THolisticViewData";
import { holisticViewActions } from "../slices/holisticViewSlice";
import { TBoardDefaultProps } from "../../../../board/types/TBoardDefaultProps";
import { IHolisticViewDataResource } from "../../../interfaces/IHolisticViewDataResource";
import { useFetchHolisticViewDataService } from "../services/useFetchHolisticViewDataService";
import { useEffect, useState } from "react";
import { TUserLaneData } from "../slices/types/TUserLaneData";
import { ITask } from "../../../../../entities/ITask";
import { LoadDirectionEnum } from "../../../enums/LoadDirectionEnum";
import { userActions } from "../../../../user/slices/userSlice";
import { boardQueryActions } from "../../../slices/boardQuerySlice";
import { IBoardUsersResource } from "../../../interfaces/IBoardUsersResource";
import { IUserOfftime } from "../../../../../entities/IUserOfftime";
import { user } from "../../../../../common/utils/user";
import { systemNotificationActions } from "../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { THttpClientError } from "../../../../../common/modules/httpClient/types/THttpClientError";
import { windowHelper } from "../../../../../common/utils/windowHelper";
import { IOccupiedSpaceResource } from "../../../interfaces/IOccupiedSpaceResource";
import { TBoardQueryState } from "../../../slices/types/TBoardQueryState";

const useInitData = (
  props: TBoardDefaultProps,
  autoDispatch: boolean = true
) => {
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const { extraFilters, filters } = useAppSelector((state) => state.boardQuery);
  const virtualizationParams = useAppSelector(
    (state) => state.holisticView.virtualizationParams
  );

  const {
    isLoading,
    data,
    dispatch: dispatchFetchData,
  } = useFetchHolisticViewDataService({
    ref: props.boardRef,
    query: {
      projectAbbr: props.projectAbbr,
      monitors: 1,
      sprints: 1,
      tasks: 1,
      ...virtualizationParams,
    },
  });
  const dispatch = useAppDispatch();

  const dispatchService = (
    loadDirection: LoadDirectionEnum,
    loadFromDate: string,
    successCallback?: (data: IHolisticViewDataResource) => void,
    errorCallback?: (error: THttpClientError) => void,
    filters?: TBoardQueryState["extraFilters"]
  ) => {
    const { taskTypeIds, taskPriorityIds, sprintRefs, customFields } =
      filters ?? extraFilters;

    dispatchFetchData({
      query: {
        loadDirection,
        loadFromDate,
        weeksToLoad: windowHelper.widthToWeeks(),
        filters: {
          ...(taskTypeIds.length > 0 && {
            taskTypeIds: taskTypeIds.map((item) => item.id),
          }),
          ...(taskPriorityIds.length > 0 && {
            taskPriorityIds: taskPriorityIds.map((item) => item.id),
          }),
          ...(sprintRefs.length > 0 && {
            sprintRefs: sprintRefs.map((item) => item.id),
          }),
          ...(customFields.length > 0 && {
            customFields: customFields.map((item) => ({
              id: item.extraId,
              label: item.value,
            })),
          }),
        },
      },
    })
      .then((data: IHolisticViewDataResource) => {
        const { tasks, boardUsers, offtimes, occupiedSpaces, ...restData } =
          data;
        const transformed: THolisticViewData = {
          ...restData,
          userLaneData: [],
        };

        transformed.userLaneData = prepareUserLaneData(
          tasks,
          offtimes,
          boardUsers,
          occupiedSpaces
        );

        // Setting board users info as it was done in the independent service useBoardUsersService
        // since the holistic view fetches that information within its own service
        dispatch(userActions.setList(data.boardUsers));
        dispatch(
          boardQueryActions.matchUserFilterToUserList(data.boardUsers?.users)
        );

        dispatch(
          holisticViewActions.setData({
            data: transformed as THolisticViewData,
            loadDirection,
          })
        );

        props.board &&
          dispatch(
            holisticViewActions.setWorkingHours(props.board.workingHours)
          );

        isFirstTimeLoading && setIsFirstTimeLoading(false);
        dispatch(
          holisticViewActions.setHavingBoardUsers(
            boardUsers.users.length > 0 || boardUsers.hasUnassigned
          )
        );

        successCallback?.(data);
      })
      .catch((error: THttpClientError) => {
        dispatch(
          systemNotificationActions.open({
            message:
              "Unable to update the user lane data. Please refresh the page.",
            variant: "error",
          })
        );

        errorCallback?.(error);
      });
  };

  // Fetch the data for the first time ONLY
  // We need to re-fetch with this only if the boardRef changes
  useEffect(() => {
    autoDispatch &&
      dispatchService(
        virtualizationParams.loadDirection,
        virtualizationParams.loadFromDate
      );
  }, [props.boardRef, JSON.stringify(virtualizationParams)]);

  return {
    isLoading: isLoading || !data,
    isFirstTimeLoading,
    dispatchService,
  };
};

export const prepareUserLaneData = (
  tasks: ITask[],
  offtimes: IUserOfftime[],
  boardUsers: IBoardUsersResource,
  occupiedSpaces: IOccupiedSpaceResource[]
) => {
  const userLaneData: TUserLaneData[] = [];

  const buildKey = (key: number) => {
    if (!(key in userLaneData)) {
      userLaneData[key] = {
        tasks: [],
        user:
          key > 0
            ? boardUsers.users.find((user) => user.id === key)!
            : user.unassignedEntity,
        offtimes: [],
        keySuffix: `${Math.random()}`,
        occupiedSpaces: [],
      };
    }

    return key;
  };

  // Group the tasks by user
  for (const task of tasks) {
    const key = task.assignedTo ?? 0;
    userLaneData[buildKey(key)].tasks.push(task);
  }

  // Group the offtimes by user
  for (const offtime of offtimes) {
    const key = offtime.userId ?? 0;
    userLaneData[buildKey(key)].offtimes.push(offtime);
  }

  // Group the occupied spaces by user
  for (const occupiedSpace of occupiedSpaces) {
    const key = occupiedSpace.userId ?? 0;
    userLaneData[buildKey(key)].occupiedSpaces.push(occupiedSpace);
  }

  return sort(userLaneData);
};

export const mergeUserLaneData = (
  oldData: THolisticViewData | undefined,
  newData: THolisticViewData | undefined,
  loadDirectionEnum: LoadDirectionEnum
) => {
  if (!newData) {
    return oldData;
  }

  const isLoadNext = loadDirectionEnum === LoadDirectionEnum.NEXT;
  const isLoadPrev = loadDirectionEnum === LoadDirectionEnum.PREV;
  const isInit = loadDirectionEnum === LoadDirectionEnum.INIT;

  if (isInit || !oldData) {
    return newData;
  }

  const data: THolisticViewData = {
    ...oldData,
    ...newData,
    maxDate: isLoadNext ? newData.maxDate : oldData.maxDate,
    minDate: isLoadPrev ? newData.minDate : oldData.minDate,
  };
  const existingData: TUserLaneData[] = [];

  for (const data of oldData.userLaneData) {
    const key = data.user?.id ?? 0;
    existingData[key] = { ...data };
  }

  for (const data of newData.userLaneData) {
    const key = data.user?.id ?? 0;
    if (!(key in existingData)) {
      existingData[key] = { ...data };
      continue;
    }

    // Make sure the data.tasks does not have duplicates in the existingData[key].tasks
    data.tasks = data.tasks.filter(
      (task) =>
        !existingData[key].tasks.some(
          (existingTask) => existingTask.segmentId === task.segmentId
        )
    );
    // Make sure the data.offtimes does not have duplicates in the existingData[key].offtimes
    data.offtimes = data.offtimes?.filter(
      (offtime) =>
        !existingData[key].offtimes?.some(
          (existingOfftime) => existingOfftime.id === offtime.id
        )
    );
    // Make sure the data.occupiedSpaces does not have duplicates in the existingData[key].occupiedSpaces
    data.occupiedSpaces = data.occupiedSpaces?.filter(
      (occupiedSpace) =>
        !existingData[key].occupiedSpaces?.some(
          (existingOccupiedSpace) =>
            existingOccupiedSpace.key === occupiedSpace.key &&
            existingOccupiedSpace.number === occupiedSpace.number
        )
    );

    if (isLoadPrev) {
      existingData[key].tasks = [...data.tasks, ...existingData[key].tasks];
      existingData[key].offtimes = [
        ...(data.offtimes ?? []),
        ...(existingData[key].offtimes ?? []),
      ];
      existingData[key].occupiedSpaces = [
        ...(data.occupiedSpaces ?? []),
        ...(existingData[key].occupiedSpaces ?? []),
      ];
    } else if (isLoadNext) {
      existingData[key].tasks = [...existingData[key].tasks, ...data.tasks];
      existingData[key].offtimes = [
        ...(existingData[key].offtimes ?? []),
        ...(data.offtimes ?? []),
      ];
      existingData[key].occupiedSpaces = [
        ...(existingData[key].occupiedSpaces ?? []),
        ...(data.occupiedSpaces ?? []),
      ];
    }
  }

  data.userLaneData = sort(existingData);

  return data;
};

const sort = (data: TUserLaneData[]) =>
  // Because userLaneData contains string keys e.g. 768, 1850... when it is converted
  // to array it produces as many values as bigger the key is. In this example we might
  // have only 4 users but the array will have 1850 elements with null values
  // Object.values() will return only the values that are not null
  Object.values(data);

export default useInitData;
