import { Button } from "../../../../../common/modules/materialUI";

const StartOver = () => {
  const navigateHandler = () => {
    window.location.reload();
  };

  return (
    <Button variant="text" size="large" onClick={() => navigateHandler()}>
      Start Over
    </Button>
  );
};

export default StartOver;
