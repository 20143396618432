import { TUseUpdateTaskPriorityServiceProps } from "./types/TUseUpdateTaskPriorityServiceProps";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";

export const useUpdateTaskPriorityService = ({
  taskPriorityId,
}: TUseUpdateTaskPriorityServiceProps) => {
  return useHttpClientDispatchable({
    url: `/taskPriority/${taskPriorityId}`,
    method: RequestMethodEnum.PATCH,
  });
};
