import classes from "./TimeArrow.module.css";
import { TTimeArrowProps } from "./types/TTimeArrowProps";

const TimeArrow = (props: TTimeArrowProps) => {
  return (
    <div
      className={`${classes.container} ${classes[props.timeHighlight]}`}
      style={{
        left: `${props.position}px`,
        height: `calc(${props.height ?? 100}% - 40px)`,
      }}
    />
  );
};

export default TimeArrow;
