import { TMenuItemProps } from "../../../../common/containers/Menu/types/TMenuItemProps";
import Menu from "../../../../common/containers/Menu/Menu";
import {
  Article,
  QuestionMark,
  SupportAgent,
  YouTube,
} from "@mui/icons-material";
import {
  Grid,
  IconButton,
  ListItemIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { menuItem } from "../../../../common/containers/Menu/utils/menuItem";
import { NavLink } from "react-router-dom";
import { useContactSupportHandler } from "../../../helpCenter/hooks/useContactSupportHandler";
import { darkTheme } from "../../../../common/modules/theme/darkTheme";
import useWindow from "../../../../common/hooks/useWindow";

const DOCS_URL = "https://tasksensor.com/docs/" as const;
const YOUTUBE_URL = "https://www.youtube.com/@TaskSensor" as const;

const HelpMenu = () => {
  const { openNewTab } = useWindow();
  const { openSupportChat } = useContactSupportHandler();

  return (
    <Menu
      sx={{ mt: 1 }}
      button={(clickHandler) => {
        return (
          <Tooltip title="Help Center">
            <IconButton onClick={clickHandler}>
              <QuestionMark
                fontSize="small"
                sx={{ color: darkTheme.palette.text.primary }}
              />
            </IconButton>
          </Tooltip>
        );
      }}
      menuItems={
        [
          menuItem.make(
            <Grid container>
              <Grid item xs={12} sx={{ pl: 2 }}>
                <Typography align="center">TaskSensor Help Center</Typography>
                <Typography align="center" fontSize={11}>
                  Please use our training material or simply contact us.
                </Typography>
                <Typography align="center" fontSize={11}>
                  We are happy to help!
                </Typography>
              </Grid>
            </Grid>
          ),
          menuItem.make(
            <NavLink to={DOCS_URL}>
              <ListItemIcon>
                <Article />
              </ListItemIcon>
              <Typography sx={{ pl: 2 }}>Open docs</Typography>
            </NavLink>,
            function () {
              openNewTab(DOCS_URL);
            }
          ),
          menuItem.make(
            <NavLink to={YOUTUBE_URL}>
              <ListItemIcon>
                <YouTube />
              </ListItemIcon>
              <Typography sx={{ pl: 2 }}>Open youtube</Typography>
            </NavLink>,
            function () {
              openNewTab(YOUTUBE_URL);
            }
          ),
          menuItem.make(
            <>
              <ListItemIcon>
                <SupportAgent />
              </ListItemIcon>
              <Typography sx={{ pl: 2 }}>Contact support</Typography>
            </>,
            function () {
              openSupportChat();
            }
          ),
        ] as TMenuItemProps[]
      }
    />
  );
};

export default HelpMenu;
