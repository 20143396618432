export const input = {
  onNumberEntered: (count: number, value?: string, callback?: () => void) => {
    if (!value) {
      return false;
    }

    const phonePattern = new RegExp(`^[0-9]{${count}}$`);

    // If entered value equals to count of needed digits
    // then validate it to be all digits
    if (!(value.length === count && phonePattern.test(value))) {
      return false;
    }

    callback?.();
    return true;
  },
};
