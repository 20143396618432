import { TSprintState } from "./types/TSprintState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISprint } from "../../../entities/ISprint";
import { IUser } from "../../../entities/IUser";
import { ISprintResource } from "../interfaces/ISprintResource";

const initialState: TSprintState = {
  creation: {
    displayModal: false,
  },
  update: {
    showModal: false,
    editableRecord: null,
  },
  delete: {
    showConfirmation: false,
    deletableRecord: null,
  },
  list: [],
  representatives: [],
};

const slice = createSlice({
  name: "sprint",
  initialState,
  reducers: {
    openCreationModal: (state) => {
      state.creation.displayModal = true;
    },
    closeCreationModal: (state) => {
      state.creation.displayModal = false;
    },
    setList: (state, action: PayloadAction<ISprint[]>) => {
      state.list = action.payload;
    },
    removeFromList: (state, action: PayloadAction<{ id: number }>) => {
      state.list = state.list.filter((item) => item.id !== action.payload.id);
    },
    addToList: (state, action: PayloadAction<ISprint>) => {
      state.list = [...state.list, action.payload];
    },
    updateList: (
      state,
      action: PayloadAction<{
        id: number;
        name: string;
        startDate: string;
        dueDate: string;
        rep: number;
      }>
    ) => {
      state.list = state.list.map((item) => {
        if (item.id == action.payload.id) {
          item = { ...item, ...action.payload };
        }
        return item;
      });
    },
    addRepresentative: (state, action: PayloadAction<IUser>) => {
      if (!state.representatives.some((rep) => rep.id === action.payload.id)) {
        state.representatives = [action.payload, ...state.representatives];
      }
    },
    setRepresentatives: (state, action: PayloadAction<IUser[]>) => {
      state.representatives = action.payload;
    },
    openUpdateModal: (state, action: PayloadAction<ISprintResource>) => {
      state.update.showModal = true;
      state.update.editableRecord = action.payload;
    },
    closeUpdateModal: (state) => {
      state.update.showModal = false;
      state.update.editableRecord = null;
    },
    openDeleteConfirmation: (state, action: PayloadAction<ISprint>) => {
      state.delete.showConfirmation = true;
      state.delete.deletableRecord = action.payload;
    },
    closeDeleteConfirmation: (state) => {
      state.delete.showConfirmation = false;
      state.delete.deletableRecord = null;
    },
    reset: () => initialState,
  },
});

export const sprintReducer = slice.reducer;

export const sprintActions = slice.actions;
