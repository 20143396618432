import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";

export const useFetchBoardListByProjectRefService = (props: {
  ref: string;
}) => {
  return useHttpClientDispatchable({
    url: `/board/byProject/${props.ref}/`,
    method: RequestMethodEnum.GET,
  });
};
