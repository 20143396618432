import { useAppSelector } from "../../../common/hooks/redux";
import { IBoard } from "../../../entities/IBoard";

export const useGetBoardByRef = (boardRef: IBoard["ref"]) => {
  const { isLoading, list } = useAppSelector((state) => state.org.init);

  const board = list
    .flatMap((listItem) => listItem.boards)
    .find((board) => board.ref === boardRef);

  return {
    board,
    isLoading,
  };
};
