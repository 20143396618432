import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import useForm from "../../../../common/hooks/useForm/useForm";
import Form from "../../../../common/components/Form/Form";
import {
  Autocomplete,
  Button,
  TextField,
} from "../../../../common/modules/materialUI";
import { THttpClientError } from "../../../../common/modules/httpClient/types/THttpClientError";
import { systemNotificationActions } from "../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { TUpdateFormProps } from "./types/TUpdateFormProps";
import { TUpdateFormAttributes } from "./types/TUpdateFormAttributes";
import { projectUtil } from "../../../../common/utils/projectUtil";
import { TUpdateFormAttributesTransformed } from "./types/TUpdateFormAttributesTransformed";
import { useDispatchEvent } from "../../../../common/modules/eventProvider";
import { useUpdateTaskPriorityService } from "../../services/useUpdateTaskPriorityService";
import { IconTypeEnum } from "../../../../entities/columns/orgIconLibrary/IconTypeEnum";
import IconLibraryPickerFormElement from "../../../iconLibrary/containers/IconLibraryPickerFormElement/IconLibraryPickerFormElement";
import { taskPriorityActions } from "../../slices/taskPrioritySlice";
import { EventEnum } from "../../../../common/modules/eventProvider/enums/EventEnum";
import { ITaskPriority } from "../../../../entities/ITaskPriority";
import Slider from "../../../../common/modules/materialUI/components/Slider/Slider";

const UpdateForm = ({ taskPriority, onCancel }: TUpdateFormProps) => {
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const projects = useAppSelector((state) => state.org.init.list).map(
    (projectWithBoard) => projectWithBoard.project
  );
  const form = useForm<TUpdateFormAttributes>({
    name: taskPriority.name,
    projectIds: projectUtil.list(
      projects.filter((project) =>
        taskPriority.projectIds?.includes(project.id as number)
      )
    ),
    iconFilePath: taskPriority.iconFilePath,
    severity: taskPriority.severity,
  });

  const { isLoading, dispatch: dispatchUpdate } = useUpdateTaskPriorityService({
    taskPriorityId: taskPriority.id,
  });

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatchUpdate({
      body: form.getTransformed<TUpdateFormAttributesTransformed>((data) => ({
        ...data,
        projectIds: data.projectIds?.length
          ? data.projectIds.map((item) => item.id)
          : null,
      })),
    })
      .then((updatedEntity: ITaskPriority) => {
        dispatch(taskPriorityActions.closeUpdateModal());
        dispatchEvent(EventEnum.ON_TASK_PRIORITY_UPDATED, updatedEntity);
        dispatch(
          systemNotificationActions.open({
            message: "Task Priority is updated successfully",
            variant: "success",
          })
        );
      })
      .catch((error: THttpClientError) => {
        form.errorHandler(error);
      });
  };

  return (
    <Form
      onSubmit={submitHandler}
      actionElement={
        <>
          <Button onClick={onCancel} disabled={isLoading} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            Update
          </Button>
        </>
      }
    >
      <TextField form={form} name="name" label="Name" showRequired />

      <Autocomplete
        form={form}
        name="projectIds"
        label={!form.data?.projectIds?.length ? "All projects" : "Projects"}
        multiple
        disabled={!projects.length}
        options={projectUtil.list(projects)}
      />

      <IconLibraryPickerFormElement
        form={form}
        name="iconFilePath"
        iconType={IconTypeEnum.TASK_PRIORITY}
        label="Icon"
        showRequired
      />

      <Slider
        form={form}
        name="severity"
        min={1}
        max={20}
        label="Severity"
        showRequired
      />
    </Form>
  );
};

export default UpdateForm;
