import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TProjectState } from "./types/TProjectState";
import { IProject } from "../../../entities/IProject";

const initialState: TProjectState = {
  projectList: [],
  creation: {
    displayModal: false,
  },
};

const slice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjectList: (state, action: PayloadAction<IProject[]>) => {
      state.projectList = action.payload;
    },
    addProject: (state, action: PayloadAction<IProject>) => {
      state.projectList.push(action.payload);
    },
    removeProject: (state, action: PayloadAction<{ ref: string }>) => {
      state.projectList = state.projectList.filter(
        (item) => item.ref !== action.payload.ref
      );
    },
    openCreationModal: (state) => {
      state.creation.displayModal = true;
    },
    closeCreationModal: (state) => {
      state.creation.displayModal = false;
    },
  },
});

export const projectReducer = slice.reducer;

export const projectActions = slice.actions;
