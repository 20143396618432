import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/hooks/redux";
import { useDispatchEvent } from "../../../../../../common/modules/eventProvider";
import useForm from "../../../../../../common/hooks/useForm/useForm";
import { taskViewActions } from "../../slices/viewSlice";
import { ConfirmationDialog } from "../../../../../../common/modules/materialUI";
import { useDeleteSegmentService } from "../../services/useDeleteSegmentService";
import { ITask } from "../../../../../../entities/ITask";
import { systemNotificationActions } from "../../../../../../common/modules/systemNotification/slices/systemNotificationSlice";
import { taskActions } from "../../../../slices/taskSlice";
import { EventEnum } from "../../../../../../common/modules/eventProvider/enums/EventEnum";
import { THttpClientError } from "../../../../../../common/modules/httpClient/types/THttpClientError";
import { TIsOnPageProp } from "../../../../../../common/modules/taskView/containers/types/TIsOnPageProp";
import { useNavigate } from "react-router-dom";

type TProps = TIsOnPageProp;

const DeleteSegmentConfirmation = ({ isOnPage }: TProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dispatchEvent = useDispatchEvent();
  const { getValidationErrorAtIndex } = useForm();
  const deleteSegmentState = useAppSelector(
    (state) => state.taskView.deleteSegment
  );
  const { dispatch: dispatchDeleteSegmentService, isLoading } =
    useDeleteSegmentService({
      taskSegmentId: deleteSegmentState?.segmentId ?? 0,
    });
  const closeConfirmationHandler = () =>
    dispatch(taskViewActions.closeDeleteSegmentConfirmation());
  const cleanupTaskStateData = () => {
    dispatch(taskActions.clearData());
    dispatch(taskViewActions.reset());
  };
  const updateBoards = () => {
    // Updating boards to reflect the changes
    dispatchEvent(EventEnum.ON_TASK_MAIN_DATA_UPDATED);
  };
  const taskLabel = deleteSegmentState?.isLastSegment ? "Task" : "Segment";

  const successHandler = () => {
    dispatchDeleteSegmentService()
      .then((resource: ITask | null) => {
        dispatch(
          systemNotificationActions.open({
            variant: "success",
            message: deleteSegmentState!.isLastSegment
              ? "Your task has been deleted successfully"
              : "Segment is deleted",
          })
        );

        updateBoards();

        if (!!resource) {
          dispatch(taskActions.setTask(resource));
          dispatch(
            taskViewActions.setViewType(
              resource.isSegmentData ? "segment" : "group"
            )
          );
          isOnPage && navigate(`/task/${resource.ref}`, { replace: true });
        } else {
          // Last segment with task has been deleted
          cleanupTaskStateData();
          dispatch(
            taskActions.addTaskDeletedRef({
              taskRef: deleteSegmentState!.taskRef,
            })
          );
        }
      })
      .catch((error: THttpClientError) => {
        if (error.status === 422) {
          const validationFirstError = getValidationErrorAtIndex(0, error);
          if (validationFirstError) {
            dispatch(
              systemNotificationActions.open({
                variant: "error",
                message: validationFirstError,
              })
            );
          }
        } else {
          dispatch(
            systemNotificationActions.open({
              variant: "error",
              message: "Failed to delete segment. Please try again.",
            })
          );
        }
      })
      .finally(() => {
        closeConfirmationHandler();
      });
  };

  if (!deleteSegmentState?.openConfirmation) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={true}
      title={`Delete ${taskLabel}`}
      content={
        deleteSegmentState.isLastSegment
          ? "All of this task’s data will be dropped, are you sure you want to delete it?"
          : "The segment will be dropped, are you sure you want to delete it?"
      }
      loading={isLoading}
      closeHandler={closeConfirmationHandler}
      successHandler={successHandler}
    />
  );
};

export default DeleteSegmentConfirmation;
