import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TIntegrationState } from "./types/TIntegrationState";
import { IIntegration } from "../../../entities/IIntegration";
import { IListResource } from "../../../interfaces/IListResource";
import { IOrgIntegration } from "../../../entities/IOrgIntegration";
import { IntegrationEnum } from "../../../entities/enums/IntegrationEnum";

const initialState: TIntegrationState = {
  list: [],
  orgIntegrationList: [],
  autocompleteList: [],
};

const slice = createSlice({
  name: "integration",
  initialState,
  reducers: {
    setList: (
      state,
      action: PayloadAction<{ data: IIntegration[]; installed?: string }>
    ) => {
      // Move installed integration to the top of the list
      if (action.payload?.installed) {
        const index = action.payload.data.findIndex(
          (obj) =>
            obj.integration ===
            IntegrationEnum[
              action.payload.installed?.toUpperCase() as keyof typeof IntegrationEnum
            ]
        );
        if (index > -1) {
          const object = action.payload.data[index];
          action.payload.data.splice(index, 1);
          action.payload.data.unshift(object);
        }
      }

      state.list = action.payload.data;
    },
    setOrgIntegrationList: (
      state,
      action: PayloadAction<IOrgIntegration[]>
    ) => {
      state.orgIntegrationList = action.payload;
    },
    removeOrgIntegration: (
      state,
      action: PayloadAction<{ integration: string }>
    ) => {
      state.orgIntegrationList = state.orgIntegrationList.filter(
        (item) => item.integration !== action.payload.integration
      );
    },
    setAutocompleteList: (state, action: PayloadAction<IIntegration[]>) => {
      state.autocompleteList = action.payload.map((item) => {
        return {
          id: item.integration,
          label: item.name,
        } as IListResource;
      });
    },
  },
});

export const integrationReducer = slice.reducer;

export const integrationActions = slice.actions;
