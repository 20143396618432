import { useHttpClientDispatchable } from "../../../common/modules/httpClient/hooks/useHttpClientDispatchable";
import { RequestMethodEnum } from "../../../common/modules/httpClient/enums/RequestMethodEnum";
import { IOAuthResource } from "../interfaces/IOAuthResource";

export const useAuthenticateService = () => {
  const response = useHttpClientDispatchable({
    url: "/auth/authenticate",
    method: RequestMethodEnum.POST,
  });

  return {
    ...response,
    data: response.data as IOAuthResource,
  };
};
